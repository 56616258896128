import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

type Props = BoxProps;

const TruentityLogo = ({ ...props }: Props) => {
  return (
    <Box
      component="img"
      src={require('../images/truentity_logo_color.svg')}
      alt="truentity logo"
      {...props}
      sx={{ maxWidth: '19rem', maxHeight: '3.375rem', display: 'block', margin: '0 auto', ...props.sx }}
    />
  );
};

export default TruentityLogo;
