import Alert from '@/components/Alert';
import Button from '@/components/Button';
import LabelGroup from '@/components/LabelGroup';
import { H2 } from '@/components/Typography';
import FlexContainer from '@/elements/FlexContainer';
import { TYPE_REQUEST_OPTIONS } from '@/types/request';
import { gql, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const SUBMIT_REQUEST = gql`
  mutation submitRequest($purpose: String!, $truentityId: String!, $catMedications: String, $catImmunizations: String) {
    submitRequest(purpose: $purpose, truentityId: $truentityId, catMedications: $catMedications, catImmunizations: $catImmunizations) {
      message
      request {
        id
      }
    }
  }
`;

export type FormValues = {
  purpose: string;
  truentityId: string;
  catMedications: string;
  catImmunizations: string;
  typeSelect: string;
};

type Props = BaseDialogProps & {
  successMessage: string;
  patientId?: string;
  patientName?: string;
  onRequestCreated?: (newRequestId: string) => void;
};

const CreateRequestDialog = ({ title, successMessage, patientId, patientName, onRequestCreated, hideDialog, ...props }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => handleSubmitRequest(data);

  const [submitRequest] = useMutation(SUBMIT_REQUEST);
  const [success, setSuccess] = useState(false);

  const handleSubmitRequest = async (values: FormValues) => {
    const truentityId = patientId || values?.truentityId;

    let variables = {};
    if (values.typeSelect === TYPE_REQUEST_OPTIONS[0].value) {
      variables = {
        purpose: values.purpose,
        truentityId,
        catMedications: '1',
        catImmunizations: '0'
      };
    } else {
      variables = {
        purpose: values.purpose,
        truentityId,
        catMedications: '0',
        catImmunizations: '1'
      };
    }
    try {
      const {
        data: {
          submitRequest: {
            request: { id }
          }
        }
      } = await submitRequest({
        variables: { ...variables }
      });
      setSuccess(true);
      if (onRequestCreated) {
        onRequestCreated(id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        {success && (
          <>
            <Alert description={successMessage} status="success" title="Success" />

            <Button a11yLabel="OK" onClick={hideDialog} />
          </>
        )}

        {!success && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {patientName ? (
              <LabelGroup label="Patient">
                <H2>{patientName}</H2>
              </LabelGroup>
            ) : (
              <TruentityTextField
                {...register('truentityId')}
                type="text"
                label="Patient ID"
                fullWidth
                variant="outlined"
                margin="dense"
                required
              />
            )}

            <TruentityTextField
              type="text"
              {...register('purpose')}
              label="Reason for request"
              fullWidth
              variant="outlined"
              margin="dense"
              required
            />

            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel id="type-select-label">Select type request</InputLabel>

              <Select {...register('typeSelect')} labelId="type-select-label" label="Select type request">
                {TYPE_REQUEST_OPTIONS.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FlexContainer align={'start'}>
              <DialogActions>
                <Button type="submit" a11yLabel="Submit" appearance="primary" />
                <Button a11yLabel="Cancel" type="reset" appearance="outline" onClick={hideDialog} />
              </DialogActions>
            </FlexContainer>
          </form>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default CreateRequestDialog;
