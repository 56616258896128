import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import SaveFilter from '@/components/SaveFilter';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDateRangePicker from '@/components/TruentityDateRangePicker';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { PatientsData } from '@/routes/Patients/patients';
import type { ClientOrganizationObject } from '@/types/administration';
import type { PatientFilter } from '@/zustand/patientFilterStore';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const filterExists = (filter: PatientFilter, list: PatientFilter[]) => {
  return list.find(f => f.id === filter?.id);
};

const SELECT_DEFAULT: { id?: string; name: string }[] = [{ id: 'all', name: 'All' }];
// const NUMBER_OF_MEDS_OPTIONS: TextSelectOption[] = [
//   { label: '1-3', value: '1-3' },
//   { label: '4-6', value: '4-6' },
//   { label: '> 6', value: '> 6' }
// ];

type Props = {
  patients: PatientsData[];
  onSaveFilter: (filter: PatientFilter) => void;
};
export const PatientListToolbar = ({ patients, onSaveFilter }: Props) => {
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [showSave, setShowSave] = useState(false);
  const { selectedFilter, setSelectedFilter, filters } = usePatientFilterStore();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const onReset = () => {
    setSelectedFilter(null);
    setShowSave(false);
  };

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([
        ...SELECT_DEFAULT,
        ...organizationData.clientOrganizations.clientOrganizations
      ] as ClientOrganizationObject[]);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    if (selectedFilter) {
      setShowSave(selectedFilter?.filter !== null && patients.length > 0 && !filterExists(selectedFilter, filters));
    }
  }, [selectedFilter, filters, patients.length]);

  return (
    <Stack alignItems={'flex-start'} p={1} spacing={1}>
      <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <TruentityDateRangePicker
          label="Onboarded On"
          sx={{ width: '300px' }}
          start={selectedFilter?.filter?.onboardedOn?.start}
          end={selectedFilter?.filter?.onboardedOn?.end}
          onChange={onboardedOn => {
            if (onboardedOn.start || onboardedOn.end) {
              setSelectedFilter({
                filter: {
                  ...selectedFilter?.filter,
                  onboardedOn
                }
              } as PatientFilter);
            }
          }}
        />

        <SelectList
          formControlProps={{ sx: { minWidth: '200px' } }}
          label="Organization"
          options={clientOrganizations?.map(item => {
            return {
              label: item.name,
              value: item.id
            } as TextSelectOption;
          })}
          placeholder="Select an option..."
          value={selectedFilter?.filter?.organization || ''}
          onChange={event => {
            setSelectedFilter({
              filter: {
                ...selectedFilter?.filter,
                organization: event.target.value as string
              }
            } as PatientFilter);
          }}
          fullWidth={false}
        />

        {/*<SelectList*/}
        {/*  formControlProps={{ sx: { 'min-width': '200px' } }}*/}
        {/*  label="Readmission Risk"*/}
        {/*  options={Object.values(ReadmissionOptions)?.map(*/}
        {/*    item =>*/}
        {/*      ({*/}
        {/*        label: item.toUpperCase(),*/}
        {/*        value: item*/}
        {/*      } as TextSelectOption)*/}
        {/*  )}*/}
        {/*  placeholder="Select an option..."*/}
        {/*  value={filter?.readmissionRisk || ReadmissionOptions.Any}*/}
        {/*  onChange={event => setFilter({ ...filter, readmissionRisk: event.target.value as ReadmissionOptions })}*/}
        {/*  fullWidth={false}*/}
        {/*/>*/}

        {/*<SelectList*/}
        {/*  formControlProps={{ sx: { 'min-width': '200px' } }}*/}
        {/*  label="Number of Meds"*/}
        {/*  options={NUMBER_OF_MEDS_OPTIONS}*/}
        {/*  placeholder="Select an option..."*/}
        {/*  value={filter?.numberOfMeds || '0'}*/}
        {/*  onChange={event => setFilter({ ...filter, numberOfMeds: event.target.value as string })}*/}
        {/*  fullWidth={false}*/}
        {/*/>*/}
      </Stack>
      {selectedFilter && filterExists(selectedFilter, filters) && <Button label={'Reset'} onClick={onReset} />}

      {showSave && selectedFilter?.filter && (
        <SaveFilter
          activeFilter={selectedFilter.filter}
          onSave={selectedFilter => {
            onSaveFilter(selectedFilter);
            setShowSave(false);
          }}
          onReset={onReset}
        />
      )}
    </Stack>
  );
};
