import { UPDATE_HEALTH_PLAN } from '@/graphql/administration';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

type FormValues = {
  id: string;
  name: string;
};

const defaultValues: FormValues = {
  id: '',
  name: ''
};

const HealthPlanUpdateDialog = ({ title, hideDialog, ...props }: Props) => {
  const {
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [updateHealthPlan] = useMutation(UPDATE_HEALTH_PLAN);
  const [nameValue, setNameValue] = useState<string>('');
  const [idValue, setIdValue] = useState<string>('');

  useEffect(() => {
    const fields = props.id?.split(',');
    if (fields) {
      setIdValue(fields[0]);
      setNameValue(fields[1]);
    }
  }, []);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const result: any = await updateHealthPlan({
        variables: {
          healthPlanId: idValue,
          name: nameValue
        }
      });

      const data = result.data!.updateHealthPlan;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, {
        variant
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to update health plan', {
        variant: 'error'
      });
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container spacing={4} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={6}>
            <Stack>
              <TruentityTextField
                autoFocus
                required
                onChange={input => setNameValue(input.target.value)}
                value={nameValue}
                label={'Name'}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}></Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button type="submit" a11yLabel="Save" appearance="primary" />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default HealthPlanUpdateDialog;
