import { useContext } from 'react';

import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';

import Alert from '@/components/Alert';
import PatientDetailContext from '@/context/patientDetailContext';
import { formatDateAndTime } from '@/util/format';
import { Paper, Stack } from '@mui/material';

const renderItem = (medication: any) => {
  return (
    <TableRow key={medication.local_id}>
      <TableCell>{medication.prescriberName}</TableCell>
      <TableCell>{formatDateAndTime(medication.updated_at)}</TableCell>
      <TableCell>{medication.name}</TableCell>
      <TableCell>-</TableCell>
    </TableRow>
  );
};

const MedicationHistory = () => {
  const { medications } = useContext(PatientDetailContext);

  return (
    <Paper component={Stack}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeading style={{ width: '25%' }}>Name</TableHeading>
            <TableHeading style={{ width: '25%' }}>Date</TableHeading>
            <TableHeading style={{ width: '25%' }}>Medication</TableHeading>
            <TableHeading style={{ width: '25%' }}>Action Taken</TableHeading>
          </TableRow>
        </TableHead>
        <tbody>{medications.map(renderItem)}</tbody>
      </Table>
      {medications.length === 0 && (
        <Alert description="There are no medications registered in the database " status="warning" title="No Medications" />
      )}
    </Paper>
  );
};

export default MedicationHistory;
