import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { medHistoryStatusColors } from '@/components/Dialogs/MedHistoryRequestsDialog';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import TruentityDateFullRangePicker from '@/components/TruentityDateFullRangePicker';
import { H1, H4 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import { GetAccountMedHistoryResponse, GET_ACCOUNT_MED_HISTORY } from '@/graphql/medHistory';
import useDateRange from '@/hooks/useDateRange';
import {
  MedHistoryRequestsFileType,
  MedHistoryRequestsType,
  MedHistoryRequestTabsTypes,
  MedHistoryStatusType,
  MedHistoryTabType
} from '@/types/medHistory';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatDateAndTime } from '@/util/format';
import { capitalizeLetterBeforeCharacter } from '@/util/string';
import { useLazyQuery } from '@apollo/client';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Box, Chip, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

type MedFileStatuses =
  | MedHistoryStatusType.PENDING
  | MedHistoryStatusType.REQUESTED
  | MedHistoryStatusType.SUCCESS
  | MedHistoryStatusType.FAILED
  | null;

type SelectedDateTypes = {
  startDate: string;
  endDate: string;
} | null;

const medHistoryRequestTabs: MedHistoryRequestTabsTypes[] = [
  {
    type: MedHistoryTabType.SCHEDULED,
    icon: <CalendarTodayIcon />,
    label: 'Scheduled',
    path: 'scheduled'
  },
  {
    type: MedHistoryTabType.REQUESTSSENT,
    icon: <LocalShippingIcon />,
    label: 'Requests Sent',
    path: 'requestsSent'
  },
  {
    type: MedHistoryTabType.MEDSRECEIVED,
    icon: <CallReceivedIcon />,
    label: 'Meds Received',
    path: 'medsReceived'
  }
];

const columnVisibility = {
  scheduled: {
    patientName: true,
    dateOfBirth: true,
    scheduledBy: true,
    scheduledOn: true,
    medReceivedOn: false,
    requestSentOn: false,
    numberOfMeds: false,
    responseStatus: false,
    medStatus: false
  },
  requestsSent: {
    patientName: true,
    dateOfBirth: true,
    scheduledBy: false,
    scheduledOn: true,
    requestSentOn: true,
    responseStatus: true,
    medReceivedOn: false,
    numberOfMeds: false,
    medStatus: false
  },
  medsReceived: {
    patientName: true,
    dateOfBirth: false,
    scheduledBy: false,
    scheduledOn: false,
    medReceivedOn: true,
    responseReceivedOn: true,
    requestSentOn: true,
    numberOfMeds: true,
    responseStatus: false,
    medStatus: true
  }
};

const MedHistoryRequests = () => {
  const { setContent } = useContext(SideMenuContext);
  const { enqueueSnackbar } = useSnackbar();

  const { startDate, endDate, handleDateChange } = useDateRange({ datesGap: 30 });

  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    patientName: true,
    dateOfBirth: true,
    scheduledBy: true,
    scheduledOn: true,
    medReceivedOn: false,
    requestedOn: false,
    numberOfPatients: false,
    numberOfMeds: false,
    responseStatus: false,
    medStatus: false
  });
  // const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  // const [selectedFileId, setSelectedFileId] = useState<string>('');
  const [dataGridData, setDataGridData] = useState<MedHistoryRequestsType[] | MedHistoryRequestsFileType[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(DEFAULT_PAGE_SIZE);

  const [medHistoryRequests, { data: medHistoryRequestData, loading: medHistoryRequestLoading }] =
    useLazyQuery<GetAccountMedHistoryResponse>(GET_ACCOUNT_MED_HISTORY, {
      fetchPolicy: 'cache-and-network'
    });

  const setDefaultPageOptions = () => {
    setCurrentPage(0);
  };

  // const closeDrawer = () => {
  //   setOpenDrawer(false);
  // };

  const callMedHistoryRequestQuery = async ({
    responseFileStatus = null,
    medResponseFileStatus = null,
    selectedDates = null
  }: {
    responseFileStatus?: MedFileStatuses;
    medResponseFileStatus?: MedFileStatuses;
    selectedDates?: SelectedDateTypes;
  }): Promise<void> => {
    try {
      const filterOptions: any = {
        responseFileStatus,
        medResponseStatus: medResponseFileStatus
      };

      if (selectedDates) {
        filterOptions.startDate = selectedDates.startDate;
        filterOptions.endDate = selectedDates.endDate;
      }
      await medHistoryRequests({
        variables: {
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE,
          filterOptions
        }
      });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve medication history requests', { variant: 'error' });
    }
  };

  const activeTabType = useMemo(() => {
    return medHistoryRequestTabs[activeTab].type;
  }, [activeTab]);

  const handleTabChange = useCallback((_event: SyntheticEvent, selectedTabIndex: number): void => {
    setActiveTab(selectedTabIndex);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1,
        valueGetter: params => getAccountUserFullName(params?.row?.account?.user),
        renderCell: params => {
          const name = getAccountUserFullName(params?.row?.account?.user);
          return <Link to={`../../patients/${params.row.account.truentityId}/details/medications/triage/list`}>{name}</Link>;
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        flex: 1,
        valueGetter: params => (params?.row?.account?.birthDate ? formatDate(params?.row?.account?.birthDate) : 'N/A')
      },
      {
        field: 'scheduledBy',
        headerName: 'Scheduled By',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => params?.row?.relyingPartyAdmin?.name ?? 'N/A'
      },
      {
        field: 'scheduledOn',
        headerName: 'Scheduled On',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => (params?.row?.createdAt ? formatDateAndTime(params?.row?.createdAt) : '-')
      },
      {
        field: 'requestSentOn',
        headerName: 'Request Sent On',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => (params?.row?.requestedOn ? formatDateAndTime(params?.row?.requestedOn) : '-')
      },
      {
        field: 'medReceivedOn',
        headerName: 'Meds Received On',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => (params?.row?.medReceivedOn ? formatDateAndTime(params?.row?.medReceivedOn) : '-')
      },
      {
        field: 'numberOfPatients',
        headerName: 'Number of Patients',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => params?.row?.totalPatientCount ?? '-'
      },
      {
        field: 'numberOfMeds',
        headerName: 'Number of Meds',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => params?.row?.medCount ?? '-'
      },
      {
        field: 'responseStatus',
        headerName: 'Response File Status',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        renderCell: params => {
          if (params?.row?.responseFileStatus) {
            return (
              <Chip
                label={params?.row?.responseFileStatus}
                color={medHistoryStatusColors.get(params?.row?.responseFileStatus) ?? 'warning'}
                variant="filled"
              />
            );
          } else return '-';
        }
      },
      {
        field: 'medStatus',
        headerName: 'Status',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        renderCell: params => {
          if (params?.row?.medResponseStatus) {
            const note = params.row.medNote;
            const isNoteAvailable = !!note;
            const chipParam = {
              label: params?.row?.medResponseStatus,
              color: medHistoryStatusColors.get(params?.row?.medResponseStatus) ?? 'warning',
              varient: 'filled',
              sx: { cursor: !isNoteAvailable ? 'default' : 'pointer' }
            };
            return isNoteAvailable ? (
              <Tooltip title={note} hidden={!isNoteAvailable} followCursor>
                <Chip {...chipParam} />
              </Tooltip>
            ) : (
              <Chip {...chipParam} />
            );
          } else {
            return <Chip label="PENDING" color="default" variant="filled" />;
          }
        }
      }
    ],
    [activeTabType]
  );

  // const onFileSelect = (data: MedHistoryRequestsFileType) => {
  //   if (data.medResponseStatus === MedHistoryStatusType.SUCCESS) {
  //     setOpenDrawer(true);
  //     setSelectedFileId(data.id);
  //   }
  // };

  useEffect(() => {
    const selectedTab = medHistoryRequestTabs[activeTab];
    const visibility = columnVisibility[selectedTab.path];
    const newVisibilityModel = { ...columnVisibilityModel, ...visibility };
    setColumnVisibilityModel(newVisibilityModel);
    setDefaultPageOptions();
  }, [activeTab]);

  useEffect(() => {
    if (activeTabType === MedHistoryTabType.SCHEDULED) {
      callMedHistoryRequestQuery({ responseFileStatus: MedHistoryStatusType.PENDING });
    } else if (activeTabType === MedHistoryTabType.REQUESTSSENT) {
      callMedHistoryRequestQuery({ responseFileStatus: MedHistoryStatusType.REQUESTED });
    } else if (activeTabType === MedHistoryTabType.MEDSRECEIVED) {
      callMedHistoryRequestQuery({ responseFileStatus: MedHistoryStatusType.SUCCESS, selectedDates: { startDate, endDate } });
    }
  }, [activeTabType, currentPage, startDate, endDate]);

  useEffect(() => {
    if (medHistoryRequestData && medHistoryRequestData?.getAccountMedHistoryRequests) {
      const { accountMedHistoryRequests, meta } = medHistoryRequestData?.getAccountMedHistoryRequests;
      setTotalRowCount(meta?.totalCount);
      return setDataGridData(accountMedHistoryRequests);
    }
    return setDataGridData([]);
  }, [activeTabType, medHistoryRequestData, setDataGridData]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return (
    <>
      <Stack spacing={2}>
        <Paper component={Stack} padding={2} elevation={0}>
          <H1>Medication History Requests</H1>
        </Paper>
        <Paper component={Stack} padding={2} elevation={0}>
          <Box mb={3}>
            <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
              {medHistoryRequestTabs?.map((tab, index: number) => (
                <Tab
                  label={<h6 style={{ marginBottom: 0 }}>{capitalizeLetterBeforeCharacter(tab.label)}</h6>}
                  key={`tab-${index}`}
                  id={`tab-${index}`}
                  icon={tab.icon}
                  iconPosition="start"
                />
              ))}
            </Tabs>
          </Box>
          {activeTabType === MedHistoryTabType.MEDSRECEIVED && (
            <Stack direction="row" justifyContent="flex-end" mb={3}>
              <Box sx={{ flexBasis: 300 }}>
                <H4 mb={1}>Dates Requested On</H4>
                <TruentityDateFullRangePicker
                  disableFuture={true}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  onChange={handleDateChange}
                />
              </Box>
            </Stack>
          )}
          <Box>
            <TruentityDataGrid
              name="dg-medication-history-requested"
              autoHeight
              paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
              columnVisibilityModel={columnVisibilityModel}
              onPaginationModelChange={({ page }) => {
                setCurrentPage(page);
              }}
              rows={dataGridData}
              columns={columns}
              loading={medHistoryRequestLoading}
              rowCount={totalRowCount}
              paginationMode="server"
              disableRowSelectionOnClick
            />
          </Box>
        </Paper>
      </Stack>
      {/* <MedHistoryDrawer isOpen={openDrawer} onClose={closeDrawer} title="Additional Details" fileId={selectedFileId} /> */}
    </>
  );
};

export default MedHistoryRequests;
