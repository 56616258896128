import Button from '@/components/Button';
import MuiMenu from '@/components/MuiMenu';
import { H4 } from '@/components/Typography';
import type { GetRelyingPartyAlertLimitsResponse, UpdateRelyingPartyAlertLimitsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RELYING_PARTY_ALERT_LIMITS, UPDATE_RELYING_PARTY_ALERT_LIMITS } from '@/graphql/remotePatientMonitoring';
import AlertLimitsMarkersSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/AlertLimitsMarkersSection';
import AlertLimitsSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/AlertLimitsSection';
import type { ClinicalRangesFormValues } from '@/types/remotePatientMonitoring';
import { GlobalAlertLimitsSubmitEnum } from '@/types/remotePatientMonitoring';
import { getAlertLimitsObject, setAlertLimits } from '@/util/rpm';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Divider, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { RelyingParty } from '@/types/graphql';

export type ClinicalRangesSectionProps = {
  companyData: RelyingParty;
};

export const ClinicalRangesSection: React.FC<ClinicalRangesSectionProps> = ({ companyData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const defaultAlertLimits: ClinicalRangesFormValues = useMemo(
    () => ({
      submitType: GlobalAlertLimitsSubmitEnum.SAVE,
      heartRateLimits: [0, 0, 0, 0],
      bloodGlucoseLimits: [0, 0, 0, 0],
      sysBloodPressureLimits: [0, 0, 0, 0],
      diaBloodPressureLimits: [0, 0, 0, 0]
    }),
    []
  );
  const methods = useForm<ClinicalRangesFormValues>({ defaultValues: defaultAlertLimits, mode: 'onBlur' });
  const { control, setValue, watch, handleSubmit } = methods;
  const onSubmitSave: SubmitHandler<ClinicalRangesFormValues> = data =>
    handleClinicalRangesSave({
      ...data,
      submitType: GlobalAlertLimitsSubmitEnum.SAVE
    });
  const onSubmitApplyToAll: SubmitHandler<ClinicalRangesFormValues> = data =>
    handleClinicalRangesSave({
      ...data,
      submitType: GlobalAlertLimitsSubmitEnum.SAVE_AND_APPLY_TO_ALL
    });

  const heartRateLimits = watch('heartRateLimits');
  const bloodGlucoseLimits = watch('bloodGlucoseLimits');
  const sysBloodPressureLimits = watch('sysBloodPressureLimits');
  const diaBloodPressureLimits = watch('diaBloodPressureLimits');

  const [
    updateCompanyAlertLimits,
    { data: updateCompanyAlertLimitsData, error: updateCompanyAlertLimitsError, loading: updateCompanyAlertLimitsLoading }
  ] = useMutation<UpdateRelyingPartyAlertLimitsResponse>(UPDATE_RELYING_PARTY_ALERT_LIMITS);
  const [getCompanyAlertLimits, { data: companyAlertLimitsData, loading: loadingCompanyAlertLimits }] =
    useLazyQuery<GetRelyingPartyAlertLimitsResponse>(GET_RELYING_PARTY_ALERT_LIMITS, {
      variables: {
        relyingPartyId: companyData?.id
      },
      fetchPolicy: 'cache-and-network'
    });

  const handleClinicalRangesSave = async (values: ClinicalRangesFormValues) => {
    const alertLimitsInput = {
      heartRate: getAlertLimitsObject(values.heartRateLimits),
      bloodGlucose: getAlertLimitsObject(values.bloodGlucoseLimits),
      sysBloodPressure: getAlertLimitsObject(values.sysBloodPressureLimits),
      diaBloodPressure: getAlertLimitsObject(values.diaBloodPressureLimits)
    };
    await updateCompanyAlertLimits({
      variables: {
        relyingPartyId: companyData?.id,
        submitType: values.submitType,
        alertLimitsInput: alertLimitsInput
      }
    });
  };

  const resetAlertLimits = () => {
    setAlertLimits(
      companyAlertLimitsData?.getRelyingPartyAlertLimitsConfigurations ?? {
        bloodGlucose: {
          cLowValue: 0,
          lowValue: 0,
          highValue: 0,
          cHighValue: 0
        },
        heartRate: {
          cLowValue: 0,
          lowValue: 0,
          highValue: 0,
          cHighValue: 0
        },
        sysBloodPressure: {
          cLowValue: 0,
          lowValue: 0,
          highValue: 0,
          cHighValue: 0
        },
        diaBloodPressure: {
          cLowValue: 0,
          lowValue: 0,
          highValue: 0,
          cHighValue: 0
        }
      },
      setValue
    );
  };

  useEffect(() => {
    if (companyAlertLimitsData?.getRelyingPartyAlertLimitsConfigurations) {
      setAlertLimits(companyAlertLimitsData?.getRelyingPartyAlertLimitsConfigurations, setValue);
    }
  }, [companyAlertLimitsData, setValue]);

  useEffect(() => {
    if (updateCompanyAlertLimitsError) {
      enqueueSnackbar('Could not update company alert limits', { variant: 'error' });
    }
  }, [updateCompanyAlertLimitsError]);

  useEffect(() => {
    if (companyData?.id) {
      getCompanyAlertLimits({
        variables: {
          relyingPartyId: companyData?.id
        }
      });
    }
  }, [companyData?.id]);

  useEffect(() => {
    if (updateCompanyAlertLimitsData?.updateRelyingPartyAlertLimits?.status === 'Success') {
      enqueueSnackbar(updateCompanyAlertLimitsData?.updateRelyingPartyAlertLimits?.message, { variant: 'success' });
    }
  }, [updateCompanyAlertLimitsData]);

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmitSave)}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
      }}
    >
      <AlertLimitsMarkersSection showDivider={true} />
      <H4
        sx={{
          mt: 2
        }}
      >
        Blood Pressure
      </H4>
      <AlertLimitsSection
        label="Systolic"
        unit={'mmHg'}
        minValue={40}
        maxValue={190}
        name={'sysBloodPressureLimits'}
        control={control}
        values={sysBloodPressureLimits}
        disabled={loadingCompanyAlertLimits}
      />
      <AlertLimitsSection
        label="Diastolic"
        unit={'mmHg'}
        minValue={10}
        maxValue={140}
        name={'diaBloodPressureLimits'}
        control={control}
        values={diaBloodPressureLimits}
        disabled={loadingCompanyAlertLimits}
      />
      <Divider
        sx={{
          width: '100%'
        }}
      />
      <AlertLimitsSection
        label="Heart Rate"
        unit={'bpm'}
        minValue={0}
        maxValue={160}
        name={'heartRateLimits'}
        control={control}
        values={heartRateLimits}
        disabled={loadingCompanyAlertLimits}
      />
      <Divider
        sx={{
          width: '100%'
        }}
      />
      <AlertLimitsSection
        label="Blood Glucose"
        unit={'mg/dl'}
        minValue={30}
        maxValue={240}
        name={'bloodGlucoseLimits'}
        control={control}
        values={bloodGlucoseLimits}
        disabled={loadingCompanyAlertLimits}
      />
      <Grid container justifyContent="flex-end" spacing={1} paddingBottom={2} paddingRight={4}>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            isLoading={updateCompanyAlertLimitsLoading}
            label={'Reset form'}
            onClick={resetAlertLimits}
            size="large"
          />
        </Grid>
        <Grid item>
          <MuiMenu
            ButtonProps={{
              size: 'large'
            }}
            variant="contained"
            disabled={updateCompanyAlertLimitsLoading}
            label="Save"
            options={[
              {
                label: <H4>SAVE</H4>,
                onAction: () => handleSubmit(onSubmitSave)()
              },
              {
                label: <H4>{'SAVE & APPLY TO ALL'}</H4>,
                onAction: () => handleSubmit(onSubmitApplyToAll)()
              }
            ]}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ClinicalRangesSection;
