import { FormValues } from '@/types/accountProfile';

export const emptyData: FormValues = {
  firstName: '',
  lastName: '',
  middleName: '',
  addressLine1: '',
  addressLine2: '',
  addressState: '',
  addressCity: '',
  zipcode: '',
  ehrId: '',
  phone: '',
  birthDate: new Date(),
  gender: '',
  consent: '',
  emergencyContactName: '',
  emergencyContactEmail: '',
  emergencyContactPhone: '',
  doNotCall: false,
  primaryLanguage: '',
  doNotCallLastSpecifiedAt: null,
  healthPlan: ''
};

export const reducer = (state, action: { type: string; payload: string | null | boolean | Date }) => {
  if (action.type === 'firstName') {
    return {
      ...state,
      firstName: action.payload
    };
  }
  if (action.type === 'lastName') {
    return {
      ...state,
      lastName: action.payload
    };
  }
  if (action.type === 'middleName') {
    return {
      ...state,
      middleName: action.payload
    };
  }
  if (action.type === 'addressLine1') {
    return {
      ...state,
      addressLine1: action.payload
    };
  }
  if (action.type === 'addressLine2') {
    return {
      ...state,
      addressLine2: action.payload
    };
  }
  if (action.type === 'addressState') {
    return {
      ...state,
      addressState: action.payload
    };
  }
  if (action.type === 'addressCity') {
    return {
      ...state,
      addressCity: action.payload
    };
  }
  if (action.type === 'zipcode') {
    return {
      ...state,
      zipcode: action.payload
    };
  }
  if (action.type === 'phone') {
    return {
      ...state,
      phone: action.payload
    };
  }
  if (action.type === 'birthDate') {
    return {
      ...state,
      birthDate: action.payload
    };
  }
  if (action.type === 'gender') {
    return {
      ...state,
      gender: action.payload
    };
  }
  if (action.type === 'consent') {
    return {
      ...state,
      consent: action.payload
    };
  }
  if (action.type === 'emergencyContactName') {
    return {
      ...state,
      emergencyContactName: action.payload
    };
  }
  if (action.type === 'emergencyContactPhone') {
    return {
      ...state,
      emergencyContactPhone: action.payload
    };
  }
  if (action.type === 'emergencyContactEmail') {
    return {
      ...state,
      emergencyContactEmail: action.payload
    };
  }
  if (action.type === 'email') {
    return {
      ...state,
      email: action.payload
    };
  }
  return state;
};
