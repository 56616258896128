import { theme } from '@/styles/mui-theme';
import { getEnvironmentLabel, isProd } from '@/util/environment';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { Body1 } from './Typography';

const EnvironmentBar = () => {
  const [info, setInfo] = useState({ label: '', isProd: false });

  useEffect(() => {
    const label = getEnvironmentLabel();

    setInfo({ label, isProd: isProd() });
  }, []);

  return (
    <Stack
      sx={{
        display: !info.isProd ? 'flex' : 'none',
        userSelect: 'none',
        zIndex: theme.zIndex.drawer + 2,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
        height: '30px',
        backgroundColor: theme.palette.error.dark,
        padding: 1,
        minWidth: '100px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px'
      }}
    >
      <Body1 sx={{ color: theme.palette.error.contrastText, textAlign: 'center' }}>{info.label}</Body1>
    </Stack>
  );
};

export default EnvironmentBar;
