import type { BoxProps, ChipProps } from '@mui/material';
import { Chip, IconButton, Stack } from '@mui/material';
import type { MenuProps } from '@mui/material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import * as React from 'react';

export type ChipDropdownOption<T> = {
  label: string;
  value: T;
  color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
};
type Props<T = unknown> = {
  selectedOption: ChipDropdownOption<T>;
  setSelectedOption: (v: ChipDropdownOption<T>) => void;
  options: ChipDropdownOption<T>[];
  Props?: BoxProps;
  ChipProps?: ChipProps;
  disabled?: boolean;
};

const StyledMenu = styled((props: MenuProps) => <Menu elevation={1} {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1.5)
      }
    }
  }
}));

const ChipDropdown = <T,>({ selectedOption, setSelectedOption, options, disabled, ...props }: Props<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack {...props?.Props}>
      <IconButton disabled={disabled} aria-label="chip-dropdown" onClick={e => setAnchorEl(e.currentTarget)}>
        <Chip disabled={disabled} label={selectedOption?.label.toLocaleUpperCase()} color={selectedOption?.color} {...props.ChipProps} />
      </IconButton>

      <StyledMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {options.map(option => (
          <MenuItem
            key={option.label}
            onClick={() => {
              setSelectedOption(option);
              handleClose();
            }}
          >
            <Chip label={option.label.toLocaleUpperCase()} color={option.color} {...props.ChipProps} />
          </MenuItem>
        ))}
      </StyledMenu>
    </Stack>
  );
};

export default ChipDropdown;
