import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Icon, IconButtonProps } from '@mui/material';

type PinnedIconProps = IconButtonProps & {
  pinned: boolean;
  color: any;
};
const PinnedIcon = ({ pinned, color, ...props }: PinnedIconProps) => {
  return <Icon {...props}>{!pinned ? <PushPinOutlinedIcon sx={{ color }} /> : <PushPinIcon sx={{ color }} />}</Icon>;
};

export default PinnedIcon;
