import TruentityDatePicker from '@/components/TruentityDatePicker';
import { EventTypeFilter } from '@/routes/TransitionalCareManagement/AdtQueue';
import { FilterModalTypes } from '@/routes/TransitionalCareManagement/AdtQueueUtil';
import { DISCHARGE_TYPES } from '@/types/dischargePatient';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveIcon from '@mui/icons-material/Save';
import { DialogActions, DialogContent, Divider, FormControl, Grid, IconButton, MenuItem, Popover, Select, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import { Body1 } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

export enum FilterType {
  Event = 'Event',
  ReadmissionRisk = 'Readmission Risk',
  InitialCommunicationDueBy = 'Initial Communication Due By',
  AdmissionReason = 'Admission Reason',
  DischargeDate = 'Discharge Date',
  AdmissionDate = 'Admission Date',
  DischargedDisposition = 'Discharged Disposition',
  DischargingPhysician = 'Discharging Physician'
}

export enum FilterTemplateType {
  Adt = 'ADT',
  PatientList = 'PATIENTS_LIST'
}

export enum FilterAvailabilityType {
  Admin = 'Admin',
  Org = 'Org'
}

export enum OperatorType {
  Is = 'is'
}

export enum reAdmissionRiskType {
  True = 'true',
  False = 'false'
}

const AdtFilterDialog = ({
  title,
  hideDialog,
  formInputFields,
  onFilterAddClicked,
  onDeleteIconClicked,
  handleFormChange,
  onFiltersApplied,
  handleDateChange,
  handleToDateChange,
  relyingPartyFilters,
  relyingPartyAdminFilters,
  onTemplateSelected,
  onSaveFilterClicked,
  onHideModalTest,
  ...props
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitImpl = e => {
    e.preventDefault();

    try {
      onFiltersApplied(formInputFields);

      enqueueSnackbar('Filters Applied', {
        variant: 'success'
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to apply filters', {
        variant: 'error'
      });
    }
  };

  const filterIndividualComponent = () => {
    const componentsArray = formInputFields.map((row, index) => {
      return (
        <Grid item xs={12} key={index}>
          <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'start', alignItems: 'center', padding: '0 0 0 0', marginTop: 0 }}>
            <Body1 sx={{ fontWeight: 'bold' }} mr={1}>
              Where:{' '}
            </Body1>

            <FormControl variant="outlined" sx={{ width: '50%' }} margin="dense" size="medium">
              <Select
                name="filterType"
                labelId={'event'}
                label={'Event'}
                placeholder={'Select filter'}
                value={row.filterType}
                sx={{ textTransform: 'capitalize' }}
                onChange={event => handleFormChange(index, event)}
              >
                {Object.values(FilterType).map(value => (
                  <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {row.filterType && (
              <FormControl variant="outlined" sx={{ width: '35%' }} margin="dense" size="medium">
                <Select
                  name="operatorType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a event type'}
                  value={'is' || row.operatorType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {Object.values(OperatorType).map(value => (
                    <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {row.filterType === FilterType.Event && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Select
                  name="eventType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a event type'}
                  value={row.eventType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {Object.values(EventTypeFilter).map(value => (
                    <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {row.filterType === FilterType.ReadmissionRisk && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Select
                  name="eventType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a readmission type'}
                  value={row.eventType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {Object.values(reAdmissionRiskType).map(value => (
                    <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {row.filterType === FilterType.AdmissionReason && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <TruentityTextField
                  name="eventType"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={row.eventType}
                  onChange={event => handleFormChange(index, event)}
                  label="Admission Reason"
                />
              </FormControl>
            )}

            {row.filterType === FilterType.DischargingPhysician && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <TruentityTextField
                  name="eventType"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={row.eventType}
                  onChange={event => handleFormChange(index, event)}
                  label="Admission Reason"
                />
              </FormControl>
            )}

            {row.filterType === FilterType.DischargeDate && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Stack direction={'row'} justifyContent="space-between">
                  <TruentityDatePicker
                    label="From"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[0]}
                    onChange={event => handleDateChange(index, event)}
                  />
                  <TruentityDatePicker
                    label="To"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[1]}
                    onChange={event => handleToDateChange(index, event)}
                  />
                </Stack>
              </FormControl>
            )}

            {row.filterType === FilterType.AdmissionDate && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Stack direction={'row'} justifyContent="space-between">
                  <TruentityDatePicker
                    label="From"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[0]}
                    onChange={event => handleDateChange(index, event)}
                  />
                  <TruentityDatePicker
                    label="To"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[1]}
                    onChange={event => handleToDateChange(index, event)}
                  />
                </Stack>
              </FormControl>
            )}

            {row.filterType === FilterType.InitialCommunicationDueBy && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Stack direction={'row'} justifyContent="space-between">
                  <TruentityDatePicker
                    label="From"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[0]}
                    onChange={event => handleDateChange(index, event)}
                  />
                  <TruentityDatePicker
                    label="To"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[1]}
                    onChange={event => handleToDateChange(index, event)}
                  />
                </Stack>
              </FormControl>
            )}

            {row.filterType === FilterType.DischargedDisposition && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Select
                  name="eventType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a event type'}
                  value={row.eventType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {DISCHARGE_TYPES.map(({ label, value }) => {
                    return (
                      <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <IconButton onClick={() => onDeleteIconClicked(index, formInputFields, FilterModalTypes.AdtFilter)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
      );
    });

    return componentsArray;
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSaveAsTemplatePopoverClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSaveAsTemplatePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="md">
      <DialogContent>
        <Grid
          component={'form'}
          container
          spacing={1}
          onSubmit={e => {
            handleSubmitImpl(e);
          }}
          mt={1}
        >
          {filterIndividualComponent()}

          <Grid item xs={12}>
            <Button
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => {
                onFilterAddClicked(formInputFields, FilterModalTypes.AdtFilter);
              }}
              size={'small'}
            >
              Add Filter
            </Button>
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '30px 0 0 0' }}>
              <Button
                type="button"
                a11yLabel="Save as Template"
                appearance="primary"
                onClick={handleSaveAsTemplatePopoverClick}
                endIcon={<KeyboardArrowDownIcon />}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleSaveAsTemplatePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <Stack>
                  <Button
                    startIcon={<SaveIcon />}
                    type="button"
                    sx={{ marginTop: 3, marginLeft: 2, marginRight: 2, marginBottom: 1 }}
                    a11yLabel="Save My Filters"
                    appearance="secondary"
                    onClick={() => onSaveFilterClicked(formInputFields, 'Admin')}
                  />

                  <Divider sx={{ margin: 1 }} />

                  <Button
                    startIcon={<SaveIcon />}
                    type="button"
                    sx={{ marginBottom: 3, marginLeft: 2, marginRight: 2 }}
                    a11yLabel="Save Org Filters"
                    appearance="secondary"
                    onClick={() => onSaveFilterClicked(formInputFields, 'Org')}
                  />
                </Stack>
              </Popover>

              <Stack direction={'row'} spacing={1}>
                <Button type="submit" a11yLabel="Apply" appearance="primary" />
                <Button
                  type="reset"
                  a11yLabel="Cancel"
                  appearance="outline"
                  onClick={() => {
                    hideDialog();
                  }}
                />
              </Stack>
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default AdtFilterDialog;
