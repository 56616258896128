import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import { H4 } from '@/components/Typography';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import { GET_HEALTH_PLANS } from '@/graphql/administration';
import { emptyData } from '@/state/patientProfileForm';
import type { FormValues, HealthPlan } from '@/types/accountProfile';
import { CONSENT_OPTIONS, GENDER_OPTIONS } from '@/types/accountProfile';
import { states } from '@/util/address';
import { toDate } from '@/util/date';
import { formatDate, formatDateIgnoreTZ } from '@/util/format';
import { zipcodeRestrictToFiveDigits } from '@/util/input';
import { useAccountStore } from '@/zustand/AccountStore';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FormControl, FormControlLabel, Grid, Stack, Switch } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

const UPDATE_USER_PROFILE = gql`
  mutation updateUserAccountProfile($truentityId: String!, $profileData: UserAccountProfileInput!) {
    updateUserAccountProfile(truentityId: $truentityId, profileData: $profileData) {
      account {
        truentityId
        addressLine1
        addressLine2
        addressState
        addressCity
        zipcode
        accountExternalEhr {
          type
          externalId
        }
        healthPlan {
          id
          orgName
        }
        phone
        birthDate
        gender
        emergencyContactName
        emergencyContactPhone
        emergencyContactEmail
        user {
          id
          firstName
          middleName
          lastName
        }
        doNotCall
        doNotCallLastSpecifiedAt
        primaryLanguage
        consent
      }
    }
  }
`;
//TODO fix warning related to using redux and react-hook-form

const PatientProfile = () => {
  const { setIsAccountChanged } = useAccountStore();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [healthPlans, setHealthPlans] = useState<HealthPlan[]>([]);
  const [defaultBirthDate, setDefaultBirthDate] = useState<Date | undefined>(undefined);

  const { control, reset, handleSubmit, setValue, watch } = useForm<FormValues>({ defaultValues: emptyData });

  const { data: patientData } = useQuery(GET_PATIENT_DETAIL, {
    variables: {
      truentityId: id
    }
  });
  const { data: healthPlansResponse } = useQuery(GET_HEALTH_PLANS, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  const [
    updateUserAccountProfile,
    { data: updateUserAccountProfileData, loading: updateUserAccountProfileLoading, error: updateUserAccountProfileError }
  ] = useMutation(UPDATE_USER_PROFILE);
  const doNotCallSwitchWatch = watch('doNotCall');
  const STATES: TextSelectOption[] = states();

  useEffect(() => {
    setValue('doNotCallLastSpecifiedAt', new Date());
  }, [doNotCallSwitchWatch, setValue]);

  const onSuccess = (success: boolean, message?: string) => {
    enqueueSnackbar(message ?? (success ? 'Profile Updated' : 'Unable to Update Profile'), { variant: success ? 'success' : 'error' });
    if (success) {
      setIsAccountChanged(true);
      navigate(`/patients/${id}/details`);
    }
  };

  useEffect(() => {
    if (healthPlansResponse && healthPlansResponse.healthPlans && healthPlansResponse.healthPlans.healthPlans) {
      setHealthPlans(healthPlansResponse.healthPlans.healthPlans);
    }
  }, [healthPlansResponse]);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    const selectedHealthPlan = healthPlans?.find(x => x?.orgName.toLowerCase() === values.healthPlan) ?? {
      id: patientData.accountGet?.healthPlan?.id
    };

    const profileData = {
      userProfile: {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName
      },
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressState: values.addressState?.toUpperCase(),
      addressCity: values.addressCity,
      zipcode: values.zipcode,
      ehrId: values.ehrId,
      healthPlan: selectedHealthPlan?.id ? { id: selectedHealthPlan.id } : null,
      phone: values.phone,
      birthDate: formatDate(values.birthDate, 'YYYY-MM-DD'),
      gender: values.gender,
      consent: values.consent,
      doNotCall: values.doNotCall,
      primaryLanguage: values.primaryLanguage,
      doNotCallLastSpecifiedAt: values.doNotCallLastSpecifiedAt
    };

    try {
      await updateUserAccountProfile({
        variables: {
          truentityId: id,
          profileData
        }
      });
    } catch (error: any) {
      onSuccess(false);
    }
  };

  useEffect(() => {
    if (patientData) {
      const account = patientData.accountGet;
      const user = account.user;
      const healthPlan = account?.healthPlan;
      const birthDate = toDate(formatDate(account.birthDate, 'YYYY-MM-DD'));
      const patientInfo: FormValues = {
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        birthDate: birthDate,
        email: user.email,
        gender: account.gender,
        address: account.address,
        addressLine1: account.addressLine1,
        addressLine2: account.addressLine2,
        addressState: account.addressState,
        addressCity: account.addressCity,
        zipcode: account.zipcode,
        ehrId: account.accountExternalEhr?.externalId,
        healthPlan: healthPlan?.orgName ?? '',
        consent: account.consent,
        phone: account.phone,
        emergencyContactName: account.emergencyContactName,
        emergencyContactPhone: account.emergencyContactPhone,
        emergencyContactEmail: account.emergencyContactEmail,
        primaryLanguage: account.primaryLanguage,
        doNotCallLastSpecifiedAt: account.doNotCallLastSpecifiedAt,
        doNotCall: account.doNotCall
      };
      setDefaultBirthDate(birthDate);
      reset(patientInfo);
    }
  }, [patientData, reset]);

  useEffect(() => {
    if (updateUserAccountProfileError) {
      onSuccess(false, updateUserAccountProfileError.message);
    }
  }, [updateUserAccountProfileError]);

  useEffect(() => {
    if (updateUserAccountProfileData?.updateUserAccountProfile?.account) {
      onSuccess(true);
    }
  }, [updateUserAccountProfileData]);

  return (
    <Grid component={'form'} container spacing={4} onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={6}>
        <H4>Profile:</H4>
        <Stack spacing={2} mt={2}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField autoFocus required onChange={onChange} value={value} label={'First Name'} />
            )}
          />
          <Controller
            control={control}
            name="middleName"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Middle Name'} />}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField required onChange={onChange} value={value} label={'Last Name'} />
            )}
          />
          <Controller
            control={control}
            name="birthDate"
            render={({ field: { onChange, value } }) => (
              <TruentityDatePicker
                value={value}
                TextFieldProps={{ required: true }}
                onChange={onChange}
                defaultValue={defaultBirthDate}
                label={'Date of Birth'}
              />
            )}
          />
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <SelectList options={GENDER_OPTIONS} placeholder="Select an option..." onChange={onChange} value={value} label={'Gender'} />
            )}
          />
          <Controller
            control={control}
            name="addressLine1"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address Line 1'} />}
          />
          <Controller
            control={control}
            name="addressLine2"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address Line 2'} />}
          />
          <Controller
            control={control}
            name="addressCity"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address City'} />}
          />
          <Controller
            control={control}
            name="addressState"
            // render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address State'} />}
            render={({ field: { onChange, value } }) => (
              <SelectList
                id={'addressState'}
                label="Address State"
                options={STATES}
                placeholder="Select a state..."
                value={value ?? ''}
                clearFunction={() => {
                  setValue('addressState', '');
                }}
                MenuProps={{
                  sx: { maxHeight: 220 }
                }}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="zipcode"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField
                onChange={e => zipcodeRestrictToFiveDigits(e.target.value, onChange)}
                value={value}
                label={'Zipcode'}
                required
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField shrink={true} disabled onChange={onChange} value={value} label={'Email'} />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <TruentityPhoneNumber editable={true} onChange={onChange} value={value} label={'Phone'} />
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <H4>Preferences:</H4>
        <Stack spacing={2} mt={2}>
          <Stack direction="row" alignContent="center">
            <Controller
              control={control}
              name="doNotCall"
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ width: '40%', justifyContent: 'center' }} variant="outlined" margin="dense">
                  <FormControlLabel
                    sx={{ justifyContent: 'space-between', marginLeft: '5px', marginRight: '5px' }}
                    labelPlacement={'start'}
                    label={'Do Not Call or Follow Up'}
                    control={<Switch checked={value} onChange={onChange} />}
                  />
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="doNotCallLastSpecifiedAt"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField shrink={true} disabled onChange={onChange} value={formatDateIgnoreTZ(value)} label={'Specified On'} />
              )}
            />
          </Stack>
          <Controller
            control={control}
            name="primaryLanguage"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Primary Language'} />}
          />
          <Controller
            control={control}
            name="consent"
            render={({ field: { onChange, value } }) => (
              <SelectList label="Consent" options={CONSENT_OPTIONS} placeholder="Select an option..." value={value} onChange={onChange} />
            )}
          />
          <Controller
            control={control}
            name="healthPlan"
            render={({ field: { onChange, value } }) => (
              <SelectList
                id={'healthPlan'}
                label="Health Plan"
                options={[...healthPlans]
                  .sort((a, b) => a?.orgName.localeCompare(b?.orgName))
                  .map(x => ({ label: x?.orgName, value: x?.orgName }))}
                placeholder="Select a health plan..."
                value={value || ''}
                MenuProps={{
                  sx: { maxHeight: 250 }
                }}
                clearFunction={() => {
                  setValue('healthPlan', '');
                }}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="ehrId"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'EHR ID'} />}
          />
        </Stack>
      </Grid>
      <Grid xs={12}>
        <Stack direction="row" spacing={2} alignContent="center" justifyContent={'flex-end'}>
          <Button isLoading={updateUserAccountProfileLoading} type="submit" a11yLabel="Save" appearance="primary" />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PatientProfile;
