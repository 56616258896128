import { color } from '@/styles/assets/colors';
import { FormControl } from '@mui/material';
import Slider, { SliderProps, SliderThumb } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

export const CustomizedSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  width: 300,
  color: theme.palette.grey[800],
  '& .MuiSlider-thumb': {
    backgroundColor: theme.palette.primary.main,
    '&.ranges-slider-thumb-0': {
      backgroundColor: color.brown700
    },
    '&.ranges-slider-thumb-1': {
      backgroundColor: color.warningMain
    },
    '&.ranges-slider-thumb-2': {
      backgroundColor: color.pink100
    },
    '&.ranges-slider-thumb-3': {
      backgroundColor: color.errorMain
    }
  }
}));

interface ClinicalRangesSliderThumbProps extends React.HTMLAttributes<unknown> {}
export const ClinicalRangesSliderThumb = (props: ClinicalRangesSliderThumbProps) => {
  const { children, className, ...other } = props;
  const extraClassName = `ranges-slider-thumb-${other['data-index']}`;

  return (
    <SliderThumb {...other} className={clsx(className, extraClassName)}>
      {children}
    </SliderThumb>
  );
};

interface ClinicalRangesSliderProps {
  minValue: number;
  maxValue: number;
  unit: string;
  label: string;
  name: string;
  control: Control<any>;
  disabled?: boolean;
}
export const ClinicalRangesSlider: React.FC<ClinicalRangesSliderProps> = ({
  name,
  control,
  label,
  minValue,
  maxValue,
  unit,
  disabled = false
}) => {
  const getValueText = (value: number) => {
    return `${value} ${unit ?? ''}`;
  };

  const marks = useMemo(() => {
    const marks: Array<{ value: number; label: string }> = [];
    const section = (maxValue - minValue) / 2;
    for (let i = 0; i < 3; i++) {
      const roundedValue = Math.round(minValue + section * i);
      marks.push({
        value: roundedValue,
        label: `${roundedValue}${unit ?? ''}`
      });
    }
    return marks;
  }, [minValue, maxValue]);

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomizedSlider
            getAriaLabel={() => label}
            value={value}
            track={false}
            onChange={onChange}
            slots={{ thumb: ClinicalRangesSliderThumb }}
            min={minValue}
            disabled={disabled}
            max={maxValue}
            marks={marks}
            valueLabelDisplay="auto"
            valueLabelFormat={(value, _) => getValueText(value)}
          />
        )}
      />
    </FormControl>
  );
};

export default ClinicalRangesSlider;
