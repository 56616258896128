export const parseStringToJson = (input: string) => {
  try {
    if (input === '') {
      return {};
    }
    return JSON.parse(input);
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.error('Invalid JSON syntax. Please provide valid JSON.');

      return {};
    } else {
      console.error('An error occurred while parsing the JSON string.');
      return {};
    }
  }
};

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const getValueFromJsonString = (jsonString: string | undefined, path: string, defaultValue: any = false): any => {
  try {
    if (!jsonString) return defaultValue;
    const json = JSON.parse(jsonString);
    return path.split('.').reduce((acc, part) => acc && acc[part], json) ?? defaultValue;
  } catch (error) {
    console.error('Failed to parse JSON string:', error);
    return defaultValue;
  }
};
