import { AccountType, RelyingPartyAdminType } from './graphql';

export enum MedHistoryStatusType {
  PENDING = 'PENDING',
  REQUESTED = 'REQUESTED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum MedHistoryTabType {
  SCHEDULED = 'SCHEDULED',
  REQUESTSSENT = 'REQUESTSSENT',
  MEDSRECEIVED = 'MEDSRECEIVED'
}

export type MedHistoryRequestTabsTypes = {
  type: MedHistoryTabType.SCHEDULED | MedHistoryTabType.REQUESTSSENT | MedHistoryTabType.MEDSRECEIVED;
  icon: JSX.Element;
  label: string;
  path: string;
};

export type MedHistoryRequestsType = {
  id: string;
  transmissionControlNumber: string;
  medNote: string;
  medCount: number | null;
  responseFileStatus: MedHistoryStatusType;
  medResponseStatus: MedHistoryStatusType | null;
  createdAt: string;
  account: AccountType;
  relyingPartyAdmin: RelyingPartyAdminType;
};

export type AssociatedAccountWithMedCountType = {
  account: AccountType;
  totalMedsCount: number;
};

export type MedHistoryRequestsFileType = {
  id: string;
  fileName: string;
  externalCorrelationId: string;
  source: string;
  transmissionControlNumber: string;
  fileRequestedOn: string | null;
  fileRespondedOn: string | null;
  flatFileReceivedOn: string | null;
  additionalDetails: string;
  medCount: number | null;
  responseFileStatus: MedHistoryStatusType;
  medResponseStatus: MedHistoryStatusType | null;
  createdAt: string;
  totalPatientCount: number;
  associatedAccounts?: AssociatedAccountWithMedCountType[];
};
