import { usePersistColumnSettings } from '@/hooks/usePersistColumnSettings';
import type { TooltipProps } from '@mui/material';
import { Stack, Tooltip, tooltipClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import type { DataGridProProps } from '@mui/x-data-grid-pro';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid-pro';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

export const SMALL_PAGE_SIZE = process.env.SMALL_DATA_GRID_PAGINATION_SIZE ? parseInt(process.env.SMALL_DATA_GRID_PAGINATION_SIZE) : 25;
export const MEDIUM_PAGE_SIZE = process.env.MEDIUM_DATA_GRID_PAGE_SIZE ? parseInt(process.env.MEDIUM_DATA_GRID_PAGE_SIZE) : 50;
export const DEFAULT_PAGE_SIZE = process.env.DEFAULT_DATA_GRID_PAGE_SIZE ? parseInt(process.env.DEFAULT_DATA_GRID_PAGE_SIZE) : 100;
export const HIGH_PAGE_SIZE = process.env.HIGH_DATA_GRID_PAGE_SIZE ? parseInt(process.env.HIGH_DATA_GRID_PAGE_SIZE) : 1000;

interface TruentityDataGridProps extends DataGridProProps {
  name: string;
  disabled?: boolean;
  customFilter?: ReactNode;
}

export const DataGridToolTip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <Stack direction={'row'} sx={{ userSelect: 'none' }} alignItems={'center'} spacing={2}>
      {' '}
      {children}
    </Stack>
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: '#000000',
    backgroundColor: theme.palette.grey[50],
    fontSize: theme.typography.pxToRem(10),
    border: '1px solid #dadde9',
    borderRadius: 10,
    userSelect: 'none'
  }
}));

const CustomToolbar = ({ customFilter }: { customFilter?: ReactNode }) => {
  return (
    <GridToolbarContainer>
      <Stack sx={{ width: '100%' }}>
        {customFilter}
        <Stack sx={{ width: '100%' }} justifyContent={'flex-start'} direction="row" spacing={2} p={2}>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
};

export const TruentityDataGrid = ({ disabled, name, customFilter, paginationModel, autoPageSize, ...props }: TruentityDataGridProps) => {
  const apiRef = useGridApiRef();
  usePersistColumnSettings(apiRef, name);

  const getApiRef = useCallback(() => {
    return props.apiRef ?? apiRef;
  }, [apiRef, props.apiRef]);

  return (
    <DataGridPro
      pagination
      pageSizeOptions={[]}
      apiRef={getApiRef()}
      slots={{
        toolbar: CustomToolbar
      }}
      slotProps={{
        toolbar: {
          customFilter
        }
      }}
      {...props}
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        opacity: disabled ? 0.5 : 1,
        '& .MuiDataGrid-row:hover': {
          '& .MuiCheckbox-root': {
            color: grey[700]
          },
          '.MuiSvgIcon-root': {
            color: grey[700]
          },
          '.selected .MuiSvgIcon-root': {
            color: 'inherit'
          }
        },
        '& .MuiDataGrid-cellCheckbox': {
          minWidth: '25px !important'
        },
        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
          minWidth: '25px !important'
        },
        '& .star-column.MuiDataGrid-cell': {
          paddingLeft: 0
        },
        '& .no-gutter.MuiDataGrid-columnHeader': {
          paddingX: 0,
          minWidth: '25px !important'
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          marginLeft: 0.5,
          minWidth: '30px !important',
          width: '30px !important'
        },
        '& .MuiCheckbox-root': {
          minWidth: '25px !important',
          color: grey[400],
          '&:hover': {
            color: grey[700]
          }
        },
        ...props?.sx
      }}
      autoPageSize={autoPageSize ?? true}
      paginationModel={{
        pageSize: paginationModel?.pageSize || DEFAULT_PAGE_SIZE,
        page: paginationModel?.page || 0
      }}
    />
  );
};
