import Button from '@/components/Button';
import { ASSIGN_TASKS } from '@/graphql/taskEncounter';
import { RelyingPartyAdminData } from '@/routes/Administration';
import { useMutation } from '@apollo/client';
import { Autocomplete, DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  relyingPartyAdmins: RelyingPartyAdminData[];
  taskIds: string[];
  selectedAdmins: RelyingPartyAdminData[];
};

const AssignTasksToPharmacistDialog = ({ title, hideDialog, relyingPartyAdmins, selectedAdmins, taskIds, ...props }: Props) => {
  const [assignTasksToAdmin] = useMutation(ASSIGN_TASKS);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChange = (event, value) => setSelectedOptions(value);

  const handleOnAssignSubmitClicked = async admin_ids => {
    if (admin_ids.length > 0 && taskIds.length > 0) {
      admin_ids = admin_ids.map(admin => admin.id);

      let variables = {};
      variables = {
        taskIds,
        relyingPartyAdminIds: admin_ids
      };

      try {
        const result = await assignTasksToAdmin({
          variables: { ...variables }
        });

        const status = result.data.assignTasksToRelyingPartyAdmins.status;

        if (status !== 'Success') {
          throw new Error('Not successful');
        }
      } catch (err) {
        console.log(err);
      } finally {
        hideDialog();
      }
    }
  };

  return (
    <BaseDialog
      disableBackdropClick={true}
      canNavigateBack={false}
      title={title}
      hideDialog={hideDialog}
      {...props}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogContent>
        <Autocomplete
          multiple
          id="tags-standard"
          options={relyingPartyAdmins}
          onChange={handleChange}
          getOptionLabel={option => option.name}
          defaultValue={selectedAdmins}
          renderInput={params => <TextField {...params} variant="standard" label="Users" placeholder="select an option" />}
        />
      </DialogContent>

      <DialogActions>
        <Button a11yLabel="Submit" appearance="primary" onClick={() => handleOnAssignSubmitClicked(selectedOptions)} />
        <Button a11yLabel="Cancel" type="reset" appearance="outline" onClick={hideDialog} />
      </DialogActions>
    </BaseDialog>
  );
};

export default AssignTasksToPharmacistDialog;
