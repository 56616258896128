import { Document, Page, StyleSheet } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  page: {
    flex: 1,
    padding: 50,
    backgroundColor: '#FFFFFF'
  }
});

interface PdfDocumentProps {
  title?: string;
  children: React.ReactNode;
}

const PdfDocument: React.FC<PdfDocumentProps> = ({ title, children }) => {
  return (
    <Document title={title}>
      <Page style={styles.page}>{children}</Page>
    </Document>
  );
};

export default PdfDocument;
