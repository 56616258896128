import { UPDATE_CLIENT_STORE } from '@/graphql/administration';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { useMutation } from '@apollo/client';
import { Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

type FormValues = {
  name: string;
  nameTag: string;
};

const defaultValues: FormValues = {
  name: '',
  nameTag: ''
};

const StoreUpdateDialog = ({ title, hideDialog, ...props }: Props) => {
  const {
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();
  const { roleType } = useToken();

  const [updateStore] = useMutation(UPDATE_CLIENT_STORE);
  const [clientStoreIdValue, setClientStoreIdValue] = useState<string>('');
  const [nameValue, setNameValue] = useState<string>('');
  const [nameTagValue, setNameTagValue] = useState<string>('');
  const [isPrimaryClientStore, setIsPrimaryClientStore] = useState<boolean>(false);

  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);

  useEffect(() => {
    const fields = props.id?.split(',');
    if (fields) {
      setClientStoreIdValue(fields[0]);
      setNameValue(fields[1]);
      setNameTagValue(fields[2]);
      setIsPrimaryClientStore(fields[3] ? fields[3] === 'true' : false);
    }
  }, []);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const result: any = await updateStore({
        variables: {
          clientStoreId: clientStoreIdValue,
          name: nameValue,
          nameTag: nameTagValue,
          isPrimary: isPrimaryClientStore
        }
      });

      const data = result.data!.updateClientStore;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, {
        variant
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to update location', {
        variant: 'error'
      });
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container columnSpacing={4} rowSpacing={2} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={6}>
            <Stack>
              <TruentityTextField
                autoFocus
                required
                onChange={input => setNameValue(input.target.value)}
                value={nameValue}
                label={'Name'}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack>
              <TruentityTextField
                required
                onChange={input => setNameTagValue(input.target.value)}
                value={nameTagValue}
                label={'Name Tag'}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isPrimaryClientStore}
                    onChange={event => {
                      setIsPrimaryClientStore(event.target.checked);
                    }}
                  />
                }
                label="Primary Client Org"
                disabled={!isSuperAdmin}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '20px 0 0 0' }}>
              <Button type="submit" a11yLabel="Save" appearance="primary" />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default StoreUpdateDialog;
