import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDateRangePicker from '@/components/TruentityDateRangePicker';
import type { PatientFilter } from '@/zustand/patientFilterStore';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import { toDateOrDefault } from '@/util/date';
import { Stack } from '@mui/material';

export const ConversationsListToolbar = () => {
  const { selectedFilter, setSelectedFilter } = usePatientFilterStore();

  return (
    <Stack>
      <Stack direction="row" alignItems="center" gap={2} p={1} flexWrap="wrap">
        <TruentityDateRangePicker
          label="Duration"
          sx={{ width: '300px' }}
          start={toDateOrDefault(selectedFilter?.filter?.conversationDuration?.start, null)}
          end={toDateOrDefault(selectedFilter?.filter?.conversationDuration?.end, null)}
          onChange={conversationDuration => {
            if (conversationDuration.start || conversationDuration.end) {
              setSelectedFilter({
                filter: {
                  ...selectedFilter?.filter,
                  conversationDuration
                }
              } as PatientFilter);
            }
          }}
        />
        <SelectList
          formControlProps={{ sx: { minWidth: '200px' } }}
          label="Status"
          defaultValue={selectedFilter?.filter?.conversationStatus || 'all'}
          options={
            [
              {
                label: 'All',
                value: 'all'
              },
              {
                label: 'Active',
                value: 'active'
              },
              {
                label: 'Ended',
                value: 'ended'
              }
            ] as TextSelectOption[]
          }
          placeholder="Select an option..."
          value={selectedFilter?.filter?.conversationStatus || 'all'}
          onChange={event => {
            setSelectedFilter({
              filter: {
                ...selectedFilter?.filter,
                conversationStatus: event.target.value as string
              }
            } as PatientFilter);
          }}
          fullWidth={false}
        />
      </Stack>
    </Stack>
  );
};

export default ConversationsListToolbar;
