import Button from '@/components/Button';
import MuiAccordion from '@/components/MuiAccordion';
import TruentityTextField from '@/components/TruentityTextField';
import type { UpdateRelyingPartyResponse } from '@/graphql/account';
import { UPDATE_RELYING_PARTY } from '@/graphql/account';
import useToken from '@/hooks/useToken';

import { Role } from '@/types/admin';
import { type RelyingParty } from '@/types/graphql';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getValueFromJsonString } from '@/util/json';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import PioneerRxDetailsForm from '../Components/Administration/integrations/PioneerRxDetailsForm';

type FormValues = {
  companyName: string;
  companyId: string;
};

const defaultValues: FormValues = {
  companyId: '',
  companyName: ''
};

const IntegrationSection: React.FC<{ integrationData: RelyingParty }> = ({ integrationData }) => {
  const { roleType } = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [updateRelyingParty] = useMutation<UpdateRelyingPartyResponse>(UPDATE_RELYING_PARTY);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const isCurrentUserCanMangeCompany = useMemo(() => {
    if (!currentUser?.settings) return false;

    return getValueFromJsonString(currentUser.settings, 'additional_permission.can_manage_my_company');
  }, [currentUser]);
  const isCurrentUserCanAccessCompany = useMemo(
    () => isSuperAdmin || isCurrentUserCanMangeCompany,
    [isSuperAdmin, isCurrentUserCanMangeCompany]
  );

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, reset, handleSubmit, watch } = methods;

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      await updateRelyingParty({
        variables: {
          relyingPartyId: integrationData?.id,
          name: values?.companyName,
          logo: integrationData?.logo,
          coverLetterBody: integrationData?.coverLetterBody,
          addressLine1: integrationData.addressLine1,
          addressLine2: integrationData.addressLine2,
          addressCity: integrationData.addressCity,
          addressState: integrationData.addressState,
          email: integrationData.email,
          phone: integrationData.phone
        }
      });
      enqueueSnackbar('Successfully updated company details', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
  };

  const resetCompanyIntegrations = useCallback(() => {
    if (integrationData) {
      const companyProfile: FormValues = {
        companyName: integrationData.name || '',
        companyId: integrationData.id || ''
      };

      reset(companyProfile);
    }
  }, [reset, integrationData]);

  const isCompanyIdAvailable = watch('companyId');
  const isCompanyNameAvailable = watch('companyName');

  const checkIfAnyFieldAvailable = () => {
    return isCompanyIdAvailable !== '' || isCompanyNameAvailable !== '';
  };

  useEffect(() => {
    resetCompanyIntegrations();
  }, [resetCompanyIntegrations]);

  return (
    <MuiAccordion
      detailsSx={{ p: 2 }}
      options={[
        {
          label: 'Company Details',
          defaultExpand: true,
          content: (
            <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack sx={{ width: '50%', marginRight: 1, marginTop: 2 }}>
                <Controller
                  control={control}
                  name="companyId"
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField autoFocus disabled onChange={onChange} value={value} label={'Company ID'} />
                  )}
                />

                <Controller
                  control={control}
                  name="companyName"
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField autoFocus disabled={!isSuperAdmin} onChange={onChange} value={value} label={'Company Name'} />
                  )}
                />
              </Stack>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    label={'Reset form'}
                    disabled={!checkIfAnyFieldAvailable()}
                    onClick={resetCompanyIntegrations}
                  />
                </Grid>
                <Grid item>
                  <Button label={'Save details'} type="submit" disabled={!checkIfAnyFieldAvailable()} />
                </Grid>
              </Grid>
            </Stack>
          )
        },
        {
          label: 'PioneerRx Details',
          defaultExpand: isSuperAdmin,
          disabled: !isCurrentUserCanAccessCompany,
          content: <PioneerRxDetailsForm relyingPartyId={integrationData.id} />
        }
      ]}
    />
  );
};

export default IntegrationSection;
