import type { DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { DateTimePicker } from '@mui/x-date-pickers-pro';

type Props = DateTimePickerProps;

const TruentityDateTimePicker = ({ ...props }): Props => {
  return <DateTimePicker {...props} />;
};

export default TruentityDateTimePicker;
