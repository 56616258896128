import { MEDIUM_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import SelectList, { TextSelectOption } from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { H3 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import { GetActivityLogsResponse, GET_ACTIVITY_LOGS } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';

import { Role } from '@/types/admin';
import { ActivityLog } from '@/types/remotePatientMonitoring';
import { categorizedActivityLogTypes } from '@/util/activityLog';
import { formatDateAndTime } from '@/util/format';
import { unknown } from '@/util/string';
import { useLazyQuery } from '@apollo/client';
import { Box, Paper, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';

const defaultSelectType = {
  label: 'ALL',
  value: 'ALL'
};

const Logs = () => {
  const { roleType } = useToken();
  const { setContent } = useContext(SideMenuContext);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [activityName, setActivityName] = useState<string>(defaultSelectType.value);
  const [subActivityName, setSubActivityName] = useState<string>(defaultSelectType.value);
  const [activities, setActivities] = useState<string[]>([]);

  const [callActivityLog, { data: activityLogData, loading: activityLogLoading, refetch: activityLogRefetch }] =
    useLazyQuery<GetActivityLogsResponse>(GET_ACTIVITY_LOGS, {
      fetchPolicy: 'cache-and-network'
    });

  const columns: GridColDef<ActivityLog>[] = [
    {
      field: 'firstName',
      flex: 0.5,
      headerName: 'First Name',
      sortable: true,
      valueGetter: params => params.row.account?.user?.firstName ?? unknown()
    },
    {
      field: 'lastName',
      flex: 0.5,
      headerName: 'Last Name',
      sortable: true,
      valueGetter: params => params.row.account?.user?.lastName ?? unknown()
    },
    {
      field: 'logText',
      flex: 2,
      headerName: 'Log Text',
      sortable: true
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Log recorded on',
      valueGetter: params => formatDateAndTime(params.row.createdAt),
      sortable: true
    }
  ];

  const getRefactoredLogTypes = useMemo(() => {
    let refactoredLogTypes: TextSelectOption[] = [];
    const nonSuperAdminsAcceptLogNames = ['Patient Vital Access Logs'];
    if (!isSuperAdmin) {
      nonSuperAdminsAcceptLogNames.forEach(nonSuperAdminsAcceptLogName => {
        const log = categorizedActivityLogTypes.find(type => type.logName === nonSuperAdminsAcceptLogName);
        if (log) {
          refactoredLogTypes.push({
            label: log.logName,
            value: log.logName
          });
          setActivityName(log.logName);
        }
      });
      return refactoredLogTypes;
    } else {
      refactoredLogTypes = categorizedActivityLogTypes.map(logType => {
        return {
          label: logType.logName,
          value: logType.logName,
          disabled: logType.logTypes.length === 0
        };
      });
      if (isSuperAdmin) {
        refactoredLogTypes.unshift({ label: 'All', value: 'All' });
      }
      return refactoredLogTypes;
    }
  }, [isSuperAdmin, categorizedActivityLogTypes]);

  const getActivityTypesBasedLabel = (selectedLogName: string) => {
    return categorizedActivityLogTypes.find(logType => logType.logName.toUpperCase() === selectedLogName.toUpperCase());
  };

  const getSubActivityTypes = (labelName: string) => {
    let refactoredSubTypes: TextSelectOption[] = [];
    const subTypes = getActivityTypesBasedLabel(labelName);
    if (!subTypes) {
      refactoredSubTypes.push(defaultSelectType);
    } else {
      refactoredSubTypes = subTypes.logTypes.map(logType => {
        return { label: logType, value: logType };
      });
      refactoredSubTypes.unshift(defaultSelectType);
    }

    return refactoredSubTypes;
  };

  const logTypesOptions = useMemo(() => getSubActivityTypes(activityName), [activityName]);

  useEffect(() => {
    callActivityLog({
      variables: {
        pageNum: currentPage + 1,
        activityTypes: activities,
        pageSize: MEDIUM_PAGE_SIZE
      }
    });
  }, [callActivityLog, currentPage, activities]);

  useEffect(() => {
    const activityTypes =
      activityName.toUpperCase() === defaultSelectType.value
        ? []
        : subActivityName.toUpperCase() === defaultSelectType.value
        ? getActivityTypesBasedLabel(activityName)?.logTypes ?? []
        : [subActivityName.toUpperCase()];

    setActivities(activityTypes);
    setCurrentPage(0);
  }, [activityName, subActivityName]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return (
    <Paper component={Stack} spacing={2} p={5}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <H3 flex={1}>Logs</H3>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <SelectList
            formControlProps={{ sx: { width: '230px' } }}
            label="Log Type Name"
            options={getRefactoredLogTypes}
            placeholder="Select an option..."
            value={activityName}
            onChange={event => {
              setActivityName(event.target.value as string);
              setSubActivityName(defaultSelectType.value);
            }}
            fullWidth={false}
            readOnly={getRefactoredLogTypes.length <= 1}
          />

          <SelectList
            label="Log Types"
            options={logTypesOptions}
            placeholder="Select an option..."
            value={subActivityName}
            onChange={event => {
              setSubActivityName(event.target.value as string);
            }}
            fullWidth={false}
            formControlProps={{ sx: { width: '230px' } }}
            disabled={logTypesOptions.length === 0 || activityName === defaultSelectType.value}
            readOnly={logTypesOptions.length === 1}
          />
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <TruentityDataGrid
          name={'dg-logs'}
          paginationModel={{ pageSize: MEDIUM_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          paginationMode={'server'}
          rowCount={activityLogData?.activityLogsByFilter.meta.totalCount ?? 0}
          autoHeight
          loading={activityLogLoading}
          rows={activityLogData?.activityLogsByFilter.activityLogs ?? []}
          columns={columns}
          disableRowSelectionOnClick
        />
      </Box>
    </Paper>
  );
};

export default Logs;
