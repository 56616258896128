import debug from '@/util/debug';
import type { ApolloError } from '@apollo/client';
import type { GraphQLError } from 'graphql';

export type ErrorHandler = (error: GraphQLError) => void;
export type HandlerMap = Record<string, ErrorHandler>;

export const isApolloError = (err: any): err is ApolloError => !!(err as ApolloError).graphQLErrors || !!(err as ApolloError).networkError;

export const hasGraphQLErrors = (err: ApolloError) => err.graphQLErrors && err.graphQLErrors.length > 0;

export const handleGraphQLError = (error: ApolloError, handlers: HandlerMap) => {
  debug.log('[handleGraphQLError]', error);

  if (error.networkError || !error.graphQLErrors) {
    debug.error('[handleGraphQLError]', 'cannot handle:', error);
    return;
  }

  const defaultHandler = (gqlError: GraphQLError) => handlers.all && handlers.all(gqlError);

  error.graphQLErrors.forEach(gqlError => {
    const { extensions } = gqlError;
    let handler = defaultHandler;

    if (extensions && extensions.code) {
      handler = handlers[extensions.code.toUpperCase()] || defaultHandler;
    }

    handler(gqlError);
  });
};

export const handleUncaughtError = (error: any, cb: any) => {
  debug.error('[handleUncaughtError]', error);

  if (error.networkError) {
    const apolloError = (error as ApolloError).networkError!;

    if (apolloError.message.endsWith('Failed to fetch')) {
      cb({
        message: 'Unable to connect to the remote server.'
      });
    } else if (apolloError.name === 'ServerError') {
      cb({ message: 'A server error has occurred. Please contact support.' });
    } else {
      cb('networkError', { message: apolloError.message });
    }
  } else {
    cb({ message: 'An unknown error has occurred. Please contact support.' });
  }
};

type FormErrorHandler = (err: GraphQLError) => any;
type ErrorMap = { [code: string]: FormErrorHandler } & { all?: FormErrorHandler };

type HandleMutatationFormErrorArgsWithCb = {
  errorMap?: ErrorMap;
  cb: any;
};

export const handleMutationFormErrorCb = (err: any, { errorMap, cb }: HandleMutatationFormErrorArgsWithCb) => {
  const handlerMap: HandlerMap = {};

  if (errorMap) {
    Object.keys(errorMap).forEach(key => {
      handlerMap[key] = err => cb(err);
    });
  }

  if (isApolloError(err) && hasGraphQLErrors(err)) {
    handleGraphQLError(err, handlerMap);
  } else {
    handleUncaughtError(err, cb);
  }
};
