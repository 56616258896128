import { gql } from '@apollo/client';

export const SAVE_TIMESHEET = gql`
  mutation CreateTimesheet($timesheetData: TimesheetInput!, $timesheetId: String) {
    saveTimesheet(timesheetData: $timesheetData, timesheetId: $timesheetId) {
      timesheet {
        id
      }
    }
  }
`;

export const GET_TIMESHEETS = gql`
  query getTimesheetsQuery($pageNum: Int!, $pageSize: Int!, $status: String!, $period: String!) {
    timesheetsGet(pageNum: $pageNum, pageSize: $pageSize, status: $status, period: $period) {
      timesheets {
        id
        datePerformed
        description
        numberOfHours
        numberOfMinutes
        status
        clientOrganization {
          id
          name
        }
        clientStore {
          id
          name
        }
        relyingPartyAdmin {
          id
          name
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_TIMESHEETS_BY_ADMIN = gql`
  query getTimesheetsByRelyingPartyAdminQuery(
    $relyingPartyAdminId: String!
    $pageNum: Int!
    $pageSize: Int!
    $status: String!
    $period: String!
  ) {
    timesheetsByRelyingPartyAdmin(
      relyingPartyAdminId: $relyingPartyAdminId
      pageNum: $pageNum
      pageSize: $pageSize
      status: $status
      period: $period
    ) {
      timesheets {
        id
        datePerformed
        description
        numberOfHours
        numberOfMinutes
        status
        clientOrganization {
          id
          name
        }
        clientStore {
          id
          name
        }
        relyingPartyAdmin {
          id
          name
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const CHANGE_TIMESHEET_STATUSES = gql`
  mutation changeStatusTimesheets($timesheetStatuses: [TimesheetStatusInput!]!) {
    changeStatusTimesheets(timesheetStatuses: $timesheetStatuses) {
      status
    }
  }
`;

export const DELETE_TIMESHEET = gql`
  mutation DeleteTimesheet($timesheetId: String!) {
    deleteTimesheet(timesheetId: $timesheetId) {
      status
      message
    }
  }
`;
