import Alert from '@/components/Alert';
import { Body1 } from '@/components/Typography';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Control, Controller, UseFormReset } from 'react-hook-form';

interface Props {
  control: Control<any>;
  reset: UseFormReset<any>;
  coverLetterBody: string;
  watchCoverLetterContent: string;
  isDirty: boolean;
  coverLetterBodyCalled?: boolean;
  coverLetterBodyLoading?: boolean;
  textareaProps?: TextFieldProps;
}

const RpmReportCoverLetterBody = ({
  control,
  reset,
  coverLetterBody,
  coverLetterBodyCalled = true,
  coverLetterBodyLoading,
  watchCoverLetterContent,
  textareaProps,
  isDirty
}: Props) => {
  const MAX_CHARACTERS_COUNT = 2000;
  const TEMPLATE_VARIABLE_CHARACTERS_COUNT = 850;
  const PATIENT_SERVICES_TEMPLATE_VARIABLE = 'Patient Services';

  const { enqueueSnackbar } = useSnackbar();

  const [charactersCount, setCharactersCount] = useState<number>(0);
  const [isTemplateVariableAdded, setIsTemplateVariableAdded] = useState<boolean | null>(null);

  const getTotalCharactersCount = () => {
    if (isTemplateVariableAdded === true) {
      return charactersCount + TEMPLATE_VARIABLE_CHARACTERS_COUNT;
    } else {
      return charactersCount;
    }
  };

  useEffect(() => {
    if (coverLetterBody) {
      const coverLetterFormValues = {
        coverLetterContent: coverLetterBody
      };
      const nCounts = coverLetterBody?.match(/\n\s*\n/g)?.length;
      if (nCounts) {
        setCharactersCount(coverLetterBody?.length + nCounts * 90);
      } else {
        setCharactersCount(coverLetterBody?.length);
      }
      reset(coverLetterFormValues);
    }
  }, [coverLetterBody]);

  useEffect(() => {
    if (isDirty && watchCoverLetterContent && coverLetterBodyCalled) {
      const timer = setTimeout(() => {
        if (
          watchCoverLetterContent.includes('{{') &&
          watchCoverLetterContent.includes('}}') &&
          watchCoverLetterContent.includes(PATIENT_SERVICES_TEMPLATE_VARIABLE)
        ) {
          if (!isTemplateVariableAdded) {
            setIsTemplateVariableAdded(true);
            enqueueSnackbar('Template variable added successfully.', {
              variant: 'info'
            });
          }
        } else if (isTemplateVariableAdded === true || isTemplateVariableAdded === null) {
          setIsTemplateVariableAdded(false);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [watchCoverLetterContent]);

  return (
    <Box
      sx={{
        flex: '1 1 auto'
      }}
      pb={2}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Body1 sx={{ mr: 1 }}>Character Count:</Body1>
          <Body1>
            {getTotalCharactersCount()}/{MAX_CHARACTERS_COUNT}
          </Body1>
        </Box>
      </Box>
      {getTotalCharactersCount() > MAX_CHARACTERS_COUNT && (
        <Alert
          sx={{
            m: 1
          }}
          status="warning"
          description={"Please note that if you exceed 2000 characters, then the 'Cover Letter' may not fit within a single page."}
        />
      )}
      <Controller
        control={control}
        name="coverLetterContent"
        render={({ field: { onChange, value } }) => (
          <TextField
            autoFocus={true}
            required
            disabled={coverLetterBodyLoading || false}
            minRows={6}
            onChange={e => {
              onChange(e);
              const emptyLineCounts = e.target.value?.toString()?.match(/\n\s*\n/g)?.length;
              if (emptyLineCounts) {
                setCharactersCount(e.target.value.length + emptyLineCounts * 90);
              } else {
                setCharactersCount(e.target.value.length);
              }
            }}
            value={value}
            fullWidth
            multiline
            placeholder="Cover Letter Content"
            {...textareaProps}
          />
        )}
      />
    </Box>
  );
};

export default RpmReportCoverLetterBody;
