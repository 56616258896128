import { PDFViewer } from '@/routes/PatientDetails/DischargeReports/Summary';
import { ChecklistMimeTypes } from '@/types/checklists';
import { fetchDocumentUrl } from '@/util/rpm';
import { DialogContent, Skeleton, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  resourceType: string;
  convertedS3Url: string;
};

const ResourceDialog = ({ title, resourceType, convertedS3Url, ...props }: Props) => {
  const [convertedUrl, setConvertedUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const modalSize = useRef<'xs' | 'sm' | 'md'>('md');

  const handleLoad = () => {
    setLoading(false);
  };

  const handleDownload = async () => {
    try {
      const filenameWithQuery = convertedUrl.split('/').pop();
      if (!filenameWithQuery) {
        console.error('Can not download the file');
        return;
      }
      const filename = filenameWithQuery.split('?')[0];

      const response = await fetch(convertedUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    const fetchConvertedUrl = async () => {
      try {
        if (resourceType === ChecklistMimeTypes.PDF) {
          const data = await fetchDocumentUrl(convertedS3Url);
          setConvertedUrl(data);
        } else {
          setConvertedUrl(convertedS3Url);
        }
      } catch (error) {
        console.error('Error fetching converted URL:', error);
      }
    };

    fetchConvertedUrl();
  }, [convertedS3Url]);

  const renderResource = () => {
    switch (resourceType) {
      case ChecklistMimeTypes.PDF:
        return <PDFViewer src={convertedUrl} />;
      case ChecklistMimeTypes.JPEG:
      case ChecklistMimeTypes.PNG:
        return (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            {loading && (
              <div>
                <Skeleton variant="rectangular" />
              </div>
            )}
            <img
              src={convertedUrl}
              onLoad={handleLoad}
              style={{ display: loading ? 'none' : 'block', width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </div>
        );
      case ChecklistMimeTypes.MP4:
      case ChecklistMimeTypes.MPEG:
      case ChecklistMimeTypes.QUICKTIME:
        return (
          <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
            <video
              controls
              style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
              src={convertedUrl}
              autoPlay
              controlsList="nodownload"
              onContextMenu={e => e.preventDefault()}
            />
          </div>
        );
      case ChecklistMimeTypes.DOC:
      case ChecklistMimeTypes.ZIP:
      case ChecklistMimeTypes.DOCX:
        modalSize.current = 'xs';
        return (
          <Stack justifyContent="center" width="100%">
            <Button onClick={handleDownload}>Download file</Button>
          </Stack>
        );
      default:
        return <div>Unsupported resource type</div>;
    }
  };

  return (
    <BaseDialog {...props} title={title} fullWidth maxWidth={modalSize.current}>
      <DialogContent>{renderResource()}</DialogContent>
    </BaseDialog>
  );
};

export default ResourceDialog;
