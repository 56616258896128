import Alert from '@/components/Alert';
import { RpmSetupStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { FormControlLabel, Paper, Stack, Switch, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';

const TASK_COMPLETE_TEXT = 'This task has been marked as Completed.';
const TASK_IMCOMPLETE_TEXT = 'This task is currently marked as In Progress.';

const completionCriteria = {
  [RpmWorkflowTab.CLINICAL_SUMMARY]: 'Completion Criteria: At least one Clinical Summary submitted for approval',
  [RpmWorkflowTab.DEVICES]: 'Completion Criteria: At least one device successfully connected for monitoring',
  [RpmWorkflowTab.CONSENT_MANAGEMENT]: 'Completion Criteria: Consent obtained',
  [RpmWorkflowTab.MEDICAL_RECONCILIATION]: 'Completion Criteria: At least one MedRec done and assigned',
  [RpmWorkflowTab.MEDICAL_CONSULTS]: 'Completion Criteria: At least one Intake (HPI) completed',
  [RpmWorkflowTab.CARE_PLAN]: 'Completion Criteria: Care Plan answers entered',
  [RpmWorkflowTab.CONFIGURATION]: 'Completion Criteria: At least one Provider for Monthly Reports configured'
};

interface Props {
  tabType: RpmWorkflowTab;
  tabStatus: string;
  onStatusChange: (val: boolean) => void;
  canCurrentTabBeCompleted: boolean;
}

const RpmTabStatusHandler = ({ tabType, tabStatus, onStatusChange, canCurrentTabBeCompleted }: Props) => {
  const getAlertDescription = useCallback(
    (tabStatus: string) => {
      if (tabStatus === RpmSetupStatusTypes.COMPLETED)
        return (
          TASK_COMPLETE_TEXT +
          (!canCurrentTabBeCompleted ? '' : ' Please toggle the Completed switch if you wish to track it as In Progress.')
        );
      else
        return (
          TASK_IMCOMPLETE_TEXT +
          (!canCurrentTabBeCompleted ? '' : ' Please toggle the Completed switch if you wish to mark it as Completed')
        );
    },
    [canCurrentTabBeCompleted]
  );

  useEffect(() => {
    console.warn('tabStatus', tabStatus);
  }, [tabStatus]);

  return (
    <Tooltip title={<Typography fontSize={13}>{completionCriteria[tabType]}</Typography>}>
      <Paper
        component={Stack}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        spacing={2}
        sx={{ backgroundColor: theme => theme.palette.background.default, padding: 2 }}
      >
        <Alert
          variant={'standard'}
          status={tabStatus === RpmSetupStatusTypes.COMPLETED ? 'success' : 'warning'}
          description={getAlertDescription(tabStatus)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={tabStatus === RpmSetupStatusTypes.COMPLETED}
              onChange={e => onStatusChange(e.target.checked)}
              disabled={!canCurrentTabBeCompleted}
            />
          }
          label={'Completed'}
          labelPlacement="end"
        />
      </Paper>
    </Tooltip>
  );
};

export default RpmTabStatusHandler;
