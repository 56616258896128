import { currentLoggedUserVar } from '@/util/apollo/cache';
import { gql, useQuery, useReactiveVar } from '@apollo/client';
import type { PaperProps } from '@mui/material';
import { Paper, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import pkg from '../../package.json';
import { Small } from './Typography';
export const GET_VERSION = gql`
  query version {
    version {
      version
    }
  }
`;

type Props = PaperProps;
const VersionFooter = ({ ...props }: Props) => {
  const { loading: versionLoading, data: versionData, called: versionCalled } = useQuery(GET_VERSION);
  const [backendVersion, setBackendVersion] = useState('');
  const currentUser = useReactiveVar(currentLoggedUserVar);

  useEffect(() => {
    if (versionCalled && !versionLoading && versionData) {
      setBackendVersion(versionData.version.version);
    }
  }, [versionLoading, versionData, versionCalled]);

  return (
    <Paper component="footer" square variant="outlined" {...props}>
      <Stack py={1} alignItems={'center'}>
        <Small fontSize={8}>Portal Version: {pkg.version}</Small>
        {currentUser && <Small fontSize={8}>Backend Version: {backendVersion}</Small>}
      </Stack>
    </Paper>
  );
};

export default VersionFooter;
