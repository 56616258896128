import ChatBubble from '@/components/ChatBubble/ChatBubble';
import { Caption } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { MessagesByDate, UserTypes } from '@/types/chat';
import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';
import { useEffect, useRef } from 'react';

export type AssistantActionType = { showVisit: boolean; showSummary: boolean; showDrugConditionSummary: boolean };

type ConversationLayoutProps = StackProps & {
  messagesByDate: MessagesByDate;
};

const customParseMessage = (message: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return message.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
};

const conversationWithDateLabel = (messagesByDate: MessagesByDate) =>
  Object.keys(messagesByDate || []).map(key => {
    const chatBubbles = messagesByDate[key]?.map((message, i) => (
      <ChatBubble
        user={{
          avatar: message?.user?.avatar ?? '',
          name: message?.user?.name ?? ''
        }}
        userType={message.type}
        timestamp={message.timestamp}
        message={customParseMessage(message.message)}
        key={key + ' ' + i}
        color={message.type === UserTypes.user ? color.primaryMain : color.grey300}
        textColor={message.type === UserTypes.user ? color.white : color.black100}
        flexDirection={message.type === UserTypes.user ? 'row-reverse' : 'row'}
      />
    ));
    return (
      <Stack
        key={key}
        display="flex"
        flexDirection="column-reverse"
        width="100%"
        height="auto"
        justifyContent="stretch"
        alignItems="stretch"
      >
        {chatBubbles}
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          sx={{
            my: 2
          }}
        >
          <Caption sx={{ color: color.grey600, justifyContent: 'center' }}>{key}</Caption>
        </Stack>
      </Stack>
    );
  });

export const ConversationLayout = ({ messagesByDate, ...props }: ConversationLayoutProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        // added timeout to fix scrollIntoView issue in Chrome
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesByDate]);

  return (
    <Stack
      spacing={1}
      display="flex"
      flexDirection="column-reverse"
      width="100%"
      height="100%"
      maxHeight="100%"
      justifyContent="stretch"
      alignItems="stretch"
      sx={{
        padding: 1,
        overflow: 'auto',
        border: '2px solid',
        borderRadius: 2,
        borderColor: theme => theme.palette.grey[200]
      }}
      {...props}
    >
      <div ref={messagesEndRef}></div>
      {conversationWithDateLabel(messagesByDate)}
    </Stack>
  );
};
