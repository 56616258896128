import { theme } from '@/styles/mui-theme';
import styled from '@emotion/styled';

const InputRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.spacing(2)
}));

export default InputRow;
