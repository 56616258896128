import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { GetMedrecSnapshotDetailResponse } from '@/graphql/medication';
import { GET_MED_REC_SNAPSHOT_DETAIL } from '@/graphql/medication';
import AddMedicationNote from '@/routes/PatientDetails/RemotePatientMonitoring/Components/MedicalReconciliation/AddMedicationNote';
import MedicationDetailsDataGrid from '@/routes/PatientDetails/RemotePatientMonitoring/Components/MedicalReconciliation/MedicationDetailsDataGrid';
import MedicationNotePreview from '@/routes/PatientDetails/RemotePatientMonitoring/Components/MedicalReconciliation/MedicationNotePreview';
import type { Medication } from '@/types/medication';
import type { medicationType } from '@/types/remotePatientMonitoring';
import { useLazyQuery } from '@apollo/client';
import { DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  truentityId: string | undefined;
  medrecSnapshotId: string;
  hideDialog: () => void;
};

export enum DialogTypes {
  DEFAULT = 'DEFAULT',
  FORM = 'FORM',
  PREVIEW = 'PREVIEW'
}

const MedicationReconciliationDetailDialog = ({ title, truentityId, medrecSnapshotId, hideDialog, ...props }: Props) => {
  const [getInfo, { data, loading, called, refetch }] = useLazyQuery<GetMedrecSnapshotDetailResponse>(GET_MED_REC_SNAPSHOT_DETAIL, {
    fetchPolicy: 'cache-and-network'
  });

  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [dialogType, setDialogType] = useState<DialogTypes>(DialogTypes.DEFAULT);
  const [medicationDetails, setMedicationDetails] = useState<medicationType>({} as medicationType);
  const [medRecSnapshotDetail, setMedRecSnapshotDetail] = useState<Medication[]>([]);

  const switchToNewDialog = (medicationId: string, medicationTitle: string, note: string) => {
    setDialogType(DialogTypes.PREVIEW);
    setMedicationDetails({ id: medicationId, title: medicationTitle, note });
  };

  const switchToModal = (modalType: DialogTypes) => setDialogType(modalType);

  const renderDialogContent = () => {
    if (dialogType === DialogTypes.DEFAULT) {
      return (
        <MedicationDetailsDataGrid
          medRecSnapshotDetail={medRecSnapshotDetail}
          totalRowCount={totalRowCount}
          currentPage={currentPage}
          loading={loading}
          setCurrentPage={setCurrentPage}
          switchToNewDialog={switchToNewDialog}
        />
      );
    } else if (dialogType === DialogTypes.FORM) {
      return (
        <AddMedicationNote
          switchToModal={switchToModal}
          medicationDetails={medicationDetails}
          setMedicationDetails={setMedicationDetails}
          refetch={refetch}
        />
      );
    } else {
      return <MedicationNotePreview medicationDetails={medicationDetails} switchToModal={switchToModal} />;
    }
  };

  useEffect(() => {
    getInfo({
      variables: {
        medrecSnapshotId: medrecSnapshotId,
        truentityId: truentityId,
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, [currentPage, medrecSnapshotId]);

  useEffect(() => {
    if (!loading && data?.medrecSnapshot?.accountMedications) {
      const accountMedications = data?.medrecSnapshot?.accountMedications;
      const meta = data?.medrecSnapshot?.meta;
      setMedRecSnapshotDetail(accountMedications);
      setTotalRowCount(meta?.totalCount || 0);
    } else {
      setMedRecSnapshotDetail([]);
    }
  }, [loading, data, called]);

  return (
    <BaseDialog
      title={
        dialogType === DialogTypes.DEFAULT
          ? `Medication Reconciliation Details - ${title}`
          : dialogType === DialogTypes.FORM
          ? 'Add Notes'
          : 'Note'
      }
      {...(dialogType !== DialogTypes.PREVIEW ? { hideDialog } : {})}
      {...(dialogType === DialogTypes.PREVIEW
        ? { onClickBack: () => switchToModal(DialogTypes.DEFAULT) }
        : dialogType === DialogTypes.FORM
        ? { onClickBack: () => switchToModal(DialogTypes.PREVIEW) }
        : {})}
      fullWidth
      maxWidth={dialogType === DialogTypes.FORM ? 'sm' : 'lg'}
      canNavigateBack={true}
      {...(medicationDetails.note && medicationDetails.note.trim().length > 0
        ? {
            headerElement: (
              <Button
                variant="contained"
                disableElevation
                fullWidth={false}
                sx={{ position: 'absolute', right: '24px', top: 8 }}
                onClick={() => switchToModal(DialogTypes.FORM)}
              >
                Edit
              </Button>
            )
          }
        : {})}
      {...props}
    >
      <DialogContent>{renderDialogContent()}</DialogContent>
    </BaseDialog>
  );
};

export default MedicationReconciliationDetailDialog;
