import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import HealthPlanAddDialog from '@/components/Dialogs/HealthPlanAddDialog';
import HealthPlanUpdateDialog from '@/components/Dialogs/HealthPlanUpdateDialog';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import { GET_HEALTH_PLANS } from '@/graphql/administration';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { useLazyQuery } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect, useMemo, useState } from 'react';

type Data = {
  id: string;
  orgName: string;
};

const HealthPlans = () => {
  const [getHealthPlans, { loading, data, called }] = useLazyQuery(GET_HEALTH_PLANS);
  const { reloadAccounts, setReloadAccounts, setContent } = useContext(SideMenuContext);
  const [currentPage, setCurrentPage] = useState(0);
  const { roleType } = useToken();
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const { showModal } = useModal();
  const [healthPlans, setHealthPlans] = useState<Data[]>([]);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, []);

  const isDataLoaded = () => {
    return called && !loading && data && data.healthPlans.healthPlans;
  };

  const updateData = data => {
    setCurrentPage(data.page);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'orgName',
        headerName: 'Health Plans',
        sortable: true,
        flex: 1,
        align: 'left'
      },
      {
        field: 'action',
        headerName: 'Edit',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        valueGetter: params => {
          return [params.row?.id, params.row?.orgName].join(',');
        },
        renderCell: value => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton onClick={() => showEditModal(value.value)}>
                <EditIcon />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [showEditModal]
  );

  useEffect(() => {
    getHealthPlans({
      variables: {
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, [currentPage, reloadAccounts]);

  useEffect(() => {
    if (isDataLoaded()) {
      setHealthPlans(data.healthPlans.healthPlans);
      setRowCount(data.healthPlans.meta.totalCount);
    }
  }, [isDataLoaded()]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const showEditModal = id => {
    const modalRef = showModal(HealthPlanUpdateDialog, {
      title: 'Edit Health Plan',
      hideDialog: () => {
        modalRef.hide();
        setReloadAccounts(new Date());
      },
      id: id
    });
  };

  const showAddModal = () => {
    const modalRef = showModal(HealthPlanAddDialog, {
      title: 'Add Health Plan',
      hideDialog: () => {
        modalRef.hide();
        setReloadAccounts(new Date());
      }
    });
  };

  return (
    <Stack spacing={1}>
      <Grid
        container
        spacing={2}
        key={'search-bar'}
        alignItems="center"
        style={{ display: isSuperAdmin || isPharmacistAdmin ? 'flex' : 'none' }}
      >
        <Grid item xs={12}>
          <Stack spacing={2} alignItems={'center'} direction="row" justifyContent={'flex-end'}>
            <Button label="Add Health Plan" onClick={() => showAddModal()} />
          </Stack>
        </Grid>
      </Grid>

      <Paper component={Grid} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-health-plan'}
            autoHeight
            rows={healthPlans}
            rowCount={rowCountState}
            loading={loading}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            paginationMode="server"
            disableRowSelectionOnClick
            onPaginationModelChange={updateData}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default HealthPlans;
