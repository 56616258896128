import { Stack } from '@mui/material';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => <FormControlLabel {...props} />)(({ theme, checked }) => ({
  '.MuiFormControlLabel-label': checked && {
    color: theme.palette.primary.main
  }
}));

function MyFormControlLabel(props: FormControlLabelProps) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

export default function UseRadioGroup({ selectedFilter, handleRadioButtonChange }) {
  return (
    <>
      <Stack direction="row" alignItems={'center'} sx={{ width: '100%', paddingLeft: 2 }} spacing={2}>
        <RadioGroup name="use-radio-group" value={selectedFilter} onChange={handleRadioButtonChange} row>
          <MyFormControlLabel value="All" label="All" control={<Radio />} />
          <MyFormControlLabel value="Assigned" label="Assigned" control={<Radio />} />
          <MyFormControlLabel value="Unassigned" label="Unassigned" control={<Radio />} />
        </RadioGroup>
      </Stack>
    </>
  );
}
