import { PDFViewer } from '@/routes/PatientDetails/DischargeReports/Summary';
import { DialogContent } from '@mui/material';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  pdfUrl: string;
};

const PdfDialog = ({ title, pdfUrl, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} fullWidth maxWidth="lg">
      <DialogContent
        sx={{
          overflowY: 'hidden'
        }}
      >
        <PDFViewer src={pdfUrl} />
      </DialogContent>
    </BaseDialog>
  );
};

export default PdfDialog;
