import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';
import Button from '../Button';
import AddUserDialog from '../Dialogs/AddUserDialog';

type UserAddProps = {
  onUserAdd?: () => void;
};

const UserAdd = ({ onUserAdd }: UserAddProps) => {
  const { showModal } = useModal();

  const showAddUserDialog = () => {
    const modal = showModal(AddUserDialog, {
      title: 'Add User',
      hideDialog: () => {
        modal.hide();
      },
      onUserAdd: () => {
        onUserAdd && onUserAdd();
        modal.hide();
      }
    });
  };

  return <Button startIcon={<AddIcon />} label="Add User" onClick={showAddUserDialog} />;
};

export default UserAdd;
