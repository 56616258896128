import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { Body1, Small } from '@/components/Typography';
import { GetPatientDetailResponse, GET_PATIENT_DETAIL } from '@/graphql/account';
import type { GetMedConsultsResponse } from '@/graphql/med-consult';
import { GET_MED_CONSULTATIONS } from '@/graphql/med-consult';
import type { Medication } from '@/types/medication';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { formatDateAndTime } from '@/util/format';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import type { SelectChangeEvent } from '@mui/material';
import { Grid, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import Alert from '../Alert';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  truentityId: any;
  hideDialog: () => void;
  handleNewMedRec: any;
  verifiedMeds: Medication[];
};

const ADD_MED_REC_SNAPSHOT = gql`
  mutation saveMedrecSnapshot($truentityId: String!, $name: String!, $description: String!, $medConsultIds: [String!]) {
    saveMedrecSnapshot(truentityId: $truentityId, name: $name, description: $description, medConsultIds: $medConsultIds) {
      medrecSnapshotTimestamp
      medrecSnapshotId
    }
  }
`;

export type FormValues = {
  name: string;
  description: string;
  medConsultIds?: string[];
};

type KeyValuePair = {
  label: string;
  value: string;
};

const MedRecDialog = ({ truentityId, title, handleNewMedRec, verifiedMeds, hideDialog, ...props }: Props) => {
  const [selectedMedConsultation, setSelectedMedConsultation] = useState<string[]>([]);
  const [patientRpmStatus, setPatientRpmStatus] = useState<string | undefined>('');
  const defaultValues: FormValues = {
    name: '',
    description: '',
    medConsultIds: []
  };

  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const [saveMedrecSnapshot] = useMutation(ADD_MED_REC_SNAPSHOT);
  const { data: medConsultationsData } = useQuery<GetMedConsultsResponse>(GET_MED_CONSULTATIONS, {
    variables: {
      truentityId,
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });
  const [getPatientDetail] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL);

  const [medConsultations, setMedConsultations] = useState<KeyValuePair[]>([]);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitMedRec(data);

  useEffect(() => {
    if (medConsultationsData && medConsultationsData.medConsults) {
      const medConsults = medConsultationsData.medConsults.medConsults;
      const filtered = medConsults.map(consult => {
        const consult_label = consult.title + ', Conducted on: ' + formatDateAndTime(consult.startTime);
        const obj = {
          label: consult_label,
          value: consult.id
        };
        return obj;
      });
      setMedConsultations(filtered);
    }
  }, [medConsultationsData]);

  const handleSubmitMedRec = async (values: FormValues) => {
    values.medConsultIds = selectedMedConsultation || [];

    try {
      const result = await saveMedrecSnapshot({
        variables: {
          ...values,
          truentityId
        }
      });

      if (result.data!.saveMedrecSnapshot) {
        if (
          patientRpmStatus === RpmStatusTypes.ENROLLED ||
          patientRpmStatus === RpmStatusTypes.IS_CANDIDATE ||
          patientRpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT
        ) {
          handleNewMedRec(true, truentityId, result.data!.saveMedrecSnapshot.medrecSnapshotId, true);
        }
        handleNewMedRec(true, truentityId, result.data!.saveMedrecSnapshot.medrecSnapshotId);
      } else {
        handleNewMedRec(false);
      }
    } catch (err) {
      handleNewMedRec(false);
    } finally {
      hideDialog();
    }
  };

  const onConsultSelected = (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event;

    setSelectedMedConsultation(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    if (truentityId) {
      getPatientDetail({
        variables: {
          truentityId: truentityId
        }
      })
        .then(response => {
          if (!response.data?.accountGet) {
            throw new Error('Unable to find patient');
          }
          setPatientRpmStatus(response.data?.accountGet?.rpmStatus);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [truentityId]);

  const isEnabled = verifiedMeds?.length > 0;

  return (
    <BaseDialog canNavigateBack={false} title={title} hideDialog={hideDialog} {...props} maxWidth={'md'} fullWidth={true}>
      <DialogContent>
        {!isEnabled && (
          <Stack mb={1}>
            <Alert
              status="warning"
              title="No Verified Meds"
              description="You can only lock and certify a medrec if there are verified meds"
            />
          </Stack>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <Stack>
                <Body1 css={{ marginBottom: '10px' }}>
                  Create a Truentity MedRec Snapshot of all <b>verified</b> medications
                </Body1>

                <TruentityTextField
                  {...register('name')}
                  disabled={!isEnabled}
                  type="text"
                  label="Name"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  required
                />

                <TruentityTextField
                  {...register('description')}
                  disabled={!isEnabled}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows={6}
                  inputProps={{ maxLength: 500 }}
                  label="Description (optional)"
                />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" alignItems={'center'} justifyContent="space-between" spacing={2}>
                <Body1 fontWeight={'bold'}>Verified Medications</Body1>
                <Small>Total: {verifiedMeds.length}</Small>
              </Stack>
              <Stack sx={{ marginTop: '10px', overflow: 'auto', maxHeight: '80vh' }}>
                <ul>
                  {verifiedMeds.map((med: Medication, index: number) => (
                    <Small css={{ marginBottom: '10px' }} key={index}>
                      {' '}
                      {med.displayName}
                    </Small>
                  ))}
                </ul>
              </Stack>
            </Grid>
          </Grid>

          <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
            <Button disabled={!isEnabled} type="submit" a11yLabel="Submit" appearance="primary" />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default MedRecDialog;
