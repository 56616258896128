import { UPDATE_RELYING_PARTY_ADMIN_CHECKLIST } from '@/graphql/checklists';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Paper, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ReactNode, useEffect, useState } from 'react';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  id: string;
  title: string;
  checklistNote: string;
  reloadChecklist: () => void;
};

const ChecklistNodeDialog = ({ id, title, checklistNote, hideDialog, reloadChecklist, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [note, setNote] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [updateCheckListForAdmin, { loading: isLoading }] = useMutation(UPDATE_RELYING_PARTY_ADMIN_CHECKLIST);

  const handleTextField = (value: string) => {
    setNote(value);
  };

  const handleSaveButton = () => {
    if (note.trim().length === 0) {
      enqueueSnackbar(`Please add something for ${title} note`, { variant: 'warning' });
      return;
    }

    updateCheckListForAdmin({ variables: { relyingPartyAdminChecklistId: id, notes: note } })
      .then(res => {
        const { status } = res.data.updateRelyingPartyAdminChecklist;
        if (status === 'Success') {
          if (hideDialog) hideDialog();
          reloadChecklist();
          enqueueSnackbar(`${title} note updated successfully`, { variant: 'success' });
        }
      })
      .catch(err => {
        enqueueSnackbar(`Can not update ${title} note`, { variant: 'error' });
        if (hideDialog) hideDialog();
      });
  };

  const renderHeaderElement = (): ReactNode => {
    if (!isEditMode) {
      return (
        <Button variant="contained" onClick={() => setIsEditMode(true)} size="small">
          Edit Note
        </Button>
      );
    } else return null;
  };

  useEffect(() => {
    if (checklistNote === null || checklistNote.trim().length === 0) {
      setIsEditMode(true);
    } else {
      setNote(checklistNote);
      setIsEditMode(false);
    }
  }, [checklistNote]);

  return (
    <BaseDialog {...props} onClose={hideDialog} title={title} fullWidth maxWidth="md" headerElement={renderHeaderElement()}>
      <DialogContent>
        <Paper elevation={0} sx={{ p: 1 }}>
          <TextField
            onChange={event => {
              const value = event.target.value;
              if (value) handleTextField(value);
            }}
            value={note}
            multiline
            rows={6}
            placeholder="Type your note here"
            id="note"
            label="Note"
            autoFocus
            InputProps={{
              readOnly: !isEditMode
            }}
            sx={{ width: '100%' }}
          />
        </Paper>
        <DialogActions>
          {isEditMode ? (
            <Button isLoading={isLoading} appearance="primary" onClick={handleSaveButton}>
              Save
            </Button>
          ) : null}
          <Button disabled={isLoading} appearance="outline" onClick={hideDialog}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default ChecklistNodeDialog;
