export class Stack<T extends NonNullable<unknown>> {
  private maxSize: number;
  private stackKey: string;
  private map: { [k: string]: boolean };

  constructor(maxSize = 1000, stackKey = 'default_key') {
    this.maxSize = maxSize;
    this.stackKey = stackKey;
    this.map = {};
    if (!localStorage.getItem(this.stackKey)) {
      localStorage.setItem(this.stackKey, '[]');
    } else {
      const obj = this.getItems();
      obj.forEach(item => (this.map[JSON.stringify(item)] = true));
    }
  }

  push(item: T) {
    const itemKey = JSON.stringify(item);
    if (this.map[itemKey]) {
      console.log('Item already exists in stack');
      return;
    }

    const stack = this.getItems();

    if (stack.length >= this.maxSize) {
      const removedItem = stack.pop();
      if (removedItem) {
        delete this.map[JSON.stringify(removedItem)];
      }
    }

    stack.unshift(item);
    this.map[itemKey] = true;
    this.setItems(stack);
  }

  pop(): T | null {
    const stack = this.getItems();
    const item = stack.pop();

    if (item) {
      delete this.map[JSON.stringify(item)];
    }

    this.setItems(stack);
    return item || null;
  }

  getItems(): T[] {
    return JSON.parse(localStorage.getItem(this.stackKey) || '[]');
  }

  setItems(items: T[]) {
    localStorage.setItem(this.stackKey, JSON.stringify(items));
  }
}
