import type { PatientsData } from '@/routes/Patients/patients';
import { gql } from '@apollo/client';

export type AccountList = {
  id: string;
  name: string;
  description: string;
  isShared: boolean;
  accounts: Partial<PatientsData>[];
};
export type AccountListsResponse = {
  getAllAccountLists: {
    accountLists: AccountList[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_ALL_ACCOUNTS_LIST = gql`
  query GetAllAccountLists($pageNum: Int, $pageSize: Int) {
    getAllAccountLists(pageNum: $pageNum, pageSize: $pageSize) {
      accountLists {
        id
        name
        description
        isShared
        accounts {
          id
          truentityId
          phone
          birthDate
          user {
            firstName
            middleName
            lastName
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_LIST_BY_ID = gql`
  query GetAccountListById($accountListId: ID!) {
    getAccountListById(accountListId: $accountListId) {
      id
      name
      description
      isShared
      accounts {
        id
        user {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT_LIST = gql`
  mutation CreateAccountList($name: String!, $description: String, $isShared: Boolean) {
    createAccountList(name: $name, description: $description, isShared: $isShared) {
      status
      message
    }
  }
`;

export const REMOVE_ACCOUNT_LIST = gql`
  mutation RemoveAccountList($accountListId: ID!) {
    removeAccountList(accountListId: $accountListId) {
      status
      message
    }
  }
`;

export const ADD_ACCOUNT_TO_ACCOUNT_LIST = gql`
  mutation AddAccountsToAccountList($accountListId: ID!, $truentityIds: [ID!]!) {
    addAccountsToAccountList(accountListId: $accountListId, truentityIds: $truentityIds) {
      status
      message
    }
  }
`;

export const REMOVE_ACCOUNT_FROM_ACCOUNT_LIST = gql`
  mutation RemoveAccountsFromAccountList($accountListId: ID!, $truentityIds: [ID!]!) {
    removeAccountsFromAccountList(accountListId: $accountListId, truentityIds: $truentityIds) {
      status
      message
    }
  }
`;
