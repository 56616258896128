import SideMenuContext from '@/context/sideMenuContext';
import { Box, IconButton, List, ListSubheader, Paper, Stack, ToggleButton } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//TODO:  tidy up so at least the back button is not constantly repeated.  Ideally we tidy up the menu system
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import CollapsibleListGroup from '@/components/CollapsibleListGroup';
import type { AssistantActionType } from '@/components/ConversationLayout';

import { PdfDialog } from '@/components/Dialogs';
import { MessageDrawer } from '@/components/MessageDrawer/MessageDrawer';
import type { NavigationSidebarMenuItem } from '@/components/SideMenuTree';
import SideMenuTree from '@/components/SideMenuTree';
import { H3, H4 } from '@/components/Typography';
import DataSourceItem from '@/routes/Patients/DataSourceItem';
import { color } from '@/styles/assets/colors';
import type { ConversationMessage } from '@/types/chat';
import { AssistantActions, UserTypes } from '@/types/chat';
import type { TruentityDateInput } from '@/types/date';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { groupBy } from '@/util/array';
import { getDateStringForMessage } from '@/util/date';
import { addMinutes, formatDate, formatDateAndTime, formatTime, removeMinutes } from '@/util/format';
import { dateCompare } from '@/util/sort';
import { useReactiveVar } from '@apollo/client';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssistantIcon from '@mui/icons-material/Assistant';
import DeleteIcon from '@mui/icons-material/Delete';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import { useModal } from 'mui-modal-provider';
import { GeneratedInsights } from './GeneratedInsights';
import type { InsightConfig, InsightsType } from './insights-types';
import * as testDataImp from './test.json';

const testData: InsightsType = testDataImp['default'] as InsightsType;

const parseDataSources = () => {
  const parsedSources = new Map<string, InsightConfig[]>();

  for (const item of testData.insightConfigs!) {
    if (!parsedSources[item.type]) {
      parsedSources[item.type] = [];
    }
    parsedSources[item.type].push(item);
  }

  return parsedSources;
};

const messageIsSimilar = (searchTerm: string, prompt: string) => {
  return prompt.toLowerCase().includes(searchTerm.toLowerCase());
};

const CANNED_MESSAGES = {
  Summarize: "Please summarize Patient's longitudinal data for me",
  ProviderVisit: 'Include all recent Provider visits',
  DrugInteractions: `Are there any drug interactions indicated in this patient's health data`
};

export type PatientInsightHistoryType = {
  id: number;
  startedAt: TruentityDateInput;
  endedAt: TruentityDateInput;
  actions: AssistantActionType;
};

const now = new Date();

const DEFAULT_SESSION = {
  actions: { showVisit: false, showSummary: false, showDrugConditionSummary: false },
  endedAt: null,
  startedAt: now
};

const testHistory: PatientInsightHistoryType[] = [
  {
    id: 1,
    startedAt: removeMinutes(now, 10),
    endedAt: now,
    actions: {
      showVisit: true,
      showSummary: false,
      showDrugConditionSummary: false
    }
  },
  {
    id: 2,
    startedAt: removeMinutes(now, 4000),
    endedAt: removeMinutes(now, 3950),
    actions: {
      showVisit: false,
      showSummary: true,
      showDrugConditionSummary: false
    }
  },
  {
    id: 3,
    startedAt: removeMinutes(now, 1010),
    endedAt: removeMinutes(now, 1000),
    actions: {
      showVisit: true,
      showSummary: true,
      showDrugConditionSummary: false
    }
  }
];

let currentDate = new Date();
const updateDate = () => {
  currentDate = addMinutes(currentDate, 1);
  return currentDate;
};

const PatientInsights = () => {
  const [activeSession, setActiveSession] = useState<PatientInsightHistoryType | null>(null);
  const [previousInsight, setPreviousInsight] = useState<PatientInsightHistoryType | null>(null);
  const [recentInsights, setRecentInsights] = useState<PatientInsightHistoryType[]>(testHistory);
  const [showDataSources, setShowDataSources] = useState(false);
  const [showInsights, setShowInsights] = useState(false);

  const [selectedDataSources, setSelectedDataSources] = useState<InsightConfig[]>([]);

  const { setContent } = useContext(SideMenuContext);
  const navigate = useNavigate();
  const { showModal } = useModal();

  const [sideMenuNavItems, setSideMenuNavItems] = useState<NavigationSidebarMenuItem[]>([]);

  const [messages, setMessages] = useState<ConversationMessage[]>([]);
  const [messagesByDate, setMessagesByDate] = useState<{
    [key: string]: ConversationMessage[];
  }>({});

  const [dataSources] = useState<Map<string, InsightConfig[]>>(parseDataSources());
  const [inputValue, setInputValue] = useState('');
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const showPdf = (item: InsightConfig) => {
    const modalRef = showModal(PdfDialog, {
      title: item.label,
      pdfUrl: item.document,
      hideDialog: () => {
        modalRef.hide();
      }
    });
  };

  const showPreviousInsight = (item: PatientInsightHistoryType) => {
    setPreviousInsight(item);
  };

  const renderNavItems = useCallback((): NavigationSidebarMenuItem[] => {
    const Icon = () => {
      return <AccessTimeIcon fontSize="small" />;
    };

    const getLabel = (item: PatientInsightHistoryType) => {
      if (activeSession?.id === item.id) {
        return 'Current Session';
      }

      let labelText = formatTime(item.startedAt);
      if (item.endedAt) {
        labelText += ' - ' + formatTime(item.endedAt);
      }

      return labelText;
    };

    //TODO: combine the groupBy logic below into its own helper method
    const sortedItems = [...recentInsights].sort(dateCompare('startedAt', true));
    const groupedByDate = groupBy<PatientInsightHistoryType>(sortedItems, m => formatDate(m.startedAt));
    const entries = Object.entries(groupedByDate);

    return entries.map(([date, children]) => {
      return {
        labelText: date,
        labelInfo: children.length.toString(),
        items: children.map(item => ({
          labelText: getLabel(item),
          labelIcon: Icon,
          onClick: () => showPreviousInsight(item)
        }))
      };
    });
  }, [activeSession?.id, recentInsights]);

  const SideMenuContent = useCallback(() => {
    return (
      <List component="nav">
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <ListSubheader>Insights</ListSubheader>
          <CollapsibleListGroup defaultOpen={true} primaryText="Recent" icon={<ListRoundedIcon />}>
            <SideMenuTree index={0} items={sideMenuNavItems} />
          </CollapsibleListGroup>
        </Box>
      </List>
    );
  }, [navigate, sideMenuNavItems]);

  const handleOnGenerate = (prompt: ConversationMessage): Promise<boolean> => {
    if (previousInsight) {
      setPreviousInsight(null);
    }

    if (!activeSession) {
      setActiveSession({
        id: recentInsights.length + 1,
        ...DEFAULT_SESSION
      });
    }

    return new Promise(resolve => {
      setTimeout(() => {
        switch (prompt.action) {
          case AssistantActions.showSummary:
            setActiveSession(session => ({
              ...session!,
              actions: {
                ...session!.actions,
                showSummary: true
              }
            }));
            break;
          case AssistantActions.showVisit:
            setActiveSession(session => ({
              ...session!,
              actions: {
                ...session!.actions,
                showVisit: true
              }
            }));
            break;

          case AssistantActions.showDrugConditionSummary:
            setActiveSession(session => ({
              ...session!,
              actions: {
                ...session!.actions,
                showDrugConditionSummary: true
              }
            }));
            break;
        }

        setShowInsights(false);
        return resolve(true);
      }, 2000);
    });
  };

  const handleOnClear = () => {
    setActiveSession(null);
    setShowInsights(false);
  };

  const handleOnKeep = () => {
    const exists = recentInsights.find(f => f.id === activeSession?.id);

    if (exists) {
      setRecentInsights(
        recentInsights.map(item =>
          item.id === activeSession?.id ? Object.assign({}, item, { ...activeSession }, { endedAt: new Date() }) : item
        )
      );
    } else if (activeSession) {
      const session = { ...activeSession, endedAt: new Date() };
      setRecentInsights(prev => [...prev, session]);
    }

    setShowInsights(false);
  };

  useEffect(() => {
    setContent(<SideMenuContent />);
  }, [SideMenuContent, sideMenuNavItems, setContent]);

  useEffect(() => {
    setSideMenuNavItems(renderNavItems());
  }, [renderNavItems]);

  useEffect(() => {
    const sortedItems = messages.sort(dateCompare('createdAt', false));
    const groupedByDate = groupBy<ConversationMessage>(sortedItems, m => getDateStringForMessage(m.createdAt));
    setMessagesByDate(groupedByDate);
  }, [messages]);

  const onSourceItemClick = (item: InsightConfig) => {
    setSelectedDataSources(state => {
      return state.includes(item) ? state.filter(f => f !== item) : [...state, item];
    });
  };

  const fakeResponse = (cb, resolveTimeout = 1000): Promise<boolean> => {
    return new Promise(resolve => {
      window.setTimeout(() => {
        const message = cb();
        setMessages(messages => [...messages, message]);
        window.setTimeout(() => {
          resolve(!message.action || message.action !== AssistantActions.failed);
        }, resolveTimeout);
      }, 1000);
    });
  };

  const generateFakeBotResponseMessage = (message: string) => {
    currentDate = updateDate();
    let response: ConversationMessage = {
      id: `${currentDate.getTime()}`,
      type: UserTypes.bot,
      message: `Sorry, I did not understand`,
      action: AssistantActions.failed,
      user: { avatar: 'Bot', name: 'Bot' },
      timestamp: currentDate.getTime(),
      createdAt: currentDate
    };

    currentDate = updateDate();

    switch (true) {
      case messageIsSimilar(message, CANNED_MESSAGES.Summarize):
        response = {
          id: `${currentDate.getTime()}`,
          type: UserTypes.bot,
          message: `Generating summary...`,
          action: AssistantActions.showSummary,
          user: { avatar: 'Bot', name: 'Bot' },
          timestamp: currentDate.getTime(),
          createdAt: currentDate
        };
        break;

      case messageIsSimilar(message, CANNED_MESSAGES.ProviderVisit):
        response = {
          id: `${currentDate.getTime()}`,
          type: UserTypes.bot,
          message: `OK, one moment...`,
          action: AssistantActions.showVisit,
          user: { avatar: 'Bot', name: 'Bot' },
          timestamp: currentDate.getTime(),
          createdAt: currentDate
        };
        break;

      case messageIsSimilar(message, CANNED_MESSAGES.DrugInteractions):
        response = {
          id: `${currentDate.getTime()}`,
          type: UserTypes.bot,
          message: `OK, one moment...`,
          action: AssistantActions.showDrugConditionSummary,
          user: { avatar: 'Bot', name: 'Bot' },
          timestamp: currentDate.getTime(),
          createdAt: currentDate
        };
        break;
    }

    return response;
  };

  return (
    <Paper component={Stack} direction="column" spacing={1} p={2}>
      <Stack direction="row" p={5} sx={{ justifyContent: 'space-between' }}>
        <H3>Patient Insights</H3>
        <Stack direction="row" spacing={1} alignItems={'flex-end'}>
          <Button sx={{ textTransform: 'none' }} startIcon={<AssistantIcon />} label="TruentityGPT" onClick={() => setShowInsights(true)} />
        </Stack>
      </Stack>

      {previousInsight && previousInsight?.startedAt !== activeSession?.startedAt && (
        <Alert status={'info'} description={`Insight from: ${formatDateAndTime(previousInsight?.startedAt)}`} />
      )}

      <GeneratedInsights
        data={testData}
        showVisits={previousInsight?.actions.showVisit || (!previousInsight && activeSession?.actions.showVisit) || false}
        showSummary={previousInsight?.actions.showSummary || (!previousInsight && activeSession?.actions.showSummary) || false}
        showDrugCondition={
          previousInsight?.actions.showDrugConditionSummary ||
          (!previousInsight && activeSession?.actions.showDrugConditionSummary) ||
          false
        }
      />

      <MessageDrawer
        readOnly={false}
        inputLabel={'How can I help?'}
        onClose={() => setShowInsights(false)}
        open={showInsights}
        onSubmitCallback={async values => {
          currentDate = updateDate();

          setMessages(messages => [
            ...messages,
            {
              id: `${currentDate.getTime()}`,
              type: UserTypes.user,
              message: values.message,
              user: {
                avatar: getAccountUserFullName(currentUser?.user),
                name: getAccountUserFullName(currentUser?.user)
              },
              timestamp: currentDate.getTime(),
              createdAt: currentDate
            }
          ]);

          const response = generateFakeBotResponseMessage(values.message);
          const fakedResponse = await fakeResponse(() => response);
          if (fakedResponse) {
            const result = await handleOnGenerate(response);
            if (result) {
              currentDate = updateDate();

              await fakeResponse(
                () => ({
                  id: `${currentDate.getTime()}`,
                  timestamp: currentDate.getTime(),
                  createdAt: currentDate,
                  type: UserTypes.bot,
                  message: `Generated`,
                  user: { avatar: 'Bot', name: 'Bot' }
                }),
                3000
              );
            }
          }
        }}
        messagesByDate={messagesByDate}
        inputMessage={inputValue}
        inputControlsLeft={
          <Stack direction={'row'}>
            <IconButton disabled={messages.length === 0} onClick={() => handleOnKeep()} title="Keep">
              <SaveIcon fontSize="medium" />
            </IconButton>
            <IconButton
              disabled={messages.length === 0}
              onClick={() => {
                setMessages([]);
                handleOnClear();
              }}
              type="reset"
              title="Reset"
            >
              <DeleteIcon fontSize="medium" />
            </IconButton>
          </Stack>
        }
        header={
          <Stack spacing={4}>
            <H3>TruentityGPT</H3>
            <Stack direction={'row'} justifyContent="space-between" spacing={1} flex={1}>
              <ToggleButton
                size={'small'}
                sx={{ borderRadius: 2, color: color.primaryMain, borderColor: color.primaryMain }}
                value={'data sources'}
                selected={showDataSources}
                onChange={() => setShowDataSources(!showDataSources)}
              >
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <SettingsIcon color={'inherit'} />
                  <Stack>Data Source</Stack>
                </Stack>
              </ToggleButton>

              <Button
                size={'small'}
                label="Summarize"
                disabled={selectedDataSources.length === 0}
                onClick={() => setInputValue(CANNED_MESSAGES.Summarize)}
              />
            </Stack>
          </Stack>
        }
        sidePanel={
          showDataSources && (
            <Stack px={4} mb={5} justifyContent="flex-end">
              <Stack flex={1} spacing={6} py={4} sx={{ overflow: 'auto' }}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <H3>Configure Data Sources</H3>
                </Stack>
                {Object.values(dataSources).map((item: InsightConfig, i) => (
                  <Stack spacing={1} key={i}>
                    <H4>{item[0].type}</H4>
                    <Stack direction={'row'} spacing={1} flexWrap={'wrap'}>
                      {Object.values(item).map((subItem: InsightConfig, j) => (
                        <DataSourceItem
                          key={`${subItem.type}-${j}`}
                          showPdf={() => showPdf(subItem)}
                          onClick={onSourceItemClick}
                          item={subItem}
                          selected={selectedDataSources.includes(subItem)}
                        />
                      ))}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              <Button variant={'contained'} color={'primary'} label={'Close'} onClick={() => setShowDataSources(false)} />
            </Stack>
          )
        }
      />
    </Paper>
  );
};

export default PatientInsights;
