import { Body2, Caption } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TreeView } from '@mui/lab';
import type { TreeItemProps } from '@mui/lab/TreeItem';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import type { SvgIconProps } from '@mui/material';
import { Box } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import type { State, To } from 'history';
import type React from 'react';
import { useState } from 'react';

declare module 'react' {
  // eslint-disable-next-line no-unused-vars
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon?: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
  additionalLabelText?: string;
  labelIconColor?: string;
};

const StyledTreeItemRoot = MuiStyled(TreeItem)(({ theme }) => ({
  paddingLeft: '8px',

  [`& .${treeItemClasses.content}`]: {
    borderLeft: '5px solid transparent',

    [`& .${treeItemClasses.label}`]: {
      svg: {
        marginRight: theme.spacing(2)
      }
    },

    [`&.${treeItemClasses.disabled}`]: {
      pointerEvents: 'none'
    }
  },
  [`& .${treeItemClasses.group}`]: {
    [`& .${treeItemClasses.content}`]: {
      '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        borderColor: `${theme.palette.primary.main}`,

        '&.Mui-expanded': {
          borderColor: 'transparent'
        }
      },

      [`& .${treeItemClasses.label}`]: {
        wordBreak: 'break-word',
        textTransform: 'capitalize',

        svg: {
          marginRight: theme.spacing(1)
        }
      }
    }
  }
}));

const StyledTreeItem = (props: StyledTreeItemProps) => {
  const { bgColor, color: itemColor, labelIcon, labelInfo, labelText, additionalLabelText, labelIconColor, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 1, pb: 1 }}>
          <Box component={labelIcon} color={labelIconColor} sx={{ mr: 1 }} />
          <Body2 sx={{ fontWeight: 'inherit', flexGrow: 1, pr: 1, color: itemColor ?? color.grey300 }}>
            {labelText}{' '}
            <span
              style={{
                fontWeight: 900,
                fontStyle: 'italic',
                textDecoration: 'underlined'
              }}
            >
              {additionalLabelText}
            </span>
          </Body2>
          <Caption
            variant="caption"
            sx={{
              color: itemColor ?? color.grey300
            }}
          >
            {labelInfo}
          </Caption>
        </Box>
      }
      style={{
        '--tree-view-color': itemColor,
        '--tree-view-bg-color': bgColor
      }}
      {...other}
    />
  );
};

export type NavigationSidebarMenuItem = Omit<StyledTreeItemProps, 'nodeId'> & {
  to?: To;
  state?: State;
  items?: NavigationSidebarMenuItem[];
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEventHandler<HTMLLIElement>) => void;
};

type Props = {
  items: NavigationSidebarMenuItem[];
  collapsed?: boolean;
  index: number;
};

const TreeItems = ({ items, collapsed, index }: Props) => {
  return (
    <>
      {items.map((item, cindex) => {
        const key = `${++index}.${cindex}`;

        return (
          <StyledTreeItem
            key={key}
            labelText={item.labelText}
            additionalLabelText={item.additionalLabelText}
            title={item.labelText}
            labelIcon={item.labelIcon}
            labelIconColor={item.labelIconColor}
            labelInfo={item.labelInfo}
            bgColor={item.bgColor}
            color={item.color}
            nodeId={key}
            onClick={item.onClick}
            className={collapsed ? 'collapsed' : ''}
            disabled={item.disabled}
          >
            {item.items && <TreeItems items={item.items} index={index} />}
          </StyledTreeItem>
        );
      })}
    </>
  );
};

const SideMenuTree = ({ items, ...props }: Props) => {
  const [expanded, setExpanded] = useState<string[]>(['1.0']);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  return (
    <TreeView
      aria-label="navigation-sidebar-menu"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      sx={{ userSelect: 'none', width: '100%', overflow: 'hidden', overflowY: 'auto' }}
      {...props}
    >
      <TreeItems collapsed={true} items={items} index={0} />
    </TreeView>
  );
};

export default SideMenuTree;
