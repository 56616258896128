import { H6, LogText } from '@/components/Typography';
import { Card, CardContent, Stack } from '@mui/material';

import { AdtTcmActivityLog } from '@/types/activityLog';
import { formatDateTimeIgnoreTZ } from '@/util/format';

type Props = {
  adtTcmActivityLogs: AdtTcmActivityLog[];
};

const LogView = ({ adtTcmActivityLogs }: Props) => {
  return (
    <Stack justifyContent={'center'} alignItems={'center'} sx={{ flex: 1 }}>
      <Card sx={{ minWidth: 275, marginTop: '18px', backgroundColor: '#f7f7f7', width: 500 }}>
        <CardContent>
          {adtTcmActivityLogs.length === 0 && (
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'} sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
              <H6 sx={{ width: '100%' }} textAlign="center">
                No Logs available for this task
              </H6>
            </Stack>
          )}
          {adtTcmActivityLogs.length > 0 &&
            adtTcmActivityLogs.map(log => {
              return (
                <Stack
                  key={log.id}
                  direction={'row'}
                  alignItems="center"
                  justifyContent={'space-between'}
                  sx={{ marginTop: 1.5, marginBottom: 1.5 }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <LogText fontWeight="bold">
                      {log.relyingPartyAdmin.user.firstName} {log.relyingPartyAdmin.user.lastName} : {log.logText}
                    </LogText>
                  </Stack>
                  <H6 sx={{ width: '50%' }} textAlign="right">
                    {formatDateTimeIgnoreTZ(log.activityTime)}
                  </H6>
                </Stack>
              );
            })}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default LogView;
