import { GET_CLIENT_ORGANIZATIONS_STANDARD, GET_CLIENT_STORES_STANDARD, GET_HEALTH_PLANS } from '@/graphql/administration';
import type { HealthPlan } from '@/types/accountProfile';
import { CONSENT_OPTIONS, GENDER_OPTIONS_ARRAY } from '@/types/accountProfile';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField as TextFieldMUI
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { GetGlobalAccountByIdResponse } from '@/graphql/account';
import { GET_GLOBAL_ACCOUNT_BY_ID, UPDATE_USER_PROFILE } from '@/graphql/account';
import { color } from '@/styles/assets/colors';
import { states } from '@/util/address';
import { formatDate, titleCase } from '@/util/format';
import { zipcodeRestrictToFiveDigits } from '@/util/input';
import { useSnackbar } from 'notistack';
import Button from '../Button';
import MuiAccordion from '../MuiAccordion';
import type { TextSelectOption } from '../SelectList';
import SelectList from '../SelectList';
import TruentityDatePicker from '../TruentityDatePicker';
import TruentityTextField from '../TruentityTextField';
import { Small } from '../Typography';

type Props = BaseDialogProps & {
  accountId: string;
  hideDialog: (props?: any) => void;
  skipDialog?: (props: any) => void;
  onPatientUpdated: () => void;
  subHeaderTitle?: string;
};

type FormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  birthDate: Date | null;
  email: string;
  phone: string;
  gender: string;
  zipcode: string;
  consent: string;
  healthPlan: string;
  clientOrganization: string;
  clientStore: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
};

const defaultValues: FormValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  birthDate: null,
  email: '',
  phone: '',
  gender: '',
  zipcode: '',
  healthPlan: '',
  consent: 'PatientAnyProvider',
  clientOrganization: '',
  clientStore: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: ''
};

const PatientUpdateDialog = ({ title, subHeaderTitle, accountId, hideDialog, skipDialog, onPatientUpdated, ...props }: Props) => {
  const { control, setValue, reset, handleSubmit } = useForm<FormValues>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();
  const clientOrgStoreInitialValue = useRef({
    clientOrg: '',
    clientStore: ''
  });

  const [updateUserAccountProfile, { loading: isUpdateUserAccountProfileLoading }] = useMutation(UPDATE_USER_PROFILE);

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: clientStoresDataLoading,
    data: clientStoresData,
    called: clientStoresDataCalled
  } = useQuery(GET_CLIENT_STORES_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });
  const { data: patientData } = useQuery<GetGlobalAccountByIdResponse>(GET_GLOBAL_ACCOUNT_BY_ID, {
    variables: {
      truentityId: accountId
    },
    fetchPolicy: 'cache-and-network'
  });

  const [getHealthPlans, { data: healthPlans }] = useLazyQuery(GET_HEALTH_PLANS);

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [clientStores, setClientStores] = useState<ClientStoreObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);
  const [healthPlanList, setHealthPlans] = useState<HealthPlan[]>([]);
  const [isAddressRequired, setIsAddressRequired] = useState<boolean>(true);
  const [disableFields, setDisableFields] = useState({
    clientOrganizations: false,
    clientStores: false
  });
  const [defaultValue, setDefaultValue] = useState<Date | undefined>(undefined);
  const STATES: TextSelectOption[] = states();

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations(organizationData.clientOrganizations.clientOrganizations);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    if (clientStoresDataCalled && !clientStoresDataLoading && clientStoresData) {
      setClientStores(clientStoresData.clientStores.clientStores);
    }
  }, [clientStoresDataLoading, clientStoresData, clientStoresDataCalled]);

  useEffect(() => {
    getHealthPlans({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, []);

  useEffect(() => {
    if (healthPlans && healthPlans.healthPlans && healthPlans.healthPlans.healthPlans) {
      setHealthPlans(healthPlans.healthPlans.healthPlans);
    }
  }, [healthPlans]);

  const setClientStore = useCallback(
    (clientOrganizationId: string) => {
      if (clientStores.length > 0) {
        const storesBelongingToSelectedOrg = clientStores.filter(store => store.clientOrg?.id === clientOrganizationId);
        setFilteredClientStores(storesBelongingToSelectedOrg);
        return storesBelongingToSelectedOrg;
      }
    },
    [clientStores]
  );

  useEffect(() => {
    if (patientData && clientStores) {
      const account = patientData.globalAccountById;
      const user = account.user;

      const birthDate = new Date((account.birthDate as string).replace(/-/g, '/'));
      const selectedClientStore =
        account?.clientOrgs && account?.clientOrgs?.length > 0 ? setClientStore(account.clientOrgs?.[0]?.id ?? '') : [];

      const patientInfo: FormValues = {
        firstName: user?.firstName ?? '',
        middleName: user?.middleName ?? '',
        lastName: user.lastName ?? '',
        birthDate: birthDate ?? '',
        email: user.email ?? '',
        gender: account.gender ?? '',
        addressLine1: account.addressLine1 ?? '',
        addressLine2: account.addressLine2 ?? '',
        addressState: account.addressState ?? '',
        addressCity: account.addressCity ?? '',
        zipcode: account.zipcode ?? '',
        healthPlan: account.healthPlan?.id ?? '',
        consent: account.consent ?? '',
        phone: account.phone ?? '',
        clientOrganization: account?.clientOrgs && account?.clientOrgs?.length > 0 ? account.clientOrgs?.[0]?.id ?? '' : '',
        clientStore: selectedClientStore?.[0]?.id ?? ''
      };

      clientOrgStoreInitialValue.current = {
        clientOrg: patientInfo?.clientOrganization || '',
        clientStore: patientInfo?.clientStore || ''
      };

      reset(patientInfo);

      const { addressLine1, addressLine2, addressState, addressCity, clientOrganization, clientStore } = patientInfo;

      if ([addressLine1, addressLine2, addressState, addressCity].some(field => field.trim().length > 0)) {
        setIsAddressRequired(true);
      }
      updateDisableFields('clientOrganizations', clientOrganization);
      updateDisableFields('clientStores', clientStore);

      setDefaultValue(birthDate);
    }
  }, [patientData, reset, clientStores]);

  const updateDisableFields = (fieldName: string, value: string) => {
    setDisableFields(prevFields => ({
      ...prevFields,
      [fieldName]: value?.length !== 0 ?? false
    }));
  };

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    const { firstName, lastName, middleName, email, healthPlan, ...resOfValues } = values;
    const profileData = {
      userProfile: {
        firstName: titleCase(values.firstName.trim()),
        middleName: values.middleName ? titleCase(values.middleName.trim()) : null,
        lastName: titleCase(values.lastName.trim())
      },
      ...resOfValues,
      phone: values.phone.trim(),
      zipcode: values.zipcode.trim(),
      birthDate: formatDate(values.birthDate, 'YYYY-MM-DD'),
      healthPlan: {
        id: healthPlan
      }
    };
    try {
      await updateUserAccountProfile({
        variables: {
          truentityId: accountId,
          profileData
        }
      });

      enqueueSnackbar('Account updated successfully', {
        variant: 'success'
      });

      onPatientUpdated();
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to update account', {
        variant: 'error'
      });
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        {subHeaderTitle && (
          <Box mb={1}>
            <Small>
              <mark>{subHeaderTitle}</mark>
            </Small>
          </Box>
        )}
        <Grid component={'form'} container columnSpacing={4} rowSpacing={1} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={6}>
            <Stack>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField autoFocus={true} required onChange={onChange} value={value} label={'First Name'} />
                )}
              />

              <Controller
                control={control}
                name="middleName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField autoFocus={true} onChange={onChange} value={value} label={'Middle Name'} />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField required onChange={onChange} value={value} label={'Last Name'} />
                )}
              />

              <Controller
                control={control}
                name="birthDate"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker
                    TextFieldProps={{ required: true }}
                    onChange={onChange}
                    value={value}
                    label={'Date of Birth'}
                    defaultValue={defaultValue}
                  />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField type={'email'} shrink={true} onChange={onChange} value={value} label={'Email'} disabled />
                )}
              />

              <Controller
                control={control}
                name="clientOrganization"
                render={({ field: { onChange, value } }) => (
                  <FormControl variant="outlined" fullWidth margin="dense" size="medium">
                    <InputLabel id={'organizations-input'} required>
                      Client Organization
                    </InputLabel>

                    <Select
                      labelId={'organizations'}
                      label={'Organizations'}
                      placeholder={'select an organization'}
                      value={value}
                      required
                      onChange={event => {
                        if (clientStores.length > 0) {
                          const storesBelongingToSelectedOrg = clientStores.filter(store => store.clientOrg?.id === event.target.value);
                          setFilteredClientStores(storesBelongingToSelectedOrg);
                        }
                        onChange(event);
                      }}
                      disabled={disableFields.clientOrganizations}
                      sx={!!value ? {} : { border: '.125rem solid', borderColor: color.truentityCyan[400] }}
                    >
                      {clientOrganizations.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="healthPlan"
                render={({ field: { onChange, value } }) => (
                  <SelectList
                    id={'healthPlan'}
                    label="Health Plan"
                    options={healthPlanList.map(x => ({ label: x?.orgName, value: x?.id }))}
                    placeholder="Select a health plan..."
                    value={value}
                    clearFunction={() => {
                      setValue('healthPlan', '');
                    }}
                    MenuProps={{
                      sx: { maxHeight: 220 }
                    }}
                    onChange={onChange}
                  />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack sx={{ marginTop: 1 }}>
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="gender-options"
                    options={GENDER_OPTIONS_ARRAY}
                    renderInput={params => <TextFieldMUI required {...params} label="Gender" variant="outlined" />}
                    getOptionLabel={option => option}
                    value={value}
                    onChange={(_event, selectedOption) => {
                      onChange(selectedOption);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="zipcode"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField
                    onChange={e => zipcodeRestrictToFiveDigits(e.target.value, onChange)}
                    value={value}
                    label={'Zipcode'}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="consent"
                render={({ field: { onChange, value } }) => (
                  <SelectList
                    label="Consent"
                    options={CONSENT_OPTIONS}
                    placeholder="Select an option..."
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <TruentityPhoneNumber editable={true} onChange={onChange} value={value} label={'Phone'} />
                )}
              />

              <Controller
                control={control}
                name="clientStore"
                render={({ field: { onChange, value } }) => (
                  <FormControl variant="outlined" fullWidth margin="dense" size="medium">
                    <InputLabel required id={'organizations-input'}>
                      Client Location
                    </InputLabel>

                    <Select
                      labelId={'stores'}
                      label={'Stores'}
                      placeholder={'select a store'}
                      value={value}
                      required
                      onChange={onChange}
                      disabled={disableFields.clientStores}
                      sx={!!value ? {} : { border: '.125rem solid', borderColor: color.truentityCyan[400] }}
                    >
                      {filteredClientStores.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ marginTop: 2 }} />
            <FormControlLabel
              label={`Add Account Address`}
              control={<Checkbox checked={isAddressRequired} onChange={(_e, checked) => setIsAddressRequired(checked)} />}
            />
            <MuiAccordion
              options={[
                {
                  label: 'Account Address Details',
                  defaultExpand: false,
                  content: (
                    <>
                      <Controller
                        control={control}
                        name="addressLine1"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'Address Line 1'} required={isAddressRequired} />
                        )}
                      />
                      <Controller
                        control={control}
                        name="addressLine2"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'Address Line 2'} required={false} />
                        )}
                      />
                      <Controller
                        control={control}
                        name="addressCity"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'Address City'} required={isAddressRequired} />
                        )}
                      />
                      <Controller
                        control={control}
                        name="addressState"
                        render={({ field: { onChange, value } }) => (
                          <SelectList
                            id={'addressState'}
                            label="Address State"
                            options={STATES}
                            placeholder="Select a state..."
                            value={value}
                            required={isAddressRequired}
                            clearFunction={() => {
                              setValue('addressState', '');
                            }}
                            MenuProps={{
                              sx: { maxHeight: 220 }
                            }}
                            onChange={onChange}
                          />
                        )}
                      />
                    </>
                  ),
                  expanded: isAddressRequired,
                  onChange: () => setIsAddressRequired(!isAddressRequired)
                }
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ padding: '30px 0 0 0' }}>
              <Button
                type="reset"
                a11yLabel="Skip"
                appearance="outline"
                onClick={() => {
                  reset();
                  skipDialog &&
                    skipDialog({
                      clientOrg: clientOrgStoreInitialValue.current.clientOrg,
                      clientStore: clientOrgStoreInitialValue.current.clientStore
                    });
                }}
              />
              <Button type="submit" a11yLabel="Done" appearance="primary" isLoading={isUpdateUserAccountProfileLoading} />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default PatientUpdateDialog;
