import Button from '@/components/Button';
import type { SaveUpdateTaskResponse } from '@/graphql/taskEncounter';
import { SAVE_UPDATE_TASK } from '@/graphql/taskEncounter';
import type { TaskType } from '@/types/graphql';
import { useMutation } from '@apollo/client';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Checkbox, DialogContent, FormControlLabel, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';
import TruentityTextField from '../TruentityTextField';
import { Small } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  onSubmit: () => void;
  taskDrawerInfo: TaskType;
  truentityId: string;
};

const TaskCompleteBillingDialog = ({ title, hideDialog, onSubmit, truentityId, taskDrawerInfo, ...props }: Props) => {
  const [saveUpdateTask] = useMutation<SaveUpdateTaskResponse>(SAVE_UPDATE_TASK);
  const [billedAmount, setBilledAmount] = useState<number>(taskDrawerInfo.billedAmount);

  const [bonusAmount, setBonusAmount] = useState<number>(taskDrawerInfo.bonusAmount);
  const [addBonusChecked, setAddBonusChecked] = useState<boolean>(bonusAmount > 0);

  const { enqueueSnackbar } = useSnackbar();

  const hideDialogImpl = () => {
    hideDialog();
  };

  const handleOnSubmit = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      if (addBonusChecked && bonusAmount <= 0) {
        enqueueSnackbar('Unable to update billed amount and bonus. Please enter a valid bonus amount', { variant: 'error' });
        return;
      }

      saveUpdateTask({
        variables: {
          taskData: {
            billedAmount: Number(billedAmount),
            bonusAmount: Number(bonusAmount)
          },
          truentityId: truentityId,
          taskId: taskDrawerInfo.id
        }
      })
        .then(response => {
          if (response.data?.saveUpdateTask?.status === 'Success') {
            onSubmit();
          }
        })
        .catch(error => {
          console.error(error);
          enqueueSnackbar('Unable to update billed amount', { variant: 'error' });
          hideDialog();
        });
    },
    [addBonusChecked, bonusAmount, saveUpdateTask, billedAmount, truentityId, taskDrawerInfo.id, enqueueSnackbar, onSubmit, hideDialog]
  );

  return (
    <BaseDialog
      sx={{ zIndex: theme => theme.zIndex.drawer + 2 }}
      canNavigateBack={false}
      title={title}
      hideDialog={hideDialogImpl}
      {...props}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogContent>
        <Stack spacing={2} component="form" onSubmit={handleOnSubmit}>
          <Small>Enter the claims billed amount, if applicable.</Small>
          <TruentityTextField
            sx={{ marginTop: '22px !important' }}
            label="Billed Amount"
            type="number"
            fullWidth
            variant="outlined"
            margin="dense"
            value={billedAmount}
            defaultValue={billedAmount}
            onChange={input => {
              setBilledAmount(parseFloat(input.target.value));
            }}
            startIcon={<AttachMoneyIcon />}
          />

          <Stack
            direction="row"
            alignItems={'center'}
            sx={{ alignItems: 'center', justifyContent: 'start', padding: '0 0 0 0' }}
            spacing={1}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={addBonusChecked}
                  disabled={billedAmount <= 0}
                  size="medium"
                  onChange={event => {
                    setAddBonusChecked(event.target.checked);
                  }}
                />
              }
              label={<Small>Add Bonus</Small>}
            />

            {addBonusChecked && (
              <TruentityTextField
                label="Bonus Amount"
                type="number"
                fullWidth
                variant="outlined"
                margin="dense"
                value={bonusAmount}
                defaultValue={bonusAmount}
                onChange={input => {
                  setBonusAmount(parseFloat(input.target.value));
                }}
                startIcon={<AttachMoneyIcon />}
              />
            )}
          </Stack>

          <Button type="submit" a11yLabel="Update Billing" fullWidth />
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default TaskCompleteBillingDialog;
