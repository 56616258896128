import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { FollowUpAddEditDialog } from '@/components/Dialogs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { renderFollowUpStatusChip } from '@/components/Dialogs/FollowUpAddEditDialog';
import TruentityTextField from '@/components/TruentityTextField';
import { H1 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { FollowUpStatusTypes, GetAccountFollowupsResponse } from '@/graphql/account';
import { DELETE_ACCOUNT_FOLLOWUP, GET_ACCOUNT_FOLLOWUPS } from '@/graphql/account';
import { color } from '@/styles/assets/colors';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDate, formatTime } from '@/util/format';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, Stack } from '@mui/material';
import type { GridColDef, GridFilterModel } from '@mui/x-data-grid-pro';
import { GridLogicOperator } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const AllFollowUps = () => {
  const { id: truentityId } = useParams();
  const { showModal } = useModal();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();
  const initialAccountFollowUp = useRef<FollowUpRemindersTypes[]>([]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [accountFollowUps, setAccountFollowUps] = useState<FollowUpRemindersTypes[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.Or
  });

  const {
    data: accountFollowUpData,
    loading: accountFollowUpLoading,
    refetch: accountFollowUpRefetch
  } = useQuery<GetAccountFollowupsResponse>(GET_ACCOUNT_FOLLOWUPS, {
    variables: {
      truentityId,
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        relyingPartyAdminId: 'ALL'
      }
    }
  });
  const [deleteFollowup] = useMutation(DELETE_ACCOUNT_FOLLOWUP);

  const checkFollowupAction = (
    action: 'edit' | 'delete',
    followUp: FollowUpRemindersTypes,
    truentityId?: string | undefined,
    doNotCall?: boolean
  ) => {
    try {
      const creatorId = followUp.relyingPartyAdmin?.id;
      const isRpmEnrolled = followUp.account.rpmStatus === RpmStatusTypes.ENROLLED;

      const isCurrentUserCreator = creatorId === currentUser?.id;

      const confirmAction = () => {
        if (action === 'edit') {
          showEditFollowupDialog(truentityId, followUp, isRpmEnrolled, doNotCall as boolean);
        } else if (action === 'delete') {
          showDeleteFollowUpDialog(followUp.id);
        }
      };

      if (isCurrentUserCreator) {
        confirmAction();
      } else {
        showConfirmationDialogForAction(followUp, confirmAction);
      }
    } catch (error) {
      console.error('Error occurred while handling follow-up action:', error);
    }
  };

  const showConfirmationDialogForAction = (followUp: FollowUpRemindersTypes, confirmAction: () => void) => {
    const assigneeName = getAccountUserFullName(followUp.relyingPartyAdmin?.user);
    const modal = showModal(ConfirmDialog, {
      title: `Confirmation`,
      message: `This Follow-up is assigned to <b>${assigneeName}</b>. Are you sure? Do you want to proceed further?`,
      onAgree: () => {
        modal.hide();
        confirmAction();
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  const showEditFollowupDialog = (
    truentityId: string | undefined,
    followUp: FollowUpRemindersTypes,
    isRpmEnrolled: boolean,
    doNotCall: boolean
  ) => {
    try {
      if (followUp && truentityId) {
        const modal = showModal(FollowUpAddEditDialog, {
          title: 'Update Follow Up On',
          hideDialog: () => {
            modal.hide();
          },
          handleRefetchAndClose: () => {
            modal.hide();
            setReloadPatientInfo(true);
          },
          truentityId,
          rpmStatus: patientInfo?.rpmStatus,
          doNotCall,
          followupsData: [followUp],
          isUpdateModal: true
        });
      } else {
        console.error('Error occurred in update follow-up folow');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showDeleteFollowUpDialog = (followUpId: string) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Delete Follow-Up',
      message: 'Are you sure you want to delete this follow-up? This action cannot be undone.',
      onAgree: async () => {
        await deleteFollowup({ variables: { followUpIds: [followUpId] } })
          .then(response => {
            const data = response.data.removeAccountFollowup;
            const variant = data!.status === 'Success' ? 'success' : 'error';
            enqueueSnackbar(data.message, { variant });
            modal.hide();
            setReloadPatientInfo(true);
          })
          .catch(() => {
            enqueueSnackbar('Failed to delete this follow-up', {
              variant: 'error'
            });
            modal.hide();
          });
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  const columns: GridColDef<FollowUpRemindersTypes>[] = useMemo(
    () => [
      {
        field: 'followupDate',
        headerName: 'Date',
        valueGetter: params => formatDate(params.row.followUpOn),
        flex: 1
      },
      {
        field: 'followupTime',
        headerName: 'Time',
        valueGetter: params => formatTime(params.row.followUpOn),
        flex: 1
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: params => renderFollowUpStatusChip(params.value as FollowUpStatusTypes)
      },
      {
        field: 'subType',
        headerName: 'Sub Type',
        flex: 1
      },
      {
        field: 'assignedTo',
        headerName: 'Assigned To',
        valueGetter: params => `${getAccountUserFullName(params.row.relyingPartyAdmin?.user)}`,
        flex: 1
      },
      {
        field: 'notes',
        headerName: 'Note',
        flex: 1
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: cellValues => {
          return (
            <Stack spacing={2} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton
                color="primary"
                onClick={() => checkFollowupAction('edit', cellValues?.row, truentityId, patientInfo?.doNotCall as boolean)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton color="primary" onClick={() => checkFollowupAction('delete', cellValues?.row)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    setFilterModel(prevFilterModel => ({
      ...prevFilterModel,
      items: [
        {
          field: 'type',
          operator: 'contains',
          value: searchValue
        },
        {
          field: 'subType',
          operator: 'contains',
          value: searchValue
        },
        {
          field: 'assignedTo',
          operator: 'contains',
          value: searchValue
        },
        {
          field: 'notes',
          operator: 'contains',
          value: searchValue
        }
      ],
      logicOperator: GridLogicOperator.Or
    }));
  }, [searchValue]);

  useEffect(() => {
    if (accountFollowUpData && accountFollowUpData?.accountFollowups) {
      return setAccountFollowUps(accountFollowUpData?.accountFollowups.accountFollowups ?? []);
    }
    setAccountFollowUps([]);
  }, [accountFollowUpData]);

  useEffect(() => {
    if (patientInfo && Object.values(patientInfo).length > 0) {
      const accountFollowups = patientInfo?.accountsFollowupReminders;
      if (JSON.stringify(accountFollowups) !== JSON.stringify(initialAccountFollowUp.current)) {
        if (!accountFollowUpLoading) {
          accountFollowUpRefetch();
          initialAccountFollowUp.current = accountFollowups;
        }
      }
    }
  }, [patientInfo, accountFollowUpLoading]);

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <H1
            textAlign="left"
            sx={{
              width: '50%',
              fontSize: '38px',
              color: color.black50,
              lineHeight: '50px',
              margin: 0
            }}
          >
            All Follow-Ups
          </H1>
        </Grid>
        <Grid item xs={6}>
          <TruentityTextField
            icon={<SearchIcon />}
            label={'Search'}
            fullWidth
            margin="none"
            value={searchValue}
            onChange={e => setSearchValue(e.currentTarget.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TruentityDataGrid
            filterModel={filterModel}
            onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
            name={'account-followups'}
            autoHeight
            columns={columns}
            rows={accountFollowUps ?? []}
            loading={accountFollowUpLoading}
            rowCount={accountFollowUps?.length ?? 0}
            pagination
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            paginationMode="server"
            disableRowSelectionOnClick
            disableColumnSelector
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllFollowUps;
