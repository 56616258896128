import { UpdateRelyingPartyResponse, UPDATE_RELYING_PARTY } from '@/graphql/account';
import { GetCoverLetterBodyResponse, GET_COVER_LETTER_BODY } from '@/graphql/remotePatientMonitoring';
import RpmReportCoverLetterBody from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmReportCoverLetter/RpmReportCoverLetterBody';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, DialogActions, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  onCoverLetterUpdated: () => Promise<void>;
  hideDialog: () => void;
};

type FormValues = {
  coverLetterContent: string;
};

const defaultValues: FormValues = {
  coverLetterContent: ''
};

const EditCoverLetterDialog = ({ hideDialog, onCoverLetterUpdated, ...props }: Props): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [coverLetterBodyContent, setCoverLetterBodyContent] = useState('');

  const [updateRelyingParty, { loading: updateRelyingPartyLoading }] = useMutation<UpdateRelyingPartyResponse>(UPDATE_RELYING_PARTY);
  const { control, reset, handleSubmit, watch, formState } = useForm<FormValues>({ defaultValues });

  const watchCoverLetterContent = watch('coverLetterContent');
  const isDirty = formState.isDirty;
  const [
    getCoverLetterBody,
    { data: coverLetterBody, error: coverLetterBodyError, loading: coverLetterBodyLoading, called: coverLetterBodyCalled }
  ] = useLazyQuery<GetCoverLetterBodyResponse>(GET_COVER_LETTER_BODY, {
    fetchPolicy: 'cache-and-network'
  });

  const onSubmit: SubmitHandler<FormValues> = data => handleCoverLetterSubmit(data);

  const handleCoverLetterSubmit = async (values: FormValues) => {
    try {
      await updateRelyingParty({
        variables: {
          relyingPartyId: currentUser?.relyingParty?.id,
          coverLetterBody: values.coverLetterContent,
          name: null,
          logo: null
        }
      });

      enqueueSnackbar('Cover letter content updated successfully.', {
        variant: 'success'
      });
      hideDialog();

      await onCoverLetterUpdated();
    } catch (err) {
      enqueueSnackbar('Could not update cover letter content.', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (currentUser?.relyingParty?.id) {
      getCoverLetterBody({
        variables: {
          relyingPartyId: currentUser?.relyingParty?.id
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (coverLetterBodyError) {
      enqueueSnackbar('Could not get cover letter body.', {
        variant: 'error'
      });
    }
  }, [coverLetterBodyError]);

  useEffect(() => {
    if (coverLetterBody && coverLetterBody?.relyingParty) {
      setCoverLetterBodyContent(coverLetterBody?.relyingParty.coverLetterBody);
    }
  }, [coverLetterBody]);

  return (
    <BaseDialog hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent
        sx={{
          minHeight: '400px',
          display: 'flex'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch'
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <RpmReportCoverLetterBody
            control={control}
            coverLetterBody={coverLetterBodyContent}
            coverLetterBodyCalled={coverLetterBodyCalled}
            coverLetterBodyLoading={coverLetterBodyLoading}
            reset={reset}
            watchCoverLetterContent={watchCoverLetterContent}
            isDirty={isDirty}
          />

          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button isLoading={updateRelyingPartyLoading} type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default EditCoverLetterDialog;
