import { useEffect, useState } from 'react';

import CommentsTable from '@/components/Comments/CommentsTable';

import { COMMENTS_PER_PAGE } from '@/components/Comments';
import Pagination from '@/components/Pagination';
import { Body1 } from '@/components/Typography';
import { GET_COMMENTS_FOR_MEDICATIONS } from '@/graphql/medication';
import { useLazyQuery } from '@apollo/client';
import { DialogContent } from '@mui/material';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  medicationId: string;
  hideDialog: () => void;
};

const CommentsMedRecDialog = ({ title, medicationId, hideDialog, ...props }: Props) => {
  const [comments, setComments] = useState<[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [getCommentsForMedication, commentsResultForMedicationData] = useLazyQuery(GET_COMMENTS_FOR_MEDICATIONS);

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  const isDataLoaded = () =>
    commentsResultForMedicationData &&
    commentsResultForMedicationData.called &&
    !commentsResultForMedicationData.loading &&
    commentsResultForMedicationData.data &&
    commentsResultForMedicationData.data.commentsByAccountMedication.comments;

  const isThereData = () =>
    isDataLoaded() &&
    commentsResultForMedicationData.data &&
    commentsResultForMedicationData.data.commentsByAccountMedication.meta.totalCount > 0;

  useEffect(() => {
    if (commentsResultForMedicationData) {
      if (commentsResultForMedicationData && commentsResultForMedicationData.data) {
        setTotalPages(commentsResultForMedicationData.data.commentsByAccountMedication.meta.totalPages);
        setTotalCount(commentsResultForMedicationData.data.commentsByAccountMedication.meta.totalCount);
        setComments(commentsResultForMedicationData.data.commentsByAccountMedication.comments);
      }
    }
  }, [commentsResultForMedicationData]);

  useEffect(() => {
    getCommentsForMedication({
      variables: {
        accountMedicationId: medicationId,
        pageNum: currentPage,
        pageSize: COMMENTS_PER_PAGE
      }
    });
  }, [currentPage, totalCount, totalPages]);

  return (
    <BaseDialog title={title} {...props} hideDialog={hideDialog} fullWidth maxWidth={'md'}>
      <DialogContent>
        {comments.length > 0 ? <CommentsTable rows={comments} /> : <Body1>There are no comments for this medication </Body1>}

        {isThereData() && (
          <Pagination
            currentPage={currentPage}
            totalRecords={totalCount}
            pageLimit={totalPages}
            pageNeighbours={2}
            onPageChanged={updateData}
          />
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default CommentsMedRecDialog;
