/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { GET_ADT_EVENTS_ANALYTICS, GET_TCM_TASKS_ANALYTICS } from '@/graphql/analytics';
import type {
  AdtEventsAnalyticsPayloadType,
  AdtEventsAnalyticsType,
  AdtStatusDistributionType,
  TaskStatusDistributionDataType,
  TcmTasksAnalyticsPayloadType
} from '@/types/tcmAnalytics';
import { Card, CardContent, Grid, Paper, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { PieChart } from '@/components/Charts/charts';
import LoadingOverlay from '@/components/LoadingOverlay';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H2 } from '@/components/Typography';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

type DashboardSectionProps = {
  title?: ReactNode;
  children: ReactNode;
};

const DashboardSection = ({ title, children }: DashboardSectionProps) => {
  return (
    <Paper component={Stack} spacing={2} sx={{ padding: '15px' }}>
      {title && <H2>{title}</H2>}
      {children}
    </Paper>
  );
};

const TcmDashboard = () => {
  const [adtEventsAnalyticsData, setAdtEventsAnalyticsData] = useState<AdtEventsAnalyticsType[]>();
  const [clickedAdtDistributionData, setClickedAdtDistributionData] = useState<AdtStatusDistributionType[]>();
  const [taskStatusDistributionData, setTaskStatusDistributionData] = useState<TaskStatusDistributionDataType[]>();

  const today = new Date();
  const firstDatePrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastDatePrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const [adtStartDate, setAdtStartDate] = useState<Date | null>(firstDatePrevMonth);
  const [adtEndDate, setAdtEndDate] = useState<Date | null>(lastDatePrevMonth);

  const [tcmStartDate, setTcmStartDate] = useState<Date | null>(firstDatePrevMonth);
  const [tcmEndDate, setTcmEndDate] = useState<Date | null>(lastDatePrevMonth);

  const [getAdtEventsAnalytics, { loading: isAdtAnalyticsDataLoading }] = useLazyQuery(GET_ADT_EVENTS_ANALYTICS);
  const [getTcmTasksAnalytics, { loading: isTcmAnalyticsDataLoading }] = useLazyQuery(GET_TCM_TASKS_ANALYTICS);

  useEffect(() => {
    setAdtEventsAnalyticsData([]);
    getAdtEventsAnalytics({
      variables: {
        filterOptions: {
          dischargeStartDate: moment(adtStartDate).add(1, 'days'),
          dischargeEndDate: moment(adtEndDate).add(1, 'days')
        }
      }
    })
      .then(response => setAdtAnalyticsData(response))
      .catch(error => console.error(error));
  }, [adtStartDate, adtEndDate, getAdtEventsAnalytics]);

  useEffect(() => {
    getTcmTasksAnalytics({
      variables: {
        filterOptions: {
          dischargeStartDate: moment(tcmStartDate).add(1, 'days'),
          dischargeEndDate: moment(tcmEndDate).add(1, 'days')
        }
      }
    })
      .then(response => setTcmTasksData(response))
      .catch(error => console.error(error));
  }, [tcmStartDate, tcmEndDate, getTcmTasksAnalytics]);

  const setAdtAnalyticsData = (data: AdtEventsAnalyticsPayloadType) => {
    setAdtEventsAnalyticsData(data?.data?.adtEventsAnalytics?.adtEventsAnalytics);
  };

  const setTcmTasksData = (data: TcmTasksAnalyticsPayloadType) => {
    setTaskStatusDistributionData(data?.data?.tcmTasksAnalytics?.tcmTasksAnalytics?.tcmTaskDistribution);
  };

  const onSliceSelected = (event, chartContext, config) => {
    selectAdtEventByIndex(config.dataPointIndex);
  };

  const selectAdtEventByIndex = index => {
    const event = adtEventsAnalyticsData![index];
    if (event) {
      setClickedAdtDistributionData(event?.statusDistribution);
    }
  };

  return (
    <Paper component={Stack} sx={{ backgroundColor: 'transparent' }} elevation={0} spacing={2}>
      {<LoadingOverlay active={isAdtAnalyticsDataLoading || isTcmAnalyticsDataLoading} text="Please wait. Loading Analytics Data..." />}
      <DashboardSection title={'ADT Events Analytics'}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={'flex-start'}>
              <TruentityDatePicker
                label="Discharge Start Date"
                fullWidth={false}
                sx={{ width: 400, marginRight: 1 }}
                value={adtStartDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setAdtStartDate(date as Date);
                }}
              />
              <TruentityDatePicker
                label="Discharge End Date"
                fullWidth={false}
                sx={{ width: 400 }}
                value={adtEndDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setAdtEndDate(date as Date);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5}>
            {adtEventsAnalyticsData && adtEventsAnalyticsData.length > 0 && (
              <PieChart
                width={480}
                series={adtEventsAnalyticsData?.map(v => v?.count)}
                options={{
                  labels: adtEventsAnalyticsData?.map(v => v?.eventType),
                  chart: {
                    events: {
                      dataPointSelection: onSliceSelected,
                      mounted: chartCtx => chartCtx.toggleDataPointSelection(0)
                    }
                  },
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {clickedAdtDistributionData && clickedAdtDistributionData.length !== 0 && adtEventsAnalyticsData!.length !== 0 && (
              <PieChart
                width={480}
                series={clickedAdtDistributionData?.map(v => v?.count)}
                options={{
                  labels: clickedAdtDistributionData?.map(v => v?.eventType),
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            )}
          </Grid>

          {!isAdtAnalyticsDataLoading && adtEventsAnalyticsData?.length === 0 && (
            <Grid item xs={12} md={12} pb={4} pt={2} sx={{ textAlign: 'center' }}>
              <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <CardContent component={Stack} justifyContent={'space-evenly'} sx={{ padding: '16px', flex: '1', minHeight: '100px' }}>
                  <H2>No Discharge tasks completed for this time range</H2>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </DashboardSection>
      <DashboardSection title={'TCM Analytics'}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={'flex-start'}>
              <TruentityDatePicker
                label="Discharge Start Date"
                fullWidth={false}
                sx={{ width: 400, marginRight: 1 }}
                value={tcmStartDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setTcmStartDate(date as Date);
                }}
              />
              <TruentityDatePicker
                label="Discharge End Date"
                fullWidth={false}
                sx={{ width: 400 }}
                value={tcmEndDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setTcmEndDate(date as Date);
                }}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12} md={6}>
            {taskStatusDistributionData && taskStatusDistributionData.length > 0 && (
              <PieChart
                width={500}
                series={taskStatusDistributionData?.map(v => v?.count)}
                options={{
                  labels: taskStatusDistributionData?.map(v => v?.status),
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            )}
          </Grid>
        </Grid>
        {taskStatusDistributionData && taskStatusDistributionData.length === 0 && (
          <Grid item xs={12} md={12} pb={4} pt={2} sx={{ textAlign: 'center' }}>
            <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
              <CardContent component={Stack} justifyContent={'space-evenly'} sx={{ padding: '16px', flex: '1', minHeight: '100px' }}>
                <H2>No Discharge tasks completed for this time range</H2>
              </CardContent>
            </Card>
          </Grid>
        )}
      </DashboardSection>
    </Paper>
  );
};

export default TcmDashboard;
