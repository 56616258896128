import { MedicalServiceType } from './graphql';

export type ChecklistItem = {
  id: string;
  name: string;
  category: string;
  description: string | null;
  medicalService: MedicalServiceType;
  resourceS3Key: string;
  convertedS3Url: string;
  resourceMimeType: string;
  isMandatory: boolean;
  orderNum: number;
};

export type ChecklistsType = {
  id: string;
  checklistItem: ChecklistItem;
  checkedOn: string | null;
  notes: string;
};

export type CategoryNameType = { categoryName: string };

export type ChecklistCountsType = {
  totalChecklistCount: number;
  totalPendingCount: number;
  totalCompletedCount: number;
  totalMandatoryCount: number;
  completedMandatoryCount: number;
};

export type ChecklistByCategoryType = ChecklistCountsType &
  CategoryNameType & {
    checklists: ChecklistsType[];
  };

export enum ChecklistMimeTypes {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  MP4 = 'video/mp4',
  MPEG = 'video/mpeg',
  QUICKTIME = 'video/quicktime',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ZIP = 'application/x-zip'
}
