import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { H3, H5 } from '@/components/Typography';
import { emptyData, reducer } from '@/state/taskForm';
import { color } from '@/styles/assets/colors';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Box, Card, CardContent, Divider, FormControl, Grid, Stack } from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import type { Encounter } from '@/types/medication';
import { DISPOSITION_TYPES, TASK_NEXT_STEPS, TASK_RESOLUTION_STATUSES, TASK_TYPES } from '@/types/medication';

import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import InputRow from '@/elements/InputRow';
import { GET_HEALTH_PLANS } from '@/graphql/administration';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityDatePicker from '../TruentityDatePicker';
import TruentityTextField from '../TruentityTextField';
import BaseDialog from './BaseDialog';

import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import IndividualEncounterComponent from '@/components/Encounter/IndividualEncounterComponent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const SAVE_UPDATE_TASK = gql`
  mutation saveUpdateTask($truentityId: String!, $taskData: TaskInput!, $taskId: String!) {
    saveUpdateTask(truentityId: $truentityId, taskData: $taskData, taskId: $taskId) {
      status
      message
    }
  }
`;

const ADD_ENCOUNTER = gql`
  mutation saveEncounter(
    $truentityId: String!
    $title: String!
    $type: String!
    $subType: String!
    $calledOn: ISO8601DateTime!
    $notes: String!
    $disposition: String!
  ) {
    saveEncounter(
      truentityId: $truentityId
      title: $title
      type: $type
      subType: $subType
      calledOn: $calledOn
      notes: $notes
      disposition: $disposition
    ) {
      call {
        id
        type
      }
    }
  }
`;

const DELETE_ENCOUNTER = gql`
  mutation deleteEncounter($encounterId: String!, $truentityId: String!) {
    deleteEncounter(encounterId: $encounterId, truentityId: $truentityId) {
      status
    }
  }
`;

const UPDATE_ENCOUNTER = gql`
  mutation UpdateEncounter($truentityId: String!, $encounterId: String!, $encounterData: EncounterInput!) {
    updateEncounter(truentityId: $truentityId, encounterId: $encounterId, encounterData: $encounterData) {
      status
    }
  }
`;

const GET_ENCOUNTERS = gql`
  query GetEncountersQuery($truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    encounters(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      encounters {
        id
        title
        type
        subType
        calledOn
        notes
        disposition
        modeOfResolution
        createdAt
        tasks {
          id
          type
          modeOfResolution
          healthPlan
          billedAmount
          wrapUpStatus
          performedOn
          isPrimaryAffiliation
          medicationName
        }
      }
    }
  }
`;

type TempEncounterObjType = {
  notes: string;
  newEncounterId: string;
  newDisposition: string;
  performedOn: Date;
};

type Props = BaseDialogProps & {
  id: any;
  medication?: any;
  task?: any;
  editMode: boolean;
  sourceType?: any;
  hideDialog: () => void;
  reloadEncounters: () => void;
  reloadTasks: () => void;
};

const NewTaskDialog = ({
  id,
  title,
  hideDialog,
  task,
  medication,
  editMode,
  sourceType,
  reloadEncounters,
  reloadTasks,
  ...props
}: Props): React.ReactElement => {
  const taskId = task?.id;

  const [saveUpdateTask, { data: dataOnSaveUpdateTask, loading: loadingOnSaveUpdateTask, error: errorOnSaveUpdateTask }] =
    useMutation(SAVE_UPDATE_TASK);
  const [getHealthPlans, { loading: loadingHealthPlans, data: healthPlans, called }] = useLazyQuery(GET_HEALTH_PLANS);
  const [healthPlanList, setHealthPlans] = useState<[]>([]);
  const [saveEncounter, { data: dataOnSaveEncounter, loading: loadingOnSaveEncounter, error: errorOnSaveEncounter }] =
    useMutation(ADD_ENCOUNTER);
  const [getEncounters, { loading: encountersLoading, data: encountersData, refetch: refetchEncountersData }] =
    useLazyQuery(GET_ENCOUNTERS);
  const [deleteEncounter, { data: dataOnDeleteEncounter }] = useMutation(DELETE_ENCOUNTER);

  const [updateEncounter, { data: dataOnUpdateEncounter }] = useMutation(UPDATE_ENCOUNTER);

  const [encounters, setEncounters] = useState<Encounter[]>([]);

  const [state, dispatch] = useReducer(reducer, emptyData);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (editMode && task) {
      if (task.type) {
        updateType({ type: task.type });
      }

      if (task.subType) {
        updateType({ subType: task.subType });
      }

      if (task.healthPlan) {
        dispatch({ type: 'health-plan', payload: task.healthPlan });
      }

      if (task.name) {
        dispatch({ type: 'name', payload: task.name });
      }

      if (task.performedOn) {
        dispatch({ type: 'performed-on', payload: task.performedOn });
      }

      if (task.status) {
        dispatch({ type: 'status', payload: task.status });
      }

      if (task.resolutionStatus) {
        dispatch({ type: 'resolution-status', payload: task.resolutionStatus });
      }

      if (task.location) {
        dispatch({ type: 'location', payload: task.location });
      }

      if (task.billedAmount) {
        dispatch({ type: 'payment', payload: task.billedAmount });
      }

      if (task.nextSteps) {
        updateNextSteps({ nextSteps: task.nextSteps });
      }
    }
  }, [task, editMode]);

  useEffect(() => {
    getEncounters({
      variables: {
        truentityId: id,
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, []);

  useEffect(() => {
    getHealthPlans({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, []);

  useEffect(() => {
    if (healthPlans?.healthPlans?.healthPlans) {
      setHealthPlans(healthPlans.healthPlans.healthPlans);
    }
  }, [healthPlans]);

  useEffect(() => {
    if (!encountersLoading && encountersData) {
      if (editMode) {
        if (encountersData.encounters.encounters.length > 0) {
          const encountersFromAPI = encountersData.encounters.encounters;
          const previouslyAssignedEncounters = task.encounters;

          if (previouslyAssignedEncounters.length > 0) {
            const encounterArrayAdditionalAttributes = encountersFromAPI.map(obj => ({
              ...obj,
              edited: false,
              assigned: previouslyAssignedEncounters.filter(encounter => encounter.id === obj.id).length > 0 ? true : false
            }));
            setEncounters(encounterArrayAdditionalAttributes);
          } else {
            const encounterArrayAdditionalAttributes = encountersFromAPI.map(obj => ({
              ...obj,
              edited: false,
              assigned: false
            }));
            setEncounters(encounterArrayAdditionalAttributes);
          }
        } else {
          setEncounters([]);
        }
      } else {
        if (encountersData.encounters.encounters.length > 0) {
          const tempEncounters = encountersData.encounters.encounters;
          const encounterArrayAdditionalAttributes = tempEncounters.map(obj => ({
            ...obj,
            edited: false,
            assigned: false
          }));
          setEncounters(encounterArrayAdditionalAttributes);
        }
      }
    }
  }, [encountersData, editMode]);

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();

      if (editMode) {
        const assignedEncountersArray: Array<string | undefined> = [];
        const formattedPerformedOnDate = moment(state.performedOn).format();

        if (encounters && encounters.length > 0) {
          const assignedEncounters = encounters.filter(e => e.assigned === true);

          if (assignedEncounters.length > 0) {
            assignedEncounters.forEach(encounter => {
              assignedEncountersArray.push(encounter?.id);
            });
          }
        }

        try {
          const task = {
            name: state.name,
            type: state.type,
            subType: 'SUB_TYPE',
            status: state.status,
            resolutionStatus: state.resolutionStatus,
            resolutionNotes: 'NOTES',
            performedOn: formattedPerformedOnDate,
            minutesTaken: 23,
            performedAt: 'at',
            nextCallScheduledFor: '2002-03-22',
            nextSteps: state.nextSteps,
            healthPlan: state.healthPlan,
            location: state.location,
            payment: state.payment,
            billableEligible: false,
            billedAmount: Number(state.billedAmount),
            validationPaymentEligible: true,
            validationPaymentAmount: true,
            notes: 'notes test',
            encounterIds: assignedEncountersArray
          };

          saveUpdateTask({
            variables: {
              taskData: {
                ...task
              },
              truentityId: id,
              taskId: taskId
            }
          }).then(response => {
            if (response.data?.saveUpdateTask?.status === 'Success') {
              reloadTasks();
            }
          });
        } catch (error) {
          const failMessage: string = editMode ? 'Failed to Update Task' : 'Failed to Create Task';
          enqueueSnackbar(failMessage, {
            variant: 'error'
          });
        }

        hideDialog();
      } else {
        const assignedEncountersArray: Array<string | undefined> = [];
        const formattedPerformedOnDate = moment(state.performedOn).format();

        if (encounters && encounters.length > 0) {
          const assignedEncounters = encounters.filter(e => e.assigned === true);

          if (assignedEncounters.length > 0) {
            assignedEncounters.forEach(encounter => {
              assignedEncountersArray.push(encounter.id);
            });
          }
        }

        try {
          const task = {
            name: state.name,
            type: state.type,
            subType: 'SUB_TYPE',
            status: state.status,
            resolutionStatus: state.resolutionStatus,
            resolutionNotes: 'NOTES',
            performedOn: formattedPerformedOnDate,
            minutesTaken: 23,
            performedAt: 'at',
            nextCallScheduledFor: '2002-03-22',
            nextSteps: state.nextSteps,
            healthPlan: state.healthPlan,
            location: state.location,
            payment: state.payment,
            billableEligible: false,
            billedAmount: Number(state.billedAmount),
            validationPaymentEligible: true,
            validationPaymentAmount: true,
            notes: 'notes test',
            encounterIds: assignedEncountersArray
          };

          saveUpdateTask({
            variables: {
              taskData: {
                ...task
              },
              truentityId: id,
              taskId: ''
            }
          }).then(response => {
            if (response.data?.saveUpdateTask?.status === 'Success') {
              reloadTasks();
            }
          });
        } catch (error) {
          console.error(error);
          const failMessage: string = editMode ? 'Failed to Update Medication' : 'Failed to Create Medication';
          enqueueSnackbar(failMessage, {
            variant: 'error'
          });
        }
      }
    },
    [state, encounters]
  );

  const updateType = ({ type, subType }: { type?: string; subType?: string }) => {
    if (type) {
      dispatch({ type: 'type', payload: type });
    }

    if (subType) {
      dispatch({ type: 'subType', payload: subType });
    }
  };

  const updateStatus = ({ status, resolutionStatus }: { status?: string; resolutionStatus?: string }) => {
    if (status) {
      dispatch({ type: 'status', payload: status });
    }

    if (resolutionStatus) {
      dispatch({ type: 'resolution-status', payload: resolutionStatus });
    }
  };

  const updateNextSteps = ({ nextSteps }: { nextSteps?: string }) => {
    if (nextSteps) {
      dispatch({ type: 'next-steps', payload: nextSteps });
    }
  };

  const [encounterObjects, setEncounterOjects] = useState<TempEncounterObjType[]>([]);

  const addNewEncounterObject = () => {
    const newUUID = uuidv4();

    const temp = {
      notes: '',
      newEncounterId: newUUID,
      newDisposition: 'NoAnswerNoVm',
      performedOn: new Date()
    };

    setEncounterOjects([...encounterObjects, temp]);
  };

  const onNotesChanged = (event, encounterId) => {
    const note = event.target.value;

    let newObjectsTemp = [...encounterObjects];
    newObjectsTemp = newObjectsTemp.filter(item => item.newEncounterId === encounterId);
    newObjectsTemp[0].notes = note;

    setEncounterOjects(newObjectsTemp);
  };

  const onDispositionChanged = (event, encounterId) => {
    const selectedDisposition = event.target.value;

    let newObjectsTemp = [...encounterObjects];
    newObjectsTemp = newObjectsTemp.filter(item => item.newEncounterId === encounterId);
    newObjectsTemp[0].newDisposition = selectedDisposition;

    setEncounterOjects(newObjectsTemp);
  };

  const onPerformedOnChanged = (event, encounterId) => {
    const selectedPerformedOnDate = event;

    let newObjectsTemp = [...encounterObjects];
    newObjectsTemp = newObjectsTemp.filter(item => item.newEncounterId === encounterId);
    newObjectsTemp[0].performedOn = selectedPerformedOnDate;

    setEncounterOjects(newObjectsTemp);
  };

  const [disableAddEncounterBtn, setDisableAddEncounterBtn] = useState(false);

  const onAddClickNewEncounter = () => {
    addNewEncounterObject();
    setDisableAddEncounterBtn(true);
  };

  const onRemoveClickEncounter = index => {
    let newObjectsTemp = [...encounterObjects];
    newObjectsTemp = newObjectsTemp.filter(item => item.newEncounterId !== index);

    setEncounterOjects(newObjectsTemp);
    setDisableAddEncounterBtn(false);
  };

  const onAddNewEncounter = () => {
    const formattedDate = moment(encounterObjects[0].performedOn).format();

    try {
      const encounter = {
        title: 'Title',
        type: 'BillableAnalysis',
        subType: 'SUB_TYPE',
        calledOn: formattedDate,
        notes: encounterObjects[0].notes,
        disposition: encounterObjects[0].newDisposition
      };

      saveEncounter({
        variables: {
          ...encounter,
          truentityId: id
        }
      })
        .then(response => {
          setDisableAddEncounterBtn(false);
          setEncounterOjects([]);

          getEncounters({
            variables: {
              truentityId: id,
              pageNum: 0,
              pageSize: DEFAULT_PAGE_SIZE
            }
          });
        })
        .catch(error => {
          console.log('error ? ', error);
        });
    } catch (error) {
      console.error(error);
      const failMessage = 'Failed to Create Encounter';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  const dynamicEncounterComponents = () => {
    return encounterObjects.map((el, i) => (
      <>
        <Grid container spacing={1} pb={1} pt={1} key={i}>
          <Grid item xs={6}>
            <InputRow>
              <TruentityDatePicker
                type={'time'}
                label="Performed On"
                format="YYYY-MM-DD HH:mm:ss"
                value={el.performedOn}
                onChange={date => onPerformedOnChanged(date, el.newEncounterId)}
              />
            </InputRow>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size="small">
              <SelectList
                labelId={'newDisposition'}
                id="type"
                label="Disposition"
                options={DISPOSITION_TYPES}
                placeholder="Select an option..."
                value={el.newDisposition}
                defaultValue=""
                onChange={input => onDispositionChanged(input, el.newEncounterId)}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={8}>
            <FormControl fullWidth size="small">
              <InputRow>
                <TruentityTextField
                  label="Notes"
                  type="text"
                  multiline
                  rows={2}
                  maxRows={4}
                  defaultValue={el.notes}
                  onChange={input => {
                    onNotesChanged(input, el.newEncounterId);
                  }}
                />
              </InputRow>
            </FormControl>
          </Grid>

          <Grid item xs={4} sx={{ margin: 'auto' }}>
            <Stack direction={'row'} spacing={1} sx={{ justifyContent: 'center' }}>
              <Button
                onClick={() => {
                  onRemoveClickEncounter(el.newEncounterId);
                }}
                a11yLabel="Cancel"
                appearance="secondary"
                variant="contained"
                startIcon={<RemoveCircleIcon />}
                fullWidth={false}
              />

              <Button
                onClick={onAddNewEncounter}
                a11yLabel="Add"
                appearance="plainBlur"
                variant="contained"
                startIcon={<AddBoxIcon />}
                fullWidth={false}
              />
            </Stack>
          </Grid>
        </Grid>
        <Divider />
      </>
    ));
  };

  const [expanded, setExpanded] = React.useState('');

  const handleChangePanel = panel => (evt, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [deleteConfirmationPopoverEl, setDeleteConfirmationPopoverEl] = React.useState(null);

  const [selectedEncounterToBeRemoved, setSelectedEncounterToBeRemoved] = useState('');

  const removeEncounter = (encounterId, event) => {
    setDeleteConfirmationPopoverEl(event.currentTarget);
    setSelectedEncounterToBeRemoved(encounterId);
  };

  const onRemoveEncounterConfirmed = encounterId => {
    setDeleteConfirmationPopoverEl(null);

    deleteEncounter({
      variables: {
        truentityId: id,
        encounterId: selectedEncounterToBeRemoved
      }
    }).then(response => {
      setSelectedEncounterToBeRemoved('');
      refetchEncountersData();
    });
  };

  const onEditEncounterNotes = (index, encounterUUID, event) => {
    const note = event.target.value;

    const newObjectsTemp = [...encounters];

    const newObj = Object.assign({}, newObjectsTemp[index]);
    newObj.notes = note;
    newObj.edited = true;

    newObjectsTemp[index] = newObj;

    setEncounters(newObjectsTemp);
  };

  const onEditEncounterPerformedOn = (index, date) => {
    const calledOn = date;

    const temporaryEncounters = [...encounters];

    const copiedEncounterObject = Object.assign({}, temporaryEncounters[index]);
    copiedEncounterObject.calledOn = calledOn;
    copiedEncounterObject.edited = true;

    temporaryEncounters[index] = copiedEncounterObject;

    setEncounters(temporaryEncounters);
  };

  const onEditEncounterDisposition = (index, event) => {
    const selectedDisposition = event.target.value;

    const newObjectsTemp = [...encounters];

    const newObj = Object.assign({}, newObjectsTemp[index]);
    newObj.disposition = selectedDisposition;
    newObj.edited = true;

    newObjectsTemp[index] = newObj;

    setEncounters(newObjectsTemp);
  };

  const editAnEncounter = (index, encounterId) => {
    const editedObj = {
      notes: encounters[index].notes,
      calledOn: encounters[index].calledOn,
      disposition: encounters[index].disposition
    };

    updateEncounter({
      variables: {
        truentityId: id,
        encounterId: encounterId,
        encounterData: editedObj
      }
    })
      .then(response => {
        getEncounters({
          variables: {
            truentityId: id,
            pageNum: 0,
            pageSize: DEFAULT_PAGE_SIZE
          }
        });
      })
      .catch(error => {
        console.log('error ? ', error);
      });
  };

  const onAssignEncounter = (index, encounterId, event) => {
    const checked = event.target.checked;

    const temporaryEncounters = [...encounters];

    const newObj = Object.assign({}, temporaryEncounters[index]);
    newObj.assigned = checked;

    temporaryEncounters[index] = newObj;

    setEncounters(temporaryEncounters);
  };

  return (
    <>
      <BaseDialog title={title} hideDialog={hideDialog} maxWidth={'xl'} fullWidth {...props}>
        <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
          <form onSubmit={handleOnSubmit}>
            <Grid container spacing={1} pb={2}>
              <Grid item xs={6}>
                <Card sx={{ minWidth: 275, marginTop: '10px' }}>
                  <CardContent>
                    <Grid container spacing={1} pb={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <SelectList
                            id="type"
                            label="Type"
                            options={TASK_TYPES}
                            placeholder="Select an option..."
                            value={state.type}
                            onChange={input => updateType({ type: input?.target?.value })}
                            required
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <SelectList
                          id={'healthPlan'}
                          label="Health Plan"
                          options={healthPlanList.map(x => ({
                            label: x?.orgName,
                            value: x?.orgName
                          }))}
                          placeholder="Select a health plan..."
                          value={state.healthPlan}
                          onChange={input => {
                            dispatch({ type: 'health-plan', payload: input.target.value });
                          }}
                          required
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} pb={2}>
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <InputRow>
                            <TruentityTextField
                              label="Description"
                              type="text"
                              multiline
                              rows={2}
                              maxRows={4}
                              value={state.name}
                              defaultValue={state.name}
                              onChange={input => {
                                dispatch({
                                  type: 'name',
                                  payload: input.target.value
                                });
                              }}
                            />
                          </InputRow>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={1} pb={2} pt={2}>
                      <Grid item xs={6}>
                        <InputRow>
                          <TruentityDatePicker
                            label="Performed On"
                            format="YYYY-MM-DD"
                            value={state.performedOn}
                            onChange={date => {
                              dispatch({ type: 'performed-on', payload: date });
                            }}
                          />
                        </InputRow>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <SelectList
                            id="resolutionStatus"
                            label="Status"
                            options={TASK_RESOLUTION_STATUSES}
                            placeholder="Select an option..."
                            value={state.status}
                            defaultValue=""
                            onChange={input => updateStatus({ status: input.target.value })}
                            required
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <SelectList
                            id="resolutionStatus"
                            label="Resolution Status"
                            options={TASK_RESOLUTION_STATUSES}
                            placeholder="Select an option..."
                            value={state.resolutionStatus}
                            defaultValue=""
                            onChange={input => updateStatus({ resolutionStatus: input.target.value })}
                            required
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <SelectList
                            id="nextSteps"
                            label="Next Step"
                            options={TASK_NEXT_STEPS}
                            placeholder="Select an option..."
                            value={state.nextSteps}
                            defaultValue=""
                            onChange={input => updateNextSteps({ nextSteps: input.target.value })}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={1} pb={2} pt={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <TruentityTextField
                            id={'location'}
                            label="Location"
                            type="text"
                            value={state.location}
                            defaultValue={state.location}
                            onChange={input => {
                              dispatch({ type: 'location', payload: input.target.value });
                            }}
                            required
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <TruentityTextField
                            id={'payment'}
                            label="Payment"
                            type="number"
                            min={0}
                            step={1}
                            value={state.billedAmount}
                            defaultValue={state.billedAmount}
                            onChange={input => {
                              dispatch({ type: 'payment', payload: input.target.value });
                            }}
                            startIcon={<AttachMoneyIcon />}
                            required
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card sx={{ minWidth: 275, marginTop: '10px' }}>
                  <Stack sx={{ cursor: 'pointer', margin: '15px' }} spacing={1} direction="row">
                    <H3 fontWeight={'600'} color={color.truentityBlue[300]}>
                      Encounters
                    </H3>
                  </Stack>

                  <CardContent>
                    {encounters.length === 0 && (
                      <Grid container>
                        <Grid item sm={12}>
                          <Box display="flex" justifyContent="center">
                            <H5 fontWeight={'500'} color={color.primaryMain}>
                              No encounters have been created. You may save this task without any encounters
                            </H5>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    {encounters.length > 0 && encounters.filter(en => en.assigned === true).length === 0 && (
                      <Grid container pt={1} pb={4}>
                        <Grid item sm={12}>
                          <Box display="flex" justifyContent="center">
                            <H5 fontWeight={'500'} color={color.primaryMain}>
                              No encounters have been assigned. You may save this task without any assigned encounters
                            </H5>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container spacing={2} pb={2} alignItems="center" justifyContent="center">
                      <Grid item xs={12}>
                        {encounters.map((item, index) => (
                          <IndividualEncounterComponent
                            index={index}
                            item={item}
                            task={task}
                            editMode={editMode}
                            expanded={expanded}
                            handleChangePanel={handleChangePanel}
                            onEditEncounterPerformedOn={onEditEncounterPerformedOn}
                            onEditEncounterDisposition={onEditEncounterDisposition}
                            onEditEncounterNotes={onEditEncounterNotes}
                            onAssignEncounter={onAssignEncounter}
                            editAnEncounter={editAnEncounter}
                            removeEncounter={removeEncounter}
                            onRemoveEncounterConfirmed={onRemoveEncounterConfirmed}
                            deleteConfirmationPopoverEl={deleteConfirmationPopoverEl}
                            setDeleteConfirmationPopoverEl={setDeleteConfirmationPopoverEl}
                          />
                        ))}
                      </Grid>
                    </Grid>

                    <Divider />

                    <Grid container spacing={1} pb={2} pt={2}>
                      <Grid item xs={4}>
                        <Stack direction="row" spacing={2}>
                          <Button
                            disabled={disableAddEncounterBtn}
                            variant="contained"
                            startIcon={<AddCircleOutlineRoundedIcon />}
                            onClick={onAddClickNewEncounter}
                          >
                            New Encounter
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>

                    {dynamicEncounterComponents()}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button type="submit" a11yLabel="Submit" appearance="primary" />
              <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
            </DialogActions>
          </form>
        </DialogContent>
      </BaseDialog>
    </>
  );
};

export default NewTaskDialog;
