import { DialogContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import ReactJson, { OnCopyProps } from 'react-json-view';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  jsonUrl: string;
};

type JSONData = { [key: string]: any } | null;

const ConnectiveJsonDialog = ({ title, jsonUrl, hideDialog, ...props }: Props) => {
  const [jsonData, setJsonData] = useState<JSONData>(null);

  useEffect(() => {
    fetch(jsonUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setJsonData(data))
      .catch(error => console.error('Error fetching JSON data:', error));
  }, [jsonUrl]);

  const handleCopy = (copy: OnCopyProps) => {
    navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="lg">
      <DialogContent>
        {jsonData ? (
          <ReactJson enableClipboard={handleCopy} src={jsonData} collapsed={true} />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <CircularProgress />
          </div>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default ConnectiveJsonDialog;
