import { useMutation } from '@apollo/client';
import type { JSXElementConstructor, ReactElement, ReactNode, SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { H3 } from '@/components/Typography';
import { TASK_MODE_OF_RESOLUTION_TYPES, TASK_TYPES, TASK_WRAP_UP_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import { formatDateIgnoreTZ } from '@/util/format';
import { Paper, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';

import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import AdministrationContext from '@/context/administrationContext';
import { UPDATE_TASKS_VALIDATION_STATUS } from '@/graphql/taskEncounter';
import { TaskStatuses } from '@/types/tasks';
import { toDateOrNull } from '@/util/date';
import { useSnackbar } from 'notistack';
import TaskActions from './taskActions';

type FilterTab = {
  icon: string | ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  iconPosition: 'bottom' | 'top' | 'start' | 'end' | undefined;
  label: ReactNode;
  status: TaskStatuses.Pending | TaskStatuses.Completed;
};

const AssignedTasks = () => {
  const filterTabs: FilterTab[] = [
    {
      icon: <PendingIcon />,
      iconPosition: 'start',
      label: 'Pending',
      status: TaskStatuses.Pending
    },
    {
      icon: <TaskAltIcon />,
      iconPosition: 'start',
      label: 'Completed',
      status: TaskStatuses.Completed
    }
  ];

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [updateTasksValidationStatus] = useMutation(UPDATE_TASKS_VALIDATION_STATUS);

  //TODO:  This is not really doing any filtering based on Task status since the respective objects below are not retriving any of the tasks, task list is always empty
  //See GET_CLIENT_ORGANIZATIONS
  const { organization, clientStore, setReloadOrganization } = useContext(AdministrationContext);

  const [selectedTasks, setSelectedTasks] = useState<GridRowId[]>([]);
  const [organizationalView, setOrganizationalView] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  const [tab, setTab] = useState<number>(0);

  const [filteredTasks, setFilteredTasks] = useState<any[]>([]);

  useEffect(() => {
    if (location.pathname.includes('/stores')) {
      setOrganizationalView(false);
    } else {
      setOrganizationalView(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (organizationalView) {
      setFilteredTasks(organization?.tasks?.length > 0 ? organization?.tasks : []);
    } else {
      setFilteredTasks(clientStore?.tasks?.length > 0 ? clientStore?.tasks : []);
    }
  }, [organizationalView, organization, clientStore]);

  useEffect(() => {
    setFilteredTasks(filteredTasks.filter(t => t.status === filterTabs[tab].status));
  }, [tab]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        sortable: true,
        flex: 1,
        minWidth: 30,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_TYPES.filter(type => type.value === cellValues.row.type);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'subType',
        headerName: 'Sub Type',
        sortable: true,
        flex: 1,
        minWidth: 210,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = UPDATED_SUB_TASK_TYPES.filter(type => type.value === cellValues.row.subType);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={8} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'billedAmount',
        headerName: 'Billed Amount',
        sortable: true,
        flex: 1,
        minWidth: 80,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedBilledAmount = `$ ${cellValues.row.billedAmount}`;

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {formattedBilledAmount}
            </Stack>
          );
        }
      },
      {
        field: 'modeOfResolution',
        headerName: 'Mode Of Resolution',
        sortable: true,
        flex: 1,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === cellValues.row.modeOfResolution);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'wrapUp',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        minWidth: 130,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_WRAP_UP_TYPES.filter(type => type.value === cellValues.row.wrapUpStatus);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        sortable: true,
        valueGetter: params => params.row.healthPlan,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'performedOn',
        headerName: 'Performed On',
        type: 'date',
        sortable: true,
        valueGetter: params => toDateOrNull(params.row.performedOn),
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedDate = formatDateIgnoreTZ(cellValues.row.performedOn, 'YYYY-MM-DD');
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {cellValues.row.performedOn ? formattedDate : ''}
            </Stack>
          );
        }
      },
      {
        field: 'completedByAdmin',
        headerName: 'Performed By',
        sortable: true,
        valueGetter: params => params.row.completedByAdmin,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'noOfEncounters',
        headerName: '# of Encounters ',
        sortable: true,
        valueGetter: params => params.row.encounters.length,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      }
    ],
    []
  );

  const handleTabChange = useCallback((event: SyntheticEvent, selectedTabIndex: number) => {
    setTab(selectedTabIndex);
  }, []);

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const updateValidationStatus = async validationStatus => {
    try {
      const result = await updateTasksValidationStatus({
        variables: {
          taskIds: selectedTasks,
          validationStatus: validationStatus
        }
      });
      const { status } = result.data!.updateTasksValidationStatus;

      showSnackbar('Successfully updated the Validation Status.', 'success');

      onReset();
    } catch (err) {
      console.log(err);

      showSnackbar('Something went wrong.', 'error');
    }
  };

  const onReset = () => {
    setSelectedTasks([]);
  };

  return (
    <Stack spacing={2}>
      <H3 textAlign="center" css={{ width: '100%' }}>
        Task List
      </H3>

      <Stack
        spacing={2}
        sx={{
          backgroundColor: '#fff',
          borderBottom: 1,
          borderColor: 'divider',
          paddingLeft: '10px',
          paddingRight: '10px'
        }}
      >
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          {filterTabs.map(tab => (
            <Tab icon={tab.icon} iconPosition={tab.iconPosition} label={tab.label} sx={{ textTransform: 'capitalize' }} />
          ))}
        </Tabs>
      </Stack>

      <Paper component={Stack} direction="column" spacing={2}>
        {selectedTasks?.length > 0 && <TaskActions selectedTasks={selectedTasks} updateValidationStatus={updateValidationStatus} />}

        <div style={{ display: 'flex' }}>
          {/* TODO: This cannot be paginated server side since the data is part of object not controlled by this component */}
          <TruentityDataGrid
            name={'dg-tasks'}
            autoHeight
            columnVisibilityModel={{
              completedByAdmin: tab === 0
            }}
            rows={filteredTasks}
            rowCount={rowCountState}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            paginationMode="client"
            checkboxSelection
            onRowSelectionModelChange={e => {
              setSelectedTasks(e);
            }}
            rowSelectionModel={selectedTasks}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default AssignedTasks;
