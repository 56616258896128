import { useEffect, useState } from 'react';

export interface Timer extends State {
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
}
interface State {
  id: number;
  label: string;
  isRunning: boolean;
  timeElapsed: number;
  timerStart?: Date;
  timerEnd?: Date;
}

const initialTimerState: State = {
  id: -1,
  label: '',
  isRunning: false,
  timeElapsed: 0
};

const useTimer = (id: number, label: string) => {
  const [timer, setTimer] = useState<State>({
    ...initialTimerState,
    id,
    label
  });

  useEffect(() => {
    let intervalId = 0;

    if (timer.isRunning) {
      intervalId = window.setInterval(() => {
        setTimer(prevTimer => ({
          ...prevTimer,
          timeElapsed: prevTimer.timeElapsed + 1
        }));
      }, 1000);
    } else {
      window.clearInterval(intervalId);
    }

    return () => {
      if (intervalId !== null) {
        window.clearInterval(intervalId);
      }
    };
  }, [timer.isRunning]);

  const startTimer = () => {
    setTimer(prevTimer => ({
      ...prevTimer,
      timerStart: new Date(),
      isRunning: true
    }));
  };

  const stopTimer = () => {
    setTimer(prevTimer => ({
      ...prevTimer,
      isRunning: false
    }));
  };

  const resetTimer = () => {
    setTimer(prevTimer => ({
      ...prevTimer,
      isRunning: false,
      timerEnd: new Date(),
      timeElapsed: 0
    }));
  };

  return {
    ...timer,
    startTimer,
    stopTimer,
    resetTimer
  };
};

export default useTimer;
