import DropdownMenu from '@/components/DropdownMenu';
import { COLUMN_TITLES } from '@/types/triage-medication-types';
import { removeDashes } from '@/util/string';
import { MenuItemData } from 'mui-nested-menu';
import { useEffect, useState } from 'react';
import { AdherenceStatuses } from '../../../components/AdherenceSection';

export type SelectableFilter = {
  category: string;
  value: any;
};

type Props = {
  setSelectedFilter: any;
  selectedFilter: SelectableFilter;
  defaultFilter: SelectableFilter;
};

const TriageFilters = ({ setSelectedFilter, selectedFilter, defaultFilter }: Props) => {
  const [menuData, setMenuData] = useState<MenuItemData>({ label: 'Filters', items: [] });

  const medicationStatusFilterAction: MenuItemData[] = Object.values(COLUMN_TITLES).map(title => {
    const action: MenuItemData = {
      label: title,
      callback: () => setSelectedFilter({ category: 'medication-status', value: title })
    };

    return action;
  });

  const adherenceFilterAction: MenuItemData[] = Object.entries(AdherenceStatuses).map((data: string[]) => {
    const id = data[0];
    const label = data[1];

    const action: MenuItemData = {
      label,
      callback: () => setSelectedFilter({ category: 'adherence', value: id })
    };

    return action;
  });

  const supplementFilterAction: MenuItemData[] = ['Yes', 'No'].map(title => {
    const action: MenuItemData = {
      label: title,
      callback: () => setSelectedFilter({ category: 'supplement', value: title })
    };

    return action;
  });

  const verifiedStatusFilterAction: MenuItemData[] = ['Yes', 'No'].map(title => {
    const action: MenuItemData = {
      label: title,
      callback: () => setSelectedFilter({ category: 'verified', value: title })
    };

    return action;
  });

  useEffect(() => {
    setMenuData({
      label: selectedFilter ? `${removeDashes(selectedFilter.category)} - ${removeDashes(selectedFilter.value)}` : 'Filters',
      items: [
        {
          label: 'Status',
          items: medicationStatusFilterAction
        },
        {
          label: 'Adherence',
          items: adherenceFilterAction
        },
        {
          label: 'Supplement',
          items: supplementFilterAction
        },
        {
          label: 'Verified',
          items: verifiedStatusFilterAction
        },
        {
          label: 'Clear Filter',
          callback: () => setSelectedFilter(defaultFilter)
        }
      ]
    });
  }, [selectedFilter]);

  return (
    <DropdownMenu
      menuItemsData={menuData}
      MenuProps={{ elevation: 3 }}
      ButtonProps={{ variant: 'outlined', color: 'primary', sx: { minWidth: '150px' } }}
    />
  );
};

export default TriageFilters;
