import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { unstable_useBlocker as useBlocker } from 'react-router';

type ConfirmNavigateAwayDialogProps = {
  when: boolean | (() => boolean);
  title: string;
  message: string;
  onNavigateAway: () => void;
};

function ConfirmNavigateAwayDialog({ title, message, onNavigateAway, when }: ConfirmNavigateAwayDialogProps) {
  const blocker = useBlocker(when);
  const handleClose = () => blocker.reset && blocker.reset();
  const handleConfirm = () => {
    blocker.proceed && blocker.proceed();
    onNavigateAway();
  };

  return (
    <ConfirmDialog
      open={blocker?.state === 'blocked'}
      onClose={handleClose}
      message={message}
      title={title}
      onAgree={handleConfirm}
      onDisagree={handleClose}
    />
  );
}

export default ConfirmNavigateAwayDialog;
