import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import AddAccountGoalDialog from '@/components/Dialogs/AddAccountGoalDialog';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import PatientSignOffDialog from '@/components/Dialogs/PatientSignOffDialog';
import PdfDialog from '@/components/Dialogs/PdfDialog';
import TruentityTextField from '@/components/TruentityTextField';
import { H3, H4, H5, Small } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { GetAccountOrdersResponse, GetAccountSmartGoalResponse } from '@/graphql/remotePatientMonitoring';
import {
  GET_ACCOUNT_ORDERS,
  GET_ACCOUNT_SMART_GOAL,
  UpdateRpmSetupResponse,
  UPDATE_ACCOUNT_ORDER,
  UPDATE_ACCOUNT_SMART_GOAL,
  UPDATE_RPM_SETUP
} from '@/graphql/remotePatientMonitoring';
import type { GetDocumentPreviewResponse, GetRpmAccountSummaryResponse } from '@/graphql/rpmWorkflow';
import { GET_DOCUMENT_PREVIEW, GET_RPM_ACCOUNT_SUMMARY, REGENERATE_ACCOUNT_CARE_PLAN_REPORT } from '@/graphql/rpmWorkflow';
import useDebounce from '@/hooks/useDebounce';
import { PatientsData } from '@/routes/Patients/patients';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { RpmDiagnoses } from '@/types/administration';
import {
  AccountOrderTypes,
  RpmAccountSmartGoalStatuses,
  RpmApprovalStatusTypes,
  RpmWorkflowTab,
  type AccountSmartGoalType
} from '@/types/remotePatientMonitoring';
import { formatDateAndTime } from '@/util/format';
import { updateRpmTabStatus } from '@/util/rpm';
import { formatFromSnakeCase, unknown } from '@/util/string';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { VisibilityOff } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton, Stack } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import type { MenuItemData } from 'mui-nested-menu';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import BaseRpmWorkflowTabContent from './BaseRpmWorkflowTabContent';

export type FormValues = {
  programDescription: string;
  conditions: string[];
  outcomes: string[];
  goals: string[];
  barriers: string[];
};

export const defaultValues: FormValues = {
  programDescription: '',
  conditions: [],
  outcomes: [],
  goals: [],
  barriers: []
};

interface FetchDocumentPreviewProps {
  s3Key: string;
  type: 'CarePlan' | 'AccountCareSummary';
}

const CarePlan = () => {
  const { showModal, hideModal } = useModal();
  const { rpmSetupTabs, activeRpmSetupTab, editRpmStatus } = useRpmSetupStore();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const { enqueueSnackbar } = useSnackbar();

  const isReadOnly = useMemo(() => activeRpmSetupTab?.isReadOnly || false, [activeRpmSetupTab]);

  const { id: truentityId } = useParams();

  const confirmationModalId = useRef<string | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [bloodPressureChecked, setBloodPressureChecked] = useState<boolean>(false);
  const [bloodSugarChecked, setBloodSugarChecked] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

  const {
    data: rpmAccountSummaryData,
    error: getRpmAccountSummaryError,
    loading: loadingRpmAccountSummary,
    refetch: reFetchRpmAccountSummary
  } = useQuery<GetRpmAccountSummaryResponse>(GET_RPM_ACCOUNT_SUMMARY, {
    variables: {
      truentityId
    },
    skip: !truentityId,
    fetchPolicy: 'cache-and-network'
  });

  const {
    data: accountSmartGoalData,
    loading: accountSmartGoalLoading,
    refetch: accountSmartGoalRefetch
  } = useQuery<GetAccountSmartGoalResponse>(GET_ACCOUNT_SMART_GOAL, {
    variables: {
      truentityId,
      searchTerm: debouncedSearchTerm,
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: rpmAccountOrderData } = useQuery<GetAccountOrdersResponse>(GET_ACCOUNT_ORDERS, {
    variables: {
      truentityId
    },
    skip: !truentityId,
    fetchPolicy: 'cache-and-network'
  });

  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW);

  const accountSmartGoals = useMemo(() => accountSmartGoalData?.getAccountSmartGoals?.accountGoals || [], [accountSmartGoalData]);

  const [regenerateCarePlanReport, { loading: loadingRegenerateCarePlanReport }] = useMutation(REGENERATE_ACCOUNT_CARE_PLAN_REPORT, {
    variables: {
      truentityId
    },
    onCompleted: () => {
      reFetchRpmAccountSummary();
    }
  });

  const [updateAccountSmartGoal] = useMutation(UPDATE_ACCOUNT_SMART_GOAL, {
    variables: {
      truentityId
    },
    onCompleted: response => {
      enqueueSnackbar(response.updateAccountSmartGoal.message, { variant: 'success' });
      accountSmartGoalRefetch();
      regenerateCarePlanReport();
      confirmationModalId.current && hideModal(confirmationModalId.current);
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to update the goal. Please try again later.', { variant: 'error' });
      }
    }
  });

  const [updateAccountOrder] = useMutation(UPDATE_ACCOUNT_ORDER, {
    onCompleted: data => {
      if (data.updateAccountOrder.errors) {
        enqueueSnackbar('Failed to update order status', { variant: 'error' });
      } else {
        regenerateCarePlanReport();
        enqueueSnackbar('Order status updated successfully', { variant: 'success' });
      }
    },
    onError: () => {
      enqueueSnackbar('Error occurred while updating order status', { variant: 'error' });
    }
  });

  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const handleArchiveGoals = useCallback(
    async (goalId: GridRowId) => {
      updateAccountSmartGoal({
        variables: {
          accountSmartGoalId: goalId,
          isArchived: true
        }
      });
    },
    [updateAccountSmartGoal]
  );

  const handleConfirmationModal = useCallback(
    (goalId: GridRowId) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Archive Goal Confirmation',
        message: 'Are you sure you want to archive this goal? This action cannot be undone.',
        onAgree: () => handleArchiveGoals(goalId),
        onDisagree: () => modal.hide()
      });
      confirmationModalId.current = modal.id;
    },
    [handleArchiveGoals, showModal]
  );

  const handleGoalStatus = useCallback(
    (goalId: GridRowId, status: string) => {
      updateAccountSmartGoal({
        variables: {
          accountSmartGoalId: goalId,
          status
        }
      });
    },
    [updateAccountSmartGoal]
  );

  const handleAddGoal = () => {
    const modal = showModal(AddAccountGoalDialog, {
      title: 'Add Goals',
      truentityId,
      reasonForVisit: patientInfo?.accountRpmMonitoredCondition,
      hideDialog: () => modal.hide(),
      onSuccess: () => {
        accountSmartGoalRefetch();
        regenerateCarePlanReport();
        modal.hide();
      }
    });
  };

  const fetchDocumentPreview = useCallback(
    async ({ s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: s3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          fetch(`${documentResponse?.data?.documentPreview?.documentUrl}`)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
              const modal = showModal(PdfDialog, {
                title: 'Care Plan Report Preview',
                pdfUrl: url,
                hideDialog: () => {
                  modal.hide();
                }
              });
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getDocumentPreview, showModal]
  );

  const hasRpmCarePlanDocument = useMemo(
    () => (rpmAccountSummaryData?.getRpmAccountSummary?.carePlanS3Key?.length ?? 0) > 0,
    [rpmAccountSummaryData]
  );

  const hasClinicalDiagnoses = useMemo(() => (patientInfo?.accountRpmDiagnosis?.length ?? 0) > 0, [patientInfo]);

  const hasGoals = useMemo(() => (accountSmartGoalData?.getAccountSmartGoals?.accountGoals?.length ?? 0) > 0, [accountSmartGoalData]);

  const signOffAt = useCallback(() => rpmAccountSummaryData?.getRpmAccountSummary?.signOffAt, [rpmAccountSummaryData]);

  const dropDownMenuItems = useCallback(
    (goalId: GridRowId): MenuItemData[] => {
      return Object.values(RpmAccountSmartGoalStatuses).map(status => {
        return {
          label: formatFromSnakeCase(status),
          callback: () => handleGoalStatus(goalId, status)
        };
      });
    },
    [handleGoalStatus]
  );

  const getButtonColor = useCallback((value: string) => {
    switch (value) {
      case RpmAccountSmartGoalStatuses.PENDING:
        return 'info';
      case RpmAccountSmartGoalStatuses.MET:
        return 'success';
      case RpmAccountSmartGoalStatuses.PARTIALLY_MET:
        return 'warning';
      case RpmAccountSmartGoalStatuses.NOT_MET:
        return 'error';
      default:
        return 'info';
    }
  }, []);

  const handleCheckboxChange = (orderType: string, checked: boolean) => {
    if (orderType === AccountOrderTypes.RPM_BLOOD_PRESSURE_AND_HEART_RATE) {
      setBloodPressureChecked(checked);
    } else if (orderType === AccountOrderTypes.RPM_BLOOD_SUGAR) {
      setBloodSugarChecked(checked);
    }

    updateAccountOrder({
      variables: {
        truentityId,
        orderChecked: checked,
        orderType
      }
    }).catch(error => {
      console.error('Error updating order:', error);
    });
  };

  const checkGoalDisabled = useCallback(() => {
    return patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED || isReadOnly || !hasClinicalDiagnoses;
  }, [isReadOnly, patientInfo]);

  const checkOrdersDisabled = useCallback(() => {
    return checkGoalDisabled() || patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED || isReadOnly || !hasGoals;
  }, [isReadOnly, patientInfo, accountSmartGoalData]);

  const checkPatientSignOffDisabled = useCallback(() => {
    return (
      checkGoalDisabled() ||
      patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED ||
      isReadOnly ||
      (!bloodPressureChecked && !bloodSugarChecked)
    );
  }, [isReadOnly, patientInfo, bloodPressureChecked, bloodSugarChecked]);

  const showPatientSignOffDialog = () => {
    const modal = showModal(PatientSignOffDialog, {
      title: 'Visit Sign Off',
      hideDialog: () => {
        modal.hide();
        reFetchRpmAccountSummary();
      },
      patientInfo: patientInfo,
      onSignOffComplete: (success: boolean) => {
        if (success) {
          setReloadPatientInfo(true);
          updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CARE_PLAN, editRpmStatus, updateRpmSetup, true).catch(err => console.error(err));
          regenerateCarePlanReport();
        }
      }
    });
  };

  const getCustomRowId = useCallback((row: RpmDiagnoses) => {
    return `${row.diagnosisCode}-${row.diagnosesName}}`;
  }, []);

  const diagnosisColumns: GridColDef<PatientsData[]>[] = useMemo(
    () => [
      {
        field: 'diagnosesName',
        headerName: 'Diagnoses Name',
        minWidth: 350,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'diagnosesCode',
        headerName: 'Diagnoses Code',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      }
    ],
    [patientInfo]
  );

  const smartGoalsColumns: GridColDef<AccountSmartGoalType>[] = useMemo(
    () => [
      {
        field: 'goalContent',
        headerName: 'Goal',
        minWidth: 350,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateAndTime(params.row.createdAt) || unknown()
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateAndTime(params.row.updatedAt) || unknown()
      },
      {
        field: 'action',
        headerName: 'Action',
        maxWidth: 80,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: cellValue => {
          return (
            <IconButton onClick={() => handleConfirmationModal(cellValue.id)}>
              <VisibilityOff fontSize="small" />
            </IconButton>
          );
        }
      }
    ],
    [dropDownMenuItems, getButtonColor, handleConfirmationModal]
  );

  useEffect(() => {
    if (getRpmAccountSummaryError) {
      enqueueSnackbar('Failed to retrieve Care Plan document ', { variant: 'error' });
    }
  }, [enqueueSnackbar, getRpmAccountSummaryError]);

  useEffect(() => {
    if (rpmAccountOrderData && rpmAccountOrderData.getAccountOrders) {
      rpmAccountOrderData.getAccountOrders.map(order => {
        if (order.orderType === AccountOrderTypes.RPM_BLOOD_PRESSURE_AND_HEART_RATE && order.orderChecked) {
          setBloodPressureChecked(true);
        }
        if (order.orderType === AccountOrderTypes.RPM_BLOOD_SUGAR && order.orderChecked) {
          setBloodSugarChecked(true);
        }
      });
    }
  }, [rpmAccountOrderData]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <H3>Assessment and Plan</H3>
            <Stack alignItems="flex-end">
              <Button
                disabled={checkPatientSignOffDisabled()}
                sx={{ backgroundColor: color.secondaryMain }}
                size="small"
                onClick={showPatientSignOffDialog}
              >
                Sign off
              </Button>
              {checkPatientSignOffDisabled() && signOffAt() && (
                <Small marginTop={1} color={color.primaryDark} sx={{ display: 'inline' }}>
                  Signed off on {formatDateAndTime(signOffAt())}
                </Small>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Box mt={1}>
          <Stack direction="row" justifyContent="space-between">
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%',
                height: 'auto',
                my: 2,
                p: 1,
                border: '1px solid',
                borderColor: theme => theme.palette.divider,
                backgroundColor: color.grey50,
                borderRadius: 1
              }}
            >
              <Box flex={'1'} display={'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center">
                {hasRpmCarePlanDocument ? (
                  <>
                    <Box
                      sx={{ width: '66px' }}
                      component={'img'}
                      src={'https://truentity-general.s3.amazonaws.com/Indycare-CarePlan-Thumbnail.png'}
                    />
                    <H5
                      sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: color.black100,
                        marginLeft: 2
                      }}
                    >
                      Preview Care Plan Document
                    </H5>
                  </>
                ) : (
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: color.black100,
                      marginLeft: 2
                    }}
                  >
                    Care Plan Document
                  </H5>
                )}
              </Box>
              <Stack flexDirection="row" justifyContent="flex-end" columnGap={2}>
                <Button
                  variant="outlined"
                  label={hasRpmCarePlanDocument ? 'Regenerate' : 'Generate'}
                  disabled={loadingRpmAccountSummary || accountSmartGoals.length === 0 || isReadOnly}
                  isLoading={loadingRegenerateCarePlanReport}
                  onClick={() => regenerateCarePlanReport()}
                />
                {hasRpmCarePlanDocument && (
                  <Button
                    sx={{ width: '86px' }}
                    label="View"
                    disabled={loadingRegenerateCarePlanReport || loadingRpmAccountSummary}
                    onClick={() => {
                      fetchDocumentPreview({
                        s3Key: rpmAccountSummaryData?.getRpmAccountSummary?.carePlanS3Key || '',
                        type: 'CarePlan'
                      });
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box mt={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <H4>Clinical Diagnoses</H4>
          </Stack>
          <TruentityDataGrid
            name={'dg-clinical-diagnoses'}
            autoHeight
            columns={diagnosisColumns}
            disableRowSelectionOnClick
            sx={{ backgroundColor: '#ffffff' }}
            getRowId={getCustomRowId}
            rows={patientInfo?.accountRpmDiagnosis || []}
          />
        </Box>
        <Box mt={4}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <H4>Goals</H4>
            <Button size="small" onClick={handleAddGoal} disabled={checkGoalDisabled()}>
              Add Goal
            </Button>
          </Stack>
          <TruentityDataGrid
            name={'dg-smart-goals'}
            autoHeight
            customFilter={
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <TruentityTextField
                  size="small"
                  name="search"
                  label="Search"
                  sx={{ width: '50%' }}
                  onChange={input => setSearchTerm(input.target.value)}
                />
              </Stack>
            }
            rows={accountSmartGoalData?.getAccountSmartGoals?.accountGoals || []}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            paginationMode="server"
            columns={smartGoalsColumns}
            disableRowSelectionOnClick
            sx={{ backgroundColor: '#ffffff' }}
            disabled={checkGoalDisabled()}
            loading={accountSmartGoalLoading}
          />
        </Box>
        <Stack spacing={4} sx={{ height: 'auto', width: '100%' }} mt={4}>
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={3}>
              <H4>Orders</H4>
            </Stack>
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%',
                height: 'auto',
                my: 2,
                p: 1,
                border: '1px solid',
                borderColor: theme => theme.palette.divider,
                backgroundColor: color.grey50,
                borderRadius: 1
              }}
            >
              <Box flex={'1'} display={'flex'} flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                <Box display={'flex'} flexDirection="row" alignItems="center">
                  <FormControlLabel
                    label={''}
                    control={
                      <Checkbox
                        checked={bloodPressureChecked}
                        disabled={checkOrdersDisabled()}
                        onChange={e => handleCheckboxChange(AccountOrderTypes.RPM_BLOOD_PRESSURE_AND_HEART_RATE, e.target.checked)}
                      />
                    }
                  />
                  <H5
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400
                    }}
                  >
                    Remote Physiological Monitoring - Blood pressure & heart rate
                  </H5>
                </Box>
                <Box display={'flex'} flexDirection="row" alignItems="center">
                  <FormControlLabel
                    label={''}
                    control={
                      <Checkbox
                        checked={bloodSugarChecked}
                        disabled={checkOrdersDisabled()}
                        onChange={e => handleCheckboxChange(AccountOrderTypes.RPM_BLOOD_SUGAR, e.target.checked)}
                      />
                    }
                  />
                  <H5
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400
                    }}
                  >
                    Remote Physiological Monitoring - Blood sugar
                  </H5>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default CarePlan;
