import type { ReactNode } from 'react';
import create from 'zustand';

export enum MainMenuKeys {
  RecentPatients = 'Recent Patients',
  Patients = 'Patients',
  MedicationManagement = 'Medication Management',
  RemotePatientMonitoring = 'Remote Patient Monitoring',
  TimesheetsInvoicesAdmin = 'Timesheets & Invoices Admin',
  TransitionalCareManagement = 'Transitional Care Management',
  Reports = 'Reports',
  UserAdmin = 'User Administration',
  Administration = 'Administration',
  SystemAdmin = 'System Administration',
  SystemCompany = 'System Company',
  QuickLinksAdmin = 'Quick Links Admin'
}

export type MenuSectionType = {
  title: ReactNode;
  options: MainMenuItem[];
  hidden?: boolean;
};

export type MainMenuItem = {
  navigatePath: string;
  icon: ReactNode;
  label: string;
  hidden: boolean;
  selected: boolean;
  disabled?: boolean;
  ribbonPosition?: number;
};

export type AppFrameStore = {
  contentScrollPosition: number;
  setContentScrollPosition: (value: number) => void;
  goTop: boolean;
  setGoTop: (value: boolean) => void;
  mainMenuItems: Map<string, MenuSectionType>;
  setMainMenuItems: (value: Map<string, MenuSectionType>) => void;
};

export const useAppFrameStore = create<AppFrameStore>(set => ({
  contentScrollPosition: 0,
  goTop: false,
  setContentScrollPosition: value => set(() => ({ contentScrollPosition: value })),
  setGoTop: value => set(() => ({ goTop: value })),
  mainMenuItems: new Map(),
  setMainMenuItems: value => set(() => ({ mainMenuItems: value }))
}));
