import Alert from '@/components/Alert';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { DELETE_ACCOUNT_COMMENT } from '@/graphql/account';
import { formatDateAndTime } from '@/util/format';
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { useModal } from 'mui-modal-provider';

type Props = {
  isDeletable?: boolean;
  truentityId?: string | null;
  onCommentsDelete?: () => Promise<void>;
  rows: { id: number | string; sender: { name }; body: string; createdAt: string }[];
};

const CommentsTable = ({ rows, isDeletable, truentityId, onCommentsDelete }: Props) => {
  const { showModal } = useModal();
  const [deleteComment] = useMutation(DELETE_ACCOUNT_COMMENT);

  const handleCommentDelete = (commentId: string) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Delete comment',
      message: 'Are you sure you want to delete this comment?',
      onAgree: () => {
        deleteComment({
          variables: {
            commentId,
            truentityId
          }
        });
        if (onCommentsDelete) {
          onCommentsDelete();
        }
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeading>Name</TableHeading>
            <TableHeading>Date</TableHeading>
            <TableHeading>Comment</TableHeading>
            {isDeletable && <TableHeading>Delete</TableHeading>}
          </TableRow>
        </TableHead>
        <tbody>
          {rows.length > 0 &&
            rows.map(row => (
              <TableRow key={row.id.toString()}>
                <TableCell>{row.sender.name}</TableCell>
                <TableCell
                  sx={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  {formatDateAndTime(row.createdAt)}
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      height: 'auto',
                      width: 'auto',
                      maxWidth: '400px',
                      maxHeight: '100px',
                      overflow: 'auto'
                    }}
                  >
                    {row.body}
                  </Box>
                </TableCell>
                {isDeletable && (
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleCommentDelete(row.id.toString());
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
        </tbody>
      </Table>

      {rows.length === 0 && <Alert description={'No comments yet'} status="warning" title="No comments" />}
    </>
  );
};

export default CommentsTable;
