import create from 'zustand';

interface FollowUp {
  date: string;
  items: FollowUpItem[];
}

export interface FollowUpItem {
  time: string;
  name: string;
  status: string;
  description: string;
  assignedTo: string;
}

interface DeviceBreakdown {
  name: string;
  value: number;
  fill: string;
}

interface NewsArticle {
  title: string;
  source: string;
  date: string;
  image: string;
}

interface HomeDashboardState {
  numberOfPatients: number;
  patientsToFollowUp: number;
  followUps: FollowUp[];
  enrolledPatients: number;
  connectedDevices: number;
  deviceBreakdown: DeviceBreakdown[];
  newsArticles: NewsArticle[];
  setNewsArticles: (value: NewsArticle[]) => void;
  setFollowUps: (value: FollowUp[]) => void;
  setNumberOfPatients: (value: number) => void;
  setPatientsToFollowUp: (value: number) => void;
  setEnrolledPatients: (value: number) => void;
  setConnectedDevices: (value: number) => void;
  setDeviceBreakdown: (value: DeviceBreakdown[]) => void;
}

export const useHomeDashboardStore = create<HomeDashboardState>(set => ({
  numberOfPatients: 189,
  patientsToFollowUp: 4,
  followUps: [
    {
      date: '2024-01-01',
      items: [
        { time: '08:00', name: 'Jane Smith', status: 'Complete', description: 'Call Patients', assignedTo: 'Chanuri' },
        { time: '09:40', name: 'Kevin Thompson', status: 'Complete', description: 'Review Chart', assignedTo: 'Chanuri' },
        { time: '11:35', name: 'Leonard Cooper', status: 'Complete', description: 'Call Provider', assignedTo: 'Chanuri' }
      ]
    }
  ],
  enrolledPatients: 189,
  connectedDevices: 148,
  deviceBreakdown: [
    { name: 'Blood Pressure Meter', value: 10000, fill: '#8884d8' },
    { name: 'Blood Glucose Meter', value: 5000, fill: '#83a6ed' },
    { name: 'Other', value: 4800, fill: '#8dd1e1' }
  ],
  newsArticles: [
    {
      title: 'Breakthrough Treatment for Alzheimer’s Disease Shows Promising Results',
      source: 'BBC News',
      date: '2024-01-27T08:00:00.000Z',
      image: 'https://picsum.photos/100'
    },
    {
      title: 'COVID-19 Vaccine Rollout Reaches Milestone with 70% of Population Fully Vaccinated',
      source: 'CNN News',
      date: '2024-01-25T08:00:00.000Z',
      image: 'https://picsum.photos/150'
    },
    {
      title: 'New Research Identifies Potential Biomarker for Early Cancer Detection',
      source: 'Al Jazeera News',
      date: '2024-01-22T08:00:00.000Z',
      image: 'https://picsum.photos/200'
    },
    {
      title: 'New Study Shows Successful Treatment for Patients with COVID-19',
      source: 'The Guardian',
      date: '2024-01-20T08:00:00.000Z',
      image: 'https://picsum.photos/250'
    }
  ],
  setNewsArticles: (value: NewsArticle[]) => set({ newsArticles: value }),
  setFollowUps: (value: FollowUp[]) => set({ followUps: value }),
  setNumberOfPatients: (value: number) => set({ numberOfPatients: value }),
  setPatientsToFollowUp: (value: number) => set({ patientsToFollowUp: value }),
  setEnrolledPatients: (value: number) => set({ enrolledPatients: value }),
  setConnectedDevices: (value: number) => set({ connectedDevices: value }),
  setDeviceBreakdown: (value: DeviceBreakdown[]) => set({ deviceBreakdown: value })
}));
