import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';
import Button from './Button';
import PatientAddDialog from './Dialogs/PatientAddDialog';
import { getAccountUserFullName } from '@/util/account';
import ScheduleEnrollmentDialog from './Dialogs/ScheduleEnrollmentDialog';
import type { PatientsData } from '@/routes/Patients/patients';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import { MedicalServices } from '@/types/admin';
import ConfirmDialog from './Dialogs/ConfirmDialog';

type Props = {
  refetch: () => void;
};
const PatientAdd = ({ refetch }: Props) => {
  const { showModal } = useModal();
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);

  const triggerScheduleEnrollment = (account: PatientsData) => {
    const patientName = getAccountUserFullName(account?.user);
    const modal = showModal(ConfirmDialog, {
      title: `Confirm RPM Enrollment for ${patientName}?`,
      message: `Schedule RPM enrollment for ${patientName} now?`,
      onAgree: () => {
        modal.hide();
        showScheduleForEnrollment(patientName, account);
      },
      onDisagree: () => modal.hide()
    });
  };

  const showScheduleForEnrollment = (patientName: string, account: PatientsData) => {
    const modal = showModal(ScheduleEnrollmentDialog, {
      title: `Schedule for Enrollment: ${patientName}`,
      patientInfo: account,
      hideDialog: () => modal.hide()
    });
  };

  const showPatientAddDialog = () => {
    const modal = showModal(PatientAddDialog, {
      title: 'Add Patient',
      onPatientAdded: (account: PatientsData) => {
        modal.hide();
        refetch();
        if (isRPMViewable && account) triggerScheduleEnrollment(account);
      },
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  return <Button startIcon={<AddIcon />} label="Add Patient" onClick={() => showPatientAddDialog()} />;
};

export default PatientAdd;
