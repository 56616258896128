import { useModal } from 'mui-modal-provider';
import { useEffect } from 'react';
import Button from './Button';
import TimesheetAddDialog from './Dialogs/TimeSheetAddDialog';

const TimesheetAddEdit = ({ onDone, selectedTimesheet }) => {
  const { showModal } = useModal();

  useEffect(() => {
    if (selectedTimesheet) {
      showTimesheetAddDialog();
    }
  }, [selectedTimesheet]);

  const showTimesheetAddDialog = () => {
    const modal = showModal(TimesheetAddDialog, {
      title: selectedTimesheet ? 'Edit Timesheet' : 'Add Timesheet',
      selectedTimesheet,
      hideDialog: () => {
        modal.hide();
        onDone();
      }
    });
  };

  return <Button label="Add Timesheet" onClick={() => showTimesheetAddDialog()} />;
};

export default TimesheetAddEdit;
