import MarkerLabel from '@/components/MarkerLabel';
import { color } from '@/styles/assets/colors';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';

interface AlertLimitsMarkersSectionProps {
  showDivider?: boolean;
}
export const AlertLimitsMarkersSection: React.FC<AlertLimitsMarkersSectionProps> = ({ showDivider = false }) => {
  const [showMarkers, setShowMarkers] = React.useState<boolean>(true);

  const onClose = () => {
    setShowMarkers(false);
  };

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          p: 1,
          backgroundColor: color.amber50,
          my: 2,
          borderRadius: 1
        }}
        display={showMarkers ? 'flex' : 'none'}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack flexGrow={1} display="flex" direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <MarkerLabel label={'Critically Low'} color={color.brown700} />
          <MarkerLabel label={'Low'} color={color.warningMain} />
          <MarkerLabel label={'High'} color={color.pink100} />
          <MarkerLabel label={'Critically High'} color={color.errorMain} />
        </Stack>
        <IconButton
          aria-label="close"
          onClick={onClose}
          type="button"
          sx={{
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      {showDivider && showMarkers && (
        <Divider
          sx={{
            width: '100%'
          }}
        />
      )}
    </>
  );
};

export default AlertLimitsMarkersSection;
