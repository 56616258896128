import CollapsibleListGroup from '@/components/CollapsibleListGroup';

import { Box, DialogContent, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

import AdtFilterTemplateItems from '../../components/AdtFilter/AdtFilterTemplateItems';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

export enum FilterType {
  Event = 'Event',
  ReadmissionRisk = 'Readmission Risk',
  InitialCommunicationDueBy = 'Initial Communication Due By'
}

export enum OperatorType {
  Is = 'is'
}

export enum reAdmissionRiskType {
  True = 'true',
  False = 'false'
}

const AdtFilterDialog = ({
  title,
  hideDialog,
  formInputFields,
  onFilterAddClicked,
  onDeleteIconClicked,
  handleFormChange,
  onFiltersApplied,
  handleDateChange,
  handleToDateChange,
  relyingPartyFilters,
  relyingPartyAdminFilters,
  onTemplateSelected,
  onSaveFilterClicked,
  onHideModalTest,
  onUpdateFilterClicked,
  onRemoveFilterClicked,
  selectedTemplate,
  setSelectedTemplate,
  ...props
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitImpl = async e => {
    e.preventDefault();

    try {
      onFiltersApplied(formInputFields);

      enqueueSnackbar('Filters Applied', {
        variant: 'success'
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to apply filters', {
        variant: 'error'
      });
    }
  };

  const [activeOrgFilterTab, setActiveOrgFilterTab] = useState('');
  const [activeMyFilterTab, setActiveMyFilterTab] = useState('');

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid
          component={'form'}
          container
          spacing={1}
          onSubmit={e => {
            handleSubmitImpl(e);
          }}
          mt={1}
        >
          <Grid item xs={3} sx={{ borderRight: '1px solid #ebebeb' }}>
            <List sx={{ pr: 1 }}>
              {['My Filters'].map(myFilter => {
                return (
                  <CollapsibleListGroup
                    onMainTabItemClicked={() => setActiveMyFilterTab(myFilter)}
                    selected={activeMyFilterTab === myFilter}
                    primaryText={myFilter}
                  >
                    <List>
                      <Box sx={{ paddingLeft: 2 }}>
                        {relyingPartyAdminFilters.map(myFilterTemplate => {
                          return (
                            <ListItem disablePadding selected={selectedTemplate && selectedTemplate?.id === myFilterTemplate.id}>
                              <ListItemButton
                                onClick={() => {
                                  onTemplateSelected(myFilterTemplate);
                                }}
                              >
                                <ListItemText primary={myFilterTemplate.name} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </Box>
                    </List>
                  </CollapsibleListGroup>
                );
              })}

              {['Org Filters'].map(orgFilter => {
                return (
                  <CollapsibleListGroup
                    onMainTabItemClicked={() => setActiveOrgFilterTab(orgFilter)}
                    selected={activeOrgFilterTab === orgFilter}
                    primaryText={orgFilter}
                  >
                    <List>
                      <Box sx={{ paddingLeft: 2 }}>
                        {relyingPartyFilters.map(myFilterTemplate => {
                          return (
                            <ListItem disablePadding selected={selectedTemplate && selectedTemplate?.id === myFilterTemplate.id}>
                              <ListItemButton
                                onClick={() => {
                                  onTemplateSelected(myFilterTemplate);
                                }}
                              >
                                <ListItemText primary={myFilterTemplate.name} />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </Box>
                    </List>
                  </CollapsibleListGroup>
                );
              })}
            </List>
          </Grid>

          <Grid item xs={9}>
            <AdtFilterTemplateItems
              onFilterAddClicked={onFilterAddClicked}
              onUpdateFilterClicked={onUpdateFilterClicked}
              selectedTemplate={selectedTemplate}
              formInputFields={formInputFields}
              handleFormChange={handleFormChange}
              handleDateChange={handleDateChange}
              handleToDateChange={handleToDateChange}
              onDeleteIconClicked={onDeleteIconClicked}
              onRemoveFilterClicked={onRemoveFilterClicked}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default AdtFilterDialog;
