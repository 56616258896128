import { ProviderType } from '@/types/remotePatientMonitoring';
import create from 'zustand';

export type ProviderSessionStore = {
  provider: ProviderType | null;
  setProvider: (value: ProviderType) => void;
  requestedOn: string;
  setRequestedOn: (value: string) => void;
};

export const useProviderSessionStore = create<ProviderSessionStore>(set => ({
  provider: null,
  setProvider: value => set(() => ({ provider: value })),
  requestedOn: '',
  setRequestedOn: value => set(() => ({ requestedOn: value }))
}));
