import { ON_RELYING_PARTY_ADMIN_ANNOUNCEMENTS } from '@/graphql/announcements';
import type { Announcement, AnnouncementSubscriptionResponse } from '@/types/announcement';
import { useSubscription } from '@apollo/client';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PrimaryAnnouncement from './PrimaryAnnouncement';
import SecondaryAnnouncement from './SecondaryAnnouncement';

export type LastReadAnnouncement = {
  primaryAnnouncementId: string;
  secondaryAnnouncementId: string;
};

const AnnouncementManager = () => {
  const location = useLocation();
  const initializedPrimaryAnnouncement = useRef(false);
  const [lastReadAnnouncement, setLastReadAnnouncement] = useState<LastReadAnnouncement>({
    primaryAnnouncementId: '',
    secondaryAnnouncementId: ''
  });
  const [primaryAnnouncement, setPrimaryAnnouncement] = useState<Announcement | null>(null);
  const [secondaryAnnouncement, setSecondaryAnnouncement] = useState<Announcement | null>(null);

  useSubscription<AnnouncementSubscriptionResponse>(ON_RELYING_PARTY_ADMIN_ANNOUNCEMENTS, {
    onSubscriptionData: ({ subscriptionData }) => {
      const primaryAnnouncement = subscriptionData.data?.relyingPartyAdminAnnouncements?.primaryAnnouncement || null;
      const secondaryAnnouncement = subscriptionData.data?.relyingPartyAdminAnnouncements?.secondaryAnnouncement || null;

      if (
        primaryAnnouncement &&
        !initializedPrimaryAnnouncement.current &&
        lastReadAnnouncement.primaryAnnouncementId !== primaryAnnouncement.id &&
        location.pathname === '/home'
      ) {
        setPrimaryAnnouncement(primaryAnnouncement);
        initializedPrimaryAnnouncement.current = true;
      }
      if (secondaryAnnouncement && lastReadAnnouncement.secondaryAnnouncementId !== secondaryAnnouncement.id)
        setSecondaryAnnouncement(secondaryAnnouncement);
    }
  });

  return (
    <>
      {primaryAnnouncement ? (
        <PrimaryAnnouncement
          announcement={primaryAnnouncement}
          setAnnouncement={setPrimaryAnnouncement}
          setLastReadAnnouncement={setLastReadAnnouncement}
        />
      ) : (
        <SecondaryAnnouncement
          announcement={secondaryAnnouncement}
          setAnnouncement={setSecondaryAnnouncement}
          setLastReadAnnouncement={setLastReadAnnouncement}
        />
      )}
    </>
  );
};

export default AnnouncementManager;
