import { pathnameIncludes } from '@/util/location';
import type { MenuItemOwnProps } from '@mui/material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import type { To } from 'history';
import type { ReactNode } from 'react';
import { NavLink, useResolvedPath } from 'react-router-dom';

type MenuItemNavLinkProps = {
  to: To;
  title: ReactNode;
  icon: ReactNode;
  onClick: () => void;
  MenuItemProps?: MenuItemOwnProps;
};
const MenuItemNavLink = ({ to, title, icon, onClick, ...props }: MenuItemNavLinkProps) => {
  const resolved = useResolvedPath(to);
  return (
    <MenuItem
      component={NavLink}
      to={to}
      selected={pathnameIncludes(resolved.hash || resolved.search || resolved.pathname)}
      onClick={onClick}
      {...props.MenuItemProps}
    >
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: 'auto'
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{title}</ListItemText>
    </MenuItem>
  );
};

export default MenuItemNavLink;
