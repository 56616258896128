import { Body1, H4, H5, H6 } from '@/components/Typography';
import type { MedicationInformation } from '@/types/medication';
import { formatDateAndTime } from '@/util/format';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack } from '@mui/material';
import type { ReactNode } from 'react';

import MuiTabs from '@/components/MuiTabs';
import colors from '@/styles/colors';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export type MedicationInfoProps = {
  medication: MedicationInformation;
  medicationInteractionInfo: any;
};

type EncounterInfoProps = {
  title: ReactNode;
  value: ReactNode;
  children?: ReactNode;
  dateField?: boolean;
};

const MedicationInteractionInfoTabs = ({ medication, medicationInteractionInfo }: MedicationInfoProps) => {
  const EncounterInfo = ({ title, value, children, dateField }: EncounterInfoProps) => {
    return (
      <Stack sx={{ marginBottom: '20px' }}>
        <Body1>
          <strong>{title}</strong>
        </Body1>
        {dateField === true ? <Body1>{value ? formatDateAndTime(value) : '---'}</Body1> : <Body1>{value ? value : '---'}</Body1>}
        {children}
      </Stack>
    );
  };

  const InteractionTabContent = info => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EncounterInfo title="Primary drug label name" value={info.primaryDrugLabelName} />
        </Grid>

        <Grid item xs={12}>
          <EncounterInfo title="Conflict drug label name" value={info.conflictDrugLabelName} />
        </Grid>

        <Grid item xs={12}>
          <EncounterInfo title="Severity Detail" value={info.severityDetail} />
        </Grid>

        <Grid item xs={12}>
          <EncounterInfo title="Effect" value={info.effect} />
        </Grid>

        <Grid item xs={12}>
          <EncounterInfo title="Mechanism Action" value={info.mechanismAction} />
        </Grid>

        <Grid item xs={12}>
          <EncounterInfo title="Clinical Effects" value={info.clinicalEffects} />
        </Grid>

        <Grid item xs={12}>
          <EncounterInfo title="Patient Management" value={info.patientManagement} />
        </Grid>
      </Grid>
    );
  };

  const mapIconsWithSeverityLevel = severityLevel => {
    if (severityLevel === 1) {
      return <ReportProblemIcon sx={{ color: 'red' }} />;
    } else if (severityLevel === 2) {
      return <ReportProblemIcon sx={{ color: 'yellow' }} />;
    } else if (severityLevel === 3) {
      return <DangerousIcon sx={{ color: 'red' }} />;
    } else {
      return <DangerousIcon sx={{ color: 'yellow' }} />;
    }
  };

  const CollapseChildren = ({ type }) => {
    let filteredData = medicationInteractionInfo?.filter(info => info.severityLevel === type);

    if (filteredData.length > 0) {
      return filteredData.map((row, index) => {
        return (
          <Accordion key={row.id} defaultExpanded={index === 0 ? true : false}>
            <AccordionSummary
              sx={{ boxShadow: `inset 0 -1px 0 0 ${colors.neutralA8}` }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`{option.label}-header-content-{index}`}
              id={`{option.label}-header-{index}`}
            >
              <Stack direction="column" spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <H4>
                    {row.primaryDrugLabelName} / {row.conflictDrugLabelName}
                  </H4>
                </Stack>

                <H6>{row.title}</H6>
              </Stack>
            </AccordionSummary>

            <AccordionDetails
              sx={{
                backgroundColor: '#fafafa',
                padding: '30px'
              }}
            >
              {InteractionTabContent(row)}
            </AccordionDetails>
          </Accordion>
        );
      });
    } else {
      return (
        <Stack
          direction="row"
          alignContent={'center'}
          spacing={2}
          sx={{ marginTop: '20px', marginBottom: '20px', alignItems: 'center', justifyContent: 'center' }}
        >
          <H5 color="default" weight="medium" css={{ marginLeft: '3px', fontStyle: 'italic' }}>
            No Medication Interaction info available for this severity level
          </H5>
        </Stack>
      );
    }
  };

  return (
    <Stack sx={{ marginTop: 3 }}>
      <MuiTabs
        tabs={[
          {
            label: 'Moderate',
            children: <CollapseChildren type={3} />
          },
          {
            label: 'Severe',
            children: <CollapseChildren type={2} />
          },
          {
            label: 'Contraindicated',
            children: <CollapseChildren type={1} />
          },
          {
            label: 'Undetermined',
            children: <CollapseChildren type={9} />
          }
        ]}
      />
    </Stack>
  );
};

export default MedicationInteractionInfoTabs;
