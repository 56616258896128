export const getEnvironmentLabel = () => {
  const hostname = window.location.hostname.toLowerCase();

  switch (true) {
    case hostname.startsWith('portal.dev'):
      return 'DEV';

    case hostname.startsWith('portal.qa'):
      return 'QA';

    case !hostname.startsWith('portal'):
      return 'LOCAL';

    default:
      return 'PROD';
  }
};

export const getClientIpAddress = async (): Promise<string> => {
  const response = await fetch('https://api.ipify.org?format=json');
  const data = await response.json();
  return data.ip ?? '';
};

export const isProd = () => getEnvironmentLabel() === 'PROD';
export const isQA = () => getEnvironmentLabel() === 'QA';
export const isLocal = () => getEnvironmentLabel() === 'LOCAL';

export const getServerAccessUrl = () => {
  const graphqlEndpoint = process.env.GRAPHQL_ENDPOINT;
  if (graphqlEndpoint) {
    const serverURL = graphqlEndpoint.replace('/graphql', '');
    return serverURL;
  } else {
    throw new Error('REACT_APP_GRAPHQL_ENDPOINT environment variable is not defined.');
  }
};
