import Button from '@/components/Button';
import { GET_ALL_ACCOUNTS_LIST, type AccountList } from '@/graphql/account-lists';
import type { PatientsData } from '@/routes/Patients/patients';
import { useLazyQuery } from '@apollo/client';
import { Star } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { BoxProps } from '@mui/material';
import { Box, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const MAX_ITEMS = 9;

type Props = BoxProps;
const StarredPatientsList = ({ ...props }: Props) => {
  const [getAllAccountsLists] = useLazyQuery(GET_ALL_ACCOUNTS_LIST);
  const [starredList, setStarredList] = useState<AccountList | null>(null);
  const [starredAccounts, setStarredAccounts] = useState<Partial<PatientsData>[]>([]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = e => {
    const redirectUrl = `patients/${e.target.value}/details/medications/triage/list`;
    navigate(redirectUrl);
    handleClose();
  };

  const callGetAllAccountLists = () => {
    getAllAccountsLists({
      variables: {
        pageNum: 1,
        pageSize: 100
      }
    })
      .then(result => {
        const list = result.data?.getAllAccountLists?.accountLists?.find(accountList => accountList?.name === 'Starred');
        setStarredList(list);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    callGetAllAccountLists();
  }, []);

  useEffect(() => {
    setStarredAccounts(starredList?.accounts as Partial<PatientsData>[]);
  }, [starredList]);

  return (
    <Box {...props} onFocus={() => callGetAllAccountLists()}>
      {starredList && (
        <>
          <Button
            sx={{
              paddingY: 0.25,
              paddingX: 0.75
            }}
            size={'small'}
            variant={'outlined'}
            startIcon={<Star />}
            endIcon={<ExpandMoreIcon />}
            onClick={handleClick}
            label={'Starred Patients'}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
          />
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            id="customized-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            {starredAccounts?.length === 0 && (
              <MenuItem disabled>Click stars next to patient names to add to this list for quick access</MenuItem>
            )}
            {starredAccounts?.slice(0, MAX_ITEMS).map(account => (
              <MenuItem
                key={account?.truentityId}
                value={account?.truentityId}
                onClick={() => handleChange({ target: { value: account?.truentityId } })}
              >
                {account?.user?.firstName} {account?.user?.lastName}
              </MenuItem>
            ))}
            {starredAccounts?.length > MAX_ITEMS && (
              <MenuItem key="view-more">
                <Link to={'/patients/starred'}>View More</Link>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default StarredPatientsList;
