import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import Button from '@/components/Button';
import { Small } from '@/components/Typography';
import { DialogActions, DialogContent } from '@mui/material';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type FormValues = {
  truentityId: string;
  emailSubject: string;
  emailAddresses: string;
};

const defaultValues: FormValues = {
  truentityId: '',
  emailSubject: '',
  emailAddresses: ''
};

type Props = BaseDialogProps & {
  title: string;
  truentityId?: string;
  handleSubmitEmail: any;
  hideDialog: () => void;
};

const SendEmailDialog = ({ title, truentityId, handleSubmitEmail, hideDialog, ...props }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitEmail(data);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} maxWidth="sm" fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TruentityTextField
            {...register('emailSubject')}
            type="text"
            label="Subject"
            fullWidth
            variant="outlined"
            margin="dense"
            required
          />

          <TruentityTextField
            {...register('emailAddresses')}
            type="email"
            label="Email"
            multiple
            fullWidth
            variant="outlined"
            margin="dense"
            required
          />

          <Small>One email address or multiple separated by ,</Small>

          <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
            <Button type="submit" a11yLabel="Submit" appearance="primary" />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </DialogContent>
      </form>
    </BaseDialog>
  );
};

export default SendEmailDialog;
