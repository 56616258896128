import MuiTabs from '@/components/MuiTabs';
import { H2, H4, H5 } from '@/components/Typography';
import { AccountCareEventResponse, GET_ACCOUNT_ADT_EVENT } from '@/graphql/transitionalCareManagement';
import { MuiColor } from '@/types/mui';
import { formatDate } from '@/util/format';
import { useQuery } from '@apollo/client';
import { Box, Chip, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type AdtEventDetailsType = AccountCareEventResponse['accountCareEvent'];

const DetailRow = ({ title, value }: { title: string; value: string | Date | JSX.Element }) => {
  return value ? (
    <Stack direction="row" bgcolor="white" p={2} columnGap={2} borderRadius="10px" alignItems="center">
      <H4 textTransform="capitalize" minWidth="225px">
        {title}:
      </H4>
      {React.isValidElement(value) ? value : <H5 fontWeight={500}>{value}</H5>}
    </Stack>
  ) : null;
};

const careEventStatusColorMap = new Map<string, MuiColor>([
  ['Pending', 'default'],
  ['InProgress', 'info'],
  ['Success', 'success']
]);

const AdtEventDetails = () => {
  const { eventId } = useParams();

  const [adtEventDetails, setAdtEventDetails] = useState<AdtEventDetailsType>({} as AdtEventDetailsType);

  const { data: getAccountAdtEventData } = useQuery<AccountCareEventResponse>(GET_ACCOUNT_ADT_EVENT, {
    variables: {
      accountCareEventId: eventId
    }
  });

  useEffect(() => {
    if (getAccountAdtEventData && getAccountAdtEventData.accountCareEvent) {
      setAdtEventDetails(getAccountAdtEventData.accountCareEvent);
    }
  }, [getAccountAdtEventData]);

  return (
    <>
      <Box>
        <H2>Details for ADT Event on {formatDate(adtEventDetails?.dischargeDate)}</H2>
      </Box>
      <Box mt={3}>
        <MuiTabs
          tabs={[
            {
              label: 'Admission Details',
              sx: { textTransform: 'uppercase' },
              children: (
                <Stack gap={1}>
                  <DetailRow title="Admission Date" value={adtEventDetails?.admissionDate} />
                  <DetailRow title="Admission Reason" value={adtEventDetails?.admissionReason} />
                  <DetailRow title="Admitted From" value={adtEventDetails?.admittedFrom} />
                </Stack>
              )
            },
            {
              label: 'Discharge Details',
              sx: { textTransform: 'uppercase' },
              children: (
                <Stack gap={1}>
                  <DetailRow title="Discharged From" value={adtEventDetails?.dischargeLocation} />
                  <DetailRow title="Discharged Date" value={adtEventDetails?.dischargeDate} />
                  <DetailRow title="Discharged Disposition" value={adtEventDetails?.dischargedDisposition} />
                </Stack>
              )
            },
            {
              label: 'Event Details',
              sx: { textTransform: 'uppercase' },
              children: (
                <Stack gap={1}>
                  <DetailRow title="Event Type" value={adtEventDetails?.eventType} />
                  <DetailRow
                    title="Event Action Type"
                    value={<Chip size="small" variant="outlined" color="info" label={adtEventDetails?.eventActionType} />}
                  />
                  <DetailRow title="Source" value={adtEventDetails?.source} />
                  <DetailRow
                    title="Event Status"
                    value={
                      <Chip
                        size="small"
                        variant="outlined"
                        color={careEventStatusColorMap.get(adtEventDetails?.eventStatus)}
                        label={adtEventDetails?.eventStatus}
                      />
                    }
                  />
                  <DetailRow title="Event Facility Type" value={adtEventDetails?.eventFacilityType} />
                  <DetailRow title="Event Facility Name" value={adtEventDetails?.eventFacilityName} />
                </Stack>
              )
            },
            {
              label: 'Diagnosis Details',
              sx: { textTransform: 'uppercase' },
              children: (
                <Stack gap={1}>
                  <DetailRow title="Primary Diagnosis Code" value={adtEventDetails?.primaryDiagnosisCode} />
                  <DetailRow title="Primary Diagnosis Description" value={adtEventDetails?.primaryDiagnosisDescription} />
                </Stack>
              )
            }
          ]}
          customPadding
        />
      </Box>
    </>
  );
};

export default AdtEventDetails;
