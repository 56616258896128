import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CSSProperties } from 'react';

const ReportsTable = ({ columns, rows, loader, tableName }) => {
  const customRowStyles: CSSProperties = {
    borderBottom: '1px solid black',
    fontWeight: 'bold'
  };

  return rows?.length !== 0 ? (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#777777', marginTop: '10px', marginBottom: '-1px' }}>{tableName}</p>
      <TableContainer sx={{ height: 380 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow style={{ ...customRowStyles, height: 60 }}>
              {columns?.map(({ headerName }) => (
                <TableCell sx={{ textAlign: 'center' }} key={headerName?.id} style={customRowStyles}>
                  {headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No data available
                </TableCell>
              </TableRow>
            ) : loader ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <CircularProgress /> {/* Display the loading indicator */}
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, rowIndex) => (
                <TableRow key={rowIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {columns?.map(({ field }) => (
                    <TableCell sx={{ textAlign: 'center' }} key={field}>
                      {row[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : (
    <></>
  );
};

export default ReportsTable;
