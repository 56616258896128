import { gql, useMutation } from '@apollo/client';

import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import Button from '@/components/Button';
import { formatDateIgnoreTZ } from '@/util/format';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

import { TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { toDateOrNull } from '@/util/date';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  tasks: object[];
  encounterId?: string;
};

const ASSIGN_TASKS_TO_ENCOUNTER = gql`
  mutation AssignTasksToEncounter($taskIds: [String!], $encounterId: String!) {
    assignTasksToEncounter(taskIds: $taskIds, encounterId: $encounterId) {
      status
    }
  }
`;

//TODO:  This is not being used.  This will not work with pagination since we are passing in the result set (tasks) as a param
const AssignTasksToEncounterDialog = ({ title, hideDialog, tasks, encounterId, ...props }: Props) => {
  const [assignAdminsToPatients] = useMutation(ASSIGN_TASKS_TO_ENCOUNTER);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const handleOnAssignSubmitClicked = async admin_ids => {
    if (admin_ids.length > 0) {
      let variables = {};
      variables = {
        taskIds: admin_ids,
        encounterId: encounterId
      };

      try {
        const result = await assignAdminsToPatients({
          variables: { ...variables }
        });
        const status = result.data.assignTasksToEncounter.status;
      } catch (err) {
        console.log(err);
      } finally {
        hideDialog();
      }
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        sortable: true,
        valueGetter: params => params.row.type,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'modeOfResolution',
        headerName: 'Mode Of Resolution',
        sortable: true,
        valueGetter: params => params.row.modeOfResolution,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        sortable: true,
        valueGetter: params => params.row.healthPlan,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'performedOn',
        headerName: 'Performed On',
        type: 'date',
        sortable: true,
        valueGetter: params => toDateOrNull(params.row.performedOn),
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedDate = formatDateIgnoreTZ(cellValues.row.performedOn, 'YYYY-MM-DD');
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {formattedDate}
            </Stack>
          );
        }
      },
      {
        field: 'noOfEncounters',
        headerName: '# of Encounters ',
        sortable: true,
        valueGetter: params => params.row.encounters.length,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'billedAmount',
        headerName: 'Billed Amount',
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedBilledAmount = `$ ${cellValues.row.billedAmount}`;

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {formattedBilledAmount}
            </Stack>
          );
        }
      },
      {
        field: 'wrapUp',
        headerName: 'Wrap Up',
        sortable: true,
        valueGetter: params => params.row.wrapUpStatus,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      }
    ],
    []
  );

  return (
    <BaseDialog
      disableBackdropClick={true}
      canNavigateBack={false}
      title={title}
      hideDialog={hideDialog}
      {...props}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogContent>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-assign-tasks-to-encounter'}
            autoHeight
            rows={tasks || []}
            checkboxSelection
            columns={columns}
            paginationMode="server"
            disableRowSelectionOnClick
            onRowSelectionModelChange={e => {
              setSelectedOptions(e);
              setSelectionModel(e);
            }}
            rowSelectionModel={selectionModel}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          a11yLabel="Assign"
          appearance="primary"
          onClick={() => handleOnAssignSubmitClicked(selectedOptions)}
          disabled={selectedOptions.length === 0}
        />
        <Button
          a11yLabel="Cancel"
          type="reset"
          appearance="outline"
          onClick={() => {
            hideDialog();
          }}
        />
      </DialogActions>
    </BaseDialog>
  );
};

export default AssignTasksToEncounterDialog;
