import { StatusColors, StatusTypeFilter } from '@/types/remotePatientMonitoring';
import { formatDate, formatTime } from '@/util/format';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import StartIcon from '@mui/icons-material/Start';
import { Box, Chip, IconButton, Stack, Tooltip } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

type VitalsTableColumnsProps = {
  tabUnit: string;
  getChipStatusStyles: (label: StatusTypeFilter) => StatusColors;
  handleActionsIconClick: (event: React.MouseEvent<HTMLButtonElement>, rowId: string) => void;
};

const VitalsTableColumns = ({ tabUnit, getChipStatusStyles, handleActionsIconClick }: VitalsTableColumnsProps) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'recordedAt',
        headerName: 'Recorded Date',
        sortable: true,
        type: 'string',
        flex: 1,
        valueGetter: params => formatDate(params.row.recordedAt, 'YYYY-MM-DD')
      },
      {
        field: 'recordedTime',
        headerName: 'Recorded Time',
        sortable: true,
        flex: 1,
        valueGetter: params => formatTime(params.row.recordedAt)
      },
      {
        field: 'value',
        headerName: tabUnit,
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.value
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.status,
        renderCell: cellValues => {
          const { textColor, bgColor } = getChipStatusStyles(cellValues?.value);

          return (
            <Stack direction="row" sx={{ width: '100%', userSelect: 'none' }}>
              <Chip label={cellValues.value as string} sx={{ bgcolor: bgColor, color: textColor }} variant="filled" />
            </Stack>
          );
        }
      },
      {
        field: 'isBaseline',
        headerName: 'Type',
        sortable: true,
        flex: 1,
        renderCell: params => (
          <span style={{ marginLeft: 8 }}>
            <Tooltip title={params.row.isBaseline ? 'Baseline' : 'Standard'}>
              <Box>
                <IconButton
                  id="vitals-actions-button"
                  size="small"
                  onClick={event => {
                    handleActionsIconClick(event, params.row.id);
                  }}
                >
                  {params.row.isBaseline ? <StartIcon fontSize="small" /> : <QueryStatsIcon fontSize="small" />}
                </IconButton>
              </Box>
            </Tooltip>
          </span>
        )
      }
      // {
      //   field: 'source',
      //   headerName: 'Source',
      //   sortable: true,
      //   flex: 1,
      //   renderCell: params => <span style={{ marginLeft: 8 }}>{params.row.source}</span>
      // }
    ],
    [getChipStatusStyles, tabUnit]
  );

  return columns;
};

export default VitalsTableColumns;
