import MenuIcon from '@mui/icons-material/Menu';
import type { PopoverOrigin } from '@mui/material';
import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import type { MenuItemData } from 'mui-nested-menu';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';

type Props = {
  items: MenuItemData[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const ActionDropdown = ({ items }: Props) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <IconButton onClick={(e: SyntheticEvent) => setAnchorEl(e.currentTarget)}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map(item => (
          <MenuItem key={item.label} onClick={item.callback}>
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ActionDropdown;
