import { gql, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';

import styled from '@/styles';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ChipBadge from '@/components/ChipBadge';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import Pagination from '@/components/Pagination';
import PatientDetailContext from '@/context/patientDetailContext';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { formatDateAndTime } from '@/util/format';
import { Paper, Stack } from '@mui/material';

const PaginationContainer = styled('div', {
  width: '100%'
});

const GET_REQUESTS = gql`
  query getRequests($pageNum: Int!, $pageSize: Int!, $search: String, $endDate: ISO8601DateTime, $truentityId: String) {
    requests(pageNum: $pageNum, pageSize: $pageSize, search: $search, endDate: $endDate, truentityId: $truentityId) {
      requests {
        id
        account {
          truentityId
          user {
            firstName
            lastName
          }
        }
        createdAt
        status
        body
        updatedAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

const renderItems = (item: any) => {
  const purpose = JSON.parse(item.body);
  const status = item.status.toLowerCase();

  return (
    <TableRow key={item.id}>
      <TableCell>{formatDateAndTime(item.createdAt)}</TableCell>
      <TableCell>{item.account.truentityId}</TableCell>
      <TableCell>{purpose.purpose}</TableCell>
      <TableCell>
        <ChipBadge status={status} />
      </TableCell>
    </TableRow>
  );
};

const PatientRequestHistory = () => {
  const { handleGoToNewRequest, patientId } = useContext(PatientDetailContext);
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, called } = useQuery(GET_REQUESTS, {
    variables: {
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      search: '',
      endDate: null,
      truentityId: patientId
    },
    pollInterval: 5000
  });
  const isDataLoaded = () => called && !loading && data && data.requests.requests;

  const isThereData = () => isDataLoaded() && data.requests.meta.totalCount > 0;

  const thereIsNoData = () => isDataLoaded() && data.requests.meta.totalCount === 0;

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  return (
    <Stack spacing={2} p={2}>
      <Stack direction="row" justifyContent={'flex-end'}>
        <Button a11yLabel="New Request" label="New request" onClick={handleGoToNewRequest} disabled={isProviderAdmin} />
      </Stack>

      <Paper component={Stack}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading style={{ width: '25%' }}>Request Time</TableHeading>
              <TableHeading style={{ width: '25%' }}>Truentity ID</TableHeading>
              <TableHeading style={{ width: '25%' }}>Purpose</TableHeading>
              <TableHeading style={{ width: '25%' }}>Status</TableHeading>
            </TableRow>
          </TableHead>
          <tbody>{isThereData() && data.requests.requests.map(item => renderItems(item))}</tbody>
        </Table>

        {thereIsNoData() && <Alert description="There are no requests registered for this patient" status="warning" title="No Requests" />}
      </Paper>

      {isThereData() && (
        <PaginationContainer>
          <Pagination
            currentPage={currentPage}
            totalRecords={data.requests.meta.totalCount}
            pageLimit={data.requests.meta.totalPages}
            pageNeighbours={2}
            onPageChanged={updateData}
          />
        </PaginationContainer>
      )}
    </Stack>
  );
};

export default PatientRequestHistory;
