import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import type { MouseEvent, ReactNode } from 'react';
import { useState } from 'react';
import type { Props as ButtonProps } from './Button';
import Button from './Button';

export type SplitActionButton = {
  label: ReactNode;
  onAction?: () => void;
  disabled?: boolean;
  icon?: ReactNode;
};

type Props = {
  label: ReactNode;
  options: SplitActionButton[];
  icon?: ReactNode;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  disabled?: boolean;
  ButtonProps?: ButtonProps;
};

export default function MuiMenu({ label, options, icon = undefined, variant = undefined, disabled = false, ...props }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: SplitActionButton) => {
    if (item.onAction) {
      item.onAction();
    }
    handleClose();
  };

  return (
    <>
      <Button
        disableElevation
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        startIcon={icon}
        variant={variant}
        disabled={disabled}
        {...props.ButtonProps}
      >
        {label}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {options.map((item, index) => {
          return (
            <MenuItem key={index} onClick={() => handleMenuItemClick(item)} disabled={item.disabled}>
              {item?.icon && (
                <ListItemIcon
                  sx={{
                    minWidth: 'auto'
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              )}
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
