import Comments from '@/components/Comments';
import { GridItem } from '@/components/GridItem';
import ImportActivity from '@/components/ImportActivity';
import MuiTabs from '@/components/MuiTabs';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { PatientsData } from '@/routes/Patients/patients';
import { CONSENT_OPTIONS } from '@/types/accountProfile';
import { findClosestReminderForToday } from '@/util/account';
import { formatDate } from '@/util/format';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BackupIcon from '@mui/icons-material/Backup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import RoomIcon from '@mui/icons-material/Room';
import { Collapse, Fab, Grid, Stack } from '@mui/material';
export default function PatientInfoTabsInfoTabs(props: {
  collapsePatientInfo: boolean;
  patientInfo: PatientsData;
  currentMedsCount: number;
  goToEditProfile: () => void;
  patientId: string | undefined;
  doNotCall?: boolean;
}) {
  return (
    <Collapse in={!props.collapsePatientInfo} timeout="auto">
      <MuiTabs
        tabs={[
          {
            label: 'Profile',
            children: (
              <Stack>
                <Grid container rowSpacing={2}>
                  {/* @2024/04/05 Patient emails hidden */}
                  {/* <GridItem xs={6} icon={<MailIcon fontSize={'small'} />} label="Email" value={props.patientInfo.user?.email} /> */}
                  <GridItem
                    xs={6}
                    icon={<LocalHospitalIcon fontSize={'small'} />}
                    label="Current Meds"
                    value={props.currentMedsCount || 0}
                  />
                  <GridItem
                    xs={6}
                    icon={<LocalPhoneIcon fontSize={'small'} />}
                    label="Phone"
                    value={<TruentityPhoneNumber value={props.patientInfo?.phone} editable={false} />}
                  />
                  <GridItem
                    xs
                    icon={<BackupIcon fontSize={'small'} />}
                    label="New Uploads"
                    value={props.patientInfo?.numQuickUploadsNew || 0}
                  />
                  <GridItem xs={6} icon={<RoomIcon fontSize={'small'} />} label="Location" value={props.patientInfo?.address} />
                </Grid>
                <Fab
                  sx={{ position: 'absolute', right: theme => theme.spacing(2), bottom: theme => theme.spacing(0) }}
                  color="primary"
                  aria-label="profile"
                  onClick={props.goToEditProfile}
                >
                  <EditIcon />
                </Fab>
              </Stack>
            )
          },
          {
            label: 'Preferences',
            children: (
              <Stack>
                <Grid container rowSpacing={2}>
                  <GridItem
                    xs={6}
                    icon={props.doNotCall ? <PhoneDisabledIcon fontSize={'small'} /> : <PhoneEnabledIcon fontSize={'small'} />}
                    label={props.doNotCall ? 'Do Not Follow Up' : 'May Follow Up'}
                    value=" "
                  />
                  <GridItem
                    xs
                    icon={<LanguageIcon fontSize={'small'} />}
                    label="Primary Language"
                    value={props.patientInfo?.primaryLanguage}
                  />
                  <GridItem
                    xs={6}
                    icon={<AssignmentTurnedInIcon fontSize={'small'} />}
                    label="Specified On"
                    value={formatDate(props.patientInfo?.doNotCallLastSpecifiedAt)}
                  />
                  <GridItem
                    xs
                    icon={<CheckCircleIcon fontSize={'small'} />}
                    label="Consent"
                    value={CONSENT_OPTIONS?.find(p => p.value === props.patientInfo?.consent)?.label}
                  />
                  <GridItem
                    xs={6}
                    icon={<PhoneCallbackIcon fontSize={'small'} />}
                    label="Next Call"
                    value={formatDate(findClosestReminderForToday(props.patientInfo?.accountsFollowupReminders)?.followUpOn)}
                  />
                </Grid>

                <Fab
                  sx={{ position: 'absolute', right: theme => theme.spacing(2), bottom: theme => theme.spacing(0) }}
                  color="primary"
                  aria-label="preferences"
                  onClick={props.goToEditProfile}
                >
                  <EditIcon />
                </Fab>
              </Stack>
            )
          },
          {
            label: 'Comments',
            children: <Comments collapsible={false} patientId={props.patientId} />
          },
          {
            label: 'Import Activity',
            children: <ImportActivity collapsible={false} patientId={props.patientId} />
          }
        ]}
      />
    </Collapse>
  );
}
