import type { ListItemAvatarProps, ListItemProps, ListItemTextProps } from '@mui/material';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import type { ReactNode } from 'react';

type Props = ListItemProps & {
  primary: ReactNode;
  secondary?: ReactNode;
  imagePath?: string;
  ListItemTextProps?: ListItemTextProps;
  ListItemAvatarProps?: ListItemAvatarProps;
};

const CustomListItem = ({ primary, secondary, imagePath, ...props }: Props) => {
  return (
    <ListItem {...props}>
      {imagePath && imagePath.length > 0 && (
        <ListItemAvatar>
          <Avatar
            src={imagePath}
            variant="circular"
            sx={{ backgroundColor: theme => theme.palette.background.default, img: { objectFit: 'contain' } }}
          />
        </ListItemAvatar>
      )}

      <ListItemText primary={primary} secondary={secondary} {...props.ListItemTextProps}></ListItemText>
    </ListItem>
  );
};

export default CustomListItem;
