import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import SelectList, { type TextSelectOption } from '@/components/SelectList';
import TruentityDateFullRangePicker from '@/components/TruentityDateFullRangePicker';
import { Body1, H1, Small } from '@/components/Typography';
import type { GetProviderRelyingPartiesResponse, GetRpmProviderAccountsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_PROVIDER_SESSION_ACCOUNTS, GET_PROVIDER_SESSION_RELYING_PARTIES } from '@/graphql/remotePatientMonitoring';
import useDateRange from '@/hooks/useDateRange';
import { color } from '@/styles/assets/colors';
import { RpmApprovalStatusTypes, RpmProviderActivitiesType } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatDateAndTime } from '@/util/format';
import { getRpmProviderAuthCode, mapRpmStatusToColor, SELECT_DEFAULT_OPTION } from '@/util/rpm';
import { parseStringOrDefault } from '@/util/string';
import { useProviderSessionStore } from '@/zustand/ProviderSessionStore';
import { useLazyQuery, useQuery } from '@apollo/client';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Chip, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import TruentityTextField from '@/components/TruentityTextField';
import Button from '@/components/Button';
import SearchIcon from '@mui/icons-material/Search';

const RpmInfoChip = ({ label, value }: { label: string; value: string }) => {
  return (
    <Chip
      variant="outlined"
      label={
        <Stack direction={'row'} spacing={1}>
          <Body1 fontWeight={'bold'}>{label}</Body1>
          <Body1>{value}</Body1>
        </Stack>
      }
    />
  );
};

type ProviderAccountsFilterOptions = {
  firstName?: string;
  lastName?: string;
};

const RpmEnrollmentRecordViewAll = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { provider } = useProviderSessionStore();
  const { startDate, endDate, handleDateChange } = useDateRange({ datesGap: 7 * 2 });
  const {
    control,
    reset: resetNameForm,
    getValues,
    handleSubmit
  } = useForm<ProviderAccountsFilterOptions>({
    defaultValues: {
      firstName: '',
      lastName: ''
    }
  });

  const [rpmAccountProviderActivities, setRpmAccountProviderActivities] = useState<RpmProviderActivitiesType[]>([]);

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState<string>(SELECT_DEFAULT_OPTION.value);
  const [selectedStatus, setSelectedStatus] = useState<string | RpmApprovalStatusTypes>(RpmApprovalStatusTypes.IN_REVIEW);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    status: true,
    patientName: true,
    dateOfBirth: true,
    requestedOn: true,
    statusUpdatedOn: false,
    id: true
  });

  const { data: relyingPartiesData, loading: relyingPartiesDataLoading } = useQuery<GetProviderRelyingPartiesResponse>(
    GET_PROVIDER_SESSION_RELYING_PARTIES,
    {
      variables: {
        authCode: getRpmProviderAuthCode()
      }
    }
  );
  const [getRpmProviderAccountsQuery, { data: rpmProviderAccountsQueryData, loading: rpmProviderAccountsQueryLoading }] =
    useLazyQuery<GetRpmProviderAccountsResponse>(GET_PROVIDER_SESSION_ACCOUNTS, { fetchPolicy: 'network-only' });

  const callGetRpmProviderAccounts = async () => {
    try {
      await getRpmProviderAccountsQuery({
        variables: {
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          authCode: getRpmProviderAuthCode(),
          relyingPartyId: selectedCompany,
          filterOptions: {
            rpmApprovalStatus: selectedStatus,
            startDate: startDate,
            endDate: endDate,
            firstName: getValues('firstName'),
            lastName: getValues('lastName')
          }
        }
      });
    } catch (error: any) {
      enqueueSnackbar('Failed to retrieve provider accounts', { variant: 'error' });
    }
  };

  const startReview = useCallback(
    (providerActivityId: string | undefined) => {
      if (providerActivityId) {
        navigate(`./${providerActivityId}`);
      }
    },
    [navigate]
  );

  const onSelectCompany = (event: SelectChangeEvent<unknown>) => {
    setSelectedCompany(event.target.value as string);
    setCurrentPage(0);
  };

  const onSelectStatus = (event: SelectChangeEvent<unknown>) => {
    setSelectedStatus(event.target.value as string);
    setCurrentPage(0);
  };

  const onSubmit: SubmitHandler<ProviderAccountsFilterOptions> = callGetRpmProviderAccounts;

  useEffect(() => {
    resetNameForm();
    callGetRpmProviderAccounts();
  }, [currentPage, selectedCompany, selectedStatus, startDate, endDate]);

  useEffect(() => {
    setRowCountState(prevRowCountState => rowCount ?? prevRowCountState);
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (rpmProviderAccountsQueryData?.getProviderSessionAccounts?.providerActivities) {
      setRpmAccountProviderActivities(rpmProviderAccountsQueryData?.getProviderSessionAccounts?.providerActivities ?? []);
      setRowCount(rpmProviderAccountsQueryData?.getProviderSessionAccounts?.meta.totalCount ?? 0);
    }
  }, [rpmProviderAccountsQueryData]);

  useEffect(() => {
    if (selectedStatus?.toLowerCase() === RpmApprovalStatusTypes.IN_REVIEW.toLowerCase()) {
      setColumnVisibilityModel({
        status: true,
        patientName: true,
        dateOfBirth: true,
        requestedOn: true,
        statusUpdatedOn: false,
        id: true
      });
    } else {
      setColumnVisibilityModel({
        status: true,
        patientName: true,
        dateOfBirth: true,
        requestedOn: true,
        statusUpdatedOn: true,
        id: true
      });
    }
  }, [selectedStatus]);

  const columns: GridColDef<RpmProviderActivitiesType>[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          return (
            <Box
              sx={{
                backgroundColor: mapRpmStatusToColor(params.row.account?.rpmApprovalStatus),
                width: 15,
                height: 15,
                borderRadius: '50%'
              }}
            ></Box>
          );
        }
      },
      {
        field: 'patientName',
        headerName: 'Patient Name',
        minWidth: 250,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          const name = getAccountUserFullName(params.row.account?.user);
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'center'}>
              {name}
            </Stack>
          );
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        minWidth: 250,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.account?.birthDate)
      },
      {
        field: 'requestedOn',
        headerName: 'Requested On',
        minWidth: 200,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDateAndTime(params.row?.approvalRequestedAt ?? params.row?.requestedAt)
      },
      {
        field: 'statusUpdatedOn',
        headerName: 'Status Updated On',
        minWidth: 200,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDateAndTime(params.row?.rpmStatusUpdatedAt)
      },
      {
        field: 'id',
        sortable: false,
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderHeader: () => {
          return (
            <Box fontWeight={500} px={6}>
              Review
            </Box>
          );
        },
        renderCell: params => {
          return (
            <Box px={6}>
              <IconButton onClick={() => startReview(params.row.id)}>
                <ContentPasteGoIcon color={'primary'} />
              </IconButton>
            </Box>
          );
        }
      }
    ],
    [startReview]
  );

  return (
    <>
      <Paper
        elevation={1}
        color={'inherit'}
        component={Stack}
        p={4}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack alignItems={'flex-start'} spacing={1}>
          <H1>RPM Enrollment Review</H1>
          <Stack direction="row" spacing={1}>
            <RpmInfoChip
              label={'Provider:'}
              value={parseStringOrDefault(provider?.individualFirstName) + ' ' + parseStringOrDefault(provider?.individualLastName)}
            />
          </Stack>
        </Stack>
        <Stack alignItems={'flex-start'} spacing={1}>
          <SelectList
            formControlProps={{ sx: { minWidth: 150 } }}
            label="Company"
            options={[
              SELECT_DEFAULT_OPTION,
              ...(relyingPartiesData?.getProviderSessionRelyingParties?.relyingParties?.map(item => {
                return {
                  label: item.name,
                  value: item.id
                } as TextSelectOption;
              }) ?? [])
            ]}
            placeholder="Select an option..."
            value={selectedCompany}
            onChange={onSelectCompany}
            fullWidth={false}
          />
        </Stack>
      </Paper>
      <Paper color="inherit" component={Stack} p={4}>
        <Stack flexDirection="column" justifyContent="flex-start" alignItems={'stretch'}>
          <Stack width={'100%'} flexDirection={'row'} justifyContent="space-between" alignItems="center">
            <Stack
              component={'form'}
              onSubmit={handleSubmit(onSubmit)}
              width={'auto'}
              flexDirection={'row'}
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField sx={{ margin: 1 }} autoFocus onChange={onChange} value={value} label={'First Name'} />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField sx={{ margin: 1 }} autoFocus onChange={onChange} value={value} label={'Last Name'} />
                )}
              />
              <Box sx={{ margin: 1 }}>
                <Button startIcon={<SearchIcon />} label={'Search'} size={'medium'} type="submit" />
              </Box>
            </Stack>
            <Stack width={'auto'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-end'}>
              <SelectList
                formControlProps={{ sx: { minWidth: 150, marginX: 2, marginY: 1 } }}
                label="Status"
                options={[
                  SELECT_DEFAULT_OPTION,
                  { label: 'In Review', value: RpmApprovalStatusTypes.IN_REVIEW },
                  { label: 'Approved', value: RpmApprovalStatusTypes.APPROVED },
                  { label: 'Rejected', value: RpmApprovalStatusTypes.REJECTED },
                  { label: 'Incomplete', value: RpmApprovalStatusTypes.SKIPPED }
                ]}
                placeholder="Select an option..."
                value={selectedStatus}
                onChange={onSelectStatus}
                fullWidth={false}
              />
              <Stack direction={'column'} minWidth={250} alignItems={'flex-start'} marginX={2} marginY={1}>
                <Small
                  sx={{
                    marginLeft: 1,
                    color: color.grey900
                  }}
                >
                  Select Date
                </Small>
                <TruentityDateFullRangePicker
                  disableFuture={true}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  onChange={handleDateChange}
                />
              </Stack>
            </Stack>
          </Stack>
          <TruentityDataGrid
            name={'dg-rpm-enrollment-record-view-all'}
            style={{ border: 'none', outline: 'none' }}
            autoHeight
            columnVisibilityModel={columnVisibilityModel}
            columns={columns}
            rows={rpmAccountProviderActivities}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            loading={rpmProviderAccountsQueryLoading || relyingPartiesDataLoading}
            rowCount={rowCountState}
            paginationMode="server"
          />
        </Stack>
      </Paper>
    </>
  );
};

export default RpmEnrollmentRecordViewAll;
