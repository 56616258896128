import { Box, type SnackbarCloseReason } from '@mui/material';
import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import { READ_ANNOUNCEMENT } from '@/graphql/announcements';
import { color } from '@/styles/assets/colors';
import type { Announcement } from '@/types/announcement';
import { useMutation } from '@apollo/client';
import { Campaign } from '@mui/icons-material';
import parse from 'html-react-parser';
import type { LastReadAnnouncement } from '.';
import CustomSnackbar from '../CustomSnackbar';
import { Body2, H4 } from '../Typography';

type Props = {
  announcement: Announcement | null;
  setAnnouncement: Dispatch<SetStateAction<Announcement | null>>;
  setLastReadAnnouncement: Dispatch<SetStateAction<LastReadAnnouncement>>;
};

const SecondaryAnnouncement = ({ announcement, setAnnouncement, setLastReadAnnouncement }: Props) => {
  const [open, setOpen] = useState(Boolean(announcement));

  const [readAnnouncement] = useMutation(READ_ANNOUNCEMENT, {
    onCompleted: data => {
      if (data.readAnnouncement.status === 'Failure') {
        console.error(data.readAnnouncement.message);
      } else {
        setLastReadAnnouncement(prev => ({ ...prev, secondaryAnnouncementId: announcement?.id || '' }));
      }
      setOpen(false);
    },
    onError: error => {
      console.error(error.message);
      setOpen(false);
    }
  });

  useEffect(() => {
    if (announcement && Object.keys(announcement).length > 0) {
      setOpen(true);
    }
  }, [announcement, setAnnouncement]);

  const handleExited = () => setAnnouncement(null);

  const contentMessage: React.ReactNode = (
    <>
      {announcement?.title && <H4 sx={{ color: color.truentitySalmon[800], marginBottom: 0.8 }}>{announcement?.title}</H4>}
      {announcement?.content && (
        <Box minHeight="40px" maxHeight="80px" overflow="auto">
          <Body2 color="white">{parse(announcement?.content)}</Body2>
        </Box>
      )}
    </>
  );

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    readAnnouncement({
      variables: {
        announcementId: announcement?.id
      }
    });
  };

  return (
    <>
      <CustomSnackbar
        open={open}
        message={contentMessage}
        handleClose={handleClose}
        handleExited={handleExited}
        icon={<Campaign fontSize="large" sx={{ color: 'white' }} />}
      />
    </>
  );
};

export default SecondaryAnnouncement;
