import type { ActivityLogType, EncounterType, TaskType } from '@/types/graphql';
import type Meta from '@/types/meta';
import { gql } from '@apollo/client';

export type SaveUpdateTaskResponse = {
  saveUpdateTask: {
    status: string;
    message: string;
  };
};
export const SAVE_UPDATE_TASK = gql`
  mutation saveUpdateTask($truentityId: String!, $taskData: TaskInput!, $taskId: String!) {
    saveUpdateTask(truentityId: $truentityId, taskData: $taskData, taskId: $taskId) {
      status
      message
    }
  }
`;

export type DeleteTaskResponse = {
  saveUpdateTask: {
    status: string;
  };
};
export const DELETE_TASK = gql`
  mutation deleteTask($taskId: String!, $truentityId: String!) {
    deleteTask(taskId: $taskId, truentityId: $truentityId) {
      status
    }
  }
`;

export type GetTasksEncountersActivityLogsResponse = {
  tasksEncountersLogs: {
    tasksEncountersActivityLogs: ActivityLogType[];
  };
};
export const GET_TASKS_ENCOUNTERS_ACTIVITY_LOGS = gql`
  query getTasksEncountersLogs($truentityId: String!, $pageNum: Int!, $pageSize: Int!, $taskId: String!) {
    tasksEncountersLogs(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize, taskId: $taskId) {
      tasksEncountersActivityLogs {
        logText
        activityTime
        account {
          id
          user {
            firstName
            lastName
          }
        }
        task {
          id
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const UPDATE_TASKS_VALIDATION_STATUS = gql`
  mutation UpdateTasksValidationStatus($taskIds: [String!]!, $validationStatus: String!) {
    updateTasksValidationStatus(taskIds: $taskIds, validationStatus: $validationStatus) {
      status
    }
  }
`;

export const UPDATE_ENCOUNTER = gql`
  mutation UpdateEncounter($truentityId: String!, $encounterId: String!, $encounterData: EncounterInput!) {
    updateEncounter(truentityId: $truentityId, encounterId: $encounterId, encounterData: $encounterData) {
      status
    }
  }
`;

export type GetTasksResponse = {
  tasks: {
    tasks: TaskType[];
    meta: Meta;
  };
};

export const GET_TASKS = gql`
  query getTasksQuery($truentityId: String!, $pageNum: Int!, $pageSize: Int!, $filterOptions: TaskFilterOptionsInput!) {
    tasks(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      tasks {
        id
        name
        type
        subType
        status
        resolutionStatus
        resolutionNotes
        performedOn
        completedByAdmin
        relyingPartyAdmins {
          id
          name
        }
        createdAt
        encounters {
          id
          type
          calledOn
          disposition
          notes
          createdAt
          modeOfResolution
        }
        billedAmount
        healthPlan
        location
        nextSteps
        medicationName
        modeOfResolution
        wrapUpStatus
        isPrimaryAffiliation
        priority
        bonusAmount
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_TASKS_BY_REYLING_PARTY = gql`
  query GetTasksByFilterQuery($filterOptions: TaskFilterOptionsInput!, $pageNum: Int!, $pageSize: Int!) {
    tasksByFilter(filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      tasks {
        id
        name
        type
        subType
        status
        resolutionStatus
        resolutionNotes
        performedOn
        completedByAdmin
        account {
          truentityId
          user {
            firstName
            middleName
            lastName
          }
        }
        billedAmount
        healthPlan
        location
        nextSteps
        medicationName
        modeOfResolution
        wrapUpStatus
        isPrimaryAffiliation
        priority
        bonusAmount
      }

      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_TASKS_BY_FILTER = gql`
  query GetTasksByFilterQuery($filterOptions: TaskFilterOptionsInput!, $pageNum: Int!, $pageSize: Int!, $isExport: Boolean!) {
    tasksByFilter(filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize, isExport: $isExport) {
      tasks {
        id
        name
        status
        type
        performedOn
        completedByAdmin
        wrapUpStatus
        modeOfResolution
        billedAmount
        bonusAmount
        account {
          truentityId
          user {
            firstName
            lastName
          }
          clientOrgs {
            name
          }
          clientStores {
            name
          }
        }
      }
      totalBonusAmount
      totalBilledAmount

      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_TASKS_BY_FILTER_TO_EXPORT = gql`
  query GetTasksByFilterQuery($filterOptions: TaskFilterOptionsInput!, $pageNum: Int!, $pageSize: Int!, $isExport: Boolean!) {
    tasksByFilter(filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize, isExport: $isExport) {
      tasks {
        id
        name
        status
        type
        performedOn
        completedByAdmin
        wrapUpStatus
        modeOfResolution
        billedAmount
        bonusAmount
        account {
          user {
            firstName
            lastName
          }
          clientOrgs {
            name
          }
          clientStores {
            name
          }
        }
      }
    }
  }
`;

export const ASSIGN_TASKS = gql`
  mutation AssignTasksToRelyingPartyAdmins($taskIds: [String!], $relyingPartyAdminIds: [String!]) {
    assignTasksToRelyingPartyAdmins(taskIds: $taskIds, relyingPartyAdminIds: $relyingPartyAdminIds) {
      status
      message
    }
  }
`;

export type GetEncounterResponse = {
  encounter: EncounterType;
};

export const GET_ENCOUNTER = gql`
  query GetEncounterQuery($encounterId: String!) {
    encounter(encounterId: $encounterId) {
      id
      title
      type
      subType
      disposition
      calledOn
      modeOfResolution
      notes
      tasks {
        id
        type
        modeOfResolution
        healthPlan
        billedAmount
        wrapUpStatus
        performedOn
        isPrimaryAffiliation
        medicationName
      }
    }
  }
`;

export type GetEncountersResponse = {
  encounters: {
    encounters: EncounterType[];
  };
};

export const GET_ENCOUNTERS = gql`
  query GetEncountersQuery($truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    encounters(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      encounters {
        id
        title
        type
        subType
        calledOn
        notes
        disposition
        modeOfResolution
        calledOn
        createdAt
        tasks {
          id
          type
          modeOfResolution
          healthPlan
          billedAmount
          wrapUpStatus
          performedOn
          isPrimaryAffiliation
          medicationName
          bonusAmount
        }
      }
    }
  }
`;

export const DELETE_ENCOUNTER = gql`
  mutation deleteEncounter($encounterId: String!, $truentityId: String!) {
    deleteEncounter(encounterId: $encounterId, truentityId: $truentityId) {
      status
    }
  }
`;

export const ADD_ENCOUNTER = gql`
  mutation saveEncounter(
    $truentityId: String!
    $title: String!
    $type: String!
    $subType: String!
    $calledOn: ISO8601DateTime!
    $notes: String!
    $disposition: String!
    $selectedTasks: [String!]
    $modeOfResolution: String!
    $accountCareEventId: String
  ) {
    saveEncounter(
      truentityId: $truentityId
      title: $title
      type: $type
      subType: $subType
      calledOn: $calledOn
      notes: $notes
      disposition: $disposition
      selectedTasks: $selectedTasks
      modeOfResolution: $modeOfResolution
      accountCareEventId: $accountCareEventId
    ) {
      call {
        id
        type
      }
    }
  }
`;
