import type { DateRangeSelection } from '@/components/TruentityDateRangePicker';
import type { ReadmissionOptions } from '@/routes/TransitionalCareManagement/DueDateFilters';
import { createStore, useStore } from 'zustand';

export type PatientListToolbarFilters = {
  onboardedOn: DateRangeSelection;
  organization?: string;
  conversationDuration?: DateRangeSelection;
  conversationStatus?: string;
  readmissionRisk?: ReadmissionOptions;
  numberOfMeds?: string;
};

export type PatientFilter = {
  id: string;
  shared?: boolean;
  name: string;
  description?: string;
  filter: PatientListToolbarFilters;
};

interface PatientFilterState {
  filters: PatientFilter[];
  setFilters: (val: PatientFilter[]) => void;
  selectedFilter: PatientFilter | null;
  setSelectedFilter: (val: PatientFilter | null) => void;
}

const patientFilterStore = createStore<PatientFilterState>()(set => ({
  filters: [],
  setFilters: val => set(state => ({ ...state, filters: val })),
  selectedFilter: null,
  setSelectedFilter: val => set(state => ({ ...state, selectedFilter: val }))
}));

export function usePatientFilterStore(): PatientFilterState;
// eslint-disable-next-line no-redeclare
export function usePatientFilterStore<T>(selector: (state: PatientFilterState) => T, equals?: (a: T, b: T) => boolean): T;
// eslint-disable-next-line no-redeclare
export function usePatientFilterStore<T>(selector?: (state: PatientFilterState) => T, equals?: (a: T, b: T) => boolean) {
  return useStore(patientFilterStore, selector!, equals);
}
