import {
  DEFAULT_DOSAGE,
  DEFAULT_PERIOD,
  DEFAULT_REASON,
  DEFAULT_ROUTE_OF_ADMINISTRATION,
  DEFAULT_STRENGTH,
  DEFAULT_TIME_OF_DAY,
  FormState,
  SourceType
} from '@/types/medication';

export const emptyData: FormState = {
  id: null,
  ndc: null,
  name: '',
  displayName: '',
  strength: { ...DEFAULT_STRENGTH },
  modifiedStrength: { ...DEFAULT_STRENGTH },
  dosage: { ...DEFAULT_DOSAGE },
  modifiedDosage: { ...DEFAULT_DOSAGE },
  period: { ...DEFAULT_PERIOD },
  modifiedPeriod: { ...DEFAULT_PERIOD },
  routeOfAdministration: { ...DEFAULT_ROUTE_OF_ADMINISTRATION },
  modifiedRouteOfAdministration: { ...DEFAULT_ROUTE_OF_ADMINISTRATION },
  timeOfDay: { ...DEFAULT_TIME_OF_DAY },
  modifiedTimeOfDay: { ...DEFAULT_TIME_OF_DAY },
  instructions: '',
  isTakenAsPrescribed: true,
  isTakenAsDirected: true,
  isNoLongerTaking: false,
  isTakingDifferently: false,
  isUnableToVerify: false,
  prescriberName: '',
  lastFillDateAt: new Date(),
  quantity: 0.0,
  refills: '',
  pharmacyName: '',
  prescriptionWrittenDateAt: new Date(),
  soldDateAt: new Date(),
  isEffective: true,
  source: SourceType.PROVIDER,
  reason: { ...DEFAULT_REASON },
  isSupplement: false,
  rxcui: null
};

export const reducer = (state, action: { type: string; payload: string | null | boolean | Date | undefined }) => {
  if (action.type === 'id') {
    return {
      ...state,
      id: action.payload
    };
  }
  if (action.type === 'name') {
    return {
      ...state,
      name: action.payload
    };
  }
  if (action.type === 'ndc') {
    return {
      ...state,
      ndc: action.payload
    };
  }
  if (action.type === 'source') {
    return {
      ...state,
      source: action.payload
    };
  }

  if (action.type === 'strength-id') {
    return {
      ...state,
      strength: {
        ...state.strength,
        id: action.payload
      }
    };
  }

  if (action.type === 'strength-value') {
    return {
      ...state,
      strength: {
        ...state.strength,
        value: action.payload
      }
    };
  }
  if (action.type === 'strength-unit') {
    return {
      ...state,
      strength: {
        ...state.strength,
        unit: action.payload
      }
    };
  }

  if (action.type === 'dosage-id') {
    return {
      ...state,
      dosage: {
        ...state.dosage,
        id: action.payload
      }
    };
  }

  if (action.type === 'dosage-value') {
    return {
      ...state,
      dosage: {
        ...state.dosage,
        value: action.payload
      }
    };
  }
  if (action.type === 'dosage-unit') {
    return {
      ...state,
      dosage: {
        ...state.dosage,
        unit: action.payload
      }
    };
  }

  if (action.type === 'period-id') {
    return {
      ...state,
      period: {
        ...state.period,
        id: action.payload
      }
    };
  }

  if (action.type === 'period-value') {
    return {
      ...state,
      period: {
        ...state.period,
        value: action.payload
      }
    };
  }
  if (action.type === 'period-unit') {
    return {
      ...state,
      period: {
        ...state.period,
        unit: action.payload
      }
    };
  }
  if (action.type === 'taken-as-prescribed') {
    return {
      ...state,
      isTakenAsPrescribed: action.payload
    };
  }

  if (action.type === 'modified-strength-id') {
    return {
      ...state,
      modifiedStrength: {
        ...state.modifiedStrength,
        id: action.payload
      }
    };
  }

  if (action.type === 'modified-strength-value') {
    return {
      ...state,
      modifiedStrength: {
        ...state.modifiedStrength,
        value: action.payload
      }
    };
  }
  if (action.type === 'modified-strength-unit') {
    return {
      ...state,
      modifiedStrength: {
        ...state.modifiedStrength,
        unit: action.payload
      }
    };
  }

  if (action.type === 'modified-dosage-id') {
    return {
      ...state,
      modifiedDosage: {
        ...state.modifiedDosage,
        id: action.payload
      }
    };
  }

  if (action.type === 'modified-dosage-value') {
    return {
      ...state,
      modifiedDosage: {
        ...state.modifiedDosage,
        value: action.payload
      }
    };
  }
  if (action.type === 'modified-dosage-unit') {
    return {
      ...state,
      modifiedDosage: {
        ...state.modifiedDosage,
        unit: action.payload
      }
    };
  }

  if (action.type === 'modified-period-id') {
    return {
      ...state,
      modifiedPeriod: {
        ...state.modifiedPeriod,
        id: action.payload
      }
    };
  }

  if (action.type === 'modified-period-value') {
    return {
      ...state,
      modifiedPeriod: {
        ...state.modifiedPeriod,
        value: action.payload
      }
    };
  }
  if (action.type === 'modified-period-unit') {
    return {
      ...state,
      modifiedPeriod: {
        ...state.modifiedPeriod,
        unit: action.payload
      }
    };
  }

  if (action.type === 'route-of-administration-id') {
    return {
      ...state,
      routeOfAdministration: {
        ...state.routeOfAdministration,
        id: action.payload
      }
    };
  }

  if (action.type === 'route-of-administration') {
    return {
      ...state,
      routeOfAdministration: {
        ...state.routeOfAdministration,
        value: action.payload
      }
    };
  }

  if (action.type === 'time-of-day-id') {
    return {
      ...state,
      timeOfDay: {
        ...state.timeOfDay,
        id: action.payload
      }
    };
  }

  if (action.type === 'time-of-day') {
    return {
      ...state,
      timeOfDay: {
        ...state.timeOfDay,
        value: action.payload
      }
    };
  }
  if (action.type === 'instructions') {
    return {
      ...state,
      instructions: action.payload
    };
  }

  if (action.type === 'modified-route-of-administration-id') {
    return {
      ...state,
      modifiedRouteOfAdministration: {
        ...state.modifiedRouteOfAdministration,
        id: action.payload
      }
    };
  }

  if (action.type === 'modified-route-of-administration') {
    return {
      ...state,
      modifiedRouteOfAdministration: {
        ...state.modifiedRouteOfAdministration,
        value: action.payload
      }
    };
  }

  if (action.type === 'modified-time-of-day-id') {
    return {
      ...state,
      modifiedTimeOfDay: {
        ...state.modifiedTimeOfDay,
        id: action.payload
      }
    };
  }

  if (action.type === 'modified-time-of-day') {
    return {
      ...state,
      modifiedTimeOfDay: {
        ...state.modifiedTimeOfDay,
        value: action.payload
      }
    };
  }
  if (action.type === 'prescriber') {
    return {
      ...state,
      prescriber: action.payload
    };
  }
  if (action.type === 'last-fill-date') {
    return {
      ...state,
      lastFillDateAt: action.payload
    };
  }
  if (action.type === 'quantity') {
    return {
      ...state,
      quantity: action.payload
    };
  }

  if (action.type === 'refills') {
    return {
      ...state,
      refills: action.payload
    };
  }

  if (action.type === 'is-effective') {
    return {
      ...state,
      isEffective: action.payload
    };
  }
  if (action.type === 'reason') {
    return {
      ...state,
      reason: {
        ...state.reason,
        value: action.payload
      }
    };
  }

  if (action.type === 'no-longer-taking') {
    return {
      ...state,
      isNoLongerTaking: action.payload
    };
  }
  if (action.type === 'taking-differently') {
    return {
      ...state,
      isTakingDifferently: action.payload
    };
  }
  if (action.type === 'unable-to-verify') {
    return {
      ...state,
      isUnableToVerify: action.payload
    };
  }
  if (action.type === 'taken-as-directed') {
    return {
      ...state,
      isTakenAsDirected: action.payload
    };
  }
  if (action.type === 'is-supplement') {
    return {
      ...state,
      isSupplement: action.payload
    };
  }
  if (action.type === 'rxcui') {
    return {
      ...state,
      rxcui: action.payload
    };
  }

  if (action.type === 'pharmacy') {
    return {
      ...state,
      pharmacy: action.payload
    };
  }

  return state;
};
