import { ChipProps, Fade } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

interface CustomChipProps extends ChipProps {
  textColor?: string;
  borderColor?: string;
  tooltipTitle?: string;
  isDisabled?: boolean;
}

const TruentityChip = ({ tooltipTitle, textColor, borderColor, isDisabled = false, ...props }: CustomChipProps) => {
  const color = isDisabled ? 'default' : props.color;
  return (
    <Tooltip title={tooltipTitle} TransitionComponent={Fade}>
      <span>
        <Chip sx={{ color: textColor, borderColor: borderColor }} {...props} disabled={isDisabled} color={color} />
      </span>
    </Tooltip>
  );
};

export default TruentityChip;
