import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import type { PatientsData } from '@/routes/Patients/patients';
import { MedicalServices } from '@/types/admin';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

type Props = Pick<PatientsData, 'truentityId' | 'rpmStatus' | 'accountsMonitoringDevices' | 'user'>;

const PatientLink = ({ truentityId, rpmStatus, accountsMonitoringDevices, user }: Props) => {
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);

  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [fullName] = useState<string>(getAccountUserFullName(user));

  useEffect(() => {
    let url = '';
    if (isRPMViewable) {
      url = getAccountRedirectUrl(truentityId, MedicalServices.RPM, rpmStatus, accountsMonitoringDevices);
    } else if (isMTMViewable) {
      url = getAccountRedirectUrl(truentityId, MedicalServices.MTM, rpmStatus);
    } else if (isTCMViewable) {
      url = getAccountRedirectUrl(truentityId, MedicalServices.TCM, rpmStatus);
    }
    setRedirectUrl(url);
  }, [isRPMViewable, isMTMViewable, isTCMViewable, truentityId, rpmStatus, accountsMonitoringDevices]);

  return <Link to={redirectUrl ?? ''}>{fullName}</Link>;
};

export default PatientLink;
