import React from 'react';

type FilterContextType = {
  filtersToBeApplied: any[];
  setFiltersToBeApplied: (val) => void;
};

const initialValue = {
  filtersToBeApplied: [],
  setFiltersToBeApplied: val => {}
};

const FilterContext = React.createContext<FilterContextType>(initialValue);

export default FilterContext;
