import styled from '@/styles';
import { DialogContent } from '@mui/material';
import Markdown from 'react-markdown';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  text: string;
};

const DivViewer = styled('div', {
  width: '100%',
  height: '100%',
  minHeight: '600px'
});

const MarkdownDialog = ({ title, text, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} fullWidth maxWidth="lg">
      <DialogContent
        sx={{
          overflowY: 'hidden'
        }}
      >
        <Markdown>{text}</Markdown>
      </DialogContent>
    </BaseDialog>
  );
};

export default MarkdownDialog;
