import { Body2, H1, H3, H4 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { AlertLimitType } from '@/types/remotePatientMonitoring';
import { Box, Stack } from '@mui/material';
import React, { useCallback } from 'react';

interface AlertLimitsCardProps {
  values: AlertLimitType;
  backgroundColor?: string;
  size?: 'small' | 'large';
}
export const AlertLimitsCard: React.FC<AlertLimitsCardProps> = ({ values, backgroundColor, size = 'large' }) => {
  const renderTitle = useCallback(
    (title: string, color: string) => {
      if (size === 'large') {
        return (
          <H4
            sx={{
              color: color
            }}
          >
            {title}
          </H4>
        );
      }

      return (
        <Body2
          sx={{
            fontWeight: 'bold',
            color: color
          }}
        >
          {title}
        </Body2>
      );
    },
    [size]
  );

  const renderValue = useCallback(
    (value: string | number) => {
      if (size === 'large') {
        return (
          <H1 color={color.black100} fontSize="2.5rem">
            {value}
          </H1>
        );
      }
      return (
        <H3
          sx={{
            fontWeight: 'normal'
          }}
          color={color.black100}
        >
          {value}
        </H3>
      );
    },
    [size]
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 1,
        p: size === 'small' ? 1 : 2,
        backgroundColor: backgroundColor ?? '#F0F6FB'
      }}
    >
      <Stack
        sx={{
          textAlign: 'center'
        }}
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Stack
          sx={{
            borderRight: 1,
            p: 0.5,
            borderColor: theme => theme.palette.divider
          }}
          spacing={2}
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {renderTitle('Critically Low', color.brown700)}
          {renderValue(values.cLowValue)}
        </Stack>
        <Stack
          sx={{
            borderRight: 1,
            p: 0.5,
            borderColor: theme => theme.palette.divider
          }}
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {renderTitle('Low', color.warningMain)}
          {renderValue(values.lowValue)}
        </Stack>
        <Stack
          sx={{
            borderRight: 1,
            p: 0.5,
            borderColor: theme => theme.palette.divider
          }}
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {renderTitle('High', color.pink100)}
          {renderValue(values.highValue)}
        </Stack>
        <Stack
          sx={{
            p: 0.5
          }}
          flex={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {renderTitle('Critically High', color.errorMain)}
          {renderValue(values.cHighValue)}
        </Stack>
      </Stack>
    </Box>
  );
};

export default AlertLimitsCard;
