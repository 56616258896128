import MuiTabs from '@/components/MuiTabs';
import { H1, H3, H4 } from '@/components/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, Divider, Grid, IconButton, Paper, Stack } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import StoreIcon from '@mui/icons-material/Store';

import { GridItem } from '@/components/GridItem';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { getEmail, getTelephone } from '../..';
import type { ClientOrganizationObject } from '../../../../types/administration';

type Props = {
  organizationInfo: ClientOrganizationObject;
};

const OrganizationInfo = ({ organizationInfo }: Props) => {
  const [collapsePatientInfo, setCollapsePatientInfo] = useState(true);

  const createdAt = moment(organizationInfo?.createdAt).format('MMM DD, YYYY');

  return (
    <Paper component={Stack} p={4} spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems={'center'} spacing={2}>
          <H1>{organizationInfo?.name} </H1>
        </Stack>

        <Stack
          sx={{ cursor: 'pointer' }}
          onClick={() => setCollapsePatientInfo(!collapsePatientInfo)}
          spacing={1}
          direction="row"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <IconButton>
            <ExpandMoreIcon sx={{ transform: collapsePatientInfo ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          </IconButton>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems={'center'} spacing={1}>
        <H4>Added on</H4>
        <H3>{createdAt}</H3>
      </Stack>

      {!collapsePatientInfo && <Divider sx={{ marginTop: '10px' }} />}

      <Collapse in={!collapsePatientInfo} timeout="auto">
        <MuiTabs
          tabs={[
            {
              label: 'Profile',
              children: (
                <Stack spacing={4}>
                  <Grid container rowSpacing={4}>
                    <GridItem xs={6} icon={<MailIcon fontSize={'small'} />} label="Email" value={getEmail(organizationInfo)} />
                    <GridItem
                      xs
                      icon={<PersonIcon fontSize={'small'} />}
                      label="# of Patients"
                      value={organizationInfo?.accounts?.length || 0}
                    />
                    <GridItem
                      xs={6}
                      icon={<LocalPhoneIcon fontSize={'small'} />}
                      label="Phone"
                      value={<TruentityPhoneNumber value={getTelephone(organizationInfo)} label={''} />}
                    />
                    <GridItem
                      xs
                      icon={<StoreIcon fontSize={'small'} />}
                      label="# of Locations"
                      value={organizationInfo?.clientStores?.length || 0}
                    />

                    <GridItem
                      xs={6}
                      icon={<RoomIcon fontSize={'small'} />}
                      label="Location"
                      value={organizationInfo?.addresses?.length > 0 ? organizationInfo?.addresses[0].line1 : '---'}
                    />
                  </Grid>
                </Stack>
              )
            }
          ]}
        />
      </Collapse>
    </Paper>
  );
};

export default OrganizationInfo;
