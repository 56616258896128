import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import SendInvoiceEmailDialog from '@/components/Dialogs/SendInvoiceEmailDialog';
import MuiTabs from '@/components/MuiTabs';
import PDFViewer from '@/components/PDFViewer';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import type { InvoicePDFResponse } from '@/graphql/invoices';
import { CHANGE_INVOICE_STATUSES, DELETE_INVOICE, GET_INVOICE, INVOICE_PDF } from '@/graphql/invoices';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { formatDateIgnoreTZ, secondsToTime } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Chip, Paper, Stack } from '@mui/material';
import type { GridColDef, GridSortItem } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { TimesheetData } from '../Timesheets/Timesheets';
import type { InvoiceData } from './Invoices';
import { InvoiceStatuses } from './Invoices';

const InvoiceDetails = () => {
  const { id: invoiceId } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [invoice, setInvoice] = useState<InvoiceData | null>(null);
  const [timesheets, setTimesheets] = useState<TimesheetData[]>([]);

  const { roleType } = useToken();
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);

  const [updateInvoiceStatuses] = useMutation(CHANGE_INVOICE_STATUSES);

  const { data } = useQuery(GET_INVOICE, { variables: { invoiceId } });
  const [createPdf, { data: invoicePdf }] = useMutation<InvoicePDFResponse>(INVOICE_PDF);
  const { setContent } = useContext(SideMenuContext);
  const [deleteInvoice] = useMutation(DELETE_INVOICE);
  const [pdfUrl, setPdfUrl] = useState('');

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'date',
      sort: 'desc'
    }
  ]);

  const { showModal } = useModal();

  const showConfirmDialog = (title, message, onAgree, onDisagree?) => {
    const modal = showModal(ConfirmDialog, {
      title,
      message,
      onAgree: () => {
        modal.hide();
        onAgree();
      },
      onDisagree: () => {
        modal.hide();
        if (onDisagree) {
          onDisagree();
        }
      }
    });
  };

  const callDeleteInvoice = () => {
    showConfirmDialog('Delete Invoice', 'This invoice has not been submitted yet and will be removed. Continue?', () => {
      deleteInvoice({
        variables: {
          invoiceId: invoice?.id
        }
      });
    });
  };

  const emailInvoice = () => {
    const medRecModal = showModal(SendInvoiceEmailDialog, {
      title: 'Email Invoice',
      subject: `Invoice for ${invoice?.relyingPartyAdmin?.name || 'Unknown'}`,
      successMessage: 'Invoice has been emailed to the recipients',
      invoiceId,
      invoiceData: invoice,
      hideDialog: () => medRecModal.hide(),
      onEmailSent: () => {
        medRecModal.hide();
        navigate(`../invoices?status=${InvoiceStatuses.Invoiced}`);
      },
      isInFinanceEmail: false
    });
  };

  useEffect(() => {
    setContent(<MainSideMenu />);
    createPdf({
      variables: {
        invoiceId
      }
    });
  }, []);

  useEffect(() => {
    if (data) {
      setInvoice(data.invoiceGet);
    }
  }, [data]);

  useEffect(() => {
    if (invoicePdf && invoicePdf?.invoiceCreatePdfReport?.pdfDownloadUrl) {
      const pdfDownloadUrl = invoicePdf.invoiceCreatePdfReport.pdfDownloadUrl;
      fetch(`${pdfDownloadUrl}`)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
          setPdfUrl(url);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [invoicePdf]);

  useEffect(() => {
    if (invoice) {
      setTimesheets(invoice.timesheets);
    }
  }, [invoice]);

  const changeStatusToInFinance = () => {
    let name = (invoice?.relyingPartyAdmin?.user?.firstName || '') + ' ' + (invoice?.relyingPartyAdmin?.user?.lastName || '');
    name = name.length === 1 ? 'Unknown' : name;

    const medRecModal = showModal(SendInvoiceEmailDialog, {
      title: 'Update Invoice status to In Finance and Email',
      subject: `Invoice for ${name}`,
      successMessage: 'Invoice has been emailed to the recipient and status updated successfully',
      invoiceId,
      invoiceData: invoice,
      hideDialog: () => medRecModal.hide(),
      onEmailSent: () => {
        medRecModal.hide();
        navigate(`../invoices?status=${InvoiceStatuses.InFinance}`);
      },
      isInFinanceEmail: true
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        sortable: true,
        type: 'string',
        flex: 1,
        valueGetter: params => formatDateIgnoreTZ(params.row.datePerformed, 'YYYY-MM-DD')
      },
      {
        field: 'time',
        headerName: 'Time',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          const hours = params.row.numberOfHours || 0;
          const minutes = params.row.numberOfMinutes || 0;

          return secondsToTime(hours * 3600 + minutes * 60);
        }
      },
      {
        field: 'customer',
        headerName: 'Customer',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          const orgName = params.row.clientOrganization?.name || '';
          const storeName = params.row.clientStore?.name || '';
          if (storeName) return orgName + ' - ' + storeName;
          else return orgName;
        }
      },
      {
        field: 'task',
        headerName: 'Task',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.description
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        renderCell: params => <Chip sx={{ textTransform: 'capitalize' }} label={params.row.status} />
      }
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} justifyContent={'flex-start'}>
        <Button startIcon={<ArrowBackIosNewIcon />} label="Back" onClick={() => navigate('../invoices')} />
      </Stack>
      <Stack alignItems={'center'} direction="row" justifyContent={'space-between'}>
        <Chip label={invoice?.status?.toUpperCase()} size="medium" />

        {invoice?.status === InvoiceStatuses.Pending && (
          <Stack direction="row" spacing={1}>
            <Button label="Delete" onClick={() => callDeleteInvoice()} />
            <Button label="Submit and Email Invoice" onClick={() => emailInvoice()} />
          </Stack>
        )}

        {(isPharmacistAdmin || isSuperAdmin) && invoice?.status === InvoiceStatuses.Invoiced && (
          <Stack direction="row" spacing={1}>
            <Button label="Email Invoice to Finance" onClick={() => changeStatusToInFinance()} />
          </Stack>
        )}
      </Stack>

      <Paper component={Stack} direction="column" spacing={2}>
        <MuiTabs
          tabVariant="standard"
          tabs={[
            {
              label: 'Timesheets',
              children: (
                <Stack spacing={2}>
                  <div style={{ display: 'flex' }}>
                    <TruentityDataGrid
                      name={'dg-invoice-details'}
                      autoHeight
                      rows={timesheets}
                      columns={columns}
                      paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                      onPaginationModelChange={({ page }) => setCurrentPage(page)}
                      sortModel={sortModel}
                      onSortModelChange={model => setSortModel(model)}
                      paginationMode="client"
                      disableRowSelectionOnClick
                    />
                  </div>
                </Stack>
              )
            },
            {
              label: 'Document',
              children: (
                <Stack>
                  <PDFViewer src={pdfUrl} />
                </Stack>
              )
            }
          ]}
        />
      </Paper>
    </Stack>
  );
};

export default InvoiceDetails;
