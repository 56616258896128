import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import AddActivityDialog from '@/components/Dialogs/AddActivityDialog';
import DeleteAccountActivityConfirmationDialog from '@/components/Dialogs/DeleteAccountActivityConfirmationDialog';
import UpdateAccountActivityDialog from '@/components/Dialogs/UpdateAccountActivityDialog';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1 } from '@/components/Typography';
import type { GetRpmActivitiesResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_ACTIVITIES } from '@/graphql/remotePatientMonitoring';
import type { CareActivityType } from '@/types/remotePatientMonitoring';

import MultilineCell from '@/components/DataGrid/MultilineCell';
import type { GetPatientDetailResponse } from '@/graphql/account';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import { CustomTimezone } from '@/types/date';
import { getAccountUserFullName } from '@/util/account';
import { getGeneralTimezone } from '@/util/date';
import { formatDate, formatDateToTimezone, formatSecondsToHMS, formatTime, secondsToTime } from '@/util/format';
import { capitalizeLetterBeforeCharacter } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Chip, IconButton, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { MomentInput } from 'moment';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import BarChartSection from './Components/Vitals/BarChart';

const activityLogsTabs = [
  {
    a11yLabel: 'setup',
    label: 'Setup',
    path: 'setup',
    disabled: false
  },
  {
    a11yLabel: 'monthly-care-management',
    label: 'Monthly Care Management',
    path: 'monthly-care-management',
    disabled: false
  }
];

const ActivityLogs = () => {
  const currentDateTime = new Date();
  const { showModal } = useModal();
  const { id } = useParams();
  const { setIsAccountChanged } = useAccountStore();

  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);

  const [activeTab, setActiveTab] = useState<number>(1);
  const [totalSetupTime, setTotalSetupTime] = useState(0);
  const [totalMonthlyTime, setTotalMonthlyTime] = useState(0);

  const [currentPage, setCurrentPage] = useState(0);
  const [activityLogs, setActivityLogs] = useState<CareActivityType[]>([]);
  const [startDate, setStartDate] = useState<DateInput>(formatTime(currentDateTime, 'MMM YYYY'));

  const [chartXValues, setChartXValues] = useState<string[]>([]);
  const [chartYValues, setChartYValues] = useState<number[]>([]);

  const [rpmEnrolledAtDate, setRpmEnrolledAtDate] = useState<MomentInput>('');
  const [rpmPatientStatus, setRpmPatientStatus] = useState<string>('');

  const csvHeaders = [
    { label: 'Title', key: 'Title' },
    { label: 'Notes', key: 'Notes' },
    { label: 'Type', key: 'Type' },
    { label: 'Total Time', key: 'Total Time' }
  ];

  const [getRpmActivities, { data: rpmActivitiesData, refetch: refetchRpmActivitiesData, loading: rpmActivitiesLoading }] =
    useLazyQuery<GetRpmActivitiesResponse>(GET_RPM_ACTIVITIES, {
      fetchPolicy: 'cache-and-network'
    });

  const [getInfo, { data: patientData }] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL);

  const onActivitiesChanged = useCallback(async () => {
    await refetchRpmActivitiesData();
    setIsAccountChanged(true);
  }, []);

  const showUpdateActivityDialog = (data: CareActivityType, rpmEnrolledAtDate) => {
    const modal = showModal(UpdateAccountActivityDialog, {
      title: 'Update Activity',
      hideDialog: () => {
        modal.hide();
      },
      onActivitiesChanged: onActivitiesChanged,
      id: id,
      data: data,
      rpmEnrolledAtDate: moment(rpmEnrolledAtDate, 'YYYY-MM-DD HH:mm:ss'),
      rpmStatus: rpmPatientStatus
    });
  };

  const showDeleteActivityDialog = (data: CareActivityType) => {
    const modal = showModal(DeleteAccountActivityConfirmationDialog, {
      title: 'Delete Activity',
      hideDialog: () => {
        modal.hide();
      },
      onActivitiesChanged: onActivitiesChanged,
      id: id,
      data: data
    });
  };

  const showAddActivityDialog = (title: string) => {
    const modal = showModal(AddActivityDialog, {
      title: 'Add Activity',
      activityTitle: title,
      hideDialog: () => {
        modal.hide();
      },
      onActivitiesChanged: onActivitiesChanged,
      id: id,
      rpmEnrolledAtDate: moment(rpmEnrolledAtDate, 'YYYY-MM-DD HH:mm:ss'),
      rpmStatus: rpmPatientStatus
    });
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'title',
        headerName: 'Activity',
        flex: 2,
        minWidth: 200,
        sortable: false
      },
      {
        field: 'timeSpent',
        headerName: 'Time Spent (hh:mm:ss)',
        valueGetter: params => secondsToTime(params.row.totalTimeSpentSecs),
        flex: 1,
        sortable: false
      },
      {
        field: 'notes',
        headerName: 'Notes',
        flex: 2,
        flexGrow: 1,
        valueGetter: params => params.row.notes ?? '',
        renderCell: params => (
          <Tooltip title={params.value} arrow>
            <MultilineCell>{params.value}</MultilineCell>
          </Tooltip>
        ),
        sortable: false
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 2,
        renderCell: params => (
          <Chip label={params.row.isEncounter ? 'Encounter' : 'Care Activity'} variant="outlined" sx={{ width: 'fit-content' }} />
        ),
        sortable: false
      },
      {
        field: 'performedBy',
        headerName: 'Performed By',
        flex: 2,
        valueGetter: params => {
          if (params.row?.performedBy?.user) {
            return getAccountUserFullName(params.row?.performedBy?.user);
          } else {
            return '---';
          }
        },
        sortable: false
      },
      {
        field: 'date',
        headerName: 'Date',
        valueGetter: params => formatDate(params.row.startedAt, 'MMM DD, YYYY'),
        flex: 2,
        sortable: false
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        sortable: false,
        renderCell: params => (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton
              color="primary"
              onClick={e => {
                e.preventDefault();
                showUpdateActivityDialog(params.row, rpmEnrolledAtDate);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={e => {
                e.preventDefault();
                showDeleteActivityDialog(params.row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        )
      }
    ],
    [rpmEnrolledAtDate]
  );

  const callActivitiesQuery = async () => {
    await getRpmActivities({
      variables: {
        truentityId: id,
        monthYear: activeTab === 0 ? 'all' : formatTime(startDate, 'MMM YYYY'),
        subType: activeTab === 0 ? 'Setup' : 'Monthly Care Management',
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    }).catch((error: ApolloError) => {
      console.error(error);
    });
  };

  const handleTabChange = useCallback((event: SyntheticEvent, selectedTabIndex: number): void => {
    setActiveTab(selectedTabIndex);
    setFetchDataButtonVisible(true);
  }, []);

  const setChartValues = (data: CareActivityType[]) => {
    const aggregatedData: CareActivityType[] = data.reduce((accumulator, currentActivity) => {
      const existingActivity = accumulator.find(item => item.title.toLowerCase() === currentActivity.title.toLowerCase());
      if (existingActivity) {
        existingActivity.totalTimeSpentSecs += currentActivity.totalTimeSpentSecs;
      } else {
        accumulator.push({ ...currentActivity });
      }
      return accumulator;
    }, [] as CareActivityType[]);

    const sortedValues = [...aggregatedData].sort((a, b) => (a.totalTimeSpentSecs > b.totalTimeSpentSecs ? 1 : -1));
    const xValues = sortedValues.map(item => item.title);
    if (xValues !== undefined) {
      setChartXValues(xValues);
    }
    const yValues = sortedValues.map(item => Math.floor(item.totalTimeSpentSecs / 60));
    if (yValues !== undefined) {
      setChartYValues(yValues);
    }
  };

  const getPerformanceReportDataFiltered = () => {
    const DEFAULT_STRING = 'Not Provided';
    const activitiesDataObject = activityLogs.map(
      data => ({
        Title: data.title || DEFAULT_STRING,
        Notes: data.notes || DEFAULT_STRING,
        Type: data.subType || DEFAULT_STRING,
        'Total Time': secondsToTime(data?.totalTimeSpentSecs) || DEFAULT_STRING
      }),
      []
    );
    return activitiesDataObject;
  };

  const exportDataToCsv = () => {
    callActivitiesQuery();
  };

  const onFetchExportButtonClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv();
  };

  useEffect(() => {
    callActivitiesQuery();
  }, [activeTab, startDate, currentPage]);

  useEffect(() => {
    if (rpmActivitiesData?.getRpmActivities) {
      setActivityLogs(rpmActivitiesData?.getRpmActivities?.careActivity ?? []);
      setTotalSetupTime(rpmActivitiesData?.getRpmActivities?.setupTotalTime);
      setTotalMonthlyTime(rpmActivitiesData?.getRpmActivities?.monthlyTotalTime);
      setChartValues(rpmActivitiesData?.getRpmActivities?.careActivity ?? []);
    }
  }, [rpmActivitiesData]);

  useEffect(() => {
    getInfo({
      variables: {
        truentityId: id
      }
    });
  }, [id]);

  useEffect(() => {
    if (patientData) {
      const rpmStatus = patientData?.accountGet?.rpmStatus;
      const rpmEnrolledDate = formatDateToTimezone({
        date: patientData?.accountGet?.rpmEnrolledAt,
        format: 'YYYY-MM-DD HH:mm:ss',
        timezone: getGeneralTimezone(CustomTimezone.ADMIN_TIMEZONE)
      });
      setRpmEnrolledAtDate(rpmEnrolledDate as MomentInput);
      setRpmPatientStatus(rpmStatus);
    }
  }, [patientData]);

  return (
    <Stack>
      <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
        <H1 textAlign="left" sx={{ flex: 4 }}>
          Activities
        </H1>
        {activeTab == 1 && (
          <TruentityDatePicker
            showMonth={true}
            showYear={true}
            value={startDate}
            onChange={date => setStartDate(date as Date)}
            views={['month', 'year']}
            sx={{ width: '415px' }}
          />
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={e => {
            e.preventDefault();
            showAddActivityDialog('Add Activity');
          }}
        >
          Add Activity
        </Button>
      </Stack>

      <Stack
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: 3, marginBottom: 3 }}
      >
        <Tabs value={activeTab} onChange={handleTabChange} variant="standard">
          {activityLogsTabs?.map((tab, index: number) => (
            <Tab
              sx={{ textTransform: 'none' }}
              disabled={tab.disabled}
              label={
                <h6>
                  {capitalizeLetterBeforeCharacter(tab.label)}
                  {'  '}
                  {activeTab === index && (
                    <Chip
                      size="small"
                      label={tab.label === 'Setup' ? formatSecondsToHMS(totalSetupTime) : formatSecondsToHMS(totalMonthlyTime)}
                      color={index === activeTab ? 'success' : 'default'}
                      sx={{
                        '& .MuiChip-root': {
                          height: '1.125rem'
                        },
                        '& .MuiChip-label': {
                          fontSize: '.6875rem'
                        }
                      }}
                    />
                  )}
                </h6>
              }
              key={`tab-${index}`}
              id={`tab-${index}`}
            />
          ))}
        </Tabs>
        {fetchDataButtonVisible ? (
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={activityLogs.length === 0}
            onClick={() => {
              onFetchExportButtonClicked();
            }}
          >
            Load for Export
          </Button>
        ) : (
          <>
            <Button isLoading={rpmActivitiesLoading} color="primary" variant="contained" size="small">
              {activityLogs && activityLogs.length > 0 && (
                <CSVLink
                  headers={csvHeaders}
                  data={getPerformanceReportDataFiltered()}
                  filename={`Activity Report - ${
                    activeTab === 0 ? 'Setup' : `Monthly Care Management - ${formatTime(startDate, 'MMM YYYY')}`
                  }.csv`}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {rpmActivitiesLoading ? 'Loading csv...' : 'Export'}
                </CSVLink>
              )}
            </Button>
          </>
        )}
      </Stack>

      <Stack width="100%" flexDirection="column" justifyContent="stretch" alignItems="stretch">
        <Stack>
          <TruentityDataGrid
            name={'rpm-activities-logs'}
            autoHeight
            getRowHeight={() => 'auto'}
            rows={activityLogs}
            rowCount={rpmActivitiesData?.getRpmActivities?.meta?.totalCount || 0}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            loading={rpmActivitiesLoading}
            paginationMode="server"
            columns={columns}
            disableRowSelectionOnClick
            sx={{ backgroundColor: '#ffffff' }}
          />
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <BarChartSection
            title={'Minutes Spent'}
            chartXValues={chartXValues}
            chartYValues={chartYValues}
            chartY2Values={[0]}
            additionalCss={{ backgroundColor: 'transparent' }}
            width={700}
            height={350}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default ActivityLogs;
