import { Grid, GridProps, StackProps, SvgIconProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { GridItem } from './GridItem';
import GridItemLabel from './GridItemLabel';

type ChildType = {
  childLabel: string;
  childIcon?: ReactNode;
  childValue: ReactNode;
};

type Props = {
  parents: {
    parentLabel: string;
    parentIcon?: ReactNode;
    parentProps?: StackProps & GridProps;
    children: ReactNode | ChildType[];
  }[];
};

const GridNestedItem = ({ parents }: Props) => {
  const smallIconProps: SvgIconProps = { fontSize: 'small' };

  return (
    <Grid item container justifyContent="flex-end" rowSpacing={2}>
      {parents.map(parent => (
        <>
          <GridItemLabel
            label={parent.parentLabel}
            icon={parent.parentIcon}
            component={Grid}
            item
            xs={12}
            sx={{ textTransform: 'capitalize' }}
            {...parent.parentProps}
          />
          {Array.isArray(parent.children) ? (
            parent.children.map((child, index) => (
              <GridItem
                key={index}
                xs={11}
                icon={child.childIcon && React.cloneElement(child.childIcon as React.ReactElement, smallIconProps)}
                label={child.childLabel}
                value={child.childValue}
              />
            ))
          ) : (
            <Grid item xs={11}>
              {parent.children}
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default GridNestedItem;
