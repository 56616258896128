/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { TcmReEditConfirmationDialog, TcmSubmissionDialog } from '@/components/Dialogs';
import { H2, H6, Small } from '@/components/Typography';
import type {
  AccountCareEventResponse,
  TcmSnapshotReportExportResponse,
  UpdateAdtEventMutationResponse
} from '@/graphql/transitionalCareManagement';
import {
  GET_ACCOUNT_CARE_EVENT,
  TCM_SNAPSHOT_REPORT_EXPORT,
  UPDATE_ADT_EVENT,
  UPDATE_TCM_TASK
} from '@/graphql/transitionalCareManagement';
import { AccountCareEventStatuses, TaskStatuses } from '@/routes/TransitionalCareManagement/AdtQueue';
import type { AccountCareEvent, ComplexityLevel, TcmTask } from '@/types/dischargePatient';
import { ComplexityLevels } from '@/types/dischargePatient';
import { formatDateIgnoreTZ, formatDateIgnoreTZAddDays } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Chip, Divider, Grid, IconButton, Paper, Stack, Tab, Tabs } from '@mui/material';
import type { ReactNode, SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import type { ChipDropdownOption } from '@/components/ChipDropdown/ChipDropdown';
import ChipDropdown from '@/components/ChipDropdown/ChipDropdown';
import { TabPanel } from '@/components/MuiTabs';
import styled from '@/styles';
import { color } from '@/styles/assets/colors';
import { getAccountUserFullName } from '@/util/account';
import { tempDueDate } from '@/util/apollo/cache';
import { capitalizeLetterBeforeCharacter } from '@/util/string';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RoomIcon from '@mui/icons-material/Room';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { SummaryDrawer } from './SummaryDrawer';
import { TCMReport } from './TCMReport';
import type { WorkflowFormData } from './TCMWorkflow';
import { TCMWorkflow } from './TCMWorkflow';

export const getNumberOfDaysForComplexity = (complexityLevel: ComplexityLevel) => {
  switch (complexityLevel) {
    case ComplexityLevels.high:
      return 7;

    case ComplexityLevels.medium:
      return 14;
  }

  return 0;
};

const getComplexityLevelColor = (complexityLevel: ComplexityLevel) => {
  switch (complexityLevel) {
    case ComplexityLevels.high:
      return 'error';

    case ComplexityLevels.medium:
      return 'warning';

    case ComplexityLevels.low:
      return 'info';
  }

  return 'default';
};

const COMPLEXITY_DROPDOWN_OPTIONS = Object.entries(ComplexityLevels).map(
  ([key, value]) =>
    ({
      label: key,
      value,
      color: getComplexityLevelColor(value)
    }) as ChipDropdownOption<ComplexityLevel>
);

export const PDFViewer = styled('iframe', {
  width: '100%',
  height: '100%',
  minHeight: '600px'
});

const DischargeSummary = () => {
  const { eventId } = useParams();

  const {
    data: getAccountCareEventData,
    error: getAccountCareEventError,
    refetch: refetchAccountCareEvent
  } = useQuery<AccountCareEventResponse>(GET_ACCOUNT_CARE_EVENT, {
    variables: {
      accountCareEventId: eventId
    }
  });

  const [createPdfTcmReport, { data: pdfReportData }] = useMutation<TcmSnapshotReportExportResponse>(TCM_SNAPSHOT_REPORT_EXPORT);
  const [updateTcmTask] = useMutation<Partial<UpdateAdtEventMutationResponse>>(UPDATE_TCM_TASK);
  const [updateAdtEvent] = useMutation<UpdateAdtEventMutationResponse>(UPDATE_ADT_EVENT);
  const { showModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();
  const [accountCareEvent, setAccountCareEvent] = useState<Partial<AccountCareEvent>>();
  const [tcmTask, setTcmTask] = useState<Partial<TcmTask>>();
  const [dueDate, setDueDate] = useState('');
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  const [selectedComplexityLevel, setSelectedComplexityLevel] = useState<ChipDropdownOption<ComplexityLevel> | undefined>();
  const [pdfUrl, setPdfUrl] = useState('');
  const [assignedTo, setAssignedTo] = useState<string>(' ');
  const [readOnly, setReadOnly] = useState(true);

  const calculateDueDate = (complexityLevel: ComplexityLevel, dischargeDate: string) => {
    const days = getNumberOfDaysForComplexity(complexityLevel);
    return formatDateIgnoreTZAddDays(dischargeDate, days);
  };

  const [pdfRefreshRequestDate, setPdfRefreshRequestDate] = useState<Date>(new Date());
  const [selectedTab, setSelectedTab] = useState(0);

  const refetch = useCallback(() => {
    refetchAccountCareEvent().catch(console.error);
  }, [refetchAccountCareEvent]);

  const handleTabChange = useCallback((event: SyntheticEvent, selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
  }, []);

  const createReport = useCallback(() => {
    createPdfTcmReport({
      variables: {
        accountCareId: eventId
      }
    }).catch(err => {
      console.error(err);
      enqueueSnackbar('Unable to create pdf report', { variant: 'error' });
    });
  }, [createPdfTcmReport, enqueueSnackbar, eventId]);

  const showTCMSubmissionDialog = useCallback(() => {
    const modal = showModal(TcmSubmissionDialog, {
      title: '',
      hideDialog: () => {
        modal.hide();
        setSelectedTab(1);
      }
    });
  }, [showModal]);

  const saveForm = useCallback(
    (formData: WorkflowFormData) => {
      Promise.all([
        updateTcmTask({
          variables: {
            tcmTask: {
              id: tcmTask?.id,
              initialCommsDueDate: formData?.initialCommsDueDate,
              complexityLevel: formData?.complexityLevel,
              finalDueDate: formData?.finalDueDate,
              billingHoldUntilDate: formData?.billingHoldUntilDate,
              reviewDischargeInfoType: formData?.reviewDischargeInfoType,
              reviewDischargeInfoDischargingPhysician: formData?.reviewDischargeInfoDischargingPhysician,
              reviewDischargeInfoDiagnosisCode: formData?.reviewDischargeInfoDiagnosisCode,
              reviewDischargeInfoReviewed: formData?.reviewDischargeInfoReviewed,
              reviewDischargeInfoSummaryDocUrl: formData?.reviewDischargeInfoSummaryDocUrl,
              reviewDischargeInfoNotes: formData?.reviewDischargeInfoNotes,
              reviewDischargeInfoFrom: formData?.reviewDischargeInfoFrom,
              reviewDischargeSummaryDocReviewed: formData?.reviewDischargeSummaryDocReviewed,
              initialCommsDischargeToType: formData?.initialCommsDischargeToType,
              initialCommsCaregiverName: formData?.initialCommsCaregiverName,
              initialCommsCaregiverRelationship: formData?.initialCommsCaregiverRelationship,
              initialCommsDischargeTransportationType: formData?.initialCommsDischargeTransportationType,
              docReferralDiscussions: formData?.docReferralDiscussions,
              docCaregiverDiscussions: formData?.docCaregiverDiscussions,
              docSelfCareActivities: formData?.docSelfCareActivities,
              docTreatmentAdherenceInstructions: formData?.docTreatmentAdherenceInstructions,
              finalF2fLocation: formData?.finalF2fLocation,
              finalF2fProvider: formData?.finalF2fProvider,
              finalF2fNotes: formData?.finalF2fNotes,
              finalF2fDate: formData?.finalF2fDate,
              finalMedsReviewed: formData?.finalMedsReviewed,
              finalTcmSignedAndCertified: formData?.finalTcmSignedAndCertified,
              medrecSnapshotId: formData?.medrecSnapshot?.id,
              taskStatus: capitalizeLetterBeforeCharacter(formData?.taskStatus)
            }
          }
        }),

        updateAdtEvent({
          variables: {
            accountCareEventDataInput: {
              id: accountCareEvent?.id,
              status: formData?.eventStatus,
              dischargeDate: formData?.dischargeDate,
              dischargeLocation: formData?.dischargeLocation,
              admissionDate: formData?.admissionDate,
              admissionReason: formData?.admissionReason
            }
          }
        })
      ])
        .then(([eventResponse, eventResponse1]) => {
          // since we are already creatingPDFReport
          // whenever data is refetched | changed
          // we dont need this here
          // createReport();
          refetch();

          if (eventResponse1.data?.updateAdtEvent?.accountCareEvent?.eventStatus === AccountCareEventStatuses.Processed) {
            showTCMSubmissionDialog();
          }

          enqueueSnackbar('Save Successful', { variant: 'success' });
        })
        .catch(err => {
          console.error(err);
          enqueueSnackbar('Failed to Save', {
            variant: 'error'
          });
        });
    },
    [accountCareEvent?.id, enqueueSnackbar, refetch, showTCMSubmissionDialog, tcmTask?.id, updateAdtEvent, updateTcmTask]
  );

  const tabs: { a11yLabel: string; label: string; children: ReactNode }[] = [
    {
      a11yLabel: 'TCM Workflow',
      label: 'TCM Workflow',
      children: (
        <TCMWorkflow
          refetch={refetch}
          data={accountCareEvent}
          tcmTask={tcmTask}
          assignedTo={assignedTo}
          readOnly={readOnly}
          save={saveForm}
        />
      )
    },
    {
      a11yLabel: 'TCM Report',
      label: 'TCM Report',
      children: <TCMReport pdfUrl={pdfUrl} refresh={() => setPdfRefreshRequestDate(new Date())} lastUpdateRequest={pdfRefreshRequestDate} />
    }
  ];

  const showReEditConfirmationDialog = () => {
    const modal = showModal(TcmReEditConfirmationDialog, {
      title: '',
      hideDialog: () => {
        modal.hide();
      },
      onReEditConfirmed: () => {
        modal.hide();

        if (tcmTask && accountCareEvent) {
          Promise.all([
            updateTcmTask({
              variables: {
                tcmTask: {
                  id: tcmTask.id,
                  status: TaskStatuses.Pending,
                  taskStatus: capitalizeLetterBeforeCharacter(TaskStatuses.Pending)
                }
              }
            }),
            updateAdtEvent({
              variables: {
                accountCareEventDataInput: {
                  id: accountCareEvent.id,
                  status: AccountCareEventStatuses.InProgress
                }
              }
            }).then(() => setReadOnly(false))
          ]).catch(err => {
            console.error(err);
            enqueueSnackbar('Failed to open Event', {
              variant: 'error'
            });
          });
        }
      }
    });
  };

  const updateComplexityLevel = useCallback(() => {
    if (selectedComplexityLevel && tcmTask?.id && selectedComplexityLevel.value !== tcmTask?.complexityLevel) {
      updateTcmTask({
        variables: {
          tcmTask: {
            id: tcmTask.id,
            complexityLevel: selectedComplexityLevel.value,
            taskStatus: `Changed Complexity - ${selectedComplexityLevel.value?.toUpperCase()}`
          }
        }
      }).catch(err => {
        console.error(err);
        enqueueSnackbar('Failed to change complexity', {
          variant: 'error'
        });
      });
      enqueueSnackbar('Complexity updated successfully', {
        variant: 'success'
      });
    }
  }, [enqueueSnackbar, selectedComplexityLevel, tcmTask?.complexityLevel, tcmTask?.id, updateTcmTask]);

  useEffect(() => {
    if ((getAccountCareEventData?.accountCareEvent?.tcmTasks?.length ?? 0) > 0) {
      setAccountCareEvent(getAccountCareEventData?.accountCareEvent);
    }
  }, [getAccountCareEventData?.accountCareEvent]);

  useEffect(() => {
    if (getAccountCareEventError) {
      enqueueSnackbar('Unable to load account care event', { variant: 'error' });
    }
  }, [enqueueSnackbar, getAccountCareEventError]);

  useEffect(() => {
    if (accountCareEvent?.tcmTasks && accountCareEvent?.tcmTasks?.length > 0) {
      setTcmTask(accountCareEvent?.tcmTasks[0]);
      createReport();
    }
  }, [accountCareEvent?.tcmTasks, enqueueSnackbar, createReport]);

  useEffect(() => {
    if (accountCareEvent?.relyingPartyAdmin?.user) {
      setAssignedTo(getAccountUserFullName(accountCareEvent?.relyingPartyAdmin?.user));
      setReadOnly(accountCareEvent?.eventStatus === AccountCareEventStatuses.Processed);
    }
  }, [accountCareEvent?.relyingPartyAdmin?.user, accountCareEvent?.eventStatus]);

  useEffect(() => {
    if (tcmTask?.complexityLevel) {
      setSelectedComplexityLevel(COMPLEXITY_DROPDOWN_OPTIONS?.find(v => v.value === tcmTask?.complexityLevel));

      if (accountCareEvent?.dischargeDate) {
        const val = calculateDueDate(tcmTask?.complexityLevel, accountCareEvent?.dischargeDate.toString());
        setDueDate(val);
        tempDueDate(val);
      }
    }
  }, [accountCareEvent?.dischargeDate, tcmTask?.complexityLevel]);

  useEffect(() => {
    updateComplexityLevel();
  }, [updateComplexityLevel, selectedComplexityLevel]);

  useEffect(() => {
    if (pdfReportData && pdfReportData.createPdfTcmReport) {
      const { pdfDownloadUrl } = pdfReportData.createPdfTcmReport;
      if (pdfDownloadUrl && pdfDownloadUrl.length > 0) {
        fetch(`${pdfDownloadUrl}`)
          .then(response => response.blob())
          .then(blob => {
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            setPdfUrl(url);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, [pdfReportData]);

  return (
    <Stack spacing={1}>
      {(getAccountCareEventError ||
        !getAccountCareEventData?.accountCareEvent ||
        getAccountCareEventData?.accountCareEvent.tcmTasks?.length === 0) && (
        <Alert title="Failed to load Event" description="We have not been able to load this event, please contact support" status="error" />
      )}
      <SummaryDrawer data={accountCareEvent} tcmTask={tcmTask} drawerOpen={detailsDrawerOpen} setDrawerOpen={setDetailsDrawerOpen} />
      <Paper sx={{ paddingX: '15px' }} component={Stack} spacing={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid xs={2} container item>
            <H2>Discharge Summary</H2>
          </Grid>
          <Grid container item spacing={1} alignItems="center" justifyContent={'flex-start'} xs={4}>
            <Stack component={Grid} item alignItems="center" justifyContent="center">
              <H6>Discharge Date</H6>
              <Chip size="small" variant="outlined" color="primary" label={formatDateIgnoreTZ(accountCareEvent?.dischargeDate)} />
            </Stack>

            <Stack component={Grid} item alignItems="center" justifyContent="center">
              <H6>Due Date</H6>
              <Chip size="small" variant="outlined" color="error" label={dueDate} />
            </Stack>
          </Grid>
          <Grid container item alignItems="center" xs={6}>
            <Stack direction="row" sx={{ flex: 1 }} justifyContent={'flex-end'} alignItems={'center'} spacing={3}>
              <Stack>
                {selectedComplexityLevel && (
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <H6>Complexity</H6>
                    <ChipDropdown<ComplexityLevel>
                      ChipProps={{ size: 'small' }}
                      options={COMPLEXITY_DROPDOWN_OPTIONS}
                      setSelectedOption={setSelectedComplexityLevel}
                      selectedOption={selectedComplexityLevel}
                      disabled={readOnly}
                    />
                  </Stack>
                )}
                <Stack
                  spacing={0.5}
                  size="small"
                  component={IconButton}
                  onClick={() => {
                    window.open(
                      'https://www.cms.gov/Outreach-and-Education/Medicare-Learning-Network-MLN/MLNProducts/Downloads/eval-mgmt-serv-guide-ICN006764.pdf',
                      '_blank'
                    );
                  }}
                  sx={{ backgroundColor: color.grey200, borderRadius: '10px' }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <InfoIcon />
                  <Small>Medical Decision Making</Small>
                </Stack>
              </Stack>

              <Button
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                label="Details"
                variant="contained"
                onClick={() => setDetailsDrawerOpen(!detailsDrawerOpen)}
              />
            </Stack>
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <RoomIcon fontSize={'small'} color="primary" />
            <Small fontWeight="bold">Discharged From:</Small>
            <Small>{accountCareEvent?.dischargeLocation}</Small>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={0.5}>
            <DescriptionIcon fontSize={'small'} color="primary" />
            <Small fontWeight="bold">Admit Reason:</Small>
            <Small>{accountCareEvent?.admissionReason}</Small>
          </Stack>
        </Stack>

        <Stack>
          <Divider />
        </Stack>

        {accountCareEvent?.eventStatus === AccountCareEventStatuses.Processed && (
          <Alert
            status="info"
            description="This event has been processed and is locked"
            action={
              <Button
                startIcon={<LockOpenIcon />}
                label="Unlock"
                color="info"
                variant="contained"
                onClick={() => showReEditConfirmationDialog()}
              />
            }
          />
        )}

        <Stack
          spacing={2}
          sx={{
            borderBottom: 1,
            borderRight: 0,
            backgroundColor: '#fff',
            borderColor: 'divider',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        >
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ width: '250px' }}>
            {tabs.map((tab, index) => (
              <Tab label={tab.label} key={`tab-${index}`} id={`tab-${index}`} sx={{ textTransform: 'capitalize' }} />
            ))}
          </Tabs>

          <Box sx={{ flex: 1 }}>
            {
              <Box sx={{ p: 3 }}>
                {tabs.map((tab, index) => (
                  <TabPanel value={selectedTab} index={index} key={`tab-content-${index}`}>
                    {tab.children}
                  </TabPanel>
                ))}
              </Box>
            }
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default DischargeSummary;
