import { Body2, H5 } from '@/components/Typography';
import type { GetGoalTemplatesResponse } from '@/graphql/remotePatientMonitoring';
import { GET_GOAL_TEMPLATES } from '@/graphql/remotePatientMonitoring';
import type { ParsedTemplateResult, SmartGoalTemplateType } from '@/types/remotePatientMonitoring';
import { getConditionStrings, getDiagnosisUsingConditions } from '@/util/rpm';
import { formatFromSnakeCase } from '@/util/string';
import { useQuery } from '@apollo/client';
import type { AutocompleteRenderGroupParams } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  refactoredTemplateGoal: ParsedTemplateResult[];
  setRefactoredTemplateGoal: Dispatch<SetStateAction<ParsedTemplateResult[]>>;
  reasonForVisit?: string[];
};

const TemplateSelector = ({ refactoredTemplateGoal, setRefactoredTemplateGoal, reasonForVisit = [] }: Props) => {
  const [templates, setTemplates] = useState<SmartGoalTemplateType[]>([]);

  const { data: accountSmartGoalTemplates } = useQuery<GetGoalTemplatesResponse>(GET_GOAL_TEMPLATES, {
    variables: {
      categories: getDiagnosisUsingConditions(getConditionStrings(reasonForVisit))
    }
  });

  const parseTemplate = (template: SmartGoalTemplateType): ParsedTemplateResult => {
    const templateParts = template.templateText.split(/(<<[^>]+>>)/g);
    const inputs: { key: string; value: string }[] = [];

    templateParts.forEach(part => {
      const match = part.match(/<<(.+?)>>/);
      if (match) {
        const placeholder = match[1];
        inputs.push({ key: placeholder, value: '' });
      }
    });

    return {
      templateId: template.id,
      template: template,
      inputs: inputs,
      isCompleted: false
    };
  };

  const handleAutoComplete = (newValue: SmartGoalTemplateType | null) => {
    if (!newValue) return;

    const isAlreadyExists = refactoredTemplateGoal.find(templateGoal => templateGoal.templateId === newValue.id);
    if (isAlreadyExists) return;

    const parsedTemplate = parseTemplate(newValue);
    setRefactoredTemplateGoal([...refactoredTemplateGoal, parsedTemplate]);
  };

  useEffect(() => {
    if (accountSmartGoalTemplates?.getAllTemplates) {
      if (!reasonForVisit || reasonForVisit.length === 0) return setTemplates([]);

      const flatTemplates = accountSmartGoalTemplates.getAllTemplates.flatMap(category =>
        category.subCategory.flatMap(subCat => subCat.templates)
      );

      setTemplates(flatTemplates);
    }
  }, [accountSmartGoalTemplates, reasonForVisit]);

  return (
    <Autocomplete
      options={templates}
      getOptionLabel={option => option.templateText}
      onChange={(_event, newValue) => handleAutoComplete(newValue)}
      renderInput={params => <TextField {...params} value="" margin="dense" label="Select a Goal Template" variant="outlined" fullWidth />}
      groupBy={option => `${formatFromSnakeCase(option.category)} - ${formatFromSnakeCase(option.subCategory)}`}
      renderGroup={(params: AutocompleteRenderGroupParams) => [
        <li key={params.key}>
          <H5 color="text.secondary" style={{ margin: 8 }}>
            {params.group}
          </H5>
          {params.children}
        </li>
      ]}
      renderOption={(props, option) => (
        <li {...props}>
          <Body2 variant="body2">{option.templateText}</Body2>
        </li>
      )}
      noOptionsText={
        !reasonForVisit || reasonForVisit.length === 0
          ? 'Select a "Reason for your visit" to proceed.'
          : 'No templates available. For assistance, please contact support.'
      }
      fullWidth
    />
  );
};

export default TemplateSelector;
