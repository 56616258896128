import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  content: string;
  onDisagree: () => void;
};

const MedConsultDetailsDialog = ({ title, onDisagree, content, ...props }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyButton = async () => {
    let timeout;
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);

      timeout = setTimeout(() => setIsCopied(false), 5000);
    } catch (error) {
      console.error('Failed to copy text:', error);
      setIsCopied(false);
      clearTimeout(timeout);
    }
  };

  return (
    <BaseDialog {...props} title={title} onClose={onDisagree} hideDialog={onDisagree} fullWidth maxWidth="sm">
      <DialogContent>
        <TextField minRows={3} value={content} fullWidth multiline rows={10} />
        <DialogActions sx={{ pt: 3, px: 0 }}>
          {content.trim().length > 0 ? (
            <>
              <Button
                variant="outlined"
                onClick={handleCopyButton}
                startIcon={isCopied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
                color={isCopied ? 'success' : 'info'}
              >
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </>
          ) : (
            <></>
          )}
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default MedConsultDetailsDialog;
