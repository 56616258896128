import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMemo } from 'react';
import MedicalReconciliation from './MedicalReconciliation';

const MedReconciliation = () => {
  const { activeRpmSetupTab } = useRpmSetupStore();
  console.log(activeRpmSetupTab);

  const isReadOnly = useMemo(() => activeRpmSetupTab?.isReadOnly || false, [activeRpmSetupTab]);
  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <MedicalReconciliation />
    </BaseRpmWorkflowTabContent>
  );
};

export default MedReconciliation;
