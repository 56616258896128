import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  message: string;
  hideDialog: () => void;
};

export default function GlobalLookupUnavailableDialog({ title, message, hideDialog, ...props }: Props) {
  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <DialogContentText sx={{ paddingY: '20px' }}>{message}</DialogContentText>
      </DialogContent>
    </BaseDialog>
  );
}
