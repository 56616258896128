import { color } from './assets/colors';

export const theme = {
  themeDarker: color.truentityBlue.A400,
  themeDark: color.truentityBlue.A200,
  themeDefault: color.truentityBlue.A100,
  themeLight: color.truentityBlue.A100
};

export const themeAlphas = {
  themeA48: '#246cff7a',
  themeA32: '#246cff52',
  themeA4: '#246cff0a'
};

export const netural = {
  neutralLight: color.grey200,
  neutralLighter: color.grey100,
  neutralLightest: color.grey50
};

export const neutralAlphas = {
  neutralA48: '#9e9e9e7a',
  neutralA32: '#9e9e9e52',
  neutralA24: '#9e9e9e3d',
  neutralA12: '#9e9e9e1f',
  neutralA8: '#9e9e9e14',
  neutralA4: '#9e9e9e0a',
  neutralA2: '#9e9e9e05'
};

export default {
  ...theme,
  ...themeAlphas,
  ...netural,
  ...neutralAlphas
};
