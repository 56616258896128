import ChipsList from '@/components/ChipsList';
import { Body1, H3 } from '@/components/Typography';
import { Stack } from '@mui/material';
import type { ReactNode } from 'react';

type StepperPageProps = {
  title: string;
  assignedTo: string[];
  children: ReactNode;
};
export const StepperPage = ({ title, assignedTo, children }: StepperPageProps) => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
        <H3>{title}</H3>
        <Stack direction="row" spacing={1} alignItems={'center'}>
          <Body1>Assigned To: </Body1>
          <ChipsList useAvatar={true} items={assignedTo} />
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};
