import Button from '@/components/Button';
import LoadingOverlay from '@/components/LoadingOverlay';
import PDFViewer from '@/components/PDFViewer';
import {
  ClickThroughAgreementResponse,
  CTAUserDetailsInput,
  GetRPAgreementResponse,
  GET_RELYING_PARTY_AGREEMENT,
  RESPOND_CLICK_THROUGH_AGREEMENT
} from '@/graphql/administration';
import useToken from '@/hooks/useToken';
import { AgreementActionEnum } from '@/types/administration';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getClientIpAddress } from '@/util/environment';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

const ClickThroughAgreementDialog = ({ ...props }: BaseDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleLogout } = useToken();
  const navigate = useNavigate();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [pdfUrl, setPdfUrl] = useState('');

  const [getAgreement, { data: agreementData, loading: agreementLoading, error: agreementError }] =
    useLazyQuery<GetRPAgreementResponse>(GET_RELYING_PARTY_AGREEMENT);
  const [respondAgreement, { loading: respondAgreementLoading }] =
    useMutation<ClickThroughAgreementResponse>(RESPOND_CLICK_THROUGH_AGREEMENT);
  const [agreementRead, setAgreementRead] = useState<boolean>(false);

  const handleRespondToAgreement = async (action: AgreementActionEnum) => {
    try {
      const ipAddress = await getClientIpAddress();
      await respondAgreement({
        variables: {
          userDetails: {
            ipAddress: ipAddress
          } as CTAUserDetailsInput,
          action
        }
      });
      if (action === AgreementActionEnum.REJECT) {
        enqueueSnackbar('You have rejected the agreement. You have been automatically logged out.', { variant: 'warning' });
        handleLogout();
      } else if (action === AgreementActionEnum.ACCEPT) {
        enqueueSnackbar('You have accepted the agreement.', { variant: 'success' });
        if (currentUser?.relyingParty?.isCtaAccepted === true) {
          navigate('/', { replace: true });
        } else {
          if (currentUser === null || currentUser === undefined) {
            throw new Error('User is not logged in properly');
          } else {
            currentLoggedUserVar({
              ...currentUser,
              relyingParty: {
                ...currentUser.relyingParty,
                isCtaAccepted: true
              }
            });
          }
        }
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(err?.message ?? 'Could not save your response to agreement', { variant: 'error' });
      handleLogout();
    }
  };

  useEffect(() => {
    if (agreementError) {
      enqueueSnackbar(agreementError.message ?? 'Could not fetch agreement', { variant: 'error' });
    }
  }, [agreementError]);

  useEffect(() => {
    if (agreementData?.relyingPartyAgreement?.status === 'Success') {
      fetch(`${agreementData?.relyingPartyAgreement?.agreementUrl}`)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
          setPdfUrl(url);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [agreementData]);

  useEffect(() => {
    getAgreement();
  }, []);

  return (
    <BaseDialog maxWidth={'md'} {...props}>
      <DialogContent
        sx={{
          m: 0,
          p: 0,
          height: 'calc(100vh - 200px)',
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'hidden'
          },
          minWidth: theme => theme.breakpoints.values.md
        }}
        onScroll={e => {
          const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
          const bottomMargin = 0.2 * scrollHeight;
          if (scrollTop + clientHeight + bottomMargin >= scrollHeight) {
            setAgreementRead(true);
          }
        }}
      >
        <LoadingOverlay text={'Loading agreement...'} active={agreementLoading} />
        <PDFViewer
          style={{
            width: '100%',
            height: '100vh',
            maxHeight: '100vh'
          }}
          src={pdfUrl}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            mx: 1
          }}
          type="button"
          variant={'outlined'}
          disabled={respondAgreementLoading}
          onClick={async () => {
            await handleRespondToAgreement(AgreementActionEnum.REJECT);
          }}
        >
          Cancel
        </Button>
        <Button
          sx={{
            mx: 1
          }}
          type="button"
          isLoading={respondAgreementLoading}
          disabled={!agreementRead}
          onClick={async () => {
            await handleRespondToAgreement(AgreementActionEnum.ACCEPT);
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default ClickThroughAgreementDialog;
