import Button from '@/components/Button';
import { ForgotPasswordDialog } from '@/components/Dialogs';
import TruentityLogo from '@/components/TruentityLogo';
import TruentityTextField from '@/components/TruentityTextField';
import { Body1, H4 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { gql, useMutation } from '@apollo/client';
import { Box, Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const FORGOT_PASSWORD = gql`
  mutation sendPortalPasswordInstructions($email: Email!) {
    sendPortalPasswordInstructions(email: $email) {
      message
    }
  }
`;
type FormValues = { email: string };

const defaultValues: FormValues = {
  email: ''
};

const ForgotPassword = () => {
  const navigate = useNavigate();

  const [sendPortalPasswordInstructions, { loading: isLoading }] = useMutation(FORGOT_PASSWORD);

  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const { showModal } = useModal();

  const handleSubmitImpl = async (values: FormValues) => {
    if (isLoading) return;
    const email = values.email;
    if (email === '') return;

    try {
      const {
        data: { sendPortalPasswordInstructions: message },
        errors
      } = await sendPortalPasswordInstructions({ variables: { email } });

      if (errors && Array.isArray(errors)) {
        errors.map(error => enqueueSnackbar(error.message, { variant: 'error' }));
      }

      if (message) {
        showPasswordModal();
      }
    } catch (error: any) {
      let errorMessage = error.graphQLErrors[0]?.message || 'Unable to complete the operation';

      if (error.graphQLErrors && error.graphQLErrors[0].extensions.code === 'INVALID_EMAIL') {
        errorMessage = 'Invalid Email Address';
      }

      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  };

  const showPasswordModal = () => {
    const modalRef = showModal(ForgotPasswordDialog, {
      title: 'Success',
      hideDialog: () => {
        modalRef.hide();
        navigate('/login');
      }
    });
  };

  return (
    <Stack alignItems={'center'} sx={{ width: '100%', height: '100%', backgroundColor: color.truentityCyan[500] }}>
      <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', marginTop: '10vh' }}>
        <Box sx={{ paddingBottom: '24px', borderBottom: '2px solid $truentityLigthGray', marginBottom: '19px' }}>
          <TruentityLogo />
        </Box>

        <H4 align="center" color={color.truentityCyan[600]}>
          Forgot Password?
        </H4>
        <Body1>Enter the email address connected to your Truentity Health account and a link will be sent to reset your password.</Body1>

        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <TruentityTextField autoFocus required fullWidth label="Email" type="email" {...register('email')} />
          <Button type="submit" a11yLabel="Reset Password" color={'secondary'} isLoading={isLoading} fullWidth />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ForgotPassword;
