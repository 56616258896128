import { AccountList } from '@/graphql/account-lists';
import create, { SetState } from 'zustand';

type OrganizationStarredStoreTypes = {
  starredList: AccountList | null;
  setStarredList: (value: AccountList | null) => void;
};

export const useOrganizationStarredStore = create<OrganizationStarredStoreTypes>((set: SetState<OrganizationStarredStoreTypes>) => ({
  starredList: null,
  setStarredList: (value: AccountList | null) => set(() => ({ starredList: value }))
}));
