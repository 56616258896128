import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Icon, IconButtonProps } from '@mui/material';

type ShareWithProviderIconProps = IconButtonProps & {
  pinned: boolean;
  color: any;
};
const ShareWithProviderIcon = ({ pinned, color, ...props }: ShareWithProviderIconProps) => {
  return <Icon {...props}>{pinned && <HowToRegIcon sx={{ color }} />}</Icon>;
};

export default ShareWithProviderIcon;
