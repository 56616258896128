import { Chip } from '@mui/material';

type Status = 'success' | 'info' | 'attention' | 'critical' | 'warning' | 'new' | 'danger';
type Size = 'small' | 'medium';

export interface BadgeProps {
  status: Status;
  size?: Size;
}

const DEFAULT_SIZE = 'medium';

const ChipColorMap = {
  neutral: 'info',
  info: 'info',
  new: 'info',
  approved: 'success',
  success: 'success',
  pending: 'warning',
  attention: 'warning',
  denied: 'error',
  critical: 'error',
  danger: 'error'
};

const ChipBadge = ({ status, size = DEFAULT_SIZE }: BadgeProps) => {
  const chipColor = ChipColorMap[status] || 'info';

  return <Chip label={status} sx={{ minWidth: '100px' }} color={chipColor} size={size} />;
};

export default ChipBadge;
