import Button from '@/components/Button';
import { UploadSuccessfulDialog } from '@/components/Dialogs';
import ImageList from '@/components/ImageList';
import TruentityLogo from '@/components/TruentityLogo';
import TruentityTextField from '@/components/TruentityTextField';
import FlexContainer from '@/elements/FlexContainer';
import { bytesToMegabytes, parseBase64String, readFileAsDataUrl } from '@/util/file';
import ls, { QUICK_UPLOAD_AUTH_KEY } from '@/util/localstorage';
import { useMutation } from '@apollo/client';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import { Buffer } from 'buffer';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CHECK_SESSION, END_SESSION_URL, QUICKUPLOADS_ADD, QUICKUPLOAD_PRESIGN_URL } from './mutations';
import { Form, HiddenFileInput, ImageContainer, ProgressBarContainer } from './styles';

const config = {
  //TODO:  check file size limitations for aws
  DEFAULT_MAX_FILE_SIZE_IN_BYTES: 500000000,
  //TODO: cehck to see if this should be higher or pulled from server
  MAX_UPLOADS: 999
};

const QuickUploadMedications = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [endQuickUploadSession] = useMutation(END_SESSION_URL);
  const [getQuickUploadPresignedUrl] = useMutation(QUICKUPLOAD_PRESIGN_URL);
  const [addQuickUploads] = useMutation(QUICKUPLOADS_ADD);
  const [checkQuickUploadSession] = useMutation(CHECK_SESSION);

  const fileInputField = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState({});
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [medicationName, setMedicationName] = useState('');
  const [medicationCount, setMedicationCount] = useState(1);
  const [keys, setKeys] = useState<string[]>([]);
  const [filesArr, setFilesArr] = useState<File[]>([]);
  const [error, setError] = useState<Error>();
  const [isUploading, setIsUploading] = useState(false);

  const canUpload = () => Object.keys(files).length > config.MAX_UPLOADS - 1;
  const disableUploadDoneButton = () => Object.keys(files).length === 0 || isUploading;

  const { showModal } = useModal();

  const getAuthCode = () => {
    return ls.get(QUICK_UPLOAD_AUTH_KEY);
  };

  const goBack = () => {
    navigate(`/quick-upload/${sessionId}`, {
      replace: true
    });
  };

  const goThanks = () => {
    navigate(`/quick-upload/thanks`, {
      replace: true
    });
  };

  const validateSession = async (sessionId: string) => {
    try {
      const result = await checkQuickUploadSession({
        variables: {
          sessionId
        }
      });

      const { status } = result.data!.checkQuickUploadSession;

      if (status !== 'Success') {
        throw new Error('Invalid Session');
      }
    } catch (err) {
      goBack();
    }
  };

  useEffect(() => {
    validateSession(sessionId);
  }, []);

  //TODO:  check with rajeev how are we logging / handling errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar('Something has gone wrong', { variant: 'error' });
      console.error(error);
    }
  }, [error]);

  //Set default medication name based on counter of how many has been uploaded so far in this session
  useEffect(() => {
    setMedicationName('Medication Name #' + medicationCount);
  }, [medicationCount]);

  // show progress bar when we are uploading
  useEffect(() => {
    setUploadInProgress(keys.length > 0);
  }, [keys]);

  useEffect(() => {
    if (filesArr.length > 0) {
      setKey(filesArr[0]).catch(err => console.error(err));
    } else {
      if (keys.length > 0) {
        addUploadedItems({
          name: medicationName,
          keys
        }).then(() => {
          setKeys([]);
          showUploadModal();
        });
      }
    }
  }, [filesArr]);

  const handleCancel = event => {
    event.preventDefault();
    onCancel();
  };

  const onCancel = () => {
    endSession().then(() => {
      setIsUploading(false);
      goThanks();
    });
  };

  const showUploadModal = () => {
    const modalRef = showModal(UploadSuccessfulDialog, {
      title: 'Upload Successful',
      onConfirm: () => {
        modalRef.hide();
        setMedicationCount(medicationCount + 1);
        setKeys([]);
        setFiles({});
        setIsUploading(false);
      },
      onCancel: () => {
        modalRef.hide();
        onCancel();
      }
    });
  };

  //Mutation functions

  const endSession = async () => {
    try {
      await endQuickUploadSession({ variables: { authCode: getAuthCode() } });
      ls.remove(QUICK_UPLOAD_AUTH_KEY);
    } catch (err) {
      setError(err as Error);
    }
  };

  const presignedUpload = async (base64String: string) => {
    try {
      const result = await getQuickUploadPresignedUrl({
        variables: {
          authCode: getAuthCode()
        }
      });

      const { key, url } = result.data!.getQuickUploadPresignedUrl;
      const info = parseBase64String(base64String);

      await fetch(url, {
        method: 'PUT',
        body: Buffer.from(info.content, 'base64'),
        headers: {
          'Content-Type': `${info.contentType}`,
          'Content-Encoding': 'base64'
        }
      });

      return key;
    } catch (err) {
      setError(err as Error);
    }
  };

  const addUploadedItems = async payload => {
    try {
      const result = await addQuickUploads({
        variables: {
          authCode: getAuthCode(),
          ...payload
        }
      });

      const { status } = result.data!.addQuickUploads;

      if (status === 'Success') {
        setSuccess(true);
      }
    } catch (err) {
      setError(err as Error);
    }
  };

  const setKey = async (nextFile: File) => {
    try {
      const base64String = await readFileAsDataUrl(nextFile);
      const receivedKey = await presignedUpload(base64String);
      setKeys([...keys, receivedKey]);
      setFilesArr(filesArr.slice(1));
    } catch (err) {
      setError(err as Error);
    }
  };

  const handleOnSubmit = async event => {
    event.preventDefault();
    setFilesArr(Object.values(files));
    setIsUploading(true);
  };

  const removeFile = fileName => {
    delete files[fileName];
    setFiles({ ...files });
  };

  const handleNewFileUpload = e => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
    }
  };

  const addNewFiles = newFiles => {
    for (const file of newFiles) {
      if (file.size <= config.DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        if (config.MAX_UPLOADS <= 1) {
          return { file };
        }
        files[file.name] = file;
      } else {
        //TODO: show error in a nicer way, currently setError is only logging to console
        setError(
          new Error(`The file size exceeds the maximum allowed limit of ${bytesToMegabytes(config.DEFAULT_MAX_FILE_SIZE_IN_BYTES)}`)
        );
      }
    }
    return { ...files };
  };

  const handleOnFocusIn = e => {
    if (e.target.value === 'Medication Name #' + medicationCount) {
      setMedicationName('');
    }
  };

  const handleOnFocusOut = e => {
    if (e.target.value.length === 0) {
      setMedicationName('Medication Name #' + medicationCount);
    }
  };

  return (
    <FlexContainer color="darkCyan">
      <FlexContainer
        color="white"
        justify="end"
        style={{
          width: '90%',
          height: '90%',
          padding: '10px',
          borderRadius: '10px',
          maxWidth: '700px'
        }}
      >
        <ImageContainer>
          <TruentityLogo />
        </ImageContainer>

        {uploadInProgress && (
          <ProgressBarContainer style={{ flex: 1 }}>
            <p>Loading</p>
            <progress></progress>
          </ProgressBarContainer>
        )}

        <Form
          onSubmit={handleOnSubmit}
          style={{
            flex: 10,
            width: '100%',
            marginTop: '20px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <TruentityTextField
            value={medicationName}
            onChange={e => setMedicationName(e.target.value)}
            onFocus={e => handleOnFocusIn(e)}
            onBlur={e => handleOnFocusOut(e)}
          />
          <ImageList files={files} onRemoveFileCb={name => removeFile(name)} />
          <Stack spacing={2} direction="row">
            <HiddenFileInput id="file-upload" type="file" accept="image/*" ref={fileInputField} value="" onChange={handleNewFileUpload} />
            <Button
              type="button"
              iconRight={faUpload}
              a11yLabel="Upload"
              variant="outlined"
              onClick={() => fileInputField.current!.click()}
              disabled={canUpload()}
            />
            <span style={{ flex: 2 }}></span>
            <Button type="reset" a11yLabel="Cancel" variant={'text'} onClick={handleCancel} />
            <Button type="submit" a11yLabel="Done" appearance="primary" disabled={disableUploadDoneButton()} />
          </Stack>
        </Form>
      </FlexContainer>
    </FlexContainer>
  );
};

export default QuickUploadMedications;
