import Button from '@/components/Button';
import TruentityLogo from '@/components/TruentityLogo';
import { Body1, H4, H6 } from '@/components/Typography';
import Config from '@/config';
import { LOGGED_USER, TWOFACTOR_LOGIN_ADMIN } from '@/graphql/account';
import useSettings from '@/hooks/useSettings';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import type { LoggedUserAccountData } from '@/types/graphql';
import { currentLoggedRelyingParty, currentLoggedUserVar, loggedUserData } from '@/util/apollo/cache';
import { handleMutationFormErrorCb } from '@/util/error';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Box, Paper, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import qs from 'query-string';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthErrorCodes } from '@/types/error';

type FormValues = { otpCode: string };

const defaultValues: FormValues = {
  otpCode: ''
};

const TwoFactorLogin = () => {
  const navigate = useNavigate();
  const { state: locationParams } = useLocation();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [relyingPartyTwofactorLogin] = useMutation(TWOFACTOR_LOGIN_ADMIN);
  const { setTokenAndRefreshToken, setRelayingPartyId, setRoleType } = useToken();
  const [loggedUser, { data }] = useLazyQuery(LOGGED_USER);
  const otpId = locationParams && (locationParams as any).otpId;

  const { enqueueSnackbar } = useSnackbar();

  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const { parseSettingsJsonString } = useSettings();

  useEffect(() => {
    if (!otpId) {
      navigate(`/login`, { replace: true });
      enqueueSnackbar('Unable to use 2FA', { variant: 'error' });
    }
  }, [otpId]);

  const showError = err => {
    console.error('err', err);
    enqueueSnackbar(err.message, { variant: 'error' });
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const result = await relyingPartyTwofactorLogin({
        variables: {
          otpId: otpId,
          otpCode: values.otpCode.trim()
        }
      });
      const { accessToken, refreshToken, relyingPartyAdmin } = result.data!.relyingPartyTwofactorLogin;

      parseSettingsJsonString(relyingPartyAdmin?.settings);
      setTokenAndRefreshToken(accessToken, refreshToken);
      currentLoggedUserVar(relyingPartyAdmin);

      const {
        relyingParty: { id },
        roleType
      } = relyingPartyAdmin;

      setRelayingPartyId(id);
      setRoleType(roleType);
    } catch (err) {
      handleMutationFormErrorCb(err, {
        cb: showError,
        errorMap: {
          [AuthErrorCodes.INVALID_LOGIN]: () => ({
            icon: faExclamationTriangle,
            title: 'Invalid login',
            message: 'Login could not be validated.',
            status: 'warning'
          }),
          [AuthErrorCodes.INVALID_CODE]: () => ({
            icon: faExclamationTriangle,
            title: 'Invalid Code',
            message: 'This code is invalid or has expired. Click Cancel and restart the login process.',
            status: 'warning'
          }),
          all: () => ({
            title: 'Unknown error',
            message: 'Unknown error',
            status: 'danger'
          })
        }
      });
    }
  };

  useEffect(() => {
    if (currentUser) {
      loggedUser({
        variables: {
          relyingPartyId: currentUser.relyingParty.id
        }
      }).catch(console.error);
    }
  }, [currentUser]);

  useEffect(() => {
    if (data) {
      const userData: LoggedUserAccountData = {
        name: data.relyingParty.name,
        logo: data.relyingParty.logoUrl,
        orgHandle: data.relyingParty.orgHandle,
        settings: data.relyingParty.settings,
        isCtaAccepted: data.relyingParty.isCtaAccepted
      };
      loggedUserData(userData);
      currentLoggedRelyingParty(data.relyingParty);

      const parsed = qs.parse(location.search);
      const redirectTo = parsed.to?.toString() || Config.DEFAULT_PATH || '/';
      navigate(redirectTo, { replace: true });
    }
  }, [data]);

  return (
    <Stack alignItems={'center'} sx={{ width: '100%', height: '100%', backgroundColor: color.truentityCyan[500] }}>
      <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', marginTop: '10vh' }}>
        <Box sx={{ paddingBottom: '24px', borderBottom: '2px solid $truentityLigthGray', marginBottom: '19px' }}>
          <TruentityLogo />
        </Box>

        <H4 align="center" color={color.truentityCyan[600]}>
          Two-Factor Authentication
        </H4>
        <Body1>
          We've sent a one-time code to the device associated with your account. Please enter the code below to proceed with the login.
        </Body1>

        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField required sx={{ letterSpacing: '2px' }} label="One-Time Code" type="password" {...register('otpCode')} fullWidth />

          <Button type="submit" a11yLabel="Continue Login" color={'secondary'} fullWidth />

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '19px' }}>
            <H6 component={Link} color={color.truentityCyan[600]} sx={{ textDecoration: 'none' }} to="/login">
              Cancel
            </H6>
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default TwoFactorLogin;
