import Button from '@/components/Button';
import { ProfileDialog } from '@/components/Dialogs';
import MarkdownDialog from '@/components/Dialogs/MarkdownDialog';
import PdfDialog from '@/components/Dialogs/PdfDialog';
import SwitchCompaniesDialog from '@/components/Dialogs/SwitchCompaniesDialog';
import { ProfileAvatar } from '@/components/ProfileAvatar';
import RelyingAdminLogo from '@/components/RelyingAdminLogo';
import TruentityMenu from '@/components/TruentityMenu';
import { Body2, H3, H4, Small } from '@/components/Typography';
import type { GetRPAgreementResponse } from '@/graphql/administration';
import { GET_RELYING_PARTY_AGREEMENT } from '@/graphql/administration';
import type { GetDocumentPreviewResponse } from '@/graphql/rpmWorkflow';
import { GET_DOCUMENT_PREVIEW } from '@/graphql/rpmWorkflow';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { getIconDataBasedOnRole, getRoleTypeForCurrentUser } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { stringAvatar } from '@/util/avatar';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import BusinessIcon from '@mui/icons-material/Business';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import { Box, Chip, ListItemButton, ListItemIcon, ListItemText, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VersionDialog from './Dialogs/VersionDialog';

const CustomMenuItem = ({ icon, onClick, label }) => {
  return (
    <ListItemButton sx={{ color: 'inherit', fontWeight: '500', gap: 0.5, marginTop: 1.5 }} onClick={onClick}>
      <ListItemIcon sx={{ color: 'inherit', fontWeight: 'inherit' }}>{icon}</ListItemIcon>
      <ListItemText primaryTypographyProps={{ fontWeight: 'inherit' }} primary={label} />
    </ListItemButton>
  );
};
const ProfileMenu = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { handleLogout } = useToken();
  const [name, setName] = useState(' ');
  const [company, setCompany] = useState<string>(' ');
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  const [roleTypeString, setRoleTypeString] = useState('Unknown');
  const [roleTypeIcon, setRoleTypeIcon] = useState<JSX.Element | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [getAgreement] = useLazyQuery<GetRPAgreementResponse>(GET_RELYING_PARTY_AGREEMENT);
  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showProfileDialog = () => {
    handleClose();
    const modal = showModal(ProfileDialog, {
      title: 'Profile',
      hideDialog: () => {
        modal.hide();
      },
      pharmacistInfo: currentUser
    });
  };

  const showSwitchCompaniesDialog = () => {
    const modal = showModal(SwitchCompaniesDialog, {
      title: 'Switch Companies',
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const onAgreementClick = async () => {
    try {
      const response = await getAgreement();
      if (response?.data?.relyingPartyAgreement?.status === 'Success') {
        const agreementUrl = await fetch(`${response?.data?.relyingPartyAgreement?.agreementUrl}`);
        const blob = await agreementUrl.blob();
        const modal = showModal(PdfDialog, {
          title: <H3 sx={{ textAlign: 'center' }}>Terms of Agreement</H3>,
          pdfUrl: URL.createObjectURL(new Blob([blob], { type: 'application/pdf' })),
          hideDialog: () => {
            modal.hide();
          }
        });
      } else {
        throw new Error('Could not load Agreement');
      }
    } catch (err) {
      enqueueSnackbar(err?.message ?? 'Could not load Agreement', { variant: 'error' });
    }
  };

  const showPortalVersionModal = (icdCodes: string, icdNames: string) => {
    const modalRef = showModal(VersionDialog, {
      title: 'Portal Version',
      hideDialog: () => {
        modalRef.hide();
      }
    });
  };

  useEffect(() => {
    if (currentUser?.relyingParty) {
      const { Icon, color } = getIconDataBasedOnRole(currentUser.roleType as Role);

      const firstName = currentUser.user?.firstName || '';
      const lastName = currentUser.user?.lastName || '';
      setName(firstName + ' ' + lastName);
      setCompany(currentUser?.relyingParty?.name);
      setAvatarUrl(currentUser?.presignedAvatarUrl);
      setRoleTypeString(getRoleTypeForCurrentUser(currentUser));

      if (Icon) {
        setRoleTypeIcon(<Icon fontSize="small" color={color} />);
      }
    }
  }, [currentUser]);

  const fetchDocumentPreview = async () => {
    try {
      const documentResponse = await getDocumentPreview({
        variables: {
          documentS3Key: 'administration/releases/current.md'
        }
      });
      const url = `${documentResponse?.data?.documentPreview?.documentUrl}`;
      if (url) {
        const response = await fetch(url);
        const text = await response.text();
        const modal = showModal(MarkdownDialog, {
          title: 'Release Notes',
          text: text,
          hideDialog: () => {
            modal.hide();
          }
        });
      } else {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Failed to load document preview', { variant: 'error' });
    }
  };

  return (
    <Box
      sx={{
        background: 'white',
        borderColor: 'grey.300',
        borderRadius: theme => theme.spacing(6),
        display: 'flex',
        alignItems: 'center',
        paddingX: theme => theme.spacing(2),
        paddingY: theme => theme.spacing(1)
      }}
    >
      <>
        <Button
          variant={'text'}
          disableElevation
          onClick={handleClick}
          endIcon={<ProfileAvatar avatarUrl={avatarUrl} {...stringAvatar(name)} />}
        >
          <RelyingAdminLogo />
        </Button>

        <TruentityMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <Stack alignItems={'center'} spacing={1} sx={{ color: 'primary.dark' }}>
            <ProfileAvatar avatarUrl={avatarUrl} name={name} />

            <Body2>{name}</Body2>
            <Chip
              variant="outlined"
              {...(roleTypeIcon && { icon: roleTypeIcon })}
              color="primary"
              label={roleTypeString}
              sx={{
                textTransform: 'capitalize',
                '& .MuiSvgIcon-root': {
                  marginLeft: '12px'
                }
              }}
            />
            <H4 className="mt-3" sx={{ color: 'primary.main', letterSpacing: '0.06em' }}>
              {company}
            </H4>
            {currentUser?.hasMultipleRelyingParties ? (
              <Stack padding={0} direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <CustomMenuItem icon={<ShuffleIcon />} onClick={() => showSwitchCompaniesDialog()} label={'SWITCH COMPANY'} />
              </Stack>
            ) : (
              <></>
            )}
            <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
              <CustomMenuItem icon={<PersonIcon />} onClick={() => showProfileDialog()} label={'PROFILE'} />
              <CustomMenuItem
                icon={<BusinessIcon />}
                onClick={() => navigate(`/administration/system/companies/${currentUser?.relyingParty.id}`)}
                label={'MY COMPANY'}
              />
            </Stack>
            <CustomMenuItem icon={<LogoutIcon />} onClick={() => handleLogout()} label={'SIGN OUT'} />
            <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'space-around'}>
              <Small
                sx={{
                  textTransform: 'none'
                }}
                component={Button}
                appearance={'plain'}
                onClick={showPortalVersionModal}
              >
                Version
              </Small>
              <Small
                sx={{
                  textTransform: 'none'
                }}
                component={Button}
                onClick={fetchDocumentPreview}
                appearance={'plain'}
              >
                Release Notes
              </Small>
            </Stack>
            <Small
              sx={{
                textTransform: 'none'
              }}
              component={Button}
              appearance={'plain'}
              onClick={onAgreementClick}
            >
              Terms of Agreement
            </Small>
          </Stack>
        </TruentityMenu>
      </>
    </Box>
  );
};

export default ProfileMenu;
