import { NewTaskV1CMRDialog, NewTaskV1Dialog } from '@/components/Dialogs';
import PatientDetailContext from '@/context/patientDetailContext';
import { SourceType, TASK_TYPES } from '@/types/medication';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const TaskType = [
  { label: 'tip', value: 'TIP' },
  { label: 'cmr', value: 'CMR' },
  { label: 'adherence', value: 'ADHERENCE' }
];

const TasksRoute = () => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { type, taskType, taskId } = useParams();
  const {
    medications,
    reloadMeds,
    goDetailsPage,
    reloadEncounters,
    tasks,
    goToListDetailsPage,
    reloadTasks,
    setFetchTasksInPatientContextLocally
  } = useContext(PatientDetailContext);

  const showNewTaskV1Modal = (
    task = null,
    editMode: boolean = false,
    customTitleHeader: boolean,
    sourceType: any = SourceType.PROVIDER
  ) => {
    const title = editMode ? `Edit Task` : `Add ${TaskType.find(e => e.label === taskType)?.value} Tasks`;
    const showSubTypes = taskType === 'adherence' || taskType === 'cmr' ? false : true;

    const dbTaskType = TASK_TYPES.find(task => task.value.toUpperCase() === taskType?.toUpperCase())?.value;

    const taskModal = showModal(NewTaskV1Dialog, {
      title,
      customTitleHeader,
      id,
      task,
      showSubTypes,
      dbTaskType,
      editMode,
      sourceType,
      hideDialog: () => {
        taskModal.hide();
        goToListDetailsPage();
        setFetchTasksInPatientContextLocally(true);
      },
      reloadTasks: () => {
        taskModal.hide();
        reloadTasks();
      },
      reloadEncounters: () => {
        taskModal.hide();
        reloadEncounters();
      }
    });
    return taskModal;
  };

  const showNewTaskV1CMRModal = (
    task = null,
    editMode: boolean = false,
    customTitleHeader: boolean,
    sourceType: any = SourceType.PROVIDER
  ) => {
    const title = editMode ? `Edit Task` : `Add ${TaskType.find(e => e.label === taskType)?.value} Tasks`;
    const showSubTypes = taskType === 'adherence' || taskType === 'cmr' ? false : true;

    const dbTaskType = TASK_TYPES.find(task => task.value.toUpperCase() === taskType?.toUpperCase())?.value;

    const taskModal = showModal(NewTaskV1CMRDialog, {
      title,
      customTitleHeader,
      id,
      task,
      showSubTypes,
      dbTaskType,
      editMode,
      sourceType,
      hideDialog: () => {
        taskModal.hide();
        goToListDetailsPage();
        setFetchTasksInPatientContextLocally(true);
      },
      reloadTasks: () => {
        taskModal.hide();
        reloadTasks();
      },
      reloadEncounters: () => {
        taskModal.hide();
        reloadEncounters();
      }
    });
    return taskModal;
  };

  useEffect(() => {
    if (type.toLowerCase() === 'new') {
      if (taskType === 'cmr') {
        showNewTaskV1CMRModal(null, false, true);
      } else {
        showNewTaskV1Modal(null, false, true);
      }
    }
  }, [type, taskType]);

  useEffect(() => {
    if (!type || type === 'new') {
      return;
    }

    if (tasks.length > 0) {
      const typeLc = type.toLowerCase() || 'view';

      // if task is edited, reading the id from taskType
      const ta = tasks.find(m => m.id === taskId);

      if (!ta) {
        console.error('not found');
        return;
      }

      switch (typeLc) {
        case 'edit':
          if (ta.type === 'CMR') {
            showNewTaskV1CMRModal(ta, true, true);
          } else {
            showNewTaskV1Modal(ta, true, true);
          }

          break;
      }
    }
  }, [type, tasks, taskId, taskType]);

  return <></>;
};

export default TasksRoute;
