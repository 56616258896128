export type Template = {
  title: string;
  enabled: boolean;
};

export type Message = {
  sender: string;
  text: string;
};

export enum SenderType {
  BOT = 'bot',
  USER = 'human'
}
