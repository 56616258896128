import { DialogContent, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  diagnosesCodes: string;
  diagnosesNames: string;
};

const IcdDescriptionDialog = ({ title, hideDialog, diagnosesCodes, diagnosesNames, ...props }: Props) => {
  const codes = diagnosesCodes.split(', ');
  const names = diagnosesNames.split(', ');

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <TableContainer>
          <TableBody>
            {codes.map((code, index) => (
              <TableRow key={index}>
                <TableCell>{code}</TableCell>
                <TableCell>{names[index]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </DialogContent>
    </BaseDialog>
  );
};

export default IcdDescriptionDialog;
