import Icon from '@/elements/Icon';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { ButtonProps } from '@mui/material';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import { memo } from 'react';

const ButtonAppearanceMap = new Map<string, 'text' | 'outlined' | 'contained'>([
  ['clear', 'text'],
  ['outline', 'outlined'],
  ['plain', 'text'],
  ['plainBlue', 'text'],
  ['primary', 'contained'],
  ['secondary', 'outlined']
]);

const ButtonColorMap = new Map<string, 'primary' | 'secondary'>([
  ['primary', 'primary'],
  ['secondary', 'secondary']
]);

export type Props = ButtonProps & {
  a11yLabel?: string;
  hasPopover?: boolean;
  iconLeft?: IconDefinition;
  iconRight?: IconDefinition;
  isLoading?: boolean;
  label?: string;
  loadingA11yLabel?: string;
  loadingIcon?: IconDefinition;
  loadingLabel?: string;
  appearance?: string;
};

const Button = ({
  a11yLabel,
  disabled,
  iconLeft,
  iconRight,
  isLoading,
  label,
  color,
  variant,
  startIcon,
  endIcon,
  size,
  appearance = 'contained',
  ...rest
}: Props) => {
  if (!variant) {
    variant = ButtonAppearanceMap.get(appearance) || 'contained';
  }

  if (!color) {
    color = ButtonColorMap.get(appearance) || 'primary';
  }

  if (isLoading) {
    startIcon = <CircularProgress size={18} color={'inherit'} />;
  } else if (!startIcon) {
    startIcon = iconLeft ? <Icon fixedWidth icon={iconLeft} /> : null;
  }

  if (!endIcon) {
    endIcon = iconRight ? <Icon fixedWidth icon={iconRight} /> : null;
  }

  if (!size) {
    size = 'small';
  }

  return (
    <MuiButton
      sx={{ whiteSpace: 'nowrap', minWidth: 'max-content' }}
      {...rest}
      size={size}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      disabled={disabled || isLoading}
    >
      {label || a11yLabel || rest.children}
    </MuiButton>
  );
};

export default memo(Button);
