import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { TimeoutStatus } from '@/components/TimeoutStatus';
import AdministrationContext from '@/context/administrationContext';
import SideMenuContext from '@/context/sideMenuContext';
import Icon from '@/elements/Icon';
import { GET_RELYING_PARTY_ADMINS_ALL } from '@/graphql/administration';
import { formatDateAndTime } from '@/util/format';
import { useLazyQuery } from '@apollo/client';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type LiveSession = {
  id: string;
  name: string;
  loggedIn: boolean;
  lastLoginAt: Date;
  lastLogoutAt: Date;
  minutesToday: string;
  minutesThisWeek: string;
  minutesThisMonth: string;
  minutesThisYear: string;
  activityToday: string;
  activityThisWeek: string;
  activityThisMonth: string;
  activityThisYear: string;
};

const Dashboard = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sessionUsers, setSessionUsers] = useState<LiveSession[]>([]);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  const [getUsers, usersResult] = useLazyQuery(GET_RELYING_PARTY_ADMINS_ALL);

  const { setUsers } = useContext(AdministrationContext);

  const navigate = useNavigate();

  const { setContent } = useContext(SideMenuContext);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, []);

  const callGetUsers = () => {
    getUsers({
      variables: {
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  };

  const getLoggedInIcon = (params: GridRenderCellParams) => {
    return params.value ? <CheckCircleIcon color={'success'} /> : <CancelIcon color={'error'} />;
  };

  useEffect(() => {
    //TODO:  Pagination will be needed when we get more than 100 users
    callGetUsers();
    const intervalId = setInterval(() => callGetUsers(), 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (usersResult?.data) {
      setUsers(usersResult.data.relyingPartyAdmins?.relyingPartyAdmins);

      const parsedUsers: LiveSession[] = usersResult.data?.relyingPartyAdmins?.relyingPartyAdmins?.map(user => {
        return {
          name: user.name,
          id: user.id,
          loggedIn: (user.lastSession && !user.lastSession.endedAt) || false,
          lastLoginAt: user.lastSession?.startedAt ? formatDateAndTime(user.lastSession?.startedAt) : '---',
          lastLogoutAt: user.lastSession?.endedAt ? formatDateAndTime(user.lastSession?.endedAt) : '---',
          timedOut: user.lastSession?.timedOut,
          minutesToday: user.minutesToday,
          minutesThisWeek: user.minutesThisWeek,
          minutesThisMonth: user.minutesThisMonth,
          minutesThisYear: user.minutesThisYear,
          activityToday: user.activityToday,
          activityThisWeek: user.activityThisWeek,
          activityThisMonth: user.activityThisMonth,
          activityThisYear: user.activityThisYear
        };
      });

      setSessionUsers(parsedUsers);
    }
  }, [usersResult.data?.relyingPartyAdmins?.relyingPartyAdmins]);

  useEffect(() => {
    setRowCount(sessionUsers?.length || 0);
  }, [sessionUsers]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        description: 'Name',
        sortable: true,
        minWidth: 130,
        valueGetter: params => params.row.name,
        align: 'left'
      },
      {
        field: 'loggedIn',
        headerName: 'Logged In',
        description: 'Logged In',
        sortable: true,
        width: 110,
        renderCell: params => getLoggedInIcon(params),
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'lastLogInAt',
        headerName: 'Last Login At',
        description: 'Last Login At',
        sortable: true,
        minWidth: 163,
        valueGetter: params => params.row.lastLoginAt,
        flex: 1,
        align: 'left'
      },
      {
        field: 'loggedOut',
        headerName: 'Last Logout At',
        description: 'Last Logout At',
        sortable: true,
        minWidth: 163,
        renderCell: params => {
          if (params.row?.lastLogoutAt !== '---') {
            return (
              <Stack spacing={1} direction="column">
                {params.row?.lastLogoutAt} <TimeoutStatus timedOut={params.row?.timedOut}></TimeoutStatus>
              </Stack>
            );
          }

          return <>{params.row?.lastLogoutAt}</>;
        },
        flex: 1,
        align: 'left'
      },
      {
        field: 'minutesToday',
        headerName: 'Time Logged In Today',
        description: 'Time Logged In Today',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.minutesToday;
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        headerClassName: 'wrapHeader',
        renderHeader: params => {
          return (
            <Stack direction="column" spacing={-4}>
              <Stack>
                <b>&nbsp;</b>
              </Stack>
              <Stack>
                <small>
                  <b>Today &#40;mins&#41;</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'minutesThisWeek',
        description: 'Time Logged In This Week',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.minutesThisWeek;
        },
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack direction="column" spacing={-4}>
              <Stack>
                <b>Total Mins Logged In</b>
              </Stack>
              <Stack textAlign={'center'}>
                <small>
                  <b>This Week &#40;mins&#41;</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'minutesThisMonth',
        description: 'Time Logged In This Month',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.minutesThisMonth;
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack direction="column" spacing={-4}>
              <Stack>
                <b>&nbsp;</b>
              </Stack>
              <Stack>
                <small>
                  <b>This Month &#40;mins&#41;</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'minutesThisYear',
        description: 'Time Logged In This Year',
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => {
          return (
            <Stack>
              <Stack>{params.row.minutesThisYear}</Stack>
            </Stack>
          );
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack direction="column" spacing={-4}>
              <Stack>
                <b>&nbsp;</b>
              </Stack>
              <Stack>
                <small>
                  <b>YTD &#40;mins&#41;&nbsp;</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'activityToday',
        description: 'API Calls Today',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.activityToday;
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack direction="column" spacing={-4}>
              <Stack>
                <b>&nbsp;</b>
              </Stack>
              <Stack>
                <small>
                  <b>Today</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'activityThisWeek',
        description: 'API Calls This Week',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.activityThisWeek;
        },
        flex: 1.5,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack direction="column" spacing={-4}>
              <Stack>
                <b>Total API Pulls</b>
              </Stack>
              <Stack textAlign={'center'}>
                <small>
                  <b>This Week</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'activityThisMonth',
        description: 'API Calls This Month',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.activityThisMonth;
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack title="API Calls This Month" direction="column" spacing={-4}>
              <Stack>
                <b>&nbsp;</b>
              </Stack>
              <Stack>
                <small>
                  <b>This Month</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'activityThisYear',
        description: 'API Calls This Year',
        sortable: false,
        disableColumnMenu: true,
        valueGetter: params => {
          return params.row.activityThisYear;
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderHeader: params => {
          return (
            <Stack title="API Calls This Year" direction="column" spacing={-4}>
              <Stack>
                <b>&nbsp;</b>
              </Stack>
              <Stack>
                <small>
                  <b>YTD</b>
                </small>
              </Stack>
            </Stack>
          );
        }
      },
      {
        field: 'action',
        headerName: 'Actions',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: params => {
          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton onClick={() => navigate(`/administration/users/${params.row.id}/details`)}>
                <Icon icon={faUserCog} fixedWidth size="lg" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [navigate]
  );

  return (
    <Stack>
      <Paper component={Stack} direction="column">
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-admin-dashboard'}
            autoHeight
            rows={sessionUsers || []}
            rowCount={rowCountState}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            paginationMode="server"
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Dashboard;
