import { color } from '@/styles/assets/colors';
import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { H4 } from './Typography';

interface InteractivePanelProps {
  icon: ReactNode;
  text: string;
  isSelect: boolean;
  onClick?: () => void;
}

const InteractivePanel: React.FC<InteractivePanelProps> = ({ icon, text, onClick, isSelect, ...props }) => {
  return (
    <Stack
      p={2}
      direction="row"
      alignItems="center"
      rowGap={1}
      sx={{
        border: '2px dashed',
        borderColor: isSelect ? ' #0000003B' : color.truentitySalmon[200],
        backgroundColor: isSelect ? color.grey50 : color.truentitySalmon[50],
        borderRadius: '8px',
        borderSpacing: '1px',
        cursor: isSelect ? 'pointer' : 'not-allowed',
        transition: 'background-color 0.3s ease',
        '&:hover': isSelect
          ? {
              backgroundColor: color.grey100
            }
          : {}
      }}
      component="div"
      onClick={isSelect ? onClick : undefined}
      {...props}
    >
      {icon}
      <H4 fontWeight={400}>{text}</H4>
    </Stack>
  );
};

export default InteractivePanel;
