import Button from '@/components/Button';
import ReportsTable from '@/components/ReportsTable';
import { H1 } from '@/components/Typography';
import { GET_TASK_ANALYTICS } from '@/graphql/analytics';
import { useLazyQuery } from '@apollo/client';
import { Grid, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';

export enum ItemName {
  ALL_TASK_TYPE_COUNTS = 'ALL_TASK_TYPE_COUNTS',
  CMR_PER_PATIENT = 'CMR_PER_PATIENT',
  TIP_PER_PATIENT = 'TIP_PER_PATIENT',
  ADHERENCE_PER_PATIENT = 'Adherence_PER_PATIENT',
  ACCOUNT_WITH_PERFORMED_ON = 'ACCOUNT_WITH_PERFORMED_ON'
}

const Tasks = () => {
  const [getTasks, { loading: loadingTasks }] = useLazyQuery(GET_TASK_ANALYTICS);

  const [allTaskTypes, setAllTaskTypes] = useState([]);
  const [cmrPerPatient, setCmrPerPatient] = useState([]);
  const [tipPerPatient, setTipPerPatient] = useState([]);
  const [adhPerPatient, setAdhPerPatient] = useState([]);
  const [accountWithPerformed, setAccountWithPerformed] = useState([]);

  const [taskLoading, setTaskLoading] = useState(false);

  const callGetTasks = async () => {
    setTaskLoading(true);

    try {
      const tasksData = await getTasks();

      if (tasksData && allTaskTypes.length === 0) {
        const taskAnalyticsMetrics = tasksData.data.taskAnalytics.taskAnalyiticsMetrics;

        const allTaskTypeCounts = taskAnalyticsMetrics.find(item => item.name === ItemName.ALL_TASK_TYPE_COUNTS);
        const cmrPerPatientCount = taskAnalyticsMetrics.find(item => item.name === ItemName.CMR_PER_PATIENT);
        const tipPerPatientCount = taskAnalyticsMetrics.find(item => item.name === ItemName.TIP_PER_PATIENT);
        const adhPerPatientCount = taskAnalyticsMetrics.find(item => item.name === ItemName.ADHERENCE_PER_PATIENT);
        const accountWithPerformedOnCount = taskAnalyticsMetrics.find(item => item.name === ItemName.ACCOUNT_WITH_PERFORMED_ON);

        setAllTaskTypes(allTaskTypeCounts?.allTypeCountMetrics || []);
        setCmrPerPatient(cmrPerPatientCount?.tipCmrAdhTypesMetrics || []);
        setTipPerPatient(tipPerPatientCount?.tipCmrAdhTypesMetrics || []);
        setAdhPerPatient(adhPerPatientCount?.tipCmrAdhTypesMetrics || []);
        setAccountWithPerformed(accountWithPerformedOnCount?.accountMetrics || []);
      }

      setTaskLoading(false);
    } catch (error) {
      setTaskLoading(false);
      console.error('Error retrieving tasks analytics data:', error);
    }
  };

  const createGridColumn = (fieldOne: string, fieldTwo: string, headerOne: string, headerTwo: string): GridColDef[] => [
    {
      field: fieldOne,
      headerName: headerOne
    },
    {
      field: fieldTwo,
      headerName: headerTwo
    }
  ];

  const allTaskTypeGridColumn: GridColDef[] = useMemo(() => createGridColumn('name', 'count', 'Task Type', 'Total Count'), []);
  const cmrGridColumn: GridColDef[] = useMemo(
    () => createGridColumn('typesPerPatient', 'numPatients', 'CMRs Per Patient', 'Num of Patients'),
    []
  );
  const tipGridColumn: GridColDef[] = useMemo(
    () => createGridColumn('typesPerPatient', 'numPatients', 'TIPs Per Patient', 'Num of Patients'),
    []
  );
  const adherenceGridColumn: GridColDef[] = useMemo(
    () => createGridColumn('typesPerPatient', 'numPatients', 'Adherence Per Patient', 'Num of Patients'),
    []
  );
  const accountPerformedGridColumn: GridColDef[] = useMemo(
    () => createGridColumn('truentityId', 'performedOn', 'Truentity ID', 'Performed On'),
    []
  );

  return (
    <Paper component={Stack} sx={{ padding: '20px' }}>
      <Grid container mt={2}>
        <Grid item xs={5}>
          <Stack alignItems={'left'} direction="row" justifyContent={'flex-start'}>
            <Button label="Run Reports" onClick={callGetTasks} />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          {' '}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack alignItems={'center'} direction="row" justifyContent={'center'}>
            <H1>Task Analytics</H1>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={3}>
          <ReportsTable
            columns={allTaskTypeGridColumn}
            tableName="All Task Types"
            rows={allTaskTypes}
            loader={loadingTasks || taskLoading}
          />
        </Grid>
        <Grid item xs={3}>
          <ReportsTable columns={cmrGridColumn} tableName="All CMRs" rows={cmrPerPatient} loader={loadingTasks || taskLoading} />
        </Grid>
        <Grid item xs={3}>
          <ReportsTable columns={tipGridColumn} tableName="All TIPs" rows={tipPerPatient} loader={loadingTasks || taskLoading} />
        </Grid>
        <Grid item xs={3}>
          <ReportsTable columns={adherenceGridColumn} tableName="All Adherence" rows={adhPerPatient} loader={loadingTasks || taskLoading} />
        </Grid>
        <Grid item xs={3}>
          <ReportsTable
            columns={accountPerformedGridColumn}
            tableName="Account with Performed"
            rows={accountWithPerformed}
            loader={loadingTasks || taskLoading}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Tasks;
