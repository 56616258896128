import { emptyData, reducer } from '@/state/taskForm';
import type { Encounter } from '@/types/medication';
import { TASK_DUPLICATION_COUNT_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material';
import type React from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import InputRow from '@/elements/InputRow';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import { GET_HEALTH_PLANS } from '@/graphql/administration';
import colors from '@/styles/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityTextField from '../TruentityTextField';
import { H4 } from '../Typography';
import BaseDialog from './BaseDialog';

const SAVE_UPDATE_TASK = gql`
  mutation saveUpdateTask($truentityId: String!, $taskData: TaskInput!, $taskId: String!) {
    saveUpdateTask(truentityId: $truentityId, taskData: $taskData, taskId: $taskId) {
      status
      message
    }
  }
`;

const SAVE_TASK_BULK = gql`
  mutation saveTaskBulk($truentityId: String!, $taskData: TaskInput!, $taskId: String!, $taskDuplicateCount: Int!) {
    saveTaskBulk(truentityId: $truentityId, taskData: $taskData, taskId: $taskId, taskDuplicateCount: $taskDuplicateCount) {
      status
      message
    }
  }
`;

type Props = BaseDialogProps & {
  id: any;
  medication?: any;
  task?: any;
  editMode: boolean;
  sourceType?: any;
  hideDialog: () => void;
  reloadEncounters: () => void;
  reloadTasks: () => void;
  showSubTypes?: boolean;
  dbTaskType?: string;
};

const NewTaskDialog = ({
  id,
  title,
  customTitleHeader,
  showSubTypes,
  dbTaskType,
  hideDialog,
  task,
  medication,
  editMode,
  sourceType,
  reloadEncounters,
  reloadTasks,
  ...props
}: Props): React.ReactElement => {
  const taskId = task?.id;

  const [saveUpdateTask] = useMutation(SAVE_UPDATE_TASK);
  const [saveTaskBulk] = useMutation(SAVE_TASK_BULK);
  const [getHealthPlans, { data: healthPlans }] = useLazyQuery(GET_HEALTH_PLANS);
  const [getInfo, { data: patientData }] = useLazyQuery(GET_PATIENT_DETAIL);
  const [healthPlanList, setHealthPlans] = useState<[]>([]);
  const [healthPlanTask, setHealthPlanTask] = useState<string>('');
  const [encounters, setEncounters] = useState<Encounter[]>([]);

  const [taskDuplicationCount, setTaskDuplicationCount] = useState<string>('1');
  const [duplicateTaskChecked, setDuplicateTaskChecked] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, emptyData);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getInfo({
      variables: {
        truentityId: id
      }
    });
  }, [id]);

  useEffect(() => {
    if (editMode) {
      const taskHealthPlan = task?.healthPlan;

      setHealthPlanTask(taskHealthPlan);
    } else {
      const taskHealthPlan = patientData?.accountGet?.healthPlan?.orgName;

      setHealthPlanTask(taskHealthPlan);
    }
  }, [patientData, editMode]);

  useEffect(() => {
    if (editMode && task) {
      if (task.type) {
        updateType({ type: task.type });
      }

      if (task.subType) {
        updateType({ subType: task.subType });
      }

      if (task.name) {
        dispatch({ type: 'name', payload: task.name });
      }

      if (task.performedOn) {
        dispatch({ type: 'performed-on', payload: task.performedOn });
      }

      if (task.status) {
        dispatch({ type: 'status', payload: task.status });
      }

      if (task.resolutionStatus) {
        dispatch({ type: 'resolution-status', payload: task.resolutionStatus });
      }

      if (task.location) {
        dispatch({ type: 'location', payload: task.location });
      }

      if (task.billedAmount) {
        dispatch({ type: 'payment', payload: task.billedAmount });
      }

      if (task.nextSteps) {
        updateNextSteps({ nextSteps: task.nextSteps });
      }

      if (task.isPrimaryAffiliation !== null) {
        dispatch({ type: 'is-primary', payload: task.isPrimaryAffiliation });
      }

      if (task.modeOfResolution) {
        dispatch({ type: 'mode-of-resolution', payload: task.modeOfResolution });
      }

      if (task.resolutionStatus) {
        dispatch({ type: 'resolution-status', payload: task.resolutionStatus });
      }

      if (task.wrapUpStatus) {
        dispatch({ type: 'wrap-up-status', payload: task.wrapUpStatus });
      }

      if (task.medicationName) {
        dispatch({ type: 'medication-name', payload: task.medicationName });
      }
    }
  }, [task, editMode]);

  useEffect(() => {
    getHealthPlans({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, []);

  useEffect(() => {
    if (healthPlans && healthPlans.healthPlans && healthPlans.healthPlans.healthPlans) {
      setHealthPlans(healthPlans.healthPlans.healthPlans);
    }
  }, [healthPlans]);

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();

      if (editMode) {
        try {
          const task = {
            name: state.name,
            type: state.type,
            subType: state.subType,
            status: 'PENDING',
            resolutionStatus: state.resolutionStatus,
            resolutionNotes: 'NOTES',
            performedOn: state.performedOn ? moment(state.performedOn).format() : null,
            minutesTaken: 23,
            performedAt: 'at',
            nextCallScheduledFor: '2002-03-22',
            nextSteps: state.nextSteps,
            healthPlan: healthPlanTask,
            location: state.location,
            payment: state.payment,
            billableEligible: false,
            billedAmount: Number(state.billedAmount),
            validationPaymentEligible: true,
            validationPaymentAmount: true,
            notes: 'notes test',
            medicationName: state.medicationName,
            modeOfResolution: state.modeOfResolution,
            wrapUpStatus: state.wrapUpStatus,
            isPrimaryAffiliation: Boolean(state.isPrimaryAffiliation)
          };

          saveUpdateTask({
            variables: {
              taskData: {
                ...task
              },
              truentityId: id,
              taskId: taskId
            }
          }).then(response => {
            if (response.data?.saveUpdateTask?.status === 'Success') {
              reloadTasks();
            }
          });
        } catch (error) {
          const failMessage: string = editMode ? 'Failed to Update Task' : 'Failed to Create Task';
          enqueueSnackbar(failMessage, {
            variant: 'error'
          });
        }

        hideDialog();
      } else {
        if (duplicateTaskChecked) {
          handleOnClickCreateAndDuplicateTask();
        } else {
          try {
            const task = {
              name: state.name,
              type: dbTaskType,
              subType: state.subType,
              status: 'PENDING',
              resolutionStatus: state.resolutionStatus,
              resolutionNotes: 'NOTES',
              performedOn: state.performedOn ? moment(state.performedOn).format() : null,
              minutesTaken: 23,
              performedAt: 'at',
              nextCallScheduledFor: '2002-03-22',
              nextSteps: state.nextSteps,
              healthPlan: healthPlanTask,
              location: state.location,
              payment: state.payment,
              billableEligible: false,
              billedAmount: Number(state.billedAmount),
              validationPaymentEligible: true,
              validationPaymentAmount: true,
              notes: 'notes test',
              medicationName: state.medicationName,
              modeOfResolution: state.modeOfResolution,
              wrapUpStatus: state.wrapUpStatus,
              isPrimaryAffiliation: state.isPrimaryAffiliation
            };

            console.log(task);

            saveUpdateTask({
              variables: {
                taskData: {
                  ...task
                },
                truentityId: id,
                taskId: ''
              }
            }).then(response => {
              if (response.data?.saveUpdateTask?.status === 'Success') {
                reloadTasks();
              }
            });
          } catch (error) {
            console.error(error);
            const failMessage: string = editMode ? 'Failed to Update Medication' : 'Failed to Create Medication';
            enqueueSnackbar(failMessage, {
              variant: 'error'
            });
          }
        }
        hideDialog();
      }
    },
    [state, encounters, duplicateTaskChecked, taskDuplicationCount, healthPlanTask]
  );

  const handleOnClickCreateAndDuplicateTask = () => {
    try {
      const task = {
        name: state.name,
        type: dbTaskType,
        subType: state.subType,
        status: 'PENDING',
        resolutionStatus: state.resolutionStatus,
        resolutionNotes: 'NOTES',
        performedOn: state.performedOn ? moment(state.performedOn).format() : null,
        minutesTaken: 23,
        performedAt: 'at',
        nextCallScheduledFor: '2002-03-22',
        nextSteps: state.nextSteps,
        healthPlan: healthPlanTask,
        location: state.location,
        payment: state.payment,
        billableEligible: false,
        billedAmount: Number(state.billedAmount),
        validationPaymentEligible: true,
        validationPaymentAmount: true,
        notes: 'notes test',
        medicationName: state.medicationName,
        modeOfResolution: state.modeOfResolution,
        wrapUpStatus: state.wrapUpStatus,
        isPrimaryAffiliation: state.isPrimaryAffiliation
      };

      saveTaskBulk({
        variables: {
          taskData: {
            ...task
          },
          taskDuplicateCount: Number(taskDuplicationCount) + 1, // adding +1 in order to add the original task count as well
          truentityId: id,
          taskId: ''
        }
      }).then(response => {
        if (response.data?.saveTaskBulk?.status === 'Success') {
          const failMessage = 'Created Task successfully';
          enqueueSnackbar(failMessage, {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          });

          reloadTasks();
        }

        hideDialog();
      });
    } catch (error) {
      console.error(error);
      const failMessage: string = editMode ? 'Failed to Update Medication' : 'Failed to Create Medication';
      enqueueSnackbar(failMessage, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  };

  const updateType = ({ type, subType }: { type?: string; subType?: string }) => {
    if (type) {
      dispatch({ type: 'type', payload: type });
    }

    if (subType) {
      dispatch({ type: 'sub-type', payload: subType });
    }
  };

  const updateStatus = ({
    status,
    resolutionStatus,
    modeOfResolution,
    wrapUpStatus
  }: {
    status?: string;
    resolutionStatus?: string;
    modeOfResolution?: string;
    wrapUpStatus?: string;
  }) => {
    if (status) {
      dispatch({ type: 'status', payload: status });
    }

    if (modeOfResolution) {
      dispatch({ type: 'mode-of-resolution', payload: modeOfResolution });
    }

    if (resolutionStatus) {
      dispatch({ type: 'resolution-status', payload: resolutionStatus });
    }

    if (wrapUpStatus) {
      dispatch({ type: 'wrap-up-status', payload: wrapUpStatus });
    }
  };

  const updateNextSteps = ({ nextSteps }: { nextSteps?: string }) => {
    if (nextSteps) {
      dispatch({ type: 'next-steps', payload: nextSteps });
    }
  };

  return (
    <>
      <BaseDialog title={title} customTitleHeader={customTitleHeader} hideDialog={hideDialog} maxWidth={'lg'} fullWidth {...props}>
        <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
          <form onSubmit={handleOnSubmit}>
            <Grid container spacing={1} pb={2}>
              <Grid item xs={12}>
                <Card sx={{ minWidth: 275, marginTop: '10px' }}>
                  <CardContent>
                    <Grid container spacing={1} pb={2}>
                      {showSubTypes && (
                        <Grid item xs={6}>
                          <FormControl fullWidth size="small">
                            <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                              <InputLabel id={'modeOfResolution'}>TIP Types</InputLabel>

                              <Select
                                labelId={'subType'}
                                label={'TIP Types'}
                                placeholder={'Select an option...'}
                                value={state.subType || ''}
                                onChange={input => {
                                  updateType({ subType: input?.target?.value });
                                }}
                                required
                              >
                                {UPDATED_SUB_TASK_TYPES.map((item: any) => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormControl>
                        </Grid>
                      )}

                      {/* <Grid item xs={6}>
                        <FormControl fullWidth size='small'>
                          <SelectList
                            id='modeOfResolution'
                            label='Mode Of Resolution'
                            options={TASK_MODE_OF_RESOLUTION_TYPES}
                            placeholder='Select an option...'
                            value={state.modeOfResolution}
                            defaultValue=''
                            onChange={(input) => updateStatus({ modeOfResolution: input.target.value })}
                          />

                        </FormControl>
                      </Grid> */}

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <TruentityTextField
                            id={'medication_name'}
                            label="Medication"
                            type="text"
                            value={state.medicationName}
                            onChange={input => {
                              dispatch({
                                type: 'medication-name',
                                payload: input.target.value
                              });
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6}>
                        <FormControl fullWidth size="small">
                          <InputRow>
                            <TruentityTextField
                              label="Notes"
                              type="text"
                              multiline
                              rows={1}
                              maxRows={4}
                              value={state.name}
                              defaultValue={state.name}
                              onChange={input => {
                                dispatch({
                                  type: 'name',
                                  payload: input.target.value
                                });
                              }}
                            />
                          </InputRow>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Accordion key={'r1111ow.id'} defaultExpanded={false}>
                      <AccordionSummary
                        sx={{ boxShadow: `inset 0 -1px 0 0 ${colors.neutralA8}` }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`{option.label}-header-content-{index}`}
                        id={`{option.label}-header-{index}`}
                      >
                        <H4>{'Additional Information'}</H4>
                      </AccordionSummary>

                      <AccordionDetails sx={{ backgroundColor: '#fafafa', padding: '30px' }}>
                        <Grid container spacing={1} pb={2} pt={2}>
                          <Grid item xs={6}>
                            <SelectList
                              keepOriginalValue
                              id={'healthPlan'}
                              label="Health Plan"
                              options={healthPlanList?.map(x => ({
                                label: x?.orgName,
                                value: x?.orgName
                              }))}
                              placeholder="Select a health plan..."
                              value={healthPlanTask || ''}
                              onChange={e => setHealthPlanTask(e.target.value)}
                              MenuProps={{
                                sx: { maxHeight: 250 }
                              }}
                              clearFunction={() => {
                                setHealthPlanTask('');
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth size="small">
                              <TruentityTextField
                                id={'location'}
                                label="Location"
                                type="text"
                                value={state.location}
                                defaultValue={state.location}
                                onChange={input => {
                                  dispatch({
                                    type: 'location',
                                    payload: input.target.value
                                  });
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <Stack
                              direction={'row'}
                              spacing={1}
                              sx={{
                                justifyContent: 'start'
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.isPrimaryAffiliation}
                                    onChange={event => {
                                      dispatch({
                                        type: 'is-primary',
                                        payload: event.target.checked
                                      });
                                    }}
                                  />
                                }
                                label="Is Primary"
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>

                    <Divider />

                    {!editMode && (
                      <Grid container spacing={1} pb={2}>
                        <Grid item xs={6}>
                          <Stack
                            direction="row"
                            alignItems={'center'}
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'start',
                              padding: '30px 0 0 0'
                            }}
                            spacing={1}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={duplicateTaskChecked}
                                  onChange={event => {
                                    setDuplicateTaskChecked(event.target.checked);
                                  }}
                                />
                              }
                              label="Make Copies"
                            />

                            <TruentityTextField
                              size="small"
                              inputProps={{ 'aria-label': 'Without label' }}
                              sx={{ width: 150 }}
                              select
                              disabled={!duplicateTaskChecked}
                              value={taskDuplicationCount}
                              onChange={event => {
                                setTaskDuplicationCount(event.target.value);
                              }}
                            >
                              {TASK_DUPLICATION_COUNT_TYPES.map(name => (
                                <MenuItem key={name.value} value={name.value}>
                                  {name.label}
                                </MenuItem>
                              ))}
                            </TruentityTextField>
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={2}>
              <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
                <Button type="submit" a11yLabel={editMode ? 'Edit' : 'Create'} appearance="primary" />
                <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
              </DialogActions>
            </Stack>
          </form>
        </DialogContent>
      </BaseDialog>
    </>
  );
};

export default NewTaskDialog;
