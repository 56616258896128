export const localeCompare = (keyName: string, ascending: boolean) => {
  return (a: any, b: any) => {
    const keyA: any = a[keyName];
    const keyB: any = b[keyName];

    if (ascending) {
      return keyB.localeCompare(keyA);
    }

    return keyA.localeCompare(keyB);
  };
};

export const dateCompare = (keyName: string, ascending: boolean) => {
  return (a: any, b: any) => {
    const keyA: any = new Date(a[keyName]);
    const keyB: any = new Date(b[keyName]);

    if (ascending) {
      return keyA - keyB;
    }

    return keyB - keyA;
  };
};
