import ClearIcon from '@mui/icons-material/Clear';
import type { SelectProps } from '@mui/material';
import { FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';

export type TextSelectOption = { label: string; value: string; disabled?: boolean; color?: string };

export type SelectListProps = SelectProps & {
  label?: string;
  options: TextSelectOption[];
  placeholder?: string;
  fullWidth?: boolean;
  formControlProps?: any;
  value: string | string[];
  clearFunction?: any;
  keepOriginalValue?: boolean;
  formHelperText?: string;
};

const SelectList = ({
  id,
  label,
  options,
  placeholder,
  value,
  fullWidth,
  formControlProps,
  clearFunction,
  keepOriginalValue,
  formHelperText,
  ...props
}: SelectListProps) => {
  const [val, setVal] = useState('');
  const [showClearBtn, setShowClearBtn] = useState(false);

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    setShowClearBtn(clearFunction && val.length > 0);
  }, [val, clearFunction]);

  if (typeof fullWidth === 'undefined') {
    fullWidth = true;
  }

  return (
    <FormControl variant="outlined" fullWidth={fullWidth} margin="dense" size="medium" {...formControlProps}>
      <InputLabel id={id}>{label}</InputLabel>

      <Select
        labelId={id}
        label={label}
        sx={{
          '& .MuiSelect-iconOutlined': { display: showClearBtn ? 'none' : '' },
          '&.Mui-focused .MuiIconButton-root': { color: 'primary.main' }
        }}
        placeholder={placeholder}
        value={keepOriginalValue ? val : val?.toLowerCase()}
        endAdornment={
          <IconButton
            disabled={props.disabled}
            disableRipple
            sx={{ padding: 0, display: showClearBtn ? 'block' : 'none' }}
            onClick={clearFunction}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        }
        {...props}
      >
        {options.map((item: any, index: number) => (
          <MenuItem
            key={index}
            value={keepOriginalValue ? item.value : item.value?.toLowerCase()}
            disabled={item?.disabled}
            sx={{ color: item?.color }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectList;
