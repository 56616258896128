import { useState } from 'react';

import { DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  onSaveFilterClickedFromTemplateModal: (test: any) => void;
};

const SaveTemplateDialog = ({ title, hideDialog, onSaveFilterClickedFromTemplateModal, ...props }: Props) => {
  const [nameValue, setNameValue] = useState<string>('');

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container spacing={4}>
          <Grid item xs={12}>
            <Stack>
              <TruentityTextField autoFocus onChange={input => setNameValue(input.target.value)} value={nameValue} label={'Name'} />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button
                type="submit"
                a11yLabel="Save"
                appearance="primary"
                onClick={e => {
                  e.preventDefault();
                  onSaveFilterClickedFromTemplateModal(nameValue);
                }}
              />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default SaveTemplateDialog;
