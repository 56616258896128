import { stringAvatar } from '@/util/avatar';
import { Avatar, Stack } from '@mui/material';
import { memo } from 'react';
import { Body1 } from './Typography';

type ChipLabelProps = {
  item: string;
  useAvatar: boolean;
};
const ChipLabel = ({ item, useAvatar }: ChipLabelProps) => {
  return (
    <>
      {useAvatar ? (
        <Stack direction={'row'} spacing={1} alignItems="center">
          <Avatar sx={{ width: 24, height: 24, fontSize: '0.825rem' }} {...stringAvatar(item)} />
          <Body1>{item}</Body1>
        </Stack>
      ) : (
        item
      )}
    </>
  );
};

export default memo(ChipLabel);
