import Popover from '@mui/material/Popover';

import { Chip, Stack } from '@mui/material';
import Button from '../Button';

interface ShowMoreOrgStorePopoverProps {
  showMore: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  orgStorePairs: any;
  maxNamesToShow: number;
}

export default function ShowMoreOrgStorePopover({
  showMore,
  anchorEl,
  handleClose,
  orgStorePairs,
  maxNamesToShow
}: ShowMoreOrgStorePopoverProps) {
  return (
    <Popover
      open={showMore}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Stack direction="column" spacing={1} sx={{ p: 2 }}>
        {orgStorePairs.map(
          ([orgName, storeName], index) =>
            index >= maxNamesToShow && (
              <div key={`${orgName}-${storeName}`}>
                {orgName && <Chip label={orgName} style={{ marginRight: '4px' }} />}
                {storeName && <Chip label={storeName} variant="outlined" />} <br />
              </div>
            )
        )}
        <Button variant="text" onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </Popover>
  );
}
