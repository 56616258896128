import type { Dosage, FormState, Period, RouteOfAdministration, Strength, TimeOfDay } from '@/types/medication';
import { DEFAULT_DOSAGE, DEFAULT_PERIOD, DEFAULT_ROUTE_OF_ADMINISTRATION, DEFAULT_STRENGTH, DEFAULT_TIME_OF_DAY } from '@/types/medication';
import { getKeyByValue } from '@/util/object';
import { formatDate } from './format';

export const selectStrength = (strengthOptions: [Strength], isPrescribed: boolean): Strength => {
  const results = strengthOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? results[results.length - 1] : { ...DEFAULT_STRENGTH };
};

export const selectDosage = (dosageOptions: [Dosage], isPrescribed: boolean): Dosage => {
  const results = dosageOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? results[results.length - 1] : { ...DEFAULT_DOSAGE };
};

export const selectPeriod = (periodOptions: [Period], isPrescribed: boolean): Period => {
  const results = periodOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? results[results.length - 1] : { ...DEFAULT_PERIOD };
};

export const buildValues = (array: any[], isPrescribed: boolean): string => {
  const results = array.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? `${results[results.length - 1].value} ${results[results.length - 1].unit}` : '---';
};

export const buildValuesFromMap = (map: any, array: any[], isPrescribed: boolean): string => {
  const results = array.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');

  if (results.length === 0) {
    return '---';
  }

  return getKeyByValue(map, `${results[results.length - 1].value}:${results[results.length - 1].unit}`) || '---';
};

export const selectValues = (array: any[], isPrescribed: boolean) => {
  const results = array.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? results[results.length - 1] : [];
};

export const cleanupObj = (obj, date?) => {
  const cleanObj = obj.map(item => {
    const copy = { ...item };
    delete copy.__typename;

    if (!copy.timestamp && date) {
      copy.timestamp = date;
    }

    return copy;
  });

  return cleanObj;
};

export const buildStrengthValues = (state: FormState, strength: [], editMode: boolean): Strength[] => {
  let result: Strength[] = [];

  const date = new Date().toISOString();

  const newStrength: Strength = {
    id: state.strength?.id,
    value: state.strength.value,
    unit: state.strength.unit,
    timestamp: date,
    isPrescribed: true
  };

  const newModifiedStrength: Strength = {
    id: state.modifiedStrength?.id,
    value: state.modifiedStrength.value,
    unit: state.modifiedStrength.unit,
    timestamp: date,
    isPrescribed: false
  };

  if (strength && strength.length > 0) {
    const cleanStrength = cleanupObj(strength, date);

    if (editMode) {
      result = [...cleanStrength, newModifiedStrength];
    } else {
      result = [...cleanStrength, newStrength];
    }
  } else {
    result = [newStrength];

    if (editMode) {
      result = [...result, newModifiedStrength];
    }
  }

  return result;
};

export const buildDosageValues = (state: FormState, dosage: [], editMode: boolean): Dosage[] => {
  let result: Dosage[] = [];
  const date = new Date().toISOString();
  const newDosage: Dosage = {
    id: state.dosage?.id,
    value: state.dosage.value,
    unit: state.dosage.unit,
    timestamp: date,
    isPrescribed: true
  };
  const newModifiedDosage: Dosage = {
    id: state.modifiedDosage?.id,
    value: state.modifiedDosage.value,
    unit: state.modifiedDosage.unit,
    timestamp: date,
    isPrescribed: false
  };

  if (dosage && dosage.length > 0) {
    const cleanDosage = cleanupObj(dosage, date);

    if (editMode) {
      result = [...cleanDosage, newModifiedDosage];
    } else {
      result = [...cleanDosage, newDosage];
    }
  } else {
    result = [newDosage];
    if (editMode) {
      result = [...result, newModifiedDosage];
    }
  }

  return result;
};

export const buildPeriodValues = (state: FormState, period: [], editMode: boolean): Period[] => {
  let result: Period[] = [];
  const date = new Date().toISOString();
  const newPeriod: Period = {
    id: state.period?.id,
    value: state.period.value,
    unit: state.period.unit,
    timestamp: date,
    isPrescribed: true
  };
  const newModifiedPeriod: Period = {
    id: state.modifiedPeriod?.id,
    value: state.modifiedPeriod.value,
    unit: state.modifiedPeriod.unit,
    timestamp: date,
    isPrescribed: false
  };

  if (period && period.length > 0) {
    const cleanPeriod = cleanupObj(period, date);

    if (editMode) {
      result = [...cleanPeriod, newModifiedPeriod];
    } else {
      result = [...cleanPeriod, newPeriod];
    }
  } else {
    result = [newPeriod];
    if (editMode) {
      result = [...result, newModifiedPeriod];
    }
  }

  return result;
};

export const buildRouteOfAdministrationValues = (
  state: FormState,
  routeOfAdministration: [],
  editMode: boolean
): RouteOfAdministration[] => {
  let result: RouteOfAdministration[] = [];
  const date = new Date().toISOString();
  const newRouteOfAdministration: RouteOfAdministration = {
    id: state.routeOfAdministration?.id,
    value: state.routeOfAdministration.value,
    timestamp: date,
    isPrescribed: true
  };
  const newModifiedRouteOfAdministration: RouteOfAdministration = {
    id: state.modifiedRouteOfAdministration?.id,
    value: state.modifiedRouteOfAdministration.value,
    timestamp: date,
    isPrescribed: false
  };

  if (routeOfAdministration && routeOfAdministration.length > 0) {
    const cleanRouteOfAdministration = cleanupObj(routeOfAdministration, date);

    if (editMode) {
      result = [...cleanRouteOfAdministration, newModifiedRouteOfAdministration];
    } else {
      result = [...cleanRouteOfAdministration, newRouteOfAdministration];
    }
  } else {
    result = [newRouteOfAdministration];
    if (editMode) {
      result = [...result, newModifiedRouteOfAdministration];
    }
  }

  return result;
};

export const buildTimeOfDayValues = (state: FormState, timeOfDay: [], editMode: boolean): TimeOfDay[] => {
  let result: TimeOfDay[] = [];
  const date = new Date().toISOString();
  const newTimeOfDay: TimeOfDay = {
    id: state.timeOfDay?.id,
    value: state.timeOfDay.value,
    timestamp: date,
    isPrescribed: true
  };
  const newModifiedTimeOfDay: TimeOfDay = {
    id: state.modifiedTimeOfDay?.id,
    value: state.modifiedTimeOfDay.value,
    timestamp: date,
    isPrescribed: false
  };

  if (timeOfDay && timeOfDay.length > 0) {
    const cleanTimeOfDay = cleanupObj(timeOfDay, date);

    if (editMode) {
      result = [...cleanTimeOfDay, newModifiedTimeOfDay];
    } else {
      result = [...cleanTimeOfDay, newTimeOfDay];
    }
  } else {
    result = [newTimeOfDay];
    if (editMode) {
      result = [...result, newModifiedTimeOfDay];
    }
  }

  return result;
};

export const selectRouteOfAdministrationArray = (
  strengthOptions: [RouteOfAdministration],
  isPrescribed: boolean
): RouteOfAdministration => {
  const results = strengthOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? results[results.length - 1] : { ...DEFAULT_ROUTE_OF_ADMINISTRATION };
};
export const selectRouteOfAdministrationString = (routeOfAdministrationOptions: any, isPrescribed: boolean): string => {
  const results = routeOfAdministrationOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');

  return results.length > 0 ? `${results[results.length - 1].value}` : '---';
};

export const selectTimeOfDayArray = (timeOfDayOptions: [TimeOfDay], isPrescribed: boolean): TimeOfDay => {
  const results = timeOfDayOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? results[results.length - 1] : { ...DEFAULT_TIME_OF_DAY };
};

export const selectTimeOfDayString = (timeOfDayOptions: any, isPrescribed: boolean): string => {
  const results = timeOfDayOptions.filter(item => item.isPrescribed === isPrescribed && item.value.toString() !== '');
  return results.length > 0 ? `${results[results.length - 1].value}` : '---';
};

export const buildDate = (dateVal: string) => {
  if (!dateVal) {
    return '---';
  }

  return formatDate(dateVal);
};

export const handleUndefinedEntry = val => {
  return val ? val : '---';
};

export const buildRenderValue = arr => {
  if (arr?.length > 0) {
    return `${arr[0].value} ${arr[0].unit}`;
  }
  return '---';
};

// export const buildStrengthValue = (
//   strengthArr: [],
// ): Strength[] => {

//   const date = new Date().toISOString()

//   return strengthArr.map((strength: Strength) => ({
//     value:strength.value,
//     unit: strength.unit,
//     timestamp: date,
//     isPrescribed: true,
//   }))

// }

export const buildFromValues = <T>(value: any, modifiedValue?: any, previousValues?: any[], editMode: boolean = false): T[] => {
  let result: T[] = [];

  const date = new Date().toISOString();

  const newValue: T = value
    ? {
        value: value.value,
        unit: value.unit,
        timestamp: date,
        isPrescribed: true
      }
    : ({} as Strength);

  const newModifiedValue: T = modifiedValue
    ? {
        value: modifiedValue.value,
        unit: modifiedValue.unit,
        timestamp: date,
        isPrescribed: false
      }
    : ({} as Strength);

  if (previousValues && previousValues.length > 0) {
    const cleanValue = cleanupObj(previousValues, date);

    if (editMode) {
      result = [...cleanValue, newModifiedValue];
    } else {
      result = [...cleanValue, newValue];
    }
  } else {
    result = [newValue];

    if (editMode) {
      result = [...result, newModifiedValue];
    }
  }

  return result;
};

export const isVerified = medication => {
  return medication?.lastVerifiedById !== null;
};

export const isImported = medication => {
  return medication.source?.toLowerCase().startsWith('imported');
};

export const isCurrent = medication => {
  return medication.isCurrent === true;
};
