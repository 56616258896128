import { H6, LogText } from '@/components/Typography';

import { loggedUserData } from '@/util/apollo/cache';
import { formatDateTimeIgnoreTZ } from '@/util/format';
import { useReactiveVar } from '@apollo/client';
import { Card, CardContent, Stack } from '@mui/material';

const LogView = ({ tasksEncountersLogs }) => {
  const currentUser = useReactiveVar(loggedUserData);

  return (
    <>
      <Card sx={{ minWidth: 275, marginTop: '18px', backgroundColor: '#f7f7f7' }}>
        <CardContent>
          {tasksEncountersLogs.length === 0 && (
            <Stack direction={'row'} alignItems="center" justifyContent={'space-between'} sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
              <H6 sx={{ width: '50%' }} textAlign="right">
                No Logs available for this task
              </H6>
            </Stack>
          )}

          {tasksEncountersLogs.length > 0 &&
            tasksEncountersLogs.map(log => {
              return (
                <Stack direction={'row'} alignItems="center" justifyContent={'space-between'} sx={{ marginTop: 1.5, marginBottom: 1.5 }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <LogText fontWeight="bold">
                      {log.account.user.firstName} {log.account.user.lastName} {log.logText}
                    </LogText>
                  </Stack>
                  <H6 sx={{ width: '50%' }} textAlign="right">
                    {formatDateTimeIgnoreTZ(log.activityTime)}
                  </H6>
                </Stack>
              );
            })}
        </CardContent>
      </Card>
    </>
  );
};

export default LogView;
