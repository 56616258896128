import { BarChart } from '@/components/Charts/charts';
import { BloodPressureType, UnitType } from '@/types/remotePatientMonitoring';
import { formatTime } from '@/util/format';

interface BarChartSectionProps {
  title: string;
  chartXValues: string[];
  overwriteChartXValues?: string[];
  chartYValues: number[];
  chartY2Values: number[];
  additionalCss: {
    backgroundColor: string;
  };
  width?: number;
  height?: number;
}

const BarChartSection = ({
  title,
  chartXValues,
  overwriteChartXValues = [],
  chartYValues,
  chartY2Values,
  additionalCss,
  width = 550,
  height = 420
}: BarChartSectionProps) => {
  return (
    <BarChart
      width={width}
      additionalCss={additionalCss}
      options={{
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false
          }
        },
        chart: {
          id: 'vitals-chart',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        xaxis: {
          categories: chartXValues,
          overwriteCategories: overwriteChartXValues?.length > 0 ? overwriteChartXValues : undefined
        },
        colors: ['#ED8074', '#008000'],
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter: value => {
              const result = formatTime(value, 'MM/DD - h:mm A');
              if (result === 'Invalid date') {
                return value;
              } else {
                return result;
              }
            }
          }
        }
      }}
      series={
        title === UnitType.BloodPressure
          ? [
              {
                name: BloodPressureType.Systolic,
                data: chartYValues
              },
              {
                name: BloodPressureType.Diastolic,
                data: chartY2Values
              }
            ]
          : [
              {
                name: title,
                data: chartYValues
              }
            ]
      }
      type="bar"
      height={height}
    />
  );
};

export default BarChartSection;
