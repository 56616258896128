import styled from '@/styles';

export const Form = styled('form', {
  margin: '0',
  padding: '0'
});

export const HiddenFileInput = styled('input', {
  display: 'none'
});

export const ImageContainer = styled('div', {
  padding: '5px',
  borderBottom: '2px solid $truentityLigthGray',
  width: '100%',
  marginBottom: '10px'
});

export const ProgressBarContainer = styled('span', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& progress': {
    marginTop: '10px',
    marginBottom: '10px',
    width: '80%'
  }
});
