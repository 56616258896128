import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import InvoiceAdd from '@/components/InvoiceAdd';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import TimesheetAddEdit from '@/components/TimesheetAdd';
import SideMenuContext from '@/context/sideMenuContext';
import Icon from '@/elements/Icon';
import { CHANGE_TIMESHEET_STATUSES, DELETE_TIMESHEET, GET_TIMESHEETS, GET_TIMESHEETS_BY_ADMIN } from '@/graphql/timesheet';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { currentLoggedUserVar } from '@/util/apollo/cache';

import { formatDate, formatDateIgnoreTZ, secondsToTime } from '@/util/format';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { faArrowUpFromBracket, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Chip, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef, GridRowId, GridSortItem } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export enum TimesheetStatuses {
  Pending = 'pending',
  Submitted = 'submitted',
  Denied = 'denied',
  Approved = 'approved',
  Invoiced = 'invoiced',
  Paid = 'paid',
  InFinance = 'in finance'
}

export type TimesheetStatus =
  | TimesheetStatuses.Pending
  | TimesheetStatuses.Submitted
  | TimesheetStatuses.Denied
  | TimesheetStatuses.Approved
  | TimesheetStatuses.Invoiced
  | TimesheetStatuses.Paid;

export type SaveTimesheetData = {
  id?: string;
  datePerformed?: string | null;
  numberOfHours?: number;
  numberOfMinutes?: number;
  description?: string;
  clientOrganizationId?: string;
  clientStoreId?: string;
  status?: TimesheetStatus;
};

export type TimesheetData = {
  id: string;
  datePerformed?: string | null;
  numberOfHours: number;
  numberOfMinutes: number;
  description?: string;
  clientOrganization: {
    id: string;
    name: string;
  };
  clientStore: {
    id: string;
    name: string;
  };
  relyingPartyAdmin: {
    id: string;
    name: string;
  };
  status?: TimesheetStatus;
};

const Timesheets = () => {
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();
  const [timesheets, setTimesheets] = useState<TimesheetData[]>([]);
  const [filteredTimesheets, setFilteredTimesheets] = useState<TimesheetData[]>([]);

  const [getAllTimesheets, { loading: loadingTimesheet, refetch: refetchAll, data }] = useLazyQuery(GET_TIMESHEETS);
  const [getTimesheetByAdmin, { refetch: refetchByAdmin, data: dataByAdmin }] = useLazyQuery(GET_TIMESHEETS_BY_ADMIN);

  const [deleteTimesheet] = useMutation(DELETE_TIMESHEET);
  const [updateTimesheetStatuses] = useMutation(CHANGE_TIMESHEET_STATUSES);
  const [selectedTimesheet, setSelectedTimesheet] = useState<TimesheetData | null>(null);
  const [selectedTimesheets, setSelectedTimesheets] = useState<TimesheetData[]>([]);

  const [timesheetUsers, setTimesheetUsers] = useState<{ id: string; name: string }[]>([]);
  const [selectedTimesheetUserId, setSelectedTimesheetUserId] = useState<string>('');

  const { setContent } = useContext(SideMenuContext);

  const { roleType } = useToken();

  const [isPharmacist] = useState<boolean>(roleType === Role.PHARMACIST);

  const [initialColumnVisibilityModel] = useState({ user: !isPharmacist, actions: isPharmacist });

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'date',
      sort: 'desc'
    }
  ]);

  const [timesheetStatuses, setTimesheetStatuses] = useState<TextSelectOption[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(!isPharmacist ? TimesheetStatuses.Submitted : TimesheetStatuses.Pending);

  const [newStatus, setNewStatus] = useState<TimesheetStatuses.Approved | TimesheetStatuses.Denied | ''>('');

  const currentUser = useReactiveVar(currentLoggedUserVar);

  const { showModal } = useModal();

  const getPayPeriods = () => {
    const periods: TextSelectOption[] = new Array(13);
    const month = moment().startOf('month');
    // Tests
    // month = moment('2023-02-28 0:00:00').utc().startOf('month');
    // month = moment('2023-05-01 0:00:00').utc().startOf('month');

    let index = 0;
    const startOfMonth = month;

    const endOfCurrentMonth = startOfMonth.clone().add(1, 'month').subtract(1, 'day');
    let label = `${formatDate(startOfMonth.clone().add(15, 'days'))} - ${formatDate(endOfCurrentMonth)}`;
    periods[index++] = { label, value: label };

    const monthlyFirstPayPeriodEnd = startOfMonth.clone().add(14, 'days');
    label = `${formatDate(startOfMonth)} - ${formatDate(monthlyFirstPayPeriodEnd)}`;
    periods[index++] = { label, value: label };

    let current_index = 0;
    if (moment().isSameOrBefore(monthlyFirstPayPeriodEnd, 'day')) {
      current_index = 1;
    }

    for (let i = 0; i < 5; i++) {
      const prevMonth = month.clone().add(-(i + 1), 'month');
      const currentMonth = prevMonth.clone().add(1, 'month').subtract(1, 'day');
      label = `${formatDate(prevMonth.clone().add(15, 'days'))} - ${formatDate(currentMonth)}`;
      periods[index++] = { label, value: label };
      label = `${formatDate(prevMonth)} - ${formatDate(prevMonth.clone().add(14, 'days'))}`;
      periods[index++] = { label, value: label };
    }

    periods[index++] = { label: 'All', value: 'all' };

    return {
      periods: periods,
      current_index: current_index
    };
  };

  const payPeriodsInfo = getPayPeriods();
  const [selectedPayPeriod, setSelectedPayPeriod] = useState(payPeriodsInfo.periods[payPeriodsInfo.current_index].value);

  const showConfirmDialog = useCallback(
    (title, message, onAgree, onDisagree?) => {
      const modal = showModal(ConfirmDialog, {
        title,
        message,
        onAgree: () => {
          modal.hide();
          onAgree();
        },
        onDisagree: () => {
          modal.hide();
          if (onDisagree) {
            onDisagree();
          }
        }
      });
    },
    [showModal]
  );

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const { enqueueSnackbar } = useSnackbar();

  const refetch = useCallback(() => {
    if (isPharmacist) {
      refetchByAdmin();
    } else {
      refetchAll();
    }
  }, [isPharmacist, refetchAll, refetchByAdmin]);

  const getTimesheets = useCallback(() => {
    if (isPharmacist) {
      getTimesheetByAdmin({
        variables: {
          relyingPartyAdminId: currentUser?.id,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          status: selectedStatus,
          period: selectedPayPeriod
        }
      });
    } else {
      getAllTimesheets({
        variables: {
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          status: selectedStatus,
          period: selectedPayPeriod
        }
      });
    }
  }, [currentPage, currentUser?.id, getAllTimesheets, getTimesheetByAdmin, isPharmacist, selectedPayPeriod, selectedStatus]);

  const changeStatuses = useCallback(
    async (timesheetStatuses: { id: string; status: TimesheetStatus }[]) => {
      try {
        await updateTimesheetStatuses({
          variables: {
            timesheetStatuses
          }
        });

        refetch();
        enqueueSnackbar('Timesheet updated', { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Unable to update timesheet', { variant: 'error' });
      }
    },
    [enqueueSnackbar, refetch, updateTimesheetStatuses]
  );

  const callDeleteTimesheet = (timesheetId: string) => {
    showConfirmDialog('Delete Timesheet', 'Are you sure you want to delete this timesheet?', async () => {
      try {
        await deleteTimesheet({
          variables: {
            timesheetId
          }
        });

        refetch();
        enqueueSnackbar('Timesheet deleted', { variant: 'success' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Unable to delete timesheet', { variant: 'error' });
      }
    });
  };

  const callSubmitTimesheet = (id: string) => {
    showConfirmDialog('Submit Timesheet', 'This timesheet will be submitted for Review by your Manager. Continue?', () => {
      changeStatuses([{ id, status: TimesheetStatuses.Submitted }]);
      setSelectedStatus(TimesheetStatuses.Submitted);
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        sortable: true,
        type: 'string',
        flex: 1,
        valueGetter: params => formatDateIgnoreTZ(params.row.datePerformed, 'YYYY-MM-DD')
      },
      {
        field: 'time',
        headerName: 'Time',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          const hours = params.row.numberOfHours || 0;
          const minutes = params.row.numberOfMinutes || 0;

          return secondsToTime(hours * 3600 + minutes * 60);
        }
      },
      {
        field: 'customer',
        headerName: 'Customer',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          const orgName = params.row.clientOrganization?.name || '';
          const storeName = params.row.clientStore?.name || '';
          if (storeName) return orgName + ' - ' + storeName;
          else return orgName;
        }
      },
      {
        field: 'task',
        headerName: 'Task',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.description
      },
      {
        field: 'user',
        headerName: 'User',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return params.row.relyingPartyAdmin.name;
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        renderCell: params => <Chip sx={{ textTransform: 'capitalize' }} label={params.row.status} />
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        flex: 1,
        renderCell: params => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              {isPharmacist && params.row.status === TimesheetStatuses.Pending && (
                <>
                  <IconButton onClick={() => setSelectedTimesheet(params.row)}>
                    <Icon icon={faEdit} fixedWidth size="lg" />
                  </IconButton>

                  <IconButton onClick={() => callDeleteTimesheet(params.row.id)}>
                    <Icon icon={faTrashAlt} fixedWidth size="lg" />
                  </IconButton>

                  {isPharmacist && (
                    <IconButton onClick={() => callSubmitTimesheet(params.row.id)}>
                      <Icon icon={faArrowUpFromBracket} fixedWidth size="lg" />
                    </IconButton>
                  )}
                </>
              )}
            </Stack>
          );
        }
      }
    ],
    []
  );

  const refetchData = () => {
    refetch();
    setSelectedTimesheet(null);
  };

  const onTimesheetAdded = () => {
    refetchData();
    setSelectedStatus(TimesheetStatuses.Pending);
  };

  const onInvoiceAdded = () => {
    navigate('../invoices');
  };

  const onSubmitMultipleTimesheets = () => {
    changeStatuses(selectedTimesheets.map(timesheet => ({ id: timesheet.id, status: TimesheetStatuses.Submitted })));
  };

  useEffect(() => {
    if (newStatus && newStatus.toString() !== '') {
      showConfirmDialog(
        newStatus === TimesheetStatuses.Approved ? 'Approve Timesheets?' : 'Deny Timesheets?',
        'Are you sure you want to proceed?',
        () => {
          changeStatuses(selectedTimesheets.map(timesheet => ({ id: timesheet.id, status: newStatus })));
          setSelectedStatus(newStatus);
          setNewStatus('');
        }
      ),
        () => {
          setNewStatus('');
        };
    }
  }, [changeStatuses, newStatus, selectedTimesheets, showConfirmDialog]);

  useEffect(() => {
    let timesheetStatusesValues = Object.values(TimesheetStatuses).map(
      value => ({ value, label: value.toUpperCase() } as TextSelectOption)
    );

    if (!isPharmacist) {
      timesheetStatusesValues = timesheetStatusesValues.filter(v => v.value !== TimesheetStatuses.Pending);
    }

    setTimesheetStatuses(timesheetStatusesValues);
    setContent(<MainSideMenu />);
    getTimesheets();
  }, [getTimesheets, isPharmacist, setContent]);

  useEffect(() => {
    if (timesheets) {
      const map = new Map(filteredTimesheets.map(item => [item.relyingPartyAdmin.id, item.relyingPartyAdmin.name]));
      const uniqueUsers = Array.from(map).map(([id, name]) => ({ id, name }));
      setTimesheetUsers(uniqueUsers);
    }
  }, [selectedStatus, filteredTimesheets, timesheets]);

  useEffect(() => {
    if (data?.timesheetsGet) {
      setTimesheets(data.timesheetsGet.timesheets);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setTimesheets(data?.timesheetsGet?.timesheets || []);
      setRowCount(data?.timesheetsGet?.meta.totalCount || 0);
    }
  }, [data]);

  useEffect(() => {
    if (dataByAdmin?.timesheetsByRelyingPartyAdmin) {
      setTimesheets(dataByAdmin.timesheetsByRelyingPartyAdmin.timesheets);
      setRowCount(dataByAdmin.timesheetsByRelyingPartyAdmin?.meta.totalCount || 0);
    }
  }, [dataByAdmin]);

  useEffect(() => {
    if (timesheets) {
      let filteredData: TimesheetData[] = timesheets?.filter(v => v.status === selectedStatus);

      if (selectedTimesheetUserId?.length > 0) {
        filteredData = filteredData.filter(v => v.relyingPartyAdmin?.id === selectedTimesheetUserId);
      }

      if (selectedPayPeriod !== 'all') {
        const [start, end] = selectedPayPeriod.split(' - ');
        filteredData = filteredData.filter(v => moment(v.datePerformed).isBetween(new Date(start), new Date(end), undefined, '[]'));
      }
      setFilteredTimesheets(filteredData);
    }
  }, [timesheets, selectedStatus, selectedTimesheetUserId, selectedPayPeriod]);

  return (
    <Stack spacing={2}>
      <Stack alignItems={'center'} direction="row" justifyContent={'space-between'}>
        <Stack direction="row" alignItems={'center'}>
          <SelectList
            fullWidth={false}
            formControlProps={{ sx: { width: 250 } }}
            label="Status"
            options={timesheetStatuses}
            placeholder="Select an option..."
            value={selectedStatus}
            onChange={(event: any) => setSelectedStatus(event.target.value)}
          />

          <SelectList
            fullWidth={false}
            formControlProps={{ sx: { width: 250, marginLeft: 1 } }}
            label="Pay Periods"
            options={payPeriodsInfo.periods}
            placeholder="Select a pay period"
            value={selectedPayPeriod}
            onChange={(event: any) => setSelectedPayPeriod(event.target.value)}
          />

          {!isPharmacist && timesheets?.length > 1 && (
            <SelectList
              fullWidth={false}
              formControlProps={{ sx: { width: 250, marginLeft: 1 } }}
              label="User"
              options={timesheetUsers.map(
                item =>
                  ({
                    value: item.id,
                    label: item.name.toUpperCase()
                  } as TextSelectOption)
              )}
              placeholder="Select an option..."
              value={selectedTimesheetUserId}
              clearFunction={() => setSelectedTimesheetUserId('')}
              onChange={(event: any) => setSelectedTimesheetUserId(event.target.value)}
            />
          )}
        </Stack>

        <Stack direction="row" spacing={1}>
          {isPharmacist && selectedTimesheets.length > 0 && selectedStatus === TimesheetStatuses.Pending && (
            <Button label="Submit Timesheets" onClick={() => onSubmitMultipleTimesheets()} />
          )}
          {isPharmacist && selectedTimesheets.length > 0 && selectedStatus === TimesheetStatuses.Approved && (
            <InvoiceAdd onDone={onInvoiceAdded} selectedTimesheets={selectedTimesheets} />
          )}
          {isPharmacist && <TimesheetAddEdit onDone={onTimesheetAdded} selectedTimesheet={selectedTimesheet} />}
          {!isPharmacist && selectedTimesheets.length > 0 && (
            <SelectList
              fullWidth={false}
              formControlProps={{ sx: { width: 250 } }}
              label="Change Status"
              options={[
                { value: TimesheetStatuses.Approved, label: 'APPROVE' },
                { value: TimesheetStatuses.Denied, label: 'DENY' }
              ]}
              placeholder="Select an option..."
              value={newStatus}
              onChange={(event: any) => setNewStatus(event.target.value)}
            />
          )}
        </Stack>
      </Stack>

      <Paper component={Stack} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-timesheets'}
            initialState={{
              columns: {
                columnVisibilityModel: initialColumnVisibilityModel
              }
            }}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            autoHeight
            rows={filteredTimesheets}
            columns={columns}
            loading={loadingTimesheet}
            sortModel={sortModel}
            rowCount={rowCountState}
            onSortModelChange={model => setSortModel(model)}
            paginationMode="server"
            disableRowSelectionOnClick
            checkboxSelection={
              (isPharmacist && selectedStatus === TimesheetStatuses.Approved) ||
              (isPharmacist && selectedStatus === TimesheetStatuses.Pending) ||
              (!isPharmacist && selectedStatus === TimesheetStatuses.Submitted)
            }
            onRowSelectionModelChange={ids => {
              const selectedIDs = new Set(ids);
              const selectedRowData = timesheets.filter(timesheet => selectedIDs.has(timesheet.id as GridRowId));
              setSelectedTimesheets(selectedRowData);
            }}
            rowSelectionModel={selectedTimesheets.map(t => t.id)}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Timesheets;
