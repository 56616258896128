import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ManageAnnouncementDialog from '@/components/Dialogs/ManageAnnouncementDialog';
import { DELETE_ANNOUNCEMENT, GET_ALL_ANNOUNCEMENTS, GET_USER_ANNOUNCEMENT } from '@/graphql/announcements';
import type { GetUserAnnouncementsResponse } from '@/types/announcement';
import { AnnouncementType, type Announcement, type GetAllAnnouncementsResponse } from '@/types/announcement';
import { formatDateAndTime } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, IconButton, Stack } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  isAllAnnouncement?: boolean;
  isSuperAdmin: boolean;
  triggerRefetch: boolean;
  handleTriggerRefetch: () => void;
};

const AnnouncementGrid = ({ isAllAnnouncement = false, isSuperAdmin, triggerRefetch }: Props) => {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    title: true,
    type: true,
    startTime: true,
    endTime: true,
    isRead: isSuperAdmin,
    alwaysShow: !isSuperAdmin,
    action: true
  });

  const query = isAllAnnouncement ? GET_ALL_ANNOUNCEMENTS : GET_USER_ANNOUNCEMENT;
  const { data, loading, refetch } = useQuery<GetAllAnnouncementsResponse | GetUserAnnouncementsResponse>(query, {
    variables: {
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);

  const rows = isAllAnnouncement
    ? (data as GetAllAnnouncementsResponse)?.getAllAnnouncements?.announcements || []
    : (data as GetUserAnnouncementsResponse)?.getRelyingPartyAdminsAnnouncements?.announcements || [];

  const rowCount = isAllAnnouncement
    ? (data as GetAllAnnouncementsResponse)?.getAllAnnouncements?.meta?.totalCount || 0
    : (data as GetUserAnnouncementsResponse)?.getRelyingPartyAdminsAnnouncements?.meta?.totalCount || 0;

  const handleEditAnnouncement = useCallback(
    (announcement: Announcement) => {
      const modal = showModal(ManageAnnouncementDialog, {
        title: 'Update Announcement',
        announcement,
        hideDialog: () => modal.hide(),
        onSuccess: () => {
          refetch();
          modal.hide();
        }
      });
    },
    [refetch, showModal]
  );

  const handleDeleteAnnouncement = useCallback(
    (id: GridRowId) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Delete Announcement',
        message: 'Are you sure you want to delete this announcement?',
        onAgree: () => {
          deleteAnnouncement({ variables: { announcementId: id } })
            .then(res => {
              enqueueSnackbar(res.data.deleteAnnouncement.message, { variant: 'success' });
              refetch();
              modal.hide();
            })
            .catch(err => {
              if (err.graphQLErrors && err.graphQLErrors[0]) {
                enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
              } else {
                enqueueSnackbar('Unable to delete the announcement. Please try again later.', { variant: 'error' });
              }
              modal.hide();
            });
        },
        onDisagree: () => modal.hide()
      });
    },
    [showModal, deleteAnnouncement, enqueueSnackbar, refetch]
  );

  const columns: GridColDef<Announcement>[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        description: 'Title',
        sortable: true,
        minWidth: 180,
        flex: 1,
        align: 'left'
      },
      {
        field: 'type',
        headerName: 'Type',
        description: 'Type',
        sortable: true,
        minWidth: 160,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => <Chip label={params.value} color={params.value === AnnouncementType.SECONDARY ? 'info' : 'success'} />
      },

      {
        field: 'startTime',
        headerName: 'Start At',
        description: 'Start At',
        sortable: true,
        minWidth: 163,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => formatDateAndTime(params.value)
      },
      {
        field: 'endTime',
        headerName: 'End At',
        description: 'End At',
        sortable: true,
        minWidth: 163,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => formatDateAndTime(params.value)
      },
      {
        field: 'alwaysShow',
        headerName: 'Always visible',
        description: 'Always visible at time period',
        sortable: true,
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          if (!params.value) return <CheckCircleOutline color="disabled" />;
          return <CheckCircle color="success" />;
        }
      },
      {
        field: 'isActive',
        headerName: 'Is Active',
        sortable: true,
        flex: 1,
        minWidth: 80,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params?.row?.isActive || false,
        renderCell: params => {
          if (params.value) return <CheckCircleIcon fontSize="small" color="success" />;
          return <CheckCircleOutline fontSize="small" />;
        }
      },
      {
        field: 'isRead',
        headerName: 'Read Status',
        sortable: true,
        flex: 1,
        minWidth: 80,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params?.row?.currentUserRecipient?.isRead || false,
        renderCell: params => <DoneAllIcon fontSize="small" color={params.value ? 'info' : 'disabled'} />
      },
      {
        field: 'action',
        headerName: 'Actions',
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: params => {
          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton size="small" onClick={() => handleEditAnnouncement(params.row)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={() => handleDeleteAnnouncement(params.id)}>
                <DeleteOutlineIcon fontSize="small" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [handleDeleteAnnouncement, handleEditAnnouncement]
  );

  useEffect(() => {
    setColumnVisibilityModel(prevModel => ({
      ...prevModel,
      isRead: !isAllAnnouncement,
      alwaysShow: isAllAnnouncement,
      action: isSuperAdmin
    }));
  }, [isSuperAdmin, isAllAnnouncement]);

  useEffect(() => {
    if (triggerRefetch) {
      refetch();
    }
  }, [triggerRefetch, refetch]);

  return (
    <>
      <TruentityDataGrid
        name={isAllAnnouncement ? 'dg-all-announcements' : 'dg-user-announcements'}
        autoHeight
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        loading={loading}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        paginationMode="server"
        rowSelection={false}
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
      />
    </>
  );
};

export default AnnouncementGrid;
