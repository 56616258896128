import Typography from '@mui/material/Typography';

export const H1 = ({ ...props }) => {
  return <Typography color={'textPrimary'} fontWeight={'600'} variant="h1" component="div" {...props} />;
};

export const H2 = ({ ...props }) => {
  return <Typography color={'textPrimary'} fontWeight={'600'} variant="h2" component="div" {...props} />;
};

export const H3 = ({ ...props }) => {
  return <Typography color={'textPrimary'} fontWeight={'600'} variant="h3" component="div" {...props} />;
};

export const H4 = ({ ...props }) => {
  return <Typography color={'textPrimary'} fontWeight={'600'} variant="h4" component="div" {...props} />;
};

export const H5 = ({ ...props }) => {
  return <Typography color={'textPrimary'} fontWeight={'600'} variant="h5" component="div" {...props} />;
};

export const H6 = ({ ...props }) => {
  return <Typography color={'textPrimary'} fontWeight={'600'} variant="h6" component="div" {...props} />;
};

export const Body1 = ({ ...props }) => {
  return <Typography color={'textPrimary'} variant="body1" component="div" {...props} />;
};

export const Body2 = ({ ...props }) => {
  return <Typography color={'textPrimary'} variant="body2" component="div" {...props} />;
};

export const Small = ({ ...props }) => {
  return <Typography color={'textPrimary'} variant="caption" component="div" {...props} />;
};

export const Caption = ({ ...props }) => {
  const textTransform = props?.textTransform ?? 'uppercase';
  return <Typography color={'textPrimary'} sx={{ textTransform: textTransform }} variant="caption" component="div" {...props} />;
};

export const Caption2 = ({ ...props }) => {
  return (
    <Typography
      color={'textPrimary'}
      sx={{ textTransform: 'captilize', fontWeight: '80', fontSize: '0.83rem' }}
      variant="caption"
      component="div"
      {...props}
    />
  );
};

export const Label = ({ ...props }) => {
  return <Typography fontWeight={'bold'} color={'#777777'} variant="body2" component="label" {...props} />;
};

export const LogText = ({ ...props }) => {
  return <Typography color={'#737373'} fontWeight={'600'} variant="h6" component="div" {...props} />;
};

export const Subtitle = ({ ...props }) => {
  return <Typography variant="subtitle1" fontSize={'medium'} component="div" {...props} />;
};
