import Button from '@/components/Button';
import { Avatar, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack } from '@mui/material';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import { useQuery, useReactiveVar } from '@apollo/client';
import type { GetUserCompaniesResponse } from '@/graphql/company';
import { GET_USER_COMPANIES } from '@/graphql/company';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { useModal } from 'mui-modal-provider';
import { useSwitchCompanyStore } from '@/zustand/SwitchCompanyStore';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Body1 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { currentLoggedUserVar } from '@/util/apollo/cache';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

const SwitchCompaniesDialog = ({ hideDialog, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { setCurrentCompany, setNewCompany } = useSwitchCompanyStore();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const navigate = useNavigate();

  const {
    data: userCompanies,
    loading: userCompaniesLoading,
    error: userCompaniesError
  } = useQuery<GetUserCompaniesResponse>(GET_USER_COMPANIES, {
    variables: {
      hideCurrent: true
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (userCompaniesError) {
      enqueueSnackbar(userCompaniesError.message ?? 'Could not load User Companies', { variant: 'error' });
    }
  }, [enqueueSnackbar, userCompaniesError]);

  const handleSwitchCompany = ({ companyId, companyName }: { companyId: string; companyName: string }) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm New Login',
      message: `This will sign you out of <b>${currentUser?.relyingParty?.name ?? 'current company'}</b> and sign you in to <b>${companyName ?? 'new company'}</b>. Do you wish to proceed?`,
      onAgree: () => {
        // navigate to intermediate screen
        setNewCompany({
          id: companyId,
          name: companyName
        });
        setCurrentCompany({
          id: currentUser?.relyingParty?.id ?? '',
          name: currentUser?.relyingParty?.name ?? ''
        });
        hideDialog();
        modal.hide();
        navigate('/switch-company');
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  return (
    <BaseDialog {...props} hideDialog={hideDialog} fullWidth maxWidth={'sm'}>
      <DialogContent>
        <Stack spacing={1}>
          <Stack direction={'column'} spacing={1}>
            Select a Company to Sign In
          </Stack>
          {userCompaniesLoading ? (
            <Stack direction={'column'} justifyContent={'center'} width={'100%'} alignItems={'center'}>
              <CircularProgress
                size={20}
                sx={{
                  margin: 2
                }}
              />
              <Body1> Loading companies for user </Body1>
            </Stack>
          ) : (
            <Stack
              component={Paper}
              direction={'column'}
              spacing={1}
              justifyContent={'flex-start'}
              alignItems={'center'}
              sx={{
                maxHeight: 400,
                minWidth: 360,
                overflow: 'auto',
                background: color.white,
                alignSelf: 'center'
              }}
            >
              <List sx={{ width: '100%', height: '100%' }}>
                {userCompanies?.getUserRelyingParties?.relyingParties?.map(company => (
                  <ListItem
                    key={company?.id}
                    onClick={() => {
                      handleSwitchCompany({
                        companyId: company?.id,
                        companyName: company?.name
                      });
                    }}
                    alignItems="flex-start"
                    sx={{
                      '&:hover': {
                        backgroundColor: color.grey100,
                        cursor: 'pointer'
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt={company?.name} src={company?.logoUrl} />
                    </ListItemAvatar>
                    <ListItemText primary={company?.name} />
                  </ListItem>
                ))}
              </List>
            </Stack>
          )}
          <Stack direction="row" spacing={2} alignContent="center" justifyContent={'flex-end'}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                hideDialog();
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default SwitchCompaniesDialog;
