import Button from '@/components/Button';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import type { CreateScheduledRPMPerformanceReportResponse } from '@/graphql/administration';
import { CREATE_SCHEDULED_RPM_PERFORMANCE_REPORT } from '@/graphql/administration';
import ScheduledReportsList from '@/routes/Administration/Components/ScheduledReportsList';
import { color } from '@/styles/assets/colors';
import { ScheduledReportTypeEnum } from '@/types/administration';
import { formatTime, getCurrentDate } from '@/util/format';
import { useMutation } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const RpmCompanies = () => {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [monthAndYear, setMonthAndYear] = useState<string>(formatTime(getCurrentDate(), 'MMM YYYY'));
  const [triggerReFetch, setTriggerReFetch] = useState<boolean>(false);

  const [createScheduledReport, { loading: createScheduledReportLoading }] = useMutation<CreateScheduledRPMPerformanceReportResponse>(
    CREATE_SCHEDULED_RPM_PERFORMANCE_REPORT
  );

  const scheduleRPMPerformanceReport = async () => {
    try {
      const response = await createScheduledReport({
        variables: {
          monthYear: monthAndYear
        }
      });

      if (response?.data?.createRelyingPartiesPerformanceReport?.status === 'Success') {
        enqueueSnackbar('RPM Performance Report scheduled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to schedule RPM Performance Report', { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Failed to schedule RPM Performance Report', { variant: 'error' });
    } finally {
      setTriggerReFetch(true);
    }
  };

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const onScheduleReportClicked = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Schedule Report',
      message: `This will schedule to generate a RPM performance report for the month year: <b>${monthAndYear}</b>. Are you sure you want to proceed?`,
      onAgree: async () => {
        await scheduleRPMPerformanceReport();
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      maxWidth: 'md'
    });
  };

  return (
    <Stack spacing={2}>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          padding: 2,
          backgroundColor: color.paper,
          borderRadius: '8px',
          width: '100%',
          height: 'auto'
        }}
      >
        <Stack width={'auto'}>
          <TruentityDatePicker
            showMonth={true}
            showYear={true}
            slotProps={{
              actionBar: {
                actions: ['accept']
              }
            }}
            openTo="month"
            views={['month', 'year']}
            value={monthAndYear}
            onChange={handleMonthYear}
          />
        </Stack>
        <Button type="button" size="small" onClick={onScheduleReportClicked} isLoading={createScheduledReportLoading}>
          Schedule Report
        </Button>
      </Stack>
      <Paper component={Stack} direction="column" width="100%" height="auto" padding={2} spacing={1}>
        <ScheduledReportsList
          triggerReFetch={triggerReFetch}
          setTriggerReFetch={setTriggerReFetch}
          reportType={ScheduledReportTypeEnum.RPM_PERFORMANCE_REPORT}
        />
      </Paper>
    </Stack>
  );
};

export default RpmCompanies;
