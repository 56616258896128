import Button from '@/components/Button';
import ChipsList from '@/components/ChipsList';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { TaskCompleteBillingDialog } from '@/components/Dialogs';
import AssignTasksToPharmacistDialog from '@/components/Dialogs/AssignTasksToPharmacistDialog';
import type { Priority } from '@/components/Priority/Priority';
import PriorityMenu from '@/components/Priority/Priority';
import SplitButton from '@/components/SplitButton';
import { Body1, H4, H5 } from '@/components/Typography';
import type { GetTasksEncountersActivityLogsResponse, SaveUpdateTaskResponse } from '@/graphql/taskEncounter';
import { GET_TASKS_ENCOUNTERS_ACTIVITY_LOGS, SAVE_UPDATE_TASK } from '@/graphql/taskEncounter';
import { EncounterInfo } from '@/routes/PatientDetails/EncounterInfo';
import LogView from '@/routes/PatientDetails/logView';
import type { EncounterType, TaskType } from '@/types/graphql';
import {
  DISPOSITION_TYPES,
  TASK_MODE_OF_RESOLUTION_TYPES,
  TASK_TYPES,
  TASK_WRAP_UP_TYPES,
  UPDATED_SUB_TASK_TYPES
} from '@/types/medication';
import type { LabelValuePair } from '@/types/misc';
import { formatDateIgnoreTZ, formatDateTimeIgnoreTZ } from '@/util/format';
import { unknown } from '@/util/string';
import { useLazyQuery, useMutation } from '@apollo/client';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import moment from 'moment/moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EncounterTabContent = (encounter: EncounterType) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <EncounterInfo
          title="Disposition"
          value={encounter?.disposition ? DISPOSITION_TYPES.filter(type => type.value === encounter?.disposition)[0]?.label : ''}
        />
      </Grid>

      <Grid item xs={6}>
        <EncounterInfo
          title="Mode Of Resolution"
          value={
            TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === encounter?.modeOfResolution).length > 0
              ? TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === encounter?.modeOfResolution)[0].label
              : ''
          }
        />
      </Grid>

      <Grid item xs={6}>
        <EncounterInfo title="Type" value={encounter.type} />
      </Grid>

      <Grid item xs={6}>
        <EncounterInfo title="Notes" value={encounter.notes} />
      </Grid>

      <Grid item xs={6}>
        <EncounterInfo title="Called On" dateField={true} value={encounter.calledOn?.toString()} />
      </Grid>
    </Grid>
  );
};

const TaskDrawer = ({
  reloadTasks,
  reloadAllTasks,
  data,
  closeTaskDrawer,
  isLogViewVisible,
  setIsLogViewVisible,
  isProviderAdmin,
  relyingPartyAdmins,
  tasksEncountersLogs,
  setTasksEncountersLogs
}) => {
  const { id } = useParams();
  const [taskDrawerInfo, setTaskDrawerInfo] = useState<TaskType>(data);
  const { showModal } = useModal();
  const enqueueSnackbar = useSnackbar();
  const [saveUpdateTask] = useMutation<SaveUpdateTaskResponse>(SAVE_UPDATE_TASK);
  const [getLogsForTasksEncounters] = useLazyQuery<GetTasksEncountersActivityLogsResponse>(GET_TASKS_ENCOUNTERS_ACTIVITY_LOGS);

  const callSaveUpdateTask = useCallback(
    (task: Partial<TaskType>) => {
      try {
        return saveUpdateTask({
          variables: {
            taskData: {
              ...task
            },
            truentityId: id,
            taskId: taskDrawerInfo?.id
          }
        });
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Unable to update task', { variant: 'error' });
      }
    },
    [enqueueSnackbar, id, saveUpdateTask, taskDrawerInfo?.id]
  );

  const onShowBillingDialog = (e: SyntheticEvent) => {
    e.preventDefault();

    showBillingDialog()
      .then(submitted => {
        if (submitted) {
          reloadTasks();
        }
      })
      .catch(err => console.error(err));
  };

  const showBillingDialog = () => {
    return new Promise(resolve => {
      if (id) {
        const modal = showModal(TaskCompleteBillingDialog, {
          title: 'Add Amount Billed',
          truentityId: id,
          taskDrawerInfo,
          onSubmit: () => {
            modal.hide();
            resolve(true);
          },
          hideDialog: () => {
            modal.hide();
            resolve(false);
          }
        });
      }
    });
  };

  const handleOnSubmitWrapUpStatus = (selectedWrapUpStatus: LabelValuePair) => {
    const status = selectedWrapUpStatus.value === 'Completed' ? 'COMPLETED' : 'ATTEMPTED';
    if (status !== 'COMPLETED') {
      const task = {
        wrapUpStatus: selectedWrapUpStatus.value,
        status: status,
        performedOn: moment().format('YYYY-MM-DD')
      };

      callSaveUpdateTask(task)
        .then(reloadAllTasks)
        .catch(err => console.error(err));
    } else if (Number(taskDrawerInfo?.billedAmount) === 0) {
      showBillingDialog()
        .then(submitted => {
          if (submitted) {
            return callSaveUpdateTask({
              wrapUpStatus: 'Completed',
              status: 'COMPLETED',
              performedOn: moment().format('YYYY-MM-DD')
            });
          }
        })
        .then(reloadTasks)
        .then(() => closeTaskDrawer({ forEdit: false }))
        .catch(err => console.error(err));
    }
  };

  const handleUpdatePriority = async (newPriority: Priority) => {
    try {
      const task = {
        priority: newPriority
      };

      const response = await callSaveUpdateTask(task);
      if (response?.data?.saveUpdateTask?.status === 'Success') {
        await reloadTasks();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchActivityLogsForTasks = (taskId: string) => {
    getLogsForTasksEncounters({
      variables: {
        truentityId: id,
        pageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        taskId
      }
    })
      .then(response => {
        if (response.data?.tasksEncountersLogs?.tasksEncountersActivityLogs) {
          setTasksEncountersLogs(response.data.tasksEncountersLogs.tasksEncountersActivityLogs);
        }
      })
      .catch(console.error);
  };

  const handleOnSubmitModeOfResolution = async (selectedModeOfResolution: LabelValuePair) => {
    try {
      const task = {
        modeOfResolution: selectedModeOfResolution.value
      };

      const response = await callSaveUpdateTask(task);

      if (response?.data?.saveUpdateTask?.status === 'Success') {
        await reloadTasks();
        return true;
      }
    } catch (error) {
      console.error(error);
    }

    return false;
  };

  const showAssignTaskToPharmacistDialog = () => {
    const modalRef = showModal(AssignTasksToPharmacistDialog, {
      title: 'Assign to',
      hideDialog: () => {
        reloadTasks().finally(() => modalRef.hide());
      },
      relyingPartyAdmins: relyingPartyAdmins,
      taskIds: [taskDrawerInfo.id],
      selectedAdmins: taskDrawerInfo.relyingPartyAdmins
    });
  };

  useEffect(() => {
    setTaskDrawerInfo(data);
  }, [data]);

  return (
    <Drawer
      PaperProps={{
        sx: { width: '40%' }
      }}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      anchor={'right'}
      open={Object.keys(taskDrawerInfo).length !== 0}
      onClose={() => closeTaskDrawer({ forEdit: false })}
      variant="temporary"
      onBackdropClick={() => closeTaskDrawer({ forEdit: false })}
    >
      <Box sx={{ margin: 2 }} role="presentation">
        <Grid container spacing={1} pb={2}>
          <Grid item xs={8}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <SplitButton
                options={TASK_MODE_OF_RESOLUTION_TYPES}
                value={
                  (taskDrawerInfo?.encounters?.length > 0
                    ? taskDrawerInfo?.encounters[taskDrawerInfo?.encounters?.length - 1].modeOfResolution
                    : taskDrawerInfo?.modeOfResolution) || unknown()
                }
                handleOnSubmit={handleOnSubmitModeOfResolution}
              />

              <SplitButton
                options={TASK_WRAP_UP_TYPES}
                value={taskDrawerInfo?.wrapUpStatus}
                handleOnSubmit={handleOnSubmitWrapUpStatus}
                disabled={taskDrawerInfo?.encounters?.length === 0}
              />

              <Tooltip open={true} sx={{ zIndex: theme => theme.zIndex.drawer + 10 }} title="Attach Billed Amount">
                <div>
                  <IconButton
                    disabled={taskDrawerInfo?.wrapUpStatus?.length === 0}
                    sx={{ border: taskDrawerInfo?.wrapUpStatus?.length === 0 ? '1px solid lightGrey' : '1px solid #20416e' }}
                    size="small"
                    onClick={onShowBillingDialog}
                  >
                    <AttachMoneyIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack direction="row" alignContent={'center'} justifyContent={'flex-end'} spacing={1}>
              <Button
                startIcon={isLogViewVisible ? <ArrowBackIosNewIcon /> : ''}
                size="small"
                sx={{ fontSize: 12 }}
                variant={'contained'}
                disableElevation
                disabled={isProviderAdmin}
                onClick={() => {
                  setIsLogViewVisible(!isLogViewVisible);
                  fetchActivityLogsForTasks(taskDrawerInfo?.id);
                }}
              >
                {isLogViewVisible ? 'Task' : 'Logs'}
              </Button>
            </Stack>
          </Grid>
        </Grid>

        {isLogViewVisible && <LogView tasksEncountersLogs={tasksEncountersLogs} />}

        {!isLogViewVisible && (
          <Card sx={{ minWidth: 275, marginTop: '18px' }} variant="outlined">
            <CardHeader
              title={
                <>
                  <Stack direction="row" sx={{ justifyContent: 'start', alignItems: 'center' }} spacing={1}>
                    <PriorityMenu priority={taskDrawerInfo?.priority as Priority} setPriority={handleUpdatePriority} />

                    <Chip label={taskDrawerInfo?.status} color="success" variant="outlined" />

                    <Stack pl={1} direction="row" spacing={1} alignItems={'center'}>
                      <Body1>Assigned To: </Body1>
                      <ChipsList
                        onClick={() => showAssignTaskToPharmacistDialog()}
                        items={
                          taskDrawerInfo?.relyingPartyAdmins?.length > 0 ? taskDrawerInfo?.relyingPartyAdmins?.map(v => v?.name) : ['+']
                        }
                      />
                    </Stack>
                  </Stack>
                </>
              }
              action={
                <Stack direction={'row'} spacing={1}>
                  <IconButton
                    onClick={() => {
                      closeTaskDrawer({ forEdit: true });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton aria-label="Close" onClick={() => closeTaskDrawer({ forEdit: false })}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
              }
            ></CardHeader>

            <CardContent>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Chip
                  variant={'filled'}
                  sx={{ textTransform: 'uppercase', letterSpacing: 1 }}
                  label={
                    taskDrawerInfo?.type
                      ? `${TASK_TYPES.filter(type => type.value === taskDrawerInfo?.type)[0].label} ${taskDrawerInfo?.subType ? ' : ' : ''}
                  ${
                    taskDrawerInfo?.subType && UPDATED_SUB_TASK_TYPES.filter(type => type.value === taskDrawerInfo?.subType).length > 0
                      ? UPDATED_SUB_TASK_TYPES.filter(type => type.value === taskDrawerInfo?.subType)[0].label
                      : ''
                  }            
                  `
                      : '---'
                  }
                  color={'info'}
                  size="small"
                />
              </Stack>

              <Grid container spacing={1} pb={1} pt={1} mt={3} mb={3} key={'grid-112'}>
                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Performed On'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo && taskDrawerInfo?.performedOn ? formatDateIgnoreTZ(taskDrawerInfo?.performedOn) : '---'}</Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Performed By'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo && taskDrawerInfo?.completedByAdmin ? taskDrawerInfo?.completedByAdmin : '---'}</Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Medication'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo?.medicationName ? taskDrawerInfo?.medicationName : '---'}</Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Billed Amount'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo?.billedAmount ? `$ ${taskDrawerInfo?.billedAmount}` : '---'}</Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Mode Of Resolution'}</strong>
                    </Body1>
                    <Body1>
                      {taskDrawerInfo?.modeOfResolution
                        ? TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === taskDrawerInfo?.modeOfResolution)[0].label
                        : '---'}
                    </Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Wrap Up'}</strong>
                    </Body1>
                    <Body1>
                      {taskDrawerInfo?.wrapUpStatus
                        ? TASK_WRAP_UP_TYPES.filter(type => type.value === taskDrawerInfo?.wrapUpStatus)[0].label
                        : '---'}
                    </Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'# of Encounters'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo?.encounters?.length}</Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Health Plan'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo?.healthPlan ? taskDrawerInfo?.healthPlan : '---'}</Body1>
                  </Stack>
                </Grid>

                <Grid item xs={4}>
                  <Stack sx={{ marginBottom: '20px' }}>
                    <Body1>
                      <strong>{'Notes'}</strong>
                    </Body1>
                    <Body1>{taskDrawerInfo?.name ? `${taskDrawerInfo?.name}` : '---'}</Body1>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {!isLogViewVisible && taskDrawerInfo?.encounters?.length === 0 && (
          <Stack
            direction="row"
            alignContent={'center'}
            spacing={2}
            sx={{ marginTop: 3, marginBottom: '8px', alignItems: 'center', justifyContent: 'center' }}
          >
            <H5 color="default" weight="medium" css={{ marginLeft: '3px', fontStyle: 'italic' }}>
              No Encounters Assigned
            </H5>
          </Stack>
        )}

        {!isLogViewVisible && (
          <Stack sx={{ marginTop: 3 }}>
            {taskDrawerInfo?.encounters?.length > 0 && (
              <Stack
                direction="row"
                alignContent={'center'}
                spacing={2}
                sx={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <H5 color="default" weight="medium" css={{ marginLeft: '3px', fontStyle: 'italic' }}>
                  Encounters
                </H5>
              </Stack>
            )}
            {taskDrawerInfo?.encounters?.length > 0 &&
              taskDrawerInfo?.encounters?.map((row, index) => (
                <Accordion key={row.id} defaultExpanded={index === 0}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={'{option.label}-header-content-{index}'}
                    id={'{option.label}-header-{index}'}
                  >
                    <H4>{formatDateTimeIgnoreTZ(row.calledOn)}</H4>
                  </AccordionSummary>

                  <AccordionDetails
                    sx={{
                      backgroundColor: '#fafafa',
                      padding: '30px'
                    }}
                  >
                    {EncounterTabContent(row)}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default TaskDrawer;
