import { getDaysBeforeDate } from '@/util/date';
import { formatDate, formatDateIgnoreTZ } from '@/util/format';
import { useState } from 'react';

interface UseDateRangeHook {
  startDate: string;
  endDate: string;
  handleDateChange: (newStartDate: Date, newEndDate: Date) => void;
}

export type UseDateRangeProps = {
  datesGap?: number;
};

const useDateRange = ({ datesGap = 7 }: UseDateRangeProps): UseDateRangeHook => {
  const [startDate, setStartDate] = useState<string>(formatDate(getDaysBeforeDate(new Date(), datesGap), 'YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string>(formatDate(new Date(), 'YYYY-MM-DD'));

  const handleDateChange = (newStartDate: Date, newEndDate: Date): void => {
    if (formatDateIgnoreTZ(newStartDate, 'YYYY-MM-DD') !== formatDateIgnoreTZ(startDate, 'YYYY-MM-DD')) {
      setStartDate(formatDateIgnoreTZ(newStartDate, 'YYYY-MM-DD'));
    }
    if (formatDateIgnoreTZ(newEndDate, 'YYYY-MM-DD') !== formatDateIgnoreTZ(endDate, 'YYYY-MM-DD')) {
      setEndDate(formatDateIgnoreTZ(newEndDate, 'YYYY-MM-DD'));
    }
  };

  return {
    startDate,
    endDate,
    handleDateChange
  };
};

export default useDateRange;
