import { Backdrop, Box, Stack, SvgIcon } from '@mui/material';
import { useState } from 'react';

type Props = {
  src: string;
  children?: React.ReactNode;
};

const ZoomableImage = ({ src, children }: Props) => {
  const [zoomIn, setZoomIn] = useState(false);

  return (
    <>
      <Backdrop
        onClick={() => setZoomIn(false)}
        component={Stack}
        spacing={2}
        open={zoomIn}
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: '#000000c2',
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <Box
          sx={{
            zIndex: theme => theme.zIndex.drawer + 2,
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            height: '40px',
            padding: '10px',
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#1e1e24cc',
            color: '#ffffffcc'
          }}
        >
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="11 11 30 30">
              <path d="M27.92 25l8.84-8.84 1.82-1.82c.27-.27.27-.71 0-.97l-1.95-1.95a.682.682 0 0 0-.97 0L25 22.08 14.34 11.42a.682.682 0 0 0-.97 0l-1.95 1.95c-.27.27-.27.71 0 .97L22.08 25 11.42 35.66c-.27.27-.27.71 0 .97l1.95 1.95c.27.27.71.27.97 0L25 27.92l8.84 8.84 1.82 1.82c.27.27.71.27.97 0l1.95-1.95c.27-.27.27-.71 0-.97L27.92 25z"></path>
            </svg>
          </SvgIcon>
        </Box>

        <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh' }}>
          <Box
            component="img"
            src={src}
            sx={{
              position: 'relative',
              display: 'block',
              maxWidth: '80%',
              maxHeight: '80%',
              width: 'auto',
              height: 'auto',
              outline: 'none',
              userSelect: 'none',
              margin: 'auto'
            }}
          />
        </Stack>
      </Backdrop>
      <Stack sx={{ alignItems: 'flex-start' }}>
        <Box
          component="img"
          sx={{
            objectFit: 'contain',
            height: '12.5rem',
            cursor: 'pointer',
            marginBottom: '1.25rem'
          }}
          src={src}
          onClick={() => setZoomIn(true)}
        />
      </Stack>

      {children}
    </>
  );
};

export default ZoomableImage;
