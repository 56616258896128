import ClickThroughAgreementDialog from '@/components/Dialogs/ClickThroughAgreementDialog';
import { H1 } from '@/components/Typography';
import { useIdleModal } from '@/hooks/useIdleModal';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import type React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const Agreement: React.FC = () => {
  const navigate = useNavigate();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { roleType, handleLogout } = useToken();

  const { idleTimer } = useIdleModal({
    handleLogout: handleLogout,
    timeoutOverrideInSeconds: 600,
    promptTimeoutOverrideInSeconds: 60
  });

  useEffect(() => {
    if (currentUser?.relyingParty?.isCtaAccepted || roleType !== Role.PHARMACIST) {
      navigate('/', { replace: true });
    } else {
      idleTimer.start();
    }
  }, [currentUser, roleType]);

  return (
    <Grid container spacing={4} p={2}>
      <Grid item xs={12}>
        <ClickThroughAgreementDialog
          title={''}
          disableBackdropClick={true}
          headerElement={
            <Box
              sx={{
                flex: 1,
                m: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <H1>Terms of Agreement</H1>
            </Box>
          }
          open={true}
        />
      </Grid>
    </Grid>
  );
};

export default Agreement;
