import type { GeneralDetailsForm } from '@/components/Dialogs/ManageAnnouncementDialog';
import SelectList from '@/components/SelectList';
import TruentityDateTimePicker from '@/components/TruentityDateTimePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { H3 } from '@/components/Typography';
import { AnnouncementType } from '@/types/announcement';
import { getCurrentDate } from '@/util/format';
import { FormControlLabel, Stack, Switch } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type Props = {
  control: Control<GeneralDetailsForm, unknown>;
  isUpdateAnnouncement: boolean;
};

const GeneralAnnouncementDetailsForm = ({ control, isUpdateAnnouncement }: Props) => {
  return (
    <Stack component="form" gap={2}>
      <H3>General Details</H3>
      <Stack direction="row" gap={2}>
        <Controller
          control={control}
          name="title"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TruentityTextField label="Title" onChange={onChange} value={value} required margin="none" />
          )}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Controller
          control={control}
          name="startTime"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TruentityDateTimePicker
              label="Start Time"
              onChange={onChange}
              sx={{ width: '100%' }}
              TextFieldProps={undefined}
              required
              value={value}
              minDateTime={getCurrentDate()}
            />
          )}
        />

        <Controller
          control={control}
          name="endTime"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <DateTimePicker label="End Time" onChange={onChange} sx={{ width: '100%' }} value={value} minDateTime={getCurrentDate()} />
          )}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <Controller
          control={control}
          name="type"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <SelectList
              title="Type"
              onChange={e => {
                const value = e.target.value as string;
                onChange(value.toUpperCase());
              }}
              value={value}
              options={Object.values(AnnouncementType).map(value => ({
                label: value,
                value
              }))}
              required
              placeholder="Select a type"
              formControlProps={{ margin: 'none', width: '50%' }}
            />
          )}
        />
        <Stack width="100%">
          <Controller
            control={control}
            name="alwaysShow"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} size={isUpdateAnnouncement ? 'small' : 'medium'} onChange={onChange} />}
                label="Always visible during time period"
                sx={{ width: '100%', m: 0 }}
              />
            )}
          />
          {isUpdateAnnouncement && (
            <Controller
              control={control}
              name="isActive"
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Switch checked={value} size="small" onChange={onChange} />}
                  label="Enable Announcement"
                  sx={{ width: '100%', m: 0 }}
                />
              )}
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GeneralAnnouncementDetailsForm;
