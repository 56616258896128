import { styled } from '@mui/material/styles';

const MultilineCell = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 15,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal'
}));

export default MultilineCell;
