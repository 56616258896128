import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import MuiTabs from '@/components/MuiTabs';
import PerformanceDashboardTable from '@/components/PerformanceDashboardTable/PerformanceDashboardTable';
import SelectFormControl from '@/components/Selects/SelectFormControl';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import type { GetRelyingPartyAdminsResponse } from '@/graphql/account';
import { GET_RELYING_PARTY_ADMINS } from '@/graphql/account';
import type { GetAdminPerformancesResponse, GetRelyingPartyPerformanceReportsResponse } from '@/graphql/administration';
import { GET_ADMIN_PERFORMANCE, GET_RELYING_PARTY_PERFORMANCE_REPORTS } from '@/graphql/administration';
import { PerformanceTableType } from '@/types/administration';
import type { RelyingPartyPerformanceReportType } from '@/types/graphql';

import { getFirstDateOfCurrentMonth } from '@/util/date';
import { formatDate, formatDateIgnoreTZ } from '@/util/format';
import type { ApolloError } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { MomentInput } from 'moment';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

const Performance = () => {
  const [today] = useState(new Date());

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [performanceReportData, setPerformanceReportData] = useState<RelyingPartyPerformanceReportType[]>([]);
  const [startDate, setStartDate] = useState<MomentInput>(getFirstDateOfCurrentMonth(today));
  const [endDate, setEndDate] = useState<MomentInput>(today);
  const [dashboardRPAdmin, setDashboardRPAdmin] = useState<string>('');
  const [reportsRPAdmin, setReportsRPAdmin] = useState<string>('all');
  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);
  const [performanceExportReportData, setPerformanceExportReportData] = useState<RelyingPartyPerformanceReportType[]>([]);
  const [exportPerformanceReportLoading, setExportPerformanceReportLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { data: relyingPartyAdminsData } = useQuery<GetRelyingPartyAdminsResponse>(GET_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });
  const [getReports, { loading: getReportsLoading, data: getReportData }] = useMutation<GetRelyingPartyPerformanceReportsResponse>(
    GET_RELYING_PARTY_PERFORMANCE_REPORTS
  );
  const { data: performanceData } = useQuery<GetAdminPerformancesResponse>(GET_ADMIN_PERFORMANCE, {
    variables: {
      relyingPartyAdminId: dashboardRPAdmin
    },
    skip: dashboardRPAdmin === ''
  });
  const [getReportsForExport] = useMutation<GetRelyingPartyPerformanceReportsResponse>(GET_RELYING_PARTY_PERFORMANCE_REPORTS);

  const onPerformanceReportDataError = (error: ApolloError) => {
    enqueueSnackbar('Failed to retrieve reports', { variant: 'error' });
    console.error(error);
    setPerformanceReportData([]);
  };

  const callGetPerformanceReport = async () => {
    await getReports({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        startDate: moment(startDate).format('YYYY-MM-DD').toString(),
        endDate: moment(endDate).format('YYYY-MM-DD').toString(),
        relyingPartyAdminId: reportsRPAdmin,
        isExport: false
      }
    }).catch((error: ApolloError) => {
      onPerformanceReportDataError(error);
    });
  };

  useEffect(() => {
    if (getReportData?.performanceReport) {
      setPerformanceReportData(getReportData?.performanceReport?.data);
      setRowCount(getReportData?.performanceReport?.meta?.totalCount || 0);
    }
  }, [getReportData]);

  useEffect(() => {
    callGetPerformanceReport().catch(error => console.error(error));
  }, [currentPage]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (
      dashboardRPAdmin === '' &&
      relyingPartyAdminsData?.relyingPartyAdminsAll?.relyingPartyAdmins &&
      relyingPartyAdminsData?.relyingPartyAdminsAll?.relyingPartyAdmins?.length > 0
    ) {
      setDashboardRPAdmin(relyingPartyAdminsData?.relyingPartyAdminsAll?.relyingPartyAdmins[0].id ?? '');
    }
  }, [relyingPartyAdminsData]);

  const onGetReportClicked = () => {
    setFetchDataButtonVisible(true);
    callGetPerformanceReport();
  };

  const csvHeaders = [
    { label: 'Date', key: 'Date' },
    { label: 'User', key: 'User' },
    { label: 'Active Time', key: 'Active Time' },
    { label: 'Billed ($)', key: 'Billed' },
    { label: 'Bonus ($)', key: 'Bonus' },
    { label: 'No of Encounters', key: 'No of Encounters' },
    { label: 'Invoice Time', key: 'Invoice Time' },
    { label: 'Invoice Amount', key: 'Invoice Amount' }
  ];

  const performanceReportDataWithIds = performanceReportData.map((row, index) => ({
    ...row,
    id: index + 1
  }));

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        sortable: true,
        type: 'string',
        flex: 1,
        valueGetter: params => formatDateIgnoreTZ(params.row.date, 'YYYY-MM-DD')
      },
      {
        field: 'relyingPartyAdminName',
        headerName: 'User',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return params.row.relyingPartyAdminName;
        }
      },
      {
        field: 'activeTime',
        headerName: 'Active Time',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return params.row.activeTime;
        }
      },
      {
        field: 'billedAmount',
        headerName: 'Billed Amount',
        sortable: true,
        flex: 1,
        valueFormatter: params => '$' + params.value,
        valueGetter: params => {
          return params.row.billedAmount;
        }
      },
      {
        field: 'bonusAmount',
        headerName: 'Bonus Amount',
        sortable: true,
        flex: 1,
        valueFormatter: params => '$' + params.value,
        valueGetter: params => {
          return params.row.bonusAmount;
        }
      },
      {
        field: 'numEncounters',
        headerName: 'Num Of Encounters',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return params.row.numOfEncounters;
        }
      },
      {
        field: 'invoicedTime',
        headerName: 'Invoiced Time',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return params.row.invoicedTime;
        }
      },
      {
        field: 'invoicedAmount',
        headerName: 'Invoiced Amount',
        sortable: true,
        flex: 1,
        valueFormatter: params => '$' + params.value,
        valueGetter: params => {
          return params.row.invoicedAmount;
        }
      }
    ],
    []
  );

  const getPerformanceReportDataFiltered = () => {
    const DEFAULT_STRING = 'Not Provided';
    const performanceReportDataObject = performanceExportReportData.map(
      data => ({
        Date: formatDateIgnoreTZ(data.date, 'YYYY-MM-DD'),
        User: data?.relyingPartyAdminName || DEFAULT_STRING,
        'Active Time': data?.activeTime || DEFAULT_STRING,
        Billed: data?.billedAmount || DEFAULT_STRING,
        Bonus: data?.bonusAmount || DEFAULT_STRING,
        'No of Encounters': data?.numOfEncounters || DEFAULT_STRING,
        'Invoice Time': data?.invoicedTime || DEFAULT_STRING,
        'Invoice Amount': data?.invoicedAmount || DEFAULT_STRING
      }),
      []
    );
    return performanceReportDataObject;
  };

  const exportDataToCsv = () => {
    setExportPerformanceReportLoading(true);
    getReportsForExport({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        startDate: formatDate(startDate, 'YYYY-MM-DD').toString(),
        endDate: formatDate(endDate, 'YYYY-MM-DD').toString(),
        relyingPartyAdminId: reportsRPAdmin,
        isExport: true
      }
    })
      .then(res => {
        setExportPerformanceReportLoading(false);
        setPerformanceExportReportData(res?.data?.performanceReport?.data ?? []);
      })
      .catch(() => {
        setExportPerformanceReportLoading(false);
      });
  };

  const onFetchDataClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv();
  };

  return (
    <Paper component={Stack} direction="column" spacing={2}>
      <MuiTabs
        tabs={[
          {
            label: 'Dashboard',
            children: (
              <Stack spacing={2}>
                <Stack>
                  <Grid container spacing={1} pb={1}>
                    <Grid item xs={4}>
                      <SelectFormControl
                        label={'User'}
                        value={dashboardRPAdmin}
                        placeholder={'Select User'}
                        onChange={event => setDashboardRPAdmin(event.target.value)}
                        options={relyingPartyAdminsData?.relyingPartyAdminsAll?.relyingPartyAdmins?.map(item => ({
                          label: item.name,
                          value: item.id
                        }))}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <PerformanceDashboardTable performanceData={performanceData} tableType={PerformanceTableType.TEAM} />
              </Stack>
            )
          },
          {
            label: 'Detailed Performance',
            children: (
              <Stack spacing={2}>
                <Stack>
                  <Grid container spacing={1} pb={1}>
                    <Grid item xs={4}>
                      <TruentityDatePicker
                        label="Start Date"
                        fullWidth={false}
                        sx={{ width: '100%' }}
                        value={startDate}
                        format="MMM DD, YYYY"
                        onChange={date => setStartDate(date as MomentInput)}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TruentityDatePicker
                        label="End Date"
                        fullWidth={false}
                        sx={{ width: '100%' }}
                        value={endDate}
                        format="MMM DD, YYYY"
                        onChange={date => setEndDate(date as MomentInput)}
                      />
                    </Grid>

                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <SelectFormControl
                        label={'User'}
                        value={reportsRPAdmin}
                        placeholder={'Select User'}
                        defaultOptions={[
                          {
                            label: 'All',
                            value: 'all'
                          },
                          {
                            label: 'All Grouped',
                            value: 'all_grouped'
                          }
                        ]}
                        onChange={event => setReportsRPAdmin(event.target.value)}
                        options={relyingPartyAdminsData?.relyingPartyAdminsAll?.relyingPartyAdmins?.map(item => ({
                          label: item.name,
                          value: item.id
                        }))}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} pb={1}>
                    <Grid item xs={4}>
                      <Button sx={{ marginRight: 1 }} type="submit" size="small" onClick={() => onGetReportClicked()}>
                        Get Reports
                      </Button>
                    </Grid>
                  </Grid>
                </Stack>

                <Paper component={Stack} direction="column" spacing={2}>
                  <div style={{ display: 'flex' }}>
                    <TruentityDataGrid
                      name={'dg-performance'}
                      autoHeight
                      rows={performanceReportDataWithIds}
                      columns={columns}
                      paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                      onPaginationModelChange={({ page }) => {
                        setCurrentPage(page);
                      }}
                      columnVisibilityModel={{
                        relyingPartyAdminName: reportsRPAdmin === 'all'
                      }}
                      customFilter={
                        <Stack>
                          <Stack
                            spacing={3}
                            direction="row"
                            sx={{
                              cursor: 'pointer',
                              margin: '15px',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <Stack spacing={1} direction="row">
                              {fetchDataButtonVisible ? (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="small"
                                  disabled={performanceReportDataWithIds.length === 0}
                                  onClick={() => {
                                    onFetchDataClicked();
                                  }}
                                >
                                  Load for Export
                                </Button>
                              ) : (
                                <>
                                  <Button isLoading={exportPerformanceReportLoading} color="primary" variant="contained" size="small">
                                    {performanceExportReportData && performanceExportReportData.length > 0 ? (
                                      <CSVLink
                                        headers={csvHeaders}
                                        data={getPerformanceReportDataFiltered()}
                                        filename={`Performance Report -- ${formatDate(startDate, 'YYYY-MM-DD')} - ${formatDate(
                                          endDate,
                                          'YYYY-MM-DD'
                                        )}.csv`}
                                        style={{ textDecoration: 'none', color: '#fff' }}
                                      >
                                        {exportPerformanceReportLoading ? 'Loading csv...' : 'Export All'}
                                      </CSVLink>
                                    ) : undefined}
                                  </Button>
                                </>
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      }
                      loading={getReportsLoading}
                      rowCount={rowCountState}
                      paginationMode="server"
                    />
                  </div>
                </Paper>
              </Stack>
            )
          }
        ]}
      />
    </Paper>
  );
};

export default Performance;
