import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import Icon from '@/elements/Icon';
import { CHANGE_INVOICE_STATUSES, GET_INVOICES, GET_INVOICES_BY_ADMIN } from '@/graphql/invoices';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { currentLoggedUserVar } from '@/util/apollo/cache';

import { formatDateIgnoreTZ, secondsToTime } from '@/util/format';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Chip, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef, GridRowId, GridSortItem } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { TimesheetData } from '../Timesheets/Timesheets';

export enum InvoiceStatuses {
  Pending = 'pending',
  Invoiced = 'invoiced',
  Approved = 'approved',
  Denied = 'denied',
  Paid = 'paid',
  InFinance = 'in finance'
}

const filterInvoiceStatuses = [InvoiceStatuses.Pending, InvoiceStatuses.Invoiced, InvoiceStatuses.Paid, InvoiceStatuses.InFinance];

export type InvoiceStatus =
  | InvoiceStatuses.InFinance
  | InvoiceStatuses.Denied
  | InvoiceStatuses.Approved
  | InvoiceStatuses.Invoiced
  | InvoiceStatuses.Paid
  | InvoiceStatuses.Pending;

export type SaveInvoiceData = {
  id?: string;
  dateInvoiced?: string | null;
  description?: string;
  totalNumberOfHours: number;
  totalNumberOfMinutes: number;
  hourlyRate: number;
  status?: InvoiceStatus;
  timesheetIds: string[];
};

export type InvoiceData = {
  id: string;
  dateInvoiced?: string | null;
  description?: string;
  totalNumberOfHours: number;
  totalNumberOfMinutes: number;
  hourlyRate: number;
  status?: InvoiceStatus;
  timesheets: TimesheetData[];
  relyingPartyAdmin: {
    id: string;
    name: string;
    user: {
      firstName: string;
      lastName: string;
    };
  };
};

const Invoices = () => {
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);

  const [searchParams] = useSearchParams();
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [getAllInvoices, { loading, refetch: refetchAll, data }] = useLazyQuery(GET_INVOICES);
  const [getInvoicesByAdmin, { refetch: refetchByAdmin, data: dataByAdmin }] = useLazyQuery(GET_INVOICES_BY_ADMIN);
  const { setContent } = useContext(SideMenuContext);
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceData[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceData[]>([]);
  const [invoiceUsers, setInvoiceUsers] = useState<{ id: string; name: string }[]>([]);
  const [updateInvoiceStatuses] = useMutation(CHANGE_INVOICE_STATUSES);
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'date',
      sort: 'desc'
    }
  ]);

  const { roleType } = useToken();
  const [isPharmacist] = useState<boolean>(roleType === Role.PHARMACIST);
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);

  const [columnVisibilityModel] = useState({ user: !isPharmacist });

  const [selectedStatus, setSelectedStatus] = useState(
    searchParams.get('status') || (!isPharmacist ? InvoiceStatuses.Invoiced : InvoiceStatuses.Pending)
  );
  const [filterStatuses] = useState<InvoiceStatuses[]>(
    isPharmacist ? filterInvoiceStatuses : filterInvoiceStatuses.filter(v => v !== InvoiceStatuses.Pending)
  );
  const [newStatus, setNewStatus] = useState<InvoiceStatus | ''>('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedInvoiceUserId, setSelectedInvoiceUserId] = useState<string>('');
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { showModal } = useModal();

  const showConfirmDialog = (title, message, onAgree, onDisagree?) => {
    const modal = showModal(ConfirmDialog, {
      title,
      message,
      onAgree: () => {
        modal.hide();
        onAgree();
      },
      onDisagree: () => {
        modal.hide();
        if (onDisagree) {
          onDisagree();
        }
      }
    });
  };

  const refetch = () => {
    if (isPharmacist) {
      refetchByAdmin();
    } else {
      refetchAll();
    }
  };

  const getInvoices = useCallback(() => {
    if (isPharmacist) {
      getInvoicesByAdmin({
        variables: {
          relyingPartyAdminId: currentUser?.id,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          status: selectedStatus
        }
      });
    } else {
      getAllInvoices({
        variables: {
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          status: selectedStatus
        }
      });
    }
  }, [currentPage, selectedStatus, getAllInvoices, getInvoicesByAdmin]);

  useEffect(() => {
    if (invoices) {
      const map = new Map(filteredInvoices.map(item => [item.relyingPartyAdmin.id, item.relyingPartyAdmin.name]));
      const uniqueUsers = Array.from(map).map(([id, name]) => ({ id, name }));
      setInvoiceUsers(uniqueUsers);
    }
  }, [selectedStatus, filteredInvoices]);

  useEffect(() => {
    setContent(<MainSideMenu />);
    getInvoices();
  }, []);

  useEffect(() => {
    getInvoices();
  }, [selectedStatus, currentPage]);

  useEffect(() => {
    if (data?.invoicesGet) {
      setInvoices(data?.invoicesGet?.invoices);
      setRowCount(data?.invoicesGet?.meta.totalCount || 0);
    }
  }, [data]);

  useEffect(() => {
    if (dataByAdmin?.invoicesByRelyingPartyAdmin) {
      setInvoices(dataByAdmin.invoicesByRelyingPartyAdmin.invoices);
      setRowCount(dataByAdmin.invoicesByRelyingPartyAdmin?.meta.totalCount || 0);
    }
  }, [dataByAdmin]);

  useEffect(() => {
    if (data) {
      setInvoices(data.invoicesGet.invoices?.filter(v => v.status === selectedStatus));
    }
  }, [data, selectedStatus]);

  const payInvoices = event => {
    showConfirmDialog(
      'Pay Invoices?',
      'Mark the selected invoices as Paid?',
      () => {
        if (event?.target?.value && event?.target?.value.toString() !== '') {
          changeStatuses(selectedInvoices.map(invoice => ({ id: invoice.id, status: event?.target?.value })));
          setNewStatus('');
        }
      },
      () => {
        setNewStatus('');
      }
    );
  };

  useEffect(() => {
    if (newStatus && newStatus.toString() !== '') {
      changeStatuses(selectedInvoices.map(invoice => ({ id: invoice.id, status: newStatus })));
    }
  }, [newStatus]);

  useEffect(() => {
    if (invoices) {
      let filteredData: InvoiceData[] = invoices?.filter(v => v.status === selectedStatus);
      if (selectedInvoiceUserId?.length > 0) {
        filteredData = filteredData.filter(v => v.relyingPartyAdmin?.id === selectedInvoiceUserId);
      }
      setFilteredInvoices(filteredData);
    }
  }, [invoices, selectedStatus, selectedInvoiceUserId]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const changeStatuses = async (invoiceStatuses: { id: string; status: InvoiceStatus }[]) => {
    try {
      await updateInvoiceStatuses({
        variables: {
          invoiceStatuses
        }
      });

      refetch();

      enqueueSnackbar('The selected invoice(s) have been updated', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to update the selected invoice(s)', { variant: 'error' });
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        sortable: true,
        type: 'string',
        flex: 1,
        valueGetter: params => formatDateIgnoreTZ(params.row.dateInvoiced, 'YYYY-MM-DD')
      },
      {
        field: 'time',
        headerName: 'Total Time',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          const hours = params.row.totalNumberOfHours || 0;
          const minutes = params.row.totalNumberOfMinutes || 0;

          return secondsToTime(hours * 3600 + minutes * 60);
        }
      },
      {
        field: 'value',
        headerName: 'Value',
        sortable: true,
        flex: 1,
        valueFormatter: params => '$' + params.value,
        valueGetter: params => {
          const hours = params.row.totalNumberOfHours || 0;
          const minutes = params.row.totalNumberOfMinutes || 0;

          const invoiceTotalRounded = ((+hours + +minutes / 60) * params.row.hourlyRate).toFixed(2);
          return invoiceTotalRounded;
        }
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.description
      },
      {
        field: 'user',
        headerName: 'User',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return params.row.relyingPartyAdmin.name;
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        renderCell: params => <Chip sx={{ textTransform: 'capitalize' }} label={params.row.status} />
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        flex: 1,
        renderCell: params => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton onClick={() => navigate('../invoices/' + params.row.id)}>
                <Icon icon={faInfoCircle} fixedWidth size="lg" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [navigate]
  );

  return (
    <Stack spacing={2}>
      <Stack alignItems={'center'} direction="row" justifyContent={'space-between'}>
        <Stack direction="row">
          <SelectList
            fullWidth={false}
            formControlProps={{ sx: { width: 250 } }}
            label="Status"
            options={filterStatuses.map(value => ({ value, label: value.toUpperCase() } as TextSelectOption))}
            placeholder="Select an option..."
            value={selectedStatus}
            onChange={(event: any) => setSelectedStatus(event.target.value)}
          />
          {!isPharmacist && invoices?.length > 1 && (
            <SelectList
              fullWidth={false}
              formControlProps={{ sx: { width: 250, marginLeft: '5px' } }}
              label="User"
              options={invoiceUsers.map(
                item =>
                  ({
                    value: item.id,
                    label: item.name.toUpperCase()
                  } as TextSelectOption)
              )}
              placeholder="Select an option..."
              value={selectedInvoiceUserId}
              clearFunction={() => setSelectedInvoiceUserId('')}
              onChange={(event: any) => setSelectedInvoiceUserId(event.target.value)}
            />
          )}
        </Stack>

        {(isPharmacistAdmin || isSuperAdmin) && selectedInvoices?.length > 0 && selectedStatus === InvoiceStatuses.InFinance && (
          <SelectList
            fullWidth={false}
            formControlProps={{ sx: { width: 250 } }}
            label="Change Status"
            options={[InvoiceStatuses.Paid].map(
              value =>
                ({
                  value,
                  label: value.toUpperCase()
                } as TextSelectOption)
            )}
            placeholder="Select an option..."
            value={newStatus}
            onChange={(event: any) => {
              payInvoices(event);
            }}
          />
        )}
      </Stack>

      <Paper component={Stack} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-invoices'}
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibilityModel
              }
            }}
            autoHeight
            rows={filteredInvoices}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            loading={loading}
            sortModel={sortModel}
            rowCount={rowCountState}
            onSortModelChange={model => setSortModel(model)}
            paginationMode="server"
            disableRowSelectionOnClick
            checkboxSelection={!isPharmacist}
            onRowSelectionModelChange={ids => {
              const selectedIDs = new Set(ids);
              const selectedRowData = invoices.filter(invoice => selectedIDs.has(invoice.id as GridRowId));
              console.log(selectedRowData);
              setSelectedInvoices(selectedRowData);
            }}
            rowSelectionModel={selectedInvoices.map(t => t.id)}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Invoices;
