import { useModal } from 'mui-modal-provider';
import Button from './Button';
import InvoiceAddDialog from './Dialogs/InvoiceAddDialog';

const InvoiceAdd = ({ onDone, selectedTimesheets }) => {
  const { showModal } = useModal();

  const showAddInvoiceDialog = () => {
    const modal = showModal(InvoiceAddDialog, {
      title: 'Add Invoice',
      selectedTimesheets,
      hideDialog: () => {
        modal.hide();
        onDone();
      }
    });
  };

  return <Button label="Add Invoice" onClick={() => showAddInvoiceDialog()} />;
};

export default InvoiceAdd;
