import { gql } from '@apollo/client';

export enum FilterTemplateType {
  Adt = 'ADT',
  PatientList = 'PATIENTS_LIST'
}

export enum FilterAvailabilityType {
  Admin = 'Admin',
  Org = 'Org'
}

export type FilterTemplate = {
  id: string;
  name: string;
  category: FilterTemplateType;
  availabilityType: FilterAvailabilityType;
  filterTemplateAttributes: FilterInputData[];
};

export type GetFiltersByRelyingPartyQueryResponse = {
  filtersByRelyingParty: {
    filterTemplates: FilterTemplate[];
  };
};
export const GET_FILTERS_BY_RELYING_PARTY = gql`
  query FiltersByRelyingPartyQuery($availabilityType: String!, $category: String!) {
    filtersByRelyingParty(availabilityType: $availabilityType, category: $category) {
      filterTemplates {
        id
        name
        filterTemplateAttributes {
          id
          filterType
          value
          value2
        }
      }
    }
  }
`;

export type FilterInputData = {
  filterType: string;
  operatorType: string;
  value: string;
  value2?: string;
};

export type AddFilterInput = {
  name: string;
  category: FilterTemplateType;
  availabilityType: string;
  filterInputData: FilterInputData;
};

export const CREATE_FILTER_TEMPLATE = gql`
  mutation AddFilterTemplate($name: String!, $category: String!, $availabilityType: String!, $filterInputData: [CreateFilterInput!]) {
    addFilterTemplate(name: $name, category: $category, availabilityType: $availabilityType, filterInputData: $filterInputData) {
      filterTemplate {
        id
        name
        filterTemplateAttributes {
          id
          filterType
          operatorType
          value
          value2
        }
      }
      message
    }
  }
`;

export type UpdateFilterInput = {
  filterTemplateId: string;
  filterInputData: FilterInputData[];
};

export const UPDATE_FILTER_TEMPLATE = gql`
  mutation UpdateFilterTemplate($templateId: String!, $filterInputData: [CreateFilterInput!]) {
    updateFilterTemplate(templateId: $templateId, filterInputData: $filterInputData) {
      filterTemplate {
        id
        name
        filterTemplateAttributes {
          id
          filterType
          operatorType
          value
          value2
        }
      }
      message
    }
  }
`;

export type DeleteFilterInput = {
  templateId: string;
};
export const DELETE_FILTER_TEMPLATE = gql`
  mutation DeleteFilterTemplate($templateId: String!) {
    deleteFilterTemplate(templateId: $templateId) {
      status
      message
    }
  }
`;
