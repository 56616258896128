import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import PatientDetailContext from '@/context/patientDetailContext';
import type { UpdateCandidateStatusResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_CANDIDATE_STATUS } from '@/graphql/remotePatientMonitoring';
import type { RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { enrollmentEligibility } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMutation } from '@apollo/client';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  notConsiderForRpmEnrollments?: RpmWorkflowTab[];
};

const useAutoEnrollPatient = ({ notConsiderForRpmEnrollments }: Props) => {
  const { id } = useParams();
  const { rpmSetupTabs } = useRpmSetupStore();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);

  const [updateCandidateStatus] = useMutation<UpdateCandidateStatusResponse>(UPDATE_RPM_CANDIDATE_STATUS);

  const isAutoEnrollmentEligible = useCallback(() => {
    return (
      enrollmentEligibility(rpmSetupTabs, notConsiderForRpmEnrollments) &&
      patientInfo?.rpmStatus &&
      [RpmStatusTypes.IS_CANDIDATE, RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT].includes(patientInfo.rpmStatus as RpmStatusTypes)
    );
  }, [rpmSetupTabs, notConsiderForRpmEnrollments, patientInfo]);

  const triggerAutoEnrollment = useCallback(() => {
    if (!isAutoEnrollmentEligible()) return;

    const patientName = getAccountUserFullName(patientInfo?.user);
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Enrollment',
      message: `This patient, ${patientName}, is eligible to enroll in the RPM program because they have met the RPM enrollment eligibility requirements. Would you like to enroll this patient?`,
      onAgree: async () => {
        try {
          const response = await updateCandidateStatus({
            variables: { truentityIds: [id], rpmStatus: RpmStatusTypes.ENROLLED }
          });

          if (response.data?.updateCandidateStatus.status === 'Success') {
            enqueueSnackbar(`${patientName} enrolled Successfully`, { variant: 'success' });
            setReloadPatientInfo(true);
            modal.hide();
          } else {
            enqueueSnackbar(`Can not enrolled ${patientName}, Please contact support`, { variant: 'error' });
            modal.hide();
            return;
          }
        } catch (error: unknown) {
          const message = error instanceof Error ? error.message : 'An unknown error occurred during enrollment.';
          enqueueSnackbar(message, { variant: 'error' });
          console.error(`Error during auto enrollment: ${message}`);
        }
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  }, [enqueueSnackbar, id, isAutoEnrollmentEligible, patientInfo?.user, setReloadPatientInfo, showModal, updateCandidateStatus]);

  return { isAutoEnrollmentEligible, triggerAutoEnrollment };
};

export default useAutoEnrollPatient;
