import { useTheme } from '@mui/material';
import ReactJson from 'react-json-view';

export type JSONData = { [key: string]: any };

type Props = {
  src: JSONData;
  jsonViewerStyles?: Record<string, string | number | boolean>;
  isDisabled?: boolean;
  onEdit?: (prop: Record<string, any>) => void;
  onDelete?: (prop: Record<string, any>) => void;
  onAdd?: (prop: Record<string, any>) => void;
};

const JsonEditor = ({ src, isDisabled = false, onEdit, onDelete, onAdd, jsonViewerStyles, ...props }: Props) => {
  const theme = useTheme();

  const jsonViewStyles = {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    width: '100%',
    minHeight: '100px',
    height: 'auto',
    overflow: 'auto',
    ...jsonViewerStyles
  };

  return (
    <ReactJson
      src={src}
      style={jsonViewStyles}
      displayObjectSize={false}
      displayDataTypes={false}
      quotesOnKeys={false}
      indentWidth={4}
      {...(!isDisabled && {
        onEdit: onEdit,
        onDelete: onDelete,
        onAdd: onAdd
      })}
      {...props}
    />
  );
};

export default JsonEditor;
