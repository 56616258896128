import type { AccountCareEvent, TcmTask } from '@/types/dischargePatient';

import { AdtTcmActivityLog } from '@/types/activityLog';
import type Meta from '@/types/meta';
import { gql } from '@apollo/client';

export type AccountCareEventsResponse = {
  accountCareEvents: {
    accountCareEvents: AccountCareEvent[];
  };
};

export const GET_ACCOUNT_CARE_EVENTS = gql`
  query GetAccountCareEvents($pageNum: Int!, $pageSize: Int!, $truentityId: String!) {
    accountCareEvents(pageNum: $pageNum, pageSize: $pageSize, truentityId: $truentityId) {
      accountCareEvents {
        id
        dischargeDate
        dischargeLocation
        dischargingPhysician
        admissionDate
        admissionReason
        eventActionType
        eventStatus: status
        dischargedDisposition
        admittedFrom
        eventFacilityType
        eventFacilityName
        primaryDiagnosisCode
        primaryDiagnosisDescription
        eventType
        readmissionRiskFlag
        tcmTasks {
          id
          initialCommsDueDate
          complexityLevel
          finalDueDate
          billingHoldUntilDate
          reviewDischargeInfoType
          reviewDischargeInfoDischargingPhysician
          reviewDischargeInfoDiagnosisCode
          reviewDischargeInfoReviewed
          reviewDischargeInfoSummaryDocUrl
          reviewDischargeInfoNotes
          reviewDischargeSummaryDocReviewed
          reviewDischargeInfoFrom
          initialCommsDischargeToType
          initialCommsCaregiverName
          initialCommsCaregiverRelationship
          initialCommsDischargeTransportationType
          docReferralDiscussions
          docCaregiverDiscussions
          docSelfCareActivities
          docTreatmentAdherenceInstructions
          finalF2fLocation
          finalF2fProvider
          finalF2fNotes
          finalF2fDate
          finalMedsReviewed
          finalTcmSignedAndCertified
          taskStatus: status
          medrecSnapshot {
            id
          }
          encounters {
            id
            title
            calledOn
            disposition
            modeOfResolution
            notes
          }
        }
        account {
          id
          user {
            firstName
            lastName
            email
          }
          birthDate
          gender
          address
          truentityId
          phone
        }
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
        createdAt
      }
    }
  }
`;

export type EventStatusCount = {
  eventStatusesPendingCount: number;
  eventStatusesInProgressCount: number;
  eventStatusesProcessedCount: number;
  eventStatusesMissedCount: number;
};

export type AccountCareEventsByFilterResponse = {
  accountCareEventsByFilter: {
    events: AccountCareEvent[];
    admitCount: number;
    dischargeCount: number;
    meta: Meta;
    eventStatusCounts: EventStatusCount;
  };
};

export const GET_ACCOUNT_CARE_EVENTS_BY_FILTER = gql`
  query GetAccountCareEventsByFilterQuery($filterOptions: TaskFilterOptionsInput!, $pageNum: Int!, $pageSize: Int!) {
    accountCareEventsByFilter(filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      admitCount
      dischargeCount
      events {
        id
        dischargeDate
        dischargeLocation
        admissionDate
        admissionReason
        eventActionType
        eventStatus: status
        dischargedDisposition
        dischargingPhysician
        admittedFrom
        eventFacilityType
        eventFacilityName
        primaryDiagnosisCode
        primaryDiagnosisDescription
        eventType
        eventStatus: status
        readmissionRiskFlag
        tcmTasks {
          id
          initialCommsDueDate
          complexityLevel
          finalDueDate
          billingHoldUntilDate
          reviewDischargeInfoType
          reviewDischargeInfoDischargingPhysician
          reviewDischargeInfoDiagnosisCode
          reviewDischargeInfoReviewed
          reviewDischargeInfoSummaryDocUrl
          reviewDischargeInfoNotes
          reviewDischargeSummaryDocReviewed
          reviewDischargeInfoFrom
          initialCommsDischargeToType
          initialCommsCaregiverName
          initialCommsCaregiverRelationship
          initialCommsDischargeTransportationType
          docReferralDiscussions
          docCaregiverDiscussions
          docSelfCareActivities
          docTreatmentAdherenceInstructions
          finalF2fLocation
          finalF2fProvider
          finalF2fNotes
          finalF2fDate
          finalMedsReviewed
          finalTcmSignedAndCertified
          taskStatus: status
          medrecSnapshot {
            id
          }
          encounters {
            id
            title
            calledOn
            disposition
            modeOfResolution
            notes
          }
        }
        account {
          id
          user {
            firstName
            lastName
          }
          birthDate
          gender
          address
          truentityId
          phone
        }
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
        createdAt
      }
      meta {
        totalPages
        totalCount
      }
      eventStatusCounts {
        eventStatusesPendingCount
        eventStatusesInProgressCount
        eventStatusesProcessedCount
        eventStatusesMissedCount
      }
    }
  }
`;

export type AccountCareEventResponse = {
  accountCareEvent: AccountCareEvent;
};
export const GET_ACCOUNT_CARE_EVENT = gql`
  query GetAccountCareEvent($accountCareEventId: String!) {
    accountCareEvent(accountCareEventId: $accountCareEventId) {
      id
      dischargeDate
      dischargeLocation
      admissionDate
      admissionReason
      eventActionType
      eventStatus: status
      dischargedDisposition
      dischargingPhysician
      admittedFrom
      eventFacilityType
      eventFacilityName
      primaryDiagnosisCode
      primaryDiagnosisDescription
      eventType
      eventStatus: status
      readmissionRiskFlag
      tcmTasks {
        id
        initialCommsDueDate
        complexityLevel
        finalDueDate
        billingHoldUntilDate
        reviewDischargeInfoType
        reviewDischargeInfoDischargingPhysician
        reviewDischargeInfoDiagnosisCode
        reviewDischargeInfoReviewed
        reviewDischargeInfoSummaryDocUrl
        reviewDischargeInfoNotes
        reviewDischargeSummaryDocReviewed
        reviewDischargeInfoFrom
        initialCommsDischargeToType
        initialCommsCaregiverName
        initialCommsCaregiverRelationship
        initialCommsDischargeTransportationType
        docReferralDiscussions
        docCaregiverDiscussions
        docSelfCareActivities
        docTreatmentAdherenceInstructions
        finalF2fLocation
        finalF2fProvider
        finalF2fNotes
        finalF2fDate
        finalMedsReviewed
        finalTcmSignedAndCertified
        taskStatus: status
        medrecSnapshot {
          id
        }
        encounters {
          id
          title
          calledOn
          disposition
          modeOfResolution
          notes
        }
      }
      account {
        id
        user {
          firstName
          lastName
          email
        }
        birthDate
        gender
        address
        truentityId
        phone
        truentityId
      }
      relyingPartyAdmin {
        id
        user {
          firstName
          lastName
        }
      }
      createdAt
    }
  }
`;

export const GET_ACCOUNT_ADT_EVENT = gql`
  query GetAccountCareEvent($accountCareEventId: String!) {
    accountCareEvent(accountCareEventId: $accountCareEventId) {
      id
      dischargeDate
      dischargeLocation
      admissionDate
      admissionReason
      eventActionType
      source
      eventStatus: status
      dischargedDisposition
      dischargingPhysician
      admittedFrom
      eventFacilityType
      eventFacilityName
      primaryDiagnosisCode
      primaryDiagnosisDescription
      eventType
      eventStatus: status
      readmissionRiskFlag
      createdAt
    }
  }
`;

export const GET_ACCOUNT_TCM_TASKS = gql`
  query GetTcmTasksQuery($pageNum: Int!, $pageSize: Int!, $truentityId: String!) {
    tcmTasks(pageNum: $pageNum, pageSize: $pageSize, truentityId: $truentityId) {
      tcmTasks {
        id
        tcmTaskType
        dueDate
        createdAt
      }
      meta {
        totalCount
      }
    }
  }
`;

export const DISCHARGE_PATIENT = gql`
  mutation AddAdtEvent(
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $dateOfBirth: ISO8601DateTime!
    $gender: String!
    $zipcode: String!
    $accountCareEventData: AccountCareEventInput!
  ) {
    addAdtEvent(
      firstName: $firstName
      lastName: $lastName
      middleName: $middleName
      dateOfBirth: $dateOfBirth
      gender: $gender
      zipcode: $zipcode
      accountCareEventData: $accountCareEventData
    ) {
      status
      message
    }
  }
`;

export type TcmSnapshotReportExport = {
  pdfDownloadUrl: string;
};
export type TcmSnapshotReportExportResponse = {
  createPdfTcmReport: TcmSnapshotReportExport;
};
export const TCM_SNAPSHOT_REPORT_EXPORT = gql`
  mutation createPdfTcmReport($accountCareId: String!) {
    createPdfTcmReport(accountCareId: $accountCareId) {
      pdfDownloadUrl
    }
  }
`;

export type UpdateTcmTaskMutationResponse = {
  saveTcmTask: { tcmTask: Partial<TcmTask> };
};

export const UPDATE_TCM_TASK = gql`
  mutation UpdateTcmTask($tcmTask: TcmTaskInput!) {
    saveTcmTask(tcmTaskInput: $tcmTask) {
      tcmTask {
        id
        initialCommsDueDate
        complexityLevel
        finalDueDate
        billingHoldUntilDate
        reviewDischargeInfoType
        reviewDischargeInfoDischargingPhysician
        reviewDischargeInfoDiagnosisCode
        reviewDischargeInfoReviewed
        reviewDischargeInfoSummaryDocUrl
        reviewDischargeInfoNotes
        reviewDischargeInfoFrom
        reviewDischargeSummaryDocReviewed
        initialCommsDischargeToType
        initialCommsCaregiverName
        initialCommsCaregiverRelationship
        initialCommsDischargeTransportationType
        docReferralDiscussions
        docCaregiverDiscussions
        docSelfCareActivities
        finalF2fLocation
        finalF2fProvider
        finalF2fNotes
        finalF2fDate
        finalMedsReviewed
        finalTcmSignedAndCertified
        taskStatus: status
        medrecSnapshot {
          id
        }
      }
    }
  }
`;

export type UpdateAdtEventMutationResponse = {
  updateAdtEvent: { accountCareEvent: Partial<AccountCareEvent> };
};
export const UPDATE_ADT_EVENT = gql`
  mutation UpdateAdtTask($accountCareEventDataInput: AccountCareEventInput!) {
    updateAdtEvent(accountCareEventDataInput: $accountCareEventDataInput) {
      accountCareEvent {
        id
        eventStatus: status
        dischargeDate
        dischargeLocation
        admissionDate
        admissionReason
      }
    }
  }
`;

export type GetAdtTcmActivityLogsMutationResponse = {
  getAdtTcmActivityLogs: { activityLog: Partial<AdtTcmActivityLog[]> };
};

export const GET_ADT_TCM_ACTIVITY_LOGS = gql`
  mutation GetAdtTcmActivityLogs($tcmTaskInput: TcmTaskInput!) {
    getAdtTcmActivityLogs(tcmTaskInput: $tcmTaskInput) {
      activityLog {
        activityTime
        account {
          id
        }
        tcmTask {
          id
        }
        relyingPartyAdmin {
          user {
            firstName
            lastName
          }
        }
        logText
      }
    }
  }
`;

export const GET_FILTERS_BY_RELYING_PARTY = gql`
  query FiltersByRelyingPartyQuery($availabilityType: String!, $category: String!) {
    filtersByRelyingParty(availabilityType: $availabilityType, category: $category) {
      filterTemplates {
        id
        name
        filterTemplateAttributes {
          id
          filterType
          value
          value2
        }
      }
    }
  }
`;

export const CREATE_FILTER_TEMPLATE = gql`
  mutation AddFilterTemplate($name: String!, $category: String!, $availabilityType: String!, $filterInputData: [CreateFilterInput!]) {
    addFilterTemplate(name: $name, category: $category, availabilityType: $availabilityType, filterInputData: $filterInputData) {
      filterTemplate {
        id
        name
        filterTemplateAttributes {
          id
          filterType
          operatorType
          value
          value2
        }
      }
      message
    }
  }
`;

export const UPDATE_FILTER_TEMPLATE = gql`
  mutation UpdateFilterTemplate($templateId: String!, $filterInputData: [CreateFilterInput!]) {
    updateFilterTemplate(templateId: $templateId, filterInputData: $filterInputData) {
      filterTemplate {
        id
        name
        filterTemplateAttributes {
          id
          filterType
          operatorType
          value
          value2
        }
      }
      message
    }
  }
`;

export const DELETE_FILTER_TEMPLATE = gql`
  mutation DeleteFilterTemplate($templateId: String!) {
    deleteFilterTemplate(templateId: $templateId) {
      status
      message
    }
  }
`;
