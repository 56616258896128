import ChipsList from '@/components/ChipsList';
import ActionDropdown from '@/components/DataGrid/ActionDropdown';
import { DataGridToolTip, DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import LabelGroup from '@/components/LabelGroup';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { Body2 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import Link from '@/elements/Link';
import type { GetAllPioneerEventsDefaultResponse, PioneerRxEventTypes } from '@/graphql/account';
import { GET_ALL_PIONEER_RX_EVENTS_DEFAULT } from '@/graphql/account';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { PatientsData } from '@/routes/Patients/patients';
import type { PatientFilter } from '@/zustand/patientFilterStore';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';

import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { ClientOrganizationObject } from '@/types/administration';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SELECT_DEFAULT: { id?: string; name: string }[] = [{ id: 'all', name: 'All' }];

const PatientListToolbar = () => {
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const { selectedFilter, setSelectedFilter } = usePatientFilterStore();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([
        ...SELECT_DEFAULT,
        ...organizationData.clientOrganizations.clientOrganizations
      ] as ClientOrganizationObject[]);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setSelectedFilter({
      filter: {
        organization: 'all'
      }
    });
  }, []);

  return (
    <Stack>
      <Stack direction="row" alignItems="center" gap={2} p={1} flexWrap="wrap">
        <SelectList
          formControlProps={{ sx: { minWidth: '200px' } }}
          label="Organization"
          options={clientOrganizations?.map(
            item =>
              ({
                label: item.name,
                value: item.id
              } as TextSelectOption)
          )}
          placeholder="Select an option..."
          value={selectedFilter?.filter?.organization || ''}
          onChange={event => {
            setSelectedFilter({
              filter: {
                ...selectedFilter?.filter,
                organization: event.target.value as string
              }
            } as PatientFilter);
          }}
          fullWidth={false}
        />
      </Stack>
    </Stack>
  );
};

const PatientsListWithImportedData = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { setContent } = useContext(SideMenuContext);
  const [patients, setPatients] = useState<PioneerRxEventTypes[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedFilter } = usePatientFilterStore();
  const navigate = useNavigate();

  const [getInfoByLookupSimplified, { loading, data, error }] = useLazyQuery<GetAllPioneerEventsDefaultResponse>(
    GET_ALL_PIONEER_RX_EVENTS_DEFAULT,
    {
      notifyOnNetworkStatusChange: true
    }
  );

  const columns: GridColDef<PioneerRxEventTypes>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        valueGetter: params => getAccountUserFullName(params.row.account.user),
        renderCell: params => {
          return (
            <Link to={`../../patients/${params.row.account.truentityId}/details/medications/triage/list`}>
              {getAccountUserFullName(params.row.account.user)}
            </Link>
          );
        },
        flex: 1,
        align: 'left'
      },
      {
        field: 'profile',
        headerName: 'Profile',
        sortable: true,
        valueGetter: params => (params.row.account.birthDate ? formatDate(new Date(params.row.account.birthDate.replace(/-/g, '/'))) : ''),
        renderCell: params => {
          return (
            <DataGridToolTip
              title={
                <Stack p={2}>
                  <LabelGroup LabelProps={{ color: 'primary' }} label={'Date of Birth'}>
                    <Body2>{params.row.account.birthDate}</Body2>
                  </LabelGroup>
                  {params.row.account?.phone && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Phone'}>
                      <TruentityPhoneNumber value={params.row.account.phone} />
                    </LabelGroup>
                  )}
                  {/* @2024/04/05 Patient emails hidden */}
                  {/* {params.row.account?.user?.email && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Email'}>
                      <Body2>{params.row.account.user.email}</Body2>
                    </LabelGroup>
                  )} */}
                  {params.row.account.gender && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Gender'}>
                      <Body2>{params.row.account.gender}</Body2>
                    </LabelGroup>
                  )}
                  {params.row.account.address && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Address'}>
                      <Body2>{params.row.account.address}</Body2>
                    </LabelGroup>
                  )}
                </Stack>
              }
            >
              {params.value}
            </DataGridToolTip>
          );
        },
        flex: 1
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        type: 'string',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        valueGetter: params => params.value?.orgName,
        renderCell: params => {
          return <>{params.row.account?.healthPlan ? params.row.account?.healthPlan?.orgName : 'N/A'}</>;
        }
      },
      {
        field: 'currentAccountsAssignments',
        headerName: 'Assigned To',
        align: 'center',
        headerAlign: 'center',
        sortable: true,
        flex: 1,
        renderCell: params => (
          <ChipsList
            items={
              params.row?.account?.currentAccountsAssignments?.length > 0
                ? params.row?.account.currentAccountsAssignments?.map(user => user.relyingPartyAdmin?.name)
                : ['N/A']
            }
            color="default"
            variant="filled"
            size="small"
          />
        )
      },
      {
        field: 'currentMedsCount',
        headerName: 'Number of Meds',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row?.account.currentMedsCount
      },
      {
        field: 'truentityId',
        headerName: '',
        sortable: false,
        filterable: false,
        flex: 1,
        align: 'center',
        renderCell: params => {
          const id = params.row.account.truentityId;
          return (
            <ActionDropdown
              items={[
                {
                  label: 'Details',
                  callback: () => navigate(`../../patients/${id}/details/medications/triage/list`)
                },
                {
                  label: 'Tasks',
                  callback: () => navigate(`../../patients/${id}/details/medications/tasks/list`)
                },
                {
                  label: 'Profile',
                  callback: () => navigate(`../../providers/${id}/manage`)
                }
              ]}
            />
          );
        }
      }
    ],
    [navigate]
  );

  const onSelectPatient = patient => {
    console.warn('selected', patient);
  };

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  useEffect(() => {
    getInfoByLookupSimplified({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        orgId:
          selectedFilter?.filter?.organization && selectedFilter?.filter.organization?.length > 0 && selectedFilter?.filter.organization
            ? selectedFilter?.filter?.organization
            : ''
      }
    }).catch(err => console.error(err));
  }, [getInfoByLookupSimplified, selectedFilter?.filter]);

  useEffect(() => {
    const accounts = data?.pioneerrxEvents?.accounts || [];
    if (!accounts) {
      return;
    }
    setPatients(accounts);
  }, [data]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Unable to retrieve patients list', {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (selectedFilter?.filter) {
      setCurrentPage(0);
      //need api to load filters
    }
  }, [selectedFilter?.filter]);

  return (
    <Box>
      <Paper component={Stack} direction="column" spacing={2} overflow={'auto'}>
        <TruentityDataGrid
          name={'dg-patient-list-imported'}
          customFilter={<PatientListToolbar />}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          autoHeight
          rows={patients}
          columns={columns}
          disableRowSelectionOnClick
          loading={loading}
          paginationMode={'client'}
          onRowClick={e => onSelectPatient(e.row as PatientsData)}
        />
      </Paper>
    </Box>
  );
};

export default PatientsListWithImportedData;
