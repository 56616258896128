import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMemo } from 'react';
import DeviceManagement from './DeviceManagement';

const RpmDevices = () => {
  const { activeRpmSetupTab } = useRpmSetupStore();

  const isReadOnly = useMemo(() => activeRpmSetupTab?.isReadOnly || false, [activeRpmSetupTab]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <DeviceManagement />
    </BaseRpmWorkflowTabContent>
  );
};

export default RpmDevices;
