import { ADD_RPM_ACTIVITY } from '@/graphql/remotePatientMonitoring';
import type { TruentityDateInput } from '@/types/date';
import { CareActivityTypes, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { secondsToDate } from '@/util/date';
import { formatOnlyDateTime, formatTime, getCurrentDate } from '@/util/format';
import { activityCategoryList, activityTypeList, timeStringToSeconds } from '@/util/rpm';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import { useMutation } from '@apollo/client';
import { Autocomplete, Box, DialogActions, DialogContent, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';
import type { Moment, MomentInput } from 'moment';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityDateTimePicker from '../TruentityDateTimePicker';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import { useMemo } from 'react';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  activityTitle: string;
  rpmEnrolledAtDate: MomentInput | undefined;
  rpmStatus: string;
  hideDialog: (activitiesChanged: boolean) => void;
  onActivitiesChanged: () => void;
};

type FormValues = {
  category: string;
  type: string;
  activityTitle: string;
  startTime: TruentityDateInput;
  timeTaken: TruentityDateInput;
  note: string;
};

const AddActivityDialog = ({
  id,
  rpmEnrolledAtDate,
  rpmStatus,
  title,
  activityTitle,
  hideDialog,
  onActivitiesChanged,
  ...props
}: Props): React.ReactElement => {
  const isCandidateOrScheduledPatent = useMemo(
    () => rpmStatus === RpmStatusTypes.IS_CANDIDATE || rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    [rpmStatus]
  );

  const defaultValues: FormValues = {
    category: isCandidateOrScheduledPatent ? 'Setup' : 'Monthly Care Management',
    type: 'RPM',
    activityTitle,
    startTime: null,
    timeTaken: secondsToDate(0),
    note: ''
  };

  const [addRpmActivity] = useMutation(ADD_RPM_ACTIVITY);
  const { enqueueSnackbar } = useSnackbar();

  const { control, reset, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      ...defaultValues,
      startTime: getCurrentDate()
    }
  });

  const categoryValue = watch('category');

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const isStartTimeGreaterThanRpmEnrolledDate = (startTime: Moment, rpmEnrolledAtDate: Moment): boolean => {
    // If category is SETUP or Rpm Status is IS_CANDIDATE, skip the rpmEnrolledAtDate condition check
    if (categoryValue === CareActivityTypes.SETUP.toLowerCase() || isCandidateOrScheduledPatent) {
      return true;
    } else {
      return startTime.isAfter(rpmEnrolledAtDate);
    }
  };

  const validateInputs = (startTime: Moment, timeTaken: Moment, rpmEnrolledAtDate: Moment): boolean => {
    const isStartTimeValid = !(!startTime || !timeTaken);
    const isStartTimeGreaterThanRpmEnrolled = isStartTimeGreaterThanRpmEnrolledDate(startTime, rpmEnrolledAtDate);

    return isStartTimeValid && isStartTimeGreaterThanRpmEnrolled;
  };

  const prepareInputValues = (values: FormValues) => {
    return {
      title: capitalizeLetterBeforeCharacterAddSpace(values.activityTitle),
      category: capitalizeLetterBeforeCharacterAddSpace(values.category),
      notes: values.note,
      type: values.type.toUpperCase(),
      startTime: formatOnlyDateTime(values.startTime, 'YYYY-MM-DD HH:mm:ss'),
      timeTaken: timeStringToSeconds(formatTime(values.timeTaken, 'mm:ss')),
      isEncounter: false
    };
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const { startTime, timeTaken } = values;
      const validInputs = validateInputs(startTime, timeTaken, rpmEnrolledAtDate);

      if (validInputs) {
        const inputValues = prepareInputValues(values);
        await addRpmActivityMutation(inputValues);
      } else {
        displayErrorMessage('Failed to Create an Activity. Please check your date & time inputs.');
      }
    } catch (error) {
      displayErrorMessage('Failed to Create an Activity');
    }
  };

  const addRpmActivityMutation = async (inputValues: any) => {
    try {
      const response = await addRpmActivity({
        variables: { truentityId: id, ...inputValues }
      });

      const data = response.data!.addRpmActivity;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, { variant });
      onActivitiesChanged();
      hideDialog();
    } catch (error) {
      enqueueSnackbar('Unable to add activity', { variant: 'error' });
      hideDialog();
    }
  };

  const displayErrorMessage = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const titleValues = [
    'Monthly Chart Review',
    'Phone call with patient',
    'Left voicemail for patient/caregiver',
    "Communication with patient's provider/care team",
    'Communication with Tenovi Tech Support',
    'Medication Regimen Review',
    'Med Review/Med Rec',
    'Test strips/lancets replenished',
    'Text message to patient',
    'Notes/care plan review',
    'Face-to-face interaction with patient/caregiver',
    'Misc'
  ];

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box pb={2} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id={'category'}
                  label="Category"
                  options={activityCategoryList
                    .filter(option => !(isCandidateOrScheduledPatent && option.name === 'Monthly Care Management'))
                    .map(x => ({ label: x?.name, value: x?.id }))}
                  placeholder="Select a category"
                  value={value}
                  required
                  MenuProps={{
                    sx: { maxHeight: 220 }
                  }}
                  onChange={onChange}
                  sx={{ marginRight: 1 }}
                />
              )}
            />
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id={'type'}
                  label="Type"
                  options={activityTypeList.map(x => ({ label: x?.name, value: x?.id }))}
                  placeholder="Select a type"
                  value={value}
                  required
                  clearFunction={() => {
                    setValue('type', '');
                  }}
                  MenuProps={{
                    sx: { maxHeight: 220 }
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>

          <Box pb={2}>
            <Controller
              control={control}
              name="activityTitle"
              render={({ field }) => (
                <Autocomplete
                  freeSolo
                  sx={{ width: '100%', marginTop: 1 }}
                  value={field.value}
                  options={titleValues}
                  onChange={(event, newValue) => {
                    setValue('activityTitle', newValue ?? '');
                  }}
                  renderInput={params => (
                    <TextField
                      required
                      {...params}
                      label={`Enter title`}
                      onChange={event => setValue('activityTitle', event.target.value)}
                    />
                  )}
                />
              )}
            />
          </Box>

          <Box pb={2} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Controller
              control={control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <TruentityDateTimePicker
                  label="Start Time"
                  onChange={onChange}
                  sx={{ flex: 1, marginRight: 1 }}
                  TextFieldProps={undefined}
                  required
                  value={value}
                  minDateTime={categoryValue === CareActivityTypes.SETUP.toLowerCase() ? undefined : rpmEnrolledAtDate}
                />
              )}
            />
            <Controller
              control={control}
              name="timeTaken"
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  ampm={false}
                  views={['minutes', 'seconds']}
                  format="mm:ss"
                  slotProps={{
                    textField: {
                      label: 'Time Taken (mm:ss)'
                    }
                  }}
                  sx={{ flex: 1 }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>

          <Box pb={2}>
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoFocus={true}
                  minRows={3}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  multiline
                  rows={8}
                  placeholder="Notes (Optional)"
                />
              )}
            />
          </Box>

          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddActivityDialog;
