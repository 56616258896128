import { CREATE_ACCOUNT_SMART_GOAL } from '@/graphql/remotePatientMonitoring';
import TemplateSelector from '@/routes/PatientDetails/RemotePatientMonitoring/Components/SmartGoals/TemplateSelector';
import type { CustomGoalType, ParsedTemplateResult } from '@/types/remotePatientMonitoring';
import { useMutation } from '@apollo/client';
import { Box, DialogActions, DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

import CompletedGoalsSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/SmartGoals/CompletedGoalsSection';
import GoalSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/SmartGoals/GoalSection';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  truentityId: string | undefined;
  title: string;
  reasonForVisit?: string[];
  hideDialog: () => void;
  onSuccess: () => void;
};

const AddAccountGoalDialog = ({ truentityId, title, reasonForVisit = [], hideDialog, onSuccess, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [refactoredTemplateGoal, setRefactoredTemplateGoal] = useState<ParsedTemplateResult[]>([]);
  const [customGoals, setCustomGoals] = useState<CustomGoalType[]>([]);

  const [createAccountSmartGoal] = useMutation(CREATE_ACCOUNT_SMART_GOAL, {
    onCompleted: res => {
      enqueueSnackbar(res.createAccountSmartGoal.message, { variant: 'success' });
      onSuccess();
    },
    onError: err => {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unable to add the goal for the patient. Please try again later.', { variant: 'error' });
      }
    }
  });

  const handleSaveAccountGoal = (completedTemplates: ParsedTemplateResult[], completedCustomGoals: CustomGoalType[]) => {
    const goals = completedTemplates.map(completedGoal => {
      return {
        smartGoalTemplateId: completedGoal.templateId,
        placeholders: completedGoal.inputs.map(competedInput => {
          return { name: competedInput.key, value: competedInput.value };
        })
      };
    });

    const customGoals = completedCustomGoals.map(completedCustomGoal => completedCustomGoal.value);

    const variables = {
      truentityId,
      goals: goals,
      customGoals: customGoals
    };

    createAccountSmartGoal({ variables });
  };

  const removeSelectedGoal = useCallback(
    (id: string, type: 'CUSTOM' | 'TEMPLATE' = 'TEMPLATE') => {
      if (type === 'TEMPLATE') {
        const templates = refactoredTemplateGoal.filter(template => template.templateId !== id);
        setRefactoredTemplateGoal(templates);
      } else {
        const refactoredCustomGoals = customGoals.filter(goal => goal.id !== id);
        setCustomGoals(refactoredCustomGoals);
      }
    },
    [customGoals, refactoredTemplateGoal]
  );

  const handleEditTemplateGoal = useCallback((id: string, type: 'CUSTOM' | 'TEMPLATE' = 'TEMPLATE') => {
    if (type === 'TEMPLATE') {
      setRefactoredTemplateGoal(currentTemplates =>
        currentTemplates.map(template => (template.templateId === id ? { ...template, isCompleted: false } : template))
      );
    } else {
      setCustomGoals(currentGoal => currentGoal.map(goal => (goal.id === id ? { ...goal, isCompleted: false } : goal)));
    }
  }, []);

  const completedTemplate = useMemo(() => {
    return refactoredTemplateGoal.filter(template => template.isCompleted === true);
  }, [refactoredTemplateGoal]);

  const completedCustomGoals = useMemo(() => {
    return customGoals.filter(goal => goal.isCompleted === true);
  }, [customGoals]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth="md" {...props}>
      <DialogContent>
        <Box>
          <TemplateSelector
            refactoredTemplateGoal={refactoredTemplateGoal}
            setRefactoredTemplateGoal={setRefactoredTemplateGoal}
            reasonForVisit={reasonForVisit}
          />
        </Box>
        <Stack py={2}>
          <GoalSection
            refactoredTemplateGoal={refactoredTemplateGoal}
            customGoals={customGoals}
            setCustomGoals={setCustomGoals}
            setRefactoredTemplateGoal={setRefactoredTemplateGoal}
            removeSelectedGoal={removeSelectedGoal}
          />
          <CompletedGoalsSection
            completedCustomGoals={completedCustomGoals}
            completedTemplate={completedTemplate}
            handleEditTemplateGoal={handleEditTemplateGoal}
            removeSelectedGoal={removeSelectedGoal}
          />
        </Stack>

        <DialogActions>
          <Stack direction="row" gap={1}>
            <Button variant="outlined" onClick={hideDialog}>
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={() => handleSaveAccountGoal(completedTemplate, completedCustomGoals)}
              disabled={completedTemplate.length === 0 && completedCustomGoals.length === 0}
            >
              Save Goals
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddAccountGoalDialog;
