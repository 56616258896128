import { Alert, AlertTitle, DialogContent, Stack } from '@mui/material';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

const TcmSubmissionDialog = ({ hideDialog, ...props }: Props) => {
  return (
    <BaseDialog {...props} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="info">
            <AlertTitle sx={{ margin: '0 auto' }}>
              All steps of this TCM task were successfully completed and the TCM report is ready to be submitted.
            </AlertTitle>
          </Alert>

          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{ alignItems: 'center', justifyContent: 'center', padding: '0 0 0 0' }}
            spacing={1}
          >
            <Button type="submit" a11yLabel={'OK'} onClick={hideDialog} />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default TcmSubmissionDialog;
