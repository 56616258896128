import Button from '@/components/Button';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import { H3 } from '@/components/Typography';
import type { UpdateRelyingPartyResponse } from '@/graphql/account';
import { UPDATE_RELYING_PARTY } from '@/graphql/account';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import type { RelyingParty } from '@/types/graphql';
import { useMutation } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

type FormValues = {
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  email: string;
  phone: string;
};

const defaultValues: FormValues = {
  addressOne: '',
  addressTwo: '',
  city: '',
  state: '',
  email: '',
  phone: ''
};

const ProfileSection: React.FC<{ profileData: RelyingParty }> = ({ profileData }) => {
  const { roleType } = useToken();

  const { enqueueSnackbar } = useSnackbar();
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [updateRelyingParty, { loading: updateRelyingPartyLoading, error: updateRelyingPartyError, data: updateRelyingPartyData }] =
    useMutation<UpdateRelyingPartyResponse>(UPDATE_RELYING_PARTY);

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, reset, handleSubmit, watch } = methods;

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      await updateRelyingParty({
        variables: {
          relyingPartyId: profileData?.id,
          name: profileData?.name,
          logo: profileData?.logo,
          coverLetterBody: profileData?.coverLetterBody,
          addressLine1: values.addressOne,
          addressLine2: values.addressTwo,
          addressCity: values.city,
          addressState: values.state,
          email: values.email,
          phone: values.phone
        }
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not update company details', { variant: 'error' });
    }
  };

  const isAddressOneAvailable = watch('addressOne');
  const isAddressTwoAvailable = watch('addressTwo');
  const isCityAvailable = watch('city');
  const isStateAvailable = watch('state');
  const isEmailAvailable = watch('email');
  const isPhoneAvailable = watch('phone');

  const checkIfAnyFieldAvailable = () => {
    return (
      isAddressOneAvailable !== '' ||
      isAddressTwoAvailable !== '' ||
      isCityAvailable !== '' ||
      isStateAvailable !== '' ||
      isEmailAvailable !== '' ||
      isPhoneAvailable !== ''
    );
  };

  const resetCompanyProfile = useCallback(() => {
    if (profileData) {
      const companyProfile: FormValues = {
        addressOne: profileData.addressLine1 || '',
        addressTwo: profileData.addressLine2 || '',
        city: profileData.addressCity || '',
        state: profileData.addressState || '',
        email: profileData.email || '',
        phone: profileData.phone || ''
      };

      reset(companyProfile);
    }
  }, [reset, profileData]);

  useEffect(() => {
    resetCompanyProfile();
  }, []);

  useEffect(() => {
    if (updateRelyingPartyData?.updateRelyingParty?.status === 'Success') {
      enqueueSnackbar('Company details updated successfully', { variant: 'success' });
    }
  }, [updateRelyingPartyData]);

  useEffect(() => {
    if (updateRelyingPartyError) {
      enqueueSnackbar(updateRelyingPartyError?.message ?? 'Could not update company details', { variant: 'error' });
    }
  }, [updateRelyingPartyError]);

  return (
    <Stack component="form" spacing={3} mt={6} onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <H3>Address</H3>
        <Stack justifyContent={'space-evenly'}>
          <Stack sx={{ width: '50%', marginRight: 1, marginTop: 2 }}>
            <Controller
              control={control}
              name="addressOne"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField disabled={!isSuperAdmin} autoFocus onChange={onChange} value={value} label={'Address Line 1'} />
              )}
            />

            <Controller
              control={control}
              name="addressTwo"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField disabled={!isSuperAdmin} autoFocus onChange={onChange} value={value} label={'Address Line 2'} />
              )}
            />

            <Controller
              control={control}
              name="city"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField disabled={!isSuperAdmin} autoFocus onChange={onChange} value={value} label={'City'} />
              )}
            />

            <Controller
              control={control}
              name="state"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField disabled={!isSuperAdmin} autoFocus onChange={onChange} value={value} label={'State'} />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack mt={1}>
        <H3>Primary Contact</H3>
        <Stack justifyContent={'space-evenly'}>
          <Stack sx={{ width: '50%', marginRight: 1, marginTop: 2 }}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <TruentityTextField autoFocus disabled={!isSuperAdmin} onChange={onChange} value={value} label={'Email Address'} />
              )}
            />

            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value } }) => (
                <TruentityPhoneNumber
                  autoFocus
                  isDisabled={!isSuperAdmin}
                  value={value}
                  onChange={onChange}
                  editable={true}
                  label={'Phone Number'}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>

      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            variant="outlined"
            label={'Reset form'}
            isLoading={updateRelyingPartyLoading}
            disabled={!checkIfAnyFieldAvailable()}
            onClick={resetCompanyProfile}
            size="large"
          />
        </Grid>
        <Grid item>
          <Button
            label={'Save details'}
            isLoading={updateRelyingPartyLoading}
            type="submit"
            disabled={!checkIfAnyFieldAvailable()}
            size="large"
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProfileSection;
