import { MedHistoryRequestsFileType, MedHistoryRequestsType } from '@/types/medHistory';
import { gql } from '@apollo/client';

export const REQUEST_MED_HISTORY_MEDICATIONS = gql`
  mutation RequestMedHistoryMedications($truentityIds: [ID!]!) {
    requestMedHistoryMedications(truentityIds: $truentityIds) {
      status
      message
    }
  }
`;

export type GetAccountMedHistoryResponse = {
  getAccountMedHistoryRequests: {
    accountMedHistoryRequests: MedHistoryRequestsType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_ACCOUNT_MED_HISTORY = gql`
  query GetAccountMedHistoryRequestsQuery($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountMedHistoryFilterOptionsInput) {
    getAccountMedHistoryRequests(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountMedHistoryRequests {
        id
        transmissionControlNumber
        medCount
        medNote
        responseFileStatus
        medResponseStatus
        account {
          truentityId
          birthDate
          user {
            id
            firstName
            lastName
          }
        }
        relyingPartyAdmin {
          name
          email
        }
        createdAt
        requestedOn
        respondedOn
        medReceivedOn
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetAccountMedHistoryFilesResponse = {
  getAccountMedHistoryFiles: {
    accountMedHistoryFiles: MedHistoryRequestsFileType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_ACCOUNT_MED_HISTORY_FILES = gql`
  query GetAccountMedHistoryFilesQuery($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountMedHistoryFileFilterOptionsInput) {
    getAccountMedHistoryFiles(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountMedHistoryFiles {
        id
        fileName
        externalCorrelationId
        source
        transmissionControlNumber
        fileRequestedOn
        fileRespondedOn
        flatFileReceivedOn
        additionalDetails
        medCount
        responseFileStatus
        medResponseStatus
        createdAt
        totalPatientCount
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetAccountMedHistoryFileByIdTypes = {
  getMedHistoryFileById: MedHistoryRequestsFileType;
};

export const GET_ACCOUNT_MED_HISTORY_FILE_BY_ID = gql`
  query GetMedHistoryFileByIdQuery($medRequestFileId: ID!) {
    getMedHistoryFileById(medRequestFileId: $medRequestFileId) {
      id
      fileName
      externalCorrelationId
      source
      transmissionControlNumber
      fileRequestedOn
      fileRespondedOn
      flatFileReceivedOn
      additionalDetails
      responseFileStatus
      medResponseStatus
      createdAt
      totalPatientCount
      associatedAccounts {
        account {
          id
          truentityId
          user {
            firstName
            lastName
          }
        }
        totalMedsCount
      }
    }
  }
`;
