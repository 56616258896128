import type { FormValues } from '@/types/dischargePatient';
import { ADMIT_OPTIONS, GENDER_OPTIONS, TYPE_OPTIONS } from '@/types/dischargePatient';
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import Button from '@/components/Button';
import { DischargePatientDialog } from '@/components/Dialogs';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { DISCHARGE_PATIENT } from '@/graphql/transitionalCareManagement';
import { useMutation } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

const defaultValues: FormValues = {} as FormValues;

const DischargePatientDetailsForm = ({ patientDetails }) => {
  const [selected, setSelected] = useState<string>('');
  const [showDischargeDetails, setShowDischargeDetails] = useState(false);
  const [admitFromOptions, setAdmitFromOptions] = useState('');

  const { control, handleSubmit, reset, getValues } = useForm<FormValues>({ defaultValues: defaultValues });
  const [dischargePatient] = useMutation(DISCHARGE_PATIENT);
  const { showModal } = useModal();

  useEffect(() => {
    if (selected === 'admit') {
      setShowDischargeDetails(true);
    } else {
      setShowDischargeDetails(false);
    }
  }, [selected, showDischargeDetails]);

  const onSuccess = (success: boolean) => {
    const modalRef = showModal(DischargePatientDialog, {
      title: 'Discharge Patient',
      hideDialog: () => modalRef.hide(),
      success: success === true,
      onConfirm: () => {
        modalRef.hide();
        reset(defaultValues);
      }
    });
  };

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    const patientData = {
      firstName: patientDetails[0]?.user?.firstName || '',
      lastName: patientDetails[0]?.user?.lastName || '',
      middleName: patientDetails[0]?.user?.middleName || '',
      gender: patientDetails[0]?.gender,
      zipcode: patientDetails[0]?.zipcode,
      dateOfBirth: patientDetails[0]?.birthDate,
      accountCareEventData: {
        source: 'Patient Ping',
        eventType: selected,
        eventDate: '2022-12-22',
        eventFacilityType: 'XYZ',
        eventActionType: 'C1',
        dischargeDate: values?.dischargeDate,
        dischargeLocation: values?.dischargeLocation,
        dischargedDisposition: 'D1',
        admittedFrom: admitFromOptions,
        admissionDate: values?.admissionDate,
        admissionReason: values?.admissionReason,
        primaryDiagnosisCode: 'CD-102',
        primaryDiagnosisDescription: 'COPD',
        readmissionRiskFlag: true
      }
    };
    try {
      await dischargePatient({
        variables: patientData
      });

      onSuccess(true);
      reset(defaultValues);
    } catch (error) {
      console.error(error);
      onSuccess(false);
    }
  };

  return (
    <Grid container item component={'form'} onSubmit={handleSubmit(onSubmit)}>
      <Grid xs>
        <Stack spacing={2} mt={4}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField
                disabled
                autoFocus
                onChange={onChange}
                value={patientDetails[0]?.user?.firstName || value}
                label={'First Name'}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField disabled onChange={onChange} value={patientDetails[0]?.user?.lastName || value} label={'Last Name'} />
            )}
          />

          <Controller
            control={control}
            name="middleName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField disabled onChange={onChange} value={patientDetails[0]?.user?.middleName || value} label={'Middle Name'} />
            )}
          />

          <Controller
            defaultValue={null}
            control={control}
            name="dateOfBirth"
            render={({ field: { onChange, value } }) => (
              <TruentityDatePicker disabled onChange={onChange} value={patientDetails[0]?.birthDate || value} label={'Date of Birth'} />
            )}
          />

          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <SelectList
                disabled
                options={GENDER_OPTIONS}
                placeholder="Select an option..."
                onChange={onChange}
                value={patientDetails[0]?.gender || value}
                label={'Gender'}
              />
            )}
          />
          <Controller
            control={control}
            name="zipcode"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField disabled onChange={onChange} value={patientDetails[0]?.zipcode || value} label={'Zipcode'} />
            )}
          />

          <Controller
            control={control}
            name="zipcode"
            render={({ field: { onChange, value } }) => (
              <SelectList
                value={selected}
                placeholder="Select an option"
                label={'Type'}
                onChange={e => setSelected(e.target.value as string)}
                options={TYPE_OPTIONS}
              ></SelectList>
            )}
          />
          {!showDischargeDetails && selected !== '' ? (
            <>
              <Controller
                defaultValue={null}
                control={control}
                name="dischargeDate"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker onChange={onChange} value={value} label={'Discharge Date'} />
                )}
              />

              <Controller
                defaultValue={null}
                control={control}
                name="dischargeLocation"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField onChange={onChange} value={value} label={'Discharge Location'} />
                )}
              />
              <Controller
                defaultValue={null}
                control={control}
                name="admissionDate"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker onChange={onChange} value={value} label={'Admission Date'} />
                )}
              />

              <Controller
                control={control}
                name="admissionReason"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField onChange={onChange} value={value} label={'Admission Reason'} multiline rows={4} />
                )}
              />
            </>
          ) : showDischargeDetails && selected !== '' ? (
            <>
              <Controller
                defaultValue={null}
                control={control}
                name="admissionDate"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker onChange={onChange} value={value} label={'Admission Date'} />
                )}
              />

              <Controller
                control={control}
                name="admitFrom"
                render={({ field: { onChange, value } }) => (
                  <SelectList
                    defaultValue={'Other'}
                    value={admitFromOptions}
                    placeholder="Select an option"
                    label={'Type'}
                    onChange={e => setAdmitFromOptions(e.target.value as string)}
                    options={ADMIT_OPTIONS}
                  ></SelectList>
                )}
              />

              <Controller
                control={control}
                name="admissionReason"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField onChange={onChange} value={value} label={'Admission Reason'} multiline rows={4} />
                )}
              />
            </>
          ) : (
            <></>
          )}

          <Button type="submit" a11yLabel="Discharge" appearance="primary" />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DischargePatientDetailsForm;
