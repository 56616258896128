import { useLayoutEffect, useRef, useState } from 'react';

export default function useScrollBarPosition() {
  const [top, setTop] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const _ref = ref.current;

    function getTopPosition() {
      if (_ref) {
        const topPosition = _ref.scrollTop;
        setTop(topPosition);
      }
    }
    _ref?.addEventListener('scroll', getTopPosition);
    getTopPosition();
    return () => _ref?.removeEventListener('scroll', getTopPosition);
  }, []);
  return { ref, top };
}
