import Button from '@/components/Button';
import { CommentsMedRecDialog } from '@/components/Dialogs';
import Pagination from '@/components/Pagination';
import { H5 } from '@/components/Typography';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { color } from '@/styles/assets/colors';
import { buildValues } from '@/util/medications';
import { useModal } from 'mui-modal-provider';
import React, { useState } from 'react';

export type HistoryTableProps = {
  accountMedications: [];
  called: boolean;
  loading: boolean;
  currentPage: number;
  totalCount: number;
  totalPages: number;
  onUpdateData: any;
};

const HistoryTable = ({ accountMedications, called, loading, currentPage, totalCount, totalPages, onUpdateData }: HistoryTableProps) => {
  const [currentMed, setcurrentMed] = useState<string>('');
  const { showModal } = useModal();

  const showCommentsModal = () => {
    const modalRef = showModal(CommentsMedRecDialog, {
      title: 'Comments',
      medicationId: currentMed,
      hideDialog: () => modalRef.hide()
    });
  };

  const isDataLoaded = () => called && !loading && accountMedications;

  const isThereData = () => isDataLoaded() && totalCount > 0;

  const handleMedicationSelected = item => {
    setcurrentMed(item.id);
    showCommentsModal();
  };

  const renderItems = item => {
    const prescribedStrength = buildValues(item.strength, true);
    const prescribedDosage = buildValues(item.dosage, true);
    const prescribedPeriod = buildValues(item.period, true);

    const modifiedStrength = buildValues(item.strength, false);
    const modifiedDosage = buildValues(item.period, false);
    const modifiedPeriod = buildValues(item.dosage, false);

    const isModifiedValues = modifiedStrength !== '---' || modifiedPeriod !== '---' || modifiedDosage !== '---';
    return (
      <React.Fragment key={item.id}>
        <TableRow noShadow>
          <TableCell style={{ padding: '0.125rem 1.25rem', borderBottom: 'none' }} colSpan={8}>
            <H5 color={color.truentityCyan[600]} fontWeight="normal">
              {item.name}
            </H5>
          </TableCell>
        </TableRow>
        <TableRow noShadow={isModifiedValues}>
          <TableCell>Prescribed</TableCell>
          <TableCell>{prescribedStrength}</TableCell>
          <TableCell>{prescribedDosage}</TableCell>
          <TableCell>{prescribedPeriod}</TableCell>
          <TableCell>{item.isEffective ? 'Yes' : 'No'}</TableCell>
          <TableCell>{item.instructions ? item.instructions : '-'}</TableCell>
          <TableCell>{item.adherance ? item.adherance : '-'}</TableCell>
          <TableCell>{item.prescriberName}</TableCell>
          <TableCell>
            <Button a11yLabel="Comments" appearance="plainBlue" onClick={() => handleMedicationSelected(item)} />
          </TableCell>
        </TableRow>

        {isModifiedValues && (
          <TableRow>
            <TableCell style={{ color: color.errorDark }}> Modified</TableCell>
            <TableCell style={{ color: color.errorDark }}>{modifiedStrength}</TableCell>
            <TableCell style={{ color: color.errorDark }}>{modifiedDosage}</TableCell>
            <TableCell style={{ color: color.errorDark }}>{modifiedPeriod}</TableCell>
            <TableCell style={{ color: color.errorDark }}> </TableCell>
            <TableCell style={{ color: color.errorDark }}> </TableCell>
            <TableCell style={{ color: color.errorDark }}> </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeading></TableHeading>
            <TableHeading>Strength</TableHeading>
            <TableHeading>Dosage</TableHeading>
            <TableHeading>Frequency</TableHeading>
            <TableHeading>Effective</TableHeading>
            <TableHeading>Indication</TableHeading>
            <TableHeading>Adherance</TableHeading>
            <TableHeading>Prescriber</TableHeading>
            <TableHeading>Comments</TableHeading>
          </TableRow>
        </TableHead>
        <tbody>{accountMedications.map(renderItems)}</tbody>
      </Table>
      {isThereData() && (
        <Pagination
          currentPage={currentPage}
          totalRecords={totalCount}
          pageLimit={totalPages}
          pageNeighbours={2}
          onPageChanged={onUpdateData}
        />
      )}
    </>
  );
};

export default HistoryTable;
