import Button from '@/components/Button';
import TruentityTextField from '@/components/TruentityTextField';
import { Body1, Caption2, H5 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import type { CustomGoalType, ParsedTemplateResult } from '@/types/remotePatientMonitoring';
import { RemoveCircleOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { Box, IconButton, Stack } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  refactoredTemplateGoal: ParsedTemplateResult[];
  customGoals: CustomGoalType[];
  setCustomGoals: Dispatch<SetStateAction<CustomGoalType[]>>;
  setRefactoredTemplateGoal: Dispatch<SetStateAction<ParsedTemplateResult[]>>;
  removeSelectedGoal: (id: string, type?: 'CUSTOM' | 'TEMPLATE') => void;
};

const GoalSection = ({ refactoredTemplateGoal, customGoals, setCustomGoals, setRefactoredTemplateGoal, removeSelectedGoal }: Props) => {
  const handleCustomGoal = () => {
    const goalId = uuidv4() as string;

    const customGoal: CustomGoalType = {
      id: goalId,
      value: '',
      isCompleted: false
    };

    setCustomGoals([...customGoals, customGoal]);
  };

  const handleInputChange = useCallback(
    (key: string, value: string, templateId: string) => {
      setRefactoredTemplateGoal(currentTemplates =>
        currentTemplates.map(template =>
          template.templateId === templateId
            ? {
                ...template,
                inputs: template.inputs.map(input => (input.key === key ? { ...input, value } : input))
              }
            : template
        )
      );
    },
    [setRefactoredTemplateGoal]
  );

  const handleSaveTemplateGoal = useCallback(
    (id: string, type: 'CUSTOM' | 'TEMPLATE' = 'TEMPLATE') => {
      if (type === 'TEMPLATE') {
        setRefactoredTemplateGoal(currentTemplates =>
          currentTemplates.map(template => (template.templateId === id ? { ...template, isCompleted: true } : template))
        );
      } else {
        setCustomGoals(currentGoal => currentGoal.map(goal => (goal.id === id ? { ...goal, isCompleted: true } : goal)));
      }
    },
    [setCustomGoals, setRefactoredTemplateGoal]
  );

  const handleCustomGoalInputChange = useCallback(
    (id: string, value: string) => {
      setCustomGoals(customGoal =>
        customGoal.map(goal =>
          goal.id === id
            ? {
                ...goal,
                value: value
              }
            : goal
        )
      );
    },
    [setCustomGoals]
  );

  const generateDynamicNode = useCallback(
    (parsedTemplate: ParsedTemplateResult) => {
      const { template } = parsedTemplate;
      const templateParts = template.templateText.split(/(<<[^>]+>>)/g);

      const dynamicNode = templateParts.map((part, index) => {
        const match = part.match(/<<(.+?)>>/);
        if (match) {
          const placeholder = match[1];
          const validation = template.validationRules[placeholder];
          const inputType = validation?.type === 'Integer' ? 'number' : 'text';

          const value = parsedTemplate.inputs.find(input => input.key.toLowerCase() === placeholder.toLowerCase())?.value || '';

          return (
            <TruentityTextField
              key={placeholder}
              type={inputType}
              id={`${parsedTemplate.templateId}`}
              size="small"
              margin="none"
              focused
              value={value}
              onChange={e => handleInputChange(placeholder, e.target.value, template.id)}
              sx={{
                width: '60px',
                margin: 0.5,
                display: 'inline-flex',
                '& .MuiOutlinedInput-input': {
                  textAlign: 'center',
                  fontWeight: 'bold'
                }
              }}
            />
          );
        } else {
          return (
            <Body1 key={index} sx={{ display: 'inline', mx: 0.5, lineHeight: '3rem' }}>
              {part}
            </Body1>
          );
        }
      });

      const allInputsFilled = parsedTemplate.inputs.every(input => input.value.trim() !== '');

      return (
        <Stack
          direction="row"
          alignItems="center"
          borderRadius={2}
          padding={1}
          paddingLeft={2}
          my={0.5}
          key={template.id}
          bgcolor={color.grey100}
        >
          <Box>{dynamicNode}</Box>
          <Box>
            <Stack direction="row" gap={0.5}>
              <IconButton edge="end" onClick={() => handleSaveTemplateGoal(template.id)} disabled={!allInputsFilled}>
                <SaveIcon fontSize="small" />
              </IconButton>
              <IconButton edge="end" onClick={() => removeSelectedGoal(template.id)}>
                <RemoveCircleOutline fontSize="small" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      );
    },
    [handleInputChange, handleSaveTemplateGoal, removeSelectedGoal]
  );

  const generateDynamicCustomGoalNode = useCallback(
    (customGoal: CustomGoalType) => {
      const dynamicNode = (
        <TruentityTextField
          key={customGoal.id}
          type="text"
          id={`${customGoal.id}`}
          size="small"
          margin="none"
          fullWidth
          focused
          value={customGoal.value}
          onChange={e => handleCustomGoalInputChange(customGoal.id, e.target.value)}
          sx={{
            margin: 0.5,
            display: 'inline-flex',
            '& .MuiOutlinedInput-input': {
              fontWeight: 'bold'
            }
          }}
        />
      );

      return (
        <Stack direction="row" alignItems="center" borderRadius={2} padding={1} paddingLeft={2} key={customGoal.id} bgcolor={color.grey100}>
          <Box width="100%">{dynamicNode}</Box>
          <Box>
            <Stack direction="row" gap={0.5}>
              <IconButton
                edge="end"
                onClick={() => handleSaveTemplateGoal(customGoal.id, 'CUSTOM')}
                disabled={customGoal.value.length === 0}
              >
                <SaveIcon fontSize="small" />
              </IconButton>
              <IconButton edge="end" onClick={() => removeSelectedGoal(customGoal.id, 'CUSTOM')}>
                <RemoveCircleOutline fontSize="small" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      );
    },
    [handleCustomGoalInputChange, handleSaveTemplateGoal, removeSelectedGoal]
  );

  const selectedTemplate = useMemo(() => {
    return refactoredTemplateGoal.filter(template => template.isCompleted === false);
  }, [refactoredTemplateGoal]);

  const inProgressCustomGoals = useMemo(() => {
    return customGoals.filter(goal => goal.isCompleted === false);
  }, [customGoals]);

  return (
    <Box sx={{ p: 1, border: '1px solid', borderColor: theme => theme.palette.divider, backgroundColor: color.grey50, borderRadius: 1 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <H5>Complete the goal</H5>
        <Button size="small" variant="text" startIcon={<AddIcon fontSize="small" />} onClick={handleCustomGoal}>
          Add Custom Goal
        </Button>
      </Stack>
      {selectedTemplate.length > 0 || inProgressCustomGoals.length > 0 ? (
        <>
          <>{selectedTemplate.map(template => generateDynamicNode(template))}</>
          <>{inProgressCustomGoals.map(customGoal => generateDynamicCustomGoalNode(customGoal))}</>
        </>
      ) : (
        <Box py={3} textAlign="center">
          <Caption2>No goal selected</Caption2>
        </Box>
      )}
    </Box>
  );
};

export default GoalSection;
