import { TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { H4 } from '@/components/Typography';
import { GetAccountDiagnosisResponseType } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { Chip, LinearProgress, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';

interface Props {
  accountDiagnosis: GetAccountDiagnosisResponseType | undefined;
  accountDiagnosisLoading: boolean;
}

type GetAccountDiagnosisType = GetAccountDiagnosisResponseType['getAccountDiagnoses'];

const AccountsDiagnoses = ({ accountDiagnosis, accountDiagnosisLoading }: Props) => {
  const [accountDiagnoses, setAccountDiagnoses] = useState<GetAccountDiagnosisType>({} as GetAccountDiagnosisType);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Diagnoses Name',
        sortable: true,
        flex: 1,
        align: 'left',
        renderCell: params => (
          <Chip size="small" label={params?.row?.name} sx={{ backgroundColor: color.truentityBlue[50], color: color.truentityBlue[700] }} />
        )
      },
      {
        field: 'system',
        headerName: 'Coding System',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => params?.row?.system.toUpperCase() ?? '-'
      },
      {
        field: 'code',
        headerName: 'Code',
        sortable: true,
        flex: 1,
        align: 'left'
      }
    ],
    []
  );

  useEffect(() => {
    if (accountDiagnosis && !accountDiagnosisLoading && accountDiagnosis.getAccountDiagnoses) {
      setAccountDiagnoses(accountDiagnosis.getAccountDiagnoses);
    }
  }, [accountDiagnosis, accountDiagnosisLoading]);

  if (accountDiagnoses?.diagnoses?.length === 0 || !accountDiagnoses.diagnoses) return null;

  return (
    <Stack rowGap={2} mt={4}>
      <H4>Clinical Diagnoses</H4>
      <TruentityDataGrid
        name={'dg-account-diagnoses'}
        autoHeight
        pagination={false}
        slots={{
          loadingOverlay: LinearProgress
        }}
        loading={accountDiagnosisLoading}
        disableRowSelectionOnClick
        hideFooter
        hideFooterSelectedRowCount
        hideFooterPagination
        columns={columns}
        rows={accountDiagnoses?.diagnoses || []}
      />
    </Stack>
  );
};

export default AccountsDiagnoses;
