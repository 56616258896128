import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import { useLazyQuery } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { H3 } from '@/components/Typography';
import { GET_ACCOUNT_TCM_TASKS } from '@/graphql/transitionalCareManagement';
import { toDateOrNull } from '@/util/date';

const TCMTasks = () => {
  const { id } = useParams();
  const { setContent } = useContext(SideMenuContext);
  const [getAccountTCMTasks, { loading: loadingAccountTcmTasks, data, refetch, called: accountTcmTasksCalled }] =
    useLazyQuery(GET_ACCOUNT_TCM_TASKS);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [accountTCMTasks, setAccountTCMTasks] = useState([]);

  const callGetAccountTcmTasks = () => {
    getAccountTCMTasks({
      variables: {
        truentityId: id,
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  };

  useEffect(() => {
    callGetAccountTcmTasks();
    setContent(<MainSideMenu />);
  }, []);

  useEffect(() => {
    callGetAccountTcmTasks();
  }, [currentPage]);

  useEffect(() => {
    if (data) {
      setAccountTCMTasks(data?.tcmTasks?.tcmTasks);
      setRowCount(data?.tcmTasks?.meta?.totalCount || 0);
    }
  }, [data]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'tcmTaskType',
        headerName: 'TCM Task Type',
        minWidth: 160,
        sortable: true,
        flex: 1,
        valueGetter: params => params.row.tcmTaskType,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'dueDate',
        headerName: 'Due Date',
        sortable: true,
        minWidth: 160,
        type: 'date',
        valueGetter: params => toDateOrNull(params.row.dueDate),
        renderCell: params => {
          return moment(params.row.dueDate).format('MMM DD, YYYY');
        },
        headerAlign: 'left',
        align: 'center'
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        type: 'date',
        sortable: true,
        valueGetter: params => new Date(params.value),
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          return moment(params.row.createdAt).format('MMM DD, YYYY');
        }
      }
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <H3 textAlign="center" css={{ width: '100%' }}>
        TCM Tasks List
      </H3>

      <Paper component={Stack} direction="column">
        <div style={{ display: 'flex', marginTop: '40px' }}>
          <TruentityDataGrid
            name={'dg-tcm-tasks'}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => setCurrentPage(page)}
            autoHeight
            rows={accountTCMTasks}
            rowCount={rowCountState}
            columns={columns}
            paginationMode="server"
            loading={loadingAccountTcmTasks}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default TCMTasks;
