import type { ChipProps } from '@mui/material';
import { Chip, Stack } from '@mui/material';
import ChipLabel from './ChipLabel';

type Props = ChipProps & {
  items: string[];
  onClick?: () => void;
  useAvatar?: boolean;
  spacing?: number;
  maxItemsToShow?: number;
};
const ChipsList = ({ items = [], onClick, size, variant, useAvatar, spacing, maxItemsToShow, ...props }: Props) => {
  size = size || 'medium';
  variant = variant || 'filled';
  maxItemsToShow = maxItemsToShow || items.length;

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      spacing={spacing}
      sx={{ flexWrap: 'wrap', overflow: 'auto', maxHeight: '100%', maxWidth: '100%' }}
      onClick={onClick}
    >
      {items.slice(0, maxItemsToShow).map((item, index) => {
        return (
          <Chip
            {...props}
            variant={variant}
            size={size}
            key={index}
            sx={{ overflow: 'hidden', userSelect: 'none', cursor: onClick ? 'pointer' : 'default', ...props.sx }}
            label={<ChipLabel item={item} useAvatar={useAvatar === true} />}
          />
        );
      })}

      {items.length > maxItemsToShow && <Chip variant="outlined" size="small" label={`+${items.length - maxItemsToShow}`} />}
    </Stack>
  );
};

export default ChipsList;
