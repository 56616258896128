import Button from '@/components/Button';
import type { PatientFilter, PatientListToolbarFilters } from '@/zustand/patientFilterStore';
import { DialogContent, Stack, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type FormValues = PatientFilter;

const defaultValues: { name: string; description: string } = {
  name: '',
  description: ''
};

type Props = BaseDialogProps & {
  hideDialog: () => void;
  activeFilter: PatientListToolbarFilters;
  onSave: (filter: PatientFilter) => void;
};

const SaveFilterDialog = ({ title, hideDialog, activeFilter, onSave, ...props }: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    const patientFilter: PatientFilter = {
      ...values,
      filter: activeFilter
    };
    onSave(patientFilter);
    hideDialog();
  };

  return (
    <BaseDialog canNavigateBack={false} title={title} hideDialog={hideDialog} {...props} maxWidth={'xs'} fullWidth={true}>
      <DialogContent>
        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField autoFocus required {...register('name')} label="Name " fullWidth variant="outlined" margin="dense" />
          {/*<TextField*/}
          {/*  multiline={true}*/}
          {/*  rows={4}*/}
          {/*  {...register('description')}*/}
          {/*  label="Description (optional)"*/}
          {/*  fullWidth*/}
          {/*  variant="outlined"*/}
          {/*  margin="dense"*/}
          {/*/>*/}

          <DialogActions>
            <Button variant="outlined" type="reset" label="Cancel" onClick={hideDialog} />
            <Button type="submit" label="Save" />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default SaveFilterDialog;
