import { gql, useMutation } from '@apollo/client';
import { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';

import styled from '@/styles';

import PatientDetailContext from '@/context/patientDetailContext';
import { Stack } from '@mui/material';
import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack';

const PRESIGN_URL = gql`
  mutation PresignUrl {
    presignedUploadMedsExternal {
      key
      url
      getUrl
    }
  }
`;

const InputRow = styled('div', {
  '@smallDesktop': {
    width: '50%'
  },

  marginBottom: '1.25rem'
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  '@smallDesktop': {
    width: '50%'
  }
});

const Label = styled('label', {
  display: 'block',
  color: '#777777',
  fontSize: '$md',
  fontWeight: '$bold'
});

const UploadFile = () => {
  const navigate = useNavigate();

  const [base64, setBase64] = useState('');
  const { id } = useParams();
  const [presignUrl] = useMutation(PRESIGN_URL);
  const { setS3_key } = useContext(PatientDetailContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = useCallback(event => {
    event.preventDefault();
    navigate(`/patients/${id}/details`, {
      replace: true
    });
  }, []);

  const presignedUpload = async (base64: string) => {
    try {
      const {
        data: {
          presignedUploadMedsExternal: { key: s3Key, url }
        }
      } = await presignUrl();

      await fetch(url, {
        method: 'PUT',
        body: Buffer.from(base64, 'base64'),
        headers: {
          'Content-Encoding': 'base64',
          'Content-Type': 'text/csv'
        }
      });
      setS3_key(s3Key);
      onSuccess(true);

      navigate(`/patients/${id}/details/import-preview`, {
        replace: true
      });
    } catch (error) {
      console.log(error);
      onSuccess(false);
    }
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function () {
      const base64Encoded = window.btoa(reader.result);
      presignedUpload(base64Encoded);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleOnSubmit = useCallback(event => {
    event.preventDefault();
    var file = document.getElementById('file').files[0];
    getBase64(file);
  }, []);

  const onSuccess = (success: boolean) => {
    const message = success ? 'File Uploaded' : 'Something went wrong, try again';
    const variant = success ? 'success' : 'error';

    enqueueSnackbar(message, { variant });
  };

  return (
    <Stack>
      <form style={{ padding: '20px' }} onSubmit={handleOnSubmit}>
        <InputRow>
          <Label htmlFor="file">Choose a File:</Label>
          <input type="file" name="file" id="file" accept=".csv" required />
        </InputRow>

        <ButtonsContainer>
          <Button type="submit" a11yLabel="Submit" appearance="primary" />
          <Button a11yLabel="Cancel" appearance="outline" onClick={handleCancel} />
        </ButtonsContainer>
      </form>
    </Stack>
  );
};

export default UploadFile;
