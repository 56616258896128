import { DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import AddUserForm from '@/routes/Administration/Components/AddUserForm';
import useAddNewUser from '@/hooks/forms/useAddNewUser';
import { UserFormMode } from '@/types/utils';

type AddUserDialogProps = BaseDialogProps & {
  hideDialog: () => void;
  onUserAdd?: () => void;
};

const AddUserDialog = ({ title, hideDialog, onUserAdd, ...props }: AddUserDialogProps) => {
  const { control, setValue, getValues, watch, reset, addUserFormState, onSubmit } = useAddNewUser({ onUserAdd });

  const handleCancelDialog = () => {
    reset();
    hideDialog();
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack component={'form'} spacing={1} onSubmit={onSubmit}>
          <Grid item xs={12} justifyContent="flex-end">
            <AddUserForm
              formMode={UserFormMode.EXISTING_COMPANY}
              control={control}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              hideHeader={true}
            />
            <DialogActions sx={{ justifyContent: 'end', padding: '30px 0 0 0' }}>
              <Button
                disabled={!addUserFormState?.isDirty || !addUserFormState?.isValid}
                type="submit"
                onClick={onSubmit}
                a11yLabel="Save"
                appearance="primary"
              />
              <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={handleCancelDialog} />
            </DialogActions>
          </Grid>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddUserDialog;
