import ConfirmHealthCarePatientAttributesDialog from '@/components/Dialogs/ConfirmHealthCarePatientAttributesDialog';
import { GetAccountMedHistoryResponse, GET_ACCOUNT_MED_HISTORY, REQUEST_MED_HISTORY_MEDICATIONS } from '@/graphql/medHistory';
import { MedHistoryRequestsType, MedHistoryStatusType } from '@/types/medHistory';
import { MuiColor } from '@/types/mui';
import { formatDate } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Chip, DialogContent, Stack, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '../DataGrid/TruentityDataGrid';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  hideDialog: () => void;
};

export const medHistoryStatusColors = new Map<string, MuiColor>([
  [MedHistoryStatusType.PENDING, 'default'],
  [MedHistoryStatusType.REQUESTED, 'info'],
  [MedHistoryStatusType.SUCCESS, 'success'],
  [MedHistoryStatusType.FAILED, 'error']
]);

const MedHistoryRequestsDialog = ({ title, hideDialog, ...props }: Props) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [medHistoryData, setMedHistoryData] = useState<MedHistoryRequestsType[]>([]);

  const {
    data: medHistoryRequestData,
    refetch: medHistoryRequestRefetch,
    loading: medHistoryRequestLoading
  } = useQuery<GetAccountMedHistoryResponse>(GET_ACCOUNT_MED_HISTORY, {
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        truentityId: id
      }
    }
  });
  const [requestMedication, { loading: requestMedicationLoading }] = useMutation(REQUEST_MED_HISTORY_MEDICATIONS);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'DateRequestedOn',
        headerName: 'Date Requested On',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params?.row?.createdAt) ?? 'N/A'
      },
      {
        field: 'NumberOfMedicationReceived',
        headerName: 'Number of Medications Received',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params?.row?.medCount ?? 'N/A'
      },
      {
        field: 'MedStatus',
        headerName: 'Med Status',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          if (params?.row?.medResponseStatus) {
            const note = params.row.medNote;
            const isNoteAvailable = !!note;
            const chipParam = {
              label: params?.row?.medResponseStatus,
              color: medHistoryStatusColors.get(params?.row?.medResponseStatus) ?? 'warning',
              varient: 'filled',
              sx: { cursor: !isNoteAvailable ? 'default' : 'pointer' }
            };
            return isNoteAvailable ? (
              <Tooltip title={note} hidden={!isNoteAvailable} followCursor>
                <Chip {...chipParam} />
              </Tooltip>
            ) : (
              <Chip {...chipParam} />
            );
          } else {
            return <Chip label="PENDING" color="default" variant="filled" />;
          }
        }
      }
    ],
    []
  );

  const confirmPatientInfo = () => {
    const modal = showModal(ConfirmHealthCarePatientAttributesDialog, {
      title: 'Please confirm the Patient Details',
      onPatientUpdated: () => {
        handelNewRequest();
        modal.hide();
      },
      skipDialog: () => {
        handelNewRequest();
        modal.hide();
      },
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const handelNewRequest = useCallback(() => {
    requestMedication({
      variables: {
        truentityIds: [id]
      }
    })
      .then(res => {
        const data = res?.data?.requestMedHistoryMedications;

        if (Array.isArray(data?.status)) {
          data?.status.map(status => {
            const variant = status === 'Success' ? 'success' : 'error';
            if (variant === 'error') {
              enqueueSnackbar(data.message, { variant });
            } else {
              enqueueSnackbar(data.message, { variant });
              medHistoryRequestRefetch();
            }
          });
        } else {
          const variant = data?.status === 'Success' ? 'success' : 'error';
          if (variant === 'error') {
            enqueueSnackbar('Failed to schedule medication request', { variant });
          } else {
            enqueueSnackbar(data.message, { variant });
            medHistoryRequestRefetch();
          }
        }
      })
      .catch(err => {
        enqueueSnackbar('Failed to schedule medication request', {
          variant: 'error'
        });
      });
  }, [id, medHistoryRequestRefetch]);

  useEffect(() => {
    if (medHistoryRequestData && medHistoryRequestData?.getAccountMedHistoryRequests?.accountMedHistoryRequests) {
      setMedHistoryData(medHistoryRequestData?.getAccountMedHistoryRequests?.accountMedHistoryRequests ?? []);
    }
  }, [medHistoryRequestData]);

  return (
    <BaseDialog
      title={title}
      fullWidth
      maxWidth={'lg'}
      headerElement={
        <Button
          variant="contained"
          disableElevation
          fullWidth={false}
          startIcon={<AddIcon />}
          onClick={confirmPatientInfo}
          isLoading={requestMedicationLoading}
        >
          New Request
        </Button>
      }
      onBackdropClick={hideDialog}
      {...props}
    >
      <DialogContent>
        <TruentityDataGrid
          name={'dg-device-management'}
          autoHeight
          rows={medHistoryData}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          paginationMode="server"
          columns={columns}
          disableRowSelectionOnClick
          loading={medHistoryRequestLoading}
        />
        <Stack mt={2} alignItems="flex-end">
          <Button onClick={hideDialog}>Done</Button>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default MedHistoryRequestsDialog;
