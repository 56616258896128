import type { TextSelectOption } from '@/components/SelectList';
import { color } from '@/styles/assets/colors';
import { Role, Titles, TITLE_TYPE_OPTIONS, ROLE_TYPE_OPTIONS } from '@/types/admin';
import { AdminRoleTypeEnum } from '@/types/graphql';

export const superAdminAccessibleRoles: string[] = [Titles.SUPER_ADMIN];
export const adminAccessibleRoles: string[] = [Titles.PHARMACY_DIRECTOR, Titles.PHARMACY_MANAGER, Titles.CARE_ADMINISTRATOR];

export const getCurrentAdminsUsableTitles = (useRole: string | undefined | null, isSelfProfileEditing?: boolean): TextSelectOption[] => {
  if (!useRole) return [];

  const isSuperAdmin = useRole === Role.SUPER;
  const isPharmacistAdmin = useRole === Role.PHARMACIST_ADMIN;
  const isPharmacist = useRole === Role.PHARMACIST;

  const styledOptions = TITLE_TYPE_OPTIONS.map(option => {
    const isRed = adminAccessibleRoles.includes(option.value);
    return {
      label: option.label,
      value: option.value,
      ...(isRed && { color: color.truentitySalmon[800] })
    };
  });
  if (isSuperAdmin) {
    return styledOptions;
  }
  if (isPharmacistAdmin && isSelfProfileEditing) {
    return styledOptions.filter(option => adminAccessibleRoles.includes(option.value));
  }
  if (isPharmacist || !(isPharmacistAdmin && isSelfProfileEditing)) {
    return styledOptions.filter(option => !adminAccessibleRoles.includes(option.value));
  }

  return styledOptions;
};

export const getRoleTypeUsingTitle = (title: string): AdminRoleTypeEnum => {
  if (superAdminAccessibleRoles.includes(title)) return AdminRoleTypeEnum.SUPER_ADMIN;
  if (adminAccessibleRoles.includes(title)) return AdminRoleTypeEnum.PHARMACIST_ADMIN;
  return AdminRoleTypeEnum.PHARMACIST;
};

export const mapRolesToText = (role: string) => {
  return [...ROLE_TYPE_OPTIONS, { label: 'Super Admin', value: Role.SUPER }].find(option => option.value === role)?.label ?? 'N/A';
};

export const mapTitlesToText = (title: string): string => {
  return (
    [...TITLE_TYPE_OPTIONS, { label: 'Super Admin', value: Titles.SUPER_ADMIN }].find(option => option.value === title)?.label ?? 'N/A'
  );
};

export const convertRoleToRoleType = (role: string): AdminRoleTypeEnum => {
  switch (role) {
    case Role.SUPER:
      return AdminRoleTypeEnum.SUPER_ADMIN;
    case Role.PHARMACIST:
      return AdminRoleTypeEnum.PHARMACIST;
    case Role.PHARMACIST_ADMIN:
      return AdminRoleTypeEnum.PHARMACIST_ADMIN;
    case Role.ADT_ADMIN:
      return AdminRoleTypeEnum.ADT_ADMIN;
    default:
      return AdminRoleTypeEnum.SUPER_ADMIN;
  }
};
