import { DialogActions, DialogContent } from '@mui/material';
import Alert from '../Alert';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  onCancel: () => void;
  onConfirm: () => void;
};
const UploadSuccessfulDialog = ({ title, onConfirm, onCancel, ...props }: Props) => {
  return (
    <BaseDialog {...props} title="Upload Successful">
      <DialogContent>
        <Alert title="Upload Successful" description="Add another or end session?" status="success" />

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button a11yLabel="End Session" appearance="outline" onClick={onCancel} />

          <Button a11yLabel="New" appearance="primary" onClick={onConfirm} />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default UploadSuccessfulDialog;
