import { gql } from '@apollo/client';

export const GET_METRICS = gql`
  query analyticsGet {
    analyticsGet {
      totalNumOfPatients
      avgPatientAge
      totalNumOfImports
      totalNumMedsImported
      totalNumMedsPostcmr
      avgNumMedsPerPatient
    }
  }
`;

export const GET_TASKS_BY_TIP_SUB_TYPES = gql`
  query getTasksReportingQuery($filterOptions: TaskFilterOptionsInput!) {
    tipOverSubTypesTasksReporting(filterOptions: $filterOptions) {
      tasksReports {
        count
        subType
        relyingPartyAdmins {
          name
          count
        }
      }
    }
  }
`;

export const GET_TASKS_BY_WRAP_UP_STATUS = gql`
  query getTasksReportingQuery($filterOptions: TaskFilterOptionsInput!) {
    usersOverWrapUpStatusTasksReporting(filterOptions: $filterOptions) {
      tasksReports {
        count
        wrapUpStatus
        relyingPartyAdmins {
          name
          count
        }
      }
    }
  }
`;

export const GET_PATIENTS_BY_ORG = gql`
  query PatientsByOrgReportingGet {
    patientsByOrgReporting {
      patientsByOrgReports {
        accounts
        name
        totalPatients
        locations {
          name
          accounts
          totalPatients
        }
        patientsByGender {
          gender
          total
        }
      }
    }
  }
`;

export const GET_PATIENTS_REPORTING = gql`
  query PatientsByYearMonthDay($month: String!, $year: Int!) {
    patientsByYearMonthDay(month: $month, year: $year) {
      patientsReports {
        count
        month
        days {
          day
          count
        }
      }
    }
  }
`;
export const GET_ADT_EVENTS_ANALYTICS = gql`
  query AdtEventsAnalyticsGet($filterOptions: TcmTaskFilterOptionsInput!) {
    adtEventsAnalytics(filterOptions: $filterOptions) {
      adtEventsAnalytics {
        eventType
        count
        statusDistribution {
          eventType
          count
        }
      }
    }
  }
`;

export const GET_TCM_TASKS_ANALYTICS = gql`
  query TcmTasksAnalyticsGet($filterOptions: TcmTaskFilterOptionsInput!) {
    tcmTasksAnalytics(filterOptions: $filterOptions) {
      tcmTasksAnalytics {
        tcmTaskDistribution {
          status
          count
        }
      }
    }
  }
`;

export const GET_LANDING_PAGE_METRICS = gql`
  query GetLandingPageMetrics($filterOptions: TaskFilterOptionsInput!) {
    getLandingPageMetrics(filterOptions: $filterOptions) {
      landingPageMetrics {
        name
        assignedCount
        dueCount
        completedCount
        pendingCount
        enrolledCount
        devicesCount
        metrics {
          name
          count
          dateRange
          dataSet {
            date
            count
          }
        }
      }
    }
  }
`;

export const GET_TASK_ANALYTICS = gql`
  query TaskAnalytics {
    taskAnalytics {
      taskAnalyiticsMetrics {
        name
        allTypeCountMetrics {
          id
          name
          count
        }
        tipCmrAdhTypesMetrics {
          id
          typesPerPatient
          numPatients
        }
        accountMetrics {
          id
          truentityId
          performedOn
        }
      }
    }
  }
`;

export const GET_HEALTH_PLAN_ANALYTICS = gql`
  query HealthPlanAnalytics {
    healthPlanAnalytics {
      healthPlanAnalyiticsMetrics {
        healthPlansOverall {
          rowId
          org
          count
        }
        healthPlansByPharmacyOrgs {
          rowId
          org
          plan
          count
        }
        healthPlansByPharmacyOrgsStores {
          rowId
          name
          count
          plan
          org
        }
        healthPlansByOrgStores {
          rowId
          org
          plan
          count
        }
      }
    }
  }
`;
