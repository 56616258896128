import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ClipboardCopy from '@/components/ClipboardCopy';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { CreateQuickUploadDialog, UpdateQuickUploadDialog } from '@/components/Dialogs';
import Pagination from '@/components/Pagination';
import { H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import useToken from '@/hooks/useToken';
import styled from '@/styles';
import { Role } from '@/types/admin';
import { formatDateAndTime } from '@/util/format';
import { getQuickUploadSessionUrl } from '@/util/upload';
import { gql, useQuery } from '@apollo/client';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PaginationContainer = styled('div', {
  width: '100%'
});

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'start'
});

const HeaderContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  alignItems: 'end'
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end'
});

const GET_SESSIONS = gql`
  query GetQuickUploadSessionsByAccountQuery($truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    quickUploadSessionsByAccount(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      quickUploadSessions {
        status
        sessionId
        createdAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

const canCopySession = status => {
  const statusLc = status.toLowerCase();
  return statusLc === 'initialized' || statusLc === 'started';
};

const renderItems = (item: any, onUpdateStatus: Function, navigate: Function) => {
  const handleNavigation = (item: any) => {
    navigate(`${item.sessionId}`);
  };

  return (
    <TableRow key={item.sessionId}>
      <TableCell>
        <ClipboardCopy disabled={!canCopySession(item.status)} label={item.sessionId} copyText={getQuickUploadSessionUrl(item.sessionId)} />
      </TableCell>
      <TableCell>{formatDateAndTime(item.createdAt)}</TableCell>
      <TableCell>
        <Button
          color={'primary'}
          variant="text"
          size="large"
          startIcon={<ModeEditOutlineOutlinedIcon />}
          label={item.status}
          onClick={() => onUpdateStatus(item)}
          sx={{ width: '120px', justifyContent: 'flex-start' }}
        />
      </TableCell>
      <TableCell>
        <Button a11yLabel="Items" appearance="outline" onClick={() => handleNavigation(item)} />
      </TableCell>
    </TableRow>
  );
};

const QuickUploadHistory = () => {
  const { id } = useParams();
  const { patientId, patientInfo } = useContext(PatientDetailContext);
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const [currentPage, setCurrentPage] = useState(1);
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { data, loading, called, refetch } = useQuery(GET_SESSIONS, {
    variables: {
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      truentityId: patientId
    }
  });
  const isDataLoaded = () => called && !loading && data && data.quickUploadSessionsByAccount.quickUploadSessions;

  const isThereData = () => isDataLoaded() && data.quickUploadSessionsByAccount.meta.totalCount > 0;

  const thereIsNoData = () => isDataLoaded() && data.quickUploadSessionsByAccount.meta.totalCount === 0;

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  const onUpdateStatus = useCallback(item => {
    showUpdateQuickUploadDialog(item.sessionId, item.status);
  }, []);

  const showUpdateQuickUploadDialog = (sessionId, sessionStatus) => {
    const modalRef = showModal(UpdateQuickUploadDialog, {
      title: 'Update Quick Upload Session Status',
      successMessage: 'Session Status Updated',
      quickUploadSessionId: sessionId,
      quickUploadSessionStatus: sessionStatus,
      onQuickUploadUpdated: () => {
        modalRef.hide();
        refetch();
      },
      hideDialog: () => modalRef.hide()
    });
  };

  const showQuickUploadModal = () => {
    const fullName = `${patientInfo?.user?.firstName ?? ''} ${patientInfo?.user?.lastName ?? ''}`;
    const title = 'Create New Quick Upload Session';
    const successMessage = `Quick Upload Session has been created for ${fullName}.`;
    const patientId = id;
    const patientName = fullName;

    const quickUploadModal = showModal(CreateQuickUploadDialog, {
      title,
      successMessage,
      patientId,
      patientName,
      hideDialog: () => {
        quickUploadModal.hide();
        refetch();
      }
    });
  };

  return (
    <Stack direction="column" spacing={6}>
      <Stack spacing={2}>
        <TitleContainer>
          <HeaderContent>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <H4 sx={{ maxWidth: '50vw' }}>Quick Upload Sessions</H4>
            </Stack>
            <ButtonsContainer>
              <Button
                sx={{ marginLeft: '10px' }}
                disableElevation
                variant={'contained'}
                a11yLabel="New Session"
                label="New session"
                onClick={() => showQuickUploadModal()}
                disabled={isProviderAdmin}
              />
            </ButtonsContainer>
          </HeaderContent>
        </TitleContainer>
      </Stack>

      <Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>Session ID</TableHeading>
              <TableHeading>Created At</TableHeading>
              <TableHeading>Status</TableHeading>
              <TableHeading>Items</TableHeading>
            </TableRow>
          </TableHead>
          <tbody>
            {isThereData() &&
              data.quickUploadSessionsByAccount.quickUploadSessions.map(item => renderItems(item, onUpdateStatus, navigate))}
          </tbody>
        </Table>

        {thereIsNoData() && (
          <Alert description="There are no quick upload sessions for this patient yet" status="warning" title="No Sessions" />
        )}

        {isThereData() && (
          <PaginationContainer>
            <Pagination
              currentPage={currentPage}
              totalRecords={data.quickUploadSessionsByAccount.meta.totalCount}
              pageLimit={data.quickUploadSessionsByAccount.meta.totalPages}
              pageNeighbours={2}
              onPageChanged={updateData}
            />
          </PaginationContainer>
        )}
      </Stack>
    </Stack>
  );
};

export default QuickUploadHistory;
