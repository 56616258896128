import { Backdrop, CircularProgress, Stack } from '@mui/material';
import { Body1 } from './Typography';

type Props = {
  active: boolean;
  text?: string;
  children?: React.ReactNode;
};

const LoadingOverlay = ({ active, text, children }: Props) => {
  return (
    <>
      <Backdrop
        component={Stack}
        spacing={2}
        open={active}
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: '#000000c2',
          color: '#fff',
          zIndex: theme => theme.zIndex.drawer + 1
        }}
      >
        <CircularProgress color="inherit" />
        {text && (
          <Body1 color="white" sx={{ userSelect: 'none' }}>
            {text}
          </Body1>
        )}
      </Backdrop>
      {children}
    </>
  );
};

export default LoadingOverlay;
