import { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import MedicationInfoTabs from '@/routes/PatientDetails/medicationInfo';
import MedicationInteractionInfoTabs from '@/routes/PatientDetails/medicationInteractionInfo';
import DialogContent from '@mui/material/DialogContent';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  medicationInfo: any;
  medicationInteractionInfo?: any;
  modalType: string;
};

const MedicationInfoTabsDialog = ({ title, hideDialog, medicationInfo, medicationInteractionInfo, modalType, ...props }: Props) => {
  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={modalType === 'medicationInfo' ? 'xl' : 'lg'} {...props}>
      <DialogContent>
        {modalType === 'medicationInfo' ? (
          <MedicationInfoTabs medication={medicationInfo}></MedicationInfoTabs>
        ) : (
          <MedicationInteractionInfoTabs
            medication={medicationInfo}
            medicationInteractionInfo={medicationInteractionInfo}
          ></MedicationInteractionInfoTabs>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default MedicationInfoTabsDialog;
