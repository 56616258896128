import moment from 'moment';

export function getKeyByValue(object: object, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

export function addIfExists(prop: string | undefined | null) {
  return prop && prop.length > 0 ? prop : undefined;
}

export function stripTypenames(obj: object, propToDelete: string) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj[property];
      const newData = stripTypenames(obj[property] as object, propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
}

export const transformObjectCase = <T>(obj: T, caseFunction: (input: string) => string, convertNullToEmpty = false): T => {
  if (obj === null) {
    return (convertNullToEmpty ? '' : null) as unknown as T;
  }

  if (typeof obj === 'string') {
    return caseFunction(obj) as unknown as T;
  }

  if (obj instanceof Date || moment.isMoment(obj)) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => transformObjectCase(item, caseFunction, convertNullToEmpty)) as T;
  }

  if (typeof obj === 'object' && obj !== null) {
    const result = {} as Record<string, unknown>;
    Object.keys(obj).forEach(key => {
      result[key] = transformObjectCase((obj as Record<string, unknown>)[key], caseFunction, convertNullToEmpty);
    });
    return result as T;
  }

  return obj;
};
