import type { DefaultOptions, NextLink, Operation, WatchQueryFetchPolicy } from '@apollo/client';
import { ApolloClient, ApolloLink, from, InMemoryCache, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client'; // Import createUploadLink

import ls, { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/util/localstorage';

import Config from '@/config';
import { getMainDefinition } from '@apollo/client/utilities';
import { createConsumer } from '@rails/actioncable';
import type { OperationDefinitionNode } from 'graphql';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import { clearCache } from './cache';

export const AUTH_HEADER = 'authorization';
export const CLIENT_HEADER = 'x-client';
export const CLIENT_VERSION_HEADER = 'x-client-version';
export const NEW_TOKEN_HEADER = 'x-refreshed-token';
export const REFRESH_HEADER = 'x-refresh';
export const REQUEST_ID_HEADER = 'x-request-id';

const clientVersion = `${Config.BUILD_VERSION}-${Config.BUILD_COMMIT}`;
const websocketUrl = Config.WEBSOCKET_URL;
const featureActionCableEnabled = Config.FEATURE_ACTION_CABLED_ENABLED ?? 'true';

// 6 minutes timeout to accommodate for long running queries
const timeoutLink = new ApolloLinkTimeout(Config.APOLLO_LINK_TIMEOUT_S * 1000);

// ==
// HTTP config
// ==
const uploadLink = createUploadLink({
  uri: Config.GRAPHQL_ENDPOINT
});

// ==
// Authentication
// ==
const authLink = new ApolloLink((operation, forward) => {
  const customHeaders: Record<string, string> = {
    [CLIENT_HEADER]: 'truentity-portal-app',
    [CLIENT_VERSION_HEADER]: clientVersion
  };

  const [accessToken, deviceToken] = ls.multiGet(ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY);

  if (accessToken) customHeaders[AUTH_HEADER] = accessToken;
  if (deviceToken) customHeaders[REFRESH_HEADER] = deviceToken;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...customHeaders
    }
  }));

  return forward(operation);
});

type Context = {
  response: {
    headers: Map<string, string>;
  };
};

const tokenRefreshLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext() as Context;
    if (context?.response?.headers) {
      const newToken = context.response.headers.get(NEW_TOKEN_HEADER);

      if (newToken) {
        ls.set(ACCESS_TOKEN_KEY, newToken);
      }
    }

    return response;
  })
);

// ==
// Error handling
// ==
const serverErrorLink = onError(({ networkError }) => {
  if (networkError) {
    console.error('[apollo]', '(serverErrorLink)', networkError);
  }
});

const errorLink = onError(({ graphQLErrors }) => {
  graphQLErrors?.forEach(({ extensions }) => {
    if (extensions?.code === 'AUTHORIZATION_FAILED') {
      console.error('[apollo]', '(errorLink)', extensions.message);
      ls.clear();
      clearCache();
      window.location.replace('/login');
    }
  });
});

// ==
// Debug logging
// ==
const loggerLink = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    const context = operation.getContext() as Context;
    const requestId = context.response.headers.get(REQUEST_ID_HEADER);

    if (requestId) {
      console.log(`[apollo]', '(${requestId})`, response);
    }

    response?.errors?.forEach(({ extensions }) => {
      if (extensions?.code === 'AUTHORIZATION_FAILED') {
        console.error('[apollo]', '(errorLink)', extensions.message);
        ls.clear();
        clearCache();
        window.location.replace('/login');
      }
    });

    return response;
  })
);

// ==
// Remove _typename from mutations
// https://github.com/apollographql/apollo-client/issues/1564
//
const isFile = (value: any): boolean => value instanceof File || (Array.isArray(value) && value.some(isFile));

const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value);

const cleanMutationTypeLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const def = getMainDefinition(operation.query);

  if (def && (def as OperationDefinitionNode).operation === 'mutation') {
    // Check if any variables contain a File object
    const containsFile = Object.values(operation.variables).some(isFile);

    if (!containsFile) {
      // If there are no files, omit __typename as usual
      operation.variables = JSON.parse(JSON.stringify(operation.variables, omitTypename));
    }
  }

  return forward ? forward(operation) : null;
});

// ==
// Subscription config
// ==

const noopLink = new ApolloLink(() => {
  // if ActionCableLink is not initialized.
  return null;
});

const createActionCableLink = () => {
  if (featureActionCableEnabled?.toLowerCase() === 'false') {
    console.warn('Skipping Action Cable setup: Action cabled feature toggled off from ENV');
    return null;
  }

  const token = ls.get(ACCESS_TOKEN_KEY);
  if (!token || !websocketUrl || websocketUrl.trim().length === 0) {
    console.warn('Skipping Action Cable setup: Missing token or WebSocket URL.');
    return null;
  }
  const cable = createConsumer(`${websocketUrl}/cable?token=${token}`);
  return new ActionCableLink({ cable, channelName: 'GraphQLChannel' });
};

let actionCableLink = createActionCableLink();

const updateOrInitializeApolloClientLink = () => {
  const activeActionCableLink = actionCableLink || noopLink;
  // Prepare the HTTP link with all middlewares, including the tokenRefreshLink
  const httpLinkWithMiddleware = from([authLink, tokenRefreshLink, timeoutLink, errorLink, uploadLink]);

  // Use the split function to handle the routing between WebSocket and HTTP links
  const splitLink = split(hasSubscriptionOperation, activeActionCableLink, httpLinkWithMiddleware);

  // Set the combined link as the client's link
  client.setLink(splitLink);
};

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription');
};

// ==
// Composition
// ==
const httpLinkWithMiddleware = cleanMutationTypeLink.concat(
  timeoutLink.concat(loggerLink.concat(serverErrorLink.concat(tokenRefreshLink.concat(errorLink.concat(uploadLink)))))
);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: Config.CACHE_POLICY as WatchQueryFetchPolicy,
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const client = new ApolloClient({
  cache: new InMemoryCache({}),
  link: from([authLink, httpLinkWithMiddleware]),
  resolvers: {},
  defaultOptions
});

// Subscribe to token changes
ls.subscribeToTokenChanges(newToken => {
  if (newToken) {
    // Apollo client reconfigured with new ActionCableLink.
    actionCableLink = createActionCableLink();
    updateOrInitializeApolloClientLink();
  }
});

// Ensure that the client is correctly initialized once at the start
if (actionCableLink) {
  updateOrInitializeApolloClientLink();
} else {
  console.error('Initial ActionCableLink creation failed. Check token availability.');
}

export default client;
