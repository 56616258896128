import type { ReadingType } from '@/types/remotePatientMonitoring';

export const getReadingTypeValue = (readingType?: ReadingType) => {
  if (!readingType) {
    return '';
  }

  if (!readingType.value || !readingType.unit) {
    return '';
  } else {
    return `${readingType.value} ${readingType.unit}`;
  }
};

export const getCustomRowHeight = (rowCount: number): string => {
  return `${rowCount > 1 ? rowCount * 21 : 21}px`;
};
