import { useMutation } from '@apollo/client';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import Button from '@/components/Button';
import { Small } from '@/components/Typography';
import { CHANGE_INVOICE_STATUSES, INVOICE_EMAIL } from '@/graphql/invoices';
import { DialogActions, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type FormValues = {
  invoiceId: string;
  emailSubject: string;
  emailAddresses: string;
};

const defaultValues: FormValues = {
  invoiceId: '',
  emailSubject: '',
  emailAddresses: ''
};

type Props = BaseDialogProps & {
  title: string;
  successMessage: string;
  invoiceId?: string;
  invoiceData?: any;
  subject: string;
  onEmailSent: any;
  hideDialog: () => void;
  isInFinanceEmail: boolean;
};

const SendInvoiceEmailDialog = ({
  title,
  successMessage,
  subject,
  invoiceId,
  invoiceData,
  onEmailSent,
  hideDialog,
  isInFinanceEmail,
  ...props
}: Props) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues: { ...defaultValues, emailSubject: subject } });
  const [sendEmail] = useMutation(INVOICE_EMAIL);
  const [updateInvoiceStatuses] = useMutation(CHANGE_INVOICE_STATUSES);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitEmail(data);
  const handleSubmitEmail = async (values: FormValues) => {
    try {
      if (isInFinanceEmail) {
        const invoiceStatuses = [{ id: invoiceId, status: 'in finance' }];
        await updateInvoiceStatuses({
          variables: {
            invoiceStatuses
          }
        });
      } else {
        await sendEmail({
          variables: {
            invoiceId,
            emailSubject: values.emailSubject,
            emailAddresses: values.emailAddresses
          }
        });
      }

      showSnackbar(successMessage, 'success');
      onEmailSent();
    } catch (err) {
      console.log(err);
      showSnackbar('Error sending email. Please contact Tech Support', 'error');
    }
  };

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
    hideDialog();
  };

  useEffect(() => {
    if (isInFinanceEmail) {
      if (invoiceData && invoiceData?.relyingPartyAdmin) {
        setValue('emailAddresses', invoiceData?.relyingPartyAdmin?.relyingParty?.email);
      }
    } else {
      if (invoiceData && invoiceData?.relyingPartyAdmin) {
        setValue('emailAddresses', invoiceData?.relyingPartyAdmin?.supervisorEmail);
      }
    }
  }, [invoiceData, isInFinanceEmail]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} maxWidth="sm" fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TruentityTextField
            {...register('emailSubject')}
            type="text"
            label="Subject"
            fullWidth
            variant="outlined"
            margin="dense"
            required
          />

          <TruentityTextField
            {...register('emailAddresses')}
            type="email"
            label="Email"
            multiple
            fullWidth
            variant="outlined"
            margin="dense"
            required
            disabled
          />

          {!isInFinanceEmail && !invoiceData?.relyingPartyAdmin?.supervisorEmail && (
            <Small sx={{ color: 'red' }}>Invoice can not be submitted. Please contact your supervisor to set up this email</Small>
          )}

          <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
            <Button
              disabled={!isInFinanceEmail && !invoiceData?.relyingPartyAdmin?.supervisorEmail}
              type="submit"
              a11yLabel="Submit"
              appearance="primary"
            />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </DialogContent>
      </form>
    </BaseDialog>
  );
};

export default SendInvoiceEmailDialog;
