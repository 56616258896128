import Alert from '@/components/Alert';
import Button from '@/components/Button';
import LabelGroup from '@/components/LabelGroup';
import { H4, H6 } from '@/components/Typography';
import { getQuickUploadSessionUrl } from '@/util/upload';
import { gql, useMutation } from '@apollo/client';
import { Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import ClipboardCopy from '../ClipboardCopy';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const CREATE_SESSION = gql`
  mutation CreateQuickUploadSession($truentityId: String!) {
    createQuickUploadSession(truentityId: $truentityId) {
      quickUploadSession {
        status
        sessionId
      }
    }
  }
`;

type Props = BaseDialogProps & {
  successMessage: string;
  patientId?: string;
  patientName?: string;
  onQuickUploadCreated?: (newSessionId: string) => void;
};

const CreateQuickUploadDialog = ({ title, successMessage, patientId, patientName, onQuickUploadCreated, hideDialog, ...props }: Props) => {
  const [createSession] = useMutation(CREATE_SESSION);
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit: SubmitHandler = _ => onSubmitForm();

  const onSubmitForm = async () => {
    try {
      const result = await createSession({
        variables: {
          truentityId: patientId
        }
      });
      const { status, sessionId } = result.data!.createQuickUploadSession.quickUploadSession;
      setSuccess(true);
      setSessionId(sessionId);

      if (onQuickUploadCreated) {
        onQuickUploadCreated(sessionId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSend = event => {
    event.preventDefault();
    hideDialog!();
  };

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} maxWidth="sm" fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} component={DialogContent}>
          {success && (
            <>
              <Alert description={successMessage} status="success" title="Success" />
            </>
          )}

          {patientName ? (
            <LabelGroup label="Patient">
              <H4>{patientName}</H4>
            </LabelGroup>
          ) : (
            <TruentityTextField {...register('truentityId')} variant="outlined" margin="dense" type="text" label="Patient ID" required />
          )}

          {sessionId && (
            <LabelGroup label="Temporary Upload URL">
              <Stack direction="row" alignItems={'center'}>
                <H6 sx={{ flex: 1 }} fontWeight="600">
                  {getQuickUploadSessionUrl(sessionId)}
                </H6>
                <ClipboardCopy copyText={getQuickUploadSessionUrl(sessionId)} />
              </Stack>
            </LabelGroup>
          )}

          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            {!success && <Button type="submit" a11yLabel="Create Session" appearance="primary" />}
            {success && <Button disabled={true} onClick={handleSend} a11yLabel="Send Upload URL to Patient" appearance="primary" />}
          </DialogActions>
        </Stack>
      </form>
    </BaseDialog>
  );
};

export default CreateQuickUploadDialog;
