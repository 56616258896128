import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styled from '@/styles';

export const Icon = styled(FontAwesomeIcon, {
  color: '$truentityBlue',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  padding: 0,

  variants: {
    size: {
      sm: {
        fontSize: '$sm'
      },
      md: {
        fontSize: '$md'
      },
      lg: {
        fontSize: '$lg'
      },
      xl: {
        fontSize: '$xl'
      },
      xxl: {
        fontSize: '$xxl'
      },
      xxxl: {
        fontSize: '$xxxl'
      }
    },

    color: {
      default: {
        color: '$truentityBlue'
      },
      white: {
        color: '$truentityWhite'
      },
      green: {
        color: '$truentityDeepGreen'
      },
      salmon: {
        color: '$truentitySalmon'
      },
      amber: {
        color: '$truentityAmber'
      }
    },

    context: {
      searchBar: {
        position: 'absolute',
        left: '20px',
        top: 0,
        bottom: 0,
        margin: 'auto'
      }
    }
  }
});

Icon.displayName = 'stitches(Icon)';

export default Icon;
