import ConversationsListToolbar from '@/components/Conversations/ConversationsListToolbar';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { MessageDrawer } from '@/components/MessageDrawer/MessageDrawer';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, Caption, H4 } from '@/components/Typography';
import type { GetMessagesByConversationResponse, GetPatientConversationsResponse } from '@/graphql/accountConversations';
import { GET_MESSAGES_BY_CONVERSATION } from '@/graphql/accountConversations';
import { color } from '@/styles/assets/colors';
import type { MessagesByDate, PatientConversation } from '@/types/chat';
import { UserTypes } from '@/types/chat';
import { getAccountUserFullName } from '@/util/account';
import { stringAvatar } from '@/util/avatar';
import { getDateStringForMessage, toDate } from '@/util/date';
import { formatDate, formatDateAndTime } from '@/util/format';
import { useLazyQuery } from '@apollo/client';
import { Avatar, Box, Chip, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface ConversationsListProps {
  type: 'patient' | 'all';
  conversations: GetPatientConversationsResponse | undefined;
  conversationsLoading: boolean;
  currentPage: number;
  setCurrentPage: (currentPage: number) => void;
}

export const getConversationGridColumns = (type: 'patient' | 'all'): GridColDef<PatientConversation>[] => {
  const columns: GridColDef<PatientConversation>[] = [
    {
      field: 'name',
      headerName: 'Topic',
      sortable: true,
      flex: 1,
      align: 'left'
    },
    {
      field: 'accountPhoneNumber',
      headerName: 'Patient Phone',
      sortable: false,
      flex: 1,
      align: 'left',
      renderCell: params => <TruentityPhoneNumber value={params.value} />
    },
    {
      field: 'adminPhoneNumber',
      headerName: 'Admin Phone',
      sortable: false,
      flex: 1,
      align: 'left',
      renderCell: params => <TruentityPhoneNumber value={params.value} />
    },
    {
      field: 'accountLastMessageAt',
      headerName: 'Patient Last Message',
      flex: 1,
      valueGetter: params => formatDateAndTime(params.row?.accountLastMessageAt),
      align: 'left'
    },
    {
      field: 'adminLastMessageAt',
      headerName: 'Admin Last Message',
      flex: 1,
      valueGetter: params => formatDateAndTime(params.row?.adminLastMessageAt),
      align: 'left'
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      valueGetter: params => formatDate(params.row.createdAt),
      flex: 1,
      align: 'left'
    },
    {
      field: 'isActive',
      headerName: 'Status',
      valueGetter: params => params.row.isActive,
      renderCell: params => {
        if (params.value) {
          return <Chip variant="filled" color="success" label="Active" />;
        } else {
          return <Chip variant="filled" color="error" label="Ended" />;
        }
      },
      sortable: true,
      flex: 1
    }
  ];

  if (type === 'all') {
    return [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        valueGetter: params => params.row.account.user.firstName + ' ' + params.row.account.user.lastName,
        renderCell: params => {
          return <Link to={`../../patients/${params.row.account.truentityId}/details/medications/triage/list`}>{params.value}</Link>;
        },
        flex: 1,
        align: 'left'
      },
      ...columns
    ];
  } else {
    return columns;
  }
};

export const ConversationsList: React.FC<ConversationsListProps> = ({
  type,
  conversations,
  conversationsLoading,
  currentPage,
  setCurrentPage
}) => {
  const [selectedConversation, setSelectedConversation] = useState<PatientConversation | null>(null);
  const [conversationMessagesByDate, setConversationMessagesByDate] = useState<MessagesByDate | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [getMessagesByConversation] = useLazyQuery<GetMessagesByConversationResponse>(GET_MESSAGES_BY_CONVERSATION);

  const columns: GridColDef<PatientConversation>[] = useMemo(() => getConversationGridColumns(type), [type]);

  const handleMessageDrawerClose = () => {
    setSelectedConversation(null);
    setConversationMessagesByDate(null);
  };

  const onPatientConversationsRowClick = async (conversation: PatientConversation) => {
    try {
      const conversationMessagesResponse = await getMessagesByConversation({
        variables: {
          accountConversationId: conversation.id.toString()
        }
      });
      const currentConversationMessages: MessagesByDate = {};
      conversationMessagesResponse?.data?.accountMessagesByAccountConversation?.map(messagesGroupedByDate => {
        const date = getDateStringForMessage(toDate(messagesGroupedByDate?.date));
        currentConversationMessages[date] = messagesGroupedByDate?.messages?.map(message => {
          const isAdmin = Boolean(message?.sourceType === 'RelyingPartyAdmin');
          return {
            timestamp: toDate(message.createdAt).getTime(),
            message: message.messageBody,
            id: message.id,
            user: isAdmin
              ? {
                  name: getAccountUserFullName(conversation?.relyingPartyAdmin?.user),
                  avatar: getAccountUserFullName(conversation?.relyingPartyAdmin?.user)
                }
              : {
                  name: getAccountUserFullName(conversation?.account?.user),
                  avatar: getAccountUserFullName(conversation?.account?.user)
                },
            type: isAdmin ? UserTypes.user : UserTypes.otherUser,
            createdAt: new Date(message.createdAt)
          };
        });
      });

      setConversationMessagesByDate(currentConversationMessages);
      setSelectedConversation(conversation);
    } catch (err) {
      setSelectedConversation(null);
      setConversationMessagesByDate(null);
      enqueueSnackbar('Error getting messages for selected conversation', { variant: 'error' });
    }
  };

  return (
    <>
      <MessageDrawer
        readOnly={true}
        messagesByDate={conversationMessagesByDate ?? {}}
        header={
          <Stack flex={1} spacing={1} direction={'row'} justifyContent={'flex-start'} alignItems="center">
            <Avatar
              {...stringAvatar(
                `${selectedConversation?.account?.user?.firstName ?? ''} ${selectedConversation?.account?.user?.lastName ?? ''}`
              )}
              sx={{ backgroundColor: color.grey400 }}
              aria-label="receipent-header-avatar"
            />
            <Stack spacing={0} direction={'column'} justifyContent={'flex-start'} alignItems="flex-start">
              <H4>{getAccountUserFullName(selectedConversation?.account?.user)}</H4>
              <TruentityPhoneNumber value={selectedConversation?.accountPhoneNumber} />
            </Stack>
            <Stack flex={1} direction={'row'} justifyContent={'flex-end'} alignItems="center">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginLeft: 2,
                  borderRadius: '4px'
                }}
              >
                <Caption
                  sx={{
                    color: color.grey600
                  }}
                >
                  From
                </Caption>
                <Body1
                  sx={{
                    color: color.primaryDark
                  }}
                >
                  <TruentityPhoneNumber value={selectedConversation?.adminPhoneNumber} />
                </Body1>
              </Box>
            </Stack>
          </Stack>
        }
        open={Boolean(selectedConversation && conversationMessagesByDate)}
        onClose={handleMessageDrawerClose}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Paper elevation={type === 'all' ? 1 : 0} component={Stack} sx={{ p: type === 'all' ? 2 : 0, mb: 2 }} spacing={2} overflow={'auto'}>
          <TruentityDataGrid
            name={'dg-conversations-list'}
            customFilter={<ConversationsListToolbar />}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            rowCount={conversations?.meta?.totalCount ?? 0}
            autoHeight
            rows={conversations?.accountConversations ?? []}
            columns={columns}
            disableRowSelectionOnClick
            loading={conversationsLoading}
            paginationMode={'server'}
            onRowClick={e => onPatientConversationsRowClick(e.row as PatientConversation)}
          />
        </Paper>
      </Box>
    </>
  );
};

export default ConversationsList;
