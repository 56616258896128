import { ProfileDialogFormValues } from '@/components/Dialogs/ProfileDialog';
import JsonEditor from '@/components/JsonKit/JsonEditor';
import MuiTabs from '@/components/MuiTabs';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import TruentityTextField from '@/components/TruentityTextField';
import { H3 } from '@/components/Typography';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { getValueFromJsonString, isJSON, parseStringToJson } from '@/util/json';
import { Box, FormControlLabel, Stack, Switch, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';

type Props = {
  control: Control<ProfileDialogFormValues, any>;
  setValue: UseFormSetValue<ProfileDialogFormValues>;
  watch: UseFormWatch<ProfileDialogFormValues>;
  relyingPartyAdminSettings: string;
};

type UserSettingFormTypes = {
  emailLoginOTP: boolean;
  textLoginOTP: boolean;
  title: string;
  description: string;
  rpmReport: {
    phoneNumber: string;
    faxNumber: string;
  };
};

const UserSettingTab = ({ control, setValue, watch, relyingPartyAdminSettings }: Props) => {
  const { roleType } = useToken();
  const { enqueueSnackbar } = useSnackbar();

  const [settingFormValues, setSettingFormValues] = useState<UserSettingFormTypes>({
    emailLoginOTP: false,
    textLoginOTP: true,
    title: '',
    description: '',
    rpmReport: {
      phoneNumber: '',
      faxNumber: ''
    }
  });

  const isCurrentUserSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);

  const email = watch('email');
  const phoneNumber = watch('phone');

  const handleOnChange = (field: keyof UserSettingFormTypes | string, value: any) => {
    setSettingFormValues(prev => {
      if (field.startsWith('rpmReport.')) {
        const key = field.split('.')[1] as keyof UserSettingFormTypes['rpmReport'];
        return {
          ...prev,
          rpmReport: {
            ...prev.rpmReport,
            [key]: value
          }
        };
      } else {
        return {
          ...prev,
          [field]: value
        };
      }
    });
    updateSettingsJson(field, value);
  };

  const updateSettingsJson = (field: keyof UserSettingFormTypes | string, value: any) => {
    let existingSetting = JSON.parse(relyingPartyAdminSettings);

    if (field.startsWith('rpmReport.')) {
      const key = field.split('.')[1] as keyof UserSettingFormTypes['rpmReport'];
      if (!existingSetting['rpmReport']) {
        existingSetting['rpmReport'] = {};
      }
      existingSetting['rpmReport'][key] = value;
    } else {
      existingSetting[field] = value;
    }
    setValue('settings', JSON.stringify(existingSetting));
  };

  const handleToggleOtp = (otpType, checked) => {
    const otherOtpType = otpType === 'emailLoginOTP' ? 'textLoginOTP' : 'emailLoginOTP';
    const isOtherOtpActive = settingFormValues[otherOtpType];

    if (!checked && !isOtherOtpActive) {
      enqueueSnackbar('At least one OTP method must be enabled.', { variant: 'error' });
    } else {
      handleOnChange(otpType, checked);
    }
  };

  const handleJsonUpdate = useCallback(
    (changedValues: Record<string, any>) => {
      const { updated_src } = changedValues;
      const updatedValue = JSON.stringify(updated_src);
      if (updated_src && isJSON(updatedValue)) setValue('settings', updatedValue);
    },
    [setValue]
  );

  useEffect(() => {
    setSettingFormValues({
      emailLoginOTP: email ? getValueFromJsonString(relyingPartyAdminSettings, 'emailLoginOTP', false) : false,
      textLoginOTP:
        phoneNumber && phoneNumber?.trim().length > 0 ? getValueFromJsonString(relyingPartyAdminSettings, 'textLoginOTP', false) : false,
      title: getValueFromJsonString(relyingPartyAdminSettings, 'title', ''),
      description: getValueFromJsonString(relyingPartyAdminSettings, 'description', ''),
      rpmReport: {
        phoneNumber: getValueFromJsonString(relyingPartyAdminSettings, 'rpmReport.phoneNumber', ''),
        faxNumber: getValueFromJsonString(relyingPartyAdminSettings, 'rpmReport.faxNumber', '')
      }
    });
  }, [relyingPartyAdminSettings]);

  return (
    <MuiTabs
      tabVariant="standard"
      tabs={[
        {
          label: 'Form',
          children: (
            <Box>
              <Box>
                <Stack direction="row" justifyContent="flex-end">
                  <FormControlLabel
                    name="emailLoginOTP"
                    control={
                      <Switch
                        checked={settingFormValues.emailLoginOTP}
                        onChange={e => handleToggleOtp('emailLoginOTP', e.target.checked)}
                      />
                    }
                    label="Email OTP"
                    disabled={email?.trim().length <= 0}
                  />
                  <FormControlLabel
                    name="textLoginOTP"
                    control={
                      <Switch checked={settingFormValues.textLoginOTP} onChange={e => handleToggleOtp('textLoginOTP', e.target.checked)} />
                    }
                    label="SMS OTP"
                    disabled={!phoneNumber || phoneNumber?.trim().length <= 0}
                  />
                </Stack>
                <TruentityTextField
                  name="title"
                  value={settingFormValues.title}
                  label="Title"
                  onChange={e => handleOnChange('title', e.target.value)}
                />
                <TruentityTextField
                  name="description"
                  value={settingFormValues.description}
                  label="Description"
                  onChange={e => handleOnChange('description', e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <H3>RPM Report</H3>
                <Box>
                  <TruentityPhoneNumber
                    editable
                    name="phoneNumber"
                    value={settingFormValues.rpmReport.phoneNumber}
                    label="Phone Number"
                    onChange={e => handleOnChange('rpmReport.phoneNumber', e.target.value)}
                  />
                  <TruentityPhoneNumber
                    editable
                    name="faxNumber"
                    value={settingFormValues.rpmReport.faxNumber}
                    label="Fax Number"
                    onChange={e => handleOnChange('rpmReport.faxNumber', e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          )
        },
        {
          label: 'Text',
          children: (
            <Controller
              control={control}
              name="settings"
              render={({ field: { onChange, value } }) => (
                <TextField multiline minRows={5} fullWidth variant="outlined" value={value} onChange={onChange} />
              )}
            />
          )
        },
        {
          label: 'JSON',
          children: (
            <Box>
              <JsonEditor
                src={parseStringToJson(relyingPartyAdminSettings)}
                isDisabled={!isCurrentUserSuperAdmin}
                onAdd={handleJsonUpdate}
                onDelete={handleJsonUpdate}
                onEdit={handleJsonUpdate}
              />
            </Box>
          )
        }
      ]}
      customPadding
    />
  );
};

export default UserSettingTab;
