import { gql, useLazyQuery, useQuery } from '@apollo/client';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';

import Comments from '@/components/Comments';
import { Body1, H4, Small } from '@/components/Typography';
import Icon from '@/elements/Icon';
import useToken from '@/hooks/useToken';
import styled from '@/styles';
import { Role } from '@/types/admin';
import type { Medication, MedicationInformation } from '@/types/medication';
import { frequencyOptionsMap } from '@/types/medication';
import { buildValues, buildValuesFromMap, isVerified, selectRouteOfAdministrationString, selectTimeOfDayString } from '@/util/medications';
import { faInfoCircle, faPrescription, faPrescriptionBottle } from '@fortawesome/free-solid-svg-icons';

import MedicationTypeIcon from '@/components/MedicationTypeIcon';
import { formatDate } from '@/util/format';
import AdherenceSection, { defaultAdherenceRadioButtonConfig } from '../../components/AdherenceSection';

import Alert from '@/components/Alert';
import { MedicationInfoTabsDialog } from '@/components/Dialogs';
import IconWithChildrenContainer from '@/components/IconWithChildrenContainer';
import PatientDetailContext from '@/context/patientDetailContext';
import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Grid, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useModal } from 'mui-modal-provider';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import MuiAccordion from '@/components/MuiAccordion';
import ZoomableImage from '@/components/ZoomableImage';
import { color } from '@/styles/assets/colors';
import FaxIcon from '@mui/icons-material/FaxOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useSnackbar } from 'notistack';

import { isProd } from '@/util/environment';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';

const GET_MEDICATION_INFORMATION = gql`
  query getMedicationDetails($ndc: String!) {
    medicationDetails(ndc: $ndc) {
      name
      brand
      type
      description
      route
      interactions
      rxcui
      images
      manufacturerName
      drugInteractions
      packageLabels
      precautions
      indicationsAndUsage
      pediatricUse
      contraindications
      drugAbuseAndDependence
      pregnancy
      nursingMothers
      splProductDataElements
      dosageAndAdministration
      controlledSubstance
      adverseReactions
      informationForPatients
      howSupplied
      packageLabelPrincipalDisplayPanel
      clinicalPharmacology
      carcinogenesisAndMutagenesisAndImpairmentOfFertility
      overdosage
      boxedWarning
      geriatricUse
      otherReferences
      splUnclassifiedSection
    }
  }
`;

export const GET_MEDICATION_DETAIL = gql`
  query ($truentityId: String!, $accountMedicationId: String!) {
    accountMedication(truentityId: $truentityId, accountMedicationId: $accountMedicationId) {
      attachments {
        id
        url
      }
      id
      name
      displayName
      instructions
      isNoLongerTaking
      isTakenAsPrescribed
      isTakenAsDirected
      isTakingDifferently
      isUnableToVerify
      isEffective
      isSupplement
      prescriberName
      prescriber {
        phoneNumber
        faxNumber
      }
      interactions {
        title
        id
        conflictNdc
        conflictDrugLabelName
        primaryDrugLabelName
        severityLevel
        severityDetail
        effect
        mechanismAction
        clinicalEffects
        patientManagement
      }
      adherance
      updatedAt
      createdAt
      ndc
      lastFillDateAt
      quantity
      numRefills
      pharmacyName
      pharmacy {
        phoneNumber
        faxNumber
      }
      prescriptionWrittenDateAt
      soldDateAt
      dosage {
        id
        value
        unit
        isPrescribed
      }
      strength {
        id
        value
        unit
        isPrescribed
      }
      period {
        id
        value
        unit
        isPrescribed
      }
      timeOfDay {
        id
        value
        unit
        isPrescribed
      }
      routeOfAdministration {
        id
        value
        unit
        isPrescribed
      }
      source
      rxcui
      importedName
      lastVerifiedById
    }
  }
`;

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'start'
});

const HeaderContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  alignItems: 'end'
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end'
});

const MedInfoIcon = styled(Icon, {
  cursor: 'pointer'
});

const emptyData = [
  {
    src: ' '
  }
];

type MedicationInfoProps = {
  title: ReactNode;
  value: ReactNode;
  children?: ReactNode;
};
const MedicationInfo = ({ title, value, children }: MedicationInfoProps) => {
  return (
    <Stack sx={{ marginBottom: '20px' }}>
      <Body1>
        <strong>{title}</strong>
      </Body1>
      <Body1>{value}</Body1>
      {children}
    </Stack>
  );
};

const MedicationDetails = () => {
  const { medicationId, id } = useParams();
  const initialAttachmentsValues = [...emptyData];
  const [attachments, setAttachments] = useState(initialAttachmentsValues);
  const [isAttachmentsEmpty, setIsAttachmentEmpty] = useState<boolean>();
  const navigate = useNavigate();
  const { setCurrentOption, goDetailsPage } = useContext(PatientDetailContext);

  const { enqueueSnackbar } = useSnackbar();

  const { data, error: medicationDetailQueryError } = useQuery(GET_MEDICATION_DETAIL, {
    variables: {
      truentityId: id,
      accountMedicationId: medicationId
    }
  });

  const [medicationDetail, setMedicationDetail] = useState<Medication>({} as Medication);

  const [adherenceRadioButtonConfig, setAdherenceRadioButtonConfig] = useState(defaultAdherenceRadioButtonConfig);
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);

  const IsSupplement = () => {
    return medicationDetail?.isSupplement;
  };

  const [medicationName, setMedicationName] = useState('');

  const getMedicationName = (): string => {
    return medicationDetail?.displayName || '';
  };

  const buildStrength = (isPrescribed: boolean) => {
    if (medicationDetail && medicationDetail?.strength && medicationDetail?.strength.length > 0) {
      return buildValues(medicationDetail?.strength, isPrescribed);
    }
  };

  const buildDosage = (isPrescribed: boolean) => {
    if (medicationDetail && medicationDetail?.dosage && medicationDetail?.dosage.length > 0) {
      return buildValues(medicationDetail?.dosage, isPrescribed);
    }
  };

  const buildFrequency = (isPrescribed: boolean) => {
    if (medicationDetail && medicationDetail?.period && medicationDetail?.period.length > 0) {
      return buildValuesFromMap(frequencyOptionsMap, medicationDetail?.period, isPrescribed);
    }
  };

  const routeOfAdministration = (isPrescribed: boolean) => {
    if (medicationDetail && medicationDetail?.routeOfAdministration) {
      return selectRouteOfAdministrationString(medicationDetail?.routeOfAdministration, isPrescribed);
    }
  };

  const timeOfDay = (isPrescribed: boolean) => {
    if (medicationDetail && medicationDetail?.timeOfDay) {
      return selectTimeOfDayString(medicationDetail?.timeOfDay, isPrescribed);
    }
  };

  const [getMedicationInfo, { data: medicationInfoData }] = useLazyQuery(GET_MEDICATION_INFORMATION);
  const [medicationInfo, setMedicationInfo] = useState<MedicationInformation | null>(null);

  const { showModal } = useModal();

  useEffect(() => {
    setMedicationName(getMedicationName());
  }, [medicationDetail]);

  useEffect(() => {
    if (medicationDetailQueryError) {
      enqueueSnackbar('Medication not found', {
        variant: 'warning'
      });

      goDetailsPage();
    }
  }, [medicationDetailQueryError]);

  useEffect(() => {
    if (medicationDetail && medicationDetail.ndc) {
      getMedicationInfo({
        variables: {
          ndc: medicationDetail.ndc
        }
      });
    }
  }, [medicationDetail]);

  useEffect(() => {
    if (medicationInfoData) {
      setMedicationInfo(medicationInfoData.medicationDetails[0]);
    }
  }, [medicationInfoData]);

  const goToEditView = () => {
    navigate(`/patients/${id}/details/medications/edit/${medicationDetail.id}`, {
      state: {
        returnUrl: `/patients/${id}/details/medications/view/${medicationDetail.id}`
      }
    });
  };

  useEffect(() => {
    setCurrentOption(medicationId);
  }, [medicationId]);

  useEffect(() => {
    if (data && data.accountMedication) {
      setMedicationDetail(data.accountMedication);

      setAdherenceRadioButtonConfig({
        'taken-as-directed': data.accountMedication.isTakenAsDirected,
        'no-longer-taking': data.accountMedication.isNoLongerTaking,
        'taking-differently': data.accountMedication.isTakingDifferently,
        'unable-to-verify': data.accountMedication.isUnableToVerify
      });
    }
  }, [data, medicationId]);

  useEffect(() => {
    if (medicationDetail && medicationDetail.attachments) {
      try {
        const attachmentsList = medicationDetail.attachments;
        setIsAttachmentEmpty(attachmentsList.every(item => item.url === ''));
        attachmentsList.map((item, index) => {
          initialAttachmentsValues[index] = {
            ...initialAttachmentsValues[index],
            src: `${item.url}`
          };
          setAttachments(initialAttachmentsValues);
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [medicationDetail]);

  const showMedicationInfoModal = modalType => {
    //TODO:  Need to handle this better.  Needing to add this here otherwise when info modal is closed wrong route is loaded
    navigate(location.pathname, { state: null });
    const medicationInfoModal = showModal(MedicationInfoTabsDialog, {
      modalType: modalType,
      customTitleHeader: true,
      title: modalType !== 'medicationInfo' ? 'Interactions' : medicationInfo?.name || '',
      medicationInfo: medicationInfo,
      medicationInteractionInfo: medicationDetail?.interactions,
      hideDialog: () => medicationInfoModal.hide()
    });
  };

  const ReadonlyPhoneNumber = ({ value }) => {
    return (
      <Stack color={color.grey700} fontSize="0.75rem" direction="row" spacing={1} alignItems={'center'}>
        <LocalPhoneIcon fontSize={'inherit'} />
        <Small sx={{ textDecoration: 'none' }} component="a" href={`tel:${value}`} fontSize={'inherit'} color={'inherit'}>
          {value}
        </Small>
      </Stack>
    );
  };

  const ReadonlyFaxNumber = ({ value }) => {
    return (
      <Stack color={color.grey700} fontSize="0.75rem" direction="row" spacing={1} alignItems={'center'}>
        <FaxIcon fontSize={'inherit'} />
        <Small sx={{ textDecoration: 'none' }} component="a" href={`tel:${value}`} fontSize={'inherit'} color={'inherit'}>
          {value}
        </Small>
      </Stack>
    );
  };

  const PrescribedTabContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MedicationInfo title="Strength, unit" value={buildStrength(true)} />
          <MedicationInfo title="Dosage, form" value={buildDosage(true)} />
          <MedicationInfo title="Frequency, unit" value={buildFrequency(true)} />
          <MedicationInfo title="SIG" value={medicationDetail?.instructions || '---'} />
          <MedicationInfo
            title="Prescriber"
            value={medicationDetail?.prescriberName || '---'}
            children={
              <>
                {medicationDetail?.prescriber?.phoneNumber && <ReadonlyPhoneNumber value={medicationDetail?.prescriber?.phoneNumber} />}{' '}
                {medicationDetail?.prescriber?.faxNumber && <ReadonlyFaxNumber value={medicationDetail?.prescriber?.faxNumber} />}{' '}
              </>
            }
          />
          <MedicationInfo title="Route of Administration" value={routeOfAdministration(true)} />
          <MedicationInfo title="Time of Day" value={timeOfDay(true)} />
        </Grid>

        <Grid item xs={6}>
          <MedicationInfo title="Date Written" value={formatDate(medicationDetail?.prescriptionWrittenDateAt)} />
          <MedicationInfo title="Date Last Filled" value={formatDate(medicationDetail?.lastFillDateAt)} />
          <MedicationInfo title="Date Sold" value={formatDate(medicationDetail?.soldDateAt)} />
          <MedicationInfo title="Quantity" value={medicationDetail?.quantity} />
          <MedicationInfo title="Refills" value={medicationDetail?.numRefills} />
          <MedicationInfo
            title="Pharmacy"
            value={medicationDetail?.pharmacyName}
            children={
              <>
                {medicationDetail?.pharmacy?.phoneNumber && <ReadonlyPhoneNumber value={medicationDetail?.pharmacy?.phoneNumber} />}{' '}
                {medicationDetail?.pharmacy?.faxNumber && <ReadonlyFaxNumber value={medicationDetail?.pharmacy?.faxNumber} />}{' '}
              </>
            }
          />
          <MedicationInfo title="Effective" value={medicationDetail?.isEffective ? 'Yes' : 'No'} />
        </Grid>
      </Grid>
    );
  };

  const PatientModifiedTabContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MedicationInfo title="Strength, unit" value={buildStrength(false)} />
          <MedicationInfo title="Dosage, form" value={buildDosage(false)} />
          <MedicationInfo title="Frequency, unit" value={buildFrequency(false)} />
          <MedicationInfo title="Route of Administration" value={routeOfAdministration(false)} />
          <MedicationInfo title="Time of Day" value={timeOfDay(false)} />
        </Grid>
      </Grid>
    );
  };

  const AttachmentTabContent = () => {
    return (
      <>
        {medicationDetail && medicationDetail.attachments && !isAttachmentsEmpty ? (
          <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap', justifyContent: 'flex-start', marginTop: '1.25rem' }}>
            {attachments.map((item, index) => (
              <ZoomableImage src={`${item.src}`} key={index} />
            ))}
          </Stack>
        ) : (
          <Alert description={'No attachments yet'} status="warning" title="No attachments" />
        )}
      </>
    );
  };

  const options = [
    {
      label: 'Prescribed',
      content: <PrescribedTabContent />,
      defaultExpand: true
    },
    {
      label: 'Comments',
      content: <Comments collapsible={false} medicationId={medicationId} />,
      defaultExpand: false
    },
    {
      label: 'Adherence Status',
      content: <AdherenceSection editable={false} radioButtonOptions={adherenceRadioButtonConfig}></AdherenceSection>,
      defaultExpand: false
    },
    {
      label: 'Patient Modified',
      content: <PatientModifiedTabContent />,
      defaultExpand: false
    },
    {
      label: 'Attachments',
      content: <AttachmentTabContent />,
      defaultExpand: false
    }
  ];

  return (
    <Stack direction="column" spacing={6}>
      <Stack spacing={2}>
        <TitleContainer>
          <HeaderContent>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <H4 sx={{ maxWidth: '50vw' }}>
                {medicationName.toUpperCase()}
                {medicationInfo != null && (
                  <MedInfoIcon
                    css={{ marginLeft: 10 }}
                    fixedWidth
                    icon={faInfoCircle}
                    onClick={() => showMedicationInfoModal('medicationInfo')}
                  />
                )}
              </H4>

              {!isProd() && medicationDetail.source && medicationDetail.source === 'Imported:Allscripts' && (
                // <Chip variant={'filled'} sx={{ textTransform: 'uppercase', letterSpacing: 2 }} label={'Allscripts'} color={'success'} size='small' />
                <Chip label={'Allscripts'} variant={'outlined'} sx={{ textTransform: 'uppercase', letterSpacing: 2 }} size="small" />
              )}

              {medicationDetail?.interactions?.length > 0 && (
                <Avatar
                  style={{
                    backgroundColor: '#cacaca'
                  }}
                  sx={{ width: 33, height: 33 }}
                >
                  <SyncDisabledIcon sx={{ color: '#2b5281' }} onClick={() => showMedicationInfoModal('medicationInteractionInfo')} />
                </Avatar>
              )}

              <Chip
                variant={'filled'}
                sx={{ textTransform: 'uppercase', letterSpacing: 2 }}
                label={isVerified(medicationDetail) ? 'verified' : 'unverified'}
                color={isVerified(medicationDetail) ? 'success' : 'error'}
                size="small"
              />
            </Stack>

            <ButtonsContainer>
              <Button
                sx={{ marginLeft: '10px' }}
                disabled={isProviderAdmin}
                disableElevation
                color={'primary'}
                startIcon={<EditIcon />}
                variant={'contained'}
                onClick={() => goToEditView()}
              >
                Edit
              </Button>
            </ButtonsContainer>
          </HeaderContent>
        </TitleContainer>

        <Stack direction="row" spacing={2} sx={{ marginBottom: '8px' }}>
          <MedicationTypeIcon type={IsSupplement() ? 'SUPPLEMENT' : 'PRESCRIPTION'} showLabel={true}></MedicationTypeIcon>

          <IconWithChildrenContainer icon={faPrescription}>
            <Small
              color={medicationDetail?.rxcui?.startsWith('000') ? 'danger' : 'default'}
              weight="medium"
              css={{ fontStyle: 'italic', textTransform: 'uppercase' }}
            >
              {medicationDetail.rxcui}
            </Small>
            <Small color="default" weight="medium" css={{ marginLeft: '3px', fontStyle: 'italic' }}>
              (RXCUI)
            </Small>
          </IconWithChildrenContainer>

          <IconWithChildrenContainer icon={faPrescriptionBottle}>
            <Small color={'default'} weight="medium" css={{ fontStyle: 'italic', textTransform: 'uppercase' }}>
              {medicationDetail.ndc}
            </Small>
            <Small color="default" weight="medium" css={{ marginLeft: '3px', fontStyle: 'italic' }}>
              (NDC)
            </Small>
          </IconWithChildrenContainer>
        </Stack>

        <Body1>{medicationDetail?.instructions}</Body1>
      </Stack>

      <Stack>
        <MuiAccordion options={options} />
      </Stack>
    </Stack>
  );
};

export default MedicationDetails;
