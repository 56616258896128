import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H2, H3, H4, H5 } from '@/components/Typography';
import { StructuredData } from '@/types/pioneerRx';
import { renderFormattedValue } from '@/util/pioneerRx';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import { Box, Chip, Stack } from '@mui/material';

type Props = {
  sectionData: StructuredData[] | undefined;
};

const PioneerRxObjectTabSection = ({ sectionData }: Props) => {
  const formatCamelCase = (input: string): string => {
    const specialCases: Record<string, string> = {
      dea: 'DEA',
      npi: 'NPI',
      ncpd: 'NCPD'
      // Add more special cases as needed
    };

    const words = input
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ')
      .split(/\s+/);

    const formattedWords = words.map(word => {
      const lowerCaseWord = word.toLowerCase();
      return specialCases[lowerCaseWord] || lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
    });

    return formattedWords.join(' ');
  };

  return sectionData && sectionData?.length > 0 ? (
    sectionData?.map(section => (
      <Box bgcolor={'white'} p={3} borderRadius=".5rem">
        {section?.header?.name?.trim()?.length > 0 && (
          <Stack direction="row" mb={4} gap={2.5}>
            <H3>{section?.header?.name}</H3>
            {section?.header?.statusTypeText?.trim()?.length > 0 && (
              <Chip
                label={`Med Sync: ${section?.header?.statusTypeText}`}
                color={section?.header?.statusTypeText === 'Not Enrolled' ? 'error' : 'success'}
                size="small"
                variant="outlined"
              />
            )}
            {section?.header?.syncDate && section?.header?.syncDate?.trim()?.length > 0 && (
              <Chip label={`Sync Date: ${renderFormattedValue(section?.header?.syncDate)}`} size="small" variant="outlined" />
            )}
          </Stack>
        )}
        <Stack direction="row" justifyContent="space-between" gap={5} flexWrap="wrap">
          {section.bodySections.firstSectionInfo && (
            <Stack flex={1} gap={2}>
              {section.bodySections.firstSectionInfo.map(section => (
                <Box flex={1}>
                  <H3>{section?.sectionTitle}</H3>
                  <Stack mt={1} gap={1}>
                    {section?.sectionBody &&
                      Object.entries(section?.sectionBody).map(([key, value]) => (
                        <Stack direction="row" flexWrap="wrap">
                          <H5 fontWeight={400} minWidth={'150px'}>
                            {formatCamelCase(key)}
                          </H5>
                          <H5>{renderFormattedValue(value)}</H5>
                        </Stack>
                      ))}
                  </Stack>
                  <Stack direction="row" mt={2} gap={1} flexWrap="wrap">
                    {section?.sectionNodes ? (
                      section?.sectionNodes?.categories?.length > 0 ? (
                        section?.sectionNodes?.categories?.map((category, index) => (
                          <Chip
                            key={index}
                            label={category}
                            size="small"
                            sx={{
                              color: section?.sectionNodes?.primaryCategory === category ? '#20416E' : undefined,
                              backgroundColor: section?.sectionNodes?.primaryCategory === category ? '#E6EAF0' : undefined
                            }}
                          />
                        ))
                      ) : (
                        <Chip
                          label={section?.sectionNodes?.primaryCategory}
                          size="small"
                          sx={{
                            color: '#20416E',
                            backgroundColor: '#E6EAF0'
                          }}
                        />
                      )
                    ) : null}
                  </Stack>
                </Box>
              ))}
            </Stack>
          )}
          {section.bodySections.secondSectionInfo && (
            <Box flex={1}>
              {section.bodySections.secondSectionInfo.map(section => (
                <Box flex={1}>
                  <H3>{section?.sectionTitle}</H3>
                  <Stack mt={2} gap={3}>
                    {section?.sectionBody?.email && (
                      <Box>
                        <Stack direction="row" gap={1}>
                          <MailOutlineIcon fontSize="small" />
                          <H4>Email</H4>
                        </Stack>
                        <H5 fontWeight={400}>{section?.sectionBody?.email}</H5>
                      </Box>
                    )}
                    <Stack direction="row" justifyContent="space-between">
                      {section?.sectionBody?.addresses.length > 0 && (
                        <Box>
                          <Stack direction="row" gap={1}>
                            <LocationOnIcon fontSize="small" />
                            <H4>Addresses</H4>
                          </Stack>
                          <Stack gap={4} mt={1}>
                            {section?.sectionBody?.addresses.map(address => (
                              <Stack spacing={0.5} alignItems="flex-start">
                                {address?.AddressLine && <H5 fontWeight={400}>{address?.AddressLine},</H5>}
                                {address?.City && <H5 fontWeight={400}>{address?.City},</H5>}
                                {address?.StateCode && <H5 fontWeight={400}>{address?.StateCode}.</H5>}
                                {address?.ZipCode && <H5 fontWeight={400}>{address?.ZipCode}</H5>}
                                {address?.Type && (
                                  <Chip
                                    label={`Type ${address?.Type}`}
                                    size="small"
                                    sx={{ mt: '15px !important', backgroundColor: '#E6EAF0', color: '#20416E' }}
                                  />
                                )}
                              </Stack>
                            ))}
                          </Stack>
                        </Box>
                      )}
                      {section?.sectionBody?.phoneNumbers?.length > 0 && (
                        <Box>
                          <Stack direction="row" gap={1}>
                            <PhoneEnabledIcon fontSize="small" />
                            <H4>Phone Numbers</H4>
                          </Stack>
                          <Stack gap={4} alignItems="flex-start" mt={1}>
                            {section?.sectionBody?.phoneNumbers?.map(phoneNumber => (
                              <Stack spacing={0.5}>
                                <TruentityPhoneNumber
                                  value={`${phoneNumber?.AreaCode && `${phoneNumber?.AreaCode}`} ${phoneNumber?.Number}`}
                                />
                                {phoneNumber?.Extension && <H5 fontWeight={400}>Extension: {phoneNumber?.Extension}</H5>}
                                {phoneNumber?.Type && (
                                  <Chip
                                    label={`Type: ${phoneNumber?.Type}`}
                                    size="small"
                                    sx={{ mt: '15px !important', backgroundColor: '#E6EAF0', color: '#20416E' }}
                                  />
                                )}
                              </Stack>
                            ))}
                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Box>
          )}
        </Stack>
      </Box>
    ))
  ) : (
    <Box bgcolor={'white'} p={3} borderRadius=".5rem">
      <H2>No Data available</H2>
    </Box>
  );
};

export default PioneerRxObjectTabSection;
