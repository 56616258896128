import config from '@/config';
import { GET_CHAT_NOTIFICATIONS, ON_RELYING_PARTY_ADMIN_CONVERSATIONS, READ_ALL_NOTIFICATIONS } from '@/graphql/notifications';
import { UserTypes } from '@/types/chat';
import type {
  NotificationItem,
  NotificationMessage,
  NotificationsResponse,
  SubscriptionNotificationsResponse
} from '@/types/notifications';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useMutation, useQuery, useReactiveVar, useSubscription } from '@apollo/client';
import type { FC, ReactNode } from 'react';
import { createContext, useMemo, useState } from 'react';

interface State {
  unreadNotificationCount: number;
  notifications: NotificationItem[];
}

interface NotificationContextValue extends State {
  setUnreadNotificationCount: (val: number) => void;
  setNotifications: (val: NotificationItem[]) => void;
  readAllNotifications: () => Promise<void>;
}

interface NotificationProviderProps {
  children: ReactNode;
}

const initialValue: NotificationContextValue = {
  unreadNotificationCount: 0,
  notifications: [],
  setUnreadNotificationCount: () => {},
  setNotifications: () => {},
  readAllNotifications: () => Promise.resolve()
};

const NotificationContext = createContext<NotificationContextValue>(initialValue);

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const featureActionCableEnabled = config.FEATURE_ACTION_CABLED_ENABLED?.toLowerCase() ?? 'true';
  const isActionCableFeatureEnabled = featureActionCableEnabled === 'true';

  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const { data: notificationsResponse } = !isActionCableFeatureEnabled
    ? useQuery<NotificationsResponse>(GET_CHAT_NOTIFICATIONS, {
        skip: !currentUser,
        pollInterval: config.POLLING_INTERVAL_MS
      })
    : useSubscription<SubscriptionNotificationsResponse>(ON_RELYING_PARTY_ADMIN_CONVERSATIONS, {
        skip: !currentUser
      });

  const [readAllNotificationsAPI] = useMutation(READ_ALL_NOTIFICATIONS);

  const readAllNotifications = async () => {
    await readAllNotificationsAPI();
  };

  useMemo(() => {
    let messages: NotificationMessage[] = [];

    if (notificationsResponse?.relyingPartyAdminConversations) {
      if ('relyingPartyAdminMessages' in notificationsResponse.relyingPartyAdminConversations) {
        messages = notificationsResponse.relyingPartyAdminConversations.relyingPartyAdminMessages;
      } else {
        messages = notificationsResponse.relyingPartyAdminConversations;
      }

      let unreadCount = 0;
      const mappedNotifications: NotificationItem[] = messages.map((message): NotificationItem => {
        if (!message.isRead && message.sourceType === 'Account') {
          unreadCount++;
        }
        return {
          timestamp: new Date(message.createdAt).getTime(),
          message: message.messageBody,
          id: message.id,
          isRead: message.isRead,
          type: message.sourceType === 'RelyingPartyAdmin' ? UserTypes.user : UserTypes.otherUser,
          createdAt: new Date(message.createdAt),
          patientId: message?.account?.id,
          patientTruentiyId: message?.account?.truentityId,
          user:
            message.sourceType === 'RelyingPartyAdmin'
              ? {
                  name: `Me`,
                  avatar: `${currentUser?.user.firstName} ${currentUser?.user?.lastName}`
                }
              : {
                  name: `${message.account.user.firstName} ${message.account.user.lastName}`,
                  avatar: `${message.account.user.firstName} ${message.account.user.lastName}`
                }
        };
      });
      mappedNotifications.reverse();

      setNotifications(mappedNotifications);
      setUnreadNotificationCount(unreadCount);
    }
    return [];
  }, [currentUser?.user.firstName, currentUser?.user?.lastName, notificationsResponse?.relyingPartyAdminConversations]);

  return (
    <NotificationContext.Provider
      value={{
        unreadNotificationCount,
        setUnreadNotificationCount,
        notifications,
        setNotifications,
        readAllNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
