import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import TruentityTextField from '@/components/TruentityTextField';
import { H1 } from '@/components/Typography';
import {
  AccountsGatewayHardwareType,
  GetAccountsByGatewayAndDeviceIdResponse,
  GET_ACCOUNTS_BY_GATEWAY_AND_DEVICE_ID
} from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { getAccountUserFullName } from '@/util/account';
import { formatDateAndTime } from '@/util/format';
import { getCustomRowHeight, getReadingTypeValue } from '@/util/get';
import { DEFAULT_DEVICE_LENGTH, statusColorMapping } from '@/util/rpm';
import { useLazyQuery } from '@apollo/client';
import { Box, Chip, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

type FormValues = {
  deviceId: string;
};

export const RpmTenoviDevices = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm<FormValues>({});

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [rpmCandidatesTableData, setRpmCandidatesTableData] = useState<AccountsGatewayHardwareType[]>([]);

  const [
    getAccountsByGatewayAndDeviceQuery,
    { data: getAccountsByGatewayAndDeviceQueryData, loading: getAccountsByGatewayAndDeviceQueryLoading }
  ] = useLazyQuery<GetAccountsByGatewayAndDeviceIdResponse>(GET_ACCOUNTS_BY_GATEWAY_AND_DEVICE_ID, { fetchPolicy: 'network-only' });

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const result: any = await getAccountsByGatewayAndDeviceQuery({
        variables: {
          id: values.deviceId,
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });

      if (result.data?.getAccountsByGatewayDeviceId === null && result.errors) {
        const errorMessage = result.errors[0].message || 'Unknown error occurred';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });

        setRpmCandidatesTableData([]);
      }
    } catch (err) {
      console.error('Error occurred:', err);

      enqueueSnackbar('Unable to get accounts', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    setRowCountState(prevRowCountState => rowCount ?? prevRowCountState);
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (getAccountsByGatewayAndDeviceQueryData?.getAccountsByGatewayDeviceId) {
      setRpmCandidatesTableData(getAccountsByGatewayAndDeviceQueryData?.getAccountsByGatewayDeviceId?.rpmAccounts);
      setRowCount(getAccountsByGatewayAndDeviceQueryData?.getAccountsByGatewayDeviceId?.meta.totalCount ?? 0);
    }
  }, [getAccountsByGatewayAndDeviceQueryData]);

  const candidatesTableDataWithIds = rpmCandidatesTableData.map((row, index) => ({
    ...row,
    id: row.deviceId
  }));

  const columns: GridColDef<AccountsGatewayHardwareType>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          const name = getAccountUserFullName(params.row.user);
          const status = params.row.rpmStatus;

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              <Link to={`../../patients/${params.row.truentityId}/details/rpm/patient-setup/care-activity/clinical-summary`}>{name}</Link>
            </Stack>
          );
        }
      },
      {
        field: 'devices',
        headerName: 'Devices',
        sortable: false,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const deviceName = params.row.deviceName;
          return (
            <Stack
              paddingY={1}
              width="100%"
              height="100%"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="stretch"
              flexWrap="nowrap"
              gap=".3125rem"
            >
              <Box
                width="100%"
                textTransform="capitalize"
                sx={{
                  textOverflow: 'ellipsis'
                }}
              >
                <Chip size="small" label={deviceName} />
              </Box>
            </Stack>
          );
        }
      },
      {
        field: 'hardwareId',
        headerName: 'Hardware Id',
        sortable: true,
        minWidth: 110,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.hardwareId
      },
      {
        field: 'gatewayId',
        headerName: 'Gateway Id',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => {
          return params?.row?.formattedGatewayId ?? 'Unavailable';
        }
      },
      {
        field: 'lastReadingAt',
        headerName: 'Last Reading Date',
        sortable: false,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.latestReadings?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack
              paddingY={0}
              width="100%"
              height="100%"
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              gap=".3125rem"
              sx={{
                textOverflow: 'ellipsis',
                height: getCustomRowHeight(devices?.length ?? 0)
              }}
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(reading => (
                    <Box key={`${params.row.truentityId}-${reading?.id}`} textTransform="capitalize">
                      {reading?.recordedAt ? `${formatDateAndTime(reading?.recordedAt)}` : 'N/A'}
                    </Box>
                  ))}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'lastReadingValue',
        headerName: 'Last Reading',
        sortable: true,
        flex: 1,
        height: 'auto',
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.latestReadings?.slice(0, DEFAULT_DEVICE_LENGTH);

          return (
            <Stack
              paddingY={0}
              width="100%"
              height="100%"
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              gap=".3125rem"
              sx={{
                textOverflow: 'ellipsis',
                height: getCustomRowHeight(devices?.length ?? 0)
              }}
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(reading => (
                    <Box key={`${params.row.truentityId}-${reading?.id}`} textTransform="capitalize">
                      {reading ? `${getReadingTypeValue(reading)}` : 'N/A'}
                    </Box>
                  ))}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'status',
        headerName: 'Device Status',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          return <Chip label={params?.value} color={statusColorMapping.get(params?.value) ?? 'warning'} variant="filled" />;
        }
      },
      {
        field: 'lastGatewayCheckingTime',
        headerName: 'Last Gateway Check-in',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params =>
          params?.row?.lastGatewayCheckingTime ? formatDateAndTime(params?.row?.lastGatewayCheckingTime) ?? 'Unavailable' : 'Unavailable'
      }
    ],
    []
  );

  return (
    <Stack>
      <Stack
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          padding: 3,
          backgroundColor: color.paper,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          RPM Devices
        </H1>
        <>
          <Controller
            control={control}
            name="deviceId"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField sx={{ width: '300px' }} required onChange={onChange} value={value} label={'Hardware ID or Gateway ID'} />
            )}
          />
        </>
        <Button type="submit" sx={{ width: '200px', height: '50px' }} label="Search" />
      </Stack>

      <Stack
        sx={{
          backgroundColor: '#fff',
          marginTop: '16px',
          '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '8px 16px' }
        }}
      >
        <TruentityDataGrid
          name={'dg-rpm-enrollment'}
          style={{ border: 'none', outline: 'none' }}
          autoHeight
          disableColumnMenu={false}
          rows={candidatesTableDataWithIds}
          columns={columns}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={getAccountsByGatewayAndDeviceQueryLoading}
          rowCount={rowCountState}
          paginationMode="server"
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
        />
      </Stack>
    </Stack>
  );
};

export default RpmTenoviDevices;
