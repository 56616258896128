import { gql } from '@apollo/client';

export const GET_ACTIVITY_LOGS_USER = gql`
  query activityLogs($truentityId: String!, $pageNum: Int!, $pageSize: Int!, $activityType: String!) {
    activityLogs(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize, activityType: $activityType) {
      activityLogs {
        id
        logText
        activityType
        activityTime
        data
        relyingPartyAdmin {
          name
          email
        }
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;
export const START_ACTIVITY_LOG = gql`
  mutation StartMedTasksActivity($truentityId: String!) {
    startMedTasksActivity(truentityId: $truentityId) {
      activityLog {
        id
        logText
        activityType
        activityTime
      }
    }
  }
`;

export const END_ACTIVITY_LOG = gql`
  mutation EndMedTasksActivity($activityLogId: ID!) {
    endMedTasksActivity(activityLogId: $activityLogId) {
      activityLog {
        id
        logText
        activityType
        activityEndTime
        timeTakenSecs
      }
    }
  }
`;

export const LOGOUT_LOG = gql`
  mutation RelyingPartyLogout($timedOut: Boolean!) {
    relyingPartyLogout(timedOut: $timedOut) {
      status
    }
  }
`;

export const RELYING_PARTY_NAVIGATE = gql`
  mutation RelyingPartyAdminNavigate($navigateTo: String!, $prevActivityLogId: ID!, $truentityId: String) {
    relyingPartyAdminNavigate(navigateTo: $navigateTo, prevActivityLogId: $prevActivityLogId, truentityId: $truentityId) {
      activityLog {
        id
        logText
        activityType
        activityTime
        activityEndTime
        timeTakenSecs
      }
    }
  }
`;
export const HEARTBEAT = gql`
  mutation RelyingPartyAdminHeartbeat {
    relyingPartyAdminHeartbeat {
      status
    }
  }
`;
