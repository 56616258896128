import CollapsibleListGroup from '@/components/CollapsibleListGroup';
import type { GetFiltersByRelyingPartyQueryResponse } from '@/graphql/filters';
import { DELETE_FILTER_TEMPLATE, FilterAvailabilityType, FilterTemplateType, GET_FILTERS_BY_RELYING_PARTY } from '@/graphql/filters';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import { transformAllToPatientFilter } from '@/routes/Patients/PatientsList';
import { useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import { List, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledListBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  [`.MuiButtonBase-root .MuiListItemSecondaryAction-root`]: {
    userSelect: 'none',
    cursor: 'pointer',
    visibility: 'hidden'
  },
  [`.MuiButtonBase-root:hover .MuiListItemSecondaryAction-root`]: {
    visibility: 'visible'
  }
}));

type Props = {
  selected: boolean;
};

const FiltersCollection = ({ selected }: Props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { filters, setFilters, selectedFilter, setSelectedFilter } = usePatientFilterStore();
  const {
    loading: patientListFiltersLoading,
    data: patientListFilterResponse,
    error: patientListFiltersError,
    refetch: patientListFiltersRefetch
  } = useQuery<GetFiltersByRelyingPartyQueryResponse>(GET_FILTERS_BY_RELYING_PARTY, {
    variables: {
      availabilityType: FilterAvailabilityType.Admin,
      category: FilterTemplateType.PatientList
    }
  });

  const [deleteFilterTemplate] = useMutation(DELETE_FILTER_TEMPLATE);

  useEffect(() => {
    if (patientListFiltersError) {
      console.error(patientListFiltersError?.message);
      enqueueSnackbar('Error loading filters', { variant: 'error' });
    }
  }, [enqueueSnackbar, patientListFiltersError]);

  useEffect(() => {
    if (patientListFilterResponse?.filtersByRelyingParty?.filterTemplates) {
      setFilters(transformAllToPatientFilter(patientListFilterResponse.filtersByRelyingParty.filterTemplates));
    }
  }, [patientListFilterResponse, setFilters]);

  const deleteFilter = (templateId: string) => {
    deleteFilterTemplate({
      variables: {
        templateId
      }
    })
      .then(() => {
        enqueueSnackbar('Filter Deleted', { variant: 'info' });
        patientListFiltersRefetch().catch(err => {
          console.error(err);
          enqueueSnackbar('Error loading filters', { variant: 'error' });
        });
      })
      .catch(err => console.error(err));
  };

  return (
    <CollapsibleListGroup
      primaryText="Patients List"
      selected={selected}
      icon={<ListIcon />}
      disableCollapse={filters.length === 0 || patientListFiltersLoading}
      defaultOpen={selected}
      onMainTabItemClicked={() => {
        navigate('../patients/list');
      }}
    >
      <List>
        <StyledListBox>
          {filters.map((f, index) => (
            <ListItemButton
              key={index}
              onClick={() => {
                setSelectedFilter(f);
                navigate('../patients/list');
              }}
              selected={selectedFilter?.id === f.id}
            >
              <ListItemText color="secondary" primary={f.name} />
              <ListItemSecondaryAction
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  deleteFilter(f.id);
                }}
              >
                <DeleteIcon aria-label={'delete'} edgeMode={'end'} size={'small'} />
              </ListItemSecondaryAction>
            </ListItemButton>
          ))}
        </StyledListBox>
      </List>
    </CollapsibleListGroup>
  );
};

export default FiltersCollection;
