import { MedicationStatus } from '@/types/triage-medication-types';
import React from 'react';

import { Medication, MedicationVerificationStatus } from '../types/medication';

type TriageContext = {
  triageMedications: Medication[];
  changeMedicationStatuses: (statuses: MedicationStatus[]) => void;
  updateMedications: (medications: Medication[]) => void;
  medicationSelected: (medication: any) => void;
  verifyMedications: (statuses: MedicationVerificationStatus[]) => void;
};

const initialValue = {
  triageMedications: [],
  changeMedicationStatuses: (statuses: MedicationStatus[]) => {},
  updateMedications: (medications: Medication[]) => {},
  medicationSelected: (medication: any) => {},
  verifyMedications: (statuses: MedicationVerificationStatus[]) => {}
};

const TriageContext = React.createContext<TriageContext>(initialValue);

export default TriageContext;
