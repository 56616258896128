import Button from '@/components/Button';
import { ShowMoreOrgStorePopover } from '@/components/Popovers';
import type { PatientsData } from '@/routes/Patients/patients';
import styled from '@/styles';
import { zip } from '@/util/array';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';

type HealthPlanRowProps = {
  patientInfo: PatientsData;
  goToEditProfile: () => void;
};
export const MAX_NAMES_TO_SHOW = 1;
export const HealthPlanRow = ({ patientInfo, goToEditProfile }: HealthPlanRowProps) => {
  const orgNames = patientInfo?.clientOrgs?.map(org => org?.name);
  const storeNames = patientInfo?.clientStores?.map(store => store?.name);
  const orgStorePairs = zip(orgNames || [], storeNames || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setShowMore(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowMore(false);
  };

  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Chip
        label={patientInfo?.healthPlan?.orgName || 'Healthplan not assigned'}
        onClick={goToEditProfile}
        variant={'outlined'}
        sx={{ width: 'fit-content' }}
      />
      {orgStorePairs.length !== 0 && <Divider orientation="vertical" flexItem />}

      <Stack direction="row" spacing={1}>
        {orgStorePairs.slice(0, MAX_NAMES_TO_SHOW).map(([orgName, storeName]) => (
          <React.Fragment key={`${orgName}-${storeName}`}>
            {orgName && <Chip size="small" label={orgName} />}
            {storeName && <Chip size="small" label={storeName} variant="outlined" />}
          </React.Fragment>
        ))}
        {orgStorePairs.length > MAX_NAMES_TO_SHOW && (
          <ButtonMore>
            <Button
              variant="text"
              label={`${orgStorePairs.length - MAX_NAMES_TO_SHOW}+`}
              onClick={handleClick}
              endIcon={showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            />
          </ButtonMore>
        )}
      </Stack>

      <ShowMoreOrgStorePopover
        showMore={showMore}
        anchorEl={anchorEl}
        handleClose={handleClose}
        orgStorePairs={orgStorePairs}
        maxNamesToShow={MAX_NAMES_TO_SHOW}
      />
    </Stack>
  );
};
export const ButtonMore = styled('div', {
  margin: 'auto !important',
  '& button': {
    marginLeft: '8px'
  },
  '.MuiButton-iconSizeSmall': {
    margin: '0px !important'
  }
});
