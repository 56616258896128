import { Body1 } from '@/components/Typography';
import type { AccountCareEventsByFilterResponse } from '@/graphql/transitionalCareManagement';
import { GET_ACCOUNT_CARE_EVENTS_BY_FILTER } from '@/graphql/transitionalCareManagement';

import { useLazyQuery, useMutation } from '@apollo/client';
import { Chip, Grid, Paper, Stack, Tab, Tabs, useTheme } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';

import AdtFilters from '@/components/AdtFilters';
import AdtFilterTemplates from '@/components/AdtFilterTemplates';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { SaveTemplateDialog } from '@/components/Dialogs';
import { FilterType } from '@/components/Dialogs/AdtFilterDialog';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import Icon from '@/elements/Icon';
import { color } from '@/styles/assets/colors';
import type { AccountCareEvent } from '@/types/dischargePatient';
import { getAccountUserFullName } from '@/util/account';

import FilterContext from '@/context/filterContext';
import {
  CREATE_FILTER_TEMPLATE,
  DELETE_FILTER_TEMPLATE,
  FilterAvailabilityType,
  FilterTemplateType,
  GET_FILTERS_BY_RELYING_PARTY,
  UPDATE_FILTER_TEMPLATE
} from '@/graphql/filters';
import { toDateOrNull } from '@/util/date';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import ClearIcon from '@mui/icons-material/Clear';
import AttemptedIcon from '@mui/icons-material/Done';
import FilterListIcon from '@mui/icons-material/FilterList';
import FlagIcon from '@mui/icons-material/Flag';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Badge from '@mui/material/Badge';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import type { AccountCareEventStatus } from './AdtQueueUtil';
import { AccountCareEventStatuses, EventTypeColorMap, FilterModalTypes, getBadgeCount, onFiltersAppliedTemp } from './AdtQueueUtil';
import PatientInfoDrawer from './PatientInfoDrawer';

//TODO: ADT Events which are incorrect (unkniown discharge date need to be ignore after selecting an adt event and loading the user's adt view)

type TabData = { a11yLabel: string; label: string; value: AccountCareEventStatus; icon: string | React.ReactElement };

const AdtQueue = () => {
  const { setContent } = useContext(SideMenuContext);

  const { filtersToBeApplied, setFiltersToBeApplied } = useContext(FilterContext);

  const theme = useTheme();
  const { showModal, hideModal } = useModal();

  const { enqueueSnackbar } = useSnackbar();

  const [createFilterTemplate] = useMutation(CREATE_FILTER_TEMPLATE);
  const [updateFilterTemplate] = useMutation(UPDATE_FILTER_TEMPLATE);
  const [deleteFilterTemplate] = useMutation(DELETE_FILTER_TEMPLATE);

  const [getRelyingPartyFilters, { data: relyingPartyEventFilters, refetch: relyingPartyEventFiltersRefetch }] =
    useLazyQuery(GET_FILTERS_BY_RELYING_PARTY);
  const [getRelyingPartyAdminFilters, { data: relyingPartyAdminEventFilters, refetch: relyingPartyAdminEventFiltersRefetch }] =
    useLazyQuery(GET_FILTERS_BY_RELYING_PARTY);

  const [getEventsForTabCounts, { data: totalAccountCareFilterData }] =
    useLazyQuery<AccountCareEventsByFilterResponse>(GET_ACCOUNT_CARE_EVENTS_BY_FILTER);

  const [getEvents, { loading: loadingAccountCareEvents, data: accountCareFilterData }] =
    useLazyQuery<AccountCareEventsByFilterResponse>(GET_ACCOUNT_CARE_EVENTS_BY_FILTER);

  const [selectedPatient, setSelectedPatient] = useState<AccountCareEvent>({} as AccountCareEvent);

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);

  const [tab, setTab] = useState<number>(0);
  const tabs: TabData[] = useMemo(
    () => [
      {
        a11yLabel: 'Pending',
        label: 'Pending',
        value: AccountCareEventStatuses.Pending,
        icon: <PendingIcon />
      },
      {
        a11yLabel: 'InProgress',
        label: 'In Progress',
        value: AccountCareEventStatuses.InProgress,
        icon: <AttemptedIcon />
      },
      {
        a11yLabel: 'Processed',
        label: 'Processed',
        value: AccountCareEventStatuses.Processed,
        icon: <TaskAltIcon />
      },
      {
        a11yLabel: 'Missed',
        label: 'Missed',
        value: AccountCareEventStatuses.Missed,
        icon: <CallMissedIcon />
      }
    ],
    []
  );

  const [accountCareEvents, setAccountCareEvents] = useState<AccountCareEvent[]>([]);
  const [filteredAccountCareEvents, setFilteredAccountCareEvents] = useState<AccountCareEvent[]>([]);
  const [filteredTaskStatusesPendingCount, setFilteredTaskStatusesPendingCount] = useState(0);
  const [filteredTaskStatusesInProgressCount, setFilteredTaskStatusesInProgressCount] = useState(0);
  const [filteredTaskStatusesProcessedCount, setFilteredTaskStatusesProcessedCount] = useState(0);
  const [filteredTaskStatusesMissedCount, setFilteredTaskStatusesMissedCount] = useState(0);
  const [adtQueueStatus, setAdtQueueStatus] = useState<AccountCareEventStatus>(AccountCareEventStatuses.Pending);
  const [currentPatientDischargeCount, setCurrentPatientDischargeCount] = useState(0);
  const [currentPatientAdmitCount, setCurrentPatientAdmitCount] = useState(0);

  const [selectedFiltersForUI, setSelectedFiltersForUI] = useState([]);

  useEffect(() => {
    getRelyingPartyFilters({
      variables: {
        availabilityType: FilterAvailabilityType.Org,
        category: FilterTemplateType.Adt
      }
    });

    getRelyingPartyAdminFilters({
      variables: {
        availabilityType: FilterAvailabilityType.Admin,
        category: FilterTemplateType.Adt
      }
    });
  }, []);

  //TODO:  This doesnt make much sense with pagination, we should get the API to return this counts
  useEffect(() => {
    const allEvents = totalAccountCareFilterData || [];

    setFilteredTaskStatusesPendingCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesPendingCount);
    setFilteredTaskStatusesInProgressCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesInProgressCount);
    setFilteredTaskStatusesProcessedCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesProcessedCount);
    setFilteredTaskStatusesMissedCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesMissedCount);
  }, [totalAccountCareFilterData]);

  useEffect(() => {
    // since we are adding 30 days to the current date which is  being used
    // for the due date filters, we can take this off
    // setFilterDueDate(new Date())
    setContent(<MainSideMenu />);
  }, [setContent]);

  useEffect(() => {
    setCurrentPatientAdmitCount(accountCareFilterData?.accountCareEventsByFilter?.admitCount || 0);
    setCurrentPatientDischargeCount(accountCareFilterData?.accountCareEventsByFilter?.dischargeCount || 0);
  }, [accountCareFilterData?.accountCareEventsByFilter?.admitCount, accountCareFilterData?.accountCareEventsByFilter?.dischargeCount]);

  useEffect(() => {
    if (accountCareFilterData) {
      if (accountCareFilterData?.accountCareEventsByFilter?.events.length > 0) {
        setAccountCareEvents(accountCareFilterData?.accountCareEventsByFilter?.events);
        setRowCount(accountCareFilterData?.accountCareEventsByFilter?.meta.totalCount);
      } else {
        setAccountCareEvents([]);
        setRowCount(0);
      }
    }
  }, [accountCareFilterData]);

  const sendPatientDataToDrawer = (patientData: AccountCareEvent) => {
    setSelectedPatient(patientData);
  };

  useEffect(() => {
    const filtered = accountCareEvents;
    setFilteredAccountCareEvents(filtered);
  }, [accountCareEvents]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    setCurrentPage(0);
  }, [rowCountState]);

  const columns: GridColDef<AccountCareEvent>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        minWidth: 160,
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: cellValues => getAccountUserFullName(cellValues.row.account.user),
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="left" justifyContent={'left'}>
              {getAccountUserFullName(cellValues.row.account.user)}
            </Stack>
          );
        }
      },
      {
        field: 'eventType',
        headerName: 'Event Type',
        sortable: true,
        flex: 1,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        valueGetter: cellValues => cellValues.row.eventType?.toLowerCase() || 'unavailable',
        renderCell: cellValues => {
          return (
            <Stack
              spacing={3}
              direction="row"
              sx={{ width: '100%', userSelect: 'none', textTransform: 'capitalize' }}
              alignItems="center"
              justifyContent={'center'}
            >
              <Chip label={cellValues.value as string} color={EventTypeColorMap.get(cellValues.value as string)} variant="filled" />
            </Stack>
          );
        }
      },
      {
        field: 'facilityName',
        headerName: 'Facility Name',
        sortable: true,
        flex: 1,
        minWidth: 280,
        headerAlign: 'center',
        valueGetter: params => params?.row.eventFacilityName,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="left" justifyContent={'left'}>
              {cellValues.row.eventFacilityName}
            </Stack>
          );
        }
      },
      {
        field: 'facilityType',
        headerName: 'Facility Type',
        sortable: true,
        flex: 1,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params?.row.eventFacilityType,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'center'}>
              <Chip label={cellValues.row.eventFacilityType || 'Unavailable'} color="primary" variant="outlined" />
            </Stack>
          );
        }
      },
      {
        field: 'readmissionRisk',
        headerName: 'Readmission Risk',
        sortable: true,
        valueGetter: params => params?.row.readmissionRiskFlag,
        renderCell: params => {
          return params?.row.readmissionRiskFlag ? <FlagIcon style={{ fill: 'red' }} /> : <FlagIcon style={{ fill: 'green' }} />;
        },
        headerAlign: 'center',
        align: 'center',
        minWidth: 130
      },
      {
        field: 'dischargeDate',
        headerName: 'Discharge Date',
        type: 'date',
        sortable: true,
        minWidth: 160,
        valueGetter: params => toDateOrNull(params.value),
        renderCell: params => {
          return moment(params.row.dischargeDate).format('MMM DD, YYYY');
        },
        headerAlign: 'center',
        align: 'center'
      },
      {
        field: 'dischargeDisposition',
        headerName: 'Discharged Disposition',
        sortable: true,
        valueGetter: params => params?.row.dischargedDisposition,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'center'}>
              <Chip label={cellValues.row.dischargedDisposition || 'Unavailable'} color="primary" variant="outlined" />
            </Stack>
          );
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 160
      },
      {
        field: 'admissionDate',
        headerName: 'Admission Date',
        type: 'date',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        resizable: true,
        valueGetter: params => toDateOrNull(params.value),
        renderCell: params => {
          return moment(params.row.admissionDate).format('MMM DD, YYYY');
        }
      },
      {
        field: 'admittedFrom',
        headerName: 'Admitted From',
        minWidth: 100,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.admittedFrom
      },
      {
        field: 'finalDueDate',
        headerName: 'Final Due Date',
        type: 'date',
        minWidth: 150,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => toDateOrNull(params.row.tcmTasks[0]?.finalDueDate)
      },
      {
        field: 'actions',
        headerName: 'Details',
        sortable: false,
        flex: 1,
        align: 'center',
        minWidth: 100,
        headerAlign: 'center',
        filterable: false,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <Link to={`/patients/${cellValues.row.account.truentityId}/details/adt-events/view/${cellValues.row.id}`}>
                <Icon icon={faUserCog} fixedWidth size="lg" />
              </Link>
            </Stack>
          );
        }
      }
    ],
    []
  );

  const [formInputFields, setFormInputFields] = useState([{ filterType: '', operatorType: '', eventType: [] }]);
  const [formInputFieldsTemplates, setFormInputFieldsTemplates] = useState([{ filterType: '', operatorType: '', eventType: [] }]);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, selectedTabIndex: number) => {
      setTab(selectedTabIndex);

      const selectedStatus: AccountCareEventStatus = tabs[selectedTabIndex].value;
      setAdtQueueStatus(selectedStatus);

      const {
        selectedEvent,
        readmissionrisk,
        initialCommsDueDateTo,
        initialCommsDueDateFrom,
        admissionReason,
        dischargeDateTo,
        dischargeDateFrom,
        admissionDateTo,
        admissionDateFrom,
        dischargedDisposition,
        dischargingPhysician,
        selectedFilters
      } = onFiltersAppliedTemp(filtersToBeApplied);

      getEvents({
        variables: {
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1,
          filterOptions: {
            status: selectedStatus,
            eventType: selectedEvent ? selectedEvent : 'all',
            readmissionRiskFlag: readmissionrisk,
            initialCommsDueDateTo,
            initialCommsDueDateFrom,
            admissionReason,
            dischargeDateTo,
            dischargeDateFrom,
            admissionDateTo,
            admissionDateFrom,
            dischargedDisposition,
            dischargingPhysician
          }
        }
      }).catch(err => {
        console.error(err);
      });
    },
    [tabs, filtersToBeApplied]
  );

  const [myFilters, setMyFilters] = useState([]);
  const [relyingPartyFilters, setRelyingPartyFilters] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const filtersModalId = useRef<null | string>(null);
  const templateModalId = useRef<null | string>(null);

  const onFilterAddClicked = (fields, modalType) => {
    const newfield = { filterType: '', eventType: [] };

    if (modalType === FilterModalTypes.AdtFilter) {
      setFormInputFields([...fields, newfield]);
    } else if (FilterModalTypes.AdtFilterTemplate) {
      setFormInputFieldsTemplates([...fields, newfield]);
    }
  };

  const onDeleteIconClicked = (index, fields, modalType) => {
    const tempArray = [...fields];
    tempArray.splice(index, 1);

    if (modalType === FilterModalTypes.AdtFilter) {
      setFormInputFields(tempArray);
    } else if (FilterModalTypes.AdtFilterTemplate) {
      setFormInputFieldsTemplates(tempArray);
    }
  };

  const handleFormChange = (index, event) => {
    const data = [...formInputFields];
    data[index][event.target.name] = event.target.value;

    setFormInputFields(data);
  };

  const handleFormChangeTemplateModal = (index, event) => {
    const data = [...formInputFieldsTemplates];
    data[index][event.target.name] = event.target.value;

    setFormInputFieldsTemplates(data);
  };

  const [isDateChanged, setIsDateChanged] = useState({
    isChanged: false,
    changedIndex: null,
    changedData: null
  });

  const [isToDateChanged, setIsToDateChanged] = useState({
    isChanged: false,
    changedIndex: null,
    changedData: null
  });

  const [isDateChangedTemplateModal, setIsDateChangedTemplateModal] = useState({
    isChanged: false,
    changedIndex: null,
    changedData: null
  });

  const [isToDateChangedTemplateModal, setIsToDateChangedTemplateModal] = useState({
    isChanged: false,
    changedIndex: null,
    changedData: null
  });

  const handleDateChange = (index, event) => {
    const dateChangeObject = {
      isChanged: true,
      changedIndex: index,
      changedData: event
    };

    setIsDateChanged(dateChangeObject);
  };

  const handleToDateChange = (index, event) => {
    const dateChangeObject = {
      isChanged: true,
      changedIndex: index,
      changedData: event
    };

    setIsToDateChanged(dateChangeObject);
  };

  const handleDateChangeTemplateModal = (index, event) => {
    const dateChangeObject = {
      isChanged: true,
      changedIndex: index,
      changedData: event
    };

    setIsDateChangedTemplateModal(dateChangeObject);
  };

  const handleToDateChangeTemplateModal = (index, event) => {
    const dateChangeObject = {
      isChanged: true,
      changedIndex: index,
      changedData: event
    };

    setIsToDateChangedTemplateModal(dateChangeObject);
  };

  useEffect(() => {
    if (isDateChanged.isChanged) {
      const data = [...formInputFields];

      if (data[isDateChanged.changedIndex]) {
        data[isDateChanged.changedIndex]['eventType'][0] = isDateChanged.changedData;
        setFormInputFields(data);

        const changedDateObject = {
          isChanged: false,
          changedIndex: null,
          changedData: null
        };
        setIsDateChanged(changedDateObject);
      }
    }
  }, [isDateChanged, formInputFields]);

  useEffect(() => {
    if (isToDateChanged.isChanged) {
      const data = [...formInputFields];

      if (data[isToDateChanged.changedIndex]) {
        data[isToDateChanged.changedIndex]['eventType'][1] = isToDateChanged.changedData;
        setFormInputFields(data);

        const changedDateObject = {
          isChanged: false,
          changedIndex: null,
          changedData: null
        };
        setIsToDateChanged(changedDateObject);
      }
    }
  }, [isToDateChanged, formInputFields]);

  useEffect(() => {
    if (isDateChangedTemplateModal.isChanged) {
      const data = [...formInputFieldsTemplates];

      if (data[isDateChangedTemplateModal.changedIndex]) {
        data[isDateChangedTemplateModal.changedIndex]['eventType'][0] = isDateChangedTemplateModal.changedData;
        setFormInputFields(data);

        const changedDateObject = {
          isChanged: false,
          changedIndex: null,
          changedData: null
        };
        setIsDateChangedTemplateModal(changedDateObject);
      }
    }
  }, [isDateChangedTemplateModal, formInputFieldsTemplates]);

  useEffect(() => {
    if (isToDateChangedTemplateModal.isChanged) {
      const data = [...formInputFieldsTemplates];

      data[isToDateChangedTemplateModal.changedIndex]['eventType'][1] = isToDateChangedTemplateModal.changedData;
      setFormInputFields(data);

      const changedDateObject = {
        isChanged: false,
        changedIndex: null,
        changedData: null
      };
      setIsToDateChangedTemplateModal(changedDateObject);
    }
  }, [isToDateChangedTemplateModal, formInputFieldsTemplates]);

  useEffect(() => {
    const {
      selectedEvent,
      readmissionrisk,
      initialCommsDueDateTo,
      initialCommsDueDateFrom,
      admissionReason,
      dischargeDateTo,
      dischargeDateFrom,
      admissionDateTo,
      admissionDateFrom,
      dischargedDisposition,
      dischargingPhysician,
      selectedFilters
    } = onFiltersAppliedTemp(filtersToBeApplied);

    setSelectedFiltersForUI(selectedFilters);

    getEventsForTabCounts({
      variables: {
        pageSize: 1,
        pageNum: 10000,
        filterOptions: {
          status: null,
          eventType: selectedEvent ? selectedEvent : 'all', // by default, the value is 'all'. but based on the change it will reftech the tab counts
          readmissionRiskFlag: readmissionrisk,
          initialCommsDueDateTo,
          initialCommsDueDateFrom,
          admissionReason,
          dischargeDateTo,
          dischargeDateFrom,
          admissionDateTo,
          admissionDateFrom,
          dischargedDisposition,
          dischargingPhysician
        }
      }
    }).catch(err => {
      console.error(err);
    });

    getEvents({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        // relyingPartyAdminId: currentUser?.id,
        filterOptions: {
          status: adtQueueStatus,
          eventType: selectedEvent ? selectedEvent : 'all',
          readmissionRiskFlag: readmissionrisk,
          // initialCommsDueDate,
          initialCommsDueDateTo,
          initialCommsDueDateFrom,
          admissionReason,
          dischargeDateTo,
          dischargeDateFrom,
          admissionDateTo,
          admissionDateFrom,
          dischargedDisposition,
          dischargingPhysician
        }
      }
    }).catch(err => {
      console.error(err);
    });

    setTimeout(() => {
      hideModal(filtersModalId.current);
      hideModal(templateModalId.current);
    }, 2000);
  }, [filtersToBeApplied]);

  const onFiltersApplied = filters => {
    setFiltersToBeApplied(filters);
  };

  useEffect(() => {
    if (relyingPartyEventFilters) {
      setRelyingPartyFilters(relyingPartyEventFilters?.filtersByRelyingParty?.filterTemplates);
    }
  }, [relyingPartyEventFilters]);

  useEffect(() => {
    if (relyingPartyAdminEventFilters) {
      setMyFilters(relyingPartyAdminEventFilters?.filtersByRelyingParty?.filterTemplates);
    }
  }, [relyingPartyAdminEventFilters]);

  const onTemplateSelected = selectedTemplate => {
    setSelectedTemplate(selectedTemplate);

    if (selectedTemplate?.filterTemplateAttributes?.length > 0) {
      const filtersFromTemplate = selectedTemplate?.filterTemplateAttributes?.map(templateAtttibute => {
        if (
          templateAtttibute.filterType === FilterType.InitialCommunicationDueBy ||
          templateAtttibute.filterType === FilterType.DischargeDate ||
          templateAtttibute.filterType === FilterType.AdmissionDate
        ) {
          return {
            filterType: templateAtttibute.filterType,
            operatorType: templateAtttibute.operatorType,
            eventType: [templateAtttibute.value, templateAtttibute.value2]
          };
        }

        return {
          filterType: templateAtttibute.filterType,
          operatorType: templateAtttibute.operatorType,
          eventType: templateAtttibute.value
        };
      });

      setFormInputFieldsTemplates(filtersFromTemplate);
    }
  };

  const showSaveFiltersModal = (fields, type) => {
    const modal = showModal(SaveTemplateDialog, {
      title: 'Save Template',
      successMessage: 'Comment Saved',
      hideDialog: () => modal.hide(),
      onSaveFilterClickedFromTemplateModal: async name => {
        if (name === '') {
          const variant = 'error';
          enqueueSnackbar('Name field is required', { variant });
          return;
        }

        const responseAfterSaving = await saveTemplateUtil(name, fields, type);
        if (responseAfterSaving) {
          relyingPartyEventFiltersRefetch();
          relyingPartyAdminEventFiltersRefetch();

          /*
           * remove all values on success
           */
          setFormInputFields([{ filterType: '', operatorType: '', eventType: [] }]);

          modal.hide();

          // hide main modal as well
          hideModal(filtersModalId.current);
        }
      }
    });
  };

  const saveTemplateUtil = async (templateName, templateAttributes, templateType) => {
    try {
      const mappedAttributes = templateAttributes?.map(templateAtttibute => {
        if (
          templateAtttibute.filterType === FilterType.InitialCommunicationDueBy ||
          templateAtttibute.filterType === FilterType.DischargeDate ||
          templateAtttibute.filterType === FilterType.AdmissionDate
        ) {
          return {
            filterType: templateAtttibute.filterType,
            operatorType: 'IS',
            value: templateAtttibute.eventType[0],
            value2: templateAtttibute.eventType[1]
          };
        }

        return {
          filterType: templateAtttibute.filterType,
          operatorType: 'IS',
          value: templateAtttibute.eventType
        };
      });

      const responseAfterMutationRUn = await createFilterTemplate({
        variables: {
          name: templateName,
          availabilityType: templateType,
          category: FilterTemplateType.Adt,
          filterInputData: mappedAttributes
        }
      });

      if (responseAfterMutationRUn?.data?.addFilterTemplate?.filterTemplate) {
        relyingPartyEventFiltersRefetch();
        relyingPartyAdminEventFiltersRefetch();
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const onSaveFilterClicked = (fields, type) => {
    if (fields.length === 1 && fields[0].eventType === '' && fields[0].filterType === '' && fields[0].operatorType === '') {
      const variant = 'error';
      enqueueSnackbar('No Filters have been added to save', {
        variant
      });
      return;
    }
    showSaveFiltersModal(fields, type);
  };

  const onUpdateFilterClicked = (selectedTemplate, fields) => {
    if (fields.length === 1 && fields[0].eventType === '' && fields[0].filterType === '' && fields[0].operatorType === '') {
      const variant = 'error';
      enqueueSnackbar('No Filters have been added to save', {
        variant
      });
      return;
    }

    const mappedAttributes = fields?.map(templateAtttibute => {
      if (
        templateAtttibute.filterType === FilterType.InitialCommunicationDueBy ||
        templateAtttibute.filterType === FilterType.DischargeDate ||
        templateAtttibute.filterType === FilterType.AdmissionDate
      ) {
        return {
          filterType: templateAtttibute.filterType,
          operatorType: 'IS',
          value: templateAtttibute.eventType[0],
          value2: templateAtttibute.eventType[1]
        };
      }

      return {
        filterType: templateAtttibute.filterType,
        operatorType: 'IS',
        value: templateAtttibute.eventType
      };
    });

    updateFilterTemplate({
      variables: {
        templateId: selectedTemplate?.id,
        filterInputData: mappedAttributes
      }
    }).then(res => {
      if (res && res?.data?.updateFilterTemplate?.filterTemplate) {
        relyingPartyEventFiltersRefetch();
        relyingPartyAdminEventFiltersRefetch();
        const variant = 'success';
        enqueueSnackbar('Filters updated', {
          variant
        });
        return;
      }
    });
  };

  const onRemoveFilterClicked = selectedTemplate => {
    setFormInputFieldsTemplates([{ filterType: '', operatorType: '', eventType: '' }]);
    setSelectedTemplate(null);

    deleteFilterTemplate({
      variables: {
        templateId: selectedTemplate?.id
      }
    }).then(res => {
      if (res && res?.data?.deleteFilterTemplate?.status === 'Success') {
        relyingPartyEventFiltersRefetch();
        relyingPartyAdminEventFiltersRefetch();

        const variant = 'error';
        enqueueSnackbar('Removed filter template', {
          variant
        });

        hideModal(templateModalId.current);
        return;
      }
    });
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={9}>
            <Stack direction={'row'} sx={{ marginTop: '20px', marginLeft: '1%' }} spacing={2} alignItems="center">
              <Stack direction={'row'} spacing={1}>
                <AdtFilters
                  formInputFields={formInputFields}
                  onFilterAddClicked={onFilterAddClicked}
                  onDeleteIconClicked={onDeleteIconClicked}
                  handleFormChange={handleFormChange}
                  handleDateChange={handleDateChange}
                  handleToDateChange={handleToDateChange}
                  onFiltersApplied={onFiltersApplied}
                  relyingPartyFilters={relyingPartyFilters}
                  relyingPartyAdminFilters={myFilters}
                  onTemplateSelected={onTemplateSelected}
                  onSaveFilterClicked={onSaveFilterClicked}
                  filtersModalId={filtersModalId}
                />

                <AdtFilterTemplates
                  formInputFields={formInputFieldsTemplates}
                  onFilterAddClicked={onFilterAddClicked}
                  onDeleteIconClicked={onDeleteIconClicked}
                  handleFormChange={handleFormChangeTemplateModal}
                  handleDateChange={handleDateChangeTemplateModal}
                  handleToDateChange={handleToDateChangeTemplateModal}
                  onFiltersApplied={onFiltersApplied}
                  relyingPartyFilters={relyingPartyFilters}
                  relyingPartyAdminFilters={myFilters}
                  onTemplateSelected={onTemplateSelected}
                  onSaveFilterClicked={onSaveFilterClicked}
                  filtersModalId={templateModalId}
                  onUpdateFilterClicked={onUpdateFilterClicked}
                  onRemoveFilterClicked={onRemoveFilterClicked}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                />
              </Stack>

              {selectedFiltersForUI.length > 0 && (
                <Stack direction={'row'} spacing={1}>
                  <Button variant="contained" startIcon={<FilterListIcon />} appearance="secondary">
                    {selectedFiltersForUI.length}
                  </Button>

                  <Stack
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'center'}
                    p={0}
                    sx={{
                      backgroundColor: '#F6F6F6',
                      borderRadius: 4
                    }}
                    spacing={1}
                  >
                    <Button
                      a11yLabel="Clear Filters"
                      type="button"
                      appearance="secondary"
                      onClick={() => {
                        onFiltersApplied([]);

                        setFormInputFields([{ filterType: '', operatorType: '', eventType: [] }]);
                        setFormInputFieldsTemplates([{ filterType: '', operatorType: '', eventType: [] }]);
                      }}
                      endIcon={<ClearIcon />}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid
            spacing={6}
            sx={{
              flexDirection: 'row-reverse',
              display: 'inline-flex',
              marginTop: '20px',
              marginLeft: '7%'
            }}
            item
            xs={5}
          >
            <Badge sx={{ marginLeft: '20px' }} badgeContent={currentPatientDischargeCount} color="primary">
              <ArrowCircleUpIcon color="action" />
            </Badge>
            <Badge badgeContent={currentPatientAdmitCount} color="primary">
              <ArrowCircleDownIcon color="action" />
            </Badge>
          </Grid>
        </Grid>
      </Stack>

      <Paper component={Stack} direction="column">
        <Stack
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            borderColor: 'divider',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab
                icon={tab.icon}
                iconPosition={'start'}
                label={
                  <Body1 textTransform="capitalize">
                    {tab.label}
                    <Chip
                      sx={{ marginLeft: 1 }}
                      label={getBadgeCount(
                        tab.label,
                        filteredTaskStatusesPendingCount,
                        filteredTaskStatusesInProgressCount,
                        filteredTaskStatusesProcessedCount,
                        filteredTaskStatusesMissedCount
                      )}
                    />
                  </Body1>
                }
                key={`tab-${index}`}
                id={`tab-${index}`}
              />
            ))}
          </Tabs>
        </Stack>

        <div style={{ display: 'flex', marginTop: '10px' }}>
          <TruentityDataGrid
            name={'dg-adtqueuev1'}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            autoHeight
            rows={filteredAccountCareEvents}
            rowCount={rowCountState}
            columns={columns}
            paginationMode="server"
            loading={loadingAccountCareEvents}
            onRowClick={e => sendPatientDataToDrawer({ ...(e.row as AccountCareEvent) })}
            getRowClassName={params => {
              return params?.indexRelativeToCurrentPage % 2 === 0 ? 'dark' : 'light';
            }}
            // Commenting for make changes
            // if you want to remove or change color can done by below section
            sx={{
              boxShadow: 0,
              '.light': {
                backgroundColor: color.white,
                color: color.black50
              },
              '.dark': {
                backgroundColor: color.grey50,
                color: color.black50
              }
            }}
            // commenting this for now
            // since we do not want to redirect to a list of tcm tasks when we click on this
            // onCellClick={(selectedCell) => {
            //     navigate(`/patients/${selectedCell.row.account?.truentityId}/details/tcm-tasks/list`)
            // }}
          />
        </div>
      </Paper>
      <PatientInfoDrawer patientsData={selectedPatient} />
    </Stack>
  );
};

export default AdtQueue;
