import { GridProps, Stack, StackProps } from '@mui/material';
import { ReactNode } from 'react';
import { H5 } from './Typography';

type Props = StackProps &
  GridProps & {
    icon?: ReactNode;
    label: ReactNode;
  };

const GridItemLabel = ({ icon, label, ...props }: Props) => {
  return (
    <Stack direction="row" alignItems="center" spacing={2} {...props}>
      {icon}
      <H5 fontWeight="bold">{label}</H5>
    </Stack>
  );
};

export default GridItemLabel;
