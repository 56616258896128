import CompanyAdd from '@/components/CompanyAdd';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { H3 } from '@/components/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import type { GetAllCompaniesResponse } from '@/graphql/company';
import { GET_ALL_COMPANIES } from '@/graphql/company';
import { useSnackbar } from 'notistack';
import { formatDateAndTime } from '@/util/format';
import type { RelyingPartyType } from '@/types/graphql';

const AllCompanies = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    data: allCompaniesData,
    loading: allCompaniesLoading,
    error: allCompaniesError,
    refetch: refetchAllCompanies
  } = useQuery<GetAllCompaniesResponse>(GET_ALL_COMPANIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  const handleEditRow = (value: RelyingPartyType) => {
    navigate(`/administration/system/companies/${value.id}`);
  };

  const onCompanyAdd = useCallback(async () => {
    await refetchAllCompanies({
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    });
  }, [refetchAllCompanies]);

  const columns: GridColDef<RelyingPartyType>[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      sortable: true
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created On',
      valueGetter: params => formatDateAndTime(params.row.createdAt),
      sortable: true
    },
    {
      field: 'actions',
      flex: 1,
      headerName: '',
      sortable: false,
      align: 'center',
      filterable: false,
      renderCell: params => {
        return (
          <Stack spacing={1} direction="row" justifyContent={'center'}>
            <IconButton size={'small'} onClick={() => handleEditRow(params.row)}>
              <EditIcon />
            </IconButton>
          </Stack>
        );
      }
    }
  ];

  useEffect(() => {
    if (allCompaniesError) {
      enqueueSnackbar('Could not load companies details', {
        variant: 'error'
      });
    }
  }, [allCompaniesError, enqueueSnackbar]);

  return (
    <Paper component={Stack} spacing={2} p={5}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <H3 flex={1}>All Companies</H3>
        <Stack direction={'row'}>
          <CompanyAdd onCompanyAdd={onCompanyAdd} />
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex' }}>
        <TruentityDataGrid
          name={'dg-all-companies'}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          paginationMode={'server'}
          rowCount={allCompaniesData?.relyingPartiesAll?.meta?.totalCount ?? 0}
          autoHeight
          loading={allCompaniesLoading}
          rows={allCompaniesData?.relyingPartiesAll?.relyingParties ?? []}
          columns={columns}
          disableRowSelectionOnClick
        />
      </Box>
    </Paper>
  );
};

export default AllCompanies;
