import { GET_PATIENT_DETAIL } from '@/graphql/account';
import type { PatientsData } from '@/routes/Patients/patients';
import client from '@/util/apollo/client';

export const getAccountDetails = async ({ truentityId }): Promise<PatientsData | null> => {
  try {
    const result = await client.query({
      query: GET_PATIENT_DETAIL,
      variables: { truentityId }
    });
    return result.data.accountGet;
  } catch (error) {
    console.error(error);
  }

  return null;
};
