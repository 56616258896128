import { Body1 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { UserTypes } from '@/types/chat';
import { stringAvatar } from '@/util/avatar';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import type { StackProps } from '@mui/material';
import { Avatar, Stack } from '@mui/material';
import parse from 'html-react-parser';
import type React from 'react';

type ChatBubbleProps = StackProps & {
  user: {
    avatar: string;
    name: string;
  } | null;
  userType: UserTypes;
  timestamp: number;
  message: string;
  color: string;
  textColor: string;
  flexDirection: 'row' | 'row-reverse';
};

const getDirection = (type: UserTypes) => (type === UserTypes.user ? 'flex-end' : 'flex-start');

export const ChatBubble: React.FC<ChatBubbleProps> = ({ user, userType, color: bgColor, textColor, message, flexDirection, ...props }) => {
  return (
    <Stack direction={'row'} height={'auto'} width={'100%'} justifyContent={getDirection(userType)}>
      <Stack direction={flexDirection}>
        {userType === UserTypes.bot ? (
          <Avatar sx={{ bgColor: 'blue' }} aria-label="user-avatar">
            <SmartToyIcon />
          </Avatar>
        ) : (
          <Avatar
            {...stringAvatar(user?.name ?? '')}
            sx={{ bgcolor: userType === UserTypes.user ? bgColor : color.grey400 }}
            aria-label="me-avatar"
          />
        )}
        <Stack
          {...props}
          sx={{
            borderRadius: userType === UserTypes.user ? '10px 0 10px 10px' : '0 10px 10px 10px',
            margin: '5px',
            px: 2,
            py: 1,
            background: bgColor,
            color: textColor
          }}
          alignItems={userType === UserTypes.user ? 'flex-end' : 'flex-start'}
          spacing={1}
        >
          <Body1 color={'contrastText'}>{parse(message)}</Body1>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ChatBubble;
