import { Body1 } from '@/components/Typography';
import { formatDateAndTime } from '@/util/format';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type EncounterInfoProps = {
  title: ReactNode;
  value: ReactNode;
  children?: ReactNode;
  dateField?: boolean;
};
export const EncounterInfo = ({ title, value, children, dateField }: EncounterInfoProps) => {
  return (
    <Stack sx={{ marginBottom: '20px' }}>
      <Body1>
        <strong>{title}</strong>
      </Body1>
      {dateField === true ? <Body1>{value ? formatDateAndTime(value) : '---'}</Body1> : <Body1>{value ? value : '---'}</Body1>}
      {children}
    </Stack>
  );
};
