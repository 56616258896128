import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';

export const defaultAdherenceRadioButtonConfig = {
  'taken-as-directed': false,
  'no-longer-taking': false,
  'taking-differently': false,
  'unable-to-verify': false
};

export const AdherenceStatuses = {
  'taken-as-directed': 'Taking as directed',
  'no-longer-taking': 'No longer taking',
  'taking-differently': 'Taking differently',
  'unable-to-verify': 'Unable to verify'
};

const styles = theme => ({
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  }
});

export type AdherenceSectionProps = {
  radioButtonOptions: any;
  onUpdate?: any;
  editable: boolean;
};

const AdherenceSection = ({ radioButtonOptions, editable, onUpdate }: AdherenceSectionProps) => {
  const getEnabledAdherence = data => {
    const val = Object.keys(data)?.find(item => data[item]);
    return val || '';
  };

  const [value, setValue] = useState<string>('');

  const handleRadioButtonState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    const currentValue = getEnabledAdherence(radioButtonOptions);
    setValue(currentValue);
  }, [radioButtonOptions]);

  useEffect(() => {
    if (onUpdate) {
      const keys = Object.keys(AdherenceStatuses);
      keys.forEach(k => {
        const data = {
          type: k,
          payload: k === value
        };
        onUpdate(data);
      });
    }
  }, [value, radioButtonOptions]);

  return (
    <FormControl fullWidth margin="dense">
      <RadioGroup
        aria-labelledby="adherence-status-group-label"
        name="adherence-status-group"
        sx={styles}
        value={value}
        onChange={handleRadioButtonState}
      >
        <FormControlLabel disabled={!editable} value="taken-as-directed" control={<Radio />} label="Taking as directed" />
        <FormControlLabel disabled={!editable} value="no-longer-taking" control={<Radio />} label="No longer taking" />
        <FormControlLabel disabled={!editable} value="taking-differently" control={<Radio />} label="Taking differently" />
        <FormControlLabel disabled={!editable} value="unable-to-verify" control={<Radio />} label="Unable to verify" />
      </RadioGroup>
    </FormControl>
  );
};

export default AdherenceSection;
