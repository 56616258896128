import FemaleSharpIcon from '@mui/icons-material/FemaleSharp';
import MaleSharpIcon from '@mui/icons-material/MaleSharp';

type Props = {
  gender: String;
};

const GenderIcon = ({ gender }: Props) => {
  return gender && (gender?.toLowerCase() === 'male' ? <MaleSharpIcon color={'primary'} /> : <FemaleSharpIcon color={'secondary'} />);
};

export default GenderIcon;
