import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { DialogContent, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import pkg from '../../../package.json';
import { H6 } from '../Typography';
import { GET_VERSION } from '../VersionFooter';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

const VersionDialog = ({ title, hideDialog, ...props }: Props) => {
  const [backendVersion, setBackendVersion] = useState<string>('');

  const { loading: versionLoading, data: versionData, called: versionCalled } = useQuery(GET_VERSION);

  const currentUser = useReactiveVar(currentLoggedUserVar);

  useEffect(() => {
    if (versionCalled && !versionLoading && versionData) {
      setBackendVersion(versionData.version.version);
    }
  }, [versionLoading, versionData, versionCalled]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <Stack>
          <H6>Portal Version: {pkg.version} </H6>
          {currentUser && <H6>Backend Version: {backendVersion}</H6>}
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default VersionDialog;
