import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { AttachToMedDialog } from '@/components/Dialogs';
import Pagination from '@/components/Pagination';
import { Body1, H4 } from '@/components/Typography';
import ZoomableImage from '@/components/ZoomableImage';
import PatientDetailContext from '@/context/patientDetailContext';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import useToken from '@/hooks/useToken';
import styled from '@/styles';
import { Role } from '@/types/admin';
import { gql, useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

const PaginationContainer = styled('div', {
  width: '100%'
});

const GET_QUICKUPLOADS = gql`
  query GetQuickUploadsByAccountQuery($truentityId: String!, $sessionId: String!, $pageNum: Int!, $pageSize: Int!) {
    quickUploadsByAccount(truentityId: $truentityId, sessionId: $sessionId, pageNum: $pageNum, pageSize: $pageSize) {
      quickUploadItems {
        id
        name
        url
        key
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const attachment_options = {
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false
  }
};

const TitleContainer = styled('div', {
  display: 'flex',
  justifyContent: 'start'
});

const HeaderContent = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1,
  alignItems: 'end'
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end'
});

const renderItems = (item: any, onAttachImage: Function) => {
  return (
    <TableRow key={item.id}>
      <TableCell>
        <Body1>{item.name}</Body1>
      </TableCell>
      <TableCell>
        <ZoomableImage src={`${item.url}`} />
        <Button a11yLabel="Attach" appearance="outline" onClick={() => onAttachImage(item)} />
      </TableCell>
    </TableRow>
  );
};

const QuickUploadItems = () => {
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { patientId, medications } = useContext(PatientDetailContext);
  const { sessionId, id } = useParams();
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, loading, called } = useQuery(GET_QUICKUPLOADS, {
    variables: {
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      sessionId: sessionId,
      truentityId: patientId
    }
  });
  const isDataLoaded = () => called && !loading && data && data.quickUploadsByAccount.quickUploadItems;

  const isThereData = () => isDataLoaded() && data.quickUploadsByAccount.meta.totalCount > 0;

  const thereIsNoData = () => isDataLoaded() && data.quickUploadsByAccount.meta.totalCount === 0;

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  const onAttachImage = useCallback(item => {
    showAttachToMedModal(item.key, item.url);
  }, []);

  const showAttachToMedModal = (key, url) => {
    const modalRef = showModal(AttachToMedDialog, {
      title: 'Attach to Medication',
      successMessage: 'Image attached to the Medication',
      truentityId: id,
      keyToAttach: key,
      urlToAttach: url,
      accountMedications: medications,
      hideDialog: () => modalRef.hide()
    });
  };

  return (
    <Stack direction="column" spacing={6}>
      <Stack spacing={2}>
        <TitleContainer>
          <HeaderContent>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <H4 sx={{ maxWidth: '50vw' }}>Quick Upload Items</H4>
            </Stack>
            <ButtonsContainer>
              <Button
                sx={{ marginLeft: '10px' }}
                disableElevation
                variant={'contained'}
                a11yLabel="Back"
                label="Back"
                onClick={() => navigate(-1)}
                disabled={isProviderAdmin}
              />
            </ButtonsContainer>
          </HeaderContent>
        </TitleContainer>
      </Stack>

      <Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>Name</TableHeading>
              <TableHeading>Item</TableHeading>
            </TableRow>
          </TableHead>
          <tbody>{isThereData() && data.quickUploadsByAccount.quickUploadItems.map(item => renderItems(item, onAttachImage))}</tbody>
        </Table>

        {thereIsNoData() && <Alert description="There are no uploaded items for this session yet" status="warning" title="No Items" />}

        {isThereData() && (
          <PaginationContainer>
            <Pagination
              currentPage={currentPage}
              totalRecords={data.quickUploadsByAccount.meta.totalCount}
              pageLimit={data.quickUploadsByAccount.meta.totalPages}
              pageNeighbours={2}
              onPageChanged={updateData}
            />
          </PaginationContainer>
        )}
      </Stack>
    </Stack>
  );
};

export default QuickUploadItems;
