import Button from '@/components/Button';
import type { TruentityTextFieldProps } from '@/components/TruentityTextField';
import TruentityTextField from '@/components/TruentityTextField';
import { Stack } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import type { FocusEvent } from 'react';
import { useState } from 'react';

type TextFieldMenuItem = {
  label: string;
  callback: (value: any) => void;
};
type Props = TruentityTextFieldProps & {
  options: TextFieldMenuItem[];
};
const TruentityTextFieldWithMenu = ({ options, ...props }: Props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState('');

  const handleClick = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEnter = () => {
    handleClose();
    if ('activeElement' in document) {
      (document.activeElement as any).blur();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Stack>
        <TruentityTextField
          {...props}
          autoComplete={'off'}
          placeholder={'Lookup Patient'}
          id="filled-adornment-weight"
          value={value}
          aria-describedby="filled-weight-helper-text"
          inputProps={{
            'aria-label': 'weight'
          }}
          aria-owns="simple-popover"
          aria-haspopup="true"
          onFocus={handleClick}
          onKeyUp={e => e.key === 'Enter' && onEnter()}
          onChange={e => setValue(e.target.value)}
        />

        <Popper id="simple-popover" open={open} anchorEl={anchorEl} placement={'bottom-start'} disablePortal={true} sx={{ width: '100%' }}>
          <Stack
            direction={'row'}
            spacing={1}
            mt={-1}
            p={2}
            sx={{
              backgroundColor: '#f8f8f8',
              borderColor: theme.palette.primary.dark,
              border: '2px solid',
              borderTop: 'none',
              borderBottomRightRadius: '8px',
              borderBottomLeftRadius: '8px'
            }}
          >
            {options?.map((item, index) => (
              <Button
                key={index}
                variant={'outlined'}
                disabled={value.length === 0}
                label={item.label}
                onClick={() => {
                  item.callback(value);
                  setValue('');
                  handleClose();
                }}
                sx={{ borderRadius: '18px' }}
              />
            ))}
          </Stack>
        </Popper>
      </Stack>
    </ClickAwayListener>
  );
};

export default TruentityTextFieldWithMenu;
