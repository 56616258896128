import styled from '@/styles';

export const PDFViewer = styled('iframe', {
  width: '100%',
  height: '100%',
  minWidth: '100px',
  minHeight: '600px'
});

export default PDFViewer;
