import { H1, H2 } from '@/components/Typography';
import { Divider, Drawer, Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { GridItem } from '@/components/GridItem';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { AccountCareEvent } from '@/types/dischargePatient';
import { formatDate } from '@/util/format';
import BackupIcon from '@mui/icons-material/Backup';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
import FlagIcon from '@mui/icons-material/Flag';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import RoomIcon from '@mui/icons-material/Room';
import TagIcon from '@mui/icons-material/Tag';

type Props = {
  patientsData: AccountCareEvent;
};

const PatientInfoDrawer = ({ patientsData }: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [patientInfo, setPatientInfo] = useState<AccountCareEvent>({} as AccountCareEvent);
  const [fullName, setFullName] = useState<string>();
  const [assignPersonName, setAssignPersonName] = useState<string>();

  useEffect(() => {
    if (patientsData) {
      setPatientInfo(patientsData);
    }
  }, [patientsData]);

  useEffect(() => {
    if (patientInfo?.account) {
      setFullName(
        patientInfo?.account?.user?.lastName && patientInfo?.account?.user?.firstName
          ? `${patientInfo?.account?.user?.lastName}, ${patientInfo?.account?.user?.firstName}`
          : '---'
      );
      setAssignPersonName(
        patientInfo?.relyingPartyAdmin?.user?.lastName && patientInfo?.relyingPartyAdmin?.user?.firstName
          ? `${patientInfo?.relyingPartyAdmin?.user?.firstName} ${patientInfo?.relyingPartyAdmin?.user?.lastName}`
          : '---'
      );
      setOpenDrawer(true);
    }
  }, [patientInfo]);

  return (
    <Drawer
      container={this}
      anchor={'right'}
      open={openDrawer}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      color="inherit"
      onClose={() => setOpenDrawer(false)}
    >
      <Stack spacing={6} p={4} sx={{ maxWidth: '600px' }}>
        <Stack spacing={2}>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            <H1>{fullName} </H1>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <H2>Profile</H2>
          <Divider />
          <Grid container rowSpacing={2}>
            <GridItem xs={12} icon={<TagIcon />} label="Truentity Id" value={patientInfo?.account?.truentityId?.toUpperCase()} />
            {/* @2024/04/05 Patient emails hidden */}
            {/* <GridItem xs={12} icon={<MailIcon />} label="Email" value={patientInfo?.account?.user?.email} /> */}
            <GridItem
              xs={12}
              icon={<LocalPhoneIcon />}
              label="Phone"
              value={<TruentityPhoneNumber value={patientInfo?.account?.phone} />}
            />
            <GridItem xs={12} icon={<RoomIcon />} label="Location" value={patientInfo?.account?.address?.toUpperCase()} />
          </Grid>
        </Stack>

        <Stack spacing={1}>
          <H2>Discharge Summary</H2>
          <Divider />
          <Grid container rowSpacing={2}>
            <GridItem xs={12} icon={<LocalHospitalIcon />} label="Discharge Date" value={formatDate(patientInfo?.dischargeDate)} />
            <GridItem xs={12} icon={<PersonRemoveIcon />} label="Discharging Physician" value={patientInfo?.dischargingPhysician} />
            <GridItem xs={12} icon={<BackupIcon />} label="Discharged Disposition" value={patientInfo?.dischargedDisposition} />
            <GridItem xs={12} icon={<RoomIcon />} label="Admitted From" value={patientInfo?.admittedFrom} />
            <GridItem xs={12} icon={<CalendarMonthIcon />} label="Admission Date" value={formatDate(patientInfo?.admissionDate)} />
            <GridItem xs={12} icon={<DescriptionIcon />} label="Admission Reason" value={patientInfo?.admissionReason} />
            <GridItem xs={12} icon={<CalendarMonthIcon />} label="Primary Diagnosis Code" value={patientInfo?.primaryDiagnosisCode} />
            <GridItem
              xs={12}
              icon={<DescriptionIcon />}
              label="Primary Diagnosis Description"
              value={patientInfo?.primaryDiagnosisDescription}
            />
            <GridItem
              xs={12}
              icon={<FlagIcon />}
              label="Readmission Risk"
              value={patientInfo?.readmissionRiskFlag ? <FlagIcon style={{ fill: 'red' }} /> : <FlagIcon style={{ fill: 'green' }} />}
            />
          </Grid>
        </Stack>

        <Stack spacing={2}>
          <Divider />
          <GridItem xs={12} icon={<PersonIcon />} label="Assigned To" value={assignPersonName} />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default PatientInfoDrawer;
