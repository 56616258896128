import DropdownMenu from '@/components/DropdownMenu';
import type { MenuItemData } from 'mui-nested-menu';
import { useEffect, useState } from 'react';

export const ASSIGNMENT_TITLES = {
  assign: 'Assign',
  unassign: 'Unassign'
};

const PatientActions = ({ handleAssignClicked, selectedPatients }) => {
  const [menuData, setMenuData] = useState<MenuItemData>({ label: 'Actions', items: [] });

  const assignmentStatusActions: MenuItemData[] = Object.values(ASSIGNMENT_TITLES).map(title => {
    const action: MenuItemData = {
      label: `${title}`,
      callback: () => handleAssignClicked(title)
    };

    return action;
  });

  useEffect(() => {
    const menuItems = [
      {
        label: 'Pharmacists Assignments',
        items: assignmentStatusActions
      }
    ];

    setMenuData({
      label: 'Actions',
      items: menuItems
    });
  }, [selectedPatients]);

  return (
    <DropdownMenu
      menuItemsData={menuData}
      MenuProps={{
        elevation: 3,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' }
      }}
      ButtonProps={{ variant: 'outlined', color: 'primary', sx: { minWidth: '200px' } }}
    />
  );
};

export default PatientActions;
