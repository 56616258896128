import { Box } from '@mui/material';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect } from 'react';
import type { EditorItemGroupNames } from './EditorToolbar';
import EditorToolbar from './EditorToolbar';

type Props = {
  label: string;
  description: string;
  disabledGroups?: Array<EditorItemGroupNames>;
  onChange: (richText: string) => void;
};

const TruentityRichTextEditor = ({ label, description, disabledGroups, onChange }: Props) => {
  const editor = useEditor({
    extensions: [StarterKit.configure(), Underline, Highlight],
    content: description,
    editorProps: {
      attributes: {
        label: label
      }
    },
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    }
  });

  useEffect(() => {
    if (editor) {
      const editorContent = editor.view.dom;
      if (editorContent) {
        editorContent.style.minHeight = '200px';
        editorContent.style.border = '1px solid #c4c4c4';
        editorContent.style.padding = '18.5px 14px';
        editorContent.style.borderRadius = '4px';
        editorContent.style.fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
        editorContent.style.fontSize = '16px';
        editorContent.style.lineHeight = '1.43';
        editorContent.style.backgroundColor = '#fff';
        editorContent.style.transition = 'border-color 0.2s';
        editorContent.style.outline = 'none';
      }
    }
  }, [editor]);

  return (
    <Box width="100%">
      <EditorToolbar editor={editor} disabledGroups={disabledGroups} />
      <EditorContent editor={editor} placeholder={label} />
    </Box>
  );
};

export default TruentityRichTextEditor;
