import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import parse from 'html-react-parser';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  content: string;
  onClose: () => void;
};

const PreviewDialog: React.FC<Props> = ({ title, content, onClose, ...props }) => {
  return (
    <BaseDialog {...props} title={title} onClose={onClose}>
      <DialogContent>
        <DialogContentText>{parse(content)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default PreviewDialog;
