import { Body1 } from '@/components/Typography';
import type { AccountCareEventsByFilterResponse } from '@/graphql/transitionalCareManagement';
import { GET_ACCOUNT_CARE_EVENTS_BY_FILTER } from '@/graphql/transitionalCareManagement';

import { useLazyQuery } from '@apollo/client';
import { Chip, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DueDateFilters, ReadmissionOptions } from './DueDateFilters';

import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import Icon from '@/elements/Icon';
import { color } from '@/styles/assets/colors';
import type { AccountCareEvent } from '@/types/dischargePatient';
import type { MuiColor } from '@/types/mui';
import { getAccountUserFullName } from '@/util/account';
import { isSameOrBefore, toDateOrNull } from '@/util/date';
import { formatDateIgnoreTZ } from '@/util/format';
import { invariantCultureIgnoreCase } from '@/util/string';
import { getReadmissionRiskValue } from '@/util/tcm';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import AttemptedIcon from '@mui/icons-material/Done';
import FlagIcon from '@mui/icons-material/Flag';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Badge from '@mui/material/Badge';
import moment from 'moment';
import PatientInfoDrawer from './PatientInfoDrawer';

//TODO: ADT Events which are incorrect (unkniown discharge date need to be ignore after selecting an adt event and loading the user's adt view)
export enum TaskStatuses {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Processed = 'Processed',
  Missed = 'Missed'
}

//TODO:  This needs to be enforced
export enum AccountCareEventStatuses {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Processed = 'Processed',
  Missed = 'Missed'
}

export enum EventTypeFilter {
  All = 'all',
  Admit = 'admit',
  Discharge = 'discharge',
  Transfer = 'transfer'
}

type TabData = { a11yLabel: string; label: string; value: AccountCareEventStatus; icon: string | React.ReactElement };

const EventTypeColorMap = new Map<string, MuiColor>([
  [EventTypeFilter.All, 'default'],
  [EventTypeFilter.Admit, 'error'],
  [EventTypeFilter.Discharge, 'success'],
  [EventTypeFilter.Transfer, 'warning']
]);

//TODO: this needs to be enforced and clarified
type AccountCareEventStatus =
  | AccountCareEventStatuses.Pending
  | AccountCareEventStatuses.InProgress
  | AccountCareEventStatuses.Processed
  | AccountCareEventStatuses.Missed;

const AdtQueue = () => {
  const { setContent } = useContext(SideMenuContext);

  const [getEventsForTabCounts, { data: totalAccountCareFilterData }] =
    useLazyQuery<AccountCareEventsByFilterResponse>(GET_ACCOUNT_CARE_EVENTS_BY_FILTER);

  const [getEvents, { loading: loadingAccountCareEvents, data: accountCareFilterData }] =
    useLazyQuery<AccountCareEventsByFilterResponse>(GET_ACCOUNT_CARE_EVENTS_BY_FILTER);

  const [selectedEvent, setSelectedEvent] = useState<EventTypeFilter>(EventTypeFilter.All);
  const [selectedPatient, setSelectedPatient] = useState<AccountCareEvent>({} as AccountCareEvent);

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);

  const [tab, setTab] = useState<number>(0);
  const tabs: TabData[] = useMemo(
    () => [
      {
        a11yLabel: 'Pending',
        label: 'Pending',
        value: AccountCareEventStatuses.Pending,
        icon: <PendingIcon />
      },
      {
        a11yLabel: 'InProgress',
        label: 'In Progress',
        value: AccountCareEventStatuses.InProgress,
        icon: <AttemptedIcon />
      },
      {
        a11yLabel: 'Processed',
        label: 'Processed',
        value: AccountCareEventStatuses.Processed,
        icon: <TaskAltIcon />
      },
      {
        a11yLabel: 'Missed',
        label: 'Missed',
        value: AccountCareEventStatuses.Missed,
        icon: <CallMissedIcon />
      }
    ],
    []
  );

  const [accountCareEvents, setAccountCareEvents] = useState<AccountCareEvent[]>([]);
  const [filteredAccountCareEvents, setFilteredAccountCareEvents] = useState<AccountCareEvent[]>([]);
  const [filteredTaskStatusesPendingCount, setFilteredTaskStatusesPendingCount] = useState(0);
  const [filteredTaskStatusesInProgressCount, setFilteredTaskStatusesInProgressCount] = useState(0);
  const [filteredTaskStatusesProcessedCount, setFilteredTaskStatusesProcessedCount] = useState(0);
  const [filteredTaskStatusesMissedCount, setFilteredTaskStatusesMissedCount] = useState(0);
  const [adtQueueStatus, setAdtQueueStatus] = useState<AccountCareEventStatus>(AccountCareEventStatuses.Pending);
  const [currentPatientDischargeCount, setCurrentPatientDischargeCount] = useState(0);
  const [currentPatientAdmitCount, setCurrentPatientAdmitCount] = useState(0);

  // adding 7 days to the current date
  const initialCommDueAfterAddition = new Date();
  const initialFilterDueAfterAddition = new Date();
  initialCommDueAfterAddition.setDate(initialCommDueAfterAddition.getDate() + 7);

  const [filterDueDate, setFilterDueDate] = useState<Date | undefined>(initialFilterDueAfterAddition);
  const [initialCommDue, setInitialCommDue] = useState<Date | undefined>(initialCommDueAfterAddition);

  const [readmissionStatusOption, setReadmissionStatusOption] = useState(ReadmissionOptions.Any);

  //TODO:  This doesnt make much sense with pagination, we should get the API to return this counts
  useEffect(() => {
    const allEvents = totalAccountCareFilterData || [];

    setFilteredTaskStatusesPendingCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesPendingCount);
    setFilteredTaskStatusesInProgressCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesInProgressCount);
    setFilteredTaskStatusesProcessedCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesProcessedCount);
    setFilteredTaskStatusesMissedCount(allEvents?.accountCareEventsByFilter?.eventStatusCounts?.eventStatusesMissedCount);
  }, [totalAccountCareFilterData]);

  useEffect(() => {
    // since we are adding 30 days to the current date which is  being used
    // for the due date filters, we can take this off
    // setFilterDueDate(new Date())
    setContent(<MainSideMenu />);
  }, [setContent]);

  useEffect(() => {
    setCurrentPatientAdmitCount(accountCareFilterData?.accountCareEventsByFilter?.admitCount || 0);
    setCurrentPatientDischargeCount(accountCareFilterData?.accountCareEventsByFilter?.dischargeCount || 0);
  }, [accountCareFilterData?.accountCareEventsByFilter?.admitCount, accountCareFilterData?.accountCareEventsByFilter?.dischargeCount]);

  // this is used to populate
  // the tabs chip counts (Pending, InProgress, Processed, Missed)
  useEffect(() => {
    getEventsForTabCounts({
      variables: {
        pageSize: 1,
        pageNum: 10000,
        filterOptions: {
          status: null,
          eventType: selectedEvent // by default, the value is 'all'. but based on the change it will reftech the tab counts
        }
      }
    }).catch(err => {
      console.error(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  useEffect(() => {
    getEvents({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        // relyingPartyAdminId: currentUser?.id,
        filterOptions: {
          status: adtQueueStatus,
          eventType: selectedEvent
        }
      }
    }).catch(err => {
      console.error(err);
    });
  }, [adtQueueStatus, currentPage, getEvents, selectedEvent]);

  useEffect(() => {
    if (accountCareFilterData) {
      setAccountCareEvents(accountCareFilterData?.accountCareEventsByFilter?.events || []);
      setRowCount(accountCareFilterData?.accountCareEventsByFilter?.meta.totalCount || 0);
    }
  }, [accountCareFilterData]);

  const sendPatientDataToDrawer = (patientData: AccountCareEvent) => {
    setSelectedPatient(patientData);
  };

  type OptionalAdtQueueFilterParams = {
    statusFilter?: AccountCareEventStatus;
    eventTypeFilter?: EventTypeFilter;
    dueDateFilter?: Date;
    initialCommDateFilter?: Date;
    readmissionStatusOptionFilter: string;
  };

  useEffect(() => {
    if (accountCareEvents && accountCareEvents.length > 0) {
      //TODO: We shoud not be mixing server and local filtering, this is going to be confusing to the user and provide inconsistent / unreliable results
      const applyFilters = (
        accountCareEventsByFilter: AccountCareEvent[],
        { statusFilter, eventTypeFilter, dueDateFilter, initialCommDateFilter }: OptionalAdtQueueFilterParams
      ) => {
        let filtered = accountCareEventsByFilter;

        if (statusFilter) {
          filtered = filtered.filter(v => invariantCultureIgnoreCase(v.eventStatus, statusFilter));
        }

        if (eventTypeFilter) {
          filtered = filtered.filter(v => invariantCultureIgnoreCase(v.eventType, eventTypeFilter));
        }

        // i have added this logic since we do not need this filter to be
        // applied when we are in the "PENDING" tab (need to verify it with Rajeev)
        if (statusFilter === AccountCareEventStatuses.InProgress && dueDateFilter) {
          filtered = filtered.filter(v =>
            isSameOrBefore(v.tcmTasks[0]?.finalDueDate?.toString(), formatDateIgnoreTZ(dueDateFilter, 'YYYY-MM-DD'))
          );
        }

        if (statusFilter === AccountCareEventStatuses.Pending && initialCommDateFilter) {
          filtered = filtered.filter(v =>
            isSameOrBefore(v?.tcmTasks[0]?.initialCommsDueDate.toString(), formatDateIgnoreTZ(initialCommDateFilter, 'YYYY-MM-DD'))
          );
        }

        if (
          readmissionStatusOption &&
          adtQueueStatus !== AccountCareEventStatuses.Processed &&
          adtQueueStatus !== AccountCareEventStatuses.Missed
        ) {
          if (readmissionStatusOption !== ReadmissionOptions.Any) {
            filtered = filtered.filter(v => getReadmissionRiskValue(v?.readmissionRiskFlag) === readmissionStatusOption);
          }
        }
        setFilteredAccountCareEvents(filtered);
      };

      if (selectedEvent === EventTypeFilter.All) {
        applyFilters(accountCareEvents, {
          statusFilter: adtQueueStatus,
          dueDateFilter: filterDueDate,
          initialCommDateFilter: initialCommDue,
          readmissionStatusOptionFilter: readmissionStatusOption
        });
      } else {
        applyFilters(accountCareEvents, {
          statusFilter: adtQueueStatus,
          eventTypeFilter: selectedEvent,
          dueDateFilter: filterDueDate,
          initialCommDateFilter: initialCommDue,
          readmissionStatusOptionFilter: readmissionStatusOption
        });
      }
    }
  }, [adtQueueStatus, accountCareEvents, filterDueDate, initialCommDue, readmissionStatusOption, selectedEvent]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    setCurrentPage(0);
  }, [rowCountState]);

  const columns: GridColDef<AccountCareEvent>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        minWidth: 160,
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: cellValues => getAccountUserFullName(cellValues.row.account.user),
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="left" justifyContent={'left'}>
              {getAccountUserFullName(cellValues.row.account.user)}
            </Stack>
          );
        }
      },
      {
        field: 'eventType',
        headerName: 'Event Type',
        sortable: true,
        flex: 1,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        valueGetter: cellValues => cellValues.row.eventType?.toLowerCase() || 'unavailable',
        renderCell: cellValues => {
          return (
            <Stack
              spacing={3}
              direction="row"
              sx={{ width: '100%', userSelect: 'none', textTransform: 'capitalize' }}
              alignItems="center"
              justifyContent={'center'}
            >
              <Chip label={cellValues.value as string} color={EventTypeColorMap.get(cellValues.value as string)} variant="filled" />
            </Stack>
          );
        }
      },
      {
        field: 'facilityType',
        headerName: 'Facility Type',
        sortable: true,
        flex: 1,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params?.row.eventFacilityType,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'center'}>
              <Chip label={cellValues.row.eventFacilityType || 'Unavailable'} color="primary" variant="outlined" />
            </Stack>
          );
        }
      },
      {
        field: 'readmissionRisk',
        headerName: 'Readmission Risk',
        sortable: true,
        valueGetter: params => params?.row.readmissionRiskFlag,
        renderCell: params => {
          return params?.row.readmissionRiskFlag ? <FlagIcon style={{ fill: 'red' }} /> : <FlagIcon style={{ fill: 'green' }} />;
        },
        headerAlign: 'center',
        align: 'center',
        minWidth: 130
      },
      {
        field: 'dischargeDate',
        headerName: 'Discharge Date',
        sortable: true,
        minWidth: 160,
        type: 'date',
        valueGetter: params => toDateOrNull(params.value),
        renderCell: params => {
          return moment(params.row.dischargeDate).format('MMM DD, YYYY');
        },
        headerAlign: 'center',
        align: 'center'
      },
      {
        field: 'dischargeDisposition',
        headerName: 'Discharged Disposition',
        sortable: true,
        valueGetter: params => params?.row.dischargedDisposition,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'center'}>
              <Chip label={cellValues.row.dischargedDisposition || 'Unavailable'} color="primary" variant="outlined" />
            </Stack>
          );
        },
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 160
      },
      {
        field: 'admissionDate',
        headerName: 'Admission Date',
        type: 'date',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        minWidth: 150,
        resizable: true,
        valueGetter: params => toDateOrNull(params.value),
        renderCell: params => {
          return moment(params.row.admissionDate).format('MMM DD, YYYY');
        }
      },
      {
        field: 'admittedFrom',
        headerName: 'Admitted From',
        minWidth: 100,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.admittedFrom
      },
      {
        field: 'finalDueDate',
        headerName: 'Final Due Date',
        type: 'date',
        minWidth: 150,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => toDateOrNull(params.row.tcmTasks[0]?.finalDueDate)
      },
      {
        field: 'actions',
        headerName: 'Details',
        sortable: false,
        flex: 1,
        align: 'center',
        minWidth: 100,
        headerAlign: 'center',
        filterable: false,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <Link to={`/patients/${cellValues.row.account.truentityId}/details/adt-events/view/${cellValues.row.id}`}>
                <Icon icon={faUserCog} fixedWidth size="lg" />
              </Link>
            </Stack>
          );
        }
      }
    ],
    []
  );

  const handleTabChange = useCallback(
    (event: SyntheticEvent, selectedTabIndex: number) => {
      setTab(selectedTabIndex);

      const selectedStatus: AccountCareEventStatus = tabs[selectedTabIndex].value;
      setAdtQueueStatus(selectedStatus);
    },
    [tabs]
  );

  const getBadgeCount = (tabLabel: string) => {
    switch (tabLabel) {
      case 'Pending':
        return filteredTaskStatusesPendingCount ? filteredTaskStatusesPendingCount : '0';
      case 'In Progress':
        return filteredTaskStatusesInProgressCount ? filteredTaskStatusesInProgressCount : '0';
      case 'Processed':
        return filteredTaskStatusesProcessedCount ? filteredTaskStatusesProcessedCount : '0';
      case 'Missed':
        return filteredTaskStatusesMissedCount ? filteredTaskStatusesMissedCount : '0';
    }
    return 0;
  };

  return (
    <Stack spacing={2}>
      <Stack>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={4}>
            <FormControl variant="outlined" margin="dense" size="medium" sx={{ width: '100%', marginRight: 1 }}>
              <InputLabel id={'priority-input'}>Event</InputLabel>

              <Select
                labelId={'event'}
                label={'Event'}
                placeholder={'select a event type'}
                value={selectedEvent}
                defaultValue={selectedEvent}
                sx={{ textTransform: 'capitalize' }}
                onChange={event => {
                  setSelectedEvent(event.target.value as EventTypeFilter);
                }}
              >
                {Object.values(EventTypeFilter).map(value => (
                  <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid
            spacing={3}
            sx={{
              flexDirection: 'row-reverse',
              display: 'inline-flex',
              marginTop: '20px',
              marginLeft: '7%'
            }}
            item
            xs={2}
          >
            <Badge sx={{ marginLeft: '20px' }} badgeContent={currentPatientDischargeCount} color="primary">
              <ArrowCircleUpIcon color="action" />
            </Badge>
            <Badge badgeContent={currentPatientAdmitCount} color="primary">
              <ArrowCircleDownIcon color="action" />
            </Badge>
          </Grid>
        </Grid>
      </Stack>

      <Paper component={Stack} direction="column">
        <Stack
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            borderBottom: 1,
            borderColor: 'divider',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab
                iconPosition="start"
                icon={tab.icon}
                label={
                  <Body1 textTransform="capitalize">
                    {tab.label}
                    <Chip sx={{ marginLeft: 1 }} label={getBadgeCount(tab.label)} />
                  </Body1>
                }
                key={`tab-${index}`}
                id={`tab-${index}`}
              />
            ))}
          </Tabs>
        </Stack>

        <DueDateFilters
          selectedStatus={adtQueueStatus}
          filterDate={filterDueDate}
          setFilterDate={setFilterDueDate}
          filterInitialCommDue={initialCommDue}
          setFilterInitialCommDue={setInitialCommDue}
          readmissionStatusOption={readmissionStatusOption}
          setReadmissionStatusOption={setReadmissionStatusOption}
        />

        <div style={{ display: 'flex', marginTop: '10px' }}>
          <TruentityDataGrid
            name={'dg-adtqueue'}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            autoHeight
            rows={filteredAccountCareEvents}
            rowCount={rowCountState}
            columns={columns}
            paginationMode="server"
            loading={loadingAccountCareEvents}
            onRowClick={e => sendPatientDataToDrawer({ ...(e.row as AccountCareEvent) })}
            getRowClassName={params => {
              return params?.indexRelativeToCurrentPage % 2 === 0 ? 'dark' : 'light';
            }}
            // Commenting for make changes
            // if you want to remove or change color can done by below section
            sx={{
              boxShadow: 0,
              '.light': {
                backgroundColor: color.white,
                color: color.black50
              },
              '.dark': {
                backgroundColor: color.grey50,
                color: color.black50
              }
            }}
            // commenting this for now
            // since we do not want to redirect to a list of tcm tasks when we click on this
            // onCellClick={(selectedCell) => {
            //     navigate(`/patients/${selectedCell.row.account?.truentityId}/details/tcm-tasks/list`)
            // }}
          />
        </div>
      </Paper>
      <PatientInfoDrawer patientsData={selectedPatient} />
    </Stack>
  );
};

export default AdtQueue;
