export const readFileAsDataUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (!reader.result) {
        return reject(new Error('reader.result undefined'));
      }

      return resolve(reader.result.toString());
    };

    reader.onerror = error => reject(error);
  });

export type Base64Info = {
  header: string;
  content: string;
  type: string;
  contentType: string;
};

export const parseBase64String = (base64Str: string): Base64Info => {
  const content = base64Str.split(',')[1]; ///9j/4AAQSkZJ
  const header = base64Str.split(',')[0]; //data:image/jpeg;base64
  const info = header?.split(';')[0]; //data:image/jpeg
  const contentType = info?.split(':')[1]; //image/jpeg
  const type = contentType?.split('/')[1]; //jpeg

  return {
    header,
    content,
    contentType,
    type
  };
};

export const bytesToKilobytes = (bytes: number) => {
  return bytes / 1024 + 'KB';
};

export const bytesToMegabytes = (bytes: number) => {
  return bytes / 1024 / 1024 + 'MB';
};

export const goToPdfUrl = (url: string, id: string, message: string) => {
  fetch(`${url}`)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${message}${id}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    })
    .catch(error => {
      console.log(error);
    });
};
