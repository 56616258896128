import ls, { QUICK_UPLOAD_AUTH_KEY } from '@/util/localstorage';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import InvalidSession from '@/components/InvalidSession';
import TruentityLogo from '@/components/TruentityLogo';
import TruentityTextField from '@/components/TruentityTextField';
import { color } from '@/styles/assets/colors';
import { Box, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { CHECK_SESSION, QUICK_UPLOAD_TENANT_ADMIN } from './mutations';

type FormValues = { lastName: string; dateOfBirth: string };

const defaultValues: FormValues = {
  lastName: '',
  dateOfBirth: ''
};

const QuickUploadStart = () => {
  const navigate = useNavigate();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const { sessionId } = useParams();
  const [authCode, setAuthCode] = useState();
  const [startQuickUploadSession] = useMutation(QUICK_UPLOAD_TENANT_ADMIN);
  const [checkQuickUploadSession] = useMutation(CHECK_SESSION);
  const [validSession, setValidSession] = useState(false);
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const { enqueueSnackbar } = useSnackbar();

  const validateSession = async (sessionId: string) => {
    try {
      const result = await checkQuickUploadSession({
        variables: {
          sessionId
        }
      });

      const { status } = result.data!.checkQuickUploadSession;

      setValidSession(status === 'Success');
    } catch (err) {
      setValidSession(false);
    }
  };

  useEffect(() => {
    if (sessionId) {
      validateSession(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (authCode) {
      ls.set(QUICK_UPLOAD_AUTH_KEY, authCode);
      navigate('./medications', { replace: true });
    }
  }, [authCode]);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const lastName = values.lastName;
      const dateOfBirth = new Date(values.dateOfBirth.replace(/-/g, '/')).toISOString()?.split('T')[0];

      const result = await startQuickUploadSession({
        variables: {
          sessionId,
          lastName,
          dateOfBirth
        }
      });

      const { authCode, status } = result.data!.startQuickUploadSession;

      switch (status.toLowerCase()) {
        case 'success':
          setAuthCode(authCode);
          break;

        case 'invalid account':
          enqueueSnackbar('Please double check your credentials', { variant: 'error' });
          break;

        default:
          enqueueSnackbar('An unknown error has occurred. Please contact support', { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Something has gone wrong.  Please make sure you enter your details correctly', { variant: 'error' });
    }
  };

  return (
    <>
      {validSession && (
        <Stack
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ width: '100%', height: '100%', backgroundColor: color.truentityCyan[500] }}
        >
          <Paper component={Stack} spacing={2} p={2} sx={{ width: '90%', maxHeight: '90%', maxWidth: '500px', overflow: 'auto' }}>
            <Box
              sx={{
                paddingBottom: '24px',
                borderBottom: '2px solid $truentityLigthGray',
                marginBottom: '19px'
              }}
            >
              <TruentityLogo />
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField autoFocus required onChange={onChange} value={value} label={'Last Name'} />
                )}
              />

              <Controller
                control={control}
                name="dateOfBirth"
                render={({ field: { onChange, value } }) => (
                  <InputMask mask="99-99-9999" maskChar={null} placeholder="MM-DD-YYYY" onChange={onChange} value={value}>
                    {inputProps => <TruentityTextField {...inputProps} required label={'Date of Birth'} type="tel" />}
                  </InputMask>
                )}
              />

              <Stack sx={{ justifyContent: 'center', marginTop: '20px' }}>
                <Button type="submit" a11yLabel="Submit" appearance="secondary" variant="contained" fullWidth={false} />
              </Stack>
            </form>
          </Paper>
        </Stack>
      )}

      {!validSession && <InvalidSession />}
    </>
  );
};

export default QuickUploadStart;
