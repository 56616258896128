import { Body1, H1 } from '@/components/Typography';
import type { PaperProps } from '@mui/material';
import { Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import type { ReactNode } from 'react';

export type KpiSectionProps = PaperProps & {
  label: ReactNode;
  value: number;
  icon?: ReactNode;
  percentage?: ReactNode;
};
export const KpiSection = ({ label, value, icon, percentage, ...props }: KpiSectionProps) => {
  return (
    <Paper
      {...props}
      elevation={0}
      p={2}
      sx={{ ...props.sx, backgroundColor: theme => theme.palette.background.default, height: props?.sx?.height ?? '110px' }}
      component={Stack}
      direction={'row'}
      justifyContent={'flex-start'}
    >
      <Stack alignItems={'flex-start'} flex={10} spacing={1} p={1}>
        <Body1 fontWeight={500} sx={{ color: grey[500] }}>
          {label}
        </Body1>
        <H1 sx={{ color: 'primary.main' }}>{value}</H1>
      </Stack>

      <Stack alignItems={'flex-end'} justifyContent={percentage ? 'space-between' : 'center'} flex={2}>
        {icon && <Box sx={{ color: 'primary.main' }}>{icon}</Box>}
        {percentage}
      </Stack>
    </Paper>
  );
};
