import { gql } from '@apollo/client';

export const QUICK_UPLOAD_TENANT_ADMIN = gql`
  mutation StartQuickUploadSession($sessionId: String!, $lastName: String!, $dateOfBirth: ISO8601DateTime!) {
    startQuickUploadSession(sessionId: $sessionId, lastName: $lastName, dateOfBirth: $dateOfBirth) {
      authCode
      status
    }
  }
`;

export const END_SESSION_URL = gql`
  mutation EndQuickUploadSession($authCode: String!) {
    endQuickUploadSession(authCode: $authCode) {
      status
    }
  }
`;

export const QUICKUPLOAD_PRESIGN_URL = gql`
  mutation GetQuickUploadPresignedUrl($authCode: String!) {
    getQuickUploadPresignedUrl(authCode: $authCode) {
      key
      url
      getUrl
    }
  }
`;

export const QUICKUPLOADS_ADD = gql`
  mutation AddQuickUploads($authCode: String!, $name: String!, $keys: [String!]!) {
    addQuickUploads(authCode: $authCode, name: $name, keys: $keys) {
      status
    }
  }
`;

export const CHECK_SESSION = gql`
  mutation CheckQuickUploadSession($sessionId: String!) {
    checkQuickUploadSession(sessionId: $sessionId) {
      status
    }
  }
`;
