/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

//TODO: Look at how to sort out types for ShowModals (https://app.clickup.com/t/864dt4mkz)

import Button from '@/components/Button';
import { SendEmailDialog } from '@/components/Dialogs';
import SendEHRDialog from '@/components/Dialogs/SendEHRDialog';
import { Small } from '@/components/Typography';
import { formatDateAndTime } from '@/util/format';
import { Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { PDFViewer } from './Summary';

type TCMReportProps = {
  pdfUrl: string;
  refresh: () => void;
  lastUpdateRequest: Date;
};

export const TCMReport = ({ pdfUrl, refresh, lastUpdateRequest }: TCMReportProps) => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const showEmailModal = () => {
    const emailModal = showModal(SendEmailDialog, {
      truentityId: id,
      title: 'Email PDF',
      handleSubmitEmail: () => {
        enqueueSnackbar('Email Sent', {
          variant: 'success'
        });

        emailModal.hide();
      },
      hideDialog: () => emailModal.hide()
    });
  };

  const showSendEHRDialog = () => {
    const tempName = 'TCM Report';
    const modalRef = showModal(SendEHRDialog, {
      title: 'Send TCM Report to EHR',
      successMessage: 'TCM Report has been sent to the EHR',
      truentityId: id,
      medRecSnapshotName: tempName,
      hideDialog: () => modalRef.hide()
    });
  };

  return (
    <Stack spacing={1} alignItems="flex-end">
      <Stack direction="row" spacing={1}>
        <Button a11yLabel="Reload PDF" label="Refresh" onClick={() => refresh()} />
        <Button a11yLabel="Download PDF" label="Download" onClick={() => showEmailModal()} />
        <Button a11yLabel="Send to EHR" label="Send to EHR" onClick={() => showSendEHRDialog()} />
        <Button a11yLabel="Email" label="Email" onClick={() => showEmailModal()} />
      </Stack>
      <PDFViewer src={pdfUrl} />
      <Small fontSize={8}>Last Update Requested: {formatDateAndTime(lastUpdateRequest)}</Small>
    </Stack>
  );
};
