import { createTheme, responsiveFontSizes } from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import { color } from './assets/colors';
import { componentStyleOverrides } from './mui/component-overrides';
import { themePalette } from './mui/palette';
import { themeTypography } from './mui/typography';

const themeOption = {
  colors: color,
  heading: color.grey900,
  paper: color.paper,
  backgroundDefault: color.white,
  background: color.white,
  darkTextPrimary: color.grey700,
  darkTextSecondary: color.grey900,
  textDark: color.grey900,
  menuSelected: color.truentityBlue[700],
  menuSelectedBack: color.truentityBlue[50],
  divider: color.grey400,
  customization: {
    fontFamily: `'Roboto', sans-serif`, //'"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"', // `'Roboto', sans-serif`,  //
    borderRadius: 8
  }
};

let theme = createTheme({
  direction: 'ltr',
  palette: themePalette(themeOption),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  typography: themeTypography(themeOption),
  components: componentStyleOverrides(themeOption),
  shadows: shadows
});
theme = responsiveFontSizes(theme);
export { theme };
