import Patients from '@/routes/Patients/patients';
import { Outlet } from 'react-router-dom';
import PatientInsights from './PatientInsights';

const PatientsSection = () => {
  return <Outlet />;
};

export { Patients, PatientInsights };
export default PatientsSection;
