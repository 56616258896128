import type { ButtonProps, MenuProps, PopoverOrigin } from '@mui/material';
import type { MenuItemData } from 'mui-nested-menu';
import { NestedDropdown } from 'mui-nested-menu';
import type React from 'react';
import { useState } from 'react';

interface NestedDropdownProps {
  children?: React.ReactNode;
  menuItemsData?: MenuItemData;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ButtonProps?: Partial<ButtonProps>;
  MenuProps?: Partial<MenuProps>;
}

type Props = NestedDropdownProps & {
  menuItemsData: MenuItemData;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
};

const DropdownMenu = ({ menuItemsData, MenuProps, ButtonProps, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <NestedDropdown
      {...props}
      menuItemsData={menuItemsData}
      MenuProps={{ elevation: 3, anchorEl: anchorEl, ...MenuProps }}
      ButtonProps={{
        variant: 'outlined',
        size: 'small',
        color: 'primary',
        sx: { minWidth: '200px' },
        ...ButtonProps
      }}
      onClick={e => setAnchorEl(e.currentTarget)}
    />
  );
};

export default DropdownMenu;
