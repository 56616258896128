import Button from '@/components/Button';
import LoadingOverlay from '@/components/LoadingOverlay';
import { ProfileAvatar } from '@/components/ProfileAvatar';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { Caption2, H1, H2, H6 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetRelyingPartyAdminSessionsResponse } from '@/graphql/administration';
import { GET_RELYING_PARTY_ADMINS_SESSIONS } from '@/graphql/administration';
import { GET_LANDING_PAGE_METRICS } from '@/graphql/analytics';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import { color } from '@/styles/assets/colors';
import { MedicalServices } from '@/types/admin';
import type { LandingPageMetricsPayloadType, MetricsDistributionDataType } from '@/types/landingPage';
import { PatientsListSearchFilters } from '@/types/patients-list-filters';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDateTimeIgnoreTZ } from '@/util/format';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import type { GridProps } from '@mui/material';
import { Avatar, Box, Divider, Grid, LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//TODO: Remove duplicate from ReportDashboard
//TODO: Extract components into components folder.  Tidy up shared components in ReportDashboard implementation as well

const IconStyle = {
  fontSize: '2.6rem',
  color: 'white',
  fontWeight: 500
};

type SummaryData = {
  icon: ReactNode;
  label: string;
  value: number;
};

type SummaryProps = GridProps & {
  data: SummaryData[];
};

type SummaryCellProps = GridProps & {
  data: any;
};

const SummaryCell = ({ data, ...props }: SummaryCellProps) => {
  return (
    <Grid
      boxShadow={3}
      alignItems={'center'}
      justifyContent={'center'}
      p={2}
      sx={{
        backgroundColor: '#F6F6F6',
        borderRadius: 4,
        height: 110,
        textAlign: 'center'
      }}
      {...props}
    >
      <Typography sx={{ fontSize: '2rem', fontWeight: 500, color: '#000000' }}>{data.value}</Typography>
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: 500,
          textAlign: 'center',
          color: '#A7A7A7'
        }}
      >
        {data.title}
      </Typography>
    </Grid>
  );
};

const SummaryRow = ({ data, ...props }: SummaryProps) => {
  return (
    <Grid {...props}>
      {data.map(dataItem => (
        <Grid key={dataItem.label} item xs={4} alignItems={'center'} justifyContent={'center'}>
          <Stack
            sx={{
              backgroundColor: color.primary700,
              borderRadius: theme => theme.spacing(2),
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-around',
              padding: theme => theme.spacing(2),
              color: '#FFFFFF',
              '& svg': {
                m: 1.5
              },
              '& hr': {
                mx: 0.5
              }
            }}
          >
            {dataItem.icon}

            <Typography
              sx={{
                fontSize: '1.3rem',
                fontWeight: 350,
                textAlign: 'left',
                color: '#CACACA'
              }}
            >
              {dataItem.label}
            </Typography>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Typography sx={{ ...IconStyle, fontSize: '2rem' }}>{dataItem.value}</Typography>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

type Dataset = {
  dates: any[];
  counts: number[];
};

const HomeDashboardV1 = () => {
  const theme = useTheme();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);

  const [homePageMetricsData, setHomePageMetricsData] = useState<MetricsDistributionDataType[]>();
  const [isIsHomepageDataLoading, setIsHomepageDataLoading] = useState<boolean>(true);

  // for charts
  const [tipsPerformedDataset, setTipsPerformedDataset] = useState<Dataset | null>(null);
  const [cmrsPerformedDataset, setCmrsPerformedDataset] = useState<Dataset | null>(null);

  const [tcmEventsReceived, setTcmEventsReceived] = useState<Dataset | null>(null);
  const [tcmTasksPerformed, setTcmTasksPerformed] = useState<Dataset | null>(null);

  const [noOfPatientsOnboarded, setNoOfPatientsOnboarded] = useState<Dataset | null>(null);
  const [noOfPullsPerformed, setNoOfPullsPerformed] = useState<Dataset | null>(null);

  const [completedTasksCount, setCompletedTasksCount] = useState(0);
  const [pendingTasksCount, setPendingTasksCount] = useState(0);
  const [tasksDueCount, setTasksDueCount] = useState(0);

  const [lastSession, setLastSession] = useState<DateInput>();

  const { setContent, setDrawerOpen } = useContext(SideMenuContext);
  const navigate = useNavigate();

  const [getLandingPageMetrics] = useLazyQuery(GET_LANDING_PAGE_METRICS);

  const { data } = useQuery<GetRelyingPartyAdminSessionsResponse>(GET_RELYING_PARTY_ADMINS_SESSIONS, {
    variables: {
      relyingPartyAdminId: currentUser?.id, //'40b40597-464f-11ec-acbb-16e10b9d7a1c',
      pageNum: 1,
      pageSize: 10000
    },
    notifyOnNetworkStatusChange: true
  });

  //TODO:  New API to generate dashboard section content?  Otherwise wasteful calls like in reporting or invoices sections
  useEffect(() => {
    if (data && data.relyingPartyAdminSessions) {
      const lastSession = data.relyingPartyAdminSessions.relyingPartyAdminSessions[1];
      if (lastSession && lastSession.endedAt) {
        setLastSession(lastSession.endedAt as DateInput);
      }
    }
  }, [data, setLastSession]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  useEffect(() => {
    getLandingPageMetrics({
      variables: {
        filterOptions: {
          relyingPartyAdminId: currentUser?.id
        }
      }
    })
      .then(response => setLandingPageMetricsData(response))
      .catch(error => console.error(error));
  }, [getLandingPageMetrics, currentUser]);

  const setLandingPageMetricsData = (data: LandingPageMetricsPayloadType) => {
    setHomePageMetricsData(data?.data?.getLandingPageMetrics?.landingPageMetrics);
    setIsHomepageDataLoading(false);

    // for the line charts
    if (data?.data?.getLandingPageMetrics?.landingPageMetrics) {
      const medicationTherapyManagementDataset = data?.data?.getLandingPageMetrics?.landingPageMetrics[0].metrics;
      const transitionalCareManagementDataset = data?.data?.getLandingPageMetrics?.landingPageMetrics[1].metrics;
      const patientManagementDataset = data?.data?.getLandingPageMetrics?.landingPageMetrics[2].metrics;

      // for pmedication therapy charts
      const tipsObject: Dataset = {
        dates: medicationTherapyManagementDataset[0].dataSet[0].date,
        counts: medicationTherapyManagementDataset[0].dataSet[0].count
      };
      setTipsPerformedDataset(tipsObject);

      const cmrsObject: Dataset = {
        dates: medicationTherapyManagementDataset[1].dataSet[0].date,
        counts: medicationTherapyManagementDataset[1].dataSet[0].count
      };
      setCmrsPerformedDataset(cmrsObject);

      // for TCM Charts
      const eventsReceivedObject: Dataset = {
        dates: transitionalCareManagementDataset[0].dataSet[0].date,
        counts: transitionalCareManagementDataset[0].dataSet[0].count
      };
      setTcmEventsReceived(eventsReceivedObject);

      const tcmTasksPerformedObject: Dataset = {
        dates: transitionalCareManagementDataset[1].dataSet[0].date,
        counts: transitionalCareManagementDataset[1].dataSet[0].count
      };
      setTcmTasksPerformed(tcmTasksPerformedObject);

      // for patient chart
      const patientsOnboardedObject: Dataset = {
        dates: patientManagementDataset[0].dataSet[0].date,
        counts: patientManagementDataset[0].dataSet[0].count
      };
      setNoOfPatientsOnboarded(patientsOnboardedObject);

      const noOfPullsPerformedObject: Dataset = {
        dates: patientManagementDataset[1].dataSet[0].date,
        counts: patientManagementDataset[1].dataSet[0].count
      };
      setNoOfPullsPerformed(noOfPullsPerformedObject);
    }

    // sum of tcm and normal tasks count for cards (completed | pending)
    const countOfCompletedTasks =
      data?.data?.getLandingPageMetrics?.landingPageMetrics[0].completedCount +
      data?.data?.getLandingPageMetrics?.landingPageMetrics[1].completedCount;
    setCompletedTasksCount(countOfCompletedTasks);

    const countOfPendingTasks =
      data?.data?.getLandingPageMetrics?.landingPageMetrics[0].pendingCount +
      data?.data?.getLandingPageMetrics?.landingPageMetrics[1].pendingCount;
    setPendingTasksCount(countOfPendingTasks);

    const countOfDueTasks =
      data?.data?.getLandingPageMetrics?.landingPageMetrics[0].dueCount + data?.data?.getLandingPageMetrics?.landingPageMetrics[1].dueCount;
    setTasksDueCount(countOfDueTasks);
  };

  return (
    <>
      {<LoadingOverlay active={isIsHomepageDataLoading} text="Please wait. Loading Landing page Data..." />}

      <Grid container spacing={4} p={2}>
        <Grid container item xs={12}>
          <Stack
            item
            xs={12}
            component={Grid}
            direction="row"
            alignItems={'center'}
            justifyContent={'center'}
            p={2}
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4 }}
            spacing={2}
          >
            <ProfileAvatar
              sx={{
                backgroundColor: theme => theme.palette.primary.main,
                width: 48,
                height: 48,
                fontSize: '1.225rem',
                borderRadius: 2
              }}
              variant="square"
              avatarUrl={currentUser?.presignedAvatarUrl}
              name={getAccountUserFullName(currentUser?.user)}
            />

            <Stack direction="column" spacing={1}>
              <H2 textAlign="center">Welcome, {getAccountUserFullName(currentUser?.user)}!</H2>
              {lastSession ? (
                <Caption2>Last login: {formatDateTimeIgnoreTZ(lastSession, 'MMM DD, YYYY (h:mm A)')}</Caption2>
              ) : (
                <Box sx={{ width: '100%', my: 2, mx: 1 }}>
                  <LinearProgress />
                </Box>
              )}
            </Stack>
          </Stack>
        </Grid>
        {/* 2023-09-25 Commenting For RPM Setup */}
        {/*<SummaryRow*/}
        {/*  container*/}
        {/*  item*/}
        {/*  xs={12}*/}
        {/*  spacing={2}*/}
        {/*  data={[*/}
        {/*    {*/}
        {/*      icon: <TaskAltIcon sx={IconStyle} />,*/}
        {/*      label: 'Completed Tasks',*/}
        {/*      value: completedTasksCount*/}
        {/*    },*/}
        {/*    {*/}
        {/*      icon: <DownloadingIcon sx={IconStyle} />,*/}
        {/*      label: 'Pending Tasks',*/}
        {/*      value: pendingTasksCount*/}
        {/*    },*/}
        {/*    {*/}
        {/*      icon: <ScheduleIcon sx={IconStyle} />,*/}
        {/*      label: 'Tasks Due Today',*/}
        {/*      value: tasksDueCount*/}
        {/*    }*/}
        {/*  ]}*/}
        {/*/>*/}

        <Grid container item xs={12} spacing={2}>
          {[
            {
              hidden: !isMTMViewable,
              header: {
                image: 'https://img.freepik.com/premium-vector/doctor-icon-avatar-white_136162-58.jpg?w=740',
                title: 'Medication Therapy Management',
                dateRange: homePageMetricsData ? homePageMetricsData[0]?.metrics[0]?.dateRange : ''
              },
              summary: [
                {
                  title: 'Tasks assigned to me',
                  type: 'assignedToMe',
                  value: homePageMetricsData ? homePageMetricsData[0].assignedCount : 0
                },
                {
                  title: 'Tasks due today',
                  type: 'dueToday',
                  value: homePageMetricsData ? homePageMetricsData[0].dueCount : 0
                }
              ],
              chartsData: [
                {
                  chartTitle: 'TIPs Performed',
                  chartDataSeries: tipsPerformedDataset?.dates || [],
                  chartDataXAxis: tipsPerformedDataset?.counts || []
                },
                {
                  chartTitle: 'CMRs Performed',
                  chartDataSeries: cmrsPerformedDataset?.dates || [],
                  chartDataXAxis: cmrsPerformedDataset?.counts || []
                }
              ],
              footer: (
                <Stack
                  m={1}
                  sx={{ width: '100%', my: 2, mx: 1 }}
                  spacing={1}
                  direction={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                >
                  <Button label="My Tasks" onClick={() => navigate('/patients?search=to-call-next')} />
                </Stack>
              )
            },
            {
              hidden: !isTCMViewable,
              header: {
                title: 'Transitional Care Management',
                image: 'https://t4.ftcdn.net/jpg/03/77/09/95/360_F_377099518_az7aGW023RJ82Pm7BKe1YnXxY22h6CkW.jpg',
                dateRange: homePageMetricsData ? homePageMetricsData[0]?.metrics[0]?.dateRange : ''
              },
              summary: [
                {
                  title: 'TCM Tasks assigned to me',
                  type: 'assignedToMe',
                  value: homePageMetricsData ? homePageMetricsData[1].assignedCount : 0
                },
                {
                  title: 'TCM Tasks due today',
                  type: 'dueToday',
                  value: homePageMetricsData ? homePageMetricsData[1].dueCount : 0
                }
              ],
              chartsData: [
                {
                  chartTitle: 'ADT Events Received',
                  chartDataSeries: tcmEventsReceived?.dates || [],
                  chartDataXAxis: tcmEventsReceived?.counts || []
                },
                {
                  chartTitle: 'TCM Tasks Performed',
                  chartDataSeries: tcmTasksPerformed?.dates || [],
                  chartDataXAxis: tcmTasksPerformed?.counts || []
                }
              ],
              footer: (
                <Stack
                  m={1}
                  sx={{ width: '100%', my: 2, mx: 1 }}
                  spacing={1}
                  direction={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                >
                  <Button label="My TCM Tasks" onClick={() => navigate('/transitional-care-management/tcm-tasks')} />
                </Stack>
              )
            },
            {
              hidden: false,
              header: {
                title: 'Patients',
                image: 'https://t4.ftcdn.net/jpg/01/23/07/93/360_F_123079396_3JYfvotW2sGGV8bGqiyngK1Ghi1T2vXv.jpg',
                dateRange: homePageMetricsData ? homePageMetricsData[0]?.dateRange : ''
              },
              summary: [
                {
                  title: 'Patients assigned to me',
                  type: 'assignedToMe',
                  value: homePageMetricsData ? homePageMetricsData[2]?.assignedCount : 0
                },
                {
                  title: 'Patients to follow up today',
                  type: 'dueToday',
                  value: homePageMetricsData ? homePageMetricsData[2]?.dueCount : 0
                }
              ],
              chartsData: [
                {
                  chartTitle: 'New Patients Onboarded',
                  chartDataSeries: noOfPatientsOnboarded?.dates || [],
                  chartDataXAxis: noOfPatientsOnboarded?.counts || []
                },
                {
                  chartTitle: 'Medication Data Pulls',
                  chartDataSeries: noOfPullsPerformed?.dates || [],
                  chartDataXAxis: noOfPullsPerformed?.counts || []
                }
              ],
              footer: (
                <Stack sx={{ width: '100%', my: 2, mx: 1 }} spacing={1} direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                  <Button label="Patients Lookup" onClick={() => navigate(`/patients?search=${PatientsListSearchFilters?.Lookup}`)} />
                  <Button label="My Patients" onClick={() => navigate(`/patients?search=${currentUser?.id}`)} />
                </Stack>
              )
            },
            {
              hidden: !isRPMViewable,
              header: {
                title: 'Remote Patient Monitoring',
                image: 'https://img.freepik.com/free-vector/online-medical-assistance-illustration_74855-4447.jpg',
                dateRange: homePageMetricsData ? homePageMetricsData[3]?.dateRange : ''
              },
              summary: [
                {
                  title: 'Enrolled Patients',
                  type: 'rpmPatients',
                  value: homePageMetricsData ? homePageMetricsData[3]?.enrolledCount : 0
                },
                {
                  title: 'Connected Devices',
                  type: 'rpmDevices',
                  value: homePageMetricsData ? homePageMetricsData[3]?.devicesCount : 0
                }
              ],
              chartsData: [
                {
                  chartTitle: 'RPM Enrolled Patients',
                  chartDataSeries: noOfPatientsOnboarded?.dates || [],
                  chartDataXAxis: noOfPatientsOnboarded?.counts || []
                },
                {
                  chartTitle: 'RPM Devices',
                  chartDataSeries: noOfPullsPerformed?.dates || [],
                  chartDataXAxis: noOfPullsPerformed?.counts || []
                }
              ],
              footer: (
                <Stack sx={{ width: '100%', my: 2, mx: 1 }} spacing={1} direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                  <Button label="RPM Patients" onClick={() => navigate('/remote-patient-monitoring/my-patients')} />
                </Stack>
              )
            }
          ].map((data, index) => (
            <Grid hidden={data?.hidden} key={index} item xs={6} alignItems={'center'} justifyContent={'center'}>
              <Stack
                component={Paper}
                sx={{
                  borderRadius: theme => theme.spacing(2),
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  padding: theme => theme.spacing(2)
                }}
              >
                <Stack direction={'row'} p={4} spacing={2} alignItems="center">
                  <Avatar
                    variant={'circular'}
                    src={data.header.image}
                    style={{
                      width: '85px',
                      height: '85px'
                    }}
                  />

                  <Stack component={Grid} direction={'column'} justifyContent={'center'} item>
                    <H1 sx={{ fontSize: '1.5rem' }} color="#090909" textAlign="left">
                      {data.header.title}
                    </H1>
                    {data.header.dateRange && (
                      <H6 sx={{ fontSize: '0.9rem', textAlign: 'center' }} color="#B0B1B3">
                        {data.header.dateRange}
                      </H6>
                    )}
                  </Stack>
                </Stack>

                <Grid container item justifyContent={'space-evenly'}>
                  {data.summary.map((summaryItem, id) => (
                    <SummaryCell item xs={5} key={id} data={summaryItem}></SummaryCell>
                  ))}
                </Grid>
                {/* 2023-09-25 Commenting For RPM Setup */}
                {/*<Grid container item py={2} justifyContent={'space-evenly'} flexWrap="wrap">*/}
                {/*  {data.chartsData.map((item, id) => (*/}
                {/*    <LineChart*/}
                {/*      component={Grid}*/}
                {/*      item*/}
                {/*      xs={12}*/}
                {/*      key={id}*/}
                {/*      title={item.chartTitle}*/}
                {/*      height={350}*/}
                {/*      width={'100%'}*/}
                {/*      additionalCss={{*/}
                {/*        backgroundColor: '#F6F6F6',*/}
                {/*        minWidth: 'fill-available',*/}
                {/*        borderRadius: 2*/}
                {/*      }}*/}
                {/*      series={[*/}
                {/*        {*/}
                {/*          name: 'Patients',*/}
                {/*          data: item.chartDataXAxis*/}
                {/*        }*/}
                {/*      ]}*/}
                {/*      options={{*/}
                {/*        xaxis: {*/}
                {/*          categories: item.chartDataSeries,*/}
                {/*          type: 'category',*/}
                {/*          labels: {*/}
                {/*            formatter: function (val) {*/}
                {/*              return val;*/}
                {/*            },*/}
                {/*            hideOverlappingLabels: true,*/}
                {/*            rotate: 0,*/}
                {/*            trim: false*/}
                {/*          },*/}
                {/*          axisTicks: {*/}
                {/*            show: true*/}
                {/*          }*/}
                {/*        },*/}
                {/*        yaxis: {*/}
                {/*          labels: {*/}
                {/*            formatter: function (val) {*/}
                {/*              return val.toFixed(0);*/}
                {/*            }*/}
                {/*          }*/}
                {/*        },*/}
                {/*        stroke: {*/}
                {/*          show: true,*/}
                {/*          curve: 'straight',*/}
                {/*          lineCap: 'butt',*/}
                {/*          colors: undefined,*/}
                {/*          width: 2,*/}
                {/*          dashArray: 0*/}
                {/*        },*/}
                {/*        grid: {*/}
                {/*          row: {*/}
                {/*            colors: ['#ffffff', 'transparent'],*/}
                {/*            opacity: 0.4*/}
                {/*          }*/}
                {/*        },*/}
                {/*        chart: {*/}
                {/*          type: 'line',*/}
                {/*          zoom: {*/}
                {/*            enabled: false*/}
                {/*          },*/}
                {/*          toolbar: {*/}
                {/*            show: false*/}
                {/*          }*/}
                {/*        }*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  ))}*/}
                {/*</Grid>*/}

                {data.footer}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default HomeDashboardV1;
