import FilterContext from '@/context/filterContext';
import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdtQueue from './AdtQueue';
import AdtQueueV1 from './AdtQueueV1';
import DischargePatient from './DischargePatient';

const TransitionalCareManagementSection = () => {
  const [filtersToBeApplied, setFiltersToBeApplied] = useState([]);

  const providerValue = useMemo(
    () => ({
      filtersToBeApplied,
      setFiltersToBeApplied
    }),
    [filtersToBeApplied, setFiltersToBeApplied]
  );

  return (
    <FilterContext.Provider value={providerValue}>
      <Outlet />
    </FilterContext.Provider>
  );
};

export { AdtQueue, AdtQueueV1, DischargePatient };
export default TransitionalCareManagementSection;
