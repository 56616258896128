import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import GlobalLookupUnavailableDialog from '@/components/Dialogs/GlobalLookupUnavailableDialog';
import InformationDialog from '@/components/Dialogs/InformationDialog';
import PatientUpdateDialog from '@/components/Dialogs/PatientUpdateDialog';
import PatientAdd from '@/components/PatientAdd';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H1 } from '@/components/Typography';
import type { GetGlobalAccountSearchResponse } from '@/graphql/account';
import { GET_GLOBAL_SEARCH_ACCOUNTS, ONBOARD_ACCOUNT } from '@/graphql/account';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import useToken from '@/hooks/useToken';
import { theme } from '@/styles/mui-theme';
import { MedicalServices, Role } from '@/types/admin';
import type { MedicalServiceType } from '@/types/graphql';
import { GLOBAL_LOOKUP_TYPES } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';

import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import { MuiColor } from '@/types/mui';
import { toDate } from '@/util/date';
import { formatDate } from '@/util/format';
import { addIfExists } from '@/util/object';
import { phoneNumberFormat, removeNonNumeric } from '@/util/string';
import { useLazyQuery, useMutation } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Chip, Grid, Stack, TextField } from '@mui/material';
import type { GridColDef, GridColumnGroupingModel, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { PatientsData, PatientsUser } from '../Patients/patients';

type LookupFilterOptions = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: string | null;
  phoneNumber?: string | null;
};

const RpmOnboarding = () => {
  const { roleType } = useToken();
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const [searchParams] = useSearchParams();

  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);

  const { setContent, setDrawerOpen } = useContext(SideMenuContext);

  const [onboardAccount] = useMutation(ONBOARD_ACCOUNT);

  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const [rpmGlobalSearchData, setRpmGlobalSearchData] = useState<PatientsData[]>([]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    mtm: isSuperAdmin || isMTMViewable ? true : false,
    tcm: isSuperAdmin || isTCMViewable ? true : false,
    rpm: isSuperAdmin || isRPMViewable ? true : false
  });

  const [getGlobalAccounts, { data: globalAccountsData, loading: loadingGlobalAccountsData, refetch: refetchGlobalAccountsData }] =
    useLazyQuery<GetGlobalAccountSearchResponse>(GET_GLOBAL_SEARCH_ACCOUNTS, {
      fetchPolicy: 'cache-and-network'
    });

  const [formDefault] = useState<LookupFilterOptions>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    dateOfBirth: null
  });

  const updateModalSubHeaderTitle = `
  Please note that a Client Org and a Location must be assigned.
  Additionally, if the full address is available for this patient, we highly recommend that it be properly entered for reliable access to their healthcare data.
`;

  const {
    control,
    reset: resetLookupForm,
    handleSubmit,
    getValues,
    setValue
  } = useForm<LookupFilterOptions>({ defaultValues: formDefault });

  const onSubmit: SubmitHandler<LookupFilterOptions> = data => handleCheckValues(data);

  const handleCheckValues = async (values: LookupFilterOptions) => {
    if ([values?.firstName, values?.lastName, values?.dateOfBirth, values?.phoneNumber].every(entry => !entry || entry?.length === 0)) {
      enqueueSnackbar('One of the First Name, Last Name, Phone Number and Date of Birth is required', {
        variant: 'error'
      });
      resetLookupForm();
      return;
    }
    await callGetInfoByLookup(values);
  };

  const callGetInfoByLookup = async (values: LookupFilterOptions) => {
    const dateOfBirth = values.dateOfBirth ? formatDate(values.dateOfBirth, 'YYYY-MM-DD') : undefined;

    try {
      await getGlobalAccounts({
        variables: {
          filterOptions: {
            firstName: addIfExists(values.firstName),
            lastName: addIfExists(values.lastName),
            phoneNumber: addIfExists(removeNonNumeric(values.phoneNumber)),
            dateOfBirth: addIfExists(dateOfBirth)
          },
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to retrieve patients', { variant: 'error' });
    }
  };

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Services',
      headerAlign: 'center',
      children: [{ field: 'mtm' }, { field: 'tcm' }, { field: 'rpm' }]
    }
  ];

  const generateInfoModalDescription = (user: PatientsUser) => {
    return `${user.firstName} ${user.lastName} was successfully onboarded for the RPM Service. This patient's existing profile information will be used. <br><br><strong>Please proceed to update any missing or additional profile information  needed for the RPM Service.</strong></br></br>`;
  };

  const onboardPatientMutation = async (account: PatientsData, service: MedicalServiceType) => {
    try {
      await onboardAccount({
        variables: {
          service: service.name,
          truentityId: account.truentityId
        }
      });
      enqueueSnackbar('Successfully onboarded the patient', { variant: 'success' });
      refetchGlobalAccountsData();
    } catch (error) {
      enqueueSnackbar('Failed to onboard the patient', { variant: 'error' });
    }
  };

  const showUnavailableDialog = () => {
    const modal = showModal(GlobalLookupUnavailableDialog, {
      hideDialog: () => {
        modal.hide();
      },
      title: 'Service unavailable',
      message: 'Please contact Truentity Health if you wish to offer this service to this patient.'
    });
  };

  const showCloseConfirmationDialog = (account: PatientsData, service: MedicalServiceType) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Onboard Patient',
      message: `Are you sure you want to onboard this patient for the ${service.name} offered by your organization`,
      onAgree: () => {
        if (service.name === MedicalServices.RPM) {
          showInformationDialog('Patient Onboarded', generateInfoModalDescription(account.user), account, service);
        } else {
          onboardPatientMutation(account, service);
        }
        modal.hide();
      },
      onDisagree: () => modal.hide()
    });
  };

  const showInformationDialog = (title: string, message: string, account: PatientsData, service: MedicalServiceType) => {
    const modal = showModal(InformationDialog, {
      title,
      message,
      actions: [
        {
          name: 'Next',
          action: () => {
            showPatientDetailsUpdateDialog(account, service);
            modal.hide();
          }
        }
      ]
    });
  };

  const showSkipInformationDialog = (title: string, message: string, account: PatientsData, service: MedicalServiceType) => {
    const modal = showModal(InformationDialog, {
      title,
      message,
      actions: [
        {
          name: 'Confirm',
          action: () => {
            modal.hide();
            onboardPatientMutation(account, service);
          }
        }
      ]
    });
  };

  const showPatientDetailsUpdateDialog = (account: PatientsData, service: MedicalServiceType) => {
    const accountId = account.truentityId;
    const modal = showModal(PatientUpdateDialog, {
      title: 'Update Profile for the Onboarded Patient',
      subHeaderTitle: updateModalSubHeaderTitle,
      accountId: accountId,
      onPatientUpdated: () => {
        onboardPatientMutation(account, service);
        navigate(`../../patients/${accountId}/details/rpm/patient-setup/care-activity/clinical-summary`);
        modal.hide();
      },
      hideDialog: () => {
        modal.hide();
      },
      skipDialog: (props: any) => {
        if (props.clientOrg === '' || props.clientStore === '') {
          modal.hide();
          showSkipInformationDialog(
            'Warning',
            "Note that the patient was onboarded without a Client Org or a Location assigned. We highly recommend that these be assigned from the Patient's Profile section.",
            account,
            service
          );
        }
      }
    });
  };

  const showDialogBox = (type: string, account: PatientsData, service: MedicalServiceType) => {
    const redirectUrl =
      service.name === MedicalServices.MTM
        ? getAccountRedirectUrl(account.truentityId, MedicalServices.MTM, account.mtmStatus)
        : service.name === MedicalServices.TCM
          ? getAccountRedirectUrl(account.truentityId, MedicalServices.TCM, account.tcmStatus)
          : service.name === MedicalServices.RPM
            ? getAccountRedirectUrl(account.truentityId, MedicalServices.RPM, account.rpmStatus)
            : null;

    if (type === GLOBAL_LOOKUP_TYPES.REDIRECT) {
      redirectUrl && navigate(redirectUrl);
    } else if (type === GLOBAL_LOOKUP_TYPES.UNAVAILABLE) {
      showUnavailableDialog();
    } else if (type === GLOBAL_LOOKUP_TYPES.ENROLL) {
      showCloseConfirmationDialog(account, service);
    }
  };

  const createChip = (service: MedicalServiceType, account: PatientsData) => {
    let color: MuiColor = 'default';
    let clickable = false;
    let type = '';

    if (service.isSameOrg && service.isOfferOrg && service.isEnrolled) {
      color = 'primary';
      clickable = true;
      type = GLOBAL_LOOKUP_TYPES.REDIRECT;
    } else if (service.isOfferOrg && !service.isEnrolled) {
      color = 'success';
      clickable = true;
      type = GLOBAL_LOOKUP_TYPES.ENROLL;
    } else if (!service.isOfferOrg && !service.isEnrolled) {
      color = 'error';
      clickable = true;
      type = GLOBAL_LOOKUP_TYPES.UNAVAILABLE;
    } else if (!service.isSameOrg && service.isOfferOrg && service.isEnrolled) {
      color = 'default';
      clickable = false;
    }

    return (
      <Chip
        label={service.text}
        clickable={clickable}
        color={color}
        variant="outlined"
        onClick={() => showDialogBox(type, account, service)}
      />
    );
  };

  const findServiceByName = (row, serviceName) => {
    return row.medicalServices.find(service => service.name === serviceName);
  };

  const renderCellWithService = (params, serviceName) => {
    const service = findServiceByName(params.row, serviceName);
    return <div>{service && createChip(service, params.row)}</div>;
  };

  const defaultDateOfBirthday = useMemo(
    () => (searchParams.get('dateOfBirth') ? toDate(searchParams.get('dateOfBirth')) : null),
    [searchParams]
  );

  const columns: GridColDef<PatientsData>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row.user)
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.birthDate)
      },
      {
        field: 'gender',
        headerName: 'Gender',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.gender
      },
      {
        field: 'zipcode',
        headerName: 'Zipcode',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.zipcode
      },
      {
        field: 'mtm',
        headerName: MedicalServices.MTM,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => renderCellWithService(params, MedicalServices.MTM)
      },
      {
        field: 'tcm',
        headerName: MedicalServices.TCM,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => renderCellWithService(params, MedicalServices.TCM)
      },
      {
        field: 'rpm',
        headerName: MedicalServices.RPM,
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => renderCellWithService(params, MedicalServices.RPM)
      }
    ],
    []
  );

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  useEffect(() => {
    if (globalAccountsData?.accountsSearch.accounts) {
      setRpmGlobalSearchData(globalAccountsData?.accountsSearch?.accounts);
      setTotalRowCount(globalAccountsData?.accountsSearch?.meta?.totalCount || 0);
    }
  }, [globalAccountsData]);

  useEffect(() => {
    const params: Record<'firstName' | 'lastName' | 'phoneNumber' | 'dateOfBirth', string | null> = {
      firstName: searchParams.get('firstName') ?? '',
      lastName: searchParams.get('lastName') ?? '',
      phoneNumber: searchParams.get('phoneNumber') ?? '',
      dateOfBirth: searchParams.get('dateOfBirth') ?? ''
    };

    Object.keys(params).forEach(key => {
      params[key] = String(params[key]) as string;
    });

    Object.entries(params).forEach(([key, value]) => {
      setValue(key as keyof typeof params, value);
    });
  }, [searchParams, setValue]);

  useEffect(() => {
    const firstName = getValues('firstName');
    const lastName = getValues('lastName');
    const dateOfBirth = getValues('dateOfBirth');
    const phoneNumber = getValues('phoneNumber');

    if (firstName || lastName || dateOfBirth || phoneNumber) {
      callGetInfoByLookup({ firstName, lastName, dateOfBirth, phoneNumber });
    }
  }, [currentPage]);

  return (
    <Stack spacing={1}>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          padding: 3,
          backgroundColor: theme.palette.background.paper,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          Global Lookup
        </H1>
        <PatientAdd refetch={refetchGlobalAccountsData} />
      </Stack>
      <Stack
        direction="row"
        flex={1}
        mt={2}
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="flex-end"
        height="auto"
        spacing={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box flex={1}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <TextField sx={{ width: '100%' }} autoFocus onChange={onChange} value={value} label={'First Name'} />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <TextField sx={{ width: '100%' }} onChange={onChange} value={value} label={'Last Name'} />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { onChange, value } }) => (
              <TruentityPhoneNumber
                editable={true}
                sx={{ width: '100%', m: 0 }}
                onChange={event => {
                  const inputValue = event.target.value.replace(/\D/g, '');
                  onChange(phoneNumberFormat(inputValue));
                }}
                value={value}
                label={'Phone Number'}
              />
            )}
          />
        </Box>
        <Box flex={1}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field }) => (
              <TruentityDatePicker
                sx={{ width: '100%', m: 0 }}
                onChange={date => field.onChange(date)}
                value={field.value}
                inputRef={field.ref}
                label={'Date of Birth'}
                defaultValue={defaultDateOfBirthday}
              />
            )}
          />
        </Box>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6} sx={{ mt: '0.5rem' }}>
            <Button startIcon={<SearchIcon />} label={'Search Patients'} type="submit" size="small" sx={{ marginRight: 1 }} />
          </Grid>
        </Grid>
      </Stack>

      <Stack sx={{ pt: 2 }}>
        <TruentityDataGrid
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroupingModel}
          name={'dg-rpm-patients-onboarding'}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
          autoHeight
          rows={rpmGlobalSearchData}
          columns={columns}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={loadingGlobalAccountsData}
          rowCount={totalRowCount}
          paginationMode="server"
        />
      </Stack>
    </Stack>
  );
};

export default RpmOnboarding;
