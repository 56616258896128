import Alert from '@/components/Alert';
import type { PaperProps } from '@mui/material';
import { Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type BaseRpmWorkflowTabContentProps = PaperProps & {
  isReadOnly: boolean;
};

const BaseRpmWorkflowTabContent = ({ isReadOnly, children, ...props }: BaseRpmWorkflowTabContentProps) => {
  const theme = useTheme();

  return (
    <Paper
      component={Stack}
      sx={{
        background: theme.palette.background.default
      }}
      p={2}
      spacing={2}
      {...props}
    >
      {isReadOnly && (
        <Alert
          sx={{ alignItems: 'center' }}
          status={'warning'}
          title="Read-Only Access"
          description="Your current role grants you read-only access to the data in this tab. You can view all information but cannot make any changes."
        />
      )}
      {children}
    </Paper>
  );
};
export default BaseRpmWorkflowTabContent;
