import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import MuiTabs from '@/components/MuiTabs';
import { H3 } from '@/components/Typography';
import type { UploadLogoResponse } from '@/graphql/account';
import { UPLOAD_LOGO } from '@/graphql/account';
import type { GetCompanyResponse } from '@/graphql/company';
import { GET_COMPANY_BY_ID } from '@/graphql/company';
import type { GetRelyingPartyProvidersResponse, RelyingPartyProviderType } from '@/graphql/remotePatientMonitoring';
import { GET_RELYING_PARTY_PROVIDERS } from '@/graphql/remotePatientMonitoring';
import { withUploadLogic } from '@/hof/withUploadLogic';
import useToken from '@/hooks/useToken';
import ClinicalRangesSection from '@/routes/Administration/System/Company/ClinicalRangesSection';
import { Role } from '@/types/admin';
import type { RelyingParty } from '@/types/graphql';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getValueFromJsonString } from '@/util/json';
import { useProviderStore } from '@/zustand/ProviderStore';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SettingsIcon from '@mui/icons-material/Settings';
import { Avatar, Paper, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CoverLetterSection from './CoverLetterSection';
import DocumentsSection from './DocumentsSection';
import IntegrationSection from './IntegrationSection';
import ProfileSection from './ProfileSection';
import ProvidersSection from './ProvidersSection';
import SettingsSection from './SettingsSection';

const RelyingPartyInfoLogo = ({ logoUrl, relyingParty }) => {
  return (
    <>
      {logoUrl ? (
        <Box sx={{ position: 'relative' }}>
          <img srcSet={`${logoUrl}`} src={`${logoUrl}`} alt={relyingParty?.name} loading="lazy" width={100} />
        </Box>
      ) : (
        <Avatar sx={{ bgcolor: '#BDBDBD', padding: 1 }} />
      )}
    </>
  );
};

const RelyingPartyInfoLogoWithUpload = withUploadLogic(RelyingPartyInfoLogo);

const companySectionTabs = ({
  relyingParty,
  assignedRelyingPartyProviders,
  isCurrentUserCanAccessCompany
}: {
  relyingParty: RelyingParty;
  assignedRelyingPartyProviders: RelyingPartyProviderType[];
  isCurrentUserCanAccessCompany: boolean;
}) => [
  {
    label: 'PROFILE',
    disabled: false,
    icon: <AccountCircleIcon />,
    children: <ProfileSection profileData={relyingParty} />
  },
  {
    label: 'INTEGRATIONS',
    disabled: false,
    icon: <LinkIcon />,
    children: <IntegrationSection integrationData={relyingParty} />
  },
  {
    label: 'REPORTS',
    disabled: false,
    icon: <AssessmentIcon />,
    children: <CoverLetterSection companyData={relyingParty} />
  },
  {
    label: 'DOCUMENTS',
    disabled: false,
    icon: <DescriptionIcon />,
    children: <DocumentsSection documentData={relyingParty} />
  },
  {
    label: 'CLINICAL RANGES',
    disabled: false,
    icon: <QueryStatsIcon />,
    children: <ClinicalRangesSection companyData={relyingParty} />
  },
  {
    label: 'Providers',
    disabled: false,
    icon: <LocalHospitalIcon />,
    children: <ProvidersSection providerData={relyingParty} assignedRelyingPartyProviders={assignedRelyingPartyProviders} />
  },
  {
    label: 'Settings',
    disabled: !isCurrentUserCanAccessCompany,
    icon: <SettingsIcon />,
    children: <SettingsSection companyData={relyingParty} />
  }
];

const Company = () => {
  const { roleType } = useToken();
  const { rpid } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [relyingParty, setRelyingParty] = useState<RelyingParty | null>(null);
  const { assignedRelyingPartyProviders, setAssignedRelyingPartyProviders } = useProviderStore();
  const [logoUrl, setLogoUrl] = useState(relyingParty?.logoUrl);

  const isCurrentUserSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);
  const isCurrentUserCanMangeCompany = useMemo(() => {
    if (!currentUser?.settings) return false;

    return getValueFromJsonString(currentUser.settings, 'additional_permission.can_manage_my_company');
  }, [currentUser]);
  const isCurrentUserCanAccessCompany = useMemo(
    () => isCurrentUserSuperAdmin || isCurrentUserCanMangeCompany,
    [isCurrentUserSuperAdmin, isCurrentUserCanMangeCompany]
  );

  const {
    data: relyingPartyProvidersData,
    called: relyingPartyProvidersCalled,
    loading: relyingPartyProvidersDataLoading
  } = useQuery<GetRelyingPartyProvidersResponse>(GET_RELYING_PARTY_PROVIDERS, {
    variables: {
      relyingPartyId: rpid,
      pageSize: DEFAULT_PAGE_SIZE,
      pageNum: 1
    },
    fetchPolicy: 'cache-and-network'
  });
  const [getCompanyById, { error: getCompanyError, data: getCompanyData }] = useLazyQuery<GetCompanyResponse>(GET_COMPANY_BY_ID, {
    fetchPolicy: 'network-only'
  });

  const [uploadLogo] = useMutation<UploadLogoResponse>(UPLOAD_LOGO);

  const handleUpload = async (file: File) => {
    try {
      const { data } = await uploadLogo({
        variables: {
          file: file,
          relyingPartyId: rpid
        }
      });
      if (data?.uploadLogo) {
        setLogoUrl(data.uploadLogo.s3Url + '?timestamp=' + Date.now());
      }
    } catch (err) {
      console.error('Upload failed:', err);
      enqueueSnackbar('Could not upload logo.', { variant: 'error' });
    }
  };

  const handleUploadFailure = err => {
    console.error('File retrieval failed:', err);
    enqueueSnackbar('Could not retrieve file.', { variant: 'error' });
  };

  useEffect(() => {
    if (rpid) {
      getCompanyById({
        variables: {
          relyingPartyId: rpid
        }
      });
    }
  }, [getCompanyById, rpid]);

  useEffect(() => {
    if (relyingPartyProvidersCalled && !relyingPartyProvidersDataLoading && relyingPartyProvidersData) {
      setAssignedRelyingPartyProviders(relyingPartyProvidersData?.relyingPartyProviders.relyingPartyProviders);
    }
  }, [relyingPartyProvidersData, relyingPartyProvidersDataLoading, relyingPartyProvidersCalled, setAssignedRelyingPartyProviders]);

  useEffect(() => {
    if (getCompanyData?.relyingParty) {
      setRelyingParty(getCompanyData.relyingParty);
    }
  }, [getCompanyData]);

  useEffect(() => {
    if (relyingParty) {
      setLogoUrl(relyingParty?.logoUrl);
    }
  }, [relyingParty]);

  useEffect(() => {
    if (getCompanyError) {
      enqueueSnackbar('Could not fetch company details.', {
        variant: 'error'
      });
      navigate(-1);
    }
  }, [enqueueSnackbar, getCompanyError, navigate]);

  return (
    <Paper component={Stack} p={5}>
      <Stack direction={'row'} spacing={2} alignItems="center">
        <RelyingPartyInfoLogoWithUpload
          logoUrl={logoUrl}
          relyingParty={relyingParty}
          onUploadSuccess={handleUpload}
          onUploadFailure={handleUploadFailure}
          modalTitle="Upload Company Logo"
        />
        <H3>{relyingParty?.name}</H3>
      </Stack>

      <Stack
        sx={{
          marginTop: '16px',
          '&.MuiStack-root': { borderRadius: '8px', padding: '24px 16px' }
        }}
      >
        {relyingParty === null ? (
          <CircularProgress />
        ) : (
          <MuiTabs tabs={companySectionTabs({ relyingParty, assignedRelyingPartyProviders, isCurrentUserCanAccessCompany })} hideDisabled />
        )}
      </Stack>
    </Paper>
  );
};

export default Company;
