import { DialogActions, DialogContent } from '@mui/material';
import Alert from '../Alert';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps;

const ForgotPasswordDialog = ({ title, hideDialog, ...props }: Props) => {
  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props}>
      <DialogContent>
        <Alert title=" Help is on its way" description="Check your email and select the link to reset your password." status={'success'} />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button a11yLabel="Return to Login" appearance="primary" onClick={hideDialog} />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default ForgotPasswordDialog;
