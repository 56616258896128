import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { Small } from '@/components/Typography';
import { getAccountUserFullName } from '@/util/account';
import { toDate } from '@/util/date';
import { formatDate, formatTime } from '@/util/format';
import { emptyString } from '@/util/string';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { StepperPage } from './StepperPage';
import { getNumberOfDaysForComplexity } from './Summary';
import type { TCMWorkflowStepProps } from './TCMWorkflow';

export const TCMWorkflowStep4 = ({ data, tcmTask, assignedTo, formData, setFormData, readOnly }: TCMWorkflowStepProps) => {
  const [providerName] = useState(tcmTask?.finalF2fProvider);
  const [faceToFaceDate, setFaceToFaceDate] = useState(formatDate(new Date(), 'YYYY-MM-DD'));
  const [faceToFaceTime, setFaceToFaceTime] = useState(formatTime(new Date(), 'HH:mm'));
  const [patientName, setPatientName] = useState(emptyString());
  const [complexityLevel] = useState(tcmTask?.complexityLevel);
  const [days, setDays] = useState(0);

  useEffect(() => {
    if (data?.account) {
      setPatientName(getAccountUserFullName(data?.account.user));
    }
  }, [data?.account]);

  useEffect(() => {
    if (tcmTask?.finalF2fDate) {
      const f2fDate = formatDate(tcmTask?.finalF2fDate, 'YYYY-MM-DD');
      const f2fTime = formatTime(tcmTask?.finalF2fDate, 'HH:mm');
      setFaceToFaceDate(f2fDate);
      setFaceToFaceTime(f2fTime);
    }
  }, [tcmTask?.finalF2fDate]);

  useEffect(() => {
    setFormData({
      ...formData,
      finalF2fDate: toDate(`${faceToFaceDate} ${faceToFaceTime}`)
    });
  }, [faceToFaceDate, faceToFaceTime, formData, setFormData]);

  useEffect(() => {
    setDays(getNumberOfDaysForComplexity(complexityLevel));
  }, [complexityLevel]);

  return (
    <StepperPage title={'Step 4: Finalize'} assignedTo={[assignedTo || '']}>
      <Grid container spacing={4}>
        <Grid item>
          <TruentityDatePicker
            value={faceToFaceDate}
            onChange={date => {
              setFaceToFaceDate(formatDate(date as string, 'YYYY-MM-DD'));
            }}
            label={'Face to Face On'}
            disabled={readOnly}
          />

          <TruentityTextField
            id="time"
            label="Face to Face Time"
            type="time"
            value={faceToFaceTime}
            onChange={e => {
              setFaceToFaceTime(e.target.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: 300
            }}
            sx={{ width: 250, marginLeft: '10px' }}
            required
            disabled={readOnly}
          />

          <TruentityTextField
            label={'Location'}
            value={formData.finalF2fLocation}
            onChange={event => {
              setFormData({
                ...formData,
                finalF2fLocation: event.target.value
              });
            }}
            disabled={readOnly}
          />

          <TruentityTextField
            label={'Name of the Provider'}
            value={formData.finalF2fProvider}
            onChange={event => {
              setFormData({
                ...formData,
                finalF2fProvider: event.target.value
              });
            }}
            disabled={readOnly}
          />

          <TruentityTextField
            multiline
            rows={4}
            label={'Notes'}
            value={formData.finalF2fNotes}
            onChange={event => {
              setFormData({
                ...formData,
                finalF2fNotes: event.target.value
              });
            }}
            disabled={readOnly}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.finalMedsReviewed}
                size="medium"
                onChange={() => {
                  setFormData({
                    ...formData,
                    finalMedsReviewed: !formData.finalMedsReviewed
                  });
                }}
              />
            }
            label={<Small>I&apos;ve reviewed this Discharge Information</Small>}
            disabled={readOnly}
          />

          {days > 0 && (
            <Small>
              This TCM was <b>{complexityLevel}</b> and the face-to-face was conducted within <b>{days}</b> days of discharge
            </Small>
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.finalTcmSignedAndCertified}
                size="medium"
                onChange={() => {
                  setFormData({
                    ...formData,
                    finalTcmSignedAndCertified: !formData.finalTcmSignedAndCertified
                  });
                }}
              />
            }
            label={
              <Small>
                I, Dr <b>{providerName}</b>, certify that this patient <b>{patientName}</b>, has met all the requirements for this TCM
              </Small>
            }
            disabled={readOnly}
          />
        </Grid>
      </Grid>
    </StepperPage>
  );
};
