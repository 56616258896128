import Button from '@/components/Button';
import { CommentDialogValues } from '@/components/Dialogs/CreateCommentDialog';
import LoadingOverlay from '@/components/LoadingOverlay';
import { Body1, Body2 } from '@/components/Typography';
import { GetProviderSessionCommentResponse, GET_PROVIDER_SESSION_COMMENT } from '@/graphql/provider';
import { ProviderReviewTypeEnum } from '@/types/remotePatientMonitoring';
import { today } from '@/util/date';
import { formatDateAndTime } from '@/util/format';
import { PROVIDER_APPROVAL_COMMENT_END_TEXT } from '@/util/rpm';
import { useQuery } from '@apollo/client';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const defaultValues: CommentDialogValues = {
  comment: ''
};

type UILabels = {
  commentInput: string;
  submitButton: string;
  cancelButton: string;
};

type Props = BaseDialogProps & {
  uiLabels: UILabels;
  prevComment?: string;
  providerActivityId: string | undefined;
  hideInitialComment?: boolean;
  authCode: string | null;
  reviewType: ProviderReviewTypeEnum;
  onSubmitted: (values: CommentDialogValues) => Promise<void>;
  onCancelled: () => void;
};

function ProviderCommentDialog({
  title,
  onSubmitted,
  prevComment,
  onCancelled,
  reviewType,
  providerActivityId,
  authCode,
  uiLabels,
  hideDialog,
  hideInitialComment = false,
  ...props
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, setValue } = useForm<CommentDialogValues>({ defaultValues });
  const onSubmit: SubmitHandler<CommentDialogValues> = data => onSubmitted(data);

  useEffect(() => {
    if (prevComment) {
      const commentText =
        prevComment.split(PROVIDER_APPROVAL_COMMENT_END_TEXT)?.length > 1
          ? prevComment.split(PROVIDER_APPROVAL_COMMENT_END_TEXT)[1]
          : prevComment;
      setValue('comment', commentText);
    }
  }, [prevComment, setValue]);

  const {
    loading: initialCommentLoading,
    error: initialCommentError,
    data: initialCommentData
  } = useQuery<GetProviderSessionCommentResponse>(GET_PROVIDER_SESSION_COMMENT, {
    variables: {
      authCode: authCode,
      providerActivityId: providerActivityId,
      reviewType: reviewType
    },
    skip: !authCode || !providerActivityId || !reviewType || hideInitialComment
  });

  useEffect(() => {
    if (initialCommentError) {
      enqueueSnackbar(initialCommentError.message, { variant: 'error' });
    }
  }, [initialCommentError]);

  return (
    <BaseDialog {...props} canNavigateBack={false} title={title} customTitleHeader hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <LoadingOverlay text="Comment data loading..." active={initialCommentLoading} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {initialCommentData?.getProviderSessionComment?.commentText && !hideInitialComment && (
            <Stack width="100%" height="100%" spacing={2} flexDirection="column" marginTop={2}>
              <Body1
                sx={{
                  textAlign: 'justify'
                }}
              >
                {initialCommentData?.getProviderSessionComment?.commentText}
              </Body1>
              <Body1> Please add further comments if applicable:</Body1>
            </Stack>
          )}
          <TruentityTextField
            {...register('comment')}
            fullWidth
            variant="outlined"
            margin="dense"
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
            label={uiLabels.commentInput}
            placeholder={'Optional'}
          />
          <Stack width={'100%'} flexDirection="row" justifyContent="flex-end">
            <Body2>{formatDateAndTime(today().toDate())}</Body2>
          </Stack>
          <DialogActions sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Button type="reset" label={uiLabels.cancelButton} appearance="outline" onClick={onCancelled} />
            <Button type="submit" label={uiLabels.submitButton} appearance="primary" />
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
}
export default ProviderCommentDialog;
