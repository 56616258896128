import type { LocationReturnUrl } from '@/types/location';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import type { SxProps, Theme } from '@mui/material';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { H1 } from '../Typography';

export type BaseDialogProps = DialogProps & {
  title: ReactNode;
  customTitleHeader?: boolean; //TODO:  This is the wrong way to do this.  Instead of title being a string, make it a reactnode and pass in custom style to title
  hideDialog?: () => void;
  disableBackdropClick?: boolean;
  canNavigateBack?: boolean; //TODO:  need to improve this, needed to add as not having was sending user back to details page after adding comment
  onClickBack?: () => void | undefined;
  headerElement?: ReactNode;
  titleSx?: SxProps<Theme>;
  hideCloseButton?: boolean;
};

const BaseDialog = ({
  title,
  customTitleHeader = false,
  children,
  hideDialog,
  disableBackdropClick = false,
  canNavigateBack = true,
  onClickBack = undefined,
  headerElement,
  titleSx,
  hideCloseButton,
  ...props
}: BaseDialogProps) => {
  const location = useLocation() as LocationReturnUrl;
  const navigate = useNavigate();

  const handleClose = useCallback(
    (event, reason) => {
      if (disableBackdropClick && reason && reason === 'backdropClick') return;
      hideDialog && hideDialog();
    },
    [disableBackdropClick, hideDialog]
  );

  useEffect(() => {
    if (canNavigateBack) {
      return () => {
        const returnUrl = location?.state?.returnUrl || null;
        if (returnUrl) {
          navigate(returnUrl);
        }
      };
    }
  }, [canNavigateBack, location?.state?.returnUrl, navigate]);

  return (
    <Dialog {...props} maxWidth={props.maxWidth || 'sm'} fullWidth={props.fullWidth} onClose={() => handleClose(null, 'backdropClick')}>
      <DialogTitle {...(headerElement && { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...titleSx } })}>
        {!!onClickBack && typeof onClickBack === 'function' && (
          <IconButton
            aria-label="back"
            onClick={onClickBack}
            sx={{
              p: 0,
              color: theme => theme.palette.grey[500]
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        )}
        {customTitleHeader ? <H1 sx={{ fontWeight: 500, fontSize: '16px', lineHeight: '25px' }}>{title}</H1> : title}

        {hideDialog && !hideCloseButton ? (
          <IconButton
            aria-label="close"
            onClick={hideDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          headerElement && headerElement
        )}
      </DialogTitle>

      {children}
    </Dialog>
  );
};

export default BaseDialog;
