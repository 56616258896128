import Button from '@/components/Button';
import type { TruentityDateInput } from '@/types/date';
import { formatDateIgnoreTZ } from '@/util/format';
import { Paper, Popover, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import TruentityDatePicker from './TruentityDatePicker';
import type { TruentityTextFieldProps } from './TruentityTextField';
import TruentityTextField from './TruentityTextField';

export type DateRangeSelection = { start: TruentityDateInput; end: TruentityDateInput };

type Props = TruentityTextFieldProps & {
  label: string;
  start: TruentityDateInput;
  end?: TruentityDateInput;
  onChange: (range: DateRangeSelection) => void;
};
const TruentityDateRangePicker = ({ label, start, end, onChange, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | (EventTarget & Element)>(null);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [dateRangeStr, setDateRangeStr] = useState('');

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const onApply = () => {
    onChange({ start: startDate, end: endDate });
    setOpen(false);
  };

  const onReset = () => {
    setDateRangeStr('');
    setStartDate(null);
    setEndDate(null);
    onApply();
  };

  useEffect(() => {
    setStartDate(start);
    setEndDate(end);
  }, [end, start]);

  useEffect(() => {
    let str = '';
    if (startDate) {
      str = formatDateIgnoreTZ(startDate);

      if (endDate) {
        if (str.length > 0) {
          str += ' - ' + formatDateIgnoreTZ(endDate);
        }
      }
      setDateRangeStr(str);
    } else {
      setDateRangeStr('');
    }
  }, [endDate, startDate]);

  return (
    <>
      <TruentityTextField
        {...props}
        label={label}
        placeholder={'Select a Date Range'}
        value={dateRangeStr}
        onClickCapture={e => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
          setOpen(!open);
        }}
      ></TruentityTextField>

      <Popover onClose={() => setOpen(false)} anchorEl={anchorEl} open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Paper component={Stack} p={1} spacing={1}>
          <TruentityDatePicker label="Start Date" value={startDate} maxDate={endDate} onChange={handleStartDateChange} />
          <TruentityDatePicker
            minDate={startDate}
            disabled={startDate === null}
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
          />
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} spacing={1}>
            <Button label="Reset" variant={'outlined'} onClick={onReset} />
            <Button label="Apply" onClick={onApply} />
          </Stack>
        </Paper>
      </Popover>
    </>
  );
};

export default TruentityDateRangePicker;
