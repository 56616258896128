import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import { DirectImportDialog, DirectImportNoConsentDialog } from '@/components/Dialogs';
import LoadingOverlay from '@/components/LoadingOverlay';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import InputRow from '@/elements/InputRow';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import { emptyData, reducer } from '@/state/patientProfileForm';
import styled from '@/styles';
import { GENDER_OPTIONS } from '@/types/accountProfile';
import { Checkbox, Divider, FormControlLabel, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';

import type { TruentityDateInput } from '@/types/date';
import { isModeProduction } from '@/util/modes';

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

const DIRECT_IMPORT = gql`
  mutation DirectImportMedicationsFromExternal($truentityId: String!) {
    directImportMedicationsFromExternal(truentityId: $truentityId) {
      status
      message
    }
  }
`;

const DirectImport = () => {
  const [startDate, setStartDate] = useState<TruentityDateInput>(null);
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [importResponseMessage, setImportResponseMessage] = useState('');
  const [state, dispatch] = useReducer(reducer, emptyData);
  const { reloadMeds } = useContext(PatientDetailContext);
  const { id } = useParams();
  const { data: patientData } = useQuery(GET_PATIENT_DETAIL, {
    variables: {
      truentityId: id
    }
  });
  const [executeDirectImport, { data }] = useMutation(DIRECT_IMPORT);
  const [isImporting, setIsImporting] = useState(false);

  const { showModal } = useModal();

  const showDirectImportNoConsentModal = useCallback(() => {
    const modalRef = showModal(DirectImportNoConsentDialog, {
      title: 'Med Direct Import',
      hideDialog: () => modalRef.hide(),
      importResponseMessage: "Medications Import can not proceed. Please obtain patient's consent, before proceeding with the Import.",
      onConfirm: () => {
        modalRef.hide();
        navigate(`/providers/${id}/update-profile`, {
          replace: true
        });
      }
    });
  }, [id, navigate, showModal]);

  const showDirectImportModal = useCallback(() => {
    const modalRef = showModal(DirectImportDialog, {
      title: 'Patient Medication Import',
      hideDialog: () => modalRef.hide(),
      success,
      importResponseMessage,
      onConfirm: () => {
        reloadMeds();
        modalRef.hide();
        navigate(`/patients/${id}/details/medications/triage/list`, {
          replace: true
        });
      }
    });
  }, [id, importResponseMessage, navigate, reloadMeds, showModal, success]);

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();
      if (!patientData?.accountGet?.consent || patientData?.accountGet?.consent === 'NotProvided') {
        showDirectImportNoConsentModal();
      } else {
        setIsImporting(true);
        executeDirectImport({
          variables: {
            truentityId: id
          }
        })
          .then(response => {
            if (response.data.directImportMedicationsFromExternal && response.data.directImportMedicationsFromExternal.status === 'true') {
              setImportResponseMessage(response.data.directImportMedicationsFromExternal.message);
              setSuccess(true);
            } else {
              setImportResponseMessage(response.data.directImportMedicationsFromExternal.message);
              setSuccess(false);
            }
          })
          .catch(e => {
            setIsImporting(false);
            setImportResponseMessage(e.graphQLErrors[0].message);
            setSuccess(false);
            showDirectImportModal();
          });
      }
    },
    [patientData?.accountGet?.consent, showDirectImportNoConsentModal, executeDirectImport, id, showDirectImportModal]
  );

  useEffect(() => {
    if (patientData?.accountGet) {
      if (patientData.accountGet.user.firstName) {
        dispatch({
          type: 'firstName',
          payload: patientData.accountGet.user.firstName
        });
      }
      if (patientData.accountGet.user.middleName) {
        dispatch({
          type: 'middleName',
          payload: patientData.accountGet.user.middleName
        });
      }
      if (patientData.accountGet.user.lastName) {
        dispatch({
          type: 'lastName',
          payload: patientData.accountGet.user.lastName
        });
      }
      if (patientData.accountGet.birthDate) {
        const date = new Date(patientData.accountGet.birthDate.replace(/-/g, '/'));
        setStartDate(date);
      }
      if (patientData.accountGet.gender) {
        dispatch({ type: 'gender', payload: patientData.accountGet.gender });
      }
      if (patientData.accountGet.zipcode) {
        dispatch({ type: 'zipcode', payload: patientData.accountGet.zipcode });
      }
      if (patientData.accountGet.address) {
        dispatch({ type: 'address', payload: patientData.accountGet.address });
      }

      if (patientData.accountGet.addressLine1) {
        dispatch({ type: 'addressLine1', payload: patientData.accountGet.addressLine1 });
      }
      if (patientData.accountGet.addressLine2) {
        dispatch({ type: 'addressLine2', payload: patientData.accountGet.addressLine2 });
      }
      if (patientData.accountGet.addressState) {
        dispatch({ type: 'addressState', payload: patientData.accountGet.addressState });
      }
      if (patientData.accountGet.addressCity) {
        dispatch({ type: 'addressCity', payload: patientData.accountGet.addressCity });
      }

      if (patientData.accountGet.consent) {
        dispatch({ type: 'consent', payload: patientData.accountGet.consent });
      }
    }
  }, [patientData]);

  useEffect(() => {
    if (data) {
      setIsImporting(false);
      if (data.directImportMedicationsFromExternal && data.directImportMedicationsFromExternal.status === 'true') {
        setImportResponseMessage(data.directImportMedicationsFromExternal.message);
        setSuccess(true);
      } else {
        setImportResponseMessage(data.directImportMedicationsFromExternal.message);
        setSuccess(false);
      }
      showDirectImportModal();
    }
  }, [data, showDirectImportModal]);

  return (
    <LoadingOverlay active={isImporting} text="Please wait. Import in progress...">
      <Stack sx={{ width: '50%' }}>
        <form style={{ padding: '20px' }} onSubmit={handleOnSubmit}>
          <H4 style={{ paddingBottom: '20px' }}>Patient Profile for the Import</H4>

          <InputRow>
            <TruentityTextField
              label="First Name"
              required
              type="text"
              disabled={true}
              maxLength={255}
              defaultValue={state.firstName}
              onChange={input => {
                dispatch({
                  type: 'firstName',
                  payload: input.target.value
                });
              }}
            />
          </InputRow>

          <InputRow>
            <TruentityTextField
              label="Middle Name"
              type="text"
              disabled={true}
              maxLength={255}
              defaultValue={state.middleName}
              onChange={input => {
                dispatch({
                  type: 'middleName',
                  payload: input.target.value
                });
              }}
            />
          </InputRow>

          <InputRow>
            <TruentityTextField
              label="Last Name"
              required
              type="text"
              disabled={true}
              maxLength={255}
              defaultValue={state.lastName}
              onChange={input => {
                dispatch({
                  type: 'lastName',
                  payload: input.target.value
                });
              }}
            />
          </InputRow>

          <InputRow>
            <TruentityDatePicker label="Date of birth" value={startDate} disabled={true} onChange={date => setStartDate(date as Date)} />
          </InputRow>
          <InputRow>
            <SelectList
              label="Gender"
              options={GENDER_OPTIONS}
              disabled={true}
              placeholder="Select an option..."
              value={state.gender}
              onChange={event => {
                dispatch({
                  type: 'gender',
                  payload: event.target.value as string
                });
              }}
            />
          </InputRow>
          <InputRow>
            <TruentityTextField
              label="Zip Code"
              type="text"
              disabled={true}
              defaultValue={state.zipcode}
              onChange={input => {
                dispatch({
                  type: 'zipcode',
                  payload: input.target.value
                });
              }}
            />
          </InputRow>

          {/* <InputRow>
              <SelectList
                label='Consent'
                options={CONSENT_OPTIONS}
                disabled={true}
                placeholder='Select an option...'
                value={state.consent}
                onChange={(event) => {
                  dispatch({
                    type: 'consent',
                    payload: event.target.value,
                  })
                }}
              />
            </InputRow> */}

          <a style={{ fontSize: '16px' }} href={'/providers/' + id + '/update-profile'}>
            Edit Profile
          </a>

          {!isModeProduction() && (
            <div>
              <Divider style={{ width: '100%' }} />
              <FormControlLabel style={{ paddingTop: '30px' }} control={<Checkbox defaultChecked />} label="Discharge Summary" />
            </div>
          )}

          <ButtonsContainer style={{ paddingTop: '20px' }}>
            <Button type="submit" a11yLabel="Run Import" appearance="primary" />
          </ButtonsContainer>
        </form>
      </Stack>
    </LoadingOverlay>
  );
};

export default DirectImport;
