import { H4 } from '@/components/Typography';
import AlertLimitsCard from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/AlertLimitsCard';
import ClinicalRangesSlider from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/ClinicalRangesSlider';
import { AlertLimitType } from '@/types/remotePatientMonitoring';
import { Grid } from '@mui/material';
import React from 'react';
import { Control } from 'react-hook-form';

interface AlertLimitsSectionProps {
  name: string;
  control: Control<any>;
  label: string;
  minValue: number;
  maxValue: number;
  unit: string;
  values: number[];
  disabled: boolean;
}
export const AlertLimitsSection: React.FC<AlertLimitsSectionProps> = ({
  name,
  control,
  label,
  minValue,
  maxValue,
  unit,
  values,
  disabled
}) => {
  return (
    <Grid container padding={2} spacing={2}>
      <Grid item xs={12}>
        <H4>{label}</H4>
      </Grid>
      <Grid item xs={5}>
        <ClinicalRangesSlider
          disabled={disabled}
          label={label}
          control={control}
          name={name}
          unit={unit}
          minValue={minValue}
          maxValue={maxValue}
        />
      </Grid>
      <Grid item xs={7}>
        <AlertLimitsCard
          values={
            {
              cLowValue: values[0] ?? 0,
              lowValue: values[1] ?? 0,
              highValue: values[2] ?? 0,
              cHighValue: values[3] ?? 0
            } as AlertLimitType
          }
        />
      </Grid>
    </Grid>
  );
};

export default AlertLimitsSection;
