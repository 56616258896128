import ConversationsList from '@/components/Conversations/ConversationsList';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetPatientConversationsResponse } from '@/graphql/accountConversations';
import { GET_PATIENT_CONVERSATIONS } from '@/graphql/accountConversations';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

export const AllPatientsConversations = () => {
  const { selectedFilter } = usePatientFilterStore();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { setContent } = useContext(SideMenuContext);
  const { data: patientsConversations, loading: conversationsLoading } = useQuery<{
    accountsConversationsAll: GetPatientConversationsResponse;
  }>(GET_PATIENT_CONVERSATIONS, {
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        startDate: selectedFilter?.filter?.conversationDuration?.start,
        endDate: selectedFilter?.filter?.conversationDuration?.end,
        activeStatus: selectedFilter?.filter?.conversationStatus
      }
    }
  });

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return (
    <ConversationsList
      type="all"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      conversations={patientsConversations?.accountsConversationsAll}
      conversationsLoading={conversationsLoading}
    />
  );
};

export default AllPatientsConversations;
