import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import { IconButton } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import * as React from 'react';

export enum PriorityTypes {
  Urgent = 'urgent',
  High = 'high',
  Normal = 'normal',
  Low = 'low',
  Clear = 'clear'
}

export type Priority = PriorityTypes.Urgent | PriorityTypes.High | PriorityTypes.Normal | PriorityTypes.Low;

export const PriorityColorMap: any = {
  [PriorityTypes.Urgent]: 'error',
  [PriorityTypes.High]: 'warning',
  [PriorityTypes.Normal]: 'info',
  [PriorityTypes.Low]: undefined,
  [PriorityTypes.Clear]: 'disabled'
};

type Props = {
  priority: Priority;
  setPriority: (p: Priority) => void;
};

const StyledMenu = styled((props: MenuProps) => <Menu elevation={1} {...props} />)(({ theme }) => ({
  '& .MuiPaper-root': {
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1.5)
      }
    }
  }
}));

const PriorityMenu = ({ priority, setPriority }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changePriority = p => {
    if (p === PriorityTypes.Clear) {
      p = PriorityTypes.Normal;
    }
    setPriority(p);
    handleClose();
  };

  return (
    <div>
      <IconButton aria-label="priority" onClick={e => setAnchorEl(e.currentTarget)}>
        <FlagCircleRoundedIcon fontSize="large" color={PriorityColorMap[priority]} />
      </IconButton>

      <StyledMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
        {Object.values(PriorityTypes).map((p, index) => (
          <MenuItem key={index} onClick={() => changePriority(p)}>
            <FlagCircleRoundedIcon color={PriorityColorMap[p]} />
            {p.toLocaleUpperCase()}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
};

export default PriorityMenu;
