import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import Button from './Button';

type Props = {
  label?: string;
  copyText: string;
  disabled?: boolean;
};
const ClipboardCopy = ({ label, copyText, disabled }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = async text => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyToClipboard(copyText)
      .then(() => {
        enqueueSnackbar('Copied to Clipboard', { variant: 'info' });
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <>
      {label && label.length > 0 && (
        <Button a11yLabel={label} title={`Copy ${copyText}`} appearance="text" disabled={disabled} onClick={handleCopyClick} />
      )}

      {(!label || label.length === 0) && (
        <IconButton title={`Copy ${copyText}`} disabled={disabled} color="primary" component="span" onClick={handleCopyClick}>
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default ClipboardCopy;
