import { ADD_ACCOUNT_NOTE } from '@/graphql/remotePatientMonitoring';
import { modeOfCaptureNoteList } from '@/util/rpm';
import { useMutation } from '@apollo/client';
import { Box, DialogActions, DialogContent, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../Button';
import SelectList from '../SelectList';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  hideDialog: () => void;
};

type FormValues = {
  modeOfCapture: string;
  description: string;
};

const defaultValues: FormValues = {
  modeOfCapture: 'Talk with Patient',
  description: ''
};

const AddAccountNoteDialog = ({ id, title, hideDialog, ...props }: Props): React.ReactElement => {
  const [addAccountNote] = useMutation(ADD_ACCOUNT_NOTE);

  const { control, reset, handleSubmit, setValue } = useForm<FormValues>({ defaultValues });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      addAccountNote({
        variables: {
          truentityId: id,
          modeOfCapture: values.modeOfCapture,
          description: values.description,
          dateTime: null
        }
      })
        .then(response => {
          hideDialog();

          const data = response.data!.createAccountNote;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
        })
        .catch(error => {
          enqueueSnackbar('Unable to add note', {
            variant: 'error'
          });
          hideDialog();
        });
    } catch (err) {
      const failMessage: string = 'Failed to Create a note';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box pb={2}>
            <Controller
              control={control}
              name="modeOfCapture"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id={'modeOfCapture'}
                  label="Noted During"
                  options={modeOfCaptureNoteList.map(x => ({ label: x?.name, value: x?.id }))}
                  placeholder="Noted During"
                  value={value}
                  required
                  clearFunction={() => {
                    setValue('modeOfCapture', '');
                  }}
                  MenuProps={{
                    sx: { maxHeight: 220 }
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box pb={2}>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoFocus={true}
                  required
                  minRows={3}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  multiline
                  rows={8}
                  placeholder="Description"
                />
              )}
            />
          </Box>

          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddAccountNoteDialog;
