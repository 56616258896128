import { PieChart } from '@/components/Charts/charts';
import DashboardSection from '@/components/Dashboard/DashboardSection';
import { KpiSection } from '@/components/KpiComponents/KpiSection';
import LoadingOverlay from '@/components/LoadingOverlay';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { Body1, Caption, Caption2, H2, H3, H4 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetRelyingPartyAdminSessionsResponse } from '@/graphql/administration';
import { GET_RELYING_PARTY_ADMINS_SESSIONS } from '@/graphql/administration';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import { MedicalServices } from '@/types/admin';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getDateStringForMessage } from '@/util/date';
import { formatDateTimeIgnoreTZ } from '@/util/format';
import type { FollowUpItem } from '@/zustand/HomeDashboardStore';
import { useHomeDashboardStore } from '@/zustand/HomeDashboardStore';
import { useQuery, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import DevicesIcon from '@mui/icons-material/Devices';
import GroupIcon from '@mui/icons-material/Group';
import { Avatar, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useContext, useEffect, useState } from 'react';

const FollowUpRecord = ({ time, name, status, description, assignedTo }: FollowUpItem) => {
  return (
    <Paper
      sx={{ width: '100%', padding: 2, background: theme => theme.palette.background.default }}
      elevation={0}
      component={Stack}
      direction={'row'}
      spacing={2}
    >
      <Stack flex={1} sx={{ height: '100%' }}>
        <H3 fontWeight={'bold'}>{time}</H3>
      </Stack>
      <Stack flex={6} spacing={1}>
        <H3>{name}</H3>
        <Body1>{description}</Body1>
        <Caption>{assignedTo}</Caption>
      </Stack>
      <Stack flex={2}>
        <Chip variant={'outlined'} color={'primary'} label={status} />
      </Stack>
    </Paper>
  );
};

const HomeDashboardV2 = () => {
  const { numberOfPatients, patientsToFollowUp, followUps, enrolledPatients, connectedDevices, deviceBreakdown, newsArticles } =
    useHomeDashboardStore();

  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [lastSession, setLastSession] = useState<DateInput>();
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);

  const { setContent, setDrawerOpen } = useContext(SideMenuContext);

  const { data } = useQuery<GetRelyingPartyAdminSessionsResponse>(GET_RELYING_PARTY_ADMINS_SESSIONS, {
    variables: {
      relyingPartyAdminId: currentUser?.id, //'40b40597-464f-11ec-acbb-16e10b9d7a1c',
      pageNum: 1,
      pageSize: 10000
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (data && data.relyingPartyAdminSessions) {
      const lastSession = data.relyingPartyAdminSessions.relyingPartyAdminSessions[1];
      if (lastSession && lastSession.endedAt) {
        setLastSession(lastSession.endedAt as DateInput);
      }
    }
  }, [data, setLastSession]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  return (
    <>
      {<LoadingOverlay active={false} text="Please wait. Loading Landing page Data..." />}
      <Stack
        p={2}
        mx={2}
        spacing={1}
        justifyContent={'flex-end'}
        sx={{ height: 130, background: theme => theme.palette.primary[400], borderRadius: '10px' }}
      >
        <H2 sx={{ color: 'white' }}>Welcome, {getAccountUserFullName(currentUser?.user)}!</H2>
        {lastSession ? (
          <Caption2 color="white">Last login: {formatDateTimeIgnoreTZ(lastSession, 'MMM DD, YYYY (h:mm A)')}</Caption2>
        ) : (
          <LinearProgress color="secondary" />
        )}
      </Stack>
      <Stack direction={'row'} flexWrap={'nowrap'} p={2} justifyContent={'stretch'} spacing={4}>
        <Stack spacing={4} flex={1}>
          <DashboardSection title="General">
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <KpiSection sx={{ flex: 1 }} icon={<GroupIcon />} label="Number of Patients" value={numberOfPatients} />
              <KpiSection sx={{ flex: 1 }} icon={<DevicesIcon />} label="Patients to Follow Up" value={patientsToFollowUp} />
            </Stack>
            <DashboardSection elevation={0} title={'Upcoming Follow ups'}>
              <List
                subheader={
                  <ListItemText
                    sx={{ padding: 2, background: theme => theme.palette.primary.light }}
                    primary={<H4>{formatDateTimeIgnoreTZ(followUps[0].date, 'DD MMMM, YYYY')}</H4>}
                  />
                }
              >
                {followUps[0].items.map((followUp, index) => (
                  <ListItem key={index} sx={{ background: 'white' }}>
                    <FollowUpRecord {...followUp} />
                  </ListItem>
                ))}
              </List>
            </DashboardSection>
          </DashboardSection>

          <DashboardSection title={'Healthcare Management'} width={'auto'}>
            <iframe
              width="100%"
              height="100%"
              style={{ maxWidth: '500px', aspectRatio: '16/9' }}
              src="https://www.youtube.com/embed/eFWCWgo5hRI?si=r-m-KFRzIGhak7xH"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </DashboardSection>
        </Stack>
        <Stack spacing={4} flex={1}>
          {isRPMViewable && (
            <DashboardSection title="Remote Patient Monitoring">
              <Stack spacing={2} direction={'row'} alignItems={'center'}>
                <KpiSection sx={{ flex: 1 }} icon={<GroupIcon />} label={'Enrolled Patients'} value={enrolledPatients} />
                <KpiSection sx={{ flex: 1 }} icon={<DevicesIcon />} label={'Connected Devices'} value={connectedDevices} />
              </Stack>
              <PieChart
                title={'Device Breakdown'}
                width={480}
                series={deviceBreakdown.map(v => v.value)}
                options={{
                  labels: deviceBreakdown.map(v => v.name),
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            </DashboardSection>
          )}

          <DashboardSection title={'Recent News'}>
            <List>
              {newsArticles.map((article, index) => (
                <ListItem key={index} sx={{ background: 'white' }}>
                  <ListItemText primary={article.title} secondary={`${article.source} - ${getDateStringForMessage(article.date)}`} />
                  <ListItemAvatar>
                    <Avatar sx={{ width: 100, height: 100 }} variant={'square'} src={article.image} />
                  </ListItemAvatar>
                </ListItem>
              ))}
            </List>
          </DashboardSection>
        </Stack>
      </Stack>
    </>
  );
};

export default HomeDashboardV2;
