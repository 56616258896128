import ConversationsList from '@/components/Conversations/ConversationsList';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { GetPatientConversationsResponse } from '@/graphql/accountConversations';
import { GET_PATIENT_CONVERSATIONS_BY_PATIENT } from '@/graphql/accountConversations';
import useMessages from '@/hooks/useMessages';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import { useQuery } from '@apollo/client';
import { useState } from 'react';

export const PatientConversations = () => {
  const { selectedFilter } = usePatientFilterStore();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const { patient } = useMessages();
  const { data: patientConversations, loading: conversationsLoading } = useQuery<{
    accountsConversationsByAccount: GetPatientConversationsResponse;
  }>(GET_PATIENT_CONVERSATIONS_BY_PATIENT, {
    variables: {
      truentityId: patient?.patientId,
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        startDate: selectedFilter?.filter?.conversationDuration?.start,
        endDate: selectedFilter?.filter?.conversationDuration?.end,
        activeStatus: selectedFilter?.filter?.conversationStatus
      }
    },
    skip: !patient?.patientId
  });

  return (
    <ConversationsList
      type="patient"
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      conversations={patientConversations?.accountsConversationsByAccount}
      conversationsLoading={conversationsLoading}
    />
  );
};

export default PatientConversations;
