import type { RelyingPartyAdminType, RelyingPartyType } from './graphql';
import type Meta from './meta';

export enum AnnouncementType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export type AnnouncementMedia = {
  s3Key: string;
  s3PresignedUrl: string;
};

export type AnnouncementRecipient = {
  isRead: boolean;
  relyingPartyAdmin: RelyingPartyAdminType;
};

export type RecipientInput = {
  relyingPartyId: string;
  relyingPartyAdminId: 'ALL' | string[];
};

export type Announcement = {
  id: string;
  title: string;
  content: string;
  startTime: string;
  endTime: string;
  alwaysShow: boolean;
  isActive?: boolean;
  media: AnnouncementMedia[] | null;
  type: AnnouncementType;
  announcementRecipientsRelyingParty: RelyingPartyType[] | null;
  announcementRecipients?: AnnouncementRecipient[] | null;
  currentUserRecipient?: AnnouncementRecipient | null;
};

export type AnnouncementSubscriptionResponse = {
  relyingPartyAdminAnnouncements: {
    primaryAnnouncement: Announcement;
    secondaryAnnouncement: Announcement;
  };
};

export type GetUserAnnouncementsResponse = {
  getRelyingPartyAdminsAnnouncements: {
    announcements: Announcement[];
    meta: Meta;
  };
};

export type GetAllAnnouncementsResponse = {
  getAllAnnouncements: {
    announcements: Announcement[];
    meta: Meta;
  };
};
