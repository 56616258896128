import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import SelectList, { type TextSelectOption } from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1 } from '@/components/Typography';
import type { GetProviderRelyingPartiesResponse, GetRpmReportsWithProviderSession } from '@/graphql/remotePatientMonitoring';
import { GET_PROVIDER_SESSION_RELYING_PARTIES, GET_PROVIDER_SESSION_RPM_REPORTS } from '@/graphql/remotePatientMonitoring';
import type { RpmProviderActivitiesType } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatDateAndTime, formatTime, getCurrentDate } from '@/util/format';
import { getRpmProviderAuthCode, mapRpmReportReviewStatusToColor, SELECT_DEFAULT_OPTION, setRpmProviderReportsIds } from '@/util/rpm';
import { useLazyQuery, useQuery } from '@apollo/client';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import type { SelectChangeEvent } from '@mui/material';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RpmAllMonthlyReports = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [monthAndYear, setMonthAndYear] = useState<string>(formatTime(getCurrentDate()?.subtract(1, 'months'), 'MMM YYYY'));
  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);
  const [reportProviderActivities, setReportProviderActivities] = useState<RpmProviderActivitiesType[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>(SELECT_DEFAULT_OPTION.value);

  const { data: relyingPartiesData, loading: relyingPartiesDataLoading } = useQuery<GetProviderRelyingPartiesResponse>(
    GET_PROVIDER_SESSION_RELYING_PARTIES,
    {
      variables: {
        authCode: getRpmProviderAuthCode()
      }
    }
  );
  const [getRpmReportsQuery, { data: rpmReportsData, called: rpmReportsCalled, loading: rpmReportsLoading }] =
    useLazyQuery<GetRpmReportsWithProviderSession>(GET_PROVIDER_SESSION_RPM_REPORTS, { fetchPolicy: 'cache-and-network' });

  const onSelectCompany = (event: SelectChangeEvent<unknown>) => {
    setSelectedCompany(event.target.value as string);
    setCurrentPage(0);
  };

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };
  const getMonthlyRpmReports = useCallback(async () => {
    try {
      await getRpmReportsQuery({
        variables: {
          authCode: getRpmProviderAuthCode(),
          relyingPartyId: selectedCompany,
          filterOptions: {
            reviewStatus: 'all',
            monthYear: monthAndYear
          },
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: currentPage + 1
        }
      });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve rpm reports', { variant: 'error' });
    }
  }, [currentPage, enqueueSnackbar, getRpmReportsQuery, monthAndYear, selectedCompany]);

  const handleStartReviewButton = () => getMonthlyRpmReports();

  const handleShowReport = useCallback(
    (providerActivityId: string) => {
      navigate(`./${providerActivityId}`);
    },
    [navigate]
  );

  const columns: GridColDef<RpmProviderActivitiesType>[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          return (
            <Box
              sx={{
                backgroundColor: mapRpmReportReviewStatusToColor(params.row?.status),
                width: 15,
                height: 15,
                marginX: 2,
                borderRadius: '50%'
              }}
            ></Box>
          );
        }
      },
      {
        field: 'patientName',
        headerName: 'Patient Name',
        flex: 2,
        minWidth: 250,
        sortable: true,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => getAccountUserFullName(params.row.rpmReport?.account.user)
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        minWidth: 150,
        flex: 2,
        sortable: true,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDate(params.row.rpmReport?.account.birthDate)
      },
      {
        field: 'monthYear',
        headerName: 'Month Year',
        minWidth: 150,
        flex: 2,
        sortable: true,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params.row.rpmReport?.monthYear
      },
      {
        field: 'requestedOn',
        headerName: 'Requested On',
        minWidth: 200,
        sortable: true,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateAndTime(params.row?.requestedAt)
      },
      {
        field: 'id',
        sortable: false,
        headerName: 'Review',
        flex: 1,
        align: 'right',
        headerAlign: 'right',
        renderCell: params => (
          <IconButton onClick={() => handleShowReport(params.row.id)}>
            <ContentPasteGoIcon color={'primary'} />
          </IconButton>
        )
      }
    ],
    [handleShowReport]
  );

  useEffect(() => {
    getMonthlyRpmReports();
  }, [currentPage, getMonthlyRpmReports, selectedCompany]);

  useEffect(() => {
    if (rpmReportsCalled && !rpmReportsLoading && rpmReportsData) {
      setReportProviderActivities(rpmReportsData.getProviderSessionRpmReports?.providerActivities ?? []);
      setTotalRowCount(rpmReportsData.getProviderSessionRpmReports?.meta?.totalCount ?? 0);
    }
  }, [rpmReportsCalled, rpmReportsLoading, rpmReportsData]);

  useEffect(() => {
    const rpmReportsIds = reportProviderActivities.map(reviewer => reviewer.id);
    setRpmProviderReportsIds(rpmReportsIds);
  }, [reportProviderActivities]);

  return (
    <>
      <Paper
        elevation={1}
        color={'inherit'}
        component={Stack}
        p={4}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between" spacing={1}>
          <H1>Monthly Report Review</H1>
          <Stack flex={1} flexDirection={'column'} justifyContent="stretch" alignItems="flex-end" spacing={2}>
            <SelectList
              formControlProps={{ sx: { minWidth: 150 } }}
              label="Company"
              options={[
                SELECT_DEFAULT_OPTION,
                ...(relyingPartiesData?.getProviderSessionRelyingParties?.relyingParties?.map(item => {
                  return {
                    label: item.name,
                    value: item.id
                  } as TextSelectOption;
                }) ?? [])
              ]}
              placeholder="Select an option..."
              value={selectedCompany}
              onChange={onSelectCompany}
              fullWidth={false}
            />
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
              <TruentityDatePicker
                showMonth
                showYear={false}
                slotProps={{
                  actionBar: {
                    actions: ['accept']
                  }
                }}
                openTo="month"
                views={['month', 'year']}
                value={monthAndYear}
                onChange={handleMonthYear}
              />
              <Button size="medium" label="Start Review" onClick={handleStartReviewButton} />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <Paper color="inherit" component={Stack} p={4}>
        <TruentityDataGrid
          name={'dg-rpm-monthly-rpm-reports-all'}
          style={{ border: 'none', outline: 'none' }}
          autoHeight
          columns={columns}
          rows={reportProviderActivities}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={rpmReportsLoading || relyingPartiesDataLoading}
          rowCount={totalRowCount}
          paginationMode="server"
        />
      </Paper>
    </>
  );
};

export default RpmAllMonthlyReports;
