import Button from '@/components/Button';
import NewEncounterDialog from '@/components/Dialogs/Encounter/NewEncounterDialog';
import type { AccordionOption } from '@/components/MuiAccordion';
import MuiAccordion from '@/components/MuiAccordion';
import SelectList from '@/components/SelectList';
import TruentityTextField from '@/components/TruentityTextField';
import { H5 } from '@/components/Typography';
import { DISCHARGE_TRANSPORTATION, DISCHARGE_TYPES } from '@/types/dischargePatient';
import { dateCompare } from '@/util/sort';
import { Grid, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import EncounterDetailsSection from '../Encounters/EncounterDetailsSection';
import { StepperPage } from './StepperPage';
import type { TCMWorkflowStepProps } from './TCMWorkflow';

export const TCMWorkflowStep2 = ({ data, tcmTask, assignedTo, formData, setFormData, readOnly, refetch }: TCMWorkflowStepProps) => {
  const { id } = useParams();
  const { showModal } = useModal();

  const callRefetch = useCallback(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  const showNewEncounterModal = useCallback(() => {
    if (!id || !data?.id) {
      return;
    }

    const encounterModal = showModal(NewEncounterDialog, {
      title: 'Log Encounter',
      accountCareEventId: data.id,
      showTasks: false,
      id,
      onComplete: () => {
        encounterModal.hide();
        callRefetch();
      },
      hideDialog: () => {
        encounterModal.hide();
      }
    });
  }, [callRefetch, data?.id, id, showModal]);

  const encountersCloned = tcmTask?.encounters?.slice(0);

  const encounterDetailsAccordionOptions: AccordionOption[] = id
    ? encountersCloned?.sort(dateCompare('calledOn', false)).map((encounter, index) => ({
        label: `Encounter #${index + 1}`,
        defaultExpand: index === 0,
        content: <EncounterDetailsSection encounterDetail={encounter} refetch={callRefetch} showTasks={false} usedInTCM={true} />
      })) || []
    : [];

  return (
    <StepperPage title={'Step 2: Initial Communication'} assignedTo={[assignedTo || '']}>
      <Stack mt={4} mb={4} direction="row" justifyContent="flex-end" alignItems={'center'}>
        <Button disabled={readOnly} label="Add New" onClick={() => showNewEncounterModal()} />
      </Stack>

      <Grid container spacing={4}>
        <Grid xs={6} item rowSpacing={4}>
          <MuiAccordion
            options={[
              {
                label: 'Discharge Information',
                defaultExpand: true,
                content: (
                  <Stack spacing={2}>
                    <SelectList
                      keepOriginalValue
                      options={DISCHARGE_TYPES}
                      onChange={event => {
                        setFormData({
                          ...formData,
                          initialCommsDischargeToType: event.target.value as string
                        });
                      }}
                      label={'Discharged To'}
                      value={formData.initialCommsDischargeToType || ''}
                      disabled={readOnly}
                    />

                    <SelectList
                      keepOriginalValue
                      onChange={event => {
                        setFormData({
                          ...formData,
                          initialCommsDischargeTransportationType: event.target.value as string
                        });
                      }}
                      options={DISCHARGE_TRANSPORTATION}
                      label={'Transported Home In'}
                      value={formData.initialCommsDischargeTransportationType || ''}
                      disabled={readOnly}
                    />
                  </Stack>
                )
              },
              {
                label: 'Caregiver Information',
                defaultExpand: false,
                content: (
                  <Stack spacing={2}>
                    <TruentityTextField
                      label={'Name'}
                      onChange={event => {
                        setFormData({
                          ...formData,
                          initialCommsCaregiverName: event.target.value
                        });
                      }}
                      value={formData.initialCommsCaregiverName}
                      disabled={readOnly}
                    />

                    <TruentityTextField
                      label={'Relationship to Patient'}
                      onChange={event => {
                        setFormData({
                          ...formData,
                          initialCommsCaregiverRelationship: event.target.value
                        });
                      }}
                      value={formData.initialCommsCaregiverRelationship}
                      disabled={readOnly}
                    />
                  </Stack>
                )
              }
            ]}
          />
        </Grid>

        <Grid xs={6} item>
          {encounterDetailsAccordionOptions?.length === 0 ? (
            <Stack alignItems="center" justifyContent="center">
              <H5>No Encounters</H5>
            </Stack>
          ) : (
            <MuiAccordion options={encounterDetailsAccordionOptions} />
          )}
        </Grid>
      </Grid>
    </StepperPage>
  );
};
