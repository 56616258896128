import LoadingOverlay from '@/components/LoadingOverlay';
import styled from '@/styles';
import { gql, useMutation } from '@apollo/client';
import { DialogContent, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';
// import moment from "moment"

type Props = BaseDialogProps & {
  hideDialog: () => void;
  type: string;
};

const ADD_ALLSCRIPTS_ACCOUNTS = gql`
  mutation AddAllScriptsAccounts($fileText: String!) {
    addAllscriptsAccounts(fileText: $fileText) {
      status
      message
      formatErrors
      skipped
      notFound
      added
      updated
    }
  }
`;

const ADD_CARE_MONITORING_ACCOUNTS = gql`
  mutation AddCareMonitoringAccounts($fileText: String!) {
    addCareMonitoringAccounts(fileText: $fileText) {
      status
      message
      formatErrors
      skipped
      notFound
      added
    }
  }
`;

const IMPORT_ACCOUNTS_FROM_EXTERNAL = gql`
  mutation ImportAccountsFromExternal($fileText: String!, $sourceType: String!) {
    importAccountsFromExternal(fileText: $fileText, sourceType: $sourceType) {
      status
      message
      formatErrors
      skipped
      notFound
      added
      updated
    }
  }
`;

// const genderValues = [
//     "M",
//     "F",
//     "O",
//     "U",
//     "Male",
//     "Female",
//     "Other",
//     "Unknown",
// ]

const InputRow = styled('div', {
  '@smallDesktop': {
    width: '50%'
  },

  marginBottom: '1.25rem'
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  '@smallDesktop': {
    width: '50%'
  }
});

const Label = styled('label', {
  display: 'block',
  color: '#777777',
  fontSize: '$md',
  fontWeight: '$bold'
});

const AllScriptsPatientOnboardDialog = ({ title, type, hideDialog, ...props }: Props) => {
  const [formatErrorCount, setFormatErrorCount] = useState(0);
  const [skippedCount, setSkippedCount] = useState(0);
  const [notFoundCount, setNotFoundCount] = useState(0);
  const [addedCount, setAddedCount] = useState(0);
  const [updatedCount, setUpdatedCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addAllscriptsAccounts] = useMutation(ADD_ALLSCRIPTS_ACCOUNTS);
  const [addCareMonitoringAccounts] = useMutation(ADD_CARE_MONITORING_ACCOUNTS);
  const [importAccountsFromExternal] = useMutation(IMPORT_ACCOUNTS_FROM_EXTERNAL);

  // function processPatient(textLine) {
  //     const patientData = textLine.split(',')
  //     if(exceedsMaxLength(patientData[0])) {
  //         console.error('First name too long')
  //         setFormatErrorCount(formatErrorCount + 1)
  //         return
  //     }
  //     if(exceedsMaxLength(patientData[1])) {
  //         console.error('Last name too long')
  //         setFormatErrorCount(formatErrorCount + 1)
  //         return
  //     }
  //     if(exceedsMaxLength(patientData[2])) {
  //         console.error('Middle name too long')
  //         setFormatErrorCount(formatErrorCount + 1)
  //         return
  //     }
  //     if(genderValues.indexOf(patientData[3]) <= -1) {
  //         console.error('Invalid value for gender, must be: “M”, “F”, “O”, “U”, “Male”, “Female”, “Other”, “Unknown”')
  //         setFormatErrorCount(formatErrorCount + 1)
  //         return
  //     }
  //     if(checkDateFormat(patientData[4])) {
  //         console.error('Invalid date format, must be: "YYYY-MM-DD"')
  //         setFormatErrorCount(formatErrorCount + 1)
  //         return
  //     }
  //     if(!isValidZip(patientData[5])) {
  //         console.error('Invalid zip code format, must be: “XXXXX-XXXX” or “XXXXX”')
  //         setFormatErrorCount(formatErrorCount + 1)
  //         return
  //     }

  //     // format is valid, process patient

  // }

  // function exceedsMaxLength(text) {
  //     return text.length > 255;
  // }

  // function checkDateFormat(text) {
  //     const dateFormat = 'YYYY-MM-DD';
  //     const toDateFormat = moment(new Date(text)).format(dateFormat);
  //     return !moment(toDateFormat, dateFormat, true).isValid();
  // }

  // function isValidZip(text) {
  //     return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(text);
  // }

  // function processLines(textLines) {
  //     if(textLines.length < 2) {
  //         console.error('File header or content missing, too few rows')
  //         return
  //     }

  //     // process the first line
  //     const firstLine = textLines[0].split(',')
  //     if(firstLine.length != 6) {
  //         console.error('Incorrect number of headers')
  //         return
  //     }
  //     if((firstLine[0].trim().toLowerCase() != 'first name') ||
  //         (firstLine[1].trim().toLowerCase() != 'last name') ||
  //         (firstLine[2].trim().toLowerCase() != 'middle name') ||
  //         (firstLine[3].trim().toLowerCase() != 'gender') ||
  //         (firstLine[4].trim().toLowerCase() != 'date of birth') ||
  //         (firstLine[5].trim().toLowerCase() != 'zip code')) {
  //         console.error('Incorrect header(s) detected')
  //         return
  //     }

  //     // process the rest of the lines (patient data)
  //     for(let i = 1; i < textLines.length; i++) {
  //         console.log(textLines[i])
  //         processPatient(textLines[i])
  //     }
  // }

  const processFile = async file => {
    var reader = new FileReader();
    reader.readAsText(file);

    reader.onload = async function () {
      const fileText = reader.result;
      // const textLines = fileText?.toString().split('\n')
      // processLines(textLines)

      try {
        let variables = {
          fileText: fileText?.toString(),
          sourceType: type
        };

        const result = await importAccountsFromExternal({
          variables: { ...variables }
        });

        const status = result.data.importAccountsFromExternal.status;
        setFormatErrorCount(result.data.importAccountsFromExternal.formatErrors);
        setSkippedCount(result.data.importAccountsFromExternal.skipped);
        setNotFoundCount(result.data.importAccountsFromExternal.notFound);
        setAddedCount(result.data.importAccountsFromExternal.added);
        // setUpdatedCount(result.data.addAllscriptsAccounts.updated)
      } catch (err) {
        console.log(err);
      }
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  useEffect(() => {
    setLoading(false);
  }, [formatErrorCount, addedCount, skippedCount, notFoundCount]);

  const handleOnSubmit = useCallback(event => {
    event.preventDefault();
    var file = document.getElementById('file').files[0];
    processFile(file);
    setSubmitted(true);
    setLoading(true);
  }, []);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <LoadingOverlay active={loading} text="Processing File..." />

        <Stack>
          <form style={{ padding: '20px', width: '100%' }} onSubmit={handleOnSubmit}>
            <InputRow>
              <Label htmlFor="file">Choose a File:</Label>
              <input type="file" name="file" id="file" accept=".csv" disabled={submitted} required />
            </InputRow>

            {submitted && (
              <InputRow style={{ padding: '20px', width: '100%' }}>
                <h4>Patient File Processed:</h4>
                <p>Number of patients with format errors: {formatErrorCount}</p>
                <p>Number of patients skipped as duplicate/other source: {skippedCount}</p>
                <p>Number of patients not found in the external system: {notFoundCount}</p>
                <p>Number of patients successfully added: {addedCount}</p>
                <p>Number of patients successfully updated: {updatedCount}</p>
              </InputRow>
            )}

            <ButtonsContainer>
              <Button type="submit" a11yLabel="Submit" appearance="primary" disabled={submitted} />
              <Button a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
            </ButtonsContainer>
          </form>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default AllScriptsPatientOnboardDialog;
