import Button from '@/components/Button';
import { NotificationsPopover } from '@/components/Popovers';
import ProfileMenu from '@/components/ProfileMenu';
import SecondaryMenu from '@/components/SecondaryMenu';
import TruentityLogo from '@/components/TruentityLogo';
import TruentityTextFieldWithMenu from '@/components/TruentityTextFieldWithMenu';
import SideMenuContext from '@/context/sideMenuContext';
import useNotifications from '@/hooks/useNotifications';
import { usePopover } from '@/hooks/usePopover';
import { PatientsListSearchFilters } from '@/types/patients-list-filters';
import AppsIcon from '@mui/icons-material/Apps';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import type { AppBarProps } from '@mui/material';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = AppBarProps;

const PATIENT_LOOKUP_FILTER_OPTIONS = [
  {
    label: 'First Name',
    value: 'firstName'
  },
  {
    label: 'Last Name',
    value: 'lastName'
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber'
  },
  {
    label: 'Date of Birth',
    value: 'dateOfBirth'
  },
  {
    label: 'Truentity ID',
    value: 'truentityId'
  }
];
const Navbar = ({ ...props }: Props) => {
  const { drawerToggle, drawerOpen } = useContext(SideMenuContext);
  const { unreadNotificationCount } = useNotifications();
  const notificationPopover = usePopover();
  const navigate = useNavigate();

  const onLookupOptionClick = ({ filterOption, searchValue }) => {
    lookupPatient(searchValue, filterOption);
  };

  const lookupPatient = (search: string, filterOption = '') => {
    navigate(`./patients?${PatientsListSearchFilters.Lookup}&q=${search}&filterOption=${filterOption}`);
  };

  return (
    <>
      <AppBar {...props} position={'sticky'}>
        <Toolbar sx={{ gap: theme => theme.spacing(2), padding: theme => theme.spacing(1) }}>
          <Stack direction={'row'} alignItems={'center'} width={320}>
            <IconButton
              size={'large'}
              edge={'start'}
              color={'inherit'}
              onClick={() => {
                drawerToggle();
              }}
            >
              {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>

            <TruentityLogo />
          </Stack>
          <Box
            flex={1}
            component={'form'}
            onSubmit={e => {
              e.preventDefault();
              lookupPatient((e.currentTarget[0] as any).value);
            }}
          >
            <TruentityTextFieldWithMenu
              options={PATIENT_LOOKUP_FILTER_OPTIONS.map(item => {
                return {
                  label: item.label,
                  callback: value => onLookupOptionClick({ filterOption: item.value, searchValue: value })
                };
              })}
              icon={<SearchIcon />}
              placeholder={'Search Patients'}
            />
          </Box>

          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
            <Box p={1} ref={notificationPopover.anchorRef}>
              <Button
                sx={{
                  width: '100%',
                  p: 0,
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  color: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'transparent'
                  }
                }}
                onClick={notificationPopover.handleOpen}
              >
                {unreadNotificationCount > 0 ? (
                  <Badge badgeContent={unreadNotificationCount} color="primary">
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </Button>
            </Box>
            <SecondaryMenu icon={<AppsIcon color={'primary'} />} />
          </Stack>

          <ProfileMenu />
        </Toolbar>
      </AppBar>

      <NotificationsPopover
        anchorEl={notificationPopover.anchorRef.current}
        open={notificationPopover.open}
        onClose={notificationPopover.handleClose}
      />
    </>
  );
};

export default Navbar;
