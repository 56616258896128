import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import StoreAddDialog from '@/components/Dialogs/StoreAddDialog';
import StoreUpdateDialog from '@/components/Dialogs/StoreUpdateDialog';
import { H3 } from '@/components/Typography';
import AdministrationContext from '@/context/administrationContext';
import Icon from '@/elements/Icon';
import { faEdit, faTableList } from '@fortawesome/free-solid-svg-icons';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Grid, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useContext, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const Stores = () => {
  const { organization, setReloadOrganization } = useContext(AdministrationContext);
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const { showModal } = useModal();

  const addStore = () => {
    const modalRef = showModal(StoreAddDialog, {
      title: 'Add Location',
      hideDialog: () => {
        modalRef.hide(), setReloadOrganization(true);
      },
      id
    });
  };

  const showEditModal = id => {
    const modalRef = showModal(StoreUpdateDialog, {
      title: 'Edit Location',
      hideDialog: () => {
        modalRef.hide(), setReloadOrganization(true);
      },
      id
    });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        valueGetter: params => params.row.name,
        flex: 1,
        align: 'left'
      },
      {
        field: 'nameTage',
        headerName: 'Tag',
        sortable: true,
        valueGetter: params => params.row.nameTag,
        flex: 1,
        align: 'left'
      },
      {
        field: 'isPrimary',
        headerName: 'Primary Client Store',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: cellValues => {
          if (cellValues.value) {
            return <CheckCircleIcon fontSize="small" color="success" />;
          }
          return <CheckCircleOutlineIcon fontSize="small" color="disabled" />;
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        valueGetter: params => {
          return [params.row.id, params.row.name, params.row.nameTag, params.row?.isPrimary].join(',');
        },
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton onClick={() => showEditModal(cellValues.value)}>
                <Icon icon={faEdit} fixedWidth size="lg" />
              </IconButton>

              <Link to={`/administration/organizations/${organization.id}/stores/${cellValues.id}/patients`}>
                <Icon icon={faTableList} fixedWidth size="lg" />
              </Link>
            </Stack>
          );
        }
      }
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <H3 textAlign="center" css={{ width: '100%' }}>
        Locations
      </H3>

      <Grid container spacing={1} key={'search-bar'} alignItems="center">
        <Grid item xs={12}>
          <Stack spacing={1} alignItems={'center'} direction="row" justifyContent={'flex-end'}>
            <Button label="Add Location" onClick={() => addStore()} />
          </Stack>
        </Grid>
      </Grid>

      <Paper component={Stack} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          {/* TODO:  This cannot be paginated server side.  The data does not belong to an api call controlled by this component. Its passed in via the context */}
          <TruentityDataGrid
            name={'dg-stores'}
            autoHeight
            rows={organization.clientStores || []}
            rowCount={rowCountState}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            paginationMode="client"
            disableRowSelectionOnClick
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Stores;
