import colors from '@/styles/colors';
import styled from '@emotion/styled';
import type { TableCellProps, TableContainerProps, TableHeadProps, TableProps, TableRowProps } from '@mui/material';
import {
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow
} from '@mui/material';

type CustomTableRowProps = TableRowProps & {
  noShadow?: boolean;
};

const StyledTableRow = styled(MuiTableRow)((props: CustomTableRowProps) => ({
  boxShadow: props.noShadow === true ? 'none' : `inset 0 -1px 0 0 ${colors.neutralA8}`
}));

const TableHeading = ({ ...props }: TableCellProps) => <MuiTableCell {...props}></MuiTableCell>;
const TableRow = ({ ...props }: CustomTableRowProps) => <StyledTableRow {...props}></StyledTableRow>;
const TableHead = ({ ...props }: TableHeadProps) => <MuiTableHead {...props}></MuiTableHead>;
const TableCell = ({ ...props }: TableCellProps) => <MuiTableCell {...props}></MuiTableCell>;
const Table = ({ ...props }: TableProps) => <MuiTable {...props}></MuiTable>;
const TableContainer = ({ ...props }: TableContainerProps) => <MuiTableContainer {...props}></MuiTableContainer>;

export { TableCell, TableHead, TableHeading, TableRow, TableContainer };
export default Table;
