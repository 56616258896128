import { UsersData } from '@/routes/Administration/Users';
import { Session } from '@/routes/Administration/Users/DetailsSection';
import React from 'react';

type AdministrationContext = {
  organizations: any[];
  reloadOrganizations: () => void;
  goToAssignPatientsToOrganization: () => void;
  selectedOrganizationId: string;
  goToOrganizationsPage: () => void;
  setOrganization: (organization) => void;
  organization: any;
  setClientStore: (store) => void;
  clientStore: any;
  relyingPartyAdmins: any[];
  setRelyingPartyAdmins: (val) => void;
  setSelectedPatients: (data) => void;
  selectedPatients: string[];
  reloadOrganization: boolean;
  setReloadOrganization: (val) => void;
  reloadStore: boolean;
  setReloadStore: (val) => void;
  users: UsersData[];
  setUsers: (users) => void;
  sessions: Session[];
  setSessions: (val) => void;
};

const initialValue = {
  organizations: [],
  reloadOrganizations: () => {},
  goToAssignPatientsToOrganization: () => {},
  selectedOrganizationId: '',
  goToOrganizationsPage: () => {},
  setOrganization: organization => {},
  organization: {},
  setClientStore: organization => {},
  clientStore: {},
  relyingPartyAdmins: [],
  setRelyingPartyAdmins: val => {},
  setSelectedPatients: data => {},
  selectedPatients: [],
  reloadOrganization: false,
  setReloadOrganization: val => {},
  reloadStore: false,
  setReloadStore: val => {},
  users: [],
  setUsers: val => {},
  sessions: [],
  setSessions: val => {}
};

const AdministrationContext = React.createContext<AdministrationContext>(initialValue);

export default AdministrationContext;
