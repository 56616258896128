/**
 * Based on https://www.digitalocean.com/community/tutorials/how-to-build-custom-pagination-with-react-es
 */

import type React from 'react';
import { Fragment } from 'react';

import styled from '@/styles';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const NavContainer = styled('nav', {
  width: '100%'
});

const UlContainer = styled('ul', {
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  justifyContent: 'center'
});

const LiContainer = styled('li', {
  display: 'flex',
  width: '2rem',
  height: '2rem',

  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '$truentitySnow'
  },

  '& a': {
    textDecoration: 'none',
    color: '$truentityMediumGray'
  },

  '&.active': {
    backgroundColor: '#E3F2FD',
    '& a': {
      color: '$truentityDarkGray'
    }
  }
});

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export type PaginationEvent = {
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  totalRecords: number;
};

export type Props = {
  currentPage: number;
  totalRecords: number;
  pageLimit: number;
  pageNeighbours: number;
  onPageChanged: (data: PaginationEvent) => void;
};

const Pagination = (props: Props): React.ReactElement => {
  var { totalRecords = 0, pageLimit, pageNeighbours } = props;
  const { currentPage } = props;

  pageLimit = typeof pageLimit === 'number' ? pageLimit : 15;
  totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;
  pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0;

  const totalPages = pageLimit;

  const gotoPage = (page: number) => {
    const { onPageChanged = f => f } = props;
    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData: PaginationEvent = {
      currentPage,
      totalPages,
      pageLimit,
      totalRecords
    };

    onPageChanged(paginationData);
  };

  const handleClick = (page: number) => evt => {
    evt.preventDefault();
    gotoPage(page);
  };

  const handleMoveLeft = evt => {
    evt.preventDefault();
    gotoPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = evt => {
    evt.preventDefault();
    gotoPage(currentPage + pageNeighbours * 2 + 1);
  };

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return range(1, totalPages);
  };

  if (!totalRecords || totalPages === 1) return <div></div>;
  const pages = fetchPageNumbers();

  return (
    <Fragment>
      <NavContainer>
        <UlContainer>
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <LiContainer key={index} className="page-item">
                  <a className="page-link" href="#" aria-label="Previous" onClick={handleMoveLeft}>
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </a>
                </LiContainer>
              );

            if (page === RIGHT_PAGE)
              return (
                <LiContainer key={index} className="page-item">
                  <a className="page-link" href="#" aria-label="Next" onClick={handleMoveRight}>
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </a>
                </LiContainer>
              );

            return (
              <LiContainer key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                <a className="page-link" href="#" onClick={handleClick(page)}>
                  {page}
                </a>
              </LiContainer>
            );
          })}
        </UlContainer>
      </NavContainer>
    </Fragment>
  );
};

export default Pagination;
