import { Stack } from '@mui/material';
import { H4, H5 } from './Typography';

export const ComingSoon = ({ title }) => {
  return (
    <Stack>
      <H4>{title}</H4>
      <H5>Coming Soon</H5>
    </Stack>
  );
};
