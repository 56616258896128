import { Column as ColumnType, ColumnMap, Entities, Id, Task, TaskMap } from '@/types/triage-medication-types';
import { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './column';
import { multiDragAwareReorder, multiSelectTo as multiSelect } from './utils';

type Props = {
  medications: any;
  columnTitles: any;
  onChanged: (tasks) => void;
  onMedicationSelected: (medication: any) => void;
};

const getTasks = (entities: Entities, columnId: Id): Task[] =>
  entities.columns[columnId].taskIds.map((taskId: Id): Task => entities.tasks[taskId]);

const getColumnId = c => `c-${c.replace(' ', '-').toLowerCase()}`;

const TriageBoard = ({ medications, columnTitles, onChanged, onMedicationSelected }: Props) => {
  const [entities, setEntities] = useState<Entities | null>(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState<Id[]>([]);
  const [draggingTaskId, setDraggingTaskId] = useState<Id | null>(null);
  const [isDragging, setIsDragging] = useState<boolean | null>(null);

  const parseColumnFromIsCurrent = isCurrent => {
    switch (true) {
      case isCurrent === null:
        return columnTitles.todo;

      case isCurrent === true:
        return columnTitles.current;

      case isCurrent === false:
        return columnTitles.previous;
    }

    //default to todo
    return columnTitles.todo;
  };

  useEffect(() => {
    if (medications?.length > 0) {
      setupData();
    }
  }, [medications]);

  const setupData = () => {
    const medicationTasksColumn: ColumnMap = {} as ColumnMap;
    const medicationTasks: TaskMap = {} as TaskMap;

    (Object.values(columnTitles) as string[]).forEach((c: string) => {
      const columnId = getColumnId(c);
      const column: ColumnType = {
        id: columnId,
        title: c,
        taskIds: []
      };

      medicationTasksColumn[columnId] = column;
    });

    const columnOrder: string[] = Object.values(medicationTasksColumn).map((c: any) => c.id);

    medications.forEach((medication: any) => {
      const { id, isCurrent } = medication;
      const columnName = parseColumnFromIsCurrent(isCurrent);
      const columnId = getColumnId(columnName);

      const medicationTask: Task = {
        id,
        content: medication
      };

      if (!medicationTasksColumn[columnId]) {
        console.warn('unable to match column', columnId, medication, columnName);
        return;
      }
      medicationTasks[id] = medicationTask;
      medicationTasksColumn[columnId].taskIds.push(id);
    });

    const entities: Entities = {
      columnOrder,
      columns: medicationTasksColumn,
      tasks: medicationTasks
    };

    setEntities(entities);
    setSelectedTaskIds([]);
    setDraggingTaskId(null);
  };

  const onDragStart = (start: any) => {
    setIsDragging(true);

    const id: string = start.draggableId;
    const selected: Id | undefined = selectedTaskIds.find((taskId: Id): boolean => taskId === id);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      unselectAll();
    }

    setDraggingTaskId(start.draggableId);
  };

  const onDragEnd = (result: any) => {
    setIsDragging(false);

    const destination = result.destination;
    const source = result.source;

    // nothing to do
    if (!destination || result.reason === 'CANCEL') {
      setDraggingTaskId(null);
      return;
    }

    if (!entities) {
      return;
    }

    const processed = multiDragAwareReorder({
      entities,
      selectedTaskIds,
      source,
      destination
    });

    setEntities(processed.entities);
    setSelectedTaskIds(processed.selectedTaskIds);
    setDraggingTaskId(null);
  };

  useEffect(() => {
    if (isDragging === false) {
      onChanged(entities);
    }
    setIsDragging(null);
  }, [entities, isDragging]);

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    unselectAll();
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const toggleSelection = (taskId: Id) => {
    const wasSelected: boolean = selectedTaskIds.includes(taskId);

    const newTaskIds: Id[] = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedTaskIds(newTaskIds);
  };

  const toggleSelectionInGroup = (taskId: Id) => {
    const index: number = selectedTaskIds.indexOf(taskId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedTaskIds([...selectedTaskIds, taskId]);
      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow: Id[] = [...selectedTaskIds];
    shallow.splice(index, 1);

    setSelectedTaskIds(shallow);
  };

  // This behaviour matches the MacOSX finder selection
  const multiSelectTo = (newTaskId: Id) => {
    if (!entities) {
      return;
    }

    const updated: Id[] = multiSelect(entities, selectedTaskIds, newTaskId);

    if (updated == null) {
      return;
    }

    setSelectedTaskIds(updated);
  };

  const unselectAll = () => {
    setSelectedTaskIds([]);
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, []);

  const goToMedicationById = (id: string) => {
    const med = medications.find(m => m.id === id);
    onMedicationSelected(med);
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {entities?.columnOrder?.map((columnId: Id) => (
        <Column
          column={entities.columns[columnId]}
          tasks={getTasks(entities, columnId)}
          selectedTaskIds={selectedTaskIds}
          key={columnId}
          draggingTaskId={draggingTaskId}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          multiSelectTo={multiSelectTo}
          goToMedicationById={goToMedicationById}
        />
      ))}
    </DragDropContext>
  );
};

export default TriageBoard;
