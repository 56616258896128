import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import PioneerRxAuthDialog from '@/components/Dialogs/PioneerRxAuthDialog';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityTextField from '@/components/TruentityTextField';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD, UPDATE_CLIENT_STORE } from '@/graphql/administration';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import type { ClientOrganizationType, ClientStoreType } from '@/types/graphql';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getServerAccessUrl } from '@/util/environment';
import { getValueFromJsonString } from '@/util/json';
import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PatternIcon from '@mui/icons-material/Pattern';
import { Box, Grid, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  relyingPartyId: string;
};

const PioneerRxDetailsForm = ({ relyingPartyId }: Props) => {
  const { roleType } = useToken();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [organizationId, setOrganizationId] = useState<string>('');
  const [clientLocationId, setClientLocationId] = useState<string>('');
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationType[]>([]);
  const [clientLocations, setClientLocations] = useState<ClientStoreType[]>([]);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const [generatedUrl, setGeneratedUrl] = useState('');

  const isCurrentUserCanMangeCompany = useMemo(() => {
    if (!currentUser?.settings) return false;

    return getValueFromJsonString(currentUser.settings, 'additional_permission.can_manage_my_company');
  }, [currentUser]);
  const isCurrentUserCanAccessCompany = useMemo(
    () => isSuperAdmin || isCurrentUserCanMangeCompany,
    [isSuperAdmin, isCurrentUserCanMangeCompany]
  );

  const [updateClientStore] = useMutation(UPDATE_CLIENT_STORE);
  const { data: clientOrgData, refetch: refetchClintOrg } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      relyingPartyId: relyingPartyId,
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  const setOrganizationValues = (data: GetClientOrganizationsResponse) => {
    const { clientOrganizations } = data.clientOrganizations;

    if (clientOrganizations) {
      setOrganizationId(clientOrganizations?.[0]?.id);
      setClientOrganizations(clientOrganizations);
    }
  };

  const generatePioneerRxUrl = (organizationId: string, clientLocationId: string) => {
    try {
      const url = new URL(getServerAccessUrl());
      url.pathname = '/pioneerrx';
      url.searchParams.append('oid', organizationId);
      if (clientLocationId?.length > 0) url.searchParams.append('sid', clientLocationId);
      return url.toString();
    } catch (error) {
      console.error('Can not generate PioneerRx URL');
    }
  };

  const handleCopyButton = async () => {
    let timeout;
    try {
      await navigator.clipboard.writeText(generatedUrl);
      setIsCopied(true);

      timeout = setTimeout(() => setIsCopied(false), 5000);
    } catch (error) {
      console.error('Failed to copy text:', error);
      setIsCopied(false);
      clearTimeout(timeout);
    }
  };

  const getOrganizationById = useCallback(
    organizationId => {
      return clientOrganizations.find(clientOrg => clientOrg.id === organizationId);
    },
    [clientOrganizations]
  );

  const getClientLocationById = useCallback(
    clientLocationId => {
      return clientLocations.find(clientLocation => clientLocation.id === clientLocationId);
    },
    [clientLocations]
  );

  const handlePioneerAuth = (id: string) => {
    const clientLocation = getClientLocationById(id);
    const locationSetting = clientLocation?.settings ?? '';

    const username = locationSetting?.['PioneerRxAPI']?.['username'] ?? '';
    const password = locationSetting?.['PioneerRxAPI']?.['password'] ?? '';

    const modal = showModal(PioneerRxAuthDialog, {
      title: 'Setup PioneerRx Authentication Credentials',
      data: { username, password },
      hideDialog: () => {
        modal.hide();
      },
      onSetCredentials(username, password) {
        try {
          if (username.trim().length <= 0) {
            return enqueueSnackbar('Please enter a username to continue.', { variant: 'error' });
          }

          if (password.trim().length <= 0) {
            return enqueueSnackbar('Please enter a password to continue.', { variant: 'error' });
          }

          if (!clientLocation) {
            return enqueueSnackbar("We couldn't find the specified client location.", { variant: 'error' });
          }

          const clientLocationSettings = {
            PioneerRxAPI: {
              username: username,
              password: password
            }
          };

          updateClientStore({
            variables: {
              clientStoreId: clientLocation.id,
              name: clientLocation.name,
              nameTag: clientLocation.nameTag,
              isPrimary: clientLocation.isPrimary,
              settings: JSON.stringify(clientLocationSettings) ?? '{}'
            }
          }).then(res => {
            if (res.errors && res.errors?.length > 0) {
              modal.hide();
              return enqueueSnackbar('Cannot save pioneerRx settings. Please contact support', { variant: 'error' });
            }
            modal.hide();
            refetchClintOrg();
            return enqueueSnackbar('PioneerRx settings saved successfully', { variant: 'success' });
          });
        } catch (error) {
          console.error('There was an error while saving the client organization.');
        }
      }
    });
  };

  useEffect(() => {
    if (organizationId?.length > 0) {
      const clientStores = getOrganizationById(organizationId)?.clientStores ?? [];

      setClientLocationId(clientStores?.[0]?.id || '');
      setClientLocations(clientStores);
    }
  }, [organizationId, getOrganizationById]);

  useEffect(() => {
    if (organizationId?.length > 0) {
      const generatedUrl = generatePioneerRxUrl(organizationId, clientLocationId);

      if (generatedUrl && generatedUrl?.length > 0) return setGeneratedUrl(generatedUrl);
      return;
    }
  }, [organizationId, clientLocationId]);

  useEffect(() => {
    if (clientOrgData) {
      setOrganizationValues(clientOrgData);
    }
  }, [clientOrgData]);

  return (
    <Stack gap={3}>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <SelectList
            formControlProps={{ sx: { minWidth: '200px' } }}
            label="Organization"
            options={clientOrganizations?.map(item => {
              return {
                label: item.name,
                value: item.id
              } as TextSelectOption;
            })}
            placeholder="Select an option..."
            value={organizationId}
            onChange={event => {
              setOrganizationId(event.target.value as string);
            }}
            disabled={clientOrganizations?.length === 0 || !isCurrentUserCanAccessCompany}
            fullWidth={false}
          />
        </Grid>
        <Grid item>
          <SelectList
            formControlProps={{ sx: { minWidth: '200px' } }}
            label="Client Location"
            options={clientLocations?.map(item => {
              return {
                label: item.name,
                value: item.id
              } as TextSelectOption;
            })}
            placeholder="Select an option..."
            value={clientLocationId}
            onChange={event => {
              setClientLocationId(event.target.value as string);
            }}
            disabled={clientLocations?.length === 0 || !isCurrentUserCanAccessCompany}
            fullWidth={false}
            {...(clientLocations.length === 0 && { formHelperText: 'No location found for the selected client store' })}
          />
        </Grid>
      </Grid>
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <TruentityTextField InputProps={{ readOnly: true }} value={generatedUrl} label={'PioneerRx Webhook URL'} />
          <Button
            variant="outlined"
            startIcon={<PatternIcon />}
            onClick={() => handlePioneerAuth(clientLocationId)}
            size="small"
            disabled={!isCurrentUserCanAccessCompany || clientLocations.length === 0}
          >
            Set PioneerRx Credentials
          </Button>
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyButton}
            color={isCopied ? 'success' : 'primary'}
            size="small"
            disabled={!isCurrentUserCanAccessCompany}
          >
            Copy URL
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default PioneerRxDetailsForm;
