import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

type Props = BoxProps;
const RelyingAdminLogo = ({ ...props }: Props) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  return (
    <Box
      component="img"
      src={currentUser?.relyingParty?.logoUrl}
      alt="company logo"
      {...props}
      sx={{
        width: '7rem',
        objectFit: 'scale-down',
        ...props.sx
      }}
    />
  );
};

export default RelyingAdminLogo;
