import type { Consult, MedConsultTemplate } from '@/graphql/med-consult';
import create from 'zustand';

export type QuestionUpdate = {
  index: number;
  answer: string;
};

export type MedConsultWindowStore = {
  openWindow: boolean;
  consult: Consult | null;
  refetchConsults: boolean;
  selectedConsultType: MedConsultTemplate | null;
  addNewQuestion: boolean;
  updateQuestion: QuestionUpdate;
  newQuestionTxt: string;
  newQuestionSecondaryTxt: string;
  expanded: string;
  isMedConsultStart: boolean;
  setOpenWindow: (value: boolean) => void;
  setConsult: (value: Consult | null) => void;
  setSelectedConsultType: (value: MedConsultTemplate | null) => void;
  setRefetchConsults: (value: boolean) => void;
  setAddNewQuestion: (value: boolean) => void;
  setUpdateQuestion: (value: QuestionUpdate) => void;
  setNewQuestionTxt: (value: string) => void;
  setNewQuestionSecondaryTxt: (value: string) => void;
  setExpanded: (value: string) => void;
  setIsMedConsultStart: (value: boolean) => void;
};

export const useMedConsultWindowStore = create<MedConsultWindowStore>(set => ({
  openWindow: false,
  consult: null,
  refetchConsults: false,
  addNewQuestion: false,
  selectedConsultType: null,
  updateQuestion: {} as QuestionUpdate,
  newQuestionTxt: '',
  newQuestionSecondaryTxt: '',
  expanded: '',
  isMedConsultStart: false,
  setOpenWindow: value => set(() => ({ openWindow: value })),
  setSelectedConsultType: (value: MedConsultTemplate | null) => set(() => ({ selectedConsultType: value })),
  setConsult: value => set(() => ({ consult: value })),
  setRefetchConsults: (value: boolean) => set(() => ({ refetchConsults: value })),
  setAddNewQuestion: value => set(() => ({ addNewQuestion: value })),
  setUpdateQuestion: value => set(() => ({ updateQuestion: value })),
  setNewQuestionTxt: value => set(() => ({ newQuestionTxt: value })),
  setNewQuestionSecondaryTxt: value => set(() => ({ newQuestionSecondaryTxt: value })),
  setExpanded: value => set(() => ({ expanded: value })),
  setIsMedConsultStart: value => set(() => ({ isMedConsultStart: value }))
}));
