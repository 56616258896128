/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { H1, H2, H4, Small } from '@/components/Typography';
import type { GetAdtTcmActivityLogsMutationResponse } from '@/graphql/transitionalCareManagement';
import { GET_ADT_TCM_ACTIVITY_LOGS } from '@/graphql/transitionalCareManagement';
import type { AccountCareEvent, TcmTask } from '@/types/dischargePatient';
import { DISCHARGE_TRANSPORTATION, DISCHARGE_TYPES } from '@/types/dischargePatient';
import { formatDateAndTime, formatDateIgnoreTZ } from '@/util/format';
import { useMutation } from '@apollo/client';
import { Button, Chip, Drawer, Grid, Stack } from '@mui/material';

import { GridItem } from '@/components/GridItem';
import RelyingAdminLogo from '@/components/RelyingAdminLogo';
import type { AdtTcmActivityLog } from '@/types/activityLog';
import { TASK_MODE_OF_RESOLUTION_TYPES } from '@/types/medication';
import { getAccountUserFullName } from '@/util/account';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box } from '@mui/system';
import { useState } from 'react';
import LogView from './logView';

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: (val: boolean) => void;
  data?: Partial<AccountCareEvent>;
  tcmTask?: Partial<TcmTask>;
  assignedTo?: string;
};

export const SummaryDrawer = ({ drawerOpen, setDrawerOpen, data, tcmTask, ...props }: Props) => {
  const [isLogViewVisible, setIsLogViewVisible] = useState<boolean>(false);
  const [adtTcmActivityLogData, setAdtTcmActivityLogsData] = useState<AdtTcmActivityLog[]>([]);
  const [getAdtTcmActivityLogs] = useMutation<GetAdtTcmActivityLogsMutationResponse>(GET_ADT_TCM_ACTIVITY_LOGS);

  const fetchActivityLogsForTasks = async taskId => {
    await getAdtTcmActivityLogs({
      variables: {
        tcmTaskInput: {
          id: taskId
        }
      }
    })
      .then(response => setAdtTcmActivityLogsData(response?.data?.getAdtTcmActivityLogs?.activityLog))
      .catch(error => console.error(error));
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
    setIsLogViewVisible(false);
  };

  return (
    <Drawer
      {...props}
      container={document.body}
      PaperProps={{
        sx: { width: '40%', padding: 2 }
      }}
      variant="temporary"
      sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      anchor="right"
      open={drawerOpen}
      onClose={() => onDrawerClose()}
      color="inherit"
      elevation={1}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
          <Stack justifyContent={'center'} alignItems={'center'} sx={{ flex: 1 }}>
            <RelyingAdminLogo />
            <Chip variant={'outlined'} label={'Verified'} size="medium" color={'success'} />
            <Box
              m={1}
              marginLeft={50}
              marginTop={-10}
              marginBottom={5}
              //margin
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                startIcon={isLogViewVisible ? <ArrowBackIosNewIcon /> : ''}
                size="small"
                sx={{ fontSize: 12 }}
                variant={'contained'}
                onClick={() => {
                  setIsLogViewVisible(!isLogViewVisible);
                  fetchActivityLogsForTasks(tcmTask?.id);
                }}
              >
                {isLogViewVisible ? 'Report' : 'Logs'}
              </Button>
            </Box>
          </Stack>
        </Grid>
        {isLogViewVisible ? (
          <Grid item xs={12}>
            <LogView adtTcmActivityLogs={adtTcmActivityLogData} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Stack component={H1} justifyContent={'center'} alignItems={'center'}>
                TCM Report
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <GridItem label="Patient Name" value={getAccountUserFullName(data?.account?.user)} />
              <GridItem label="Date of Birth" value={formatDateIgnoreTZ(data?.account?.birthDate) || '---'} />
              <GridItem label="Gender" value={data?.account?.gender || '---'} />
              <GridItem label="Address" value={data?.account?.address || '---'} />
            </Grid>
            <Grid item xs={6}>
              <GridItem label="Discharge Date" value={formatDateIgnoreTZ(data?.dischargeDate)} />
              <GridItem label="Discharged From" value={data?.dischargeLocation} />
              <GridItem label="Admission Date" value={formatDateIgnoreTZ(data?.admissionDate)} />
              <GridItem label="Admission Reason" value={data?.admissionReason} />
              <GridItem label="Complexity Level" value={tcmTask?.complexityLevel} />
            </Grid>
            <Grid item xs={12}>
              <H2 mb={1}>Discharge Review</H2>
              <GridItem label="Discharged From" value={tcmTask?.reviewDischargeInfoFrom || '---'} />
              <GridItem label="Discharge Type" value={tcmTask?.reviewDischargeInfoType || '---'} />
              <GridItem label="Discharging Physician" value={tcmTask?.reviewDischargeInfoDischargingPhysician || '---'} />
              <GridItem label="Diagnosis Code" value={tcmTask?.reviewDischargeInfoDiagnosisCode || '---'} />
            </Grid>
            <Grid item xs={12}>
              <H2 mb={1}>Initial Communications</H2>
              <GridItem
                label="Discharged To"
                value={
                  tcmTask?.initialCommsDischargeToType
                    ? DISCHARGE_TYPES.filter(type => type.value === tcmTask?.initialCommsDischargeToType).length > 0
                      ? DISCHARGE_TYPES.filter(type => type.value === tcmTask?.initialCommsDischargeToType)[0].label
                      : ''
                    : '---'
                }
              />
              <GridItem
                label="Transported Home In"
                value={
                  tcmTask?.initialCommsDischargeTransportationType
                    ? DISCHARGE_TRANSPORTATION.filter(type => type.value === tcmTask?.initialCommsDischargeTransportationType).length > 0
                      ? DISCHARGE_TRANSPORTATION.filter(type => type.value === tcmTask?.initialCommsDischargeTransportationType)[0].label
                      : ''
                    : '---'
                }
              />
              <GridItem label="Caregiver Name" value={tcmTask?.initialCommsCaregiverName || '---'} />
              <GridItem label="Caregiver Relationship to Patient" value={tcmTask?.initialCommsCaregiverRelationship || '---'} />
            </Grid>

            {tcmTask?.encounters && tcmTask?.encounters.length > 0 && (
              <Grid item xs={12} spacing={4}>
                <H4 sx={{ textDecoration: 'underline', mb: 1 }}>Encounters:</H4>
                <ul>
                  {tcmTask?.encounters?.map(encounter => (
                    <GridItem
                      key={encounter.id}
                      label={formatDateAndTime(encounter?.calledOn)}
                      value={
                        TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === encounter?.modeOfResolution).length > 0
                          ? TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === encounter?.modeOfResolution)[0].label
                          : '' || '---'
                      }
                    />
                  ))}
                </ul>
              </Grid>
            )}

            <Grid item xs={12}>
              <H2>Documentation Review</H2>

              <GridItem label="Discussions with Referrals" value={tcmTask?.docReferralDiscussions || '---'} />
              <GridItem label="Discussions with Home Care Providers" value={tcmTask?.docCaregiverDiscussions || '---'} />
              <GridItem label="Treatment Adherence Instructions" value={tcmTask?.docTreatmentAdherenceInstructions || '---'} />
              <GridItem label="Recommended Self Care Activities" value={tcmTask?.docSelfCareActivities || '---'} />
            </Grid>

            <Grid item xs={12}>
              <H2 mb={1}>Final Face to Face</H2>
              <GridItem label="Face to Face On" value={formatDateIgnoreTZ(tcmTask?.finalF2fDate) || '---'} />
              <GridItem label="Location" value={tcmTask?.finalF2fLocation || '---'} />
              <GridItem label="Name of Provider" value={tcmTask?.finalF2fProvider || '---'} />
              <GridItem label="Notes" value={tcmTask?.finalF2fNotes || '---'} />
            </Grid>

            <Grid item xs={12}>
              <Small sx={{ flex: '1' }}>I&apos;ve reviewed the TCM</Small>
              <Box sx={{ border: '1px solid black', flex: '3', height: 50 }} />
            </Grid>
          </>
        )}
      </Grid>
    </Drawer>
  );
};
