import { Outlet } from 'react-router-dom';

import Requests from '@/routes/Request/requests';

const RequestsSection = () => {
  return <Outlet />;
};

export { Requests };
export default RequestsSection;
