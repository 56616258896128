import { gql } from '@apollo/client';

export const GET_CHAT_NOTIFICATIONS = gql`
  query getRelyingPartyAdminConversations {
    relyingPartyAdminConversations {
      createdAt
      sourceType
      messageBody
      messageDirection
      isRead
      id
      messageTo
      messageFrom
      createdAt
      account {
        id
        truentityId
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export const READ_ALL_NOTIFICATIONS = gql`
  mutation UpdateAdminAllMessagesReadStatus {
    updateAdminAllMessagesReadStatus {
      status
      message
    }
  }
`;

export const ON_RELYING_PARTY_ADMIN_CONVERSATIONS = gql`
  subscription OnRelyingPartyAdminConversations {
    relyingPartyAdminConversations {
      relyingPartyAdminMessages {
        createdAt
        sourceType
        messageBody
        messageDirection
        isRead
        id
        messageTo
        messageFrom
        createdAt
        account {
          id
          truentityId
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
