import MuiTabs, { TabContent } from '@/components/MuiTabs';
import { Body1, H4 } from '@/components/Typography';
import styled from '@/styles';
import { MedicationInformation } from '@/types/medication';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

const MEDICATION_INFO_CATEGORIES = {
  description: ['description'],
  indicationsAndUsage: ['indicationsAndUsage'],
  dosageAndAdministration: ['dosageAndAdministration'],
  informationForPatients: ['informationForPatients'],
  adverseReactions: ['adverseReactions'],
  drugInteractions: ['drugInteractions'],
  contraindications: ['contraindications'],
  boxedWarning: ['boxedWarning'],
  overdosage: ['overdosage'],
  special: ['pregnancy', 'nursingMothers', 'pediatricUse', 'geriatricUse'],
  clinicalPharmacologoy: ['clinicalPharmacologoy']
};

const MEDICATION_INFO_CATEGORY_NAME = {
  description: 'Description',
  indicationsAndUsage: 'Indications and Usage',
  dosageAndAdministration: 'Dosage and Administration',
  informationForPatients: 'Information for Patients',
  adverseReactions: 'Adverse Reactions',
  drugInteractions: 'Drug Interactions',
  contraindications: 'Contraindications',
  boxedWarning: 'Warnings (include Boxed Warnings)',
  overdosage: 'Overdosage',
  special: 'Special',
  clinicalPharmacologoy: 'Clinical Pharmacology',
  pregnancy: 'Pregnancy',
  nursingMothers: 'Nursing Mothers',
  pediatricUse: 'Pediatric Use',
  geriatricUse: 'Geriatric Use'
};

const getCategoryName = key => {
  return MEDICATION_INFO_CATEGORY_NAME[key] || key;
};

const SubCategory = styled('div', {
  display: 'block',
  paddingBottom: '15px',

  '& h4': {
    marginBottom: '10px'
  }
});

export type MedicationInfoProps = {
  medication: MedicationInformation;
};

const tryParseJSON = data => {
  try {
    return JSON.parse(data);
  } catch (error) {}
  return '';
};

const MedicationInfoTabs = ({ medication }: MedicationInfoProps) => {
  const [parsedCategories, setParsedCategories] = useState<any[]>([]);
  const [tabDefinitions, setTabDefinitions] = useState<TabContent[]>([]);
  const [firstIndex, setFirstIndex] = useState(0);

  const getTabDefinitionsFromObj = categories => {
    return categories.map((cat, index) => {
      return {
        label: cat.name,
        index,
        disabled: cat.content?.reduce((acc, currentVal) => acc + (currentVal.length || 0), 0) === 0,
        children: (
          <Stack>
            {cat.content.length > 1 ? (
              cat.content.map(({ name, content }, index) => (
                <SubCategory key={index}>
                  <H4>{name}</H4>
                  <Body1 sx={{ marginTop: '20px' }}>{content}</Body1>
                </SubCategory>
              ))
            ) : (
              <>
                <H4>{cat.name}</H4>
                <Body1 sx={{ marginTop: '20px' }}>{cat.content[0]}</Body1>
              </>
            )}
          </Stack>
        )
      };
    });

    // return obj.map((v, index) => {

    //     return {
    //         index,
    //         a11yLabel: v.name,
    //         label: v.name,
    //         disabled: v.content?.reduce((acc, currentVal) => acc + (currentVal.length || 0), 0) === 0
    //     }
    // })
  };

  useEffect(() => {
    const categoryEntries = Object.entries(MEDICATION_INFO_CATEGORIES);
    const parsedCategories: any[] = [];

    for (const [key, value] of categoryEntries) {
      const categoryName = getCategoryName(key);
      const categoryContent: any[] = [];

      if (value.length === 1) {
        if (medication[value[0]]) {
          categoryContent.push([tryParseJSON(medication[value[0]])]);
        }
      } else {
        value.forEach(v => {
          categoryContent.push({
            name: getCategoryName(v),
            content: tryParseJSON(medication[v])
          });
        });
      }

      parsedCategories.push({ name: categoryName, content: categoryContent });
    }

    setParsedCategories(parsedCategories);
  }, []);

  useEffect(() => {
    if (parsedCategories?.length > 0) {
      const tabDefinitions = getTabDefinitionsFromObj(parsedCategories);
      setTabDefinitions(tabDefinitions);

      const firstEnabledTab = tabDefinitions?.find(v => v.disabled === false);
      if (firstEnabledTab) {
        setFirstIndex(firstEnabledTab.index);
      }
    }
  }, [parsedCategories]);

  return <MuiTabs tabOrientation="vertical" tabs={tabDefinitions} preSelectedTabIndex={firstIndex} />;
};

export default MedicationInfoTabs;
