/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
///TODO: https://app.clickup.com/t/864duy9rz

import React from 'react';

import type { PatientsData } from '@/routes/Patients/patients';
import type { AccountCareEvent } from '@/types/dischargePatient';
import type { TaskType } from '@/types/graphql';
import type { Medication } from '@/types/medication';

export type PatientDetailContextType = {
  medications: Medication[];
  medicationSelected: Medication | undefined;
  handleGoToNewRequest: () => void;
  lastRequestCreated?: string | null;
  currentOption: string | null;
  setCurrentOption: (medicationId: string) => void;
  patientId: string | undefined;
  handleNewMedRec: (success: boolean) => void;
  s3_key: string | null;
  setS3_key: (key: string) => void;
  setMedicationSelected: (medication: Medication) => void;
  reloadMeds: () => void;
  goDetailsPage: () => void;
  patientInfo: PatientsData | null;
  setPatientInfo: (val: object) => void;
  encounters: object[];
  reloadEncounters: () => void;
  tasks: TaskType[];
  tasksTotalCount: number;
  reloadTasks: () => void;
  goToListDetailsPage: () => void;
  goToCurrentEncounterDetailsPage: (id: string) => void;
  taskDrawerInfo: TaskType;
  setTaskDrawerInfo: (val: TaskType) => void;
  reloadPatientInfo: boolean;
  setReloadPatientInfo: (val) => void;
  resettingMeds: boolean;
  setResettingMeds: (val: boolean) => void;
  accountCareEvents: AccountCareEvent[];
  setAccountCareEvents: (val: AccountCareEvent[]) => void;
  setSelectedAdtEvent: (id: string) => void;
  selectedAdtEvent: string | null;
  goToCurrentDischargeSummaryPage: (id: string) => void;
  fetchTasksInPatientContextLocally: boolean;
  setFetchTasksInPatientContextLocally: (val: boolean) => void;
  callGetTasks: ({ truentityId, currentPage, taskStatus, selectedOrgId, selectedClientStoreId }) => void;
};

const initialValue: PatientDetailContextType = {
  medications: [],
  medicationSelected: {} as Medication,
  handleGoToNewRequest: () => {},
  lastRequestCreated: null,
  currentOption: '',
  setCurrentOption: medicationId => {},
  patientId: undefined,
  handleNewMedRec: success => {},
  s3_key: '',
  setS3_key: () => {},
  setMedicationSelected: (medication: Medication) => {},
  reloadMeds: () => {},
  goDetailsPage: () => {},
  patientInfo: null,
  setPatientInfo: (val: object) => {},
  encounters: [],
  reloadEncounters: () => {},
  tasks: [],
  tasksTotalCount: 0,
  reloadTasks: () => {},
  goToListDetailsPage: () => {},
  goToCurrentEncounterDetailsPage: (id: string) => {},
  taskDrawerInfo: {} as TaskType,
  setTaskDrawerInfo: (val: object) => {},
  reloadPatientInfo: false,
  setReloadPatientInfo: val => {},
  resettingMeds: false,
  setResettingMeds: (val: boolean) => {},
  accountCareEvents: [],
  setAccountCareEvents: (val: AccountCareEvent[]) => {},
  setSelectedAdtEvent: id => {},
  selectedAdtEvent: '',
  goToCurrentDischargeSummaryPage: (id: string) => {},
  fetchTasksInPatientContextLocally: false,
  setFetchTasksInPatientContextLocally: (val: boolean) => {},
  callGetTasks: (val: any) => {}
};

const PatientDetailContext = React.createContext<PatientDetailContextType>(initialValue);

export default PatientDetailContext;
