import Button from '@/components/Button';
import { DialogTypes } from '@/components/Dialogs/MedicationReconciliationDetailDialog';
import { Body1, H3 } from '@/components/Typography';
import { theme } from '@/styles/mui-theme';
import { medicationType } from '@/types/remotePatientMonitoring';
import { Divider, Paper, Stack } from '@mui/material';

type Props = {
  medicationDetails: medicationType;
  switchToModal: (modalType: DialogTypes) => void;
};
const MedicationNotePreview = ({ medicationDetails, switchToModal }: Props) => {
  return (
    <Paper elevation={0} sx={{ background: theme.palette.background.default, py: theme.spacing(2), px: theme.spacing(3) }}>
      <H3>{medicationDetails.title}</H3>
      <Divider sx={{ py: theme.spacing(1) }} />
      <Stack py={1} minHeight={450}>
        {!medicationDetails.note ? (
          <Stack m="auto" justifyContent="center" alignItems="center">
            <H3 sx={{ pb: theme.spacing(2) }}>No notes available</H3>
            <Button variant="contained" disableElevation fullWidth={false} onClick={() => switchToModal(DialogTypes.FORM)}>
              Add new note
            </Button>
          </Stack>
        ) : (
          <Body1 fontSize={'1rem'}>{medicationDetails.note}</Body1>
        )}
      </Stack>
    </Paper>
  );
};

export default MedicationNotePreview;
