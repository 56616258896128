import { BarChart, LineChart, PieChart } from '@/components/Charts/charts';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H2 } from '@/components/Typography';
import {
  GET_METRICS,
  GET_PATIENTS_BY_ORG,
  GET_PATIENTS_REPORTING,
  GET_TASKS_BY_TIP_SUB_TYPES,
  GET_TASKS_BY_WRAP_UP_STATUS
} from '@/graphql/analytics';
import { useLazyQuery, useQuery } from '@apollo/client';
import GroupIcon from '@mui/icons-material/Group';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MedicationIcon from '@mui/icons-material/Medication';
import PhotoIcon from '@mui/icons-material/Photo';
import {
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { Moment } from 'moment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { KpiGridItem } from '../../components/KpiComponents/KpiGridItem';
import { KpiSection } from '../../components/KpiComponents/KpiSection';

import DashboardSection from '@/components/Dashboard/DashboardSection';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import LoadingOverlay from '@/components/LoadingOverlay';
import { GET_RELYING_PARTY_PHARMACISTS } from '@/graphql/administration';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { TASK_WRAP_UP_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';

// export type BaseKpiSectionProps = CardProps & {
//   label: ReactNode;
//   value: number;
//   icon?: ReactNode;
// };
//
// export type KpiRange = {
//   matches: (val) => boolean;
//   backgroundColor: string;
//   iconColor: string;
// };

type AnalyticsMetrics = {
  totalNumOfPatients: number;
  avgPatientAge: number;
  totalNumOfImports: number;
  totalNumMedsImported: number;
  totalNumMedsPostcmr: number;
  avgNumMedsPerPatient: number;
};

type PatientsByLocation = {
  name: string;
  totalPatients: number;
};

type PatientsByOrganization = {
  name: string;
  totalPatients: number;
  patientsByGender: { gender: string; total: number }[];
  locations: PatientsByLocation[];
};

const getPatientsInYear = (data: PatientsByYear[], year: number): PatientSparklineData[] => {
  const filter = data.find((v: PatientsByYear) => v.year === year);
  return (
    filter?.patientsByMonth.map((v, index) => {
      const patientsInMonth = getPatientsInMonth(data, year, index);

      return {
        index: index + 1,
        count: patientsInMonth[patientsInMonth.length - 1].count,
        importedMeds: patientsInMonth[patientsInMonth.length - 1].importedMeds,
        avgMedsPerPatient: patientsInMonth[patientsInMonth.length - 1].avgMedsPerPatient
      };
    }) || []
  );
};

const getPatientsInMonth = (data: PatientsByYear[], year: number, monthIndex: number): PatientSparklineData[] => {
  const filter = data.find((v: PatientsByYear) => v.year === year);
  return filter?.patientsByMonth[monthIndex]!;
};

type PatientSparklineData = {
  index: number;
  count: number;
  importedMeds: number;
  avgMedsPerPatient: number;
};

type PatientsByYear = {
  year: number;
  patientsByMonth: PatientSparklineData[][];
};

const getDummyPatientsDataByMonth = (start: number): PatientSparklineData[][] => {
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const patientsDataByMonth: PatientSparklineData[][] = [];
  let totalCount = start;

  daysInMonth.forEach((daysInMonth, dIndex) => {
    totalCount = 0;

    const data: PatientSparklineData[] = [];

    for (let i = 1; i <= daysInMonth; i++) {
      data.push({ index: i, count: totalCount++, importedMeds: i, avgMedsPerPatient: dIndex % 2 === 0 ? 2 : 5 });
    }

    patientsDataByMonth.push(data);
  });

  return patientsDataByMonth;
};

const getYearMinMax = (data: PatientsByYear[]): { min: Moment; max: Moment } => {
  const sorted = dummyNumberOfPatients.map(v => v.year).sort();
  return {
    min: moment(sorted[0], 'YYYY'),
    max: moment(sorted[sorted.length - 1], 'YYYY')
  };
};

const dummyNumberOfPatients: PatientsByYear[] = [
  {
    year: 2020,
    patientsByMonth: getDummyPatientsDataByMonth(0)
  },
  {
    year: 2021,
    patientsByMonth: getDummyPatientsDataByMonth(365)
  },
  {
    year: 2022,
    patientsByMonth: getDummyPatientsDataByMonth(730)
  }
];

const dummyData: PatientsByOrganization[] = [
  {
    name: 'Org 1',
    totalPatients: 200,
    patientsByGender: [
      { gender: 'Male', total: 100 },
      { gender: 'Female', total: 100 },
      { gender: 'Other', total: 0 }
    ],
    locations: [
      {
        name: 'Org1:Store1',
        totalPatients: 150
      },
      {
        name: 'Org1:Store2',
        totalPatients: 30
      },
      {
        name: 'Org1:Store3',
        totalPatients: 20
      }
    ]
  },
  {
    name: 'Org 2',
    totalPatients: 100,
    patientsByGender: [
      { gender: 'Male', total: 10 },
      { gender: 'Female', total: 89 },
      { gender: 'Other', total: 1 }
    ],
    locations: [
      {
        name: 'Org2:Store1',
        totalPatients: 50
      },
      {
        name: 'Org2:Store2',
        totalPatients: 50
      }
    ]
  },
  {
    name: 'Org 3',
    totalPatients: 1000,
    patientsByGender: [
      { gender: 'Male', total: 900 },
      { gender: 'Female', total: 50 },
      { gender: 'Other', total: 50 }
    ],
    locations: [
      {
        name: 'Org3:Store1',
        totalPatients: 1000
      }
    ]
  }
];

const ReportDashboard = () => {
  const theme = useTheme();

  const { roleType } = useToken();
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);

  const pharmacistDefaults: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];

  const { data } = useQuery(GET_METRICS);

  const [getTasksByTipData, { refetch: refetchTasksByTipData, data: tasksByTipData, loading: tasksByTipLoading }] =
    useLazyQuery(GET_TASKS_BY_TIP_SUB_TYPES);
  const [
    getTasksByWrapUpStatusData,
    { refetch: refetchTasksByWrapUpStatusData, data: tasksByWrapUpStatusData, loading: tasksByWrapUpStatusLoading }
  ] = useLazyQuery(GET_TASKS_BY_WRAP_UP_STATUS);
  const { data: patientsByOrgData } = useQuery(GET_PATIENTS_BY_ORG);

  const [getUsers, usersResult] = useLazyQuery(GET_RELYING_PARTY_PHARMACISTS);

  const [getPatientsReporting, { refetch: refetchPatientsReporting, data: patientsReportingData, loading: patientsReportingLoading }] =
    useLazyQuery(GET_PATIENTS_REPORTING);
  const [patientsReportsData, setPatientsReportsData] = useState([]);
  const [patientsReportsDataByDay, setpatientsReportsDataByDay] = useState([]);

  const [relyingPartyAdmins, setRelyingPartyAdmins] = useState([]);
  const [selectedRelyingPatyAdmin, setSelectedRelyingPatyAdmin] = useState<string>('all');

  const [totalTWrapUpStatusTasksCount, setTotalTWrapUpStatusTasksCount] = useState(0);
  const [tasksByWrapUpStatusTypes, setTasksByWrapUpStatusTypes] = useState([]);
  const [relyingPartyAdminsByWrapUpStatus, setRelyingPartyAdminsByWrapUpStatus] = useState([]);
  const [selectedWrapUpStatus, setSelectedWrapUpStatus] = useState(null);

  const [totalTIPTasksCount, setTotalTIPTasksCount] = useState(0);
  const [tasksByTipSubTypes, setTasksByTipSubTypes] = useState([]);
  const [relyingPartyAdminsBySubType, setRelyingPartyAdminsBySubType] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState(null);

  const [patientsByOrg, setPatientsByOrg] = useState<PatientsByOrganization[]>();
  const [patientsByLocation, setPatientsByLocation] = useState<PatientsByLocation[]>([]);
  const [selectedOrg, setSelectedOrg] = useState<PatientsByOrganization | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isAnalyticsDataLoading, setIsAnalyticsDataLoading] = useState(true);

  const today = new Date();
  const firstDatePrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastDatePrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const [startDate, setStartDate] = useState<Date | null>(firstDatePrevMonth);
  const [endDate, setEndDate] = useState<Date | null>(lastDatePrevMonth);

  const [metrics, setMetrics] = useState<AnalyticsMetrics>({
    avgNumMedsPerPatient: 0,
    avgPatientAge: 0,
    totalNumMedsImported: 0,
    totalNumMedsPostcmr: 0,
    totalNumOfImports: 0,
    totalNumOfPatients: 0
  });

  const months = [
    'ALL',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
  const [totalPatientsCategories, setTotalPatientsCategories] = useState<PatientSparklineData[]>([]);
  const [totalPatientsByYear, setTotalPatientsByYear] = useState(true);

  const [yearMinMax] = useState(getYearMinMax(dummyNumberOfPatients));

  useEffect(() => {
    getTasksByTipData({
      variables: {
        filterOptions: {
          relyingPartyAdminId: '',
          performedOnStartDate: startDate ? moment(startDate).local().hours(0).minutes(0).seconds(0) : undefined,
          performedOnEndDate: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined
        }
      }
    })
      .then(res => {
        setIsAnalyticsDataLoading(false);
      })
      .catch(err => {
        setIsAnalyticsDataLoading(false);
      });

    getTasksByWrapUpStatusData({
      variables: {
        filterOptions: {
          relyingPartyAdminId: '',
          performedOnStartDate: startDate ? moment(startDate).local().hours(0).minutes(0).seconds(0) : undefined,
          performedOnEndDate: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined
        }
      }
    })
      .then(res => {
        setIsAnalyticsDataLoading(false);
      })
      .catch(err => {
        setIsAnalyticsDataLoading(false);
      });

    getUsers({
      variables: {
        pageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, []);

  useEffect(() => {
    if (!tasksByTipLoading) {
      setIsLoading(false);
    }
  }, [tasksByTipLoading, tasksByTipData]);

  useEffect(() => {
    if (!tasksByWrapUpStatusLoading) {
      setIsLoading(false);
    }
  }, [tasksByWrapUpStatusLoading, tasksByWrapUpStatusData]);

  useEffect(() => {
    if (selectedRelyingPatyAdmin) {
      let relyingPartyAdminId = '';
      if (selectedRelyingPatyAdmin !== 'all') {
        relyingPartyAdminId = selectedRelyingPatyAdmin;
      }
      getTasksByTipData({
        variables: {
          filterOptions: {
            relyingPartyAdminId: relyingPartyAdminId,
            performedOnStartDate: startDate ? moment(startDate).local().hours(0).minutes(0).seconds(0) : undefined,
            performedOnEndDate: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined
          }
        }
      })
        .then(res => {
          setIsAnalyticsDataLoading(false);
        })
        .catch(err => {
          setIsAnalyticsDataLoading(false);
        });

      getTasksByWrapUpStatusData({
        variables: {
          filterOptions: {
            relyingPartyAdminId: relyingPartyAdminId,
            performedOnStartDate: startDate ? moment(startDate).local().hours(0).minutes(0).seconds(0) : undefined,
            performedOnEndDate: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined
          }
        }
      })
        .then(res => {
          setIsAnalyticsDataLoading(false);
        })
        .catch(err => {
          setIsAnalyticsDataLoading(false);
        });
    }
  }, [selectedRelyingPatyAdmin, startDate, endDate]);

  useEffect(() => {
    if (usersResult.data?.relyingPartyAdminsAll?.relyingPartyAdmins) {
      const pharmacists = [];
      usersResult.data?.relyingPartyAdminsAll?.relyingPartyAdmins.filter(admin => {
        if (admin.roleType === 'PHARMACIST') {
          pharmacists.push(admin);
        }
      });
      setRelyingPartyAdmins([...pharmacistDefaults, ...pharmacists]);
    }
  }, [usersResult.data?.relyingPartyAdminsAll?.relyingPartyAdmins]);

  useEffect(() => {
    if (patientsByOrgData?.patientsByOrgReporting) {
      if (patientsByOrgData?.patientsByOrgReporting?.patientsByOrgReports.length > 0) {
        setPatientsByOrg(patientsByOrgData?.patientsByOrgReporting?.patientsByOrgReports);
      }
    }
  }, [patientsByOrgData]);

  useEffect(() => {
    if (patientsReportingData?.patientsByYearMonthDay) {
      if (patientsReportingData?.patientsByYearMonthDay?.patientsReports.length > 0) {
        if (selectedMonthIndex > 0) {
          setpatientsReportsDataByDay(patientsReportingData?.patientsByYearMonthDay?.patientsReports);
          setPatientsReportsData([]);
        } else {
          setPatientsReportsData(patientsReportingData?.patientsByYearMonthDay?.patientsReports);
          setpatientsReportsDataByDay([]);
        }
      }
    }
  }, [patientsReportingData, selectedMonthIndex]);

  useEffect(() => {
    if (tasksByTipData?.tipOverSubTypesTasksReporting) {
      if (tasksByTipData?.tipOverSubTypesTasksReporting?.tasksReports.length > 0) {
        const totalTIPDateCount = tasksByTipData?.tipOverSubTypesTasksReporting?.tasksReports.reduce(function (acc, obj) {
          return acc + obj.count;
        }, 0);

        setTotalTIPTasksCount(totalTIPDateCount);
        setTasksByTipSubTypes(tasksByTipData?.tipOverSubTypesTasksReporting?.tasksReports);
      } else {
        setTotalTIPTasksCount(0);
        setTasksByTipSubTypes([]);
        setRelyingPartyAdminsBySubType([]);
      }
    }
  }, [tasksByTipData]);

  useEffect(() => {
    if (tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting) {
      if (tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting?.tasksReports.length > 0) {
        const totalCount = tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting?.tasksReports.reduce(function (acc, obj) {
          return acc + obj.count;
        }, 0);

        setTotalTWrapUpStatusTasksCount(totalCount);
        setTasksByWrapUpStatusTypes(tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting?.tasksReports);
      } else {
        setTotalTWrapUpStatusTasksCount(0);
        setTasksByWrapUpStatusTypes([]);
        setRelyingPartyAdminsByWrapUpStatus([]);
      }
    }
  }, [tasksByWrapUpStatusData]);

  useEffect(() => {
    if (data?.analyticsGet) {
      setMetrics(data?.analyticsGet);
    }
  }, [data]);

  useEffect(() => {
    if (patientsByOrg?.length > 0) {
      setPatientsByLocation(patientsByOrg[0].locations);
    }
  }, [patientsByOrg]);

  useEffect(() => {
    if (selectedOrg?.locations) {
      setPatientsByLocation(selectedOrg?.locations);
    }
  }, [selectedOrg]);

  useEffect(() => {
    if (selectedSubType?.relyingPartyAdmins) {
      setRelyingPartyAdminsBySubType(selectedSubType?.relyingPartyAdmins);
    }
  }, [selectedSubType, selectedRelyingPatyAdmin]);

  useEffect(() => {
    if (selectedWrapUpStatus?.relyingPartyAdmins) {
      setRelyingPartyAdminsByWrapUpStatus(selectedWrapUpStatus?.relyingPartyAdmins);
    }
  }, [selectedWrapUpStatus, selectedRelyingPatyAdmin]);

  const selectOrgByIndex = (index: number) => {
    const org = patientsByOrg[index];
    if (org) {
      setSelectedOrg(org);
    }
  };

  const onSliceSelected = (event, chartContext, config) => {
    selectOrgByIndex(config.dataPointIndex);
  };

  useEffect(() => {
    if (selectedMonthIndex > 0) {
      //patients-for-month
      const patientsInMonth = getPatientsInMonth(dummyNumberOfPatients, selectedYear, selectedMonthIndex - 1);
      setTotalPatientsCategories(patientsInMonth);
      setTotalPatientsByYear(false);

      getPatientsReporting({
        variables: {
          year: selectedYear,
          month: months[selectedMonthIndex]
        }
      });
    } else {
      //patients-for-year
      const patientsForYear = getPatientsInYear(dummyNumberOfPatients, selectedYear);
      setTotalPatientsCategories(patientsForYear);
      setTotalPatientsByYear(true);

      getPatientsReporting({
        variables: {
          year: selectedYear,
          month: 'ALL'
        }
      });
    }
  }, [selectedYear, selectedMonthIndex]);

  const seletTaskSubTypeByIndex = (index: number) => {
    const selectedSubType = tasksByTipSubTypes[index];
    if (selectedSubType) {
      setSelectedSubType(selectedSubType);
    }
  };

  const onTaskSubTypeSliceSelected = (event, chartContext, config) => {
    seletTaskSubTypeByIndex(config.dataPointIndex);
  };

  const seletTaskWrapUpStatusTypeByIndex = (index: number) => {
    const selectedSubType = tasksByWrapUpStatusTypes[index];
    if (selectedSubType) {
      setSelectedWrapUpStatus(selectedSubType);
    }
  };

  const onTaskWrapUpStatusSliceSelected = (event, chartContext, config) => {
    seletTaskWrapUpStatusTypeByIndex(config.dataPointIndex);
  };

  return (
    <Paper component={Stack} sx={{ backgroundColor: 'transparent' }} elevation={0} spacing={2}>
      <LoadingOverlay active={isLoading} text="Please wait. Loading Analytics Data..." />

      <DashboardSection title={'Metrics'}>
        <Grid container>
          <KpiGridItem>
            <KpiSection label="Total # of Patients" value={metrics.totalNumOfPatients} icon={<GroupIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="Total # of Imports" value={metrics.totalNumOfImports} icon={<ImportExportIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="Total # of Meds Imported" value={metrics.totalNumMedsImported} icon={<MedicationIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="Total # of Meds Post-CMR" value={metrics.totalNumMedsPostcmr} icon={<PhotoIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="Average Patient Age" value={metrics.avgPatientAge} icon={<GroupIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="Average # Meds / Patient" value={metrics.avgNumMedsPerPatient} icon={<MedicationIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="TBD" value={0} icon={<PhotoIcon fontSize="large" />} />
          </KpiGridItem>
          <KpiGridItem>
            <KpiSection label="TBD" value={0} icon={<PhotoIcon fontSize="large" />} />
          </KpiGridItem>
        </Grid>
      </DashboardSection>

      <DashboardSection>
        <Paper component={Stack} elevation={1} direction={'row'} spacing={0} sx={{ padding: '15px' }}>
          <Grid container spacing={1} pb={1}>
            <Grid item xs={2}>
              <TruentityDatePicker
                disableFuture={true}
                minDate={yearMinMax.min}
                maxDate={yearMinMax.max}
                views={['year']}
                label="Year"
                value={yearMinMax.max}
                onChange={(value: Moment | null) => setSelectedYear(value!.year())}
              />
            </Grid>

            <Grid item xs={2}>
              <SelectList
                fullWidth={false}
                formControlProps={{ sx: { width: 200 } }}
                label="Months"
                options={months.map(
                  (month: string, index: number) =>
                    ({
                      value: index.toString(),
                      label: month
                    } as TextSelectOption)
                )}
                value={selectedMonthIndex.toString()}
                onChange={(event: any) => setSelectedMonthIndex(event.target.value)}
              />
            </Grid>
          </Grid>
        </Paper>

        <Grid container>
          {patientsReportsData.length > 0 && (
            <Grid item xs={12}>
              <LineChart
                subTitle={`Avg Meds Per Patient`}
                height={500}
                width={550}
                series={[
                  {
                    name: 'Patients',
                    data: patientsReportsData.map(v => v.count)
                  }
                ]}
                options={{
                  xaxis: {
                    categories: patientsReportsData.map(v => v.month)
                  },
                  chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                      enabled: false
                    }
                  }
                }}
              />
            </Grid>
          )}

          {patientsReportsDataByDay.length > 0 && (
            <Grid item xs={12}>
              <LineChart
                subTitle={`Avg Meds Per Patient`}
                height={500}
                width={550}
                series={[
                  {
                    name: 'Guests',
                    data: patientsReportsDataByDay[0].days.map(v => v.count)
                  }
                ]}
                options={{
                  xaxis: {
                    categories: patientsReportsDataByDay[0].days.map(v => v.day)
                  },
                  chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                      enabled: false
                    }
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </DashboardSection>

      <DashboardSection title={'Patients Analytics'}>
        <Grid container>
          <Grid item xs={12} md={6}>
            {patientsByOrg && patientsByOrg.length > 0 && (
              <PieChart
                title={'Patients By Client Org'}
                width={480}
                series={patientsByOrg.map(v => v.totalPatients)}
                options={{
                  labels: patientsByOrg.map(v => v.name),
                  chart: {
                    events: {
                      dataPointSelection: onSliceSelected,
                      mounted: chartCtx => chartCtx.toggleDataPointSelection(0)
                    }
                  }
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <BarChart
              title={'Patients By Client Location'}
              width={500}
              options={{
                chart: {
                  id: 'dist-by-org'
                },
                xaxis: {
                  categories: patientsByLocation.map(v => v.name)
                },
                plotOptions: {
                  bar: {
                    horizontal: true
                  }
                }
              }}
              series={[
                {
                  name: 'Patients by Client Location',
                  data: patientsByLocation.map(v => v.totalPatients)
                }
              ]}
            />
          </Grid>

          {selectedOrg && (
            <Grid item xs={12} md={6}>
              <PieChart
                title={'Gender By Org'}
                width={480}
                series={selectedOrg?.patientsByGender?.map(v => v.total)}
                options={{
                  labels: selectedOrg?.patientsByGender?.map(v => v.gender)
                }}
              />
            </Grid>
          )}
        </Grid>
      </DashboardSection>

      <DashboardSection title={'Tasks Analytics'}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={'flex-start'}>
              <TruentityDatePicker
                label="Start Date"
                fullWidth={false}
                sx={{ width: 400, marginRight: 1 }}
                value={startDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setIsAnalyticsDataLoading(true);
                  setStartDate(date as Date);
                }}
              />

              <TruentityDatePicker
                label="End Date"
                fullWidth={false}
                sx={{ width: 400 }}
                value={endDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setIsAnalyticsDataLoading(true);
                  setEndDate(date as Date);
                }}
              />

              {isAnalyticsDataLoading && (
                <Grid item sx={{ margin: 'auto 0' }}>
                  <CircularProgress size={22} color="primary" sx={{ marginLeft: 2 }} />
                </Grid>
              )}
            </Stack>
          </Grid>

          {(isPharmacistAdmin || isSuperAdmin) && (
            <Grid item xs={12}>
              <FormControl sx={{ width: 400, marginRight: 1, marginBottom: 1 }} variant="outlined" margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Pharmacists</InputLabel>

                <Select
                  labelId={'pharmacists'}
                  label={'Pharmacists'}
                  placeholder={'select an Pharmacist'}
                  value={selectedRelyingPatyAdmin}
                  onChange={event => {
                    setIsAnalyticsDataLoading(true);
                    setSelectedRelyingPatyAdmin(event.target.value);
                  }}
                >
                  {relyingPartyAdmins &&
                    relyingPartyAdmins.length > 0 &&
                    relyingPartyAdmins.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {tasksByTipSubTypes && tasksByTipSubTypes.length > 0 && (
            <Grid item xs={12} md={6}>
              <PieChart
                title={'Completion % by TIP Types'}
                width={480}
                series={tasksByTipSubTypes.map(v => v.count)}
                options={{
                  labels: tasksByTipSubTypes.map(v => UPDATED_SUB_TASK_TYPES.filter(type => type.value === v.subType)[0].label),
                  chart: {
                    events: {
                      dataPointSelection: onTaskSubTypeSliceSelected,
                      mounted: chartCtx => chartCtx.toggleDataPointSelection(0)
                    }
                  },
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            </Grid>
          )}

          {tasksByTipSubTypes && tasksByTipSubTypes.length === 0 && (
            <Grid item xs={12} md={12} pb={4} pt={2} sx={{ textAlign: 'center' }}>
              <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <CardContent component={Stack} justifyContent={'space-evenly'} sx={{ padding: '16px', flex: '1', minHeight: '100px' }}>
                  <H2>No TIP tasks completed for this time range</H2>
                </CardContent>
              </Card>
            </Grid>
          )}
          {selectedRelyingPatyAdmin === 'all' && relyingPartyAdminsBySubType.length > 0 && (
            <Grid item xs={12} md={6}>
              <BarChart
                title={
                  selectedSubType?.subType
                    ? `TIP Type: ${UPDATED_SUB_TASK_TYPES.filter(type => type.value === selectedSubType?.subType)[0].label}, by Users`
                    : ''
                }
                width={500}
                options={{
                  chart: {
                    id: 'dist-by-org'
                  },
                  xaxis: {
                    categories: relyingPartyAdminsBySubType.map(v => v.name)
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true
                    }
                  }
                }}
                series={[
                  {
                    name: 'Patients by Client Location',
                    data: relyingPartyAdminsBySubType.map(v => v.count)
                  }
                ]}
              />
            </Grid>
          )}

          {tasksByTipSubTypes && tasksByTipSubTypes.length > 0 && (
            <Grid
              item
              xs={12}
              md={selectedRelyingPatyAdmin === 'all' ? 12 : 6}
              sx={selectedRelyingPatyAdmin === 'all' ? {} : { margin: 'auto' }}
              pb={selectedRelyingPatyAdmin === 'all' ? 8 : 0}
            >
              <TableContainer
                component={Paper}
                style={{ maxHeight: 300 }}
                sx={{
                  width: selectedRelyingPatyAdmin === 'all' ? '40%' : '75%',
                  marginLeft: selectedRelyingPatyAdmin === 'all' ? 2 : 0
                }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>TIP Type</TableCell>
                      <TableCell align="center">Count</TableCell>
                      <TableCell align="center">Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksByTipSubTypes.map(task => {
                      return (
                        <TableRow key={task.id}>
                          <TableCell>{UPDATED_SUB_TASK_TYPES.filter(type => type.value === task.subType)[0].label}</TableCell>
                          <TableCell align="center">{task.count}</TableCell>
                          <TableCell align="center">{((task.count / totalTIPTasksCount) * 100).toFixed(2)} % </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}

          {tasksByWrapUpStatusTypes && tasksByWrapUpStatusTypes.length > 0 && (
            <Grid item xs={12} md={6}>
              <PieChart
                title={'Completion % by Resolution Types'}
                width={480}
                series={tasksByWrapUpStatusTypes.map(v => v.count)}
                options={{
                  labels: tasksByWrapUpStatusTypes.map(v => TASK_WRAP_UP_TYPES.filter(type => type.value === v.wrapUpStatus)[0].label),
                  chart: {
                    events: {
                      dataPointSelection: onTaskWrapUpStatusSliceSelected,
                      mounted: chartCtx => chartCtx.toggleDataPointSelection(0)
                    }
                  },
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            </Grid>
          )}

          {selectedRelyingPatyAdmin === 'all' && relyingPartyAdminsByWrapUpStatus.length > 0 && (
            <Grid item xs={12} md={6}>
              <BarChart
                title={
                  selectedWrapUpStatus?.wrapUpStatus
                    ? `Resolution Type: ${
                        TASK_WRAP_UP_TYPES.filter(type => type.value === selectedWrapUpStatus?.wrapUpStatus)[0].label
                      }, by Users`
                    : ''
                }
                width={500}
                options={{
                  chart: {
                    id: 'dist-by-org'
                  },
                  xaxis: {
                    categories: relyingPartyAdminsByWrapUpStatus.map(v => v.name)
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true
                    }
                  }
                }}
                series={[
                  {
                    name: 'Patients by Client Location',
                    data: relyingPartyAdminsByWrapUpStatus.map(v => v.count)
                  }
                ]}
              />
            </Grid>
          )}

          {tasksByWrapUpStatusTypes && tasksByWrapUpStatusTypes.length === 0 && (
            <Grid item xs={12} md={12} pb={4} pt={2} sx={{ textAlign: 'center' }}>
              <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <CardContent component={Stack} justifyContent={'space-evenly'} sx={{ padding: '16px', flex: '1', minHeight: '100px' }}>
                  <H2>No Resolution Type tasks completed for this time range</H2>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={selectedRelyingPatyAdmin === 'all' ? 12 : 6}
            sx={selectedRelyingPatyAdmin === 'all' ? {} : { margin: 'auto' }}
            pb={selectedRelyingPatyAdmin === 'all' ? 8 : 0}
          >
            {tasksByWrapUpStatusTypes && tasksByWrapUpStatusTypes.length > 0 && (
              <TableContainer
                style={{ maxHeight: 300 }}
                component={Paper}
                sx={{
                  width: selectedRelyingPatyAdmin === 'all' ? '40%' : '75%',
                  marginLeft: selectedRelyingPatyAdmin === 'all' ? 2 : 0
                }}
              >
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Resolution</TableCell>
                      <TableCell align="center">Count</TableCell>
                      <TableCell align="center">Percentage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksByWrapUpStatusTypes.map(task => {
                      return (
                        <TableRow key={task.id}>
                          <TableCell>{TASK_WRAP_UP_TYPES.filter(type => type.value === task.wrapUpStatus)[0].label}</TableCell>
                          <TableCell align="center">{task.count}</TableCell>
                          <TableCell align="center">{((task.count / totalTWrapUpStatusTasksCount) * 100).toFixed(2)} % </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
        </Grid>
      </DashboardSection>
    </Paper>
  );
};

export default ReportDashboard;
