import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export type Id = string;

export type DraggableLocation = {
  droppableId: Id;
  index: number;
};

export type Dragging = {
  id: Id;
  location: DraggableLocation;
};

export type IdMap = {
  [id: Id]: true;
};

export type Task = {
  id: Id;
  content: any;
};

export type Column = {
  id: Id;
  title: string;
  taskIds: Id[];
};

export type ColumnMap = {
  [columnId: Id]: Column;
};

export type TaskMap = {
  [taskId: Id]: Task;
};

export type Entities = {
  columnOrder: Id[];
  columns: ColumnMap;
  tasks: TaskMap;
};

export type DraggableProvided = any;

export type DraggableStateSnapshot = any;

export type Args = {
  entities: Entities;
  selectedTaskIds: Id[];
  source: DraggableLocation;
  destination: DraggableLocation;
};

export type Result = {
  entities: Entities;
  // a drop operations can change the order of the selected task array
  selectedTaskIds: Id[];
};

export type MedicationStatus = {
  id: Id;
  isCurrent: boolean | null;
};

export const COLUMN_TITLES = {
  // todo: 'To Do',
  current: 'Current Meds',
  previous: 'Prior Meds'
};

export type SortOption = {
  keyName: string;
  ascending: boolean;
  sortFunc: (a: any, b: any) => any;
  icon: IconDefinition;
};
