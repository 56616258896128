import ChipsList from '@/components/ChipsList';
import ActionDropdown from '@/components/DataGrid/ActionDropdown';
import { DataGridToolTip, DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import LabelGroup from '@/components/LabelGroup';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body2 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import Link from '@/elements/Link';
import type { GetAccountsByFilterResponse } from '@/graphql/account';
import { GET_ACCOUNT_BY_FILTER_SIMPLIFIED } from '@/graphql/account';
import type { FilterInputData, FilterTemplate } from '@/graphql/filters';
import { CREATE_FILTER_TEMPLATE, FilterAvailabilityType, FilterTemplateType } from '@/graphql/filters';
import useToken from '@/hooks/useToken';
import { PatientListToolbar } from '@/routes/Patients/PatientListToolbar';
import type { PatientsData } from '@/routes/Patients/patients';
import { Role } from '@/types/admin';
import { getAccountUserFullName } from '@/util/account';
import { toTruentityDateInput } from '@/util/date';
import { formatDate } from '@/util/format';
import type { PatientFilter } from '@/zustand/patientFilterStore';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export function transformToPatientListToolbarFilter(filter: PatientFilter): FilterInputData[] {
  const filterInputData: FilterInputData[] = [];
  if (filter.filter?.onboardedOn) {
    const { start, end } = filter.filter.onboardedOn;

    filterInputData.push({
      filterType: 'onboardedOn',
      operatorType: 'between',
      value: (start || end || '').toString(),
      value2: end?.toString()
    });
  }

  if (filter.filter.organization) {
    filterInputData.push({
      filterType: 'organization',
      operatorType: 'is',
      value: filter.filter.organization
    });
  }
  return filterInputData;
}

export function transformAllToPatientFilter(templates: FilterTemplate[]): PatientFilter[] {
  return templates?.map(t => transformToPatientFilter(t));
}

export function transformToPatientFilter(template: FilterTemplate): PatientFilter {
  const patientFilter: PatientFilter = {
    id: template.id,
    name: template.name,
    description: '',
    shared: false,
    filter: {
      onboardedOn: { start: null, end: null },
      organization: '',
      readmissionRisk: undefined,
      numberOfMeds: undefined
    }
  };

  template.filterTemplateAttributes.forEach(({ filterType, value, value2 }) => {
    switch (filterType) {
      case 'onboardedOn':
        patientFilter.filter.onboardedOn = {
          start: toTruentityDateInput(value),
          end: toTruentityDateInput(value2)
        };
        break;
      case 'organization':
        patientFilter.filter.organization = value;
        break;
      default:
        break;
    }
  });

  return patientFilter;
}

const PatientsList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { setContent } = useContext(SideMenuContext);
  const [patients, setPatients] = useState<PatientsData[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { selectedFilter, setSelectedFilter } = usePatientFilterStore();
  const navigate = useNavigate();
  const { roleType } = useToken();
  const [isAdmin] = useState<boolean>(roleType !== Role.PHARMACIST);

  const [createFilterTemplate] = useMutation(CREATE_FILTER_TEMPLATE);
  // const [updateFilterTemplate] = useMutation(UPDATE_FILTER_TEMPLATE);

  const [getInfoByLookupSimplified, { loading, data, error }] = useLazyQuery<GetAccountsByFilterResponse>(
    GET_ACCOUNT_BY_FILTER_SIMPLIFIED,
    {
      notifyOnNetworkStatusChange: true
    }
  );

  const columns: GridColDef<PatientsData>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          return <Link to={`../../patients/${params.row.truentityId}/details/medications/triage/list`}>{params.value}</Link>;
        },
        flex: 1,
        align: 'left'
      },
      {
        field: 'profile',
        headerName: 'Profile',
        sortable: true,
        valueGetter: params => (params.row.birthDate ? formatDate(new Date(params.row.birthDate.replace(/-/g, '/'))) : ''),
        renderCell: params => {
          return (
            <DataGridToolTip
              title={
                <Stack p={2}>
                  <LabelGroup LabelProps={{ color: 'primary' }} label={'Date of Birth'}>
                    <Body2>{params.value}</Body2>
                  </LabelGroup>
                  {params.row.phone && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Phone'}>
                      <TruentityPhoneNumber value={params.row.phone} />
                    </LabelGroup>
                  )}
                  {/* @2024/04/05 Patient emails hidden */}
                  {/* {params.row.user.email && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Email'}>
                      <Body2>{params.row.user.email}</Body2>
                    </LabelGroup>
                  )} */}
                  {params.row.gender && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Gender'}>
                      <Body2>{params.row.gender}</Body2>
                    </LabelGroup>
                  )}
                  {params.row.address && (
                    <LabelGroup LabelProps={{ color: 'primary' }} label={'Address'}>
                      <Body2>{params.row.address}</Body2>
                    </LabelGroup>
                  )}
                </Stack>
              }
            >
              {params.value}
            </DataGridToolTip>
          );
        },
        flex: 1
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        type: 'string',
        sortable: true,
        flex: 1,
        valueGetter: params => params.value?.orgName
      },
      {
        field: 'currentAccountsAssignments',
        headerName: 'Assigned To',
        sortable: true,
        flex: 1,
        renderCell: params => <ChipsList size={'small'} items={params.value?.map(user => user.relyingPartyAdmin?.name)} />
      },
      {
        field: 'currentMedsCount',
        headerName: 'Number of Meds',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'lastEncounterDate',
        headerName: 'Last Encounter',
        sortable: true,
        flex: 1
      },
      {
        field: 'truentityId',
        headerName: '',
        sortable: false,
        filterable: false,
        flex: 1,
        align: 'center',
        renderCell: params => {
          const id = params.value;
          return (
            <ActionDropdown
              items={[
                {
                  label: 'Details',
                  callback: () => navigate(`../../patients/${id}/details/medications/triage/list`)
                },
                {
                  label: 'Tasks',
                  callback: () => navigate(`../../patients/${id}/details/medications/tasks/list`)
                },
                {
                  label: 'Profile',
                  callback: () => navigate(`../../providers/${id}/manage`)
                }
              ]}
            />
          );
        }
      }
    ],
    [navigate]
  );

  const onSaveFilter = async (filter: PatientFilter) => {
    const filterInputData: FilterInputData[] = transformToPatientListToolbarFilter(filter);

    try {
      await createFilterTemplate({
        variables: {
          name: filter.name,
          category: FilterTemplateType.PatientList,
          availabilityType: isAdmin ? FilterAvailabilityType.Admin : FilterAvailabilityType.Org,
          filterInputData
        }
      });
      enqueueSnackbar('Filter Saved', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to save filter', { variant: 'error' });
    }
  };
  const onSelectPatient = patient => {
    console.warn('selected', patient);
  };

  useEffect(() => {
    setSelectedFilter({
      filter: {
        organization: 'all'
      }
    });
  }, []);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  useEffect(() => {
    getInfoByLookupSimplified({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE,
        filterOptions: {
          startDate: formatDate(selectedFilter?.filter?.onboardedOn?.start, 'YYYY-MM-DD') ?? undefined,
          endDate: formatDate(selectedFilter?.filter?.onboardedOn?.end, 'YYYY-MM-DD') ?? undefined,
          organization:
            selectedFilter?.filter?.organization && selectedFilter?.filter.organization?.length > 0 && selectedFilter?.filter.organization
              ? selectedFilter?.filter?.organization
              : undefined
          //readmission not on api
          //current meds not on api
        },
        relyingPartyAdminId: 'all'
      }
    }).catch(err => console.error(err));
  }, [getInfoByLookupSimplified, selectedFilter?.filter]);

  useEffect(() => {
    const accounts = data?.accountsLookup?.accounts || [];
    if (!accounts) {
      return;
    }
    setPatients(accounts);
  }, [data]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Unable to retrieve patients list', {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    if (selectedFilter?.filter) {
      setCurrentPage(0);
      //need api to load filters
    }
  }, [selectedFilter?.filter]);

  return (
    <Box>
      <Paper component={Stack} direction="column" spacing={2} overflow={'auto'}>
        <TruentityDataGrid
          name={'dg-patients-list'}
          customFilter={<PatientListToolbar patients={patients} onSaveFilter={onSaveFilter} />}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          autoHeight
          rows={patients}
          columns={columns}
          disableRowSelectionOnClick
          loading={loading}
          paginationMode={'client'}
          onRowClick={e => onSelectPatient(e.row as PatientsData)}
        />
      </Paper>
    </Box>
  );
};

export default PatientsList;
