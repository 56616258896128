import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import RpmReadingsReviewDialog from '@/components/Dialogs/RpmReadingsReviewDialog';
import type { TabContent } from '@/components/MuiTabs';
import { TabPanel } from '@/components/MuiTabs';
import TruentityDateFullRangePicker from '@/components/TruentityDateFullRangePicker';
import { Caption, H1, H2 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type {
  GetAccountAlertLimitsResponse,
  GetAccountVitalsDataAvailabilityResponse,
  GetAccountVitalsReadingsResponse,
  ReadingsSummaryType
} from '@/graphql/remotePatientMonitoring';
import {
  GetAccountVitalsReadingsErrors,
  GET_ACCOUNT_ALERT_LIMITS,
  GET_ACCOUNT_VITALS_DATA_AVAILABILITY,
  GET_ACCOUNT_VITALS_READINGS_FOR_PAGE,
  GET_ACCOUNT_VITALS_READINGS_FOR_TABLE
} from '@/graphql/remotePatientMonitoring';
import useDateRange from '@/hooks/useDateRange';
import { color } from '@/styles/assets/colors';
import type { AlertLimitsType, TableReadingsType, TabPanelDataType } from '@/types/remotePatientMonitoring';
import { ActivityLogType, BloodPressureType, UnitType, VitalsHealthTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/client';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RefreshIcon from '@mui/icons-material/Refresh';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Alert, AlertTitle, Box, Grid, IconButton, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BarChartSection from './Components/Vitals/BarChart';
import LineChartSection from './Components/Vitals/LineChart';
import VitalsTableData from './Vitals/VitalsTableData';

const DataReadings: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { patientInfo } = useContext(PatientDetailContext);
  const { showModal } = useModal();

  const [currentPage, setCurrentPage] = useState(0);
  const { startDate, endDate, handleDateChange } = useDateRange({ datesGap: 7 });
  const [value, setValue] = useState<number>(0);
  const [vitalsTableData, setVitalsTableData] = useState<TableReadingsType[]>([]);
  const [vitalsReadingsSummary, setVitalsReadingsSummary] = useState<ReadingsSummaryType>({
    uniqueDaysCount: '0',
    criticallyHigh: 0,
    high: 0,
    normal: 0,
    low: 0,
    criticallyLow: 0
  });
  const [rowCount, setRowCount] = useState(10);
  const [chartXValues, setChartXValues] = useState<string[]>([]);
  const [formattedChartXValues, setFormattedChartXValues] = useState<string[]>([]);
  const [chartYValues, setChartYValues] = useState<number[]>([]);
  const [chartY2Values, setChartY2Values] = useState<number[]>([]);
  const [chartY3Values, setChartY3Values] = useState<number[][]>([[]]);
  const [alertLimits, setAlertLimits] = useState<AlertLimitsType>({
    bloodGlucose: { cLowValue: 0, lowValue: 0, highValue: 0, cHighValue: 0 },
    heartRate: { cLowValue: 0, lowValue: 0, highValue: 0, cHighValue: 0 },
    sysBloodPressure: { cLowValue: 0, lowValue: 0, highValue: 0, cHighValue: 0 },
    diaBloodPressure: { cLowValue: 0, lowValue: 0, highValue: 0, cHighValue: 0 }
  });
  const [selectedTabType, setSelectedTabType] = useState<{ tabType: VitalsHealthTypes; tabUnit: UnitType }>({
    tabType: VitalsHealthTypes.BloodPressure,
    tabUnit: UnitType.BloodPressure
  });
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState<boolean>(false);

  const [tabs, setTabs] = useState<TabContent[]>([]);

  const tabPanelData: TabPanelDataType[] = [
    { index: 0, label: 'Blood Pressure Tab' },
    { index: 1, label: 'Blood Glucose Tab' },
    { index: 2, label: 'Heart Rate Tab' }
  ];

  const { data: vitalsDataAvailability } = useQuery<GetAccountVitalsDataAvailabilityResponse>(GET_ACCOUNT_VITALS_DATA_AVAILABILITY, {
    variables: {
      truentityId: id
    }
  });
  const [
    getVitalsTableQuery,
    { data: vitalsTableQueryData, refetch: vitalsTableQueryRefetch, loading: vitalsTableQueryLoading, error: vitalsTableQueryError }
  ] = useLazyQuery<GetAccountVitalsReadingsResponse>(GET_ACCOUNT_VITALS_READINGS_FOR_TABLE, {
    fetchPolicy: 'cache-and-network'
  });
  const [getVitalsPageQuery, { data: vitalsPageQueryData, refetch: vitalsPageQueryRefetch }] =
    useLazyQuery<GetAccountVitalsReadingsResponse>(GET_ACCOUNT_VITALS_READINGS_FOR_PAGE, {
      fetchPolicy: 'cache-and-network'
    });
  const [getAccountAlertLimits, { data: accountAlertLimitsData }] = useLazyQuery<GetAccountAlertLimitsResponse>(GET_ACCOUNT_ALERT_LIMITS, {
    variables: {
      truentityId: id
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (accountAlertLimitsData?.getAccountAlertLimitsConfigurations) {
      setAlertLimits(accountAlertLimitsData?.getAccountAlertLimitsConfigurations);
    }
  }, [accountAlertLimitsData]);

  useEffect(() => {
    if (id) {
      getAccountAlertLimits({
        variables: {
          truentityId: id
        }
      });
    }
  }, [id]);

  const onVitalsTableDataError = (error: ApolloError) => {
    if (error.message !== GetAccountVitalsReadingsErrors.NO_DATA_READINGS) {
      enqueueSnackbar('Failed to retrieve vitals readings', { variant: 'error' });
    }
    setVitalsTableData([]);
    setRowCount(0);
  };

  const callGetVitalsTable = async () => {
    await getVitalsTableQuery({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        rpmData: {
          healthType: selectedTabType.tabType,
          startDate: startDate,
          endDate: endDate
        },
        truentityId: id
      }
    }).catch((error: ApolloError) => {
      onVitalsTableDataError(error);
    });
  };

  const callGetVitalsPageData = async () => {
    await getVitalsPageQuery({
      variables: {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: 1,
        rpmData: {
          healthType: selectedTabType.tabType,
          startDate: startDate,
          endDate: endDate
        },
        truentityId: id
      }
    }).catch((error: ApolloError) => {
      onVitalsTableDataError(error);
    });
  };

  const handleTabChange = (_event: SyntheticEvent, selectedTabIndex: number): void => {
    handleChange(selectedTabIndex);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);

    const selectedTabLabel = tabs[newValue]?.label;

    if (selectedTabLabel === 'Blood Pressure') {
      setSelectedTabType({
        tabType: VitalsHealthTypes.BloodPressure,
        tabUnit: UnitType.BloodPressure
      });
    } else if (selectedTabLabel === 'Blood Glucose') {
      setSelectedTabType({
        tabType: VitalsHealthTypes.BloodGlucose,
        tabUnit: UnitType.BloodGlucose
      });
    } else {
      setSelectedTabType({
        tabType: VitalsHealthTypes.HeartRate,
        tabUnit: UnitType.HeartRate
      });
    }
  };

  function getYValues(unit: string, valueArray: TableReadingsType[]) {
    if (unit === BloodPressureType.Systolic) {
      return valueArray.map(y => Number(y.value.toString().split('/')[0]));
    } else if (unit === BloodPressureType.Diastolic) {
      return valueArray.map(y => Number(y.value.toString().split('/')[1]));
    } else {
      return valueArray.map(y => Number(y.value)) || [];
    }
  }

  function getY3Values(unit: string, valueArray: TableReadingsType[]) {
    return valueArray.map(y => [Number(y.value.toString().split('/')[0]), Number(y.value.toString().split('/')[1])]);
  }

  const generateTabsFromVitalsData = (vitalsDataAvailability: GetAccountVitalsDataAvailabilityResponse) => {
    const isBloodGlucoseDataAvailable = vitalsDataAvailability?.getAccountVitalsDataAvailability?.isBloodGlucoseDataAvailable;
    const isBloodPressureDataAvailable = vitalsDataAvailability?.getAccountVitalsDataAvailability?.isBloodPressureDataAvailable;
    const isHeartRateDataAvailable = vitalsDataAvailability?.getAccountVitalsDataAvailability?.isHeartRateDataAvailable;

    const newTabs: TabContent[] = [];

    if (isBloodPressureDataAvailable) {
      newTabs.push({
        label: 'Blood Pressure',
        icon: <MonitorHeartIcon />
      });
    }
    if (isBloodGlucoseDataAvailable) {
      newTabs.push({
        label: 'Blood Glucose',
        icon: <BloodtypeIcon />
      });
    }
    if (isHeartRateDataAvailable) {
      newTabs.push({
        label: 'Heart Rate',
        icon: <FavoriteIcon />
      });
    }

    return newTabs;
  };

  const handleDataReview = (): void => {
    const modal = showModal(RpmReadingsReviewDialog, {
      title: 'Log Review Time',
      vitalsHealthType: selectedTabType.tabType,
      hideDialog: () => {
        modal.hide();
      },
      fullName: getAccountUserFullName(patientInfo?.user)
    });
  };

  const refreshVitalsData = useCallback(() => {
    vitalsTableQueryRefetch({
      pageSize: DEFAULT_PAGE_SIZE,
      pageNum: currentPage + 1,
      rpmData: {
        healthType: selectedTabType.tabType,
        startDate: startDate,
        endDate: endDate
      },
      truentityId: id
    });
    vitalsPageQueryRefetch({
      pageSize: DEFAULT_PAGE_SIZE,
      pageNum: 1,
      rpmData: {
        healthType: selectedTabType.tabType,
        startDate: startDate,
        endDate: endDate
      },
      truentityId: id
    });
  }, [currentPage, endDate, id, selectedTabType.tabType, startDate, vitalsPageQueryRefetch, vitalsTableQueryRefetch]);

  const openReadingsActivityDrawer = () => {
    setIsActivityDrawerOpen(true);
  };
  const closeReadingsActivityDrawer = () => setIsActivityDrawerOpen(false);

  const onPageChange = useCallback(({ page }: { page: number }) => {
    setCurrentPage(page);
  }, []);

  useEffect(() => {
    callGetVitalsTable();
  }, [selectedTabType, startDate, endDate, currentPage]);

  useEffect(() => {
    callGetVitalsPageData();
  }, [selectedTabType, startDate, endDate]);

  useEffect(() => {
    if (vitalsTableQueryData?.getAccountVitalsReadings) {
      setVitalsTableData(vitalsTableQueryData.getAccountVitalsReadings.tableReadings);
      setRowCount(vitalsTableQueryData?.getAccountVitalsReadings?.meta?.totalCount ?? 0);
    }
  }, [vitalsTableQueryData]);

  useEffect(() => {
    if (vitalsPageQueryData?.getAccountVitalsReadings) {
      setVitalsReadingsSummary({
        uniqueDaysCount: vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.hasBaseline
          ? `${vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.uniqueDaysCount}, ${vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.uniqueDaysWithBaselineCount}*`
          : `${vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.uniqueDaysCount ?? 0}`,
        criticallyHigh: vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.criticallyHigh ?? 0,
        high: vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.high ?? 0,
        normal: vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.normal ?? 0,
        low: vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.low ?? 0,
        criticallyLow: vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.criticallyLow ?? 0
      });
      const reversedValues = [...vitalsPageQueryData.getAccountVitalsReadings.tableReadings]?.reverse();
      const xAxisVal = [...vitalsPageQueryData.getAccountVitalsReadings.tableReadings].map(x => x.recordedAt).reverse();
      setChartXValues(xAxisVal || []);
      setFormattedChartXValues(xAxisVal.map(x => formatDate(x, 'MM/DD')));
      if (selectedTabType.tabUnit === UnitType.BloodPressure) {
        setChartY3Values(getY3Values(selectedTabType.tabUnit, reversedValues));
      }
      setChartY2Values(
        getYValues(
          selectedTabType.tabUnit === UnitType.BloodPressure ? BloodPressureType.Diastolic : selectedTabType.tabUnit,
          reversedValues
        )
      );
      setChartYValues(
        getYValues(
          selectedTabType.tabUnit === UnitType.BloodPressure ? BloodPressureType.Systolic : selectedTabType.tabUnit,
          reversedValues
        )
      );
    } else {
      setVitalsReadingsSummary({
        uniqueDaysCount: '0',
        criticallyHigh: 0,
        high: 0,
        normal: 0,
        low: 0,
        criticallyLow: 0
      });
      setChartXValues([]);
      setFormattedChartXValues([]);
      setChartYValues([]);
      setChartY2Values([]);
    }
  }, [vitalsPageQueryData]);

  useEffect(() => {
    if (vitalsDataAvailability?.getAccountVitalsDataAvailability) {
      const dynamicTabs = generateTabsFromVitalsData(vitalsDataAvailability);
      setTabs(dynamicTabs);
    }
  }, [vitalsDataAvailability]);

  useEffect(() => {
    if (tabs?.length > 0) {
      handleChange(0);
    }
  }, [tabs]);

  useEffect(() => {
    if (vitalsTableQueryError) {
      onVitalsTableDataError(vitalsTableQueryError);
    }
  }, [vitalsTableQueryError]);

  return (
    <>
      <ActivityDrawer isOpen={isActivityDrawerOpen} onClose={closeReadingsActivityDrawer} type={ActivityLogType.READINGS_REVIEW_SUMMARY} />
      <Stack sx={{ backgroundColor: 'white', mx: '-32px', paddingRight: '0px' }}>
        <Grid component={Paper} container rowSpacing={2} sx={{ marginTop: '-32px', marginLeft: '0px', width: '100%', paddingLeft: '0px' }}>
          <Grid
            item
            xs={12}
            sx={{ px: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <H1 textAlign="left" sx={{ fontSize: '38px', color: color.black50, lineHeight: '50px' }}>
              Device Readings
            </H1>
            <Stack flexDirection="row" alignItems="center">
              <Button variant="contained" color="primary" onClick={handleDataReview}>
                Log Time
              </Button>
              <Tooltip title="Readings Review Summary">
                <Box>
                  <IconButton title="Readings Review Summary" onClick={() => openReadingsActivityDrawer()}>
                    <StickyNote2Icon color="primary" />
                  </IconButton>
                </Box>
              </Tooltip>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleTabChange}
              TabIndicatorProps={{ sx: { height: '.25rem', borderColor: color.truentityBlue[500] } }}
              sx={{
                '&& .Mui-selected': {
                  backgroundColor: color.truentityBlue[50]
                }
              }}
            >
              {tabs.map((tab, index) => {
                return (
                  <Tab icon={tab.icon ? tab.icon : ''} iconPosition="start" label={tab.label} key={`tab-${index}`} id={`tab-${index}`} />
                );
              })}
            </Tabs>
          </Grid>
        </Grid>
        <Grid component={Paper} container sx={{ direction: 'row', marginTop: '30px', padding: '30px' }}>
          {vitalsTableQueryError?.message === GetAccountVitalsReadingsErrors.NO_DATA_READINGS ? (
            <Grid item xs={12}>
              <Alert severity="warning">
                <AlertTitle>No data available</AlertTitle>
                Please check your device and its connectivity.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={5}>
                <H2 textAlign="left" fontWeight={'600'} fontSize={'20px'} color={color.grey600} lineHeight={'26px'}>
                  Range
                </H2>
                <H1 textAlign="left" fontWeight={'500'} fontSize={'2.5rem'} color={color.black50} lineHeight={'77px'}>
                  {vitalsPageQueryData?.getAccountVitalsReadings?.range}
                  <Caption
                    component="span"
                    fontWeight={'500'}
                    fontSize={'1.25rem'}
                    color={color.grey700}
                    lineHeight={'34px'}
                    sx={{ display: 'inline', marginLeft: '10px' }}
                  >
                    {vitalsPageQueryData?.getAccountVitalsReadings?.unit}
                  </Caption>
                </H1>
              </Grid>
              <Grid item xs={4}>
                <H2 textAlign="left" fontWeight={'600'} fontSize={'20px'} color={color.grey600} lineHeight={'26px'}>
                  Average
                </H2>
                <H1 textAlign="left" fontWeight={'500'} fontSize={'2.5rem'} color={color.black50} lineHeight={'77px'}>
                  {vitalsPageQueryData?.getAccountVitalsReadings?.average}
                  <Caption
                    component="span"
                    fontWeight={'500'}
                    fontSize={'1.25rem'}
                    color={color.grey700}
                    lineHeight={'34px'}
                    sx={{ display: 'inline', marginLeft: '10px' }}
                  >
                    {vitalsPageQueryData?.getAccountVitalsReadings?.unit}
                  </Caption>
                </H1>
              </Grid>
              <Grid item xs={3}>
                <H2
                  sx={{ textTransform: 'uppercase' }}
                  textAlign="left"
                  fontWeight={'400'}
                  fontSize={'12px'}
                  color={color.grey600}
                  lineHeight={'31px'}
                >
                  Select Date
                </H2>

                <TruentityDateFullRangePicker
                  disableFuture={true}
                  startDate={new Date(startDate)}
                  endDate={new Date(endDate)}
                  onChange={handleDateChange}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Box component={Paper} sx={{ direction: 'row', marginTop: '30px', width: '100%', marginBottom: '-32px' }}>
          <Stack pt={2} pr={2}>
            <Button sx={{ marginRight: '8px', alignSelf: 'flex-end' }} variant="outlined" color="error" onClick={refreshVitalsData}>
              Refresh
              <RefreshIcon fontSize="small" />
            </Button>
          </Stack>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                marginTop: 2,
                width: '100%'
              }}
            >
              <Stack
                flexDirection="column"
                flex="1 1 auto"
                display="flex"
                justifyContent="flex-start"
                alignItems="stretch"
                width="100%"
                maxWidth="100%"
                height="auto"
                padding={1}
              >
                <Stack
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {tabPanelData?.map(({ index }) => (
                    <TabPanel key={index} value={value} index={index}>
                      <BarChartSection
                        title={selectedTabType.tabUnit}
                        chartXValues={chartXValues}
                        overwriteChartXValues={formattedChartXValues}
                        chartYValues={chartYValues}
                        chartY2Values={chartY2Values}
                        additionalCss={{ backgroundColor: 'transparent' }}
                      />
                    </TabPanel>
                  ))}
                </Stack>
                <Stack
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {tabPanelData?.map(({ index }) => (
                    <TabPanel key={index} value={value} index={index}>
                      <LineChartSection
                        chartXValues={chartXValues}
                        overwriteChartXValues={formattedChartXValues}
                        chartSeries={[
                          {
                            name: BloodPressureType.Systolic,
                            data: chartYValues
                          },
                          {
                            name: BloodPressureType.Diastolic,
                            data: chartY2Values
                          }
                        ]}
                        additionalCss={{ backgroundColor: 'transparent' }}
                      />
                    </TabPanel>
                  ))}
                </Stack>
                {/*  <Stack style={{ justifyItems: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>*/}
                {/*    {tabPanelData?.map(({ index }) => (*/}
                {/*      <TabPanel key={index} value={value} index={index}>*/}
                {/*        {selectedTabType.tabType === VitalsHealthTypes.BloodPressure ? (*/}
                {/*          <EChartSection*/}
                {/*            titleText=""*/}
                {/*            titleSubtext=""*/}
                {/*            chartXValues={chartXValues}*/}
                {/*            chartYValues={chartY3Values}*/}
                {/*            unitType={selectedTabType.tabUnit}*/}
                {/*            additionalCss={{ backgroundColor: 'transparent' }}*/}
                {/*            sysCLowValue={sysCLowValue}*/}
                {/*            sysLowValue={sysLowValue}*/}
                {/*            sysHighValue={sysHighValue}*/}
                {/*            sysCHighValue={sysCHighValue}*/}
                {/*            diaCLowValue={diaCLowValue}*/}
                {/*            diaLowValue={diaLowValue}*/}
                {/*            diaHighValue={diaHighValue}*/}
                {/*            diaCHighValue={diaCHighValue}*/}
                {/*          />*/}
                {/*        ) : (*/}
                {/*          <></>*/}
                {/*        )}*/}
                {/*      </TabPanel>*/}
                {/*    ))}*/}
                {/*  </Stack>*/}
              </Stack>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: 2, width: '100%' }}>
              <VitalsTableData
                patientId={id}
                alertLimits={alertLimits}
                selectedTabType={selectedTabType.tabType}
                tabUnit={selectedTabType.tabUnit}
                readingsSummaryValues={vitalsReadingsSummary}
                hasBaseline={vitalsPageQueryData?.getAccountVitalsReadings?.readingsSummary?.hasBaseline ?? false}
                tableReadings={vitalsTableData}
                totalCount={rowCount}
                refreshVitalsData={refreshVitalsData}
                loading={vitalsTableQueryLoading}
                currentPage={currentPage}
                onPageChange={onPageChange}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

export default DataReadings;
