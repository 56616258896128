import { ConversationLayout } from '@/components/ConversationLayout';
import { color } from '@/styles/assets/colors';
import type { ConversationMessage, MessagesByDate } from '@/types/chat';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import type { DrawerProps } from '@mui/material';
import { Drawer, IconButton, Stack, TextField } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';

type ConditionalProps =
  | { onSubmitCallback?: never; inputLabel?: never; inputControlsLeft?: never; sidePanel?: never; inputMessage?: never; readOnly?: true }
  | {
      onSubmitCallback?: (data: ConversationMessage) => void;
      inputLabel?: string;
      inputControlsLeft?: ReactNode;
      sidePanel?: ReactNode;
      inputMessage?: string;
      readOnly?: boolean;
    };

type Props = DrawerProps &
  ConditionalProps & {
    messagesByDate: MessagesByDate;
    onClose: () => void;
    header?: ReactNode;
  };

export const MessageDrawer = ({
  readOnly,
  onSubmitCallback,
  messagesByDate,
  inputLabel,
  onClose,
  header,
  inputControlsLeft,
  sidePanel,
  inputMessage,
  ...props
}: Props) => {
  const { register, handleSubmit, reset, formState, setValue, getValues, watch } = useForm<ConversationMessage>();
  const messageInputFieldWatch = watch('message');

  useEffect(() => {
    if (inputMessage != null) {
      setValue('message', inputMessage);
    }
  }, [inputMessage, setValue]);

  const onSubmit: SubmitHandler<ConversationMessage> = async data => {
    reset();
    if (onSubmitCallback) {
      onSubmitCallback(data);
    }
  };

  return (
    <Drawer
      {...props}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1, width: '100%' }}
      container={this}
      anchor="right"
      color="inherit"
      elevation={1}
      onClose={onClose}
    >
      <Stack
        width="100%"
        height="100vh"
        sx={{
          overflow: 'none',
          maxHeight: '100vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch',
          background: color.white,
          backgroundColor: color.white
        }}
      >
        <Stack
          padding={2}
          sx={{
            display: 'flex',
            minWidth: 400,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            borderRight: 1,
            borderColor: color.grey300
          }}
        >
          <Stack p={1} direction="row" justifyContent="space-between" alignItems="flex-start">
            {header}
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack
            spacing={2}
            flexGrow={1}
            sx={{
              overflow: 'auto'
            }}
          >
            <ConversationLayout messagesByDate={messagesByDate} width={600} height={'100%'} />
          </Stack>
          {!readOnly ? (
            <Stack
              sx={{
                pt: 2,
                pb: 2
              }}
            >
              <Stack
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                spacing={1}
                direction="row"
                alignItems={'center'}
                justifyContent="space-evenly"
              >
                {inputControlsLeft}
                <TextField
                  sx={{
                    flexGrow: 1
                  }}
                  id={'message'}
                  InputLabelProps={{ shrink: true }}
                  {...register('message', { required: true })}
                  name={'message'}
                  required
                  label={inputLabel}
                  multiline={true}
                  variant="outlined"
                  size="small"
                />
                <IconButton
                  disabled={
                    !(formState.isDirty || (messageInputFieldWatch && messageInputFieldWatch !== '') || getValues('message') !== '') ||
                    formState.isSubmitting
                  }
                  sx={{
                    color: color.white,
                    background: color.primaryMain,
                    '&:hover': {
                      background: color.primaryMain,
                      boxShadow: `0px 2px 4px ${color.primaryMain}`
                    }
                  }}
                  color="primary"
                  type="submit"
                  title="Submit"
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <></>
          )}
        </Stack>
        {sidePanel}
      </Stack>
    </Drawer>
  );
};
