import type { FlexDirections } from '@/types/mui';
import type { SxProps } from '@mui/material';
import { Box, Stack } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import type { Theme } from '@mui/system';
import type { ReactElement, ReactNode, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  value: number;
};

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export type TabContent = {
  key?: string;
  label: ReactNode;
  icon?: string | ReactElement;
  children?: ReactNode;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

type TabsProps = {
  tabs: TabContent[];
  additionalHeaderComponents?: ReactNode;
  customPadding?: boolean;
  tabVariant?: 'fullWidth' | 'standard' | 'scrollable' | undefined;
  preSelectedTabIndex?: number;
  tabOrientation?: 'vertical' | 'horizontal';
  onTabChange?: (selectedTabIndex: number) => void;
  hideDisabled?: boolean;
  sx?: SxProps<Theme>;
  TabContentProps?: SxProps<Theme>;
};

const defaultStyle: SxProps<Theme> = {
  textTransform: 'Capitalize'
};

const MuiTabs = ({
  tabs,
  additionalHeaderComponents,
  customPadding,
  tabVariant,
  tabOrientation,
  preSelectedTabIndex,
  onTabChange,
  hideDisabled,
  ...props
}: TabsProps) => {
  const [visibleTabs, setVisibleTabs] = useState<TabContent[]>(tabs);
  const [selectedTab, setSelectedTab] = useState(preSelectedTabIndex || 0);

  tabVariant = tabVariant || 'fullWidth';
  tabOrientation = tabOrientation || 'horizontal';

  let direction: FlexDirections = 'row';
  let tabsContainerStyle = {
    width: '100%'
  };
  let tabStyle: SxProps<Theme> = {
    borderBottom: 1,
    borderRight: 0,
    borderColor: 'divider'
  };

  if (tabOrientation === 'horizontal') {
    direction = 'column';
    tabStyle = {
      borderBottom: 0,
      borderColor: 'divider'
    };
    tabsContainerStyle = {
      width: 'auto'
    };
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  useEffect(() => {
    setVisibleTabs(!hideDisabled ? tabs : tabs.filter(v => !v.disabled));
  }, [hideDisabled, tabs]);

  return (
    <>
      {visibleTabs.length === 1 && <Box sx={{ flex: 1 }}>{<Box sx={{ p: customPadding ? 0 : 3 }}>{visibleTabs[0].children}</Box>}</Box>}

      {visibleTabs.length > 1 && (
        <Stack direction={direction} sx={tabStyle} {...props}>
          <Tabs sx={tabsContainerStyle} orientation={tabOrientation} variant={tabVariant} value={selectedTab} onChange={handleTabChange}>
            {visibleTabs.map((tab, index) => (
              <Tab
                icon={tab.icon ? tab.icon : ''}
                iconPosition="start"
                disabled={tab.disabled}
                label={tab.label}
                key={`tab-${index}`}
                id={`tab-${index}`}
                sx={{ ...defaultStyle, ...tab.sx }}
              />
            ))}
          </Tabs>

          {additionalHeaderComponents}

          {visibleTabs.some(tab => tab.children) && (
            <Box sx={{ flex: 1, ...props.TabContentProps }}>
              {
                <Box sx={{ p: customPadding ? 0 : 3 }}>
                  {visibleTabs.map((tab, index) => (
                    <TabPanel value={selectedTab} index={index} key={`tab-content-${index}`}>
                      {tab.children}
                    </TabPanel>
                  ))}
                </Box>
              }
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};

export default MuiTabs;
