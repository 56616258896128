import { useCallback, useContext, useEffect } from 'react';

import AdministrationContext from '@/context/administrationContext';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetClientOrganizationResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATION_LOCATIONS } from '@/graphql/administration';
import { useLazyQuery } from '@apollo/client';
import { Box, List, ListItemButton, ListItemText, ListSubheader, Paper, Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import OrganizationDetails from './OrganizationDetails';

const OrganizationSection = () => {
  const { id } = useParams();
  const { setContent } = useContext(SideMenuContext);

  const { setOrganization, reloadOrganization, setReloadOrganization } = useContext(AdministrationContext);

  const [
    getOrganization,
    { loading: organizationDataLoading, data: organizationData, called: organizationDataCalled, refetch: organizationDataRefetch }
  ] = useLazyQuery<GetClientOrganizationResponse>(GET_CLIENT_ORGANIZATION_LOCATIONS, {
    fetchPolicy: 'cache-and-network'
  });

  const navigate = useNavigate();
  const { pathname } = useLocation();

  //TODO: Improve side menu content implementation so that we arent constantly repeating logic
  // maybe a custom hook?

  const SideMenuContent = useCallback(() => {
    const pathnameIncludes = (path: string) => {
      return pathname.includes(path);
    };

    return (
      <List component="nav">
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <List subheader={<ListSubheader>Organization</ListSubheader>}>
            {/* <ListItemButton
              selected={pathnameIncludes('/patients')}
              onClick={() => {
                navigate('patients');
              }}
            >
              <ListItemText primary="Patients" />
            </ListItemButton> */}

            <ListItemButton
              selected={pathnameIncludes('/stores')}
              onClick={() => {
                navigate('stores');
              }}
            >
              <ListItemText primary="Locations" />
            </ListItemButton>

            {/* <ListItemButton
              selected={pathnameIncludes('/tasks')}
              onClick={() => {
                navigate('tasks');
              }}
            >
              <ListItemText primary="Tasks" />
            </ListItemButton> */}
          </List>
        </Box>
      </List>
    );
  }, [navigate, pathname]);

  useEffect(() => {
    setContent(<SideMenuContent />);
  }, [SideMenuContent, setContent]);

  useEffect(() => {
    getOrganization({
      variables: {
        clientOrganizationId: id
      }
    }).catch(err => {
      console.error(err);
    });
  }, [getOrganization, id]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setOrganization(organizationData.clientOrganization);
      setReloadOrganization(false);
    } else {
      setReloadOrganization(true);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled, setOrganization]);

  useEffect(() => {
    if (reloadOrganization) {
      organizationDataRefetch().catch(err => {
        console.error(err);
      });
    }
  }, [organizationDataRefetch, reloadOrganization, setReloadOrganization]);

  return (
    <Stack direction="column" spacing={4}>
      <Paper component={Stack} p={4}>
        <OrganizationDetails />
      </Paper>
      <Paper component={Stack} p={4} sx={{ height: '100%' }}>
        <Outlet />
      </Paper>
    </Stack>
  );
};

export default OrganizationSection;
