import AddExistingUserDialog from '@/components/Dialogs/AddExistingUserDialog';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';
import Button from '../Button';

type ExistingUserAddProps = {
  onExistingUserAdd?: () => void;
};

const ExistingUserAdd = ({ onExistingUserAdd }: ExistingUserAddProps) => {
  const { showModal } = useModal();

  const showExistingUserDialog = () => {
    const modal = showModal(AddExistingUserDialog, {
      title: 'Add Existing User',
      hideDialog: () => {
        modal.hide();
      },
      onExistingUserAdd: () => {
        modal.hide();
        if (onExistingUserAdd) {
          onExistingUserAdd();
        }
      }
    });
  };

  return <Button startIcon={<AddIcon />} label="Add Existing User" onClick={() => showExistingUserDialog()} />;
};

export default ExistingUserAdd;
