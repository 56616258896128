import type Meta from '@/types/meta';
import { gql } from '@apollo/client';

export type ExistingUserType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleType: string;
  title: string;
  userExists: boolean;
};

export type GetExistingUsersResponse = {
  getExistingUsers: {
    users: ExistingUserType[];
    meta: Meta;
  };
};

export const GET_EXISTING_USERS = gql`
  query GetExistingUsers($pageSize: Int!, $pageNum: Int!, $relyingPartyId: String, $filterOptions: ExistingUserFilterOptionsInput) {
    getExistingUsers(pageSize: $pageSize, pageNum: $pageNum, relyingPartyId: $relyingPartyId, filterOptions: $filterOptions) {
      users {
        id
        firstName
        lastName
        email
        roleType
        title
        userExists
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type ExistingAdminUserType = {
  id: string;
  email: string;
  roleType: string;
  title: string;
  firstName: string;
  lastName: string;
  isObserver?: boolean;
  npiNumber?: string;
  techLicense?: string;
  userExists: boolean;
};

export type AssignMultipleUsersAdminsResponse = {
  assignMultipleUsersRelyingPartyAdmins: {
    status: string;
    message: string;
  };
};

export const ASSIGN_MULTIPLE_USERS_AS_ADMINS = gql`
  mutation AssignMultipleUsersRelyingPartyAdmins($existingUsersInput: [ExistingUserRelyingPartyAdminInput!]!, $relyingPartyId: String) {
    assignMultipleUsersRelyingPartyAdmins(existingUsersInput: $existingUsersInput, relyingPartyId: $relyingPartyId) {
      status
      message
    }
  }
`;
