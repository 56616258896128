import { ADD_HEALTH_PLAN } from '@/graphql/administration';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

type FormValues = {
  name: string;
};

const defaultValues: FormValues = {
  name: ''
};

const HealthPlanAddDialog = ({ title, hideDialog, ...props }: Props) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [addHealthPlan] = useMutation(ADD_HEALTH_PLAN);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const result: any = await addHealthPlan({
        variables: {
          ...values
        }
      });

      const data = result.data!.addHealthPlan;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, {
        variant
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to add Health Plan', {
        variant: 'error'
      });
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container spacing={4} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={6}>
            <Stack>
              <Controller
                control={control}
                name="name"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField autoFocus required onChange={onChange} value={value} label={'Name'} />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button type="submit" a11yLabel="Save" appearance="primary" />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default HealthPlanAddDialog;
