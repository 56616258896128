import type { Timer } from '@/hooks/useTimer';
import useTimer from '@/hooks/useTimer';
import { RpmMonitoringActivityTypes } from '@/routes/PatientDetails/RemotePatientMonitoring/RpmWorkflow';
import create from 'zustand';

type TimerStore = {
  activeTimer: Timer | undefined;
  setActiveTimer: (timer: Timer | undefined) => void;
  activeType: RpmMonitoringActivityTypes | undefined;
  setActiveType: (val: RpmMonitoringActivityTypes | undefined) => void;
  isReadOnly: boolean;
  setIsReadOnly: (val: boolean) => void;
};

const useStore = create<TimerStore>(set => ({
  activeTimer: undefined,
  setActiveTimer: timer => set({ activeTimer: timer }),
  activeType: undefined,
  setActiveType: type => set({ activeType: type }),
  isReadOnly: false,
  setIsReadOnly: val => set({ isReadOnly: val })
}));

export const useRpmWorkflowStore = () => {
  const careActivityTimer = useTimer(RpmMonitoringActivityTypes.CareActivity, 'Care Activity');
  const encounterTimer = useTimer(RpmMonitoringActivityTypes.Encounter, 'Encounter');
  const { activeTimer, setActiveTimer, activeType, setActiveType, setIsReadOnly, isReadOnly } = useStore();

  return {
    careActivityTimer,
    encounterTimer,
    activeTimer,
    setActiveTimer,
    activeType,
    setActiveType,
    setIsReadOnly,
    isReadOnly
  };
};
