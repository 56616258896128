import { H5 } from '@/components/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import { Stack } from '@mui/material';
import type React from 'react';

interface AlertLimitsMarkerLabelProps {
  fontSize?: string;
  label: string;
  color: string;
}

export const MarkerLabel: React.FC<AlertLimitsMarkerLabelProps> = ({ label, color, fontSize = '1rem' }) => {
  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <CircleIcon sx={{ color: color, fontSize: fontSize }} />
      <H5 sx={{ ml: 1, fontSize: fontSize }}>{label}</H5>
    </Stack>
  );
};

export default MarkerLabel;
