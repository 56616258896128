import { Box } from '@mui/material';
import Link from '@mui/material/Link';
import type { ReactNode } from 'react';

type Props = {
  to?: string;
  children: ReactNode;
};
const BreadcrumbLink = ({ to, children }: Props) => {
  return to !== undefined ? (
    <Link sx={{ textDecoration: 'none' }} href={to}>
      {children}
    </Link>
  ) : (
    <Box sx={{ fontWeight: '400' }}>{children}</Box>
  );
};

export default BreadcrumbLink;
