import { DialogActions, DialogContent } from '@mui/material';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

import Alert from '../Alert';
import Button from '../Button';

type Props = BaseDialogProps & {
  success: boolean;
  hideDialog: () => void;
  onConfirm: () => void;
};

const DischargePatientDialog = ({ title, success, hideDialog, onConfirm, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <Alert
          description={success ? 'Successfully added Patient for discharge' : 'Error in discharging Patient'}
          status={success ? 'success' : 'warning'}
        />

        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button a11yLabel="Ok" appearance="primary" onClick={onConfirm} />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default DischargePatientDialog;
