import type { SvgIconComponent } from '@mui/icons-material';
import type { SvgIcon, SxProps } from '@mui/material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import type React from 'react';

export type ButtonMenuItemTypes = {
  buttonToolTipText: string;
  buttonIcon: SvgIconComponent;
  buttonIconProps?: React.ComponentProps<typeof SvgIcon>;
  buttonAction: () => void;
  buttonStyles?: SxProps;
  hidden?: boolean;
};

type Props = {
  buttonMenuItems: ButtonMenuItemTypes[];
};

const RpmInfoTabsButtonMenu = ({ buttonMenuItems }: Props) => {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ overflowX: 'auto' }}>
      {buttonMenuItems
        .filter(buttonItem => !buttonItem.hidden)
        .map((buttonItem, index) => (
          <Tooltip key={index} title={buttonItem.buttonToolTipText}>
            <IconButton onClick={buttonItem.buttonAction} size="small" sx={{ border: '1px solid lightGrey', ...buttonItem.buttonStyles }}>
              <buttonItem.buttonIcon
                color={buttonItem.buttonIconProps?.color || 'primary'}
                fontSize={buttonItem.buttonIconProps?.fontSize || 'small'}
              />
            </IconButton>
          </Tooltip>
        ))}
    </Stack>
  );
};

export default RpmInfoTabsButtonMenu;
