import { BaseSideMenuSideNav } from '@/components/SideMenus/BaseSideMenuSideNav';
import SideMenuContext from '@/context/sideMenuContext';
import type { DrawerProps } from '@mui/material';
import { Drawer } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useContext } from 'react';

type Props = DrawerProps & {
  drawerWidth: number;
};

const NavigationSidebarMenu = ({ drawerWidth, ...props }: Props) => {
  const theme = useTheme();
  const { drawerOpen, content } = useContext(SideMenuContext);

  return (
    <Drawer
      variant={'permanent'}
      anchor="left"
      open={drawerOpen}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          backgroundColor: theme.palette.primary[600],
          color: theme.palette.primary.contrastText,
          borderRight: 'none',
          '& .MuiListSubheader-root': {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary[600]
          },

          '& .MuiDrawer-paper::-webkit-scrollbar-thumb': {
            backgroundColor: alpha(theme.palette.primary.contrastText, 0.6),
            borderRight: `2px ${theme.palette.primary[600]} solid`,
            backgroundClip: 'padding-box',
            borderRadius: 8
          },
          '& .MuiDrawer-paper::-webkit-scrollbar': {
            width: 9,
            backgroundColor: theme.palette.primary[600]
          },
          '& .MuiDrawer-paper::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.primary[600]
          },
          '& .MuiListItemButton-root': {
            color: theme.palette.grey[300],
            '& .MuiSvgIcon-root': {
              color: theme.palette.grey[300]
            },
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.dark
              }
            },
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.dark
              }
            }
          }
        }
      }}
      {...props}
    >
      <BaseSideMenuSideNav
        sx={{
          marginTop: '85px',
          height: 'calc(100% - 85px)',
          paddingRight: drawerOpen ? 2 : 0
        }}
        drawerWidth={drawerWidth}
        drawerOpen={drawerOpen}
      >
        {content}
      </BaseSideMenuSideNav>
    </Drawer>
  );
};

export default NavigationSidebarMenu;
