import { LineChart } from '@/components/Charts/charts';
import { color } from '@/styles/assets/colors';
import { formatTime } from '@/util/format';

type ChartSeriesType = {
  name: string;
  data: Array<number | null>;
};

type ChartAnnotationType = {
  value: string;
  name: string;
  color: string;
};

interface LineChartSectionProps {
  width?: number | string;
  chartSeries: ChartSeriesType[];
  chartXValues: string[];
  overwriteChartXValues?: string[];
  additionalCss: {
    backgroundColor: string;
  };
  tooltip?: {
    formatter: (value: string) => string;
  };
  annotations?: {
    xAxis: ChartAnnotationType[];
  };
  dataLabels?: {
    enabled: boolean;
  };
}

const LineChartSection = ({
  overwriteChartXValues = [],
  chartSeries,
  chartXValues,
  width = 550,
  additionalCss,
  tooltip = {
    formatter: value => formatTime(value, 'MM/DD - h:mm A')
  },
  annotations = {
    xAxis: []
  },
  dataLabels = {
    enabled: false
  }
}: LineChartSectionProps) => {
  return (
    <LineChart
      width={width}
      additionalCss={additionalCss}
      options={{
        chart: {
          id: 'vitals-chart',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        xaxis: {
          type: 'category',
          categories: chartXValues,
          overwriteCategories: overwriteChartXValues?.length > 0 ? overwriteChartXValues : undefined,
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            trim: false,
            minHeight: 60,
            showDuplicates: false
          }
        },
        colors: ['#ED8074', '#008000'],
        dataLabels,
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            formatter: value => {
              if (value > 0) {
                return tooltip?.formatter(chartXValues[value - 1]);
              }
              return '';
            }
          }
        },
        annotations: {
          xaxis:
            annotations?.xAxis?.map(annotationData => {
              return {
                x: annotationData.value,
                borderColor: annotationData.color,
                label: {
                  borderColor: annotationData.color,
                  style: {
                    color: color.white,
                    background: annotationData.color
                  },
                  text: annotationData.name
                }
              };
            }) || []
        }
      }}
      series={chartSeries}
      type="line"
      height="420"
    />
  );
};

export default LineChartSection;
