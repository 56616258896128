import Button from '@/components/Button';
import type { EndProviderSessionResponse } from '@/graphql/remotePatientMonitoring';
import { END_PROVIDER_SESSION } from '@/graphql/remotePatientMonitoring';
import { ProviderSessionStatusTypes } from '@/types/remotePatientMonitoring';
import { getRpmProviderAuthCode, resetProviderSession } from '@/util/rpm';
import { useMutation } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AppBar, IconButton, Stack, Tab, Tabs } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import type { SyntheticEvent } from 'react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIdleModal } from '@/hooks/useIdleModal';

const tabs: { a11yLabel: string; label: string; key: string }[] = [
  {
    a11yLabel: 'Enrollment',
    label: 'Enrollment',
    key: 'enrollment'
  },
  {
    a11yLabel: 'Monthly Report',
    label: 'Monthly Report',
    key: 'monthlyReport'
  }
];

const RpmEnrollmentReviewSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { recordId, reportId } = useParams();

  const [endProviderSession] = useMutation<EndProviderSessionResponse>(END_PROVIDER_SESSION);

  const { idleTimer } = useIdleModal({
    handleLogout: () => {
      navigate('/rpm?logout=true', {
        replace: true
      });
    },
    timeoutOverrideInSeconds: 600,
    promptTimeoutOverrideInSeconds: 60,
    disablePinPrompt: true
  });

  const handleLogout = async () => {
    try {
      const result = await endProviderSession({
        variables: {
          authCode: getRpmProviderAuthCode()
        }
      });

      const status = result.data?.endProviderSession?.status;

      if (status === ProviderSessionStatusTypes.SUCCESS) {
        resetProviderSession();
        navigate('/rpm?logout=true', {
          replace: true
        });
      } else {
        console.error('Logout failed with status:', status);
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const getSelectedTab = () => {
    const pathnameParts = location.pathname.split('/');

    if (pathnameParts.includes('view')) return 0;
    if (pathnameParts.includes('rpmMonthlyReports')) return 1;
    return 0;
  };

  const handleTabChange = (_event: SyntheticEvent, selectedTabIndex: number) => {
    if (selectedTabIndex === 1) {
      navigate('./rpmMonthlyReports');
    } else {
      navigate('./view');
    }
  };

  useEffect(() => {
    idleTimer.start();
  }, []);

  useEffect(() => {
    const currentRoute: string = location.pathname;
    if (currentRoute) {
      const currentRouteString: string = currentRoute.toString().toLowerCase() ?? '';
      const isProtectedRoute = currentRouteString.includes('view') || currentRouteString.includes('rpmmonthlyreports');
      if (isProtectedRoute) {
        const authCode = getRpmProviderAuthCode();
        if (!authCode) {
          navigate(`/rpm`, {
            replace: true
          });
        }
      }
    }
  }, [navigate, location]);

  return (
    <>
      <AppBar
        elevation={1}
        enableColorOnDark
        color="inherit"
        position="fixed"
        sx={{
          height: '64px'
        }}
      >
        <Toolbar
          sx={{
            mx: 10,
            justifyContent: 'space-between'
          }}
        >
          {recordId || reportId ? (
            <IconButton onClick={() => navigate(recordId ? './view' : './rpmMonthlyReports')}>
              <ArrowBackIosIcon sx={{ color: '#0000008A' }} />
            </IconButton>
          ) : (
            <Tabs value={getSelectedTab()} onChange={handleTabChange}>
              {tabs.map((tab, index) => (
                <Tab label={tab.label} key={tab.key} id={`tab-${index}`} sx={{ textTransform: 'capitalize' }} />
              ))}
            </Tabs>
          )}
          <Button label={'Logout'} variant={'outlined'} onClick={handleLogout} />
        </Toolbar>
      </AppBar>
      <Stack height="auto" minHeight="100%" mt={{ xs: '64px', sm: '72px' }} p={2} mx={{ xs: 2, sm: 10 }} flex={1} spacing={2}>
        <Outlet />
      </Stack>
    </>
  );
};

export default RpmEnrollmentReviewSection;
