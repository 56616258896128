export enum QuickUploadStatus {
  INITIALIZED = 'initialized',
  STARTED = 'started',
  DISABLED = 'disabled',
  ENDED = 'ended'
}

export const TYPE_QUICK_UPLOAD_STATUS_OPTIONS = [
  { label: 'Initialized', value: 'initialized' },
  { label: 'Started', value: 'started' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Ended', value: 'ended' }
];

export const DEFAULT_TYPE_QUICK_UPLOAD_STATUS = {
  value: QuickUploadStatus.INITIALIZED
};
