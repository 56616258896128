import { createCss, InternalCSS } from '@stitches/react';

import breakpoints from '@/styles/breakpoints';
import colors from '@/styles/colors';
import truentityColors from '@/styles/truentityColors';

type FocusPseudoElementProps = {
  activator?: string;
  baseRadius?: string;
  borderWidth?: number;
  element: 'after' | 'before';
  inset: number | string;
  ringColor?: string;
};

const { styled, theme } = createCss({
  theme: {
    colors: {
      ...colors
    },
    fontSizes: {
      sm: '0.75rem',
      md: '0.875rem',
      lg: '1rem',
      xl: '1.25rem',
      xxl: '1.5rem',
      xxxl: '2rem'
    },
    fontWeights: {
      light: '300',
      normal: '400',
      medium: '500',
      semi: '600',
      bold: '700'
    },
    radii: {
      none: '0px',
      sm: '2px',
      md: '3px',
      lg: '4px',
      xl: '6px',
      round: '9999px'
    },
    shadows: {
      0: 'none',
      1: `0 3px 6px ${colors.neutralA8}`,
      2: `0 4px 8px ${colors.neutralA12}`,
      3: `0 12px 24px ${colors.neutralA12}, 0 4px 8px ${colors.neutralA24}`,
      4: `0 16px 32px ${colors.neutralA32}, 0 4px 8px ${colors.neutralA48}`,
      focus: `0 0 0px 3px ${colors.themeA48}`
    },
    zIndices: {
      sidebar: '1000',
      overlay: '10000',
      navbar: '1001',
      popover: '2000',
      tooltip: '2001'
    }
  },
  media: {
    ...breakpoints
  },
  utils: {
    boxSize: () => (value: string | number) => ({
      height: value,
      width: value
    }),
    focusPseudoElement:
      () =>
      ({
        baseRadius = '$lg',
        element,
        ringColor = '$colors$themeLight',
        activator = '&:focus-visible',
        borderWidth = 0,
        inset = 0
      }: FocusPseudoElementProps) =>
        ({
          [`&::${element}`]: {
            content: '',
            display: 'block',
            margin: inset,
            pointerEvents: 'none',
            userSelect: 'none',

            bottom: `-${1 + borderWidth}px`,
            left: `-${1 + borderWidth}px`,
            position: 'absolute',
            right: `-${1 + borderWidth}px`,
            top: `-${1 + borderWidth}px`,

            borderRadius: baseRadius,
            boxShadow: `0 0 0 -2px ${ringColor}`,

            transition: 'box-shadow 300ms ease',
            zIndex: 1
          },

          [activator]: {
            outline: 0,

            [`&::${element}`]: {
              boxShadow: `0 2px 4px 0 ${ringColor}`
            }
          }
        } as InternalCSS)
  },
  insertionMethod: 'append'
});

type Color = typeof colors;
export const truentityTheme = theme({
  colors: {
    ...truentityColors
  } as Partial<Color>
});

export default styled;
