import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import AddAccountNoteDialog from '@/components/Dialogs/AddAccountNoteDialog';
import DeleteAccountNoteDialog from '@/components/Dialogs/DeleteAccountNoteDialog';
import UpdateAccountNoteDialog from '@/components/Dialogs/UpdateAccountNoteDialog';
import PinnedIcon from '@/components/PinnedIcon';
import ShareWithProviderIcon from '@/components/ShareWithProviderIcon';
import { H1 } from '@/components/Typography';
import type { GetAccountNotesResponse } from '@/graphql/remotePatientMonitoring';
import {
  DELETE_ACCOUNT_NOTE,
  GET_ACCOUNT_NOTES,
  UPDATE_ACCOUNT_NOTE_PIN,
  UPDATE_ACCOUNT_NOTE_PROVIDERS
} from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { AccountNoteType } from '@/types/remotePatientMonitoring';

import MultilineCell from '@/components/DataGrid/MultilineCell';
import { formatDate } from '@/util/format';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import { useLazyQuery, useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Checkbox, Chip, FormControlLabel, IconButton, Stack, Tooltip } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

const Notes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { showModal } = useModal();

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [accountsNotesDataArray, setAccountsNotesDataArray] = useState<AccountNoteType[]>([]);

  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const [isShowAllValue, setIsShowAllValue] = useState<boolean>(false);

  const [getAccountNotes, { data: accountNotesData, loading: accountNotesDataLoading, refetch: accountNotesDataRefetch }] =
    useLazyQuery<GetAccountNotesResponse>(GET_ACCOUNT_NOTES, {
      fetchPolicy: 'cache-and-network'
    });

  const [updateAccountNoteProvider] = useMutation(UPDATE_ACCOUNT_NOTE_PROVIDERS);
  const [updateAccountNotePin] = useMutation(UPDATE_ACCOUNT_NOTE_PIN);
  const [deleteAccountNote] = useMutation(DELETE_ACCOUNT_NOTE);

  const showAddNewNoteDialog = () => {
    const modal = showModal(AddAccountNoteDialog, {
      title: 'Add Account Note',
      hideDialog: () => {
        modal.hide();
        accountNotesDataRefetch();
      },
      id: id
    });
  };

  const showUpdateNewNoteDialog = useCallback(
    (data: AccountNoteType) => {
      const modal = showModal(UpdateAccountNoteDialog, {
        title: 'Update Account Note',
        hideDialog: () => {
          modal.hide();
          accountNotesDataRefetch();
        },
        id: id,
        data: data
      });
    },
    [accountNotesDataRefetch, id, showModal]
  );

  const onRemoveNoteConfirmed = useCallback(
    (noteId: string) => {
      deleteAccountNote({
        variables: {
          truentityId: id,
          noteId: noteId
        }
      })
        .then(() => {
          accountNotesDataRefetch().catch(console.error);
        })
        .catch(() => {
          enqueueSnackbar('Error while deleting a note', {
            variant: 'error'
          });
        });
    },
    [accountNotesDataRefetch, deleteAccountNote, enqueueSnackbar, id]
  );

  const showDeleteNoteDialog = useCallback(
    (noteId: string) => {
      const modalRef = showModal(DeleteAccountNoteDialog, {
        title: 'Archive note',
        description: 'Are you sure you want to archive this note?',
        onConfirm: () => {
          modalRef.hide();
          onRemoveNoteConfirmed(noteId);
        },
        hideDialog: () => modalRef.hide()
      });
    },
    [onRemoveNoteConfirmed, showModal]
  );

  const callGetCandidateQuery = async () => {
    try {
      const variables = {
        pageSize: DEFAULT_PAGE_SIZE,
        pageNum: currentPage + 1,
        truentityId: id
      };

      // Check if isShowAllValue is true
      if (!isShowAllValue) {
        variables.isArchived = false;
      }

      await getAccountNotes({ variables });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve data', { variant: 'error' });
    }
  };

  const pinNoteToProfile = async (noteIds: string[]) => {
    console.warn('pinNote', noteIds);
    try {
      await updateAccountNotePin({
        variables: {
          noteIds: noteIds,
          truentityId: id
        }
      });
      await accountNotesDataRefetch();
      enqueueSnackbar(noteIds.length === 0 ? 'Unpinned the note' : 'Pinned the note', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve data', { variant: 'error' });
    }
  };

  const shareNoteWithProvider = async () => {
    try {
      await updateAccountNoteProvider({
        variables: {
          noteIds: selectionModel,
          truentityId: id
        }
      });
      await accountNotesDataRefetch();
      enqueueSnackbar('Successfully shared with providers', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to retrieve data', { variant: 'error' });
    }
  };

  useEffect(() => {
    callGetCandidateQuery().catch(console.error);
  }, [currentPage, isShowAllValue]);

  useEffect(() => {
    if (accountNotesData?.getAccountNotes.notes) {
      setAccountsNotesDataArray(accountNotesData?.getAccountNotes.notes);
      setTotalRowCount(accountNotesData?.getAccountNotes?.meta.totalCount || 0);
    }
  }, [accountNotesData]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'note',
        headerName: 'Note',
        flex: 2,
        minWidth: 200,
        valueGetter: params => params.row.note,
        sortable: false,
        renderCell: params => (
          <Tooltip title={params.row.note} arrow>
            <MultilineCell>{params.row.note}</MultilineCell>
          </Tooltip>
        )
      },
      {
        field: 'notedDuring',
        headerName: 'Noted During',
        flex: 1,
        flexGrow: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params =>
          params.row.modeOfCapture?.length > 0 ? (
            <Chip
              label={capitalizeLetterBeforeCharacterAddSpace(params.row.modeOfCapture)}
              variant="outlined"
              sx={{ width: 'fit-content' }}
            />
          ) : null,
        sortable: false
      },
      {
        field: 'sharedWithProvider',
        headerName: 'Shared with Provider',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => <ShareWithProviderIcon size={'small'} pinned={params.row.shareWithProvider} color={color.primaryMain} />,
        sortable: false
      },
      {
        field: 'createdOn',
        headerName: 'Created On',
        valueGetter: params => formatDate(params.row.createdAt, 'MMM DD, YYYY'),
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        sortable: false
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <IconButton
              color="primary"
              onClick={e => {
                e.preventDefault();
                pinNoteToProfile(params.row.pinToProfile ? [] : [params.row.id]).catch(console.error);
              }}
            >
              <PinnedIcon size={'small'} pinned={params.row.pinToProfile} color={color.primaryMain} />
            </IconButton>
            <IconButton
              color="primary"
              onClick={e => {
                e.preventDefault();
                showUpdateNewNoteDialog(params.row);
              }}
            >
              <EditIcon />
            </IconButton>
            {!params.row.isArchived && (
              <IconButton
                color="primary"
                onClick={e => {
                  e.preventDefault();
                  showDeleteNoteDialog(params.row.id);
                }}
              >
                <ArchiveIcon />
              </IconButton>
            )}
          </Stack>
        )
      }
    ],
    [showDeleteNoteDialog, showUpdateNewNoteDialog, isShowAllValue]
  );

  return (
    <Stack sx={{ margin: 0, padding: 0 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '16px',
          alignItems: 'center'
        }}
      >
        <H1
          textAlign="left"
          sx={{
            width: '50%',
            fontSize: '38px',
            color: color.black50,
            lineHeight: '50px',
            margin: 0
          }}
        >
          Patient Notes
        </H1>
        <Stack spacing={1} direction="row" alignItems="center">
          <FormControlLabel
            control={<Checkbox checked={isShowAllValue} onChange={event => setIsShowAllValue(event.target.checked)} />}
            label="Show All"
          />
          <Button disabled={selectionModel.length === 0} variant="contained" onClick={shareNoteWithProvider}>
            SHARE WITH PROVIDERS
          </Button>
          <IconButton
            color="primary"
            onClick={e => {
              e.preventDefault();
              showAddNewNoteDialog();
            }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      </Box>

      <Stack spacing={2}>
        <TruentityDataGrid
          name={'patient-notes'}
          autoHeight
          getRowHeight={() => 'auto'}
          rows={accountsNotesDataArray}
          rowCount={totalRowCount || 0}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          checkboxSelection
          disableRowSelectionOnClick={true}
          disableMultipleRowSelection={true}
          rowSelectionModel={selectionModel}
          hideFooterSelectedRowCount
          onRowSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
          loading={accountNotesDataLoading}
          paginationMode="server"
          columns={columns}
          sx={{ backgroundColor: '#ffffff' }}
        />
      </Stack>
    </Stack>
  );
};

export default Notes;
