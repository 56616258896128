import { ADD_ACCOUNT_NOTE } from '@/graphql/remotePatientMonitoring';
import { useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  message: string;
  onAgree: () => void;
  onDisagree: () => void;
  confirmationValidator?: string;
};

const MultiStepConfirmationDialog = ({ title, message, onAgree, onDisagree, confirmationValidator = 'YES', ...props }: Props) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [addAccountNote] = useMutation(ADD_ACCOUNT_NOTE);

  const [isConfirming, setConfirming] = useState(false);
  const [confirmationText, setConfirmationText] = useState<string>('');
  const [unenrollReason, setUnenrollReason] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const handleAction = () => {
    setConfirming(true);
  };

  const handleConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (confirmationText.trim().toUpperCase() === confirmationValidator?.toUpperCase()) {
      handleSubmitImpl();
      onAgree();
    } else {
      setIsError(true);
    }
  };

  const handleSubmitImpl = async () => {
    try {
      addAccountNote({
        variables: {
          truentityId: id,
          modeOfCapture: 'Unenroll Patient',
          description: unenrollReason,
          dateTime: null
        }
      })
        .then(response => {
          const data = response.data!.createAccountNote;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
        })
        .catch(error => {
          enqueueSnackbar('Unable to add note', {
            variant: 'error'
          });
        });
    } catch (err) {
      const failMessage: string = 'Failed to Create a note';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  return (
    <BaseDialog {...props} title={title} onClose={() => setConfirming(false)} fullWidth maxWidth="sm">
      <DialogContent>
        <Box component="form" onSubmit={handleConfirm}>
          {isConfirming ? (
            <>
              <div>
                <DialogContentText>
                  To confirm, please type the word <strong>{confirmationValidator?.toUpperCase()}</strong> in the box below.
                </DialogContentText>
                <TextField
                  variant="outlined"
                  value={confirmationText}
                  fullWidth
                  onChange={e => setConfirmationText(e.target.value.toUpperCase())}
                  required
                  sx={{ pt: 1 }}
                  error={isError}
                />
              </div>

              <div style={{ paddingTop: '12px' }}>
                <DialogContentText>Reason to unenroll</DialogContentText>
                <TextField
                  variant="outlined"
                  value={unenrollReason}
                  fullWidth
                  onChange={e => setUnenrollReason(e.target.value)}
                  required
                  multiline={true}
                  minRows={2}
                  sx={{ pt: 1 }}
                  error={isError}
                />
              </div>
            </>
          ) : (
            <DialogContentText>{message}</DialogContentText>
          )}

          <DialogActions>
            {!isConfirming ? (
              <>
                <Button onClick={onDisagree}>Cancel</Button>
                <Button onClick={handleAction} autoFocus>
                  Yes
                </Button>
              </>
            ) : (
              <>
                <Button onClick={onDisagree}>Cancel</Button>
                <Button type="submit" autoFocus>
                  Confirm
                </Button>
              </>
            )}
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default MultiStepConfirmationDialog;
