import type { SvgIconComponent } from '@mui/icons-material';

export enum Role {
  SUPER = 'SUPER_ADMIN',
  PROVIDER = 'PROVIDER_ADMIN',
  PHARMACIST = 'PHARMACIST',
  PHARMACIST_ADMIN = 'PHARMACIST_ADMIN',
  ADT_ADMIN = 'ADT_ADMIN'
}

export enum Titles {
  SUPER_ADMIN = 'superadmin',
  PHARMACY_DIRECTOR = 'pharmacydirector',
  PHARMACY_MANAGER = 'pharmacymanager',
  CARE_ADMINISTRATOR = 'careadministrator',
  CARE_MANAGER = 'caremanager',
  CARE_OBSERVER = 'careobserver',
  CARE_PROVIDER = 'careprovider',
  CARE_TECHNICIAN = 'caretechnician',
  CARE_COORDINATOR = 'carecoordinator',
  PHARMACIST = 'pharmacist',
  PHARMACY_TECHNICIAN = 'pharmacytechnician',
  PHARMACY_ASSISTANT = 'pharmacyassistant',
  PHARMACY_INTERN = 'pharmacyintern'
}

export const ROLE_TYPE_OPTIONS = [
  { label: 'Provider Admin', value: Role.PROVIDER },
  { label: 'Pharmacist', value: Role.PHARMACIST },
  { label: 'Pharmacist Admin', value: Role.PHARMACIST_ADMIN },
  { label: 'ADT Admin', value: Role.ADT_ADMIN }
];

export const TITLE_TYPE_OPTIONS: { label: string; value: Titles }[] = [
  { label: 'Pharmacy Director', value: Titles.PHARMACY_DIRECTOR },
  { label: 'Pharmacy Manager', value: Titles.PHARMACY_MANAGER },
  { label: 'Care Administrator', value: Titles.CARE_ADMINISTRATOR },
  { label: 'Care Manager', value: Titles.CARE_MANAGER },
  { label: 'Care Observer', value: Titles.CARE_OBSERVER },
  { label: 'Care Provider', value: Titles.CARE_PROVIDER },
  { label: 'Care Technician', value: Titles.CARE_TECHNICIAN },
  { label: 'Care Coordinator', value: Titles.CARE_COORDINATOR },
  { label: 'Pharmacist', value: Titles.PHARMACIST },
  { label: 'Pharmacy Technician ', value: Titles.PHARMACY_TECHNICIAN },
  { label: 'Pharmacy Assistant', value: Titles.PHARMACY_ASSISTANT },
  { label: 'Pharmacy Intern', value: Titles.PHARMACY_INTERN }
];

export const ROLE_TYPES = [
  { label: 'Pharmacy Director', value: 'pharmacydirector' },
  { label: 'Pharmacy Manager', value: 'pharmacymanager' },
  { label: 'Care Administrator', value: 'careadministrator' },
  { label: 'Care Manager', value: 'caremanager' },
  { label: 'Care Observer', value: 'careobserver' },
  { label: 'Care Provider', value: 'careprovider' },
  { label: 'Care Provider', value: 'care provider' },
  { label: 'Care Technician', value: 'caretechnician' },
  { label: 'Pharmacist', value: 'pharmacist' },
  { label: 'Pharmacy Technician ', value: 'pharmacytechnician' },
  { label: 'Pharmacy Assistant', value: 'pharmacyassistant' },
  { label: 'Pharmacy Intern', value: 'pharmacyintern' },
  { label: 'Super Admin', value: 'superadmin' },
  { label: 'Provider Admin', value: 'provideradmin' },
  { label: 'Pharmacist Admin', value: 'pharmacistadmin' },
  { label: 'ADT Admin', value: 'adtadmin' },
  { label: 'Care Coordinator', value: 'carecoordinator' }
];

export const RELYING_PARTY_OPTIONS = [{ label: 'Truentity Health', value: 'Truentity Health' }];

export enum MedicalServices {
  MTM = 'MTM',
  TCM = 'TCM',
  RPM = 'RPM'
}

export type RoleBasedIconType = {
  Icon: SvgIconComponent;
  color: 'primary' | 'secondary' | 'error' | 'info';
};
