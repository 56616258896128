export const ACCESS_TOKEN_KEY = '$accessToken';
export const REFRESH_TOKEN_KEY = '$refreshToken';
export const RELAYING_PARTY_ID_KEY = '$relayingPartyId';
export const ROLE_TYPE_KEY = '$roleType';
export const QUICK_UPLOAD_AUTH_KEY = '$quickUploadToken';
export const PROVIDER_SESSION_AUTH_KEY = '$providerSessionToken';
export const IDLE_KEY = '$idle';
export const DEBUG_FLAG_KEY = 'debug';

let tokenChangeListeners: Array<(newToken: string) => void> = [];

const notifyTokenChange = (newToken: string) => {
  tokenChangeListeners.forEach(callback => callback(newToken));
};

export default {
  clear: () => localStorage.clear(),
  get: (key: string) => localStorage.getItem(key),
  set: (key: string, value: string) => {
    localStorage.setItem(key, value);
    if (key === ACCESS_TOKEN_KEY) {
      notifyTokenChange(value);
    }
  },
  remove: (key: string) => localStorage.removeItem(key),
  multiGet: (...keys: string[]) => {
    const values: (string | null)[] = [];

    keys.forEach(key => {
      values.push(localStorage.getItem(key));
    });

    return values;
  },
  multiSet: (map: Record<string, string>) => {
    Object.keys(map).forEach(key => {
      localStorage.setItem(key, map[key]);
      if (key === ACCESS_TOKEN_KEY) {
        notifyTokenChange(map[key]);
      }
    });
  },
  subscribeToTokenChanges: (callback: (newToken: string) => void) => {
    tokenChangeListeners.push(callback);
  }
};
