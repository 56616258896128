import { H2 } from '@/components/Typography';
import type { GridProps } from '@mui/material';
import { Grid, Paper } from '@mui/material';

type InsightGridContentProps = GridProps & {
  title: string;
};
export const InsightGridContent = ({ title, children, ...props }: InsightGridContentProps) => {
  return (
    <Grid component={Paper} variant="outlined" xs sx={{ padding: 2 }} {...props}>
      <H2 sx={{ marginBottom: 2 }}>{title}</H2>
      {children}
    </Grid>
  );
};
