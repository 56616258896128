import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import Users from '@/routes/Administration/Users/users';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export type UsersData = {
  id: number;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  roleType: string;
  npiNumber?: number;
  techLicense?: number;
};

const UsersSection = () => {
  const { setContent } = useContext(SideMenuContext);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return <Outlet />;
};

export { Users };
export default UsersSection;
