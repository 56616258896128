import useDeepCompareEffect from '@/hooks/useDeepCompareEffect';
import { Box, CircularProgress, debounce } from '@mui/material';
import { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  defaultValues: any;
  onSubmit: any;
  disableProgressAnimation?: boolean;
};

const AutoSave = memo(({ defaultValues, onSubmit, disableProgressAnimation = false }: Props) => {
  const methods = useFormContext();
  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce(() => {
      console.log('Saving');
      methods.handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  const watchedData = useWatch({
    control: methods.control,
    defaultValue: defaultValues
  });

  useDeepCompareEffect(() => {
    console.log('Triggered');
    if (methods.formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);

  return (
    <Box mt={2} height={20}>
      {methods.formState.isSubmitting && !disableProgressAnimation && <CircularProgress color="secondary" size={20} />}
    </Box>
  );
});

AutoSave.displayName = 'AutoSave';

export default AutoSave;
