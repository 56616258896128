import type { User } from './accountProfile';
import type { EncounterData } from './medication';

export type FormValues = {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  zipcode?: string;
  dateOfBirth?: Date | null;
  gender?: string;
  type?: string;
  dischargeDate: Date | null;
  dischargeLocation: Date | null;
  admissionDate: Date | null;
  admitFrom?: string;
  admissionReason: string;
};

export const GENDER_OPTIONS_ARRAY = ['Male', 'Female'];

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' }
];

export const TYPE_OPTIONS = [
  { label: 'Admit', value: 'Admit' },
  { label: 'Discharge', value: 'Discharge' },
  { label: 'Transfer', value: 'Transfer' }
];

export const ADMIT_OPTIONS = [
  { label: 'Court/law enforcement Home', value: 'CourtOrLawEnforcement' },
  { label: 'Home Health Agency Hospice', value: 'HomeHealthAgencyHospice' },
  { label: 'Hospital', value: 'Hospital' },
  {
    label: 'Hospital - Emergency Hospital - Observation Inpatient Rehabilitation Facility Outpatient Services',
    value: 'EmergencyHospital'
  },
  { label: 'Physician Office', value: 'PhysicianOffice' },
  { label: 'Skilled Nursing Facility', value: 'SkilledNursingFacility' },
  { label: 'Other', value: 'Other' }
];

export type AccountCareEvent = {
  id: string;
  dischargeDate: Date;
  dischargeLocation: string;
  dischargingPhysician: string;
  admissionDate: Date;
  admissionReason: string;
  source: string;
  eventActionType: string;
  eventStatus: string;
  dischargedDisposition: string;
  admittedFrom: string;
  eventFacilityType: string;
  eventFacilityName: string;
  primaryDiagnosisCode: string;
  primaryDiagnosisDescription: string;
  eventType: string;
  readmissionRiskFlag: string;
  tcmTasks?: TcmTask[];
  account?: {
    id: string;
    user: Partial<User>;
    birthDate: Date;
    gender: string;
    address: string;
    truentityId: string;
    phone: string;
  };
  relyingPartyAdmin?: {
    id: string;
    user: {
      firstName: string;
      lastName: string;
    };
  };
  createdAt: Date;
};

export enum ComplexityLevels {
  high = 'high',
  medium = 'medium',
  low = 'low',
  unassigned = 'unassigned'
}

export type ComplexityLevel =
  | ComplexityLevels.high
  | ComplexityLevels.medium
  | ComplexityLevels.low
  | ComplexityLevels.unassigned
  | undefined;

export const FACILITY_TYPE_OPTIONS = [
  { label: 'ALF - Assisted Living Facility', value: 'AssistedLivingFacility' },
  { label: 'HHA - Home Health Agency', value: 'HomeHealthAgency' },
  { label: 'HOS - Hospital', value: 'Hospital' },
  { label: 'HOSPICE - Hospice', value: 'Hospice' },
  { label: 'LTAC - Long-Term Acute Care', value: 'LongTermAcuteCare' },
  { label: 'REHAB_HOS - Rehabilitation Hospital', value: 'RehabilitationHospital' },
  { label: 'SNF - Skilled Nursing Facility', value: 'SkilledNursingFacility' },
  { label: 'URGENT_CARE – Urgent Care Facility', value: 'UrgentCareFacility' },
  { label: 'PALLIATIVE – Palliative Care Facility', value: 'PalliativeCareFacility' },
  { label: 'Other', value: 'Other' }
];

export const DISCHARGE_TYPES = [
  { label: 'Adult Foster Care', value: 'AdultFosterCare' },
  { label: 'Against Medical Advice', value: 'AgainstMedicalAdvice' },
  { label: 'Assisted Living and Home Health Assisted Living Facility', value: 'AssistedLivingFacility' },
  { label: 'Child and Family Services', value: 'ChildAndFamilyEnforcement' },
  { label: 'Court Or Law Enforcement', value: 'CourtOrLawEnforcement' },
  { label: 'Child and Family Services', value: 'ChildAndFamilyEnforcement' },
  { label: 'Critical Access Hospital', value: 'CriticalAccessHospital' },
  { label: 'Deceased', value: 'Deceased' },
  { label: 'Extended Care Facility', value: 'ExtendedCareFacility' },
  { label: 'Group Home', value: 'GroupHome' },
  { label: 'Home', value: 'Home' },
  { label: 'Hospital', value: 'Hospital' },
  { label: 'Hospital Observation', value: 'HospitalObservation' },
  { label: 'Hospital Scheduled', value: 'HospitalScheduled' },
  { label: 'Skilled Nursing Facility Unknown', value: 'SkilledNursingFacilityUnknown' },
  { label: 'Other', value: 'Other' }
];

export const DISCHARGE_TRANSPORTATION = [
  { label: 'Own Car', value: 'OwnCar' },
  { label: 'Uber', value: 'AppCarUber' },
  { label: 'Lyft', value: 'AppCarLyft' },
  { label: 'Hospital Ambulance', value: 'HospitalAmbulance' },
  { label: 'Other Service', value: 'OtherService' }
];

export type MedrecSnapshot = {
  id: string;
  name?: string;
  description?: string;
  isMedRecAssignForRpmReport?: boolean;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  relyingPartyAdmin?: {
    id?: string;
    name?: string;
    email?: string;
  };
};

export type TcmTask = {
  id?: string;
  initialCommsDueDate: Date;
  complexityLevel: ComplexityLevel;
  finalDueDate: Date;
  billingHoldUntilDate: Date;
  reviewDischargeInfoType: string;
  reviewDischargeInfoDischargingPhysician: string;
  reviewDischargeInfoDiagnosisCode: string;
  reviewDischargeInfoReviewed: boolean;
  reviewDischargeInfoSummaryDocUrl: string;
  reviewDischargeInfoNotes: string;
  reviewDischargeSummaryDocReviewed: boolean;
  reviewDischargeInfoFrom: string;
  initialCommsDischargeToType: string;
  initialCommsCaregiverName: string;
  initialCommsCaregiverRelationship: string;
  initialCommsDischargeTransportationType: string;
  docReferralDiscussions: string;
  docCaregiverDiscussions: string;
  docTreatmentAdherenceInstructions: string;
  docSelfCareActivities: string;
  readmissionRiskFlag: string;
  finalF2fLocation: string;
  finalF2fProvider: string;
  finalF2fNotes: string;
  finalF2fDate: Date;
  finalMedsReviewed: boolean;
  finalTcmSignedAndCertified: boolean;
  encounters: EncounterData[];
  medrecSnapshot: MedrecSnapshot;
  taskStatus?: string;
};
