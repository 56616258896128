/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
//TODO:  Need to fix context implementations.  Maybe via a custom context hook
//TODO:  Need to fix reloadAccounts mechanism.  Not very good and being abused by doing multiple responsibilities, triggering reloads in user, system etc
import type { ReactNode } from 'react';
import React from 'react';

export type SideMenuContextProps = {
  drawerOpen: boolean;
  setDrawerOpen: (val: boolean) => void;
  drawerToggle: () => void;
  setContent: (content: ReactNode) => void;
  content: ReactNode;
  reloadAccounts: Date | null;
  setReloadAccounts: (val: Date | null) => void;
};

const initialValue = {
  drawerOpen: true,
  setDrawerOpen: (val: boolean) => {},
  drawerToggle: () => {},
  setContent: (content: ReactNode) => {},
  content: {},
  reloadAccounts: null,
  setReloadAccounts: (val: Date | null) => {}
} as SideMenuContextProps;

const SideMenuContext = React.createContext<SideMenuContextProps>(initialValue);

export default SideMenuContext;
