import Button from '@/components/Button';
import ManageAnnouncementDialog from '@/components/Dialogs/ManageAnnouncementDialog';
import MuiTabs from '@/components/MuiTabs';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { H3 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import AddIcon from '@mui/icons-material/Add';
import { Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AnnouncementGrid from '../Components/AnnouncementGrid';

const Announcements = () => {
  const { setContent } = useContext(SideMenuContext);
  const { showModal } = useModal();
  const { roleType } = useToken();

  const [triggerRefetch, setTriggerRefetch] = useState(false);

  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);

  const handleTriggerRefetch = useCallback(() => {
    setTriggerRefetch(prev => !prev);
  }, []);

  const tabs = useMemo(
    () => [
      {
        label: 'My Announcements',
        children: (
          <AnnouncementGrid isSuperAdmin={isSuperAdmin} triggerRefetch={triggerRefetch} handleTriggerRefetch={handleTriggerRefetch} />
        )
      },
      {
        label: 'All Announcements',
        children: (
          <AnnouncementGrid
            isAllAnnouncement
            isSuperAdmin={isSuperAdmin}
            triggerRefetch={triggerRefetch}
            handleTriggerRefetch={handleTriggerRefetch}
          />
        ),
        disabled: !isSuperAdmin
      }
    ],
    [handleTriggerRefetch, isSuperAdmin, triggerRefetch]
  );

  const handleAddAnnouncement = () => {
    const modal = showModal(ManageAnnouncementDialog, {
      title: 'Add Announcement',
      hideDialog: () => modal.hide(),
      onSuccess: () => {
        modal.hide();
        setTriggerRefetch(prev => !prev);
      }
    });
  };

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return (
    <Stack spacing={2}>
      <Paper sx={{ padding: 2 }} component={Stack} flexDirection="row" alignItems="center" justifyContent="space-between" elevation={0}>
        <H3>Announcements</H3>
        {isSuperAdmin && (
          <Button startIcon={<AddIcon />} onClick={handleAddAnnouncement}>
            Add Announcements
          </Button>
        )}
      </Paper>
      <Paper sx={{ padding: 2 }} elevation={0}>
        <MuiTabs tabs={tabs} hideDisabled />
      </Paper>
    </Stack>
  );
};

export default Announcements;
