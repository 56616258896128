import { faFilePrescription, faLeaf } from '@fortawesome/free-solid-svg-icons';
import IconWithChildrenContainer from './IconWithChildrenContainer';
import { Small } from './Typography';

export type Props = {
  type: 'SUPPLEMENT' | 'PRESCRIPTION';
  showLabel?: boolean;
};

const MedicationTypeIcon = ({ type, showLabel, ...props }: Props) => {
  const icon = type === 'SUPPLEMENT' ? faLeaf : faFilePrescription;

  return (
    <IconWithChildrenContainer icon={icon} {...props}>
      {showLabel && (
        <Small color={'default'} weight="medium" css={{ fontStyle: 'italic' }}>
          {type}
        </Small>
      )}
    </IconWithChildrenContainer>
  );
};

export default MedicationTypeIcon;
