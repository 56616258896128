/* eslint-disable @typescript-eslint/no-unsafe-argument */
//TODO: Look at how to sort out types for ShowModals (https://app.clickup.com/t/864dt4mkz)

import type { AccountCareEvent, TcmTask } from '@/types/dischargePatient';
import { useEffect, useState } from 'react';

import HorizontalStepper from '@/components/Stepper/HorizontalStepper';
import { Small } from '@/components/Typography';
import type { TaskStatuses } from '@/routes/TransitionalCareManagement/AdtQueue';
import { AccountCareEventStatuses } from '@/routes/TransitionalCareManagement/AdtQueue';
import { tempDueDate } from '@/util/apollo/cache';
import { formatDateIgnoreTZ } from '@/util/format';
import { useReactiveVar } from '@apollo/client';
import { Chip } from '@mui/material';
import { TCMWorkflowStep1 } from './TCMWorkflowStep1';
import { TCMWorkflowStep2 } from './TCMWorkflowStep2';
import { TCMWorkflowStep3 } from './TCMWorkflowStep3';
import { TCMWorkflowStep4 } from './TCMWorkflowStep4';

export type WorkflowFormData = Partial<TcmTask> & Partial<AccountCareEvent>;

export type TCMWorkflowStepProps = Omit<TCMWorkflowProps, 'save'> & {
  formData: WorkflowFormData;
  setFormData: (data: WorkflowFormData) => void;
  accountCareEventStatus?: TaskStatuses;
};

export type TCMWorkflowProps = {
  data?: Partial<AccountCareEvent>;
  tcmTask?: Partial<TcmTask>;
  assignedTo: string;
  readOnly: boolean;
  save: (formData: WorkflowFormData) => void;
  refetch?: () => void;
};

export const TCMWorkflow = ({ data, tcmTask, assignedTo, readOnly, save, refetch }: TCMWorkflowProps) => {
  const [lastActiveStep] = useState(0);
  const dueDateHack = useReactiveVar(tempDueDate);

  // temp worlflow step 1 changes
  const [formData, setFormData] = useState<WorkflowFormData>({
    reviewDischargeInfoType: '',
    reviewDischargeInfoDischargingPhysician: '',
    reviewDischargeInfoFrom: '',
    reviewDischargeInfoReviewed: false,
    reviewDischargeSummaryDocReviewed: false,
    admissionReason: '',

    initialCommsDischargeToType: '',
    initialCommsDischargeTransportationType: '',
    initialCommsCaregiverName: '',
    initialCommsCaregiverRelationship: '',

    medrecSnapshot: undefined,
    docReferralDiscussions: '',
    docCaregiverDiscussions: '',
    docTreatmentAdherenceInstructions: '',
    docSelfCareActivities: '',

    finalF2fLocation: '',
    finalF2fProvider: '',
    finalF2fNotes: '',
    finalF2fDate: undefined,
    finalMedsReviewed: false,
    finalTcmSignedAndCertified: false,

    taskStatus: ''
  });

  useEffect(() => {
    //step 1
    setFormData({
      reviewDischargeInfoType: tcmTask?.reviewDischargeInfoType ?? '',
      reviewDischargeInfoFrom: tcmTask?.reviewDischargeInfoFrom ?? '',
      reviewDischargeInfoDischargingPhysician: tcmTask?.reviewDischargeInfoDischargingPhysician ?? '',
      reviewDischargeInfoReviewed: tcmTask?.reviewDischargeInfoReviewed ?? false,
      reviewDischargeSummaryDocReviewed: tcmTask?.reviewDischargeSummaryDocReviewed ?? false,
      admissionReason: data?.admissionReason ?? '',
      // step 2
      initialCommsDischargeToType: tcmTask?.initialCommsDischargeToType ?? '',
      initialCommsDischargeTransportationType: tcmTask?.initialCommsDischargeTransportationType ?? '',
      initialCommsCaregiverName: tcmTask?.initialCommsCaregiverName ?? '',
      initialCommsCaregiverRelationship: tcmTask?.initialCommsCaregiverRelationship ?? '',
      // step 3
      medrecSnapshot: tcmTask?.medrecSnapshot,
      docReferralDiscussions: tcmTask?.docReferralDiscussions ?? '',
      docCaregiverDiscussions: tcmTask?.docCaregiverDiscussions ?? '',
      docTreatmentAdherenceInstructions: tcmTask?.docTreatmentAdherenceInstructions ?? '',
      docSelfCareActivities: tcmTask?.docSelfCareActivities ?? '',
      // step 4
      finalF2fLocation: tcmTask?.finalF2fLocation ?? '',
      finalF2fProvider: tcmTask?.finalF2fProvider ?? '',
      finalF2fNotes: tcmTask?.finalF2fNotes ?? '',
      finalF2fDate: tcmTask?.finalF2fDate,
      finalMedsReviewed: tcmTask?.finalMedsReviewed ?? false,
      finalTcmSignedAndCertified: tcmTask?.finalTcmSignedAndCertified ?? false
    });
  }, [tcmTask, data]);

  return (
    <HorizontalStepper
      steps={[
        {
          label: 'Review',
          children: (
            <TCMWorkflowStep1
              data={data}
              tcmTask={tcmTask}
              assignedTo={assignedTo}
              formData={formData}
              setFormData={setFormData}
              readOnly={readOnly}
            />
          ),
          canSave: () => !readOnly,
          trySave: () => {
            save({
              ...formData,
              taskStatus: 'ReviewDone',
              eventStatus: AccountCareEventStatuses.InProgress
            });
          },
          canNext: () => true
        },
        {
          label: 'Communicate',
          optional: (
            <Small>
              Due Date: <Chip size="small" variant="outlined" color="error" label={formatDateIgnoreTZ(tcmTask?.initialCommsDueDate)} />
            </Small>
          ),
          children: (
            <TCMWorkflowStep2
              data={data}
              tcmTask={tcmTask}
              assignedTo={assignedTo}
              formData={formData}
              setFormData={setFormData}
              readOnly={readOnly}
              refetch={refetch}
            />
          ),
          canSave: () => !readOnly,
          trySave: () => {
            save({
              ...formData,
              taskStatus: 'InitialCommunicationsDone'
            });
          },
          canNext: () => true
        },
        {
          label: 'Document',
          children: (
            <TCMWorkflowStep3
              data={data}
              tcmTask={tcmTask}
              assignedTo={assignedTo}
              formData={formData}
              setFormData={setFormData}
              readOnly={readOnly}
            />
          ),
          canSave: () => !readOnly,
          trySave: () => {
            save({
              ...formData,
              taskStatus: 'DocumentationDone'
            });
          },
          canNext: () => true
        },
        {
          label: 'Finalize',
          optional: (
            <Small>
              Due Date:{' '}
              <Chip size="small" variant="outlined" color="error" label={dueDateHack /*formatDateIgnoreTZ(tcmTask?.finalDueDate)*/} />
            </Small>
          ),
          children: (
            <TCMWorkflowStep4
              data={data}
              tcmTask={tcmTask}
              assignedTo={assignedTo}
              formData={formData}
              setFormData={setFormData}
              readOnly={readOnly}
            />
          ),
          canSave: () => !readOnly && formData.finalMedsReviewed === true && formData.finalTcmSignedAndCertified === true,
          trySave: () => {
            save({
              ...formData,
              taskStatus: 'FinalF2FDone',
              eventStatus: AccountCareEventStatuses.Processed
            });
          },
          canNext: () => true
        }
      ]}
      currentStep={lastActiveStep}
    />
  );
};
