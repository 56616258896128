import type { UseFormReset, Control, SubmitHandler, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { AddUserFormValuesType } from '@/routes/Administration/Components/AddUserForm';
import { addUserFormDefault } from '@/routes/Administration/Components/AddUserForm';
import { useMutation } from '@apollo/client';
import type { AddRelyingPartyAdminResponse } from '@/graphql/administration';
import { ADD_RELYING_PARTY_ADMIN } from '@/graphql/administration';
import { getRoleTypeUsingTitle } from '@/util/adminstration';
import { useSnackbar } from 'notistack';
import type { BaseSyntheticEvent } from 'react';
import type { FormState } from 'react-hook-form/dist/types/form';

type UseAddNewUserType = {
  control: Control<AddUserFormValuesType>;
  setValue: UseFormSetValue<AddUserFormValuesType>;
  getValues: UseFormGetValues<AddUserFormValuesType>;
  watch: UseFormWatch<AddUserFormValuesType>;
  reset: UseFormReset<AddUserFormValuesType>;
  onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
  addUserFormState: FormState<AddUserFormValuesType>;
};

type UseAddNewUserProps = {
  onUserAdd?: () => void;
};

export const useAddNewUser: (props: UseAddNewUserProps) => UseAddNewUserType = ({ onUserAdd }) => {
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit: addUserHandleSubmit,
    formState: addUserFormState
  } = useForm<AddUserFormValuesType>({ defaultValues: addUserFormDefault });

  const [addUser] = useMutation<AddRelyingPartyAdminResponse>(ADD_RELYING_PARTY_ADMIN);

  const handleNewUserSave = async (values: AddUserFormValuesType) => {
    try {
      values.roleType = getRoleTypeUsingTitle(values.title);

      if (!values.isEmailLoginOtp && !values.isTextLoginOtp) {
        return enqueueSnackbar('At least one OTP method must be enabled.', { variant: 'error' });
      }

      const response = await addUser({
        variables: {
          ...values
        }
      });

      if (response.data?.addRelyingPartyAdmin?.status === 'Success') {
        enqueueSnackbar(response.data?.addRelyingPartyAdmin?.message, { variant: 'success' });
        if (onUserAdd) {
          reset();
          onUserAdd();
        }
      } else {
        enqueueSnackbar(response.data?.addRelyingPartyAdmin?.message ?? 'Failed to add new user', {
          variant: 'error'
        });
      }
    } catch (err) {
      enqueueSnackbar('Unable to add user', {
        variant: 'error'
      });
    }
  };

  const onAddUserFormSubmit: SubmitHandler<AddUserFormValuesType> = data => handleNewUserSave(data);

  return {
    control,
    setValue,
    getValues,
    watch,
    reset,
    addUserHandleSubmit,
    addUserFormState,
    onSubmit: addUserHandleSubmit(onAddUserFormSubmit)
  };
};

export default useAddNewUser;
