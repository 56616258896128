import { Body1, H3 } from '@/components/Typography';
import Table, { TableCell, TableRow } from '@/elements/Table';
import { color } from '@/styles/assets/colors';
import { Divider, Paper, Stack } from '@mui/material';

export type InteractionsTableProps = {
  interactions: [];
  called: boolean;
  loading: boolean;
};

const MedInteractions = ({ interactions, called, loading }: InteractionsTableProps) => {
  const isDataLoaded = () => called && !loading && interactions;

  const isThereData = () => isDataLoaded() && interactions.length > 0;

  const renderItems = (item, index) => {
    const names = item.meds.map(function (med, index) {
      if (index != 0) {
        return ', ' + med['name'];
      } else {
        return med['name'];
      }
    });

    return (
      <TableRow key={index}>
        <TableCell>
          <Paper component={Stack} spacing={2} sx={{ padding: '10px', borderRadius: 0 }}>
            <Body1 color={color.truentityCyan[600]} size="lg">
              {names}
            </Body1>
            <Divider />
            <Body1>{item.description}</Body1>
          </Paper>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {isThereData() && (
        <Stack>
          <Table>
            <tbody>{interactions.map(renderItems)}</tbody>
          </Table>
        </Stack>
      )}
      {!isThereData() && (
        <H3 color="title" weight="normal">
          No Interactions Found
        </H3>
      )}
    </>
  );
};

export default MedInteractions;
