import Button from '@/components/Button';
import { Alert, AlertTitle, Stack } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
};

export default function NoPatientRecordDialog({ title, hideDialog, ...props }: Props) {
  return (
    <BaseDialog {...props} title={title}>
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="warning">
            <AlertTitle sx={{ margin: '0 auto' }}>No records found</AlertTitle>
          </Alert>

          <Stack direction={'row'} spacing={1}>
            <Button type="submit" a11yLabel={'OK'} fullWidth onClick={hideDialog} />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
}
