import { DialogActions, DialogContent } from '@mui/material';
import Alert from '../Alert';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  importResponseMessage: string;
  onConfirm: () => void;
};

const DirectImportNoConsentDialog = ({ title, importResponseMessage, hideDialog, onConfirm, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <Alert description={importResponseMessage} status={'warning'} />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button a11yLabel="Ok" appearance="primary" onClick={onConfirm} />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default DirectImportNoConsentDialog;
