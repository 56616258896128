import type { AccountMessage, PatientConversation } from '@/types/chat';
import type Meta from '@/types/meta';
import { gql } from '@apollo/client';

export type GetPatientConversationsResponse = {
  accountConversations: PatientConversation[];
  meta: Meta;
};

export type GetMessagesByConversationResponse = {
  accountMessagesByAccountConversation: {
    date: string;
    messages: AccountMessage[];
  }[];
};

export const GET_MESSAGES_BY_CONVERSATION = gql`
  query GetAccountMessagesByAccountConversationQuery($accountConversationId: String!) {
    accountMessagesByAccountConversation(accountConversationId: $accountConversationId) {
      date
      messages {
        id
        messageBody
        createdAt
        sourceType
        messageDirection
        isRead
        messageTo
        messageFrom
        createdAt
      }
    }
  }
`;

export const GET_PATIENT_CONVERSATIONS = gql`
  query AccountsConversationsAllQuery($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountConversationFilterOptionsInput!) {
    accountsConversationsAll(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountConversations {
        id
        name
        description
        lastMessageAt
        accountLastMessageAt
        adminLastMessageAt
        createdAt
        isActive
        accountPhoneNumber
        adminPhoneNumber
        account {
          id
          truentityId
          user {
            firstName
            lastName
          }
        }
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetPatientConversationsByPatientResponse = {
  accountsConversationsByAccount: {
    accountConversations: PatientConversation[];
    meta: Meta;
  };
};

export const GET_PATIENT_CONVERSATIONS_BY_PATIENT = gql`
  query AccountsConversationsByAccountQuery(
    $pageNum: Int!
    $pageSize: Int!
    $truentityId: String!
    $filterOptions: AccountConversationFilterOptionsInput!
  ) {
    accountsConversationsByAccount(pageNum: $pageNum, pageSize: $pageSize, truentityId: $truentityId, filterOptions: $filterOptions) {
      accountConversations {
        id
        name
        description
        lastMessageAt
        accountLastMessageAt
        adminLastMessageAt
        createdAt
        isActive
        accountPhoneNumber
        adminPhoneNumber
        account {
          id
          truentityId
          user {
            firstName
            lastName
          }
        }
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;
