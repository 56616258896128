import Alert from '@/components/Alert';
import Button from '@/components/Button';
import PatientDetailContext from '@/context/patientDetailContext';
import Table, { TableCell, TableContainer, TableHead, TableHeading, TableRow } from '@/elements/Table';
import styled from '@/styles';
import { formatDate } from '@/util/format';
import { gql, useMutation } from '@apollo/client';
import { Checkbox, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const LOAD_MEDICATIONS = gql`
  mutation loadMedicationsFromExternal($truentityId: String!, $s3Key: String!) {
    loadMedicationsFromExternal(truentityId: $truentityId, s3Key: $s3Key) {
      temporalAccountMedications {
        createdAt
        dateWritten
        daysSupply
        fillDate
        id
        instructions
        name
        pharmacyName
        provider
        quantity
        refillsRemaining
        updatedAt
      }
    }
  }
`;

const IMPORT_MEDICATIONS = gql`
  mutation importMedicationsFromExternal($temporalIds: [String!]!) {
    importMedicationsFromExternal(temporalIds: $temporalIds) {
      errors
    }
  }
`;

const ButtonsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
});

const ImportPreview = () => {
  const { s3_key, reloadMeds } = useContext(PatientDetailContext);
  const { id } = useParams();
  const [medications, setMedications] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [temporalIds, setTemporalIds] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loadMedicationsFromExternal, { data, called, loading }] = useMutation(LOAD_MEDICATIONS);

  const [importMedicationsFromExternal, { data: importData }] = useMutation(IMPORT_MEDICATIONS);

  const handleMedicationSelected = (item: any) => {
    const selected = medications.map(element =>
      element.id === item.id
        ? {
            ...element,
            checked: !element.checked
          }
        : element
    );
    setMedications(selected);
  };

  const selectAllMedications = () => {
    setIsCheckAll(value => !value);
  };

  const onHandleSubmit = async () => {
    const medicationsToSend = medications.filter(item => item.checked).map(element => element.id);
    setTemporalIds(medicationsToSend);
    try {
      const result = await importMedicationsFromExternal({
        variables: {
          temporalIds: medicationsToSend
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const isDataLoaded = () => called && !loading && data && data.loadMedicationsFromExternal.temporalAccountMedications;

  useEffect(() => {
    if (s3_key) {
      loadMedicationsFromExternal({
        variables: {
          truentityId: id,
          s3Key: s3_key
        }
      });
    } else {
      setEmptyData(true);
    }
  }, [s3_key]);

  useEffect(() => {
    const allMedications = medications.map(item => ({
      ...item,
      checked: isCheckAll
    }));
    setMedications(allMedications);
  }, [isCheckAll]);

  useEffect(() => {
    if (isDataLoaded()) {
      const medicationsCheckbox = data.loadMedicationsFromExternal.temporalAccountMedications.map(item => ({
        id: item.id,
        name: item.name,
        createdAt: item.createdAt,
        dateWritten: item.dateWritten,
        daysSupply: item.daysSupply,
        fillDate: item.fillDate,
        instructions: item.instructions,
        pharmacy: item.pharmacyName,
        provider: item.provider,
        quantity: item.quantity,
        refillsRemaining: item.refillsRemaining,
        updatedAt: item.updatedAt,
        checked: false
      }));
      setMedications(medicationsCheckbox);
      if (data.loadMedicationsFromExternal.temporalAccountMedications.length === 0) {
        setEmptyData(true);
      }
    }
  }, [isDataLoaded()]);

  useEffect(() => {
    if (importData) {
      if (importData.importMedicationsFromExternal) {
        showSnackbar(true);
        reloadMeds();
      } else {
        showSnackbar(false);
      }
    }
  }, [importData]);

  const showSnackbar = (success: boolean) => {
    const message = success ? 'Medication Added' : 'Something went wrong, try again';
    const variant = success ? 'success' : 'error';

    enqueueSnackbar(message, { variant });

    if (success) {
      navigate(`/patients/${id}/details/`, {
        replace: true
      });
    }
  };

  const renderItems = (item: any) => {
    return (
      <TableRow key={item.id}>
        <TableCell>
          <Checkbox id={`checkbox-${item.id}`} checked={item.checked} onChange={() => handleMedicationSelected(item)} />
        </TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell>{formatDate(item.createdAt)}</TableCell>
        <TableCell>{formatDate(item.dateWritten)}</TableCell>
        <TableCell>{item.daysSupply}</TableCell>
        <TableCell>{formatDate(item.fillDate)}</TableCell>

        <TableCell>{item.instructions}</TableCell>
        <TableCell>{item.pharmacyName}</TableCell>
        <TableCell>{item.provider}</TableCell>
        <TableCell>{item.quantity}</TableCell>
        <TableCell>{item.refillsRemaining}</TableCell>
        <TableCell>{formatDate(item.updatedAt)}</TableCell>
      </TableRow>
    );
  };

  return (
    <Stack direction={'column'} spacing={6}>
      <Paper component={TableContainer} elevation={4}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>
                <Checkbox id={`checkbox`} onChange={selectAllMedications} defaultChecked={isCheckAll} />
              </TableHeading>
              <TableHeading> Name</TableHeading>
              <TableHeading>Created</TableHeading>
              <TableHeading>Written</TableHeading>
              <TableHeading>Days Supply</TableHeading>
              <TableHeading>Fill Date</TableHeading>
              <TableHeading>SIG</TableHeading>
              <TableHeading>Pharmacy</TableHeading>
              <TableHeading>Provider</TableHeading>
              <TableHeading>Quantity</TableHeading>
              <TableHeading>Refills Remainig</TableHeading>
              <TableHeading>Updated</TableHeading>
            </TableRow>
          </TableHead>

          <tbody>{isDataLoaded() && medications.map(item => renderItems(item))}</tbody>
        </Table>
        {emptyData && <Alert description="There are no medication data " status="warning" title="No Medications data" />}
      </Paper>
      {isDataLoaded() && (
        <ButtonsContainer>
          <Button a11yLabel="Import" label="Import" onClick={onHandleSubmit} />
        </ButtonsContainer>
      )}
    </Stack>
  );
};
export default ImportPreview;
