import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';
import Button from './Button';
import type React from 'react';
import AddCompanyDialog from '@/components/Dialogs/AddCompanyDialog';

type CompanyAddProps = {
  onCompanyAdd: () => void;
};

const CompanyAdd: React.FC<CompanyAddProps> = ({ onCompanyAdd }) => {
  const { showModal } = useModal();

  const showAddCompanyDialog = () => {
    const modal = showModal(AddCompanyDialog, {
      title: 'Add Company',
      onCompanyAdd: onCompanyAdd,
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  return <Button startIcon={<AddIcon />} label="Add Company" onClick={showAddCompanyDialog} />;
};

export default CompanyAdd;
