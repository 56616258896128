import { Grid } from '@mui/material';
import type { ReactNode } from 'react';

type KpiGridItemProps = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  children: ReactNode;
};
export const KpiGridItem = ({ children, ...props }: KpiGridItemProps) => {
  return (
    <Grid item xs={12} sm={6} lg={3} sx={{ padding: theme => theme.spacing(1) }} {...props}>
      {children}
    </Grid>
  );
};
