import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation SendTextMessage($truentityId: String!, $textMessage: String!, $toPhoneNumber: String!, $fromPhoneNumber: String!) {
    sendTextMessage(
      truentityId: $truentityId
      textMessage: $textMessage
      toPhoneNumber: $toPhoneNumber
      fromPhoneNumber: $fromPhoneNumber
    ) {
      status
      message
    }
  }
`;

export const GET_ADMIN_CONTACT_NUMBERS = gql`
  query getRelyingPartyAdminContacts {
    relyingPartyAdminContacts {
      value
      relyingPartyAdminId
      contactType
      seqNum
      category
    }
  }
`;

export const UPDATE_ADMIN_CONTACT_NUMBERS = gql`
  mutation UpdateRelyingPartyAdminContact($relyingPartyAdminId: String!, $phone: String, $fax: String) {
    updateRelyingPartyAdminContact(relyingPartyAdminId: $relyingPartyAdminId, phone: $phone, fax: $fax) {
      message
      status
    }
  }
`;

export const READ_MESSAGES = gql`
  mutation UpdateAdminPatientMessagesReadStatus($truentityId: String!) {
    updateAdminPatientMessagesReadStatus(truentityId: $truentityId) {
      status
      message
    }
  }
`;

export const GET_MESSAGE_TEMPLATES = gql`
  query getMessageTemplateItemsByCategoryQuery {
    messageTemplateItemsByCategory {
      category
      messageTemplateItems {
        id
        createdAt
        updatedAt
        text
        relyingPartyId
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetAccountMessagesQuery($truentityId: String!) {
    accountMessages(truentityId: $truentityId) {
      id
      messageBody
      createdAt
      sourceType
      messageDirection
      isRead
      messageTo
      messageFrom
      createdAt
    }
  }
`;

export const GET_MESSAGES_BY_CONTACT = gql`
  query GetAccountMessagesByContactQuery($truentityId: String!, $contactNumber: String!) {
    accountMessagesByContact(truentityId: $truentityId, contactNumber: $contactNumber) {
      id
      messageBody
      createdAt
      sourceType
      messageDirection
      isRead
      messageTo
      messageFrom
      createdAt
    }
  }
`;

export const GET_DIALPAD_USERS = gql`
  query GetDialpadUsersQuery($relyingPartyAdminId: String!) {
    getDialpadUsers(relyingPartyAdminId: $relyingPartyAdminId) {
      dialpadUserFirstName
      dialpadUserLastName
      dialpadUserId
      dialpadUserPhoneNumbers
      isAssigned
    }
  }
`;
