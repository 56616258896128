import { READ_ANNOUNCEMENT } from '@/graphql/announcements';
import type { Announcement } from '@/types/announcement';
import { useMutation } from '@apollo/client';
import type { Dispatch, SetStateAction } from 'react';
import type { LastReadAnnouncement } from '.';
import PrimaryAnnouncementDialog from '../Dialogs/PrimaryAnnouncementDialog';

type Props = {
  announcement: Announcement;
  setAnnouncement: Dispatch<SetStateAction<Announcement | null>>;
  setLastReadAnnouncement: Dispatch<SetStateAction<LastReadAnnouncement>>;
};

const PrimaryAnnouncement = ({ announcement, setAnnouncement, setLastReadAnnouncement }: Props) => {
  const [readAnnouncement] = useMutation(READ_ANNOUNCEMENT, {
    onCompleted: data => {
      if (data.readAnnouncement.status === 'Failure') {
        console.error(data.readAnnouncement.message);
      } else {
        setLastReadAnnouncement(prev => ({ ...prev, primaryAnnouncementId: announcement.id }));
      }
      setAnnouncement(null);
    },
    onError: error => {
      console.error(error.message);
      setAnnouncement(null);
    }
  });

  const open = Boolean(announcement);

  const handleClose = (announcementId: string) => {
    readAnnouncement({
      variables: {
        announcementId: announcementId
      }
    });
  };

  return (
    <>
      <PrimaryAnnouncementDialog open={open} handleClose={handleClose} title="" announcement={announcement} />
    </>
  );
};

export default PrimaryAnnouncement;
