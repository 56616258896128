import client from '@/util/apollo/client';
import { Stack } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LicenseInfo } from '@mui/x-license-pro';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { ComingSoon } from './components/ComingSoon';
import { Body1 } from './components/Typography';
// styling
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/mui-theme';
// providers
import { MessageProvider } from '@/context/patientMessageContext';
import { SettingsProvider } from '@/context/settingsContext';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import ModalProvider from 'mui-modal-provider';
import { SnackbarProvider } from 'notistack';
// routes
import ActivityTracker from '@/components/ActivityTracker';
import BreadcrumbLink from '@/components/BreadcrumbLink';
import EnvironmentBar from '@/components/EnvironmentBar';
import AdministrationSection, {
  AssignedPatients,
  AssignedStores,
  AssignedTasks,
  Organizations,
  OrganizationSection,
  StoreSection,
  SystemSection
} from '@/routes/Administration';
import Checklists from '@/routes/Administration/Checklists';
import { Patients as PatientOnboarding } from '@/routes/Administration/Patients';
import AllCompanies from '@/routes/Administration/System/AllCompanies';
import UsersSection, { Users } from '@/routes/Administration/Users';
import Authenticated from '@/routes/Authenticated';
import Agreement from '@/routes/Authenticated/Agreement';
import Login from '@/routes/Login';
import PatientDetailsSection, {
  DischargeSummary,
  EncounterDetails,
  MedicationDetails,
  MedicationHistory,
  MedicationsRoute,
  MedRecHistory,
  PatientRequestHistory,
  QuickUploadHistory,
  QuickUploadItems,
  TasksRoute
} from '@/routes/PatientDetails';
import PatientConversations from '@/routes/PatientDetails/Messaging/PatientConversations';
import CarePlan from '@/routes/PatientDetails/RemotePatientMonitoring/CarePlan';
import ClinicalSummary from '@/routes/PatientDetails/RemotePatientMonitoring/ClinicalSummary';
import Configuration from '@/routes/PatientDetails/RemotePatientMonitoring/Configuration';
import ConsentManagement from '@/routes/PatientDetails/RemotePatientMonitoring/ConsentManagement';
import MedConsults from '@/routes/PatientDetails/RemotePatientMonitoring/MedConsults';
import MedReconciliation from '@/routes/PatientDetails/RemotePatientMonitoring/MedReconciliation';
import Reports from '@/routes/PatientDetails/RemotePatientMonitoring/Reports';
import RpmDevices from '@/routes/PatientDetails/RemotePatientMonitoring/RpmDevices';
import RpmWorkflow from '@/routes/PatientDetails/RemotePatientMonitoring/RpmWorkflow';
import PatientsSection, { PatientInsights, Patients } from '@/routes/Patients';
import PatientsList from '@/routes/Patients/PatientsList';
import StarredPatients from '@/routes/Patients/StarredPatients';
import ProviderSection from '@/routes/Providers/index';
import RpmReportsCommunications from '@/routes/RemotePatientMonitoring/RpmReportsCommunications';
import RequestsSection, { Requests } from '@/routes/Request';
import RpmEnrollmentRecord from '@/routes/RpmReview/RpmEnrollmentRecord';
import RpmEnrollmentRecordViewAll from '@/routes/RpmReview/RpmEnrollmentRecordViewAll';
import RpmEnrollmentReviewSection from '@/routes/RpmReview/RpmEnrollmentReviewSection';
import RpmReviewStart from '@/routes/RpmReview/RpmReviewStart';
import RpmSection from '@/routes/RpmReview/RpmSection';
import SwitchCompany from '@/routes/SwitchCompany';
import { truentityTheme } from '@/styles';
import HomeIcon from '@mui/icons-material/Home';
import { Suspense } from 'react';
import InvalidSession from './components/InvalidSession';
import Announcements from './routes/Administration/Announcements';
import Dashboard from './routes/Administration/Dashboard';
import HealthPlans from './routes/Administration/HealthPlans/HealthPlans';
import InvoiceDetails from './routes/Administration/Invoices/InvoiceDetails';
import Invoices from './routes/Administration/Invoices/Invoices';
import Logs from './routes/Administration/Logs';
import MyPerformance from './routes/Administration/MyPerformances/MyPerformance';
import ProvidersPage from './routes/Administration/Providers/Providers';
import Company from './routes/Administration/System/Company';
import Timesheets from './routes/Administration/Timesheets/Timesheets';
import UserDetailsSection from './routes/Administration/Users/DetailsSection';
import RpmOnboarding from './routes/GlobalSearch/RpmOnboarding';
import HomeDashboard from './routes/Home/HomeDashboard';
import ForgotPassword from './routes/Login/forgotPassword';
import TwoFactorLogin from './routes/Login/twoFactorLogin';
import DirectImport from './routes/PatientDetails/directImport';
import AllFollowUps from './routes/PatientDetails/FollowUps/AllFollowUps';
import { Allergies } from './routes/PatientDetails/HealthApps/allergies';
import { Energy } from './routes/PatientDetails/HealthApps/energy';
import HealthAppImmunizations from './routes/PatientDetails/HealthApps/immunizations';
import HealthAppMedications from './routes/PatientDetails/HealthApps/medications';
import { Vitals } from './routes/PatientDetails/HealthApps/vitals';
import ImportPreview from './routes/PatientDetails/importPreview';
import AdtEventDetails from './routes/PatientDetails/imports/AdtEventDetails';
import PioneerImports from './routes/PatientDetails/imports/PioneerImports';
import PioneerObjects from './routes/PatientDetails/imports/PioneerObjects';
import ActivityLogs from './routes/PatientDetails/RemotePatientMonitoring/ActivityLogs';
import DataReadings from './routes/PatientDetails/RemotePatientMonitoring/DataReadings';
import Notes from './routes/PatientDetails/RemotePatientMonitoring/Notes';
import TasksList from './routes/PatientDetails/Tasks/tasksList';
import { TCMTasks } from './routes/PatientDetails/TransitionalCareManagement';
import TriageMedicationSection, { TriageMedicationBoard, TriageMedicationList } from './routes/PatientDetails/Triage/index';
import UploadFile from './routes/PatientDetails/uploadFile';
import AllPatientsConversations from './routes/Patients/AllPatientsConversations';
import MedHistoryRequests from './routes/Patients/MedHistoryRequests';
import PatientsListWithImportedData from './routes/Patients/PatientsListWithImportedData';
import ManageClientOrgAndStore from './routes/Providers/manageClientOrgAndStore';
import PatientProfile from './routes/Providers/patientProfile';
import RemotePatientMonitoringSection from './routes/RemotePatientMonitoring';
import RpmAlertsList from './routes/RemotePatientMonitoring/RpmAlertsList';
import RpmClaims from './routes/RemotePatientMonitoring/RpmClaims';
import RpmDashboard from './routes/RemotePatientMonitoring/RpmDashboard';
import RpmEnrollment from './routes/RemotePatientMonitoring/RpmEnrollment';
import RpmPatients from './routes/RemotePatientMonitoring/RpmPatients';
import RpmPatientSchedules from './routes/RemotePatientMonitoring/RpmPatientSchedules';
import RpmTenoviDevices from './routes/RemotePatientMonitoring/RpmTenoviDevices';
import Billing from './routes/Reports/Billing';
import Encounters from './routes/Reports/Encounters';
import HealthPlan from './routes/Reports/HealthPlan';
import Performance from './routes/Reports/Performance';
import ReportDashboard from './routes/Reports/ReportDashboard';
import ReportsSection from './routes/Reports/ReportsSection';
import RpmCompanies from './routes/Reports/RpmCompanies';
import RpmProviders from './routes/Reports/RpmProviders';
import Tasks from './routes/Reports/Tasks';
import RpmAllMonthlyReports from './routes/RpmReview/RpmAllMonthlyReports';
import RpmMonthlyReport from './routes/RpmReview/RpmMonthlyReport';
import TasksSection from './routes/Tasks';
import MyTasksReportDashboard from './routes/Tasks/MyReportDashboard';
import MyTasks from './routes/Tasks/MyTasks';
import TransitionalCareManagementSection, { AdtQueue, AdtQueueV1, DischargePatient } from './routes/TransitionalCareManagement';
import TcmDashboard from './routes/TransitionalCareManagement/TcmDashboard';
import QuickUploadSection, { QuickUploadStart } from './routes/Upload';
import QuickUploadThanks from './routes/Upload/thanks';
import QuickUploadMedications from './routes/Upload/upload-medications';

LicenseInfo.setLicenseKey('ba8158d80bdf29615bea860b4ad5ea2dTz05NDc1NyxFPTE3NTMzNjEzMTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const Providers = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <ModalProvider>
              <SettingsProvider>
                <MessageProvider>{children}</MessageProvider>
              </SettingsProvider>
            </ModalProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

const AppRoot = () => {
  return (
    <Providers>
      <div className={`inner-root ${truentityTheme}`}>
        <ActivityTracker />
        <EnvironmentBar />
        <Suspense fallback={<span>Loading...</span>}>
          <Outlet />
        </Suspense>
      </div>
    </Providers>
  );
};

type RoutePathDefinition = RouteObject & {
  title?: string;
  nav?: boolean;
  children?: RoutePathDefinition[];
  path?: string;
};

const providerSectionRoutes = [
  // 2024-02-15 Commented this route since we currently use new patient onboard flow
  // {
  //   path: 'manage',
  //   Component: Manage,
  //   handle: {
  //     crumb: () => <BreadcrumbLink>Manage</BreadcrumbLink>
  //   }
  // },
  {
    path: 'update-profile',
    Component: PatientProfile,
    handle: {
      crumb: () => <BreadcrumbLink>Update</BreadcrumbLink>
    }
  },
  {
    path: 'client-org-store',
    Component: ManageClientOrgAndStore,
    handle: {
      crumb: () => <BreadcrumbLink>Organizations and Stores</BreadcrumbLink>
    }
  }
];

const patientSectionRoutes = [
  {
    path: ':id',
    children: [
      {
        path: 'details',
        Component: PatientDetailsSection,
        handle: {
          crumb: data => {
            return <BreadcrumbLink to={data.pathname}>{data.params.id}</BreadcrumbLink>;
          }
        },
        children: [
          {
            path: '',
            Component: () => (
              <Body1 component={Stack} alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
                No Medication Selected
              </Body1>
            )
          },
          {
            path: 'patient-history',
            handle: { crumb: () => <BreadcrumbLink>Patient History</BreadcrumbLink> },
            Component: PatientRequestHistory
          },
          {
            path: 'medication-history',
            handle: { crumb: () => <BreadcrumbLink>Medication History</BreadcrumbLink> },
            Component: MedicationHistory
          },
          {
            handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/triage/list'}>Medications</BreadcrumbLink> }, // 2024-02-15 Commented this route since we currently use new patient onboarden
            path: 'medications',
            children: [
              {
                handle: { crumb: data => <BreadcrumbLink to={data.pathname}>Tasks</BreadcrumbLink> },
                path: 'tasks',
                children: [
                  { path: 'list', Component: TasksList },
                  { path: ':type/:taskType', Component: TasksRoute },
                  { path: ':type/:taskType/:taskId', Component: TasksRoute }
                ]
              },
              {
                handle: { crumb: () => <BreadcrumbLink>Triage</BreadcrumbLink> },
                path: 'triage',
                Component: TriageMedicationSection,
                children: [
                  { path: 'board', Component: TriageMedicationBoard },
                  { path: 'list', Component: TriageMedicationList }
                ]
              },
              { path: ':type', Component: MedicationsRoute },
              {
                path: ':type/:medicationId',
                Component: MedicationsRoute,
                handle: { crumb: data => <BreadcrumbLink>{data.params.medicationId}</BreadcrumbLink> }
              },
              { path: 'view/:medicationId', Component: MedicationDetails },
              {
                path: 'encounters',
                children: [
                  {
                    path: 'view/:encounterId',
                    Component: EncounterDetails,
                    handle: { crumb: () => <BreadcrumbLink>Encounters</BreadcrumbLink> }
                  }
                ]
              },
              {
                path: 'med-rec/:medRecId',
                Component: MedRecHistory,
                handle: { crumb: () => <BreadcrumbLink>MedRec History</BreadcrumbLink> }
              }
            ]
          },
          {
            path: 'tcm-tasks/list',
            handle: { crumb: () => <BreadcrumbLink>TCM Tasks</BreadcrumbLink> },
            Component: TCMTasks
          },
          {
            path: 'adt-events/view/:eventId',
            handle: { crumb: () => <BreadcrumbLink>ADT Events</BreadcrumbLink> },
            Component: DischargeSummary
          },
          {
            path: 'adt-events/event-details/view/:eventId',
            handle: { crumb: () => <BreadcrumbLink>ADT Event Details</BreadcrumbLink> },
            Component: AdtEventDetails
          },
          {
            path: 'imports/view/:eventId',
            handle: { crumb: () => <BreadcrumbLink>Imports</BreadcrumbLink> },
            Component: PioneerImports
          },
          {
            path: 'upload-file',
            handle: { crumb: () => <BreadcrumbLink>Upload File</BreadcrumbLink> },
            Component: UploadFile
          },
          {
            path: 'direct-import',
            handle: { crumb: () => <BreadcrumbLink>Direct Import</BreadcrumbLink> },
            Component: DirectImport
          },
          {
            path: 'import-preview',
            handle: { crumb: () => <BreadcrumbLink>Import Preview</BreadcrumbLink> },
            Component: ImportPreview
          },
          {
            path: 'rpm',
            handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/readings'}>RPM</BreadcrumbLink> },
            children: [
              { path: 'readings', Component: DataReadings, handle: { crumb: () => <BreadcrumbLink>Readings</BreadcrumbLink> } },
              {
                path: 'patient-setup',
                handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/care-activity'}>Patient Setup</BreadcrumbLink> },
                Component: RpmWorkflow,
                children: [
                  {
                    path: 'care-activity',
                    handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/clinical-summary'}>Care Activity</BreadcrumbLink> },
                    children: [
                      {
                        path: 'clinical-summary',
                        handle: { crumb: () => <BreadcrumbLink>Clinical Summary</BreadcrumbLink> },
                        Component: ClinicalSummary
                      },
                      {
                        path: 'medication-reconciliation',
                        handle: { crumb: () => <BreadcrumbLink>Medication Reconciliation</BreadcrumbLink> },
                        Component: MedReconciliation
                      },

                      {
                        path: 'configuration',
                        handle: { crumb: () => <BreadcrumbLink>Configuration</BreadcrumbLink> },
                        Component: Configuration
                      }
                    ]
                  },
                  {
                    path: 'encounter',
                    handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/consent-management'}>Encounter</BreadcrumbLink> },
                    children: [
                      {
                        path: 'consent-management',
                        handle: { crumb: () => <BreadcrumbLink>Consent Management</BreadcrumbLink> },
                        Component: ConsentManagement
                      },
                      {
                        path: 'care-plan',
                        handle: { crumb: () => <BreadcrumbLink>Care Plan</BreadcrumbLink> },
                        Component: CarePlan
                      },
                      {
                        path: 'devices',
                        handle: { crumb: () => <BreadcrumbLink>Devices</BreadcrumbLink> },
                        Component: RpmDevices
                      },
                      {
                        path: 'medical-consults',
                        handle: { crumb: () => <BreadcrumbLink>Intake (HPI)</BreadcrumbLink> },
                        Component: MedConsults
                      }
                    ]
                  }
                ]
              },
              { path: 'activities', Component: ActivityLogs, handle: { crumb: () => <BreadcrumbLink>Activity Logs</BreadcrumbLink> } },
              { path: 'alerts', Component: RpmAlertsList },
              {
                path: 'reports',
                handle: { crumb: () => <BreadcrumbLink>Reports</BreadcrumbLink> },
                Component: Reports
              }
            ]
          },
          {
            path: 'conversations',
            handle: { crumb: () => <BreadcrumbLink>Conversations</BreadcrumbLink> },
            Component: PatientConversations
          },
          {
            path: 'notes',
            handle: { crumb: () => <BreadcrumbLink>Notes</BreadcrumbLink> },
            Component: Notes
          },
          {
            path: 'pioneerrx',
            handle: { crumb: () => <BreadcrumbLink>Pioneer RX</BreadcrumbLink> },
            Component: PioneerObjects
          },
          {
            path: 'follow-ups',
            handle: { crumb: () => <BreadcrumbLink>Follow-ups</BreadcrumbLink> },
            Component: AllFollowUps
          },
          {
            path: 'shared',
            children: [
              {
                path: 'health-app/medications',
                Component: HealthAppMedications,
                handle: { crumb: () => <BreadcrumbLink>Health App Medications</BreadcrumbLink> }
              },
              {
                path: 'health-app/immunizations',
                Component: HealthAppImmunizations,
                handle: { crumb: () => <BreadcrumbLink>Health App Immunizations</BreadcrumbLink> }
              },
              { path: 'health-app/energy', Component: Energy, handle: { crumb: () => <BreadcrumbLink>Energy</BreadcrumbLink> } },
              { path: 'health-app/allergies', Component: Allergies, handle: { crumb: () => <BreadcrumbLink>Allergies</BreadcrumbLink> } },
              { path: 'health-app/vitals', Component: Vitals, handle: { crumb: () => <BreadcrumbLink>Vitals</BreadcrumbLink> } },

              {
                path: 'quickupload-history',
                Component: QuickUploadHistory,
                handle: { crumb: () => <BreadcrumbLink>Quick Upload History</BreadcrumbLink> }
              },
              {
                path: 'quickupload-history/:sessionId',
                Component: QuickUploadItems,
                handle: { crumb: data => <BreadcrumbLink>{data.params.sessionId}</BreadcrumbLink> }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'starred',
    Component: StarredPatients,
    handle: { crumb: () => <BreadcrumbLink>Starred Patients</BreadcrumbLink> }
  },
  { path: '', Component: Patients, handle: { crumb: () => null } },
  { path: 'list', Component: PatientsList, handle: { crumb: () => <BreadcrumbLink>Patients List</BreadcrumbLink> } },
  {
    path: 'imported-list',
    Component: PatientsListWithImportedData,
    handle: { crumb: () => <BreadcrumbLink>Imported Patients List</BreadcrumbLink> }
  },
  { path: 'insights', Component: PatientInsights, handle: { crumb: () => <BreadcrumbLink>Patient Insights</BreadcrumbLink> } },
  {
    path: 'conversations',
    Component: AllPatientsConversations,
    handle: { crumb: () => <BreadcrumbLink>All Conversations</BreadcrumbLink> }
  },
  {
    path: 'med-history-requests',
    Component: MedHistoryRequests,
    handle: { crumb: () => <BreadcrumbLink>Med History Requests</BreadcrumbLink> }
  }
];

const taskSectionRoutes = [{ path: '', Component: MyTasks, handle: { crumb: () => <BreadcrumbLink>My Tasks</BreadcrumbLink> } }];

const tcmSectionRoutes = [
  { path: 'dashboard', Component: TcmDashboard, handle: { crumb: () => <BreadcrumbLink>Dashboard</BreadcrumbLink> } },
  { path: 'adt-queue', Component: AdtQueue, handle: { crumb: () => <BreadcrumbLink>ADT Queue</BreadcrumbLink> } },
  { path: 'adt-queue-v1', Component: AdtQueueV1, handle: { crumb: () => <BreadcrumbLink>ADT Queue</BreadcrumbLink> } },
  { path: 'billing', Component: () => <ComingSoon title={'Billing'} />, handle: { crumb: () => <BreadcrumbLink>Billing</BreadcrumbLink> } },
  {
    path: 'tcm-tasks',
    Component: () => <ComingSoon title={'TCM Tasks'} />,
    handle: { crumb: () => <BreadcrumbLink>TCM Tasks</BreadcrumbLink> }
  },
  { path: 'discharge', Component: DischargePatient, handle: { crumb: () => <BreadcrumbLink>Discharge Patients</BreadcrumbLink> } }
];

const rpmSectionRoutes = [
  { path: 'dashboard', Component: RpmDashboard, handle: { crumb: () => <BreadcrumbLink>Dashboard</BreadcrumbLink> } },
  { path: 'my-patients', Component: RpmPatients, handle: { crumb: () => <BreadcrumbLink>My Patients</BreadcrumbLink> } },
  { path: 'provider-approvals', Component: RpmEnrollment, handle: { crumb: () => <BreadcrumbLink>Provider Approvals</BreadcrumbLink> } },
  { path: 'claims', Component: RpmClaims, handle: { crumb: () => <BreadcrumbLink>Claims</BreadcrumbLink> } },
  { path: 'alerts', Component: RpmAlertsList, handle: { crumb: () => <BreadcrumbLink>Alerts</BreadcrumbLink> } },
  { path: 'devices', Component: RpmTenoviDevices, handle: { crumb: () => <BreadcrumbLink>Devices</BreadcrumbLink> } },
  {
    path: 'reports-communication',
    Component: RpmReportsCommunications,
    handle: { crumb: () => <BreadcrumbLink>Reports Communication</BreadcrumbLink> }
  },
  {
    path: 'patient-schedules',
    Component: RpmPatientSchedules,
    handle: { crumb: () => <BreadcrumbLink>Patient Schedules</BreadcrumbLink> }
  }
];

const requestsSectionRoutes = [{ path: '', Component: Requests, handle: { crumb: () => <BreadcrumbLink>Requests</BreadcrumbLink> } }];

const reportsSectionRoutes = [
  { path: 'my-dashboard', Component: MyTasksReportDashboard, handle: { crumb: () => <BreadcrumbLink>My Dashboard</BreadcrumbLink> } },
  { path: 'dashboard', Component: ReportDashboard, handle: { crumb: () => <BreadcrumbLink>Dashboard</BreadcrumbLink> } },
  { path: 'encounters', Component: Encounters, handle: { crumb: () => <BreadcrumbLink>Encounters</BreadcrumbLink> } },
  { path: 'billing', Component: Billing, handle: { crumb: () => <BreadcrumbLink>Billing</BreadcrumbLink> } },
  { path: 'tasks', Component: Tasks, handle: { crumb: () => <BreadcrumbLink>Tasks</BreadcrumbLink> } },
  { path: 'health-plan', Component: HealthPlan, handle: { crumb: () => <BreadcrumbLink>Health Plan</BreadcrumbLink> } },
  { path: 'performance', Component: Performance, handle: { crumb: () => <BreadcrumbLink>Performance</BreadcrumbLink> } },
  { path: 'rpm-billing', Component: RpmClaims, handle: { crumb: () => <BreadcrumbLink>RPM Billing</BreadcrumbLink> } },
  { path: 'rpm-providers', Component: RpmProviders, handle: { crumb: () => <BreadcrumbLink>RPM Providers</BreadcrumbLink> } },
  { path: 'rpm-companies', Component: RpmCompanies, handle: { crumb: () => <BreadcrumbLink>RPM Companies</BreadcrumbLink> } }
];

const adminSectionRoutes = [
  { path: 'timesheets', Component: Timesheets, handle: { crumb: () => <BreadcrumbLink>Timesheets</BreadcrumbLink> } },
  { path: 'invoices/:id', Component: InvoiceDetails, handle: { crumb: () => <BreadcrumbLink>Invoices</BreadcrumbLink> } },
  { path: 'invoices', Component: Invoices, handle: { crumb: () => <BreadcrumbLink>Invoices</BreadcrumbLink> } },
  { path: 'dashboard', Component: Dashboard, handle: { crumb: () => <BreadcrumbLink>Dashboard</BreadcrumbLink> } },
  {
    path: 'organizations/:id',
    handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/patients'}>Organization</BreadcrumbLink> },
    Component: OrganizationSection,
    children: [
      {
        path: 'patients',
        handle: { crumb: () => <BreadcrumbLink>Patients</BreadcrumbLink> },
        Component: AssignedPatients
      },
      {
        path: 'stores',
        handle: { crumb: () => <BreadcrumbLink>Stores</BreadcrumbLink> },
        Component: AssignedStores
      },
      {
        path: 'tasks',
        handle: { crumb: () => <BreadcrumbLink>Tasks</BreadcrumbLink> },
        Component: AssignedTasks
      }
    ]
  },
  {
    path: 'organizations/:id/stores/:id',
    handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/patients'}>{data.params.id}</BreadcrumbLink> },
    Component: StoreSection,
    children: [
      { path: 'patients', Component: AssignedPatients, handle: { crumb: () => <BreadcrumbLink>Patients</BreadcrumbLink> } },
      { path: 'tasks', Component: AssignedTasks, handle: { crumb: () => <BreadcrumbLink>Tasks</BreadcrumbLink> } }
    ]
  },
  {
    path: 'organizations',
    handle: { crumb: () => <BreadcrumbLink>Organizations</BreadcrumbLink> },
    Component: Organizations
  },
  {
    path: 'users',
    handle: { crumb: data => <BreadcrumbLink to={data.pathname}>Users</BreadcrumbLink> },
    Component: UsersSection,
    children: [
      { path: '', Component: Users, handle: { crumb: null } },
      { path: ':id/details', Component: UserDetailsSection, handle: { crumb: data => <BreadcrumbLink>{data.params.id}</BreadcrumbLink> } }
    ]
  },
  { path: 'patients', Component: PatientOnboarding, handle: { crumb: () => <BreadcrumbLink>Patients</BreadcrumbLink> } },
  { path: 'health-plans', Component: HealthPlans, handle: { crumb: () => <BreadcrumbLink>Health Plans</BreadcrumbLink> } },
  { path: 'resources', Component: Checklists, handle: { crumb: () => <BreadcrumbLink>Resources</BreadcrumbLink> } },
  { path: 'providers', Component: ProvidersPage, handle: { crumb: () => <BreadcrumbLink>Providers</BreadcrumbLink> } },
  { path: 'logs', Component: Logs, handle: { crumb: () => <BreadcrumbLink>Logs</BreadcrumbLink> } },
  { path: 'my-performance', Component: MyPerformance, handle: { crumb: () => <BreadcrumbLink>My Performance</BreadcrumbLink> } },
  { path: 'announcements', Component: Announcements, handle: { crumb: () => <BreadcrumbLink>Announcements</BreadcrumbLink> } },
  {
    path: 'system',
    Component: SystemSection,
    handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/notification-files'}>System</BreadcrumbLink> },
    children: [
      { path: 'notification-files', Component: ComingSoon, handle: { crumb: () => <BreadcrumbLink>Notification Files</BreadcrumbLink> } },
      {
        path: 'companies',
        handle: { crumb: data => <BreadcrumbLink to={data.pathname}>Companies</BreadcrumbLink> },
        children: [
          { path: '', Component: AllCompanies, handle: { crumb: () => <BreadcrumbLink>All</BreadcrumbLink> } },
          { path: ':rpid', Component: Company, handle: { crumb: data => <BreadcrumbLink>{data.params.rpid}</BreadcrumbLink> } }
        ]
      }
    ]
  },
  {
    path: 'shared',
    children: [
      {
        path: 'health-app/medications',
        Component: HealthAppMedications,
        handle: { crumb: () => <BreadcrumbLink>Medications</BreadcrumbLink> }
      },
      {
        path: 'health-app/immunizations',
        Component: HealthAppImmunizations,
        handle: { crumb: () => <BreadcrumbLink>Immunizations</BreadcrumbLink> }
      },
      { path: 'health-app/energy', Component: Energy, handle: { crumb: () => <BreadcrumbLink>Energy</BreadcrumbLink> } },
      { path: 'health-app/allergies', Component: Allergies, handle: { crumb: () => <BreadcrumbLink>Allergies</BreadcrumbLink> } },
      { path: 'health-app/vitals', Component: Vitals, handle: { crumb: () => <BreadcrumbLink>Vitals</BreadcrumbLink> } },
      {
        path: 'quickupload-history',
        handle: { crumb: () => <BreadcrumbLink>Quick Upload History</BreadcrumbLink> },
        Component: QuickUploadHistory,
        children: [
          {
            path: ':sessionId',
            Component: QuickUploadItems,
            handle: { crumb: data => <BreadcrumbLink>{data.params.sessionId}</BreadcrumbLink> }
          }
        ]
      }
    ]
  }
];

const authenticatedRoutes: RoutePathDefinition[] = [
  {
    path: 'home',
    Component: HomeDashboard,
    handle: {
      crumb: null
    }
  },
  {
    path: 'global-lookup',
    Component: RpmOnboarding,
    handle: {
      crumb: () => <BreadcrumbLink>Global Lookup</BreadcrumbLink>
    }
  },
  {
    path: 'providers/:id',
    Component: ProviderSection,
    handle: {
      crumb: data => <BreadcrumbLink to={data.pathname + '/' + providerSectionRoutes[0].path}>{data.params.id}</BreadcrumbLink>
    },
    children: providerSectionRoutes
  },
  {
    path: 'patients',
    Component: PatientsSection,
    handle: {
      crumb: data => {
        return <BreadcrumbLink to={data.pathname}>Patients</BreadcrumbLink>;
      }
    },
    children: patientSectionRoutes
  },
  {
    path: 'tasks',
    Component: TasksSection,
    handle: {
      crumb: data => <BreadcrumbLink to={data.pathname + '/' + taskSectionRoutes[0].path}>Tasks</BreadcrumbLink>
    },
    children: taskSectionRoutes
  },
  {
    path: 'transitional-care-management/',
    Component: TransitionalCareManagementSection,
    handle: {
      crumb: data => <BreadcrumbLink to={data.pathname + '/' + tcmSectionRoutes[0].path}>TCM</BreadcrumbLink>
    },
    children: tcmSectionRoutes
  },
  {
    path: 'remote-patient-monitoring/',
    Component: RemotePatientMonitoringSection,
    handle: {
      crumb: data => <BreadcrumbLink to={data.pathname + '/' + rpmSectionRoutes[0].path}>RPM</BreadcrumbLink>
    },
    children: rpmSectionRoutes
  },
  {
    path: 'requests',
    Component: RequestsSection,
    children: requestsSectionRoutes,
    handle: { crumb: data => <BreadcrumbLink to={data.pathname + '/' + requestsSectionRoutes[0].path}>Requests</BreadcrumbLink> }
  },
  {
    path: 'reports',
    Component: ReportsSection,
    handle: {
      crumb: data => <BreadcrumbLink to={data.pathname + '/' + reportsSectionRoutes[0].path}>Reports</BreadcrumbLink>
    },
    children: reportsSectionRoutes
  },
  {
    path: 'administration',
    Component: AdministrationSection,
    handle: {
      crumb: data => <BreadcrumbLink to={data.pathname + '/' + adminSectionRoutes[0].path}>Administration</BreadcrumbLink>
    },
    children: adminSectionRoutes
  },
  { path: '*', Component: () => <Navigate to="/error/not-found" replace /> }
];

export const routes: RoutePathDefinition[] = [
  {
    element: <AppRoot />,
    children: [
      {
        path: '/',
        Component: Authenticated,
        handle: {
          crumb: () => (
            <BreadcrumbLink to="/home">
              <HomeIcon fontSize={'medium'} />
            </BreadcrumbLink>
          )
        },
        children: authenticatedRoutes
      },
      {
        path: 'login',
        Component: Login
      },
      {
        path: 'switch-company',
        Component: SwitchCompany
      },
      {
        path: 'twofactor-login',
        Component: TwoFactorLogin
      },
      {
        path: 'agreement',
        Component: Agreement
      },
      {
        path: 'forgot-password',
        Component: ForgotPassword
      },
      {
        path: 'rpm/',
        Component: RpmSection,
        children: [
          { path: '', Component: () => <InvalidSession feedback={'Invalid RPM session'} /> },
          {
            path: ':sessionId',
            Component: RpmReviewStart
          },
          {
            path: ':sessionId',
            Component: RpmEnrollmentReviewSection,
            children: [
              { path: 'view', Component: RpmEnrollmentRecordViewAll },
              {
                path: 'view/:providerActivityId',
                Component: RpmEnrollmentRecord
              },
              {
                path: 'rpmMonthlyReports',
                Component: RpmAllMonthlyReports
              },
              {
                path: 'rpmMonthlyReports/:providerActivityId',
                Component: RpmMonthlyReport
              }
            ]
          },
          { path: '*', Component: () => <InvalidSession feedback={'Route not found'} /> }
        ]
      },
      {
        path: 'quick-upload/',
        Component: QuickUploadSection,
        children: [
          { path: '', Component: () => <InvalidSession feedback={'Invalid quick upload session'} /> },
          { path: ':sessionId', Component: QuickUploadStart },
          { path: ':sessionId/medications', Component: QuickUploadMedications },
          { path: 'thanks', Component: QuickUploadThanks },
          { path: '*', Component: () => <InvalidSession feedback={'Route not found'} /> }
        ]
      },
      {
        path: 'error/',
        children: [
          {
            path: 'forbidden',
            element: <Navigate to={'/login'} replace state={{ statusCode: 401 }} />
          },
          {
            path: '*',
            element: <Navigate to={'/error/not-found'} replace />
          }
        ]
      }
    ]
  }
];

const router = createBrowserRouter(routes);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
