import { Body1 } from '@/components/Typography';
import type { ChipProps } from '@mui/material';
import { Chip, Stack } from '@mui/material';
import type React from 'react';
import type { ReactNode } from 'react';

type Props = {
  label: ReactNode;
  value: ReactNode;
  secondaryValue?: ReactNode;
} & ChipProps;

const ChipColumn: React.FC<Props> = ({ label, value, secondaryValue, ...props }) => {
  return (
    <Stack flexDirection="row" gap={1} alignItems="center" justifyContent="center">
      <Body1>{label}</Body1>
      <Stack flexDirection="column" alignItems="center" justifyContent="center">
        <Chip sx={{ fontWeight: '400' }} variant={'outlined'} label={value} color={'primary'} {...props} />
        {secondaryValue ? secondaryValue : <></>}
      </Stack>
    </Stack>
  );
};

export default ChipColumn;
