import { Box, DialogActions, DialogContent } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import PdfDocument from '../Pdfs/PdfDocument';
import TempVitalAccessQrPdf from '../Pdfs/TempVitalAccessQrPdf';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

interface QRCodeDialogProps extends BaseDialogProps {
  id?: string;
  link: string;
  downloadFileName?: string;
  qrCodeImageUrl?: string;
}

const QRCodeDialog: React.FC<QRCodeDialogProps> = ({ title, hideDialog, link, downloadFileName, qrCodeImageUrl, ...props }) => {
  const [qrCodeLink, setQrCodeLink] = useState<string>('');

  useEffect(() => {
    QRCode.toDataURL(link)
      .then(setQrCodeLink)
      .catch(error => console.error('Error generating QR code', error));
  }, [link]);

  return (
    <BaseDialog {...props} hideDialog={hideDialog} title={title} fullWidth maxWidth="md">
      <DialogContent sx={{ overflowY: 'hidden' }}>
        <Box width="100%" height="500px">
          <PDFViewer width="100%" height="100%">
            <PdfDocument title={downloadFileName}>
              <TempVitalAccessQrPdf logoUrl={qrCodeImageUrl} qrCodeUrl={qrCodeLink} />
            </PdfDocument>
          </PDFViewer>
        </Box>
        <DialogActions>
          {
            <PDFDownloadLink
              document={
                <PdfDocument title={downloadFileName}>
                  <TempVitalAccessQrPdf logoUrl={qrCodeImageUrl} qrCodeUrl={qrCodeLink} />
                </PdfDocument>
              }
              fileName={`${downloadFileName || 'document'}.pdf`}
              style={{ textDecoration: 'none' }}
            >
              {({ blob, url, loading, error }) => <Button isLoading={loading}>{loading ? 'Loading document...' : 'Download PDF'}</Button>}
            </PDFDownloadLink>
          }
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default QRCodeDialog;
