import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  logo: {
    maxWidth: 200,
    marginBottom: 30,
    alignSelf: 'center'
  },
  qrCode: {
    width: 350,
    height: 350,
    marginBottom: 20,
    alignSelf: 'center'
  },
  text: {
    textAlign: 'center',
    marginBottom: 10
  },
  footer: {
    marginTop: 10,
    fontSize: 10,
    color: 'grey',
    textAlign: 'center'
  }
});

interface TempVitalAccessQrPdfProps {
  logoUrl?: string;
  qrCodeUrl: string;
}

const TempVitalAccessQrPdf: React.FC<TempVitalAccessQrPdfProps> = ({ logoUrl, qrCodeUrl }) => {
  return (
    <View>
      {logoUrl && <Image style={styles.logo} src={{ uri: logoUrl, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }} />}
      <Text style={styles.text}>Scan to access</Text>
      <Text style={styles.text}>My recent vital readings</Text>
      <Image style={styles.qrCode} src={qrCodeUrl} />
      <Text style={styles.footer}>Powered by Truenity Health</Text>
    </View>
  );
};

export default TempVitalAccessQrPdf;
