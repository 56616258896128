import type { AccountNotesFilterOptions } from '@/graphql/remotePatientMonitoring';
import type { PatientsData } from '@/routes/Patients/patients';
import type { AccountNoteType } from '@/types/remotePatientMonitoring';
import create from 'zustand';

type QueryNotesType =
  | undefined
  | {
      filter?: AccountNotesFilterOptions;
      updated: Date | undefined;
    };

export type AccountStore = {
  account: PatientsData | null;
  setAccount: (account: PatientsData) => void;
  globalAccounts: PatientsData[];
  setGlobalAccounts: (value: PatientsData[]) => void;
  accountNotes: AccountNoteType[];
  setAccountNotes: (value: AccountNoteType[]) => void;
  queryNotes: QueryNotesType;
  setQueryNotes: (value: QueryNotesType) => void;
  isAccountChanged: boolean;
  setIsAccountChanged: (value: boolean) => void;
};

export const useAccountStore = create<AccountStore>(set => ({
  account: null,
  setAccount: value => set(() => ({ account: value })),
  globalAccounts: [],
  setGlobalAccounts: value => set(() => ({ globalAccounts: value })),
  accountNotes: [],
  setAccountNotes: value => set(() => ({ accountNotes: value })),
  queryNotes: undefined,
  setQueryNotes: value => set(() => ({ queryNotes: value })),
  isAccountChanged: false,
  setIsAccountChanged: value => set(() => ({ isAccountChanged: value }))
}));
