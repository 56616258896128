import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { GetCommunicationLogsResponse, GetRpmReviewActivitiesResponse } from '@/graphql/remotePatientMonitoring';
import { GET_COMMUNICATION_LOGS, GET_RPM_REVIEW_ACTIVITIES } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { RefactorActivityLogType } from '@/types/remotePatientMonitoring';
import { ActivityLogType } from '@/types/remotePatientMonitoring';
import { toDate } from '@/util/date';
import { formatDate } from '@/util/format';
import { useLazyQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box, Chip, Drawer, IconButton, Stack } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import type { VerticalStepperStep } from '../Stepper/VerticalStepper';
import VerticalStepper from '../Stepper/VerticalStepper';
import { H4, H5, H6 } from '../Typography';

interface ActivityLogsFilterOptions {
  typeFilters?: string[];
  rpmReportId?: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  type: ActivityLogType;
  filterOptions?: ActivityLogsFilterOptions;
  accountName?: string;
}

const renderLabel = (label: string) => {
  const segments = label.split(/<\!\>|<\/\!\>/);
  const labelElements: any = [];
  for (let i = 0; i < segments.length; i++) {
    if (i % 2 === 0) {
      labelElements.push(segments[i]);
    } else {
      labelElements.push(<Chip variant={'outlined'} color="info" size="small" label={segments[i]} />);
    }
  }
  return labelElements;
};

const renderStepperHeader = (
  temporaryStep: RefactorActivityLogType,
  type: ActivityLogType,
  index: number,
  currentStep?: number,
  setCurrentStep?: Dispatch<SetStateAction<number>>,
  isChildAvailable?: boolean
): ReactNode => {
  const isCurrentStep = index === currentStep;
  const icon = isCurrentStep ? (
    <KeyboardArrowUpIcon color={!isChildAvailable ? 'disabled' : 'primary'} />
  ) : (
    <KeyboardArrowDownIcon color={!isChildAvailable ? 'disabled' : 'primary'} />
  );
  if (
    type === ActivityLogType.COMMUNICATION_SUMMARY ||
    type === ActivityLogType.READINGS_REVIEW_SUMMARY ||
    type === ActivityLogType.REPORTS_SUMMARY ||
    type === ActivityLogType.REPORT_COMMUNICATION_SUMMARY
  ) {
    return (
      <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={1}>
        <H5 color={color.truentityBlue[700]}>{renderLabel(temporaryStep?.logText)}</H5>
        <Stack direction="row" alignItems="center">
          {temporaryStep?.activityTime && <H6 whiteSpace="nowrap">{formatDate(temporaryStep?.activityTime, 'MMM DD, YYYY h:mm A')}</H6>}
          {setCurrentStep ? (
            <IconButton disabled={!isChildAvailable} onClick={() => setCurrentStep(isCurrentStep ? -1 : index)}>
              {icon}
            </IconButton>
          ) : null}
        </Stack>
      </Stack>
    );
  }
};

const renderChildElements = (data: string): ReactNode => {
  const convertedData: { note: string } = JSON.parse(data.replace(/=>/g, ':'));

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={1}>
        <Stack direction="row">
          <H5 fontWeight={500} color={color.grey700}>
            {convertedData?.note}
          </H5>
        </Stack>
      </Stack>
    </Box>
  );
};

const ActivityDrawer = ({ isOpen, onClose, type, filterOptions, accountName }: Props) => {
  const { id } = useParams();

  const [steps, setSteps] = useState<VerticalStepperStep[]>([]);
  const [getCommunicationLogs, { data: communicationLogs }] = useLazyQuery<GetCommunicationLogsResponse>(GET_COMMUNICATION_LOGS, {
    fetchPolicy: 'cache-and-network'
  });
  const [getRpmReviewActivities, { data: readingsReviewLogs }] = useLazyQuery<GetRpmReviewActivitiesResponse>(GET_RPM_REVIEW_ACTIVITIES, {
    fetchPolicy: 'cache-and-network'
  });

  const getRpmActivitiesLogs = async () => {
    try {
      await getRpmReviewActivities({
        variables: {
          truentityId: id,
          pageNum: 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });
    } catch {
      console.error('Error getting RPM activities logs');
    }
  };

  const renderTitle = useCallback(() => {
    if (type === ActivityLogType.COMMUNICATION_SUMMARY) {
      return 'Communication Summary';
    } else if (type === ActivityLogType.READINGS_REVIEW_SUMMARY) {
      return 'Readings Review Summary';
    } else if (type === ActivityLogType.REPORTS_SUMMARY) {
      return `Reports Activities for ${accountName}`;
    } else if (type == ActivityLogType.REPORT_COMMUNICATION_SUMMARY) {
      return 'Report Communication Summary';
    }
  }, [accountName, type]);

  const getCommunicationsActivityLogs = async () => {
    try {
      await getCommunicationLogs({
        variables: {
          truentityId: id,
          filterOptions: filterOptions
        }
      });
    } catch {
      console.error('Error getting communication logs');
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (
        type === ActivityLogType.COMMUNICATION_SUMMARY ||
        type === ActivityLogType.REPORTS_SUMMARY ||
        type === ActivityLogType.REPORT_COMMUNICATION_SUMMARY
      ) {
        getCommunicationsActivityLogs();
      } else if (type === ActivityLogType.READINGS_REVIEW_SUMMARY) {
        getRpmActivitiesLogs();
      }
    }
  }, [isOpen, type, filterOptions]);

  useEffect(() => {
    if (communicationLogs?.getCommunicationLogs?.activityLogs) {
      const newSteps: VerticalStepperStep[] = communicationLogs?.getCommunicationLogs?.activityLogs.map(step => ({
        header: (index: number, currentStep?: number, setCurrentStep?: Dispatch<SetStateAction<number>>) =>
          renderStepperHeader(
            {
              id: step.id,
              logText: step.logText,
              activityTime: step.activityTime
            },
            type,
            index,
            currentStep,
            setCurrentStep,
            !!step?.data ?? false
          ),
        children: () => {
          if (step.data) return renderChildElements(step.data);
          else null;
        }
      }));

      setSteps(newSteps);
    }
  }, [communicationLogs]);

  useEffect(() => {
    if (readingsReviewLogs?.getRpmReviewActivities?.careActivity) {
      const newSteps: VerticalStepperStep[] = readingsReviewLogs?.getRpmReviewActivities?.careActivity.map(step => {
        const timeInMinutes = step.totalTimeSpentSecs > 0 ? Math.floor(step.totalTimeSpentSecs / 60) : 0;
        return {
          header: (index: number) =>
            renderStepperHeader(
              {
                id: step.id,
                logText: `${step.title} For ${timeInMinutes} Minutes`,
                activityTime: toDate(step.startedAt)
              },
              type,
              index
            )
        };
      });

      setSteps(newSteps);
    }
  }, [readingsReviewLogs]);

  return (
    <Drawer
      anchor="right"
      color="inherit"
      open={isOpen}
      elevation={1}
      sx={{ zIndex: theme => theme.zIndex.drawer + 1, width: '100%' }}
      container={this}
      onClose={onClose}
    >
      <Stack p={2.5} minHeight="100vh" justifyContent="space-between">
        <Box minHeight="100%" overflow="auto">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <H4>{renderTitle()}</H4>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Box width={500} pt={2}>
            <VerticalStepper steps={steps} icon={<PanoramaFishEyeIcon color="primary" />} />
          </Box>
        </Box>
        {/* TODO: Add new Encounter Design Change: To be removed: @Oct 2023 */}
        {/* <Box>
          <Button title="Add new Encounter" fullWidth>
            Add new Encounter
          </Button>
        </Box> */}
      </Stack>
    </Drawer>
  );
};

export default ActivityDrawer;
