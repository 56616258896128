import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { MedRecDialog } from '@/components/Dialogs';
import MuiAccordion from '@/components/MuiAccordion';
import SelectList from '@/components/SelectList';
import TruentityTextField from '@/components/TruentityTextField';
import { Body1, Small } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { GetMedrecSnapshotDetailResponse, GetMedrecSnapshotsResponse } from '@/graphql/medication';
import { GET_MED_REC_SNAPSHOTS, GET_MED_REC_SNAPSHOT_DETAIL } from '@/graphql/medication';
import type { MedrecSnapshot } from '@/types/dischargePatient';
import type { Medication } from '@/types/medication';
import { isCurrent, isVerified } from '@/util/medications';
import { localeCompare } from '@/util/sort';
import { unknown } from '@/util/string';
import { useLazyQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StepperPage } from './StepperPage';
import type { TCMWorkflowStepProps } from './TCMWorkflow';

const defaults: { label: string; value: string | 'AddNew' }[] = [{ label: 'Add New', value: 'AddNew' }];

export const TCMWorkflowStep3 = ({ assignedTo, formData, setFormData, readOnly }: TCMWorkflowStepProps) => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { medications } = useContext(PatientDetailContext);
  const [verifiedMeds, setVerifiedMeds] = useState<Medication[]>([]);
  const [getMedRecSnapshots, { data: medRecSnapshotsData, refetch: refetchMedRecSnapshotsData }] =
    useLazyQuery<GetMedrecSnapshotsResponse>(GET_MED_REC_SNAPSHOTS);

  const [getMedRecDetail, { data: medRecData }] = useLazyQuery<GetMedrecSnapshotDetailResponse>(GET_MED_REC_SNAPSHOT_DETAIL);
  const [medRecSelectListOptions, setMedRecSelectListOptions] = useState<typeof defaults>([]);
  const [medRecs, setMedRecs] = useState<MedrecSnapshot[]>([]);

  useEffect(() => {
    if (id) {
      getMedRecSnapshots({
        variables: {
          truentityId: id
        }
      }).catch(err => {
        console.error(err);
        enqueueSnackbar('Unable to retrieve medrec snapshots', { variant: 'error' });
      });
    }
  }, [enqueueSnackbar, getMedRecSnapshots, id]);

  useEffect(() => {
    if (medRecSnapshotsData && medRecSnapshotsData.medrecSnapshots) {
      const { medrecSnapshots } = medRecSnapshotsData.medrecSnapshots;
      setMedRecs(medrecSnapshots);
    }
  }, [medRecSnapshotsData]);

  useEffect(() => {
    if (medRecs) {
      const medRecOptions = medRecs.map((m: MedrecSnapshot) => ({ label: m.name || unknown(), value: m.id }));
      setMedRecSelectListOptions([...defaults, ...medRecOptions]);
    }
  }, [medRecs]);

  const handleNewMedRec = (success: boolean) => {
    if (success) {
      enqueueSnackbar('MedRec Snapshot Created', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar('MedRec Snapshot Failed', {
        variant: 'error'
      });
    }
  };

  const showModalMedRec = () => {
    const verifiedMeds = [...medications].sort(localeCompare('displayName', false)).filter(v => isVerified(v) && isCurrent(v));
    const medRecModal = showModal(MedRecDialog, {
      truentityId: id,
      title: 'Certify Med Rec',
      verifiedMeds,
      handleNewMedRec,
      hideDialog: () => {
        medRecModal.hide();

        refetchMedRecSnapshotsData().catch(err => {
          enqueueSnackbar('Oops, something has gone wrong', { variant: 'error' });
          console.error(err);
        });
        setVerifiedMeds([]);
      }
    });
  };

  useEffect(() => {
    if (formData.medrecSnapshot?.id) {
      console.log('medrecSnapshotId', formData.medrecSnapshot);

      getMedRecDetail({
        variables: {
          medrecSnapshotId: formData.medrecSnapshot.id,
          truentityId: id,
          pageNum: 0,
          pageSize: DEFAULT_PAGE_SIZE
        }
      }).catch(err => {
        enqueueSnackbar('Unable to get med rec detail', { variant: 'error' });
        console.error(err);
      });
    }
  }, [enqueueSnackbar, formData.medrecSnapshot, getMedRecDetail, id]);

  useEffect(() => {
    if (medRecData?.medrecSnapshot?.accountMedications) {
      setVerifiedMeds(medRecData.medrecSnapshot.accountMedications);
    }
  }, [medRecData]);

  return (
    <StepperPage title={'Step 3: Document Patient Details'} assignedTo={[assignedTo || '']}>
      <MuiAccordion
        options={[
          {
            label: 'Medications',
            defaultExpand: true,
            content: (
              <Stack spacing={2}>
                <SelectList
                  keepOriginalValue
                  options={medRecSelectListOptions}
                  placeholder="Select an option..."
                  label={'Med Recs'}
                  value={formData.medrecSnapshot?.id || ''}
                  onChange={e => {
                    const value = e.target.value as string;
                    if (value === 'AddNew') {
                      showModalMedRec();
                      return;
                    }

                    setFormData({
                      ...formData,
                      medrecSnapshot: medRecs?.find(m => m.id === value)
                    });
                  }}
                  disabled={readOnly}
                />
                <Stack direction="row" alignItems={'center'} justifyContent="space-between" spacing={2}>
                  <Body1 fontWeight={'bold'}>Verified Medications</Body1>
                  <Small>Total: {verifiedMeds.length}</Small>
                </Stack>
                <Stack
                  sx={{
                    marginTop: '10px',
                    overflow: 'auto',
                    maxHeight: '80vh'
                  }}
                >
                  <ul>
                    {verifiedMeds.map((med: Medication, index: number) => (
                      <Small css={{ marginBottom: '10px' }} key={index}>
                        {' '}
                        {med.displayName}
                      </Small>
                    ))}
                  </ul>
                </Stack>
              </Stack>
            )
          },
          {
            label: 'Referrals and Discussions',
            defaultExpand: false,
            content: (
              <Stack spacing={2}>
                <TruentityTextField
                  multiline
                  rows={4}
                  label={'Discussions with Referrals'}
                  onChange={event => {
                    setFormData({
                      ...formData,
                      docReferralDiscussions: event.target.value
                    });
                  }}
                  value={formData.docReferralDiscussions}
                  disabled={readOnly}
                />
                <TruentityTextField
                  multiline
                  rows={4}
                  label={'Discussions with Home Care Providers'}
                  onChange={event => {
                    setFormData({
                      ...formData,
                      docCaregiverDiscussions: event.target.value
                    });
                  }}
                  value={formData.docCaregiverDiscussions}
                  disabled={readOnly}
                />
              </Stack>
            )
          },
          {
            label: 'Home Self Care',
            defaultExpand: false,
            content: (
              <Stack spacing={2}>
                <TruentityTextField
                  multiline
                  rows={4}
                  label={'Treatment Adherence Instructions'}
                  onChange={event => {
                    setFormData({
                      ...formData,
                      docTreatmentAdherenceInstructions: event.target.value
                    });
                  }}
                  value={formData.docTreatmentAdherenceInstructions}
                  disabled={readOnly}
                />
                <TruentityTextField
                  multiline
                  rows={4}
                  label={'Recommended Self Care Activities'}
                  onChange={event => {
                    setFormData({
                      ...formData,
                      docSelfCareActivities: event.target.value
                    });
                  }}
                  value={formData.docSelfCareActivities}
                  disabled={readOnly}
                />
              </Stack>
            )
          }
        ]}
      />
    </StepperPage>
  );
};
