import { getRpmWorkflowSessionType } from '@/routes/PatientDetails/RemotePatientMonitoring/RpmWorkflow';
import { startTrackingMedTaskActivity, stopTrackingMedTaskActivity, trackNavigation } from '@/services/activity-log';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const ActivityTracker = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [trackingId, setTrackingId] = useState<number | null>(null);
  const [previousActivityId, setPreviousActivityId] = useState<string>('');
  const { activeTimer, setActiveType } = useRpmWorkflowStore();

  const rpmWorkflowSessionType = getRpmWorkflowSessionType();

  const track = useCallback(async () => {
    console.warn('track', 'navigateTo:', location.pathname, 'previousActivityId:', previousActivityId);

    const category = pathname.includes('rpm/patient-setup') ? 'rpm-patient-setup' : 'med-task';
    const data = {
      category,
      timerRunning: category !== 'rpm-patient-setup' ? activeTimer?.isRunning : true
    };

    const activityLog = id
      ? await trackNavigation({
          navigateTo: location.pathname,
          prevActivityLogId: previousActivityId,
          truentityId: id
        })
      : null;

    if (activityLog) {
      setPreviousActivityId(activityLog.id);
    }
  }, [id, previousActivityId]);

  const startMedTaskTracking = useCallback(async () => {
    const activityLog = id ? await startTrackingMedTaskActivity({ truentityId: id }) : null;

    if (activityLog) {
      setTrackingId(activityLog.id);
    }
  }, [id]);

  const stopMedTaskTracking = useCallback(async () => {
    if (trackingId) {
      try {
        await stopTrackingMedTaskActivity({ activityLogId: trackingId });
        setTrackingId(null);
      } catch (error) {
        console.error('Failed to stop tracking:', error);
      }
    }
  }, [trackingId]);

  const handleTabClose = useCallback(
    (trackingId: number) => {
      if (trackingId) {
        stopMedTaskTracking().catch(console.error);
      }
    },
    [stopMedTaskTracking]
  );

  useEffect(() => {
    setActiveType(rpmWorkflowSessionType);
  }, [rpmWorkflowSessionType, setActiveType]);

  useEffect(() => {
    let isSubscribed = true;

    const isPathPatients = pathname.split(`/patients/${id}`).length > 1;
    const shouldStartMedTaskTracking = isPathPatients && !trackingId;
    const shouldStopMedTaskTracking = !isPathPatients && trackingId;

    if (isSubscribed) {
      if (shouldStartMedTaskTracking) {
        startMedTaskTracking().catch(console.error);
      }

      if (shouldStopMedTaskTracking) {
        stopMedTaskTracking().catch(console.error);
      }

      track().catch(console.error);
    }
    if (trackingId) window.addEventListener('beforeunload', () => handleTabClose(trackingId));

    return () => {
      isSubscribed = false;
    };
  }, [id, pathname, trackingId]);

  return null;
};

export default ActivityTracker;
