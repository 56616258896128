import { UPDATE_RPM_ACTIVITY } from '@/graphql/remotePatientMonitoring';
import type { CareActivityType } from '@/types/remotePatientMonitoring';
import { CareActivityTypes, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { secondsToDate, toMomentDateOrDefault } from '@/util/date';
import { formatOnlyDateTime, formatTime, getCurrentDate } from '@/util/format';
import { activityCategoryList, activityTypeList, timeStringToSeconds } from '@/util/rpm';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import { useMutation } from '@apollo/client';
import { Box, DialogActions, DialogContent, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers-pro';
import type { Moment, MomentInput } from 'moment';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityDateTimePicker from '../TruentityDateTimePicker';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  rpmEnrolledAtDate: MomentInput | undefined;
  rpmStatus: string;
  data: CareActivityType;
  hideDialog: () => void;
  onActivitiesChanged: () => void;
};

type FormValues = {
  category: string;
  type: string;
  title: string;
  startTime: Moment;
  // endTime: Moment;
  timeTaken: Moment;
  note: string;
};

const defaultValues: FormValues = {
  category: '',
  type: '',
  title: '',
  startTime: getCurrentDate(),
  // endTime: moment(new Date()),
  timeTaken: secondsToDate(0),
  note: ''
};

const UpdateAccountActivityDialog = ({
  id,
  rpmEnrolledAtDate,
  rpmStatus,
  data,
  title,
  hideDialog,
  onActivitiesChanged,
  ...props
}: Props): React.ReactElement => {
  const [updateAccountActivity] = useMutation(UPDATE_RPM_ACTIVITY);
  const { enqueueSnackbar } = useSnackbar();
  const isCandidateOrScheduledPatent = useMemo(
    () => rpmStatus === RpmStatusTypes.IS_CANDIDATE || rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    [rpmStatus]
  );

  const { control, reset, handleSubmit, setValue, watch } = useForm<FormValues>({ defaultValues });

  const categoryValue = watch('category');

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const isStartTimeGreaterThanRpmEnrolledDate = (startTime: Moment, rpmEnrolledAtDate: Moment): boolean => {
    // If category is SETUP, skip the rpmEnrolledAtDate condition check
    if (categoryValue.toLowerCase() === CareActivityTypes.SETUP.toLowerCase()) {
      return true;
    } else {
      return startTime.isAfter(rpmEnrolledAtDate);
    }
  };

  const validateInputs = (startTime: Moment, timeTaken: Moment, rpmEnrolledAtDate: Moment): boolean => {
    const isStartTimeValid = !(!startTime || !timeTaken);
    const isStartTimeGreaterThanRpmEnrolled = isStartTimeGreaterThanRpmEnrolledDate(startTime, rpmEnrolledAtDate);
    return isStartTimeValid && isStartTimeGreaterThanRpmEnrolled;
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const validInputs = validateInputs(values.startTime, values.timeTaken, rpmEnrolledAtDate);

      if (validInputs) {
        updateAccountActivity({
          variables: {
            truentityId: id,
            activityId: data.id,
            title: capitalizeLetterBeforeCharacterAddSpace(values.title),
            category: capitalizeLetterBeforeCharacterAddSpace(values.category),
            notes: values.note,
            type: values.type.toUpperCase(),
            startTime: formatOnlyDateTime(values.startTime, 'YYYY-MM-DD HH:mm:ss'),
            // endTime: formatDateTimeIgnoreTZ(values.endTime, 'YYYY-MM-DD HH:mm:ss'),
            timeTaken: timeStringToSeconds(formatTime(values.timeTaken, 'mm:ss'))
          }
        })
          .then(response => {
            const data = response.data!.updateRpmActivity;
            const variant = data!.status === 'Success' ? 'success' : 'error';

            enqueueSnackbar(data.message, {
              variant
            });
            onActivitiesChanged();
            hideDialog();
          })
          .catch(() => {
            enqueueSnackbar('Unable to update activity', {
              variant: 'error'
            });
            hideDialog();
          });
      } else {
        enqueueSnackbar('Failed to Create an Activity. Please check your date & time inputs.', {
          variant: 'error'
        });
      }
    } catch (err) {
      const failMessage = 'Failed to update a activity';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
      console.error(err);
    }
  };

  useEffect(() => {
    if (data) {
      const accountActivity: FormValues = {
        category: data?.subType ?? '',
        type: 'RPM',
        title: data?.title ?? '',
        startTime: toMomentDateOrDefault(data?.startedAt, getCurrentDate()),
        // endTime: moment(data?.endedAt).utc() ?? '',
        timeTaken: secondsToDate(data?.totalTimeSpentSecs ?? 0),
        note: data?.notes ?? ''
      };
      reset(accountActivity);
    }
  }, [data, reset]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box pb={2} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id={'category'}
                  label="Category"
                  options={activityCategoryList
                    .filter(option => !(isCandidateOrScheduledPatent && option.name === 'Monthly Care Management'))
                    .map(x => ({ label: x?.name, value: x?.id }))}
                  placeholder="Select a category"
                  value={value}
                  required
                  MenuProps={{
                    sx: { maxHeight: 220 }
                  }}
                  onChange={onChange}
                  sx={{ marginRight: 1 }}
                />
              )}
            />
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id={'type'}
                  label="Type"
                  options={activityTypeList.map(x => ({ label: x?.name, value: x?.id }))}
                  placeholder="Select a type"
                  value={value}
                  required
                  clearFunction={() => {
                    setValue('type', '');
                  }}
                  MenuProps={{
                    sx: { maxHeight: 220 }
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>

          <Box pb={2}>
            <Controller
              control={control}
              name="title"
              render={({ field: { onChange, value } }) => (
                <TextField autoFocus={true} required onChange={onChange} value={value} fullWidth placeholder="Title" />
              )}
            />
          </Box>

          <Box pb={2} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Controller
              control={control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <TruentityDateTimePicker
                  label="Start Time"
                  onChange={onChange}
                  value={value}
                  sx={{ flex: 1, marginRight: 1 }}
                  TextFieldProps={undefined}
                  required
                  minDateTime={categoryValue.toLowerCase() === CareActivityTypes.SETUP.toLowerCase() ? undefined : rpmEnrolledAtDate}
                />
              )}
            />

            {/*<Controller*/}
            {/*  control={control}*/}
            {/*  name="endTime"*/}
            {/*  render={({ field: { onChange, value } }) => (*/}
            {/*    <TruentityDateTimePicker*/}
            {/*      label="End Time"*/}
            {/*      onChange={onChange}*/}
            {/*      value={value}*/}
            {/*      sx={{ width: '50%' }}*/}
            {/*      TextFieldProps={undefined}*/}
            {/*      required*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}

            <Controller
              control={control}
              name="timeTaken"
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  ampm={false}
                  views={['minutes', 'seconds']}
                  format="mm:ss"
                  slotProps={{
                    textField: {
                      label: 'Time Taken (mm:ss)',
                      fullWidth: true
                    }
                  }}
                  sx={{
                    flex: 1
                  }}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Box>

          <Box pb={2}>
            <Controller
              control={control}
              name="note"
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoFocus={true}
                  minRows={3}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  multiline
                  rows={8}
                  placeholder="Notes (Optional)"
                />
              )}
            />
          </Box>

          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default UpdateAccountActivityDialog;
