import { DialogActions, DialogContent, Stack } from '@mui/material';
import Alert from '../Alert';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  description: string;
  onConfirm: () => void;
};

const DeleteRelyingPartyProvider = ({ title, description, hideDialog, onConfirm, ...props }: Props): React.ReactElement => {
  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <Alert description={description} status={'warning'} />

        <Stack direction="row" sx={{ justifyContent: 'flex-end', alignItems: 'center' }} spacing={1}>
          <DialogActions sx={{ justifyContent: 'end', padding: '30px 0 0 0' }}>
            <Button type="submit" onClick={onConfirm} a11yLabel={'Delete'} appearance="primary" />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default DeleteRelyingPartyProvider;
