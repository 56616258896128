import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import MuiAccordion from '@/components/MuiAccordion';
import { Body1, Body2, Caption, H1 } from '@/components/Typography';
import type { DeleteVitalsReadingResponse, ReadingsSummaryType } from '@/graphql/remotePatientMonitoring';
import { DELETE_ACCOUNT_VITALS_READING } from '@/graphql/remotePatientMonitoring';
import AlertLimitsCard from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/AlertLimitsCard';
import VitalsReadingsSummary from '@/routes/PatientDetails/RemotePatientMonitoring/Components/Vitals/VitalsReadingsSummary';
import { color } from '@/styles/assets/colors';
import type { AlertLimitsType, AlertLimitType, TableReadingsType, UnitType } from '@/types/remotePatientMonitoring';
import { RpmConfigurationsParams, VitalsHealthTypes } from '@/types/remotePatientMonitoring';
import { getReadingStatusStyles, vitalsTableName } from '@/util/rpm';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VitalsTableColumns from '../Components/Vitals/VitalsTableColumns';

const StyledTruentityDataGrid = styled(TruentityDataGrid)(({ theme }) => ({
  '& .vitals-readings-table-highlight': {
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
    '&:hover': {
      backgroundColor: lighten(theme.palette.warning.main, 0.8)
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.warning.main, 0.7),
      '&:hover': {
        backgroundColor: lighten(theme.palette.warning.main, 0.6)
      }
    }
  }
}));

export type VitalsTableDataProps = {
  patientId: string | undefined;
  alertLimits: AlertLimitsType | undefined;
  selectedTabType: VitalsHealthTypes;
  tabUnit: UnitType;
  refreshVitalsData: () => void;
  tableReadings: TableReadingsType[];
  readingsSummaryValues: ReadingsSummaryType;
  hasBaseline?: boolean;
  totalCount: number;
  loading: boolean;
  currentPage: number;
  onPageChange: ({ page }) => void;
};

const VitalsTableData = ({
  patientId,
  alertLimits,
  selectedTabType,
  tabUnit,
  tableReadings,
  readingsSummaryValues,
  hasBaseline = false,
  totalCount,
  refreshVitalsData,
  loading,
  currentPage,
  onPageChange
}: VitalsTableDataProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const navigate = useNavigate();

  const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
  const [actionsRowId, setActionsRowId] = useState<string | null>(null);

  const [deleteVitalsReading] = useMutation<DeleteVitalsReadingResponse>(DELETE_ACCOUNT_VITALS_READING);

  const handleActionsIconClick = (event: React.MouseEvent<HTMLButtonElement>, rowId: string) => {
    setActionsAnchorEl(event.currentTarget);
    setActionsRowId(rowId);
  };

  const handleActionsMenuClose = () => {
    setActionsAnchorEl(null);
    setActionsRowId(null);
  };

  const handleVitalsRecordDelete = useCallback(async () => {
    if (!actionsRowId) return;
    try {
      const response = await deleteVitalsReading({
        variables: {
          id: actionsRowId
        }
      });

      if (response?.data?.deleteAccountVitalsReading?.status === 'Success') {
        enqueueSnackbar('Patient data reading deleted successfully', { variant: 'success' });
        refreshVitalsData();
      } else {
        throw new Error('Response is not valid');
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Could not delete patient data reading', { variant: 'error' });
    } finally {
      handleActionsMenuClose();
    }
  }, [actionsRowId, deleteVitalsReading, enqueueSnackbar, refreshVitalsData]);

  const showDeleteConfirmation = useCallback(() => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Patient Data Reading Deletion',
      message: 'Are you sure you wish to delete this data reading?',
      onAgree: () => {
        handleVitalsRecordDelete();
        modal.hide();
      },
      onDisagree: () => modal.hide()
    });
  }, [showModal, handleVitalsRecordDelete]);

  const columns: GridColDef[] = VitalsTableColumns({ tabUnit, getChipStatusStyles: getReadingStatusStyles, handleActionsIconClick });

  const renderCaption = () => {
    let captionText = '';
    if (selectedTabType === VitalsHealthTypes.BloodGlucose) {
      captionText = 'Amount of glucose in blood';
    } else if (selectedTabType === VitalsHealthTypes.BloodPressure) {
      captionText = 'Force of blood when a heart beats (systolic) and when it rests (diastolic)';
    } else if (selectedTabType === VitalsHealthTypes.HeartRate) {
      captionText = 'Number of times a heart beats per minute';
    }

    return (
      <Caption textAlign="left" fontWeight={'400'} fontSize={'14px'} color={color.grey700} lineHeight={'20px'}>
        {captionText}
      </Caption>
    );
  };

  const renderAlertLimits = useCallback(() => {
    if (!alertLimits) return <span></span>;
    if (selectedTabType === VitalsHealthTypes.BloodPressure) {
      return (
        <>
          <Stack flexDirection="row" alignItems="center" padding={1}>
            <Stack flexDirection="column" textAlign="center">
              <Body1
                sx={{
                  whiteSpace: {
                    xs: 'normal',
                    xl: 'nowrap'
                  }
                }}
              >
                Systolic
              </Body1>
              <Body2>(mmHg)</Body2>
            </Stack>
            <AlertLimitsCard
              size="small"
              backgroundColor={color.white}
              values={
                {
                  cLowValue: alertLimits?.sysBloodPressure?.cLowValue,
                  lowValue: alertLimits?.sysBloodPressure?.lowValue,
                  highValue: alertLimits?.sysBloodPressure?.highValue,
                  cHighValue: alertLimits?.sysBloodPressure?.cHighValue
                } as AlertLimitType
              }
            />
          </Stack>
          <Stack flexDirection="row" alignItems="center" padding={1}>
            <Stack flexDirection="column" textAlign="center">
              <Body1
                sx={{
                  whiteSpace: {
                    xs: 'normal',
                    xl: 'nowrap'
                  }
                }}
              >
                Diastolic
              </Body1>
              <Body2>(mmHg)</Body2>
            </Stack>
            <AlertLimitsCard
              size="small"
              backgroundColor={color.white}
              values={
                {
                  cLowValue: alertLimits?.diaBloodPressure?.cLowValue,
                  lowValue: alertLimits?.diaBloodPressure?.lowValue,
                  highValue: alertLimits?.diaBloodPressure?.highValue,
                  cHighValue: alertLimits?.diaBloodPressure?.cHighValue
                } as AlertLimitType
              }
            />
          </Stack>
        </>
      );
    } else if (selectedTabType === VitalsHealthTypes.BloodGlucose) {
      return (
        <Stack flexDirection="row" alignItems="center" padding={1}>
          <Stack flexDirection="column" textAlign="center">
            <Body1
              sx={{
                whiteSpace: {
                  xs: 'normal',
                  xl: 'nowrap'
                }
              }}
            >
              Blood Glucose
            </Body1>
            <Body2>(mg/dl)</Body2>
          </Stack>
          <AlertLimitsCard
            size="small"
            backgroundColor={color.white}
            values={
              {
                cLowValue: alertLimits?.bloodGlucose?.cLowValue,
                lowValue: alertLimits?.bloodGlucose?.lowValue,
                highValue: alertLimits?.bloodGlucose?.highValue,
                cHighValue: alertLimits?.bloodGlucose?.cHighValue
              } as AlertLimitType
            }
          />
        </Stack>
      );
    } else if (selectedTabType === VitalsHealthTypes.HeartRate) {
      return (
        <Stack flexDirection="row" alignItems="center" padding={1}>
          <Stack flexDirection="column" textAlign="center">
            <Body1
              sx={{
                whiteSpace: {
                  xs: 'normal',
                  xl: 'nowrap'
                }
              }}
            >
              Heart Rate
            </Body1>
            <Body2>{`(${tabUnit})`}</Body2>
          </Stack>
          <AlertLimitsCard
            size="small"
            backgroundColor={color.white}
            values={
              {
                cLowValue: alertLimits?.heartRate?.cLowValue,
                lowValue: alertLimits?.heartRate?.lowValue,
                highValue: alertLimits?.heartRate?.highValue,
                cHighValue: alertLimits?.heartRate?.cHighValue
              } as AlertLimitType
            }
          />
        </Stack>
      );
    } else {
      return <span></span>;
    }
  }, [alertLimits, selectedTabType, tabUnit]);

  const handleEditClinicalRanges = useCallback(() => {
    patientId &&
      navigate(
        `/patients/${patientId}/details/rpm/patient-setup/care-activity/configuration?configure=${RpmConfigurationsParams.CLINICAL_RANGES}`
      );
  }, [patientId, navigate]);

  return (
    <Stack p={2} flexDirection="column" justifyContent="flex-start" alignItems="stretch">
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} spacing={2}>
        <Stack>
          <H1 textAlign="left" fontWeight={'500'} fontSize={'34px'} color={color.black50} lineHeight={'45px'}>
            {capitalizeLetterBeforeCharacterAddSpace(vitalsTableName(tabUnit))}
          </H1>
          {renderCaption()}
        </Stack>
      </Stack>
      <MuiAccordion
        sx={{
          marginX: 1,
          marginTop: 0.5,
          marginBottom: 0,
          boxShadow: 0,
          backgroundColor: color.white,
          borderRadius: 1
        }}
        detailsSx={{
          padding: 0,
          backgroundColor: color.white
        }}
        options={[
          {
            label: 'Clinical Ranges',
            secondLabel: (
              <IconButton type="button" onClick={handleEditClinicalRanges} aria-label={'Edit clinical ranges'}>
                <EditIcon fontSize="small" />
              </IconButton>
            ),
            defaultExpand: false,
            content: renderAlertLimits()
          }
        ]}
      />
      {/*<Stack*/}
      {/*  flexDirection={'column'}*/}
      {/*  justifyContent={'flex-start'}*/}
      {/*  alignItems={'stretch'}*/}
      {/*  width={'100%'}*/}
      {/*  m={1}*/}
      {/*  padding={1}*/}
      {/*  spacing={1}*/}
      {/*  sx={{*/}
      {/*    backgroundColor: theme => theme.palette.background.default,*/}
      {/*    borderRadius: 1*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Stack flexDirection="row" justifyContent="space-between" alignItems="center">*/}
      {/*    <H4>Clinical Ranges</H4>*/}
      {/*    <IconButton type="button" onClick={handleEditClinicalRanges} aria-label={'Edit clinical ranges'}>*/}
      {/*      <EditIcon fontSize="small" />*/}
      {/*    </IconButton>*/}
      {/*  </Stack>*/}
      {/*  {renderAlertLimits()}*/}
      {/*</Stack>*/}
      <Menu
        id="vitals-actions-menu"
        anchorEl={actionsAnchorEl}
        open={Boolean(actionsAnchorEl)}
        onClose={handleActionsMenuClose}
        MenuListProps={{
          'aria-labelledby': 'vitals-actions-button'
        }}
      >
        <MenuItem onClick={showDeleteConfirmation}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Stack my={2}>
        <VitalsReadingsSummary readingsSummaryValues={readingsSummaryValues} />
        {hasBaseline === true ? (
          <Stack my={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
            <Caption textTransform={'none'}>* Including days with baseline readings</Caption>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      <StyledTruentityDataGrid
        name={'dg-vitals-data'}
        style={{ border: 'none', outline: 'none', marginTop: '20px' }}
        autoHeight
        disableColumnMenu={false}
        rows={tableReadings}
        columns={columns}
        getRowClassName={params => (params?.row?.isBaseline ? 'vitals-readings-table-highlight' : '')}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={onPageChange}
        loading={loading}
        rowCount={totalCount ?? 0}
        paginationMode="server"
      />
    </Stack>
  );
};

export default VitalsTableData;
