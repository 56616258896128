import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { SelectListProps, TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { ClientOrganizationObject } from '@/types/administration';
import { useQuery } from '@apollo/client';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

const SELECT_DEFAULT: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];

type Props = Partial<SelectListProps> & {
  selectedOrgId: string;
  setSelectedOrgId: Dispatch<SetStateAction<string>>;
};

const OrganizationSelectList = ({ selectedOrgId, setSelectedOrgId, ...props }: Props) => {
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([
        ...SELECT_DEFAULT,
        ...organizationData.clientOrganizations.clientOrganizations
      ] as ClientOrganizationObject[]);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  return (
    <SelectList
      fullWidth={false}
      formControlProps={{ sx: { width: 300 } }}
      label="Organization"
      options={clientOrganizations.map(
        (item: ClientOrganizationObject) =>
          ({
            value: item.id,
            label: item.name
          } as TextSelectOption)
      )}
      placeholder="Select an option..."
      value={selectedOrgId}
      onChange={event => setSelectedOrgId(event.target.value)}
      {...props}
    />
  );
};

export default OrganizationSelectList;
