import type { ChangeEvent } from 'react';
import { useEffect } from 'react';
import type React from 'react';
import { Box, FormControlLabel, Grid, Stack, Switch } from '@mui/material';
import Alert from '@/components/Alert';
import type { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Titles } from '@/types/admin';
import TruentityTextField from '@/components/TruentityTextField';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import SelectList from '@/components/SelectList';
import { getCurrentAdminsUsableTitles } from '@/util/adminstration';
import { useSnackbar } from 'notistack';
import useToken from '@/hooks/useToken';
import { H3 } from '@/components/Typography';
import type { UserFormMode } from '@/types/utils';

export type AddUserFormValuesType = {
  relyingPartyId: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  isEmailLoginOtp: boolean;
  isTextLoginOtp: boolean;
  isObserver: boolean;
  isRpmPrimary: boolean;
  roleType: string;
  title: string;
  npiNumber: string;
  techLicense: string;
  organization: string;
};

export const addUserFormDefault: AddUserFormValuesType = {
  relyingPartyId: '',
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  isEmailLoginOtp: false,
  isTextLoginOtp: false,
  roleType: 'pharmacist',
  title: 'pharmacist',
  isObserver: false,
  isRpmPrimary: false,
  npiNumber: '',
  techLicense: '',
  organization: 'Truentity Health'
};

type AddUserFormProps = {
  hideHeader?: boolean;
  control: Control<AddUserFormValuesType>;
  setValue: UseFormSetValue<AddUserFormValuesType>;
  getValues: UseFormGetValues<AddUserFormValuesType>;
  watch: UseFormWatch<AddUserFormValuesType>;
  formMode: UserFormMode;
};

const AddUserForm: React.FC<AddUserFormProps> = ({ formMode, control, setValue, getValues, watch, hideHeader = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { roleType } = useToken();

  const isObserver = watch('isObserver');
  const email = watch('email');
  const isEmailLoginOtp = watch('isEmailLoginOtp');
  const isTextLoginOtp = watch('isTextLoginOtp');
  const mobileNumber = watch('mobileNumber');
  const title = watch('title');
  const nameFields = watch(['firstName', 'lastName']);

  const getEmail = () => {
    if (nameFields[0]?.length > 0) {
      return `${nameFields[0].toLowerCase()}@truentity.com`;
    }

    return '';
  };

  const handleToggleOtp = (otpType, checked) => {
    const otherOtpType = otpType === 'isEmailLoginOtp' ? 'isTextLoginOtp' : 'isEmailLoginOtp';
    const isOtherOtpActive = watch(otherOtpType);

    if (!checked && !isOtherOtpActive) {
      enqueueSnackbar('At least one OTP method must be enabled.', { variant: 'error' });
    } else {
      setValue(otpType, checked);
    }
  };

  useEffect(() => {
    if (title === Titles.CARE_OBSERVER) {
      setValue('isObserver', true);
    } else {
      setValue('isObserver', false);
    }
  }, [title, setValue]);

  useEffect(() => {
    if (formMode === 'NEW_COMPANY') {
      setValue('isRpmPrimary', true);
    }
  }, [formMode, setValue]);

  const handleEmailOnchange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (...event: any[]) => void) => {
    const newValue = e.target.value;
    if (newValue.trim().length === 0) {
      if (!isTextLoginOtp && mobileNumber.trim().length > 0) setValue('isTextLoginOtp', true);
      setValue('isEmailLoginOtp', false);
    }
    onChange(e);
  };

  const handleTextOnchange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, onChange: (...event: any[]) => void) => {
    const newValue = e.target.value;
    if (newValue.trim().length > 0) {
      setValue('isTextLoginOtp', true);
    } else {
      if (!isEmailLoginOtp && email.trim().length > 0) setValue('isEmailLoginOtp', true);
      setValue('isTextLoginOtp', false);
    }
    onChange(e);
  };

  const updateDefaultEmail = () => {
    const emailField = getValues('email');
    if (emailField?.length === 0) {
      const email = getEmail();
      setValue('email', email);
    }
  };

  return (
    <Stack spacing={2}>
      {!hideHeader && <H3>Add New User</H3>}
      <Alert
        description="This user is an Observer. Activities of this user will not be included for billing."
        status="info"
        hidden={!isObserver}
      />
      <Stack flexDirection={'row'} justifyContent={'flex-end'} width={'100%'} alignItems={'center'}>
        <Controller
          control={control}
          name="isObserver"
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch disabled={title === Titles.CARE_OBSERVER} checked={value} onChange={onChange} />}
              label="Observer"
            />
          )}
        />
        {formMode === 'NEW_COMPANY' && (
          <Controller
            control={control}
            name="isRpmPrimary"
            render={({ field: { onChange, value } }) => (
              <FormControlLabel control={<Switch checked={value} onChange={onChange} />} label="RPM Primary" />
            )}
          />
        )}
      </Stack>
      <Grid item xs={6}>
        <Stack>
          <Controller
            control={control}
            name="firstName"
            rules={{ required: 'First Name is required' }}
            render={({ field: { onChange, value } }) => (
              <TruentityTextField autoFocus required onBlur={updateDefaultEmail} onChange={onChange} value={value} label={'First Name'} />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            rules={{ required: 'Last Name is required' }}
            render={({ field: { onChange, value } }) => (
              <TruentityTextField required onChange={onChange} onBlur={updateDefaultEmail} value={value} label={'Last Name'} />
            )}
          />

          <Stack direction="row" gap={2}>
            <Box flex={0.7}>
              <Controller
                control={control}
                name="email"
                rules={{ required: 'Email is required' }}
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField
                    type={'email'}
                    required
                    onChange={e => handleEmailOnchange(e, onChange)}
                    value={value}
                    label={'Email'}
                  />
                )}
              />
            </Box>
            <Stack flex={0.3} justifyContent="center" alignItems="center">
              <Controller
                control={control}
                name="isEmailLoginOtp"
                render={({ field: { value } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={email.trim().length <= 0}
                        checked={value}
                        onChange={e => handleToggleOtp('isEmailLoginOtp', e.target.checked)}
                      />
                    }
                    label="Email OTP"
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction="row" gap={2}>
            <Box flex={0.7}>
              <Controller
                control={control}
                name="mobileNumber"
                rules={{ required: 'Mobile Number is required' }}
                render={({ field: { onChange, value } }) => (
                  <TruentityPhoneNumber
                    label={'Mobile Phone'}
                    value={value}
                    onChange={e => handleTextOnchange(e, onChange)}
                    editable={true}
                    required
                  />
                )}
              />
            </Box>
            <Stack flex={0.3} justifyContent="center" alignItems="center">
              <Controller
                control={control}
                name="isTextLoginOtp"
                render={({ field: { value } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={mobileNumber.trim().length <= 0}
                        checked={value}
                        onChange={e => handleToggleOtp('isTextLoginOtp', e.target.checked)}
                      />
                    }
                    label="SMS OTP"
                  />
                )}
              />
            </Stack>
          </Stack>

          <Controller
            control={control}
            name="techLicense"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'License Number'} />}
          />
        </Stack>
      </Grid>

      <Grid item xs={6}>
        <Stack>
          {/* This Portal Org is that of the current actor
                        <Controller control={control} name='organization' render={({ field: { onChange, value } }) => (
                            <SelectList
                                label='Organization'
                                options={RELYING_PARTY_OPTIONS}
                                placeholder='Select an option...'
                                value={value}
                                disabled={true}
                                onChange={onChange} />
                        )} />
                        */}

          <Controller
            control={control}
            name="title"
            rules={{ required: 'Title is required' }}
            render={({ field: { onChange, value } }) => (
              <SelectList
                required
                options={getCurrentAdminsUsableTitles(roleType)}
                placeholder="Select an option..."
                onChange={event => {
                  onChange(event);
                }}
                value={value}
                label={'Title'}
              />
            )}
          />

          <Controller
            control={control}
            name="npiNumber"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'NPI Number'} />}
          />
        </Stack>
      </Grid>
    </Stack>
  );
};

export default AddUserForm;
