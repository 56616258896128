import IconWithChildrenContainer from '@/components/IconWithChildrenContainer';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, H1 } from '@/components/Typography';
import styled from '@/styles';
import { formatDate } from '@/util/format';
import { unknown } from '@/util/string';
import { faMapMarkerAlt, faPhoneAlt, faVenusMars } from '@fortawesome/free-solid-svg-icons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton, Stack } from '@mui/material';
import moment from 'moment';
import type { ReactNode } from 'react';
import { useState } from 'react';

const Container = styled('div', {
  marginRight: '20px'
});

type Props = {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  email?: string;
  address?: string;
  phone?: string;
  gender?: string;
  children?: ReactNode;
};

const PatientInfo = ({
  firstName = '',
  lastName = '',
  birthDate = '',
  email = '---',
  address = '---',
  phone = '---',
  gender = '---',
  children
}: Props) => {
  const fullName = lastName && firstName ? `${lastName}, ${firstName}` : '---';
  const currentDate = new Date();
  const birthDateTodate = birthDate ? new Date(birthDate.replace(/-/g, '/')) : '';
  const years = moment(currentDate).diff(moment(birthDateTodate), 'years');

  const [collapsePatientInfo, setCollapsePatientInfo] = useState(false);

  return (
    <>
      <IconButton
        onClick={() => setCollapsePatientInfo(!collapsePatientInfo)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
          zIndex: theme => theme.zIndex.appBar
        }}
      >
        <ExpandMoreIcon sx={{ transform: collapsePatientInfo ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </IconButton>

      <Container>
        <H1>{fullName}</H1>

        <Collapse in={!collapsePatientInfo} timeout="auto">
          <Stack direction="row">
            <Stack spacing={1} minWidth="fit-content">
              <Body1>{birthDate ? `${formatDate(birthDateTodate)} (${years} yr)` : '---'}</Body1>

              {/* @2024/04/05 Patient emails hidden */}
              {/* <IconWithChildrenContainer icon={faEnvelope}>
                <Body1>{email ?? unknown()}</Body1>
              </IconWithChildrenContainer> */}

              <IconWithChildrenContainer icon={faMapMarkerAlt}>
                <Body1>{address ?? unknown()}</Body1>
              </IconWithChildrenContainer>

              <IconWithChildrenContainer icon={faPhoneAlt}>
                {phone ? <TruentityPhoneNumber editable={false} value={phone} /> : <Body1>{unknown()}</Body1>}
              </IconWithChildrenContainer>

              <IconWithChildrenContainer icon={faVenusMars}>
                <Body1>{gender ?? unknown()}</Body1>
              </IconWithChildrenContainer>
            </Stack>

            {children}
          </Stack>
        </Collapse>
      </Container>
    </>
  );
};

export default PatientInfo;
