import { useState } from 'react';

import { trackLogout } from '@/services/activity-log';
import { AccountsMonitoringDeviceType } from '@/types/remotePatientMonitoring';
import { clearCache } from '@/util/apollo/cache';
import ls, { ACCESS_TOKEN_KEY, IDLE_KEY, REFRESH_TOKEN_KEY, RELAYING_PARTY_ID_KEY, ROLE_TYPE_KEY } from '@/util/localstorage';
import { Stack } from '@/util/stack';
import { useNavigate } from 'react-router-dom';

export type RecentPatientType = {
  truentityId: string;
  name: string;
  rpmStatus: string | null;
  accountsMonitoringDevices?: AccountsMonitoringDeviceType[];
};

const useToken = () => {
  const getToken = () => {
    return ls.get(ACCESS_TOKEN_KEY);
  };
  const getRefreshToken = () => {
    return ls.get(REFRESH_TOKEN_KEY);
  };
  const getRelayingPartyId = () => {
    return ls.get(RELAYING_PARTY_ID_KEY);
  };
  const getRoleType = () => {
    return ls.get(ROLE_TYPE_KEY);
  };
  const getIsIdle = () => {
    return ls.get(IDLE_KEY);
  };

  const navigate = useNavigate();
  const [token, setToken] = useState(getToken());
  const [refreshToken, setRefreshToken] = useState(getRefreshToken());
  const [relayingPartyId, setRelayingPartyId] = useState(getRelayingPartyId());
  const [roleType, setRoleType] = useState(getRoleType());
  const saveToken = userToken => {
    setToken(userToken);
    ls.set(ACCESS_TOKEN_KEY, userToken);
  };
  const saveTokens = (userToken, userRefreshToken) => {
    ls.remove(IDLE_KEY);
    setToken(userToken);
    setRefreshToken(userRefreshToken);
    ls.multiSet({
      [ACCESS_TOKEN_KEY]: userToken,
      [REFRESH_TOKEN_KEY]: userRefreshToken
    });
  };

  const getRecentPatients = (): RecentPatientType[] => {
    const key = 'recent-patients-' + token;
    const stack = new Stack(3, key);
    return stack.getItems() as RecentPatientType[];
  };

  const addNewRecentPatient = (data: RecentPatientType) => {
    const key = 'recent-patients-' + token;
    const stack = new Stack(3, key);
    stack.push(data);
  };
  const logout = (timedOut = false) => {
    if (timedOut) {
      ls.set(IDLE_KEY, new Date().toISOString());
    }

    trackLogout({ timedOut }).finally(() => removeTokenAndRefreshToken());
  };

  const handleLogout = (timedOut = false) => {
    logout(timedOut);
    clearCache();
    ls.clear();
    navigate('/login');
  };

  const removeTokenAndRefreshToken = () => {
    setToken(null);
    setRefreshToken(null);
    ls.clear();
  };
  const saveRelayingPartyId = id => {
    setRelayingPartyId(id);
    ls.set(RELAYING_PARTY_ID_KEY, id);
  };
  const saveRoleType = roleType => {
    setRoleType(roleType);
    ls.set(ROLE_TYPE_KEY, roleType);
  };

  return {
    setToken: saveToken,
    setTokenAndRefreshToken: saveTokens,
    setRelayingPartyId: saveRelayingPartyId,
    setRoleType: saveRoleType,
    logout,
    token,
    refreshToken,
    relayingPartyId,
    roleType,
    getIsIdle,
    handleLogout,
    getRecentPatients,
    addNewRecentPatient
  };
};

export default useToken;
