import { color } from './assets/colors';

export const theme = {
  truentityBlue: color.truentityBlue[500],
  truentityDarkCyan: color.truentityCyan[500],
  truentityDarkSalmon: color.truentitySalmon[800],
  truentitySalmon: color.truentitySalmon[500],
  truentityDeepGreen: color.successDark,
  truentityDarkGray: color.grey700,
  truentityMediumGray: color.grey600,
  truentityGray: color.grey500,
  truentityLigthGray: color.grey100,
  truentitySnow: color.paper,
  truentityWhite: color.paper,
  truentityAmber: color.warningDark
};

export const text = {
  textDefault: color.grey600
};

export default {
  ...theme,
  ...text
};
