import type { DropzoneFile, FileWithOrigin } from '@/components/TruentityDropzone';
import TruentityDropzone from '@/components/TruentityDropzone';
import { H3 } from '@/components/Typography';
import { Stack } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

type Props = {
  files: FileWithOrigin[];
  setFiles: Dispatch<SetStateAction<FileWithOrigin[]>>;
};

const AnnouncementMediaForm = ({ files, setFiles }: Props) => {
  const handleFiles = (files: DropzoneFile[]) => {
    setFiles(files.map(file => file));
  };

  return (
    <Stack gap={2}>
      <H3>Add Media (optional)</H3>
      <TruentityDropzone maxFileCount={3} maxFileSize={2} mediaFiles={files} onFileUpload={handleFiles} onRemoveFile={handleFiles} />
    </Stack>
  );
};

export default AnnouncementMediaForm;
