import { Role } from '@/types/admin';
import type { TabBasedActiveAdminsType } from '@/types/remotePatientMonitoring';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Stack } from '@mui/material';

type Props = {
  tabBasedActiveAdmin: TabBasedActiveAdminsType | undefined;
};

const RpmWorkflowTabLabelWithStatus = ({ tabBasedActiveAdmin }: Props) => {
  return (
    <>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
        {tabBasedActiveAdmin &&
          Object.keys(tabBasedActiveAdmin.admins).map(admin => {
            const activeAdmins: number = tabBasedActiveAdmin.admins[admin];
            const color = admin === Role.PROVIDER ? 'secondary' : 'primary';

            return activeAdmins > 0 ? <FiberManualRecordIcon fontSize="small" color={color} sx={{ fontSize: '0.7rem' }} /> : null;
          })}
      </Stack>
    </>
  );
};

export default RpmWorkflowTabLabelWithStatus;
