import { END_ACTIVITY_LOG, HEARTBEAT, LOGOUT_LOG, RELYING_PARTY_NAVIGATE, START_ACTIVITY_LOG } from '@/graphql/activityLog';
import client from '@/util/apollo/client';

export const startTrackingMedTaskActivity = async ({ truentityId }) => {
  try {
    const result = await client.mutate({
      mutation: START_ACTIVITY_LOG,
      variables: { truentityId }
    });

    return result.data.startMedTasksActivity.activityLog;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const stopTrackingMedTaskActivity = async ({ activityLogId }) => {
  try {
    const result = await client.mutate({
      mutation: END_ACTIVITY_LOG,
      variables: { activityLogId }
    });

    return result.data.endMedTasksActivity;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const trackLogout = async ({ timedOut }) => {
  try {
    const result = await client.mutate({
      mutation: LOGOUT_LOG,
      variables: { timedOut }
    });

    return result.data.relyingPartyLogout.status;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export type ActivityLog = {
  id: string;
  logText: string;
  activityType: string;
  activityTime: Date;
  activityEndTime: Date;
  timeTakenSecs: number;
};

export const trackNavigation = async ({ navigateTo, prevActivityLogId, truentityId }): Promise<ActivityLog | null> => {
  try {
    const result = await client.mutate({
      mutation: RELYING_PARTY_NAVIGATE,
      variables: { navigateTo, prevActivityLogId, truentityId }
    });

    return result.data.relyingPartyAdminNavigate.activityLog;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const sendHeartbeat = async (): Promise<any> => {
  try {
    const result = await client.mutate({
      mutation: HEARTBEAT
    });

    return result.data.relyingPartyAdminHeartbeat.status;
  } catch (error) {
    console.error(error);
  }

  return null;
};
