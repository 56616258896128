import { ActivityTypes, LogNames } from '@/types/activityLog';

export const categorizedActivityLogTypes = [
  {
    logName: LogNames.CARE_ACTIVITY_LOGS,
    logTypes: [ActivityTypes.CARE_ACTIVITY_STARTED, ActivityTypes.CARE_ACTIVITY_ENDED_TIMEDOUT, ActivityTypes.CARE_ACTIVITY_ENDED_LOGGEDOUT]
  },
  {
    logName: LogNames.ENCOUNTER_LOGS,
    logTypes: [ActivityTypes.ENCOUNTER_STARTED, ActivityTypes.ENCOUNTER_ENDED_TIMEDOUT, ActivityTypes.ENCOUNTER_ENDED_LOGGEDOUT]
  },
  {
    logName: LogNames.ADMIN_INVOICE_LOGS,
    logTypes: [ActivityTypes.INVOICE_ADMIN_EMAILED]
  },
  {
    logName: LogNames.MEDICATION_SNAPSHOT_LOGS,
    logTypes: [ActivityTypes.MEDS_ADMIN_EMAILED]
  },
  {
    logName: LogNames.MEDICATION_EHR_LOGS,
    logTypes: [ActivityTypes.MEDS_EHR_UPLOADED, ActivityTypes.MEDS_EHR_SUBMITTED]
  },
  {
    logName: LogNames.MEDICATION_ESI_LOGS,
    logTypes: [ActivityTypes.MEDS_ESI_IMPORTED_DIRECT]
  },
  {
    logName: LogNames.PATIENT_MEDICATION_VIEW_LOGS,
    logTypes: [ActivityTypes.MEDS_EPHEMERAL_VIEWED, ActivityTypes.MEDS_EPHEMERAL_EMAILED]
  },
  {
    logName: LogNames.PATIENT_CONSENT_LOGS,
    logTypes: [ActivityTypes.PATIENT_CONSENT_UPDATED]
  },
  {
    logName: LogNames.PATIENT_MED_TASK_LOGS,
    logTypes: [ActivityTypes.PATIENT_MEDTASKS_PERFORMED]
  },
  {
    logName: LogNames.SESSION_LOGS,
    logTypes: [
      ActivityTypes.SESSION_STARTED,
      ActivityTypes.SESSION_NAVIGATED_TO,
      ActivityTypes.SESSION_ENDED_TIMEDOUT,
      ActivityTypes.SESSION_ENDED_LOGGEDOUT
    ]
  },
  {
    logName: LogNames.ORGANIZATION_CTA_LOGS,
    logTypes: [ActivityTypes.RP_CTA_ACCEPTED, ActivityTypes.RP_CTA_REJECTED]
  },
  {
    logName: LogNames.RPM_DEVICE_LOGS,
    logTypes: [ActivityTypes.RPM_ADD_DEVICES]
  },
  {
    logName: LogNames.RPM_COMMUNICATION_LOGS,
    logTypes: [
      ActivityTypes.RPM_MONTHLY_SMS_SENT,
      ActivityTypes.RPM_MONTHLY_SMS_NOTSENT,
      ActivityTypes.RPM_DAILY_REMINDER_SMS_SENT,
      ActivityTypes.RPM_MONTHLY_REPORT_SENT,
      ActivityTypes.RPM_MONTHLY_REPORT_NOT_SENT
    ]
  },
  {
    logName: LogNames.RPM_CONSULT_LOGS,
    logTypes: [ActivityTypes.RPM_START_CONSULT]
  },
  {
    logName: LogNames.RPM_CONFIGURATION_LOGS,
    logTypes: [ActivityTypes.RPM_CONFIGURATION_UPDATE]
  },
  {
    logName: LogNames.RPM_CONSENT_LOGS,
    logTypes: [ActivityTypes.RPM_CONSENT_UPDATE]
  },
  {
    logName: LogNames.RPM_CARE_PLAN_LOGS,
    logTypes: [ActivityTypes.RPM_CARE_PLAN_UPDATE]
  },
  {
    logName: LogNames.RPM_SNAPSHOT_LOGS,
    logTypes: [ActivityTypes.RPM_SNAPSHOT_CREATED, ActivityTypes.RPM_SNAPSHOT_NOT_CREATED]
  },
  {
    logName: LogNames.RPM_REPORTS_LOGS,
    logTypes: [
      ActivityTypes.RPM_REPORT_FINALIZED,
      ActivityTypes.RPM_REPORT_NOT_FINALIZED,
      ActivityTypes.RPM_REPORT_FAX_SENT,
      ActivityTypes.RPM_REPORT_FAX_NOT_SENT,
      ActivityTypes.RPM_REPORT_GENERATED,
      ActivityTypes.RPM_REPORT_NOT_GENERATED,
      ActivityTypes.RPM_REPORT_REGENERATED,
      ActivityTypes.RPM_REPORT_NOT_REGENERATED,
      ActivityTypes.RPM_REPORT_SEND_NOW,
      ActivityTypes.UPDATE_REPORT_DAILY_SEND_STATUS_TO_READY_FOR_SENDING,
      ActivityTypes.UPDATE_REPORT_DAILY_SEND_STATUS_TO_SCHEDULE,
      ActivityTypes.UPDATE_REPORT_DAILY_SEND_STATUS_TO_NULL,
      ActivityTypes.UPDATE_REPORT_DAILY_SEND_STATUS_TO_SENT,
      ActivityTypes.UPDATE_REPORT_DAILY_SEND_STATUS_TO_FAILED,
      ActivityTypes.RPM_MONTHLY_REPORT_SENT,
      ActivityTypes.RPM_MONTHLY_REPORT_NOT_SENT
    ]
  },
  {
    logName: LogNames.PROVIDER_LOGS,
    logTypes: [
      ActivityTypes.UPDATE_RPM_APROVAL_STATUS_TO_IN_REVIEW,
      ActivityTypes.UPDATE_RPM_APROVAL_STATUS_TO_APPROVE,
      ActivityTypes.UPDATE_RPM_APROVAL_STATUS_TO_REJECT,
      ActivityTypes.UPDATE_RPM_APROVAL_STATUS_TO_SKIPPED
    ]
  },
  {
    logName: LogNames.PATIENTS_MEDICATION_HISTORY_LOGS,
    logTypes: [
      ActivityTypes.PATIENT_MED_HISTORY_FILE_LOAD_CREATED,
      ActivityTypes.PATIENT_MED_HISTORY_FILE_LOAD_NOT_CREATED,
      ActivityTypes.PATIENT_MED_HISTORY_RESPONSE_FILE_SUCESSS,
      ActivityTypes.PATIENT_MED_HISTORY_RESPONSE_FILE_FAILED,
      ActivityTypes.PATIENT_MED_HISTORY_MEDICATION_FILE_RECEIVED,
      ActivityTypes.PATIENT_MED_HISTORY_MEDICATION_ADDED_SUCCESS,
      ActivityTypes.PATIENT_MED_HISTORY_PATIENT_NOT_FOUND
    ]
  },
  {
    logName: LogNames.PATIENT_VITAL_ACCESS_LOGS,
    logTypes: [ActivityTypes.VITALS_EPHEMERAL_VIEWED, ActivityTypes.VITALS_EPHEMERAL_EMAILED]
  }
];
