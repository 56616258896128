import { Box, DialogActions, DialogContent, TextField } from '@mui/material';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const PIONEERRX_DEFAULT_USERNAME = 'pioneer-endpoint';

type Props = BaseDialogProps & {
  data: {
    username: string;
    password: string;
  };
  hideDialog: () => void;
  onSetCredentials: (username: string, password: string) => void;
};

type FormValues = {
  username: string;
  password: string;
};

const defaultValues: FormValues = {
  username: PIONEERRX_DEFAULT_USERNAME,
  password: ''
};

const PioneerRxAuthDialog = ({ title, data: authData, hideDialog, onSetCredentials, ...props }: Props) => {
  const { control, reset, handleSubmit } = useForm<FormValues>({ defaultValues });

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    onSetCredentials(values.username, values.password);
  };

  useEffect(() => {
    if (authData) {
      const formData = {
        username: PIONEERRX_DEFAULT_USERNAME,
        password: ''
      };
      if (authData.username && authData.username.trim().length !== 0) {
        formData.username = authData.username;
      }
      if (authData.password && authData.password.trim().length !== 0) {
        formData.password = authData.password;
      }
      return reset(formData);
    }
  }, [authData]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth="xs" {...props}>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box py={1}>
            <Controller
              control={control}
              name="username"
              render={({ field: { onChange, value } }) => (
                <TextField
                  type={'text'}
                  size="small"
                  label="Username"
                  value={value}
                  onChange={onChange}
                  autoFocus
                  sx={{ width: '100%' }}
                  required
                />
              )}
            />
          </Box>
          <Box py={1}>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <TextField
                  type={'password'}
                  label="Password"
                  size="small"
                  value={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  required
                />
              )}
            />
          </Box>

          <DialogActions sx={{ justifyContent: 'end', p: 0, pt: 1 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                // reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default PioneerRxAuthDialog;
