import type { TextFieldProps as MuiTextFieldProps } from '@mui/material';
import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

export type TruentityTextFieldProps = MuiTextFieldProps & {
  label?: string;
  min?: number | string | undefined;
  max?: number | string | undefined;
  step?: number | string | undefined;
  maxLength?: number | string | undefined;
  shrink?: boolean;
  icon?: ReactNode;
  multiple?: boolean;
  startIcon?: ReactNode;
  autoFocus?: boolean;
};

const TruentityTextField = forwardRef(
  (
    {
      id,
      label,
      min,
      max,
      step,
      maxLength,
      defaultValue,
      shrink,
      autoFocus,
      icon,
      multiple,
      startIcon,
      InputProps,
      ...props
    }: TruentityTextFieldProps,
    ref
  ) => {
    const variant = props.variant || 'outlined';

    const endAdornment = icon ? <InputAdornment position="start">{icon}</InputAdornment> : undefined;

    const startAdornment = startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : undefined;

    if (props.value && typeof shrink === 'undefined') {
      shrink = true;
    }

    return (
      <MuiTextField
        id={id}
        label={label}
        value={defaultValue}
        fullWidth
        variant={variant}
        margin="dense"
        size="medium"
        autoFocus={autoFocus}
        {...props}
        InputLabelProps={{
          shrink
        }}
        InputProps={{
          endAdornment,
          startAdornment,
          inputProps: {
            min,
            max,
            step,
            maxLength,
            multiple
          },
          ...InputProps
        }}
        inputRef={ref}
      />
    );
  }
);

export default TruentityTextField;
