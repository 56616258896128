import Icon from '@/elements/Icon';
import styled from '@/styles';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const Section = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  margin: '10px 0',

  '> p': {
    color: 'red'
  }
});

const List = styled('section', {
  display: 'flex',
  flex: '1',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  flexDirection: 'column'
});

const ImagePreview = styled('img', {
  width: '100%',
  height: '100%',
  border: '5px solid red',
  borderColor: '#2b5281'
});

const ImageContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'auto',
  overflow: 'hidden',
  marginTop: '10px',
  maxWidth: 'fit-content(100%)',
  padding: '0',
  boxSizing: 'border-box'
});

const DeleteButton = styled('button', {
  width: '30px',
  height: '30px',
  color: 'white',
  appearance: 'none',
  border: 'none',
  position: 'absolute',
  right: '0',
  bottom: '0',
  cursor: 'pointer',
  backgroundColor: '#2b5281',
  borderTopLeftRadius: '10px'
});

export interface ImageFile {
  name: string;
  src: string;
}

export type ImageListProps = {
  files: {};
  onRemoveFileCb?: Function;
};

const ImageList = ({ files = {}, onRemoveFileCb }: ImageListProps) => {
  const onDeleteBtnClicked = fileName => {
    if (onRemoveFileCb) {
      onRemoveFileCb(fileName);
    }
  };

  const isFile = input => 'File' in window && input instanceof File;
  const isBlob = input => 'Blob' in window && input instanceof Blob;

  return (
    <Section>
      <List>
        {Object.keys(files).map((fileName, index) => {
          const file = files[fileName];
          const imageSrc = isFile(file) ? URL.createObjectURL(file) : file;

          return (
            <ImageContainer key={index}>
              <ImagePreview src={imageSrc} width="1280" height="720" alt={`Image Preview: ${fileName}`} />
              <DeleteButton onClick={() => onDeleteBtnClicked(fileName)} type="button">
                <Icon icon={faTrashAlt} fixedWidth color="white" />
              </DeleteButton>
            </ImageContainer>
          );
        })}
      </List>
    </Section>
  );
};

export default ImageList;
