import { Message } from '@/types/coPilot';
import create from 'zustand';

type MessageState = {
  messages: Message[];
  addMessage: (message: Message) => void;
  clearMessages: () => void;
};

export const useCopilotMessageStore = create<MessageState>(set => ({
  messages: [],
  addMessage: message =>
    set(state => ({
      messages: [...state.messages, message]
    })),
  clearMessages: () => set({ messages: [] })
}));
