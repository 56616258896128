import { groupBy } from '@/util/array';
import { duration, formatTime } from '@/util/format';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Divider, List, ListItem, Paper, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import type { AccordionOption } from './MuiAccordion';
import MuiAccordion from './MuiAccordion';
import { Body1, H3, H4 } from './Typography';

const getLabel = activity => {
  let label = 'General';

  if (activity?.account) {
    label = activity.account.user?.firstName + ' ' + activity.account.user?.lastName;
  }

  return label;
};

const ActivityType = ({ activityType, ...props }) => {
  const activityTypeSplit = activityType?.split('::');
  return (
    <Stack direction="row" alignItems={'center'} spacing={1} {...props}>
      {activityTypeSplit?.map((label, index) => {
        const variant = index % 2 === 0 ? 'filled' : 'outlined';
        return (
          <Stack key={label} direction={'row'}>
            <Chip label={label} variant={variant} />
            {index !== activityTypeSplit.length - 1 && <ArrowForwardIcon />}
          </Stack>
        );
      })}
    </Stack>
  );
};

const Activity = ({ activity }) => {
  let activityTime = formatTime(activity?.activityTime);

  if (activity?.activityEndTime) {
    activityTime += ` until ${formatTime(activity?.activityEndTime)}`;
  }

  return (
    <Paper component={Stack} sx={{ width: '100%' }}>
      <Stack direction="row" alignItems={'center'} spacing={4} sx={{ padding: '10px', height: '100px', flex: 1 }}>
        <H4>Activity</H4>
        <Body1 sx={{ flex: 1 }}>{activity?.logText}</Body1>
        <Stack alignItems={'center'}>
          <ActivityType key={activity?.id} activityType={activity?.activityType} />
        </Stack>
      </Stack>

      <Divider />

      <Stack
        direction="row"
        alignItems={'center'}
        justifyContent={'flex-end'}
        sx={{
          padding: '10px',
          bgcolor: theme => theme.palette.grey[50],
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
          width: '100%'
        }}
        spacing={2}
      >
        <Body1>{activityTime}</Body1>
        <Chip label={duration(activity?.timeTakenSecs)} color={'primary'}></Chip>
      </Stack>
    </Paper>
  );
};

export const ActivitiesList = ({ activities }) => {
  const [options, setOptions] = useState<AccordionOption[]>([]);

  useEffect(() => {
    let _options: AccordionOption[] = [];

    const groupedByAccount = groupBy<any>(activities, activity => activity?.account?.truentityId || -1);
    const entries = Object.entries(groupedByAccount);

    entries.forEach((entry, index) => {
      const [, activities] = entry;

      if (activities?.length > 0) {
        _options.push({
          label: getLabel(activities[0]),
          defaultExpand: index === 0,
          content: (
            <List sx={{ width: '100%', bgcolor: theme => theme.palette.primary.light, borderRadius: '10px' }}>
              {activities?.map((activity, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <Activity activity={activity} key={index} />
                </ListItem>
              ))}
            </List>
          )
        });
      }
    });

    setOptions(_options);
  }, [activities]);

  return (
    <Stack alignItems={'flex-start'} justifyContent={'center'} sx={{ paddingTop: '20px' }}>
      {options?.length === 0 && <H3>No Activities</H3>}
      {options?.length > 0 && <MuiAccordion sx={{ width: '100%' }} options={options} />}
    </Stack>
  );
};
