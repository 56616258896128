import { color } from '@/styles/assets/colors';
import { controlOrMetaKeyUsed, shiftKeyUsed } from '@/util/events';
import { buildDate, buildRenderValue, handleUndefinedEntry } from '@/util/medications';
import { Card, CardContent, CardHeader, Divider, Grid, Stack } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableProvided, DraggableStateSnapshot } from '../../types/triage-medication-types';
import MedicationTypeIcon from '../MedicationTypeIcon';
import { Small } from '../Typography';

const style = props => ({
  borderRadius: '2px',
  marginBottom: '8px',
  color: 'darkgrey',
  position: 'relative',
  userSelect: 'none',
  padding: '10px',
  border: props.isSelected ? `2px solid ${color.truentityBlue[500]}` : 'none',

  '&:focus': {
    outline: 'none'
  }
});

const TaskItemText = ({ label, value }) => (
  <Stack sx={{ marginBottom: '10px' }}>
    <Small fontWeight="bold">{label}</Small>
    <Small>{value}</Small>
  </Stack>
);

const Task = ({ task, isSelected, index, selectionCount, toggleSelection, toggleSelectionInGroup, multiSelectTo, goToMedicationById }) => {
  const {
    id,
    displayName,
    prescriberName,
    pharmacyName,
    adherance,
    lastFillDateAt,
    prescriptionWrittenDateAt,
    soldDateAt,
    strength,
    dosage,
    importedName,
    images,
    numRefills,
    daysSupply,
    instructions,
    isSupplement
  } = task.content;

  const onDoubleClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button

    if (event.button !== 0) {
      return;
    }

    // marking the event as used
    event.preventDefault();
    goToMedicationById(task.id);
  };
  // Using onClick as it will be correctly
  // preventing if there was a drag
  const onClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button

    if (event.button !== 0) {
      return;
    }

    // marking the event as used
    event.preventDefault();
    performAction(event);
  };

  const onTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    event.preventDefault();
    toggleSelectionInGroup(task.id);
  };

  const performAction = (event: MouseEvent | KeyboardEvent) => {
    if (controlOrMetaKeyUsed(event)) {
      toggleSelectionInGroup(task.id);
      return;
    }

    if (shiftKeyUsed(event)) {
      multiSelectTo(task.id);
      return;
    }

    toggleSelection(task.id);
  };

  const getStyle = (style, snapshot) => {
    if (!snapshot.isDragging) return {};
    if (!snapshot.isDropAnimating) {
      return style;
    }

    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`
    };
  };

  return (
    <Draggable draggableId={task.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        const shouldShowSelection: boolean = snapshot.isDragging && selectionCount > 1;

        return (
          <Card
            key={id}
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            sx={style({ isSelected })}
            style={getStyle(draggableProps.style, snapshot)}
            onClick={onClick}
            onTouchEnd={onTouchEnd}
            onDoubleClick={onDoubleClick}
          >
            <CardHeader
              sx={{ paddingBottom: 0 }}
              title={displayName}
              subheader={
                <>
                  <Stack spacing={2}>
                    <MedicationTypeIcon type={isSupplement ? 'SUPPLEMENT' : 'PRESCRIPTION'} showLabel={true}></MedicationTypeIcon>
                    {importedName?.length > 0 && <TaskItemText label="Imported Name" value={importedName} css={{ marginTop: '5px' }} />}
                    <Grid container>
                      <Grid item xs={6}>
                        <TaskItemText label="Strength" value={buildRenderValue(strength)} />
                      </Grid>
                      <Grid item xs={6}>
                        <TaskItemText label="Dosage" value={buildRenderValue(dosage)} />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Divider />
                </>
              }
            />

            <CardContent component={Grid} container rowSpacing={1}>
              <Grid item xs={6}>
                <TaskItemText label="Date Written" value={buildDate(prescriptionWrittenDateAt)} />
                <TaskItemText label="Fill Date" value={buildDate(lastFillDateAt)} />
                <TaskItemText label="Sold Date" value={buildDate(soldDateAt)} />
              </Grid>

              <Grid item xs={6}>
                <TaskItemText label="Refills" value={handleUndefinedEntry(numRefills)} />
                <TaskItemText label="Days" value={handleUndefinedEntry(daysSupply)} />
                <></>
              </Grid>

              {instructions && (
                <Grid item xs={12}>
                  <TaskItemText label="Sig" value={instructions} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={6}>
                <TaskItemText label="Provider" value={handleUndefinedEntry(prescriberName)} />
              </Grid>

              <Grid item xs={6}>
                <TaskItemText label="Pharmacy" value={handleUndefinedEntry(pharmacyName)} />
              </Grid>
            </CardContent>
          </Card>
        );
      }}
    </Draggable>
  );
};

export default Task;
