import { DELETE_RPM_ACTIVITY } from '@/graphql/remotePatientMonitoring';
import { CareActivityType } from '@/types/remotePatientMonitoring';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import Alert from '../Alert';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  data: CareActivityType;
  hideDialog: () => void;
  onActivitiesChanged: () => void;
};

const DeleteAccountActivityConfirmationDialog = ({
  id,
  data,
  title,
  hideDialog,
  onActivitiesChanged,
  ...props
}: Props): React.ReactElement => {
  const [deleteAccountActivity] = useMutation(DELETE_RPM_ACTIVITY);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitImpl = async () => {
    try {
      deleteAccountActivity({
        variables: {
          truentityId: id,
          activityId: data.id
        }
      })
        .then(response => {
          const data = response.data!.deleteRpmActivity;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
          onActivitiesChanged();
          hideDialog();
        })
        .catch(error => {
          enqueueSnackbar('Unable to delete activity', {
            variant: 'error'
          });
          hideDialog();
        });
    } catch (err) {
      const failMessage: string = 'Failed to delete a activity';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Alert description="Are you sure want to delete?" status={'warning'} />
        <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
          <Stack direction="row" sx={{ justifyContent: 'flex-end', alignItems: 'center', marginTop: 2 }} spacing={1}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                hideDialog();
              }}
            />
            <Button
              type="submit"
              a11yLabel="Delete"
              onClick={event => {
                event.preventDefault();
                handleSubmitImpl();
              }}
              appearance="primary"
            />
          </Stack>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default DeleteAccountActivityConfirmationDialog;
