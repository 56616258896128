import { Caption, H4 } from '@/components/Typography';
import { GetAdminPerformancesResponse } from '@/graphql/administration';
import { PerformanceData, PerformanceTableType } from '@/types/administration';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import { Chip, Paper, TableBody } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import React, { useCallback, useEffect } from 'react';

interface PerformanceDashboardTableProps {
  tableType: PerformanceTableType;
  performanceData: GetAdminPerformancesResponse | undefined;
}

export const PerformanceTableRow: React.FC<PerformanceData & { utilizationChip: React.ReactNode; typeLabel: string }> = ({
  label,
  stats,
  utilizationChip,
  typeLabel
}) => {
  return (
    <TableRow>
      <TableCell>
        <Box sx={{ textTransform: 'capitalize', my: 1 }}>
          {typeLabel}
          <br />
          <Caption textTransform="capitalize">{label}</Caption>
        </Box>
      </TableCell>
      <TableCell>{stats?.tipsCompleted}</TableCell>
      <TableCell>{stats?.cmrsCompleted}</TableCell>
      <TableCell>{stats?.encounters}</TableCell>
      <TableCell>{stats?.tcmsCompleted}</TableCell>
      <TableCell>{stats?.rpmsCompleted}</TableCell>
      <TableCell>{stats?.invoicedTime}</TableCell>
      <TableCell>{utilizationChip}</TableCell>
    </TableRow>
  );
};
export const PerformanceDashboardTable: React.FC<PerformanceDashboardTableProps> = ({ performanceData, tableType }) => {
  const [dailyPerformance, setDailyPerformance] = React.useState<PerformanceData | null>(null);
  const [weeklyPerformance, setWeeklyPerformance] = React.useState<PerformanceData | null>(null);
  const [monthlyPerformance, setMonthlyPerformance] = React.useState<PerformanceData | null>(null);

  const getUtilizationRateChip = useCallback((value: number) => {
    if (!value) {
      return <span>-</span>;
    }
    let color: 'success' | 'error' | 'warning' = 'success';
    if (value < 0.8) {
      color = 'error';
    } else if (value < 0.9) {
      color = 'warning';
    }

    return <Chip variant="filled" color={color} label={value} />;
  }, []);

  const getUtilizationEmoji = useCallback((value: number) => {
    if (!value) {
      return <span>-</span>;
    }
    if (value < 0.8) {
      return <SentimentDissatisfiedOutlinedIcon color="error" />;
    } else if (value < 0.9) {
      return <SentimentNeutralOutlinedIcon color="warning" />;
    } else {
      return <SentimentSatisfiedOutlinedIcon color="success" />;
    }
  }, []);

  useEffect(() => {
    if (performanceData) {
      const dailyPerformanceData: PerformanceData = {
        label: performanceData?.getAdminPerformances?.dailyLabel ?? '',
        stats: {
          tipsCompleted: performanceData?.getAdminPerformances?.tipsCompleted?.dailyValue ?? 0,
          cmrsCompleted: performanceData?.getAdminPerformances?.cmrsCompleted?.dailyValue ?? 0,
          encounters: performanceData?.getAdminPerformances?.encounters?.dailyValue ?? 0,
          tcmsCompleted: performanceData?.getAdminPerformances?.tcmsCompleted?.dailyValue ?? 0,
          rpmsCompleted: performanceData?.getAdminPerformances?.rpmsCompleted?.dailyValue ?? 0,
          invoicedTime: performanceData?.getAdminPerformances?.invoicedTime?.dailyValue ?? '00:00',
          utilizationRate: performanceData?.getAdminPerformances?.utilizationRate?.dailyValue
        }
      };

      const weeklyPerformanceData: PerformanceData = {
        label: performanceData?.getAdminPerformances?.weeklyLabel ?? '',
        stats: {
          tipsCompleted: performanceData?.getAdminPerformances?.tipsCompleted?.weeklyValue ?? 0,
          cmrsCompleted: performanceData?.getAdminPerformances?.cmrsCompleted?.weeklyValue ?? 0,
          encounters: performanceData?.getAdminPerformances?.encounters?.weeklyValue ?? 0,
          tcmsCompleted: performanceData?.getAdminPerformances?.tcmsCompleted?.weeklyValue ?? 0,
          rpmsCompleted: performanceData?.getAdminPerformances?.rpmsCompleted?.weeklyValue ?? 0,
          invoicedTime: performanceData?.getAdminPerformances?.invoicedTime?.weeklyValue ?? '00:00',
          utilizationRate: performanceData?.getAdminPerformances?.utilizationRate?.weeklyValue
        }
      };

      const monthlyPerformanceData: PerformanceData = {
        label: performanceData?.getAdminPerformances?.monthlyLabel ?? '',
        stats: {
          tipsCompleted: performanceData?.getAdminPerformances?.tipsCompleted?.monthlyValue ?? 0,
          cmrsCompleted: performanceData?.getAdminPerformances?.cmrsCompleted?.monthlyValue ?? 0,
          encounters: performanceData?.getAdminPerformances?.encounters?.monthlyValue ?? 0,
          tcmsCompleted: performanceData?.getAdminPerformances?.tcmsCompleted?.monthlyValue ?? 0,
          rpmsCompleted: performanceData?.getAdminPerformances?.rpmsCompleted?.monthlyValue ?? 0,
          invoicedTime: performanceData?.getAdminPerformances?.invoicedTime?.monthlyValue ?? '00:00',
          utilizationRate: performanceData?.getAdminPerformances?.utilizationRate?.monthlyValue
        }
      };

      setDailyPerformance(dailyPerformanceData);
      setWeeklyPerformance(weeklyPerformanceData);
      setMonthlyPerformance(monthlyPerformanceData);
    }
  }, [performanceData]);

  return (
    <TableContainer component={Paper} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Table
        sx={{
          '& th': {
            textAlign: 'center'
          },
          '& td': {
            textAlign: 'center'
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>TIPs Completed</Box>
            </TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>CMRs Completed</Box>
            </TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>Encounters</Box>
            </TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>TCMs Completed</Box>
            </TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>RPMs Completed</Box>
            </TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>Invoiced Time</Box>
            </TableCell>
            <TableCell>
              <Box sx={{ textTransform: 'capitalize' }}>
                {tableType === PerformanceTableType.SELF ? 'Your Utilization Rate' : 'Utilization Rate'}
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dailyPerformance === null && weeklyPerformance === null && monthlyPerformance === null ? (
            <TableRow>
              <TableCell
                sx={{
                  padding: 3
                }}
                colSpan={8}
              >
                <H4>No Performance Details</H4>
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {dailyPerformance && (
            <PerformanceTableRow
              typeLabel={'Daily'}
              utilizationChip={
                tableType === PerformanceTableType.SELF
                  ? getUtilizationEmoji(dailyPerformance.stats?.utilizationRate)
                  : getUtilizationRateChip(dailyPerformance.stats?.utilizationRate)
              }
              {...dailyPerformance}
            />
          )}
          {weeklyPerformance && (
            <PerformanceTableRow
              typeLabel={'Weekly'}
              utilizationChip={
                tableType === PerformanceTableType.SELF
                  ? getUtilizationEmoji(weeklyPerformance.stats?.utilizationRate)
                  : getUtilizationRateChip(weeklyPerformance.stats?.utilizationRate)
              }
              {...weeklyPerformance}
            />
          )}
          {monthlyPerformance && (
            <PerformanceTableRow
              typeLabel={'Monthly'}
              utilizationChip={
                tableType === PerformanceTableType.SELF
                  ? getUtilizationEmoji(monthlyPerformance.stats?.utilizationRate)
                  : getUtilizationRateChip(monthlyPerformance.stats?.utilizationRate)
              }
              {...monthlyPerformance}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PerformanceDashboardTable;
