import Alert from '@/components/Alert';
import { color } from '@/styles/assets/colors';
import { Box, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  message: string;
  value: string | null;
  onCancel: () => void;
  onSetPin: (pinValue: string) => void;
};

const PinDialog = ({ title, message, value, onSetPin, onCancel, hideDialog, ...props }: Props) => {
  const [pin, setPin] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleSetPin = () => {
    onSetPin(pin);
  };

  const handleTextField = e => {
    const { value } = e.target;

    if (!isNaN(value)) return setPin(value);
  };

  const handleEditMode = () => {
    setIsEditMode(true);
    setPin('');
  };

  useEffect(() => {
    if (!value) return setIsEditMode(true);
    setPin(value);
    return setIsEditMode(false);
  }, [value, setPin, setIsEditMode]);

  return (
    <BaseDialog title={title} hideDialog={onCancel} fullWidth maxWidth="xs" {...props}>
      <DialogContent
        sx={{
          overflowY: 'hidden'
        }}
      >
        <DialogContentText sx={{ py: 1 }}>{parse(message)}</DialogContentText>

        <TextField
          type={isEditMode ? 'text' : 'password'}
          label="PIN"
          size="small"
          value={pin}
          onChange={handleTextField}
          autoFocus
          disabled={!isEditMode}
          sx={{ width: '100%', '& .MuiFormHelperText-root': { color: color.truentitySalmon[800] } }}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            minLength: 4,
            maxLength: 4,
            style: { appearance: 'textfield' }
          }}
        />
        <Box mt={1} mb={0.5}>
          <Alert
            status="warning"
            description="It is important that the patient notes down this 4-digit PIN in a safe place. This PIN can not be made visible here. If this PIN is lost, then set a new PIN."
          />
        </Box>

        <DialogActions sx={{ px: 0 }}>
          {isEditMode ? (
            <Button onClick={handleSetPin} autoFocus disabled={pin.length !== 4}>
              Set Pin
            </Button>
          ) : (
            <Button onClick={handleEditMode} autoFocus disabled={isEditMode}>
              Edit
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default PinDialog;
