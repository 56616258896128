import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

const RpmSection = () => {
  return (
    <Stack sx={{ maxHeight: '100vh', height: 'auto', display: 'flex', overflow: 'auto' }}>
      <Outlet />
    </Stack>
  );
};

export default RpmSection;
