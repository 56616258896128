import DropdownMenu from '@/components/DropdownMenu';
import type { MenuItemData } from 'mui-nested-menu';
import { useEffect, useState } from 'react';

export const COLUMN_TITLES = {
  InProgess: 'In Progress',
  PaymentPending: 'Payment Pending'
};

const TaskActions = ({ selectedTasks, updateValidationStatus }) => {
  const [menuData, setMenuData] = useState<MenuItemData>({ label: 'Actions', items: [] });

  const medicationStatusActions: MenuItemData[] = Object.keys(COLUMN_TITLES).map((key, index) => {
    const action: MenuItemData = {
      label: `${COLUMN_TITLES[key]}`,
      callback: () => updateValidationStatus(key)
    };

    return action;
  });

  useEffect(() => {
    const menuItems = [
      {
        label: 'Validation Status',
        items: medicationStatusActions
      }
    ];

    setMenuData({
      label: 'Actions',
      items: menuItems
    });
  }, [selectedTasks]);

  return (
    <DropdownMenu
      menuItemsData={menuData}
      MenuProps={{
        elevation: 3,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' }
      }}
      ButtonProps={{ variant: 'outlined', color: 'primary', sx: { minWidth: '200px', float: 'right' } }}
    />
  );
};

export default TaskActions;
