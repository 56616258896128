import AutoSave from '@/components/AutoSave';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import SelectList from '@/components/SelectList';
import PatientDetailContext from '@/context/patientDetailContext';
import { CREATE_ACCOUNT_MEDICAL_SERVICES, GET_ACCOUNT_MEDICAL_SERVICES } from '@/graphql/account';
import type { GetClientOrganizationResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import { theme } from '@/styles/mui-theme';
import type { ClientOrganizationObject, ClientStoreObject, GetAccountMedicalServicesResponse } from '@/types/administration';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Checkbox, FormControl, FormControlLabel, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

type FormValues = {
  clientOrganizationId: string;
  clientStoreId: string;

  mapValue: boolean;
  mtmValue: boolean;
  rpmValue: boolean;
  tcmValue: boolean;
  msValue: boolean;
  bloodGlucoseValue: boolean;
  bloodPressureValue: boolean;
  weightValue: boolean;
};

const defaultValues: FormValues = {
  clientOrganizationId: '',
  clientStoreId: '',

  mapValue: false,
  mtmValue: false,
  rpmValue: false,
  tcmValue: false,
  msValue: false,
  bloodGlucoseValue: false,
  bloodPressureValue: false,
  weightValue: false
};

const MedicalServices = ({ isReadOnly }: { isReadOnly: boolean }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: truentityId } = useParams();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);

  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const [selectedClientStoreId, setSelectedClientStoreId] = useState<string>('');

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [getAccountMedicalServices, { data: accountMedicalServices }] = useLazyQuery<GetAccountMedicalServicesResponse>(
    GET_ACCOUNT_MEDICAL_SERVICES,
    { fetchPolicy: 'network-only' }
  );
  const [createAccountMedicalService] = useMutation(CREATE_ACCOUNT_MEDICAL_SERVICES);

  const organizations = organizationData?.clientOrganizations?.clientOrganizations;

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, reset, watch, setValue } = methods;

  const isBloodPressureValueChange = watch('bloodPressureValue');
  const isBloodGlucoseValueChange = watch('bloodGlucoseValue');
  const isWeightValueChange = watch('weightValue');
  const isRpmValueChange = watch('rpmValue');

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const resetAccountMedicalServices = useCallback(() => {
    if (accountMedicalServices) {
      const service = accountMedicalServices?.getAccountMedicalServices;

      const accountMedicalService: FormValues = {
        mapValue: service.map || false,
        mtmValue: service.mtm || false,
        rpmValue: service.rpm || false,
        tcmValue: service.tcm || false,
        msValue: service.ms || false,
        bloodGlucoseValue: service.bloodGlucose || false,
        bloodPressureValue: service.bloodPressure || false,
        weightValue: service.weight || false,

        clientOrganizationId: selectedOrgId || '',
        clientStoreId: selectedClientStoreId || ''
      };

      reset(accountMedicalService);
    }
  }, [reset, accountMedicalServices, selectedOrgId, selectedClientStoreId]);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      await createAccountMedicalService({
        variables: {
          truentityId: truentityId,
          clientOrgId: values.clientOrganizationId,
          clientStoreId: values.clientStoreId,
          medicalServiceOptions: {
            mtmEnable: values.mtmValue,
            tcmEnable: values.tcmValue,
            rpmEnable: values.rpmValue,
            mapEnable: values.mapValue,
            msEnable: values.msValue,
            bloodPressureEnable: values.bloodPressureValue,
            bloodGlucoseEnable: values.bloodGlucoseValue,
            weightEnable: values.weightValue
          }
        }
      });
      enqueueSnackbar('Successfully updated medical service', { variant: 'success' });
      setReloadPatientInfo(true);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (selectedOrgId && selectedClientStoreId) {
      getAccountMedicalServices({
        variables: {
          truentityId: truentityId,
          clientOrgId: selectedOrgId,
          clientStoreId: selectedClientStoreId
        }
      }).catch(() => enqueueSnackbar('Unable to get the account medical services', { variant: 'error' }));
    }
  }, [selectedClientStoreId, selectedOrgId, truentityId, getAccountMedicalServices]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData && organizations.length > 0) {
      setClientOrganizations(organizations);

      const defaultOrgId = organizations[organizations.length - 1].id;
      const patientOrgId = patientInfo?.clientOrgs?.[0]?.id;
      const selectedOrgId = organizations.find(org => org.id === patientOrgId) ? patientOrgId : defaultOrgId;

      setSelectedOrgId(selectedOrgId);
    }
  }, [organizationDataLoading, patientInfo, organizationData, organizationDataCalled, organizations]);

  useEffect(() => {
    const org = clientOrganizations.find(o => o.id === selectedOrgId);
    if (org && org.clientStores && org.clientStores.length > 0) {
      setFilteredClientStores(org.clientStores);

      const defaultStoreId = org.clientStores?.[0]?.id ?? 'All';
      const patientStoreId = patientInfo?.clientStores?.[0]?.id;
      const isSelectedStoreAvailable = patientStoreId && org.clientStores.some(store => store.id === patientStoreId);
      const selectedStoreId = isSelectedStoreAvailable ? patientStoreId : defaultStoreId;

      setSelectedClientStoreId(selectedStoreId);
    }
  }, [clientOrganizations, patientInfo, selectedOrgId]);

  useEffect(() => {
    if (isBloodPressureValueChange || isBloodGlucoseValueChange || isWeightValueChange) {
      setValue('rpmValue', true);
    }
  }, [isBloodPressureValueChange, isBloodGlucoseValueChange, isWeightValueChange]);

  useEffect(() => {
    if (!isRpmValueChange) {
      setValue('bloodGlucoseValue', false);
      setValue('bloodPressureValue', false);
      setValue('weightValue', false);
    }
  }, [isRpmValueChange]);

  useEffect(() => {
    resetAccountMedicalServices();
  }, [resetAccountMedicalServices]);

  return (
    <Stack sx={{ background: theme.palette.background.default }}>
      <FormProvider {...methods}>
        <Stack component="form">
          <Stack style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}>
            <Stack direction="row" justifyContent={'space-between'}>
              <Controller
                control={control}
                name="clientOrganizationId"
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ width: '100%', marginRight: '10px' }} variant="outlined" margin="dense" size="medium">
                    <SelectList
                      label="Organization"
                      options={clientOrganizations.map((item: ClientOrganizationObject) => ({
                        value: item.id!,
                        label: item.name!
                      }))}
                      fullWidth={true}
                      placeholder="Select an option..."
                      value={value || ''}
                      onChange={e => {
                        onChange(e);
                        setSelectedOrgId(e.target.value as string);
                      }}
                      disabled={isReadOnly}
                    />
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="clientStoreId"
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ width: '100%' }} variant="outlined" margin="dense" size="medium">
                    <SelectList
                      disabled={filteredClientStores.length === 0 || isReadOnly}
                      label="Store"
                      options={filteredClientStores.map((item: ClientStoreObject) => ({
                        value: item.id!,
                        label: item.name!
                      }))}
                      fullWidth={true}
                      placeholder="Select an option..."
                      value={value || ''}
                      onChange={e => {
                        onChange(e);
                        setSelectedClientStoreId(e.target.value as string);
                      }}
                    />
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>

          <Stack direction={'row'} justifyContent={'space-evenly'}>
            <Stack sx={{ width: '50%', marginRight: 1 }}>
              <Controller
                control={control}
                name="mapValue"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label="Medication Adherence Packaging"
                    disabled={isReadOnly}
                  />
                )}
              />

              <Controller
                control={control}
                name="mtmValue"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label="Medication Therapy Management"
                    disabled={isReadOnly}
                  />
                )}
              />

              <Controller
                control={control}
                name="rpmValue"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label="Remote Physiological Monitoring"
                    disabled={isReadOnly}
                  />
                )}
              />
              <Stack sx={{ marginLeft: 4 }}>
                <Controller
                  control={control}
                  name="bloodGlucoseValue"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} />}
                      label="Blood Glucose"
                      disabled={isReadOnly}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="bloodPressureValue"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} />}
                      label="Blood Pressure"
                      disabled={isReadOnly}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="weightValue"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="Weight" disabled={isReadOnly} />
                  )}
                />
              </Stack>
            </Stack>

            <Stack sx={{ width: '50%', marginRight: 1 }}>
              <Controller
                control={control}
                name="tcmValue"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label="Transitional Care Management"
                    disabled={isReadOnly}
                  />
                )}
              />

              <Controller
                control={control}
                name="msValue"
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    control={<Checkbox checked={value} onChange={onChange} />}
                    label="Medication Synchronization"
                    disabled={isReadOnly}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
        <AutoSave defaultValues={defaultValues} onSubmit={onSubmit} />
      </FormProvider>
    </Stack>
  );
};

export default MedicalServices;
