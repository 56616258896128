import Button from '@/components/Button';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { ADD_RPM_ACTIVITY } from '@/graphql/remotePatientMonitoring';
import { CareActivityTypes, VitalsHealthTypes } from '@/types/remotePatientMonitoring';
import { today } from '@/util/date';
import { formatDateTimeIgnoreTZ, minutesToSeconds } from '@/util/format';
import { useMutation } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type RpmReadingReviewDialogProps = BaseDialogProps & {
  hideDialog: () => void;
  vitalsHealthType: VitalsHealthTypes;
  fullName: string;
};

type FormValues = {
  id: string;
  date: Date;
  time: number;
};

const defaultValues: FormValues = {
  id: '',
  date: today().toDate(),
  time: 3
};
export const RpmReadingsReviewDialog: React.FC<RpmReadingReviewDialogProps> = ({
  fullName,
  hideDialog,
  vitalsHealthType,
  title,
  ...props
}) => {
  const {
    control,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const date = watch('date');
  const time = watch('time');
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [addRpmActivity] = useMutation(ADD_RPM_ACTIVITY);

  const renderText = useCallback(() => {
    switch (vitalsHealthType) {
      case VitalsHealthTypes.HeartRate:
        return 'Heart Rate';
      case VitalsHealthTypes.BloodPressure:
        return 'Blood Pressure';
      case VitalsHealthTypes.BloodGlucose:
        return 'Blood Glucose';
      default:
        return '';
    }
  }, [vitalsHealthType]);

  const addRpmActivityMutation = async (inputValues: any) => {
    try {
      const response = await addRpmActivity({
        variables: { truentityId: id, ...inputValues }
      });

      const data = response.data!.addRpmActivity;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, { variant });
      hideDialog();
    } catch (error) {
      enqueueSnackbar('Unable to add activity', { variant: 'error' });
      hideDialog();
    }
  };

  const saveReviewLog = async (values: FormValues): Promise<void> => {
    try {
      const time = Number(values.time);
      if (isNaN(time)) {
        enqueueSnackbar('Please enter a valid number for time.', { variant: 'error' });
        return;
      }

      const healthTypeText = renderText();
      const inputValues = {
        title: `Readings Review: ${healthTypeText} Reviewed`,
        category: CareActivityTypes.MONTHLY_CARE_MANAGEMENT,
        notes: '',
        type: 'RPM',
        startTime: formatDateTimeIgnoreTZ(values.date, 'YYYY-MM-DD HH:mm:ss'),
        timeTaken: minutesToSeconds(values.time),
        isEncounter: false
      };

      await addRpmActivityMutation(inputValues);
      hideDialog();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to save review log.', { variant: 'error' });
    }
  };
  const onSubmit: SubmitHandler<FormValues> = data => saveReviewLog(data);

  const validInputs = useCallback(() => {
    const { date, time } = getValues();
    return Boolean(date && time && !errors.date && !errors.time);
  }, [date, time]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}
              component={'form'}
              onSubmit={handleSubmit(onSubmit)}
            >
              <span style={{ fontSize: '20px', fontWeight: 300, marginBottom: 13 }}>
                {renderText()} readings for {fullName} were reviewed on
              </span>
              <Controller
                control={control}
                name="date"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker
                    fullWidth={false}
                    TextFieldProps={{ required: true, size: 'small' }}
                    onChange={onChange}
                    value={value ?? ''}
                    label={'Reviewed Date'}
                  />
                )}
              />
              <span style={{ fontSize: '18px', fontWeight: 300, margin: 6 }}>&nbsp;for&nbsp;</span>
              <Controller
                control={control}
                name="time"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField size={'small'} fullWidth={false} onChange={onChange} value={value ?? ''} label={'Reviewed Time'} />
                )}
              />
              <span style={{ fontSize: '18px', fontWeight: 300, margin: 6 }}>&nbsp;minutes.</span>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'flex-end', padding: '30px 0 0 0' }}>
              <Button disabled={!validInputs()} onClick={handleSubmit(onSubmit)} type="submit" a11yLabel="Save" appearance="primary" />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default RpmReadingsReviewDialog;
