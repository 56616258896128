import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import type { TabContent } from '@/components/MuiTabs';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, H1 } from '@/components/Typography';
import type {
  GetRpmReportsCommunicationsResponse,
  GetRpmReportsCountsResponse,
  UpdateRpmReportsStatusesResponse,
  UpdateRpmReportsStatusesVariables
} from '@/graphql/remotePatientMonitoring';
import { GET_RPM_REPORTS_COMMUNICATIONS, GET_RPM_REPORTS_COUNTS, UPDATE_RPM_REPORTS_STATUSES } from '@/graphql/remotePatientMonitoring';
import type { GetDocumentPreviewResponse } from '@/graphql/rpmWorkflow';
import { GET_DOCUMENT_PREVIEW } from '@/graphql/rpmWorkflow';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { CustomTimezone } from '@/types/date';
import type { ReviewProviderType, RpmReportsCommunicationType, RpmReportsType } from '@/types/remotePatientMonitoring';
import {
  ActivityLogType,
  ContactModeType,
  ReportCommunicationActivityLogTypes,
  RpmReportStatusTypeEnum,
  UpdateRpmReportStatusTypeEnum
} from '@/types/remotePatientMonitoring';
import { RequestStatus } from '@/types/utils';
import { getAccountUserFullName } from '@/util/account';
import { getGeneralTimezone, toDate, tomorrow } from '@/util/date';
import { formatDate, formatDateToTimezone } from '@/util/format';
import { getCustomRowHeight } from '@/util/get';
import { fetchDocumentUrl, getProviderFullName } from '@/util/rpm';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PendingIcon from '@mui/icons-material/Pending';
import QueueIcon from '@mui/icons-material/Queue';
import RemoveIcon from '@mui/icons-material/Remove';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo';
import { Alert, AlertTitle, Box, Chip, CircularProgress, IconButton, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridRowId, GridSortModel } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PdfDialog from '../../components/Dialogs/PdfDialog';

const defaultReportsCommunicationTabs: TabContent[] = [
  {
    key: RpmReportStatusTypeEnum.READY_FOR_SENDING,
    label: 'READY',
    icon: <QueueIcon />
  },
  {
    key: RpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING,
    label: 'SCHEDULED',
    icon: <CalendarTodayIcon />
  },
  {
    key: RpmReportStatusTypeEnum.SENT,
    label: 'SENT',
    icon: <SendIcon />
  }
];

const columnVisibility: Record<RpmReportStatusTypeEnum, Record<string, boolean>> = {
  READY_FOR_SENDING: {
    patientName: true,
    reportMonthYear: true,
    providers: true,
    providerContacts: true,
    submittedOn: true,
    scheduledOn: false,
    dailySendStatus: false,
    sentOn: false,
    failedOn: false,
    previewReport: true
  },
  SCHEDULED_FOR_SENDING: {
    patientName: true,
    reportMonthYear: true,
    providers: true,
    providerContacts: true,
    submittedOn: false,
    scheduledOn: true,
    dailySendStatus: false,
    sentOn: false,
    failedOn: false,
    previewReport: true
  },
  SENT: {
    patientName: true,
    reportMonthYear: true,
    providers: true,
    providerContacts: true,
    submittedOn: false,
    scheduledOn: false,
    dailySendStatus: false,
    sentOn: true,
    failedOn: false,
    previewReport: true
  },
  FAILED: {
    patientName: true,
    reportMonthYear: true,
    providers: true,
    providerContacts: true,
    submittedOn: false,
    scheduledOn: false,
    dailySendStatus: true,
    sentOn: false,
    failedOn: true,
    previewReport: true
  }
};

export const RpmReportsCommunications: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { roleType } = useToken();

  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);
  const scheduledUtcDate = useMemo(() => {
    return toDate(`${formatDate(tomorrow())} 07:00:00 UTC`);
  }, []);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility.SCHEDULED_FOR_SENDING);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [selectedReportS3Key, setSelectedReportS3Key] = useState<string>('');
  const [logsFilterOptions, setLogsFilterOptions] = useState<{ typeFilters?: string[]; rpmReportId?: string }>({});
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [selectedOption, setSelectedOption] = useState<UpdateRpmReportStatusTypeEnum | null>(null);
  const [reportsCommunicationTabs, setReportsCommunicationTabs] = useState<TabContent[]>(defaultReportsCommunicationTabs);
  const [getDocumentPreview, { loading: getDocumentPreviewLoading }] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW);

  const activeTabKey = useMemo(() => reportsCommunicationTabs[activeTab].key, [activeTab, reportsCommunicationTabs]);

  const {
    data: reportsCommunicationsData,
    loading: reportsCommunicationsLoading,
    refetch: refetchReportsCommunications,
    error: reportsCommunicationsError
  } = useQuery<GetRpmReportsCommunicationsResponse>(GET_RPM_REPORTS_COMMUNICATIONS, {
    variables: {
      rpmReportStatusType: activeTabKey,
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network',
    skip: !activeTabKey
  });

  const { data: reportsCounts, error: reportsCountsError } = useQuery<GetRpmReportsCountsResponse>(GET_RPM_REPORTS_COUNTS, {
    fetchPolicy: 'cache-and-network'
  });

  const [updateRpmReportsStatuses, { loading: updateRpmReportsStatusesLoading }] = useMutation<
    UpdateRpmReportsStatusesResponse,
    UpdateRpmReportsStatusesVariables
  >(UPDATE_RPM_REPORTS_STATUSES);

  const renderContactStatus = useCallback(
    (contactStatus?: string) => {
      if (isSuperAdmin) {
        if (contactStatus === RequestStatus.SUCCESS) {
          return (
            <Tooltip title={'Success'} placement="top">
              <CheckCircleIcon fontSize="small" color="success" />
            </Tooltip>
          );
        }
        if (contactStatus === RequestStatus.FAILURE) {
          return (
            <Tooltip title={'Failed'} placement="top">
              <CancelIcon fontSize="small" color="error" />
            </Tooltip>
          );
        }
        if (contactStatus === RequestStatus.PENDING) {
          return (
            <Tooltip title={'Pending'} placement="top">
              <PendingIcon fontSize="small" color="info" />
            </Tooltip>
          );
        }
      }
      return <></>;
    },
    [isSuperAdmin]
  );

  const renderProviderContacts = useCallback(
    (reviewProvider: ReviewProviderType, report: RpmReportsType) => {
      return (
        <Stack
          flex={1}
          flexDirection="column"
          flexWrap={'nowrap'}
          key={`${reviewProvider?.provider?.id}`}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          width={'100%'}
        >
          {reviewProvider?.reportSendingContacts?.length === 0
            ? 'N/A'
            : reviewProvider?.reportSendingContacts?.map((contact, index) => (
                <Box key={`${activeTabKey}-${report?.id}-${reviewProvider?.provider?.id}-${index}`}>
                  {contact ? (
                    <>
                      {contact.type === ContactModeType.FAX || contact.type === ContactModeType.TELEPHONE ? (
                        <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
                          <Body1>{`${contact.type}:`}&nbsp;</Body1>
                          <TruentityPhoneNumber value={contact.value} textColor={'inherit'} />
                          &nbsp;
                          {renderContactStatus(contact.status)}
                        </Stack>
                      ) : (
                        <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
                          <Body1>{`${contact.type}:`}&nbsp;</Body1>
                          <Body1>{contact.value}&nbsp;</Body1>
                          {renderContactStatus(contact.status)}
                        </Stack>
                      )}
                    </>
                  ) : (
                    <span>N/A</span>
                  )}
                </Box>
              ))}
        </Stack>
      );
    },
    [activeTabKey, renderContactStatus]
  );

  const handleRpmReportPreview = useCallback(
    async (rpmReportS3Key: string) => {
      try {
        setSelectedReportS3Key(rpmReportS3Key);
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: rpmReportS3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          const previewUrl = await fetchDocumentUrl(documentResponse?.data?.documentPreview?.documentUrl);
          if (previewUrl) {
            const modal = showModal(PdfDialog, {
              title: 'Rpm Report Preview',
              pdfUrl: previewUrl,
              hideDialog: () => {
                modal.hide();
              }
            });
          } else {
            enqueueSnackbar('Failed to load document preview', { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        setSelectedReportS3Key('');
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [getDocumentPreview, enqueueSnackbar]
  );

  const handleRpmReportRowClick = useCallback((reportCommunication: RpmReportsCommunicationType) => {
    setLogsFilterOptions({
      typeFilters: Object.values(ReportCommunicationActivityLogTypes),
      rpmReportId: reportCommunication?.rpmReport?.id
    });
    setIsActivityDrawerOpen(true);
  }, []);

  const columns: GridColDef<RpmReportsCommunicationType>[] = useMemo(() => {
    return [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        flex: 2,
        minWidth: 135,
        sortable: true,
        valueGetter: params => getAccountUserFullName(params.row.rpmReport?.account?.user),
        renderCell: params => {
          return <Link to={`/patients/${params.row.rpmReport?.account?.truentityId}/details/rpm/reports`}>{params.value}</Link>;
        }
      },
      {
        field: 'reportMonthYear',
        headerName: 'Report Month/Year',
        sortable: true,
        flex: 2,
        minWidth: 110,
        valueGetter: params => {
          return params.row.rpmReport?.monthYear;
        }
      },
      {
        field: 'providers',
        headerName: 'Providers',
        flex: 2,
        minWidth: 150,
        renderCell: (params: any) => {
          return (
            <Stack marginY={1} direction="column" flexDirection="column" justifyContent="flex-start" alignItems="stretch" flexWrap="nowrap">
              {(params?.value?.length ?? 0) > 0
                ? params.value.map((reviewProvider: ReviewProviderType) => (
                    <Box
                      width="100%"
                      key={reviewProvider?.provider?.id}
                      textTransform="none"
                      sx={{
                        textOverflow: 'ellipsis',
                        height: getCustomRowHeight(reviewProvider?.reportSendingContacts?.length ?? 0)
                      }}
                    >
                      {getProviderFullName(reviewProvider?.provider)}
                    </Box>
                  ))
                : 'N/A'}
            </Stack>
          );
        },
        valueGetter: params => {
          return params.row.rpmReport?.reviewProviders;
        }
      },
      {
        field: 'providerContacts',
        headerName: 'Provider Details',
        flex: 3,
        minWidth: 300,
        renderCell: (params: any) => {
          return (
            <Stack marginY={1} direction="column" flexDirection="column" justifyContent="flex-start" alignItems="stretch" flexWrap="nowrap">
              {(params?.value?.length ?? 0) > 0
                ? params.value.map((reviewProvider: ReviewProviderType) => renderProviderContacts(reviewProvider, params.row.rpmReport))
                : 'N/A'}
            </Stack>
          );
        },
        valueGetter: params => {
          return params.row.rpmReport?.reviewProviders;
        }
      },
      {
        field: 'submittedOn',
        headerName: 'Prepared On',
        sortable: true,
        flex: 2,
        minWidth: 110,
        valueGetter: params => {
          return params.row.rpmReport?.statusUpdatedAt ?? null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'scheduledOn',
        headerName: 'Scheduled On',
        sortable: true,
        flex: 2,
        minWidth: 110,
        valueGetter: params => {
          return params.row.rpmReport?.statusUpdatedAt ?? null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'failedOn',
        headerName: 'Failed On',
        sortable: true,
        flex: 2,
        minWidth: 110,
        valueGetter: params => {
          return params.row.rpmReport?.statusUpdatedAt ?? null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'dailySendStatus',
        headerName: 'Status',
        sortable: true,
        flex: 2,
        minWidth: 150,
        valueGetter: params => {
          return params.row.rpmReport?.dailySendStatus?.replace(/_/g, ' ');
        },
        renderCell: params => (
          <Chip
            size={'small'}
            icon={
              <ErrorOutlineRounded
                sx={{
                  color: params.row.rpmReport?.dailySendStatus === RpmReportStatusTypeEnum.FAILED ? 'error' : 'warning'
                }}
              />
            }
            variant="outlined"
            label={params?.value}
            sx={{
              borderColor: params.row.rpmReport?.dailySendStatus === RpmReportStatusTypeEnum.FAILED ? 'error' : 'warning',
              color: params.row.rpmReport?.dailySendStatus === RpmReportStatusTypeEnum.FAILED ? 'error' : 'warning'
            }}
          />
        )
      },
      {
        field: 'sentOn',
        headerName: 'Sent On',
        flex: 2,
        minWidth: 110,
        valueGetter: params => {
          return params.row.rpmReport?.lastReportedOn ?? null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'previewReport',
        headerName: 'Report',
        sortable: false,
        flex: 1,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Tooltip title="View RPM Report">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRpmReportPreview(params.value);
                    }}
                    disabled={!params.value || (getDocumentPreviewLoading && selectedReportS3Key === params.value)}
                  >
                    <CircularProgress
                      size={20}
                      color="inherit"
                      thickness={5}
                      style={{ display: getDocumentPreviewLoading && selectedReportS3Key === params.value ? 'block' : 'none' }}
                    />
                    <ArticleIcon
                      style={{
                        display: getDocumentPreviewLoading && selectedReportS3Key === params.value ? 'none' : 'block'
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        },
        valueGetter: params => {
          return params.row.rpmReport?.reportedDocS3Key;
        }
      }
    ];
  }, [renderProviderContacts, handleRpmReportPreview, getDocumentPreviewLoading, selectedReportS3Key]);

  const handleUpdateRpmReports = useCallback(
    async ({ rpmReportIds, rpmDailySendStatus }: UpdateRpmReportsStatusesVariables) => {
      try {
        setSelectedOption(rpmDailySendStatus);
        const response = await updateRpmReportsStatuses({
          variables: {
            rpmReportIds,
            rpmDailySendStatus
          }
        });
        if (response?.data?.updateRpmReportsStatuses?.status === 'Success') {
          enqueueSnackbar(response?.data?.updateRpmReportsStatuses?.message, { variant: 'success' });
          refetchReportsCommunications();
        } else {
          enqueueSnackbar(response?.data?.updateRpmReportsStatuses?.message ?? 'Failed to update RPM Reports', { variant: 'error' });
        }
        setSelectedOption(null);
        setSelectionModel([]);
      } catch (error) {
        enqueueSnackbar('Failed to update RPM Reports', { variant: 'error' });
        setSelectedOption(null);
        setSelectionModel([]);
      }
    },
    [updateRpmReportsStatuses, enqueueSnackbar, refetchReportsCommunications]
  );

  const getDynamicConfirmationTitle = useCallback((rpmDailySendStatus: UpdateRpmReportStatusTypeEnum) => {
    if (rpmDailySendStatus === UpdateRpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING) {
      return 'Confirm Schedule RPM Reports';
    } else if (rpmDailySendStatus === UpdateRpmReportStatusTypeEnum.READY_FOR_SENDING) {
      return 'Confirm Unschedule RPM Reports';
    } else if (rpmDailySendStatus === UpdateRpmReportStatusTypeEnum.REMOVE) {
      return 'Confirm Remove RPM Reports';
    } else {
      return 'Invalid option selected for RPM Reports. Please try again.';
    }
  }, []);

  const getDynamicConfirmationMessage = useCallback((rpmDailySendStatus: UpdateRpmReportStatusTypeEnum) => {
    if (rpmDailySendStatus === UpdateRpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING) {
      return 'The selected reports will be scheduled for sending. Are you sure you want to proceed?';
    } else if (rpmDailySendStatus === UpdateRpmReportStatusTypeEnum.READY_FOR_SENDING) {
      return 'The selected reports will be marked as ready for sending. Are you sure you want to proceed?';
    } else if (rpmDailySendStatus === UpdateRpmReportStatusTypeEnum.REMOVE) {
      return 'The selected reports will be removed from ready for sending status. Are you sure you want to proceed?';
    } else {
      return 'Invalid submission of RPM Reports';
    }
  }, []);

  const handleConfirmationModal = useCallback(
    ({ rpmReportIds, rpmDailySendStatus }: UpdateRpmReportsStatusesVariables) => {
      const modal = showModal(ConfirmDialog, {
        title: getDynamicConfirmationTitle(rpmDailySendStatus),
        message: getDynamicConfirmationMessage(rpmDailySendStatus),
        onAgree: () => {
          handleUpdateRpmReports({ rpmReportIds, rpmDailySendStatus });
          modal.hide();
        },
        onDisagree: () => {
          modal.hide();
        },
        maxWidth: 'md'
      });
    },
    [handleUpdateRpmReports]
  );

  const getCustomRowId = useCallback(
    (row: RpmReportsCommunicationType): string => {
      return `${row.rpmReport?.id} ${activeTabKey}`;
    },
    [activeTabKey]
  );

  const handleTabChange = useCallback((_event: SyntheticEvent, selectedTabIndex: number): void => {
    setActiveTab(selectedTabIndex);
  }, []);

  const closeActivityDrawer = () => {
    setLogsFilterOptions({});
    setIsActivityDrawerOpen(false);
  };

  useEffect(() => {
    if (activeTabKey) {
      setColumnVisibilityModel(columnVisibility[activeTabKey ?? RpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING]);
      setCurrentPage(0);
      setSelectionModel([]);
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (reportsCommunicationsError) {
      enqueueSnackbar('Failed to retrieve reports communications activities', { variant: 'error' });
    }
  }, [reportsCommunicationsError]);

  useEffect(() => {
    if (reportsCountsError) {
      enqueueSnackbar('Failed to retrieve reports counts', { variant: 'error' });
    }
  }, [reportsCountsError]);

  useEffect(() => {
    if (reportsCounts) {
      if (reportsCounts?.getRpmReportsCounts?.failed > 0) {
        const newTabs = [
          ...defaultReportsCommunicationTabs,
          {
            key: RpmReportStatusTypeEnum.FAILED,
            label: 'FAILED',
            icon: <ErrorOutlineIcon />
          }
        ];
        setReportsCommunicationTabs(newTabs);
      } else {
        setReportsCommunicationTabs(defaultReportsCommunicationTabs);
      }
    }
  }, [reportsCounts]);

  return (
    <Stack spacing={2}>
      <Paper component={Stack} padding={2} elevation={0}>
        <H1>Reports Communication</H1>
      </Paper>
      <Paper component={Stack} padding={2} elevation={0}>
        <Box mb={3}>
          <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
            {reportsCommunicationTabs?.map(tab => (
              <Tab
                label={<h6 style={{ marginBottom: 0 }}>{tab.label}</h6>}
                key={tab?.key}
                id={`tab-${tab?.key}`}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
        </Box>
        <Stack
          sx={{
            width: '100%',
            height: 'auto'
          }}
          spacing={2}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Stack
            flexDirection={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
            sx={{
              width: '100%',
              height: 'auto',
              px: 2
            }}
          >
            {activeTabKey === RpmReportStatusTypeEnum.READY_FOR_SENDING && (
              <>
                <Button
                  sx={{
                    m: 1
                  }}
                  label={'Remove'}
                  startIcon={<RemoveIcon />}
                  disabled={selectionModel.length === 0}
                  isLoading={updateRpmReportsStatusesLoading && selectedOption === UpdateRpmReportStatusTypeEnum.REMOVE}
                  onClick={() => {
                    try {
                      handleConfirmationModal({
                        rpmReportIds: selectionModel.map(id => id.toString().split(' ')[0]),
                        rpmDailySendStatus: UpdateRpmReportStatusTypeEnum.REMOVE
                      });
                    } catch (error) {
                      setSelectionModel([]);
                      enqueueSnackbar('Could not remove RPM Reports statuses', { variant: 'error' });
                    }
                  }}
                />
                <Button
                  sx={{
                    m: 1
                  }}
                  label={'Schedule'}
                  startIcon={<CalendarTodayIcon />}
                  disabled={selectionModel.length === 0}
                  isLoading={updateRpmReportsStatusesLoading && selectedOption === UpdateRpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING}
                  onClick={() => {
                    try {
                      handleConfirmationModal({
                        rpmReportIds: selectionModel.map(id => id.toString().split(' ')[0]),
                        rpmDailySendStatus: UpdateRpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING
                      });
                    } catch (error) {
                      setSelectionModel([]);
                      enqueueSnackbar('Could not schedule RPM Reports', { variant: 'error' });
                    }
                  }}
                />
              </>
            )}
            {activeTabKey === RpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING && (
              <Button
                label={'Unschedule'}
                startIcon={<UndoIcon />}
                disabled={selectionModel.length === 0}
                isLoading={updateRpmReportsStatusesLoading && selectedOption === UpdateRpmReportStatusTypeEnum.READY_FOR_SENDING}
                onClick={() => {
                  try {
                    handleConfirmationModal({
                      rpmReportIds: selectionModel.map(id => id.toString().split(' ')[0]),
                      rpmDailySendStatus: UpdateRpmReportStatusTypeEnum.READY_FOR_SENDING
                    });
                  } catch (error) {
                    setSelectionModel([]);
                    enqueueSnackbar('Could not unschedule RPM Reports', { variant: 'error' });
                  }
                }}
              />
            )}
          </Stack>
          {activeTabKey === RpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING &&
            (reportsCommunicationsData?.getRpmReportCommunicationActivities?.rpmReportsCommunications?.length ?? 0) > 0 && (
              <Alert severity="info">
                <AlertTitle>Scheduled Reports</AlertTitle>
                {`These reports are scheduled to go out on ${formatDateToTimezone({
                  date: scheduledUtcDate,
                  format: 'MMM DD, YYYY - hh:mm A',
                  timezone: getGeneralTimezone(CustomTimezone.ADMIN_TIMEZONE)
                })} (Eastern Time).`}
              </Alert>
            )}
          <TruentityDataGrid
            name={`dg-reports-communications-${activeTabKey}`}
            autoHeight
            autoPageSize={false}
            getRowHeight={() => 'auto'}
            getRowId={getCustomRowId}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            sortModel={sortModel}
            onSortModelChange={newModel => {
              setSortModel(newModel);
            }}
            columnVisibilityModel={columnVisibilityModel}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            rows={reportsCommunicationsData?.getRpmReportCommunicationActivities?.rpmReportsCommunications ?? []}
            columns={columns}
            loading={reportsCommunicationsLoading}
            paginationMode="server"
            disableRowSelectionOnClick
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={newSelection => {
              setSelectionModel(newSelection);
            }}
            keepNonExistentRowsSelected
            isRowSelectable={() => true}
            rowCount={reportsCommunicationsData?.getRpmReportCommunicationActivities?.meta?.totalCount ?? 0}
            checkboxSelection={
              activeTabKey === RpmReportStatusTypeEnum.READY_FOR_SENDING || activeTabKey === RpmReportStatusTypeEnum.SCHEDULED_FOR_SENDING
            }
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox': {
                minWidth: 50
              }
            }}
            onCellClick={selectedCell => {
              if (selectedCell.field !== 'previewReport' && selectedCell.field !== '__check__') {
                handleRpmReportRowClick(selectedCell.row as RpmReportsCommunicationType);
              }
            }}
          />
        </Stack>
      </Paper>
      <ActivityDrawer
        isOpen={isActivityDrawerOpen}
        onClose={closeActivityDrawer}
        type={ActivityLogType.REPORT_COMMUNICATION_SUMMARY}
        filterOptions={logsFilterOptions}
      />
    </Stack>
  );
};

export default RpmReportsCommunications;
