import { gql } from '@apollo/client';

export const ADD_COMMENT = gql`
  mutation addCommentToAccount($comment: String!, $truentityId: String!) {
    addCommentToAccount(comment: $comment, truentityId: $truentityId) {
      comment {
        id
      }
    }
  }
`;

export const ADD_COMMENT_FOR_MEDICATION = gql`
  mutation addCommentToAccountMedication($comment: String!, $accountMedicationId: String!) {
    addCommentToAccountMedication(comment: $comment, accountMedicationId: $accountMedicationId) {
      comment {
        id
      }
    }
  }
`;
