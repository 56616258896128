import { H1 } from '@/components/Typography';
import config from '@/config';
import type { PatientsData } from '@/routes/Patients/patients';
import { stringAvatar } from '@/util/avatar';
import { unknown } from '@/util/string';
import { Avatar, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

type Props = {
  patientInfo: PatientsData;
  children?: ReactNode;
  gotoEditProfile: () => void;
};
const PatientName = ({ patientInfo, children, gotoEditProfile }: Props) => {
  const [fullName, setFullName] = useState(unknown());

  useEffect(() => {
    setFullName(
      patientInfo?.user?.lastName && patientInfo?.user?.firstName
        ? `${patientInfo?.user?.lastName}, ${patientInfo?.user?.firstName}`
        : unknown()
    );
  }, [patientInfo]);

  return (
    <Stack
      spacing={1}
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ border: config.DEBUG_LAYOUT ? '5px solid yellow' : undefined, flexWrap: 'nowrap' }}
    >
      <Stack
        sx={{ border: config.DEBUG_LAYOUT ? '5px solid grey' : undefined }}
        spacing={1}
        direction={{ xs: 'column', xl: 'row' }}
        alignItems={'center'}
      >
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
          <Tooltip title={'Profile'}>
            <Box>
              <Avatar
                {...stringAvatar(fullName)}
                onClick={gotoEditProfile}
                sx={{
                  backgroundColor: theme => theme.palette.grey[400],
                  width: 56,
                  height: 56,
                  fontSize: '1.225rem',
                  cursor: 'pointer'
                }}
              />
            </Box>
          </Tooltip>

          <H1 sx={{ border: config.DEBUG_LAYOUT ? '5px solid navy' : undefined }} textAlign={{ xs: 'center', lg: 'left' }}>
            {fullName}
          </H1>
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};

export default PatientName;
