import Button from '@/components/Button';
import MuiAccordion from '@/components/MuiAccordion';
import SelectList from '@/components/SelectList';
import TruentityTextField from '@/components/TruentityTextField';
import { Small } from '@/components/Typography';
import { FACILITY_TYPE_OPTIONS } from '@/types/dischargePatient';
import { formatDateIgnoreTZ } from '@/util/format';
import { Checkbox, FormControlLabel, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { StepperPage } from './StepperPage';
import type { TCMWorkflowStepProps } from './TCMWorkflow';

export const TCMWorkflowStep1 = ({ assignedTo, data, formData, setFormData, readOnly }: TCMWorkflowStepProps) => {
  const [pdfUrl] = useState('https://truentity-general.s3.amazonaws.com/Patient-89672230-613-Discharge-Summary.pdf');

  return (
    <StepperPage title={'Step 1: Review'} assignedTo={[assignedTo || '']}>
      <MuiAccordion
        options={[
          {
            label: 'Discharge Information',
            defaultExpand: true,
            content: (
              <Grid container spacing={4}>
                <Grid item>
                  <TruentityTextField
                    disabled={true}
                    label={'Discharged On'}
                    value={formatDateIgnoreTZ(data?.dischargeDate, 'MM/DD/YYYY')}
                  />

                  <TruentityTextField
                    label={'Discharged From'}
                    onChange={event => {
                      setFormData({
                        ...formData,
                        reviewDischargeInfoFrom: event.target.value
                      });
                    }}
                    value={formData.reviewDischargeInfoFrom}
                    disabled={readOnly}
                  />

                  <SelectList
                    keepOriginalValue
                    options={FACILITY_TYPE_OPTIONS}
                    placeholder="Select an option..."
                    label={'Type'}
                    onChange={event => {
                      setFormData({
                        ...formData,
                        reviewDischargeInfoType: event.target.value as string
                      });
                    }}
                    value={formData.reviewDischargeInfoType || 'Other'}
                    disabled={readOnly}
                  />

                  <TruentityTextField
                    label={'Discharging Physician'}
                    onChange={event => {
                      setFormData({
                        ...formData,
                        reviewDischargeInfoDischargingPhysician: event.target.value
                      });
                    }}
                    value={formData.reviewDischargeInfoDischargingPhysician}
                    disabled={readOnly}
                  />

                  <TruentityTextField
                    label={'Diagnosis at Discharge'}
                    value={formData?.admissionReason}
                    onChange={event => {
                      setFormData({
                        ...formData,
                        admissionReason: event.target.value
                      });
                    }}
                    disabled={readOnly}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        id={'reviewedDischargeInfo_checkbox_2'}
                        checked={formData.reviewDischargeInfoReviewed}
                        size="medium"
                        onChange={() => {
                          setFormData({
                            ...formData,
                            reviewDischargeInfoReviewed: !formData.reviewDischargeInfoReviewed
                          });
                        }}
                      />
                    }
                    name="setReviewedDischargeInfo"
                    label={<Small>I&apos;ve reviewed this Discharge Information</Small>}
                    disabled={readOnly}
                  />
                </Grid>
              </Grid>
            )
          },
          {
            label: 'Discharge Summary Document',
            defaultExpand: false,
            content: (
              <Grid container spacing={4}>
                <Grid item>
                  <Stack spacing={2} direction="row" justifyContent={'center'} alignItems={'center'}>
                    <Box
                      sx={{ width: 150 }}
                      component={'img'}
                      src={'https://truentity-general.s3.amazonaws.com/Patient-89672230-613-Discharge-Summary_Page_1.jpg'}
                    />
                    <Button label="View" onClick={() => window.open(pdfUrl, '_blank')} />
                  </Stack>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={readOnly}
                        checked={formData.reviewDischargeSummaryDocReviewed}
                        size="medium"
                        onChange={() => {
                          setFormData({
                            ...formData,
                            reviewDischargeSummaryDocReviewed: !formData.reviewDischargeSummaryDocReviewed
                          });
                        }}
                      />
                    }
                    label={<Small>I&apos;ve reviewed the Discharge Summary Document</Small>}
                  />
                </Grid>
              </Grid>
            )
          }
        ]}
      />
    </StepperPage>
  );
};
