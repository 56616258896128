export const pathnameIncludes: (path: string) => boolean = path => {
  return window.location.search.includes(path) || window.location.pathname.includes(path);
};

export const pathnameIncludesAny: (paths: string[]) => boolean = paths => {
  return paths.some(path => pathnameIncludes(path));
};

export const pathnameEndsWith: (path: string) => boolean = path => {
  return window.location.search.endsWith(path) || window.location.pathname.endsWith(path);
};

export const pathnameExcludes: (exclude: string) => boolean = exclude => {
  return !(window.location.search.includes(exclude) || window.location.pathname.includes(exclude));
};
