import UpdateAccountNoteDialog from '@/components/Dialogs/UpdateAccountNoteDialog';
import PinnedIcon from '@/components/PinnedIcon';
import { UPDATE_ACCOUNT_NOTE } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import type { AccountNoteType } from '@/types/remotePatientMonitoring';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import { useMutation } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import type { BoxProps } from '@mui/material';
import { IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import { useModal } from 'mui-modal-provider';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = BoxProps & {
  note: AccountNoteType;
  index: string;
  onUpdateNoteSuccess: () => void;
  readonly?: boolean;
  children?: ReactNode;
};
const NoteItem = ({ note, index, onUpdateNoteSuccess, readonly, children, ...props }: Props) => {
  const { id } = useParams();
  const { showModal } = useModal();
  const [data, setData] = useState(note);
  const [editable, setEditable] = useState(false);
  const textFieldStyle = editable ? {} : { backgroundColor: 'transparent', cursor: 'pointer' };
  const [updateAccountNote] = useMutation(UPDATE_ACCOUNT_NOTE);

  const showUpdateNewNoteDialog = (data: AccountNoteType) => {
    const modal = showModal(UpdateAccountNoteDialog, {
      title: 'Update Account Note',
      hideDialog: () => {
        modal.hide();
        onUpdateNoteSuccess();
      },
      id: id,
      data: data
    });
  };

  const updateAccountNoteData = (description: string) => {
    updateAccountNote({
      variables: {
        truentityId: id,
        noteId: data.id,
        modeOfCapture: data.modeOfCapture,
        description,
        shareWithProvider: data.shareWithProvider,
        pinToProfile: data.pinToProfile
      }
    })
      .then(() => onUpdateNoteSuccess())
      .catch(err => {
        console.error(err);
      });
  };
  const onFocus = () => {
    setEditable(true);
  };
  const onBlur = event => {
    setEditable(false);
    updateAccountNoteData(event.target.value);

    event.target.scrollTo(0, 0);
  };

  useEffect(() => {
    setData(note);
  }, [note]);

  useEffect(() => {
    if (data) {
      updateAccountNoteData(data.note);
    }
  }, [data]);

  return (
    <Stack
      direction={!readonly ? 'column' : 'row'}
      spacing={1}
      alignItems={'flex-start'}
      sx={{
        bgcolor: color.white,
        borderRadius: '8px',
        padding: theme.spacing(2),
        ...props.sx
      }}
      key={index}
    >
      {!readonly && (
        <>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={async e => {
                      setData({
                        ...data,
                        shareWithProvider: e.target.checked
                      });
                    }}
                    checked={data?.shareWithProvider}
                  />
                }
                label="Always Share with Provider"
              />
            </FormGroup>
            <IconButton
              color={'primary'}
              onClick={e => {
                e.preventDefault();
                showUpdateNewNoteDialog(data);
              }}
            >
              <EditIcon />
            </IconButton>
          </Stack>
          <Divider />
        </>
      )}

      <TextField
        label={editable ? '' : 'Click to Edit'}
        variant="standard"
        sx={{
          flexGrow: 1,
          m: 0.2,
          color: color.grey600
        }}
        multiline={true}
        minRows={2}
        maxRows={editable ? 4 : 2}
        onFocus={onFocus}
        onBlur={onBlur}
        value={data.note}
        onChange={event => setData({ ...data, note: event.target.value })}
        InputProps={{
          readOnly: !editable,
          disableUnderline: !editable,
          style: textFieldStyle
        }}
      />

      <Stack display="inline-flex" direction="row" alignItems="flex-end">
        {!readonly && (
          <Chip
            label={capitalizeLetterBeforeCharacterAddSpace(data.modeOfCapture ?? 'Not Added')}
            variant={'outlined'}
            sx={{ width: 'fit-content' }}
          />
        )}

        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ marginLeft: 'auto', height: '100%' }} spacing={1}>
          {children}
          <PinnedIcon
            size={'small'}
            pinned={data.pinToProfile}
            color={color.primaryMain}
            onClick={() => {
              setData({ ...data, pinToProfile: !data.pinToProfile });
            }}
            sx={{
              height: '100%'
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NoteItem;
