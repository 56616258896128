import { ReactiveVar } from '@apollo/client';

const getCleanValueForStorage = (value: unknown) => {
  return typeof value === 'string' ? value : JSON.stringify(value);
};

/** Persists ReactiveVar in browser session storage. NOTE:  DO NOT store sensitive information (i.e. auth tokens etc) here */
export const getVarPersisted = <T>(rv: ReactiveVar<T>, storageName: string) => {
  let value;

  // Try to fetch the value from local storage
  const previousValue = localStorage.getItem(storageName);

  if (previousValue !== null) {
    try {
      const parsed = JSON.parse(previousValue);
      value = parsed;
    } catch {
      value = previousValue as unknown as T;
    }
  }

  value && rv(value);

  const onNextChange = (newValue: T | undefined) => {
    try {
      if (newValue === undefined) {
        localStorage.removeItem(storageName);
      } else {
        localStorage.setItem(storageName, getCleanValueForStorage(newValue));
      }
    } catch (err) {
      console.log(err);
    }

    rv.onNextChange(onNextChange);
  };

  rv.onNextChange(onNextChange);

  return rv;
};
