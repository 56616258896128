import { GetAccountNotesByIdResponse, GET_ACCOUNT_NOTE_BY_ID, UPDATE_ACCOUNT_NOTE } from '@/graphql/remotePatientMonitoring';
import { AccountNoteType } from '@/types/remotePatientMonitoring';
import { modeOfCaptureNoteList } from '@/util/rpm';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from '../Button';
import SelectList from '../SelectList';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  data: AccountNoteType;
  hideDialog: () => void;
};

type FormValues = {
  modeOfCapture: string;
  description: string;
  shareWithProvider: boolean;
};

const defaultValues: FormValues = {
  modeOfCapture: '',
  description: '',
  shareWithProvider: false
};

const UpdateAccountNoteDialog = ({ id, data, title, hideDialog, ...props }: Props): React.ReactElement => {
  const [getAccountNoteById, { data: accountNoteByIdData }] = useLazyQuery<GetAccountNotesByIdResponse>(GET_ACCOUNT_NOTE_BY_ID, {
    fetchPolicy: 'cache-and-network'
  });
  const [updateAccountNote] = useMutation(UPDATE_ACCOUNT_NOTE);

  const { control, reset, handleSubmit, setValue } = useForm<FormValues>({ defaultValues });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      updateAccountNote({
        variables: {
          truentityId: id,
          noteId: data.id,
          modeOfCapture: values.modeOfCapture,
          description: values.description,
          shareWithProvider: values.shareWithProvider,
          pinToProfile: data.pinToProfile
        }
      })
        .then(response => {
          hideDialog();

          const data = response.data!.updateAccountNote;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
        })
        .catch(error => {
          enqueueSnackbar('Unable to update note', {
            variant: 'error'
          });
          hideDialog();
        });
    } catch (err) {
      const failMessage: string = 'Failed to update a note';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (accountNoteByIdData) {
      const accountNote: FormValues = {
        description: accountNoteByIdData.getAccountNotesById?.note ?? '',
        modeOfCapture: accountNoteByIdData.getAccountNotesById?.modeOfCapture ?? '',
        shareWithProvider: accountNoteByIdData.getAccountNotesById?.shareWithProvider
      };

      reset(accountNote);
    }
  }, [accountNoteByIdData, reset]);

  useEffect(() => {
    getAccountNoteById({
      variables: {
        truentityId: id,
        noteId: data.id
      }
    });
  }, [accountNoteByIdData]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent>
        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Controller
              control={control}
              name="shareWithProvider"
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Grid container alignItems="center" justifyContent="flex-end">
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="Share with provider" />
                </Grid>
              )}
            />
          </Box>
          <Box pb={2}>
            <Controller
              control={control}
              name="modeOfCapture"
              render={({ field: { onChange, value } }) => (
                <SelectList
                  id={'modeOfCapture'}
                  label="Noted During"
                  options={modeOfCaptureNoteList?.map(x => ({ label: x?.name, value: x?.id }))}
                  placeholder="Noted During"
                  value={value}
                  required
                  clearFunction={() => {
                    setValue('modeOfCapture', '');
                  }}
                  MenuProps={{
                    sx: { maxHeight: 220 }
                  }}
                  onChange={onChange}
                />
              )}
            />
          </Box>
          <Box pb={2}>
            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value } }) => (
                <TextField
                  autoFocus={true}
                  required
                  minRows={3}
                  onChange={onChange}
                  value={value}
                  fullWidth
                  multiline
                  rows={8}
                  placeholder="Description"
                />
              )}
            />
          </Box>

          <DialogActions sx={{ justifyContent: 'end', p: 0 }}>
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
            <Button type="submit" a11yLabel="Save" appearance="primary" />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default UpdateAccountNoteDialog;
