import { color } from '@/styles/assets/colors';
import type { Announcement } from '@/types/announcement';
import { Campaign, CheckCircleOutline } from '@mui/icons-material';
import { Box, DialogActions, DialogContent, ImageList, ImageListItem, Skeleton, Stack } from '@mui/material';
import parse from 'html-react-parser';
import { useState } from 'react';
import Button from '../Button';
import { Body1, H3 } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  announcement: Announcement;
  handleClose: (id: string) => void;
};

const PrimaryAnnouncementDialog = ({ title, hideDialog, announcement, handleClose, ...props }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(new Array(announcement.media?.length).fill(false));

  const handleImageLoad = (index: number) => {
    const updatedLoaded = [...imageLoaded];
    updatedLoaded[index] = true;
    setImageLoaded(updatedLoaded);
  };

  return (
    <BaseDialog {...props} hideDialog={hideDialog} title={title} fullWidth maxWidth="md">
      <DialogContent>
        <Stack direction="row" alignItems="center" justifyContent="center" gap={1} mb={2}>
          <Campaign sx={{ fontSize: '40px', color: color.truentityBlue[400] }} />
          {announcement.title && (
            <Box>
              <H3 sx={{ textTransform: 'capitalize', color: color.truentityBlue[800] }}>{announcement.title}</H3>
            </Box>
          )}
        </Stack>
        <Box maxHeight={'30rem'} overflow="auto">
          <Box>
            <Body1 fontSize={'16px'}>{parse(announcement.content)}</Body1>
          </Box>
          {Array.isArray(announcement.media) && announcement.media?.length > 0 && (
            <>
              {announcement.media?.length === 1 ? (
                <>
                  {!imageLoaded[0] && <Skeleton variant="rectangular" width="100%" height={158} animation="wave" />}
                  <Stack mt={2} alignItems="center">
                    <Box
                      component="img"
                      src={announcement.media[0].s3PresignedUrl}
                      maxWidth="80%"
                      onLoad={() => handleImageLoad(0)}
                      style={{ visibility: imageLoaded[0] ? 'visible' : 'hidden' }}
                    />
                  </Stack>
                </>
              ) : (
                <ImageList variant="masonry" gap={15} cols={2} sx={{ mt: 2 }}>
                  {announcement.media.map((media, index) => (
                    <>
                      {!imageLoaded[index] && <Skeleton variant="rectangular" width="100%" height={158} animation="wave" />}
                      <ImageListItem key={media.s3Key}>
                        <img
                          key={`${Math.random}`}
                          src={media.s3PresignedUrl}
                          alt={`announcement-image-${index + 1}`}
                          loading="lazy"
                          onLoad={() => handleImageLoad(index)}
                          style={{ visibility: imageLoaded[index] ? 'visible' : 'hidden' }}
                        />
                      </ImageListItem>
                    </>
                  ))}
                </ImageList>
              )}
            </>
          )}
        </Box>
        <DialogActions>
          <Button startIcon={<CheckCircleOutline />} onClick={() => handleClose(announcement.id)}>
            Ok
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default PrimaryAnnouncementDialog;
