import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import SelectList, { type TextSelectOption } from '@/components/SelectList';
import { H1 } from '@/components/Typography';
import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { ClientOrganizationObject } from '@/types/administration';
import { rpmAlertsReadOptions, rpmStatusOptions } from '@/util/rpm';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import type React from 'react';
import { useEffect, useState } from 'react';

const SELECT_DEFAULT: { id: string; name: string } = { id: 'all', name: 'All' };
interface RpmAlertsListToolbarProps {
  setOrganization: (val: string) => void;
  organization: string;
  readStatus: string;
  rpmStatus: string;
  setReadStatus: (val: string) => void;
  setRpmStatus: (val: string) => void;
  isInPatientContext?: boolean;
}
export const RpmAlertsListToolbar: React.FC<RpmAlertsListToolbarProps> = ({
  organization,
  setOrganization,
  readStatus,
  rpmStatus,
  setReadStatus,
  setRpmStatus,
  isInPatientContext = false
}) => {
  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);

  const { data: organizationData } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (organization === '') {
      setOrganization(SELECT_DEFAULT.id);
    }
    if (organizationData?.clientOrganizations?.clientOrganizations) {
      setClientOrganizations([SELECT_DEFAULT, ...organizationData.clientOrganizations.clientOrganizations] as ClientOrganizationObject[]);
    }
  }, [organizationData]);

  return (
    <Stack direction="row" padding={3} spacing={2} justifyContent="space-between" alignItems="center">
      <H1>RPM Alerts</H1>
      <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
        <SelectList
          formControlProps={{ sx: { minWidth: '100px' } }}
          label="Read Status"
          options={rpmAlertsReadOptions}
          placeholder="Select an option..."
          value={readStatus}
          onChange={event => {
            setReadStatus(event.target.value as string);
          }}
          fullWidth={false}
        />
        {!isInPatientContext && (
          <>
            <SelectList
              formControlProps={{ sx: { minWidth: '100px' } }}
              label="RPM Status"
              options={rpmStatusOptions}
              placeholder="Select an option..."
              value={rpmStatus}
              onChange={event => {
                setRpmStatus(event.target.value as string);
              }}
              fullWidth={false}
            />
            <SelectList
              formControlProps={{ sx: { minWidth: '200px' } }}
              label="Organization"
              options={clientOrganizations?.map(item => {
                return {
                  label: item.name,
                  value: item.id
                } as TextSelectOption;
              })}
              placeholder="Select an option..."
              value={organization || SELECT_DEFAULT.id}
              onChange={event => {
                setOrganization(event.target.value as string);
              }}
              fullWidth={false}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
