import { HeaderInfo, PioneerRxObjectData, SectionInfo, StructuredData } from '@/types/pioneerRx';
import moment from 'moment';
import { formatDate, formatDateAndTime } from './format';

export const mapJsonData = (pioneerRxObjectsData: PioneerRxObjectData) => {
  const convertedPioneerRxPrimaryObject = JSON.parse(pioneerRxObjectsData.primary);

  const { Patient, Prescribers, Pharmacy } = convertedPioneerRxPrimaryObject;

  const mapEntity = (
    entities: any[],
    headerCallback: (entity: any) => HeaderInfo,
    firstSectionCallback: (entity: any) => SectionInfo[],
    secondSectionCallback: (entity: any) => SectionInfo[]
  ): StructuredData[] => {
    const reStructuredData: StructuredData[] = [];

    if (Array.isArray(entities)) {
      entities.forEach(entity => {
        const headerInfo: HeaderInfo = headerCallback(entity);
        const firstSectionInfo: SectionInfo[] = firstSectionCallback(entity);
        const secondSectionInfo: SectionInfo[] = secondSectionCallback(entity);

        reStructuredData.push({ header: headerInfo, bodySections: { firstSectionInfo, secondSectionInfo } });
      });
    } else {
      const headerInfo: HeaderInfo = headerCallback(entities);
      const firstSectionInfo: SectionInfo[] = firstSectionCallback(entities);
      const secondSectionInfo: SectionInfo[] = secondSectionCallback(entities);

      reStructuredData.push({ header: headerInfo, bodySections: { firstSectionInfo, secondSectionInfo } });
    }

    return reStructuredData;
  };

  const reStructuredPatient = mapEntity(
    Patient,
    () => ({
      name: `${Patient?.Name?.FirstName} ${Patient.Name?.LastName} (${Patient?.Name?.Initials})`,
      statusTypeText: Patient?.SyncStatusTypeText,
      syncDate: Patient?.SyncDate
    }),
    () => [
      {
        sectionTitle: 'Basic Information',
        sectionBody: {
          gender: Patient?.Gender,
          language: Patient?.LanguageText,
          dateOfBirth: Patient?.DateOfBirth,
          comments: Patient?.Comments?.Informational
        }
      },
      (Patient?.Categories?.Category || Patient?.PrimaryCategory) && {
        sectionTitle: 'Categories',
        sectionBody: {},
        sectionNodes: {
          categories: Patient?.Categories?.Category,
          primaryCategory: Patient?.PrimaryCategory
        }
      }
    ],
    () => [
      {
        sectionTitle: 'Contact Information',
        sectionBody: {
          email: '',
          addresses: Patient?.Addresses?.Address || [],
          phoneNumbers: Patient?.PhoneNumbers?.PhoneNumber || []
        }
      }
    ]
  );

  const reStructuredPrescribe = mapEntity(
    Prescribers?.Prescriber || [],
    prescriber => ({
      name: `${prescriber?.Name?.FirstName} ${prescriber?.Name?.LastName} (${prescriber?.Name?.Initials})`,
      statusTypeText: ''
    }),
    prescriber => [
      {
        sectionTitle: 'Memberships and Licenses',
        sectionBody: {
          prescriberType: prescriber?.PrescriberTypeText,
          npiNo: prescriber?.Identification?.NPI
        }
      }
    ],
    prescriber => [
      {
        sectionTitle: 'Contact Information',
        sectionBody: {
          email: '',
          addresses: prescriber?.Addresses?.Address || [],
          phoneNumbers: prescriber?.PhoneNumbers?.PhoneNumber || []
        }
      }
    ]
  );

  const reStructuredPharmacy = mapEntity(
    Pharmacy,
    () => ({
      name: Pharmacy?.PharmacyName,
      statusTypeText: ''
    }),
    () => [
      {
        sectionTitle: 'Memberships and Licenses',
        sectionBody: {
          ncpdMembershipNo: Pharmacy?.Identification?.NCPDP,
          npiNo: Pharmacy?.Identification?.NPI,
          deaNo: Pharmacy?.Identification?.DEA,
          stateLicenseNo: Pharmacy?.Identification?.StateLicenseNumber
        }
      }
    ],
    () => [
      {
        sectionTitle: 'Contact Information',
        sectionBody: {
          email: Pharmacy?.Email,
          addresses: Pharmacy?.Addresses?.Address || [],
          phoneNumbers: Pharmacy?.PhoneNumbers?.PhoneNumber || []
        }
      }
    ]
  );

  return {
    reStructuredPatient,
    reStructuredPrescribe,
    reStructuredPharmacy
  };
};

export const renderFormattedValue = (value: string): string => {
  const date = moment(value, 'MM/DD/YYYY', true);
  const isISO8601 = moment(value, moment.ISO_8601, true).isValid();

  const isDate = date.isValid();

  if (isDate) {
    const formattedDate = formatDate(date);
    return formattedDate;
  } else if (isISO8601) {
    return formatDateAndTime(value);
  } else {
    return value;
  }
};
