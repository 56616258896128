import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@/components/Button';
import { H3 } from '@/components/Typography';
import { useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack
} from '@mui/material';
import { useSnackbar } from 'notistack';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import { ASSIGN_CLIENT_ORGS_STORES_TO_ACCOUNT, GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';

type ClientOrgsStoresObject = {
  id: string | undefined;
  clientStoreIds: Array<string | undefined>;
};

type ClientStoreObject = {
  name?: string;
  nameTag?: string;
  id?: string;
  assigned: boolean;
};

type ClientOrganizationObject = {
  name?: string;
  id?: string | undefined;
  clientStores: ClientStoreObject[];
  collapsed: boolean;
  assigned: boolean;
};

type PatientObject = {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  email?: string;
  address?: string;
  phone?: string;
  gender?: string;
  clientOrgs: ClientOrganizationObject[];
  clientStores: ClientStoreObject[];
};

const initialValues = {
  firstName: '',
  lastName: '',
  birthDate: '',
  email: '',
  address: '',
  phone: '',
  gender: '',
  clientOrgs: [],
  clientStores: []
};

const ManageClientOrgAndStore = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled,
    refetch: organizationDataRefetch
  } = useQuery(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: patientDataLoading,
    data: patientData,
    called: patientDataCalled,
    refetch: patientDataRefetch
  } = useQuery(GET_PATIENT_DETAIL, {
    variables: {
      truentityId: id
    }
  });

  const [
    assignClientOrgStoresTOAccount,
    { data: dataOnAssignClientOrgStoresTOAccount, loading: loadingOnAssignClientOrgStoresTOAccount, error: errorOnSaveUpdateTask }
  ] = useMutation(ASSIGN_CLIENT_ORGS_STORES_TO_ACCOUNT);

  const { enqueueSnackbar } = useSnackbar();
  const [clientOrganizations, setClientOrganizations] = useState<Array<ClientOrganizationObject>>([]);
  const [patient, setPatient] = useState<PatientObject>(initialValues);

  useEffect(() => {
    if (patientDataCalled && !patientDataLoading && patientData) {
      setPatient(patientData.accountGet);
    }
  }, [patientDataLoading, patientData, patientDataCalled]);

  useEffect(() => {
    if (patient && organizationDataCalled && !organizationDataLoading && organizationData) {
      const orgLoaded = organizationData.clientOrganizations.clientOrganizations;

      const temporaryClientOrganizations: Array<ClientOrganizationObject> = [];

      if (patient.hasOwnProperty('clientOrgs') && patient.clientOrgs.length > 0) {
        orgLoaded.map(org => {
          const orgObj = { ...org };
          orgObj.collapsed = false;
          orgObj.assigned =
            patient.clientOrgs.length > 0 && patient.clientOrgs.filter(org => org.id === orgObj.id).length > 0 ? true : false;

          if (orgObj.clientStores.length > 0) {
            const clientStoresFromOrg = [...orgObj.clientStores];

            orgObj.clientStores = clientStoresFromOrg.map(store => ({
              ...store,
              assigned: patient.clientStores.length > 0 && patient.clientStores.filter(org => org.id === store.id).length > 0 ? true : false
            }));
          }

          temporaryClientOrganizations.push(orgObj);
        });
        setClientOrganizations(temporaryClientOrganizations);
      } else {
        orgLoaded.map(org => {
          const orgObj = { ...org };
          orgObj.collapsed = false;
          orgObj.assigned = false;

          if (orgObj.clientStores.length > 0) {
            const clientStoresFromOrg = [...orgObj.clientStores];

            orgObj.clientStores = clientStoresFromOrg.map(store => ({
              ...store,
              assigned: false
            }));
          }
          temporaryClientOrganizations.push(orgObj);
        });
        setClientOrganizations(temporaryClientOrganizations);
      }
    }
  }, [organizationDataCalled, organizationDataLoading, organizationData, patient]);

  const handleExpandClick = (id, index) => {
    const temporaryOrganizations = [...clientOrganizations];

    const newObj = Object.assign({}, temporaryOrganizations[index]);
    newObj.collapsed = !newObj.collapsed;

    temporaryOrganizations[index] = newObj;
    setClientOrganizations(temporaryOrganizations);
  };

  const handleChange = (event, index, organizationId) => {
    const checked = event.target.checked;

    const tempClientOrgs = [...clientOrganizations];
    const tempFilteredClientOrg = tempClientOrgs?.find(org => org.id === organizationId);

    if (tempFilteredClientOrg) {
      if (checked === false) {
        if (tempFilteredClientOrg.clientStores.length > 0) {
          const tempClientStores = [...tempFilteredClientOrg.clientStores];
          const tempClientStoresUnAssigned = tempClientStores.map(obj => ({ ...obj, assigned: false }));

          tempFilteredClientOrg.clientStores = tempClientStoresUnAssigned;
        }
      }

      tempFilteredClientOrg.assigned = checked;
      tempClientOrgs[index] = tempFilteredClientOrg;
    }
    setClientOrganizations(tempClientOrgs);
  };

  const handleChangeStore = (event, parentIndex, index, storeId, organizationId) => {
    const checked = event.target.checked;

    const tempClientOrgs = [...clientOrganizations];
    const tempFilteredClientOrg = tempClientOrgs?.find(org => org.id === organizationId);

    if (tempFilteredClientOrg) {
      if (tempFilteredClientOrg.clientStores.length > 0) {
        const tempClientStores = [...tempFilteredClientOrg.clientStores];
        const tempFilteredClientStore = tempClientStores?.find(store => store.id === storeId);

        if (tempFilteredClientStore) {
          tempFilteredClientStore.assigned = checked;
          tempClientStores[index] = tempFilteredClientStore;
        }

        tempClientOrgs[parentIndex] = tempFilteredClientOrg;
      }
    }

    setClientOrganizations(tempClientOrgs);
  };

  const onAssignOrgsAndStores = () => {
    if (clientOrganizations.length > 0) {
      const tempClientOrganization: Array<ClientOrgsStoresObject> = [];

      clientOrganizations.map(org => {
        if (org.assigned) {
          if (org.clientStores.length > 0) {
            const tempStore: Array<string | undefined> = [];
            org.clientStores.map(store => {
              if (store.assigned) {
                tempStore.push(store?.id);
              }
            });

            const obj = {
              id: org.id,
              clientStoreIds: tempStore
            };
            tempClientOrganization.push(obj);
          }
        }
      });

      assignClientOrgStoresTOAccount({
        variables: {
          clientOrganizationStoreData: tempClientOrganization,
          truentityId: id
        }
      }).then(response => {
        const data = response.data!.assignClientOrgsStoresToAccount;
        const variant = data!.status === 'Success' ? 'success' : 'error';

        enqueueSnackbar(data.message, {
          variant
        });
      });
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <H3 weight="bold">Client Organizations and Stores</H3>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <List component="nav">
            {clientOrganizations.map((sub, parentIndex) => (
              <div>
                <ListItem dense key={parentIndex}>
                  <ListItemIcon>
                    <Checkbox
                      disableRipple
                      edge="start"
                      checked={sub.assigned}
                      onChange={event => {
                        handleChange(event, parentIndex, sub.id);
                      }}
                    />
                  </ListItemIcon>
                  <ListItemIcon>
                    <Button disableFocusRipple disableRipple variant="outlined" size="small">
                      {sub?.name?.toUpperCase()}
                    </Button>
                  </ListItemIcon>

                  <ListItemSecondaryAction>
                    <IconButton onClick={() => handleExpandClick(sub.id, parentIndex)}>
                      {sub.collapsed ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse unmountOnExit in={sub.collapsed} timeout="auto">
                  <List disablePadding component="div" sx={{ paddingLeft: 4 }}>
                    <ListItem dense>
                      {sub && sub.clientStores && sub.clientStores.length > 0 ? (
                        <ListItemText primary={'Locations'} />
                      ) : (
                        <ListItemText primary={'No Locations Available'} />
                      )}
                    </ListItem>
                    {sub.clientStores.map((store, childIndex) => (
                      <ListItem dense>
                        <ListItemIcon>
                          <Checkbox
                            disableRipple
                            edge="start"
                            checked={store.assigned}
                            tabIndex={-1}
                            onChange={event => {
                              handleChangeStore(event, parentIndex, childIndex, store.id, sub.id);
                            }}
                            disabled={!sub.assigned}
                          />
                        </ListItemIcon>
                        <ListItemText primary={store.name} secondary={store?.nameTag} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" spacing={0} padding={2} justifyContent={'flex-end'}>
            <Button onClick={onAssignOrgsAndStores} variant="contained" startIcon={<AddCircleOutlineRoundedIcon />}>
              Assign Items
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ManageClientOrgAndStore;
