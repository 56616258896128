import { AccountType, ClientOrganizationType } from '@/types/graphql';
import { gql } from '@apollo/client';

export type PioneerRxObjectsResponseType = {
  getPioneerrxObjectsByAccountId: {
    id: string;
    account: AccountType;
    clientOrg: ClientOrganizationType;
    primary: string;
    objectHeader: string;
  };
};

export const GET_PIONEERRX_OBJECTS = gql`
  query GetPioneerrxObjectsByAccountIdQuery($truentityId: ID!, $clientOrgId: String!, $clientStoreId: String) {
    getPioneerrxObjectsByAccountId(truentityId: $truentityId, clientOrgId: $clientOrgId, clientStoreId: $clientStoreId) {
      id
      account {
        id
        user {
          firstName
          lastName
          email
        }
      }
      clientOrg {
        id
      }
      primary
      objectHeader
    }
  }
`;
