import { gql } from '@apollo/client';

export const GET_INVOICES = gql`
  query getInvoicesQuery($pageNum: Int!, $pageSize: Int!, $status: String!) {
    invoicesGet(pageNum: $pageNum, pageSize: $pageSize, status: $status) {
      invoices {
        id
        description
        status
        dateInvoiced
        totalNumberOfHours
        totalNumberOfMinutes
        hourlyRate
        timesheets {
          id
        }
        relyingPartyAdmin {
          id
          name
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_INVOICES_BY_ADMIN = gql`
  query getInvoicesByRelyingPartyAdminQuery($relyingPartyAdminId: String!, $pageNum: Int!, $pageSize: Int!, $status: String!) {
    invoicesByRelyingPartyAdmin(relyingPartyAdminId: $relyingPartyAdminId, pageNum: $pageNum, pageSize: $pageSize, status: $status) {
      invoices {
        id
        description
        status
        dateInvoiced
        totalNumberOfHours
        totalNumberOfMinutes
        hourlyRate
        timesheets {
          id
        }
        relyingPartyAdmin {
          id
          name
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const SAVE_INVOICE = gql`
  mutation CreateInvoice($invoiceData: InvoiceInput!, $invoiceId: String) {
    saveInvoice(invoiceData: $invoiceData, invoiceId: $invoiceId) {
      invoice {
        id
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query getInvoiceQuery($invoiceId: String!) {
    invoiceGet(invoiceId: $invoiceId) {
      id
      description
      status
      dateInvoiced
      timesheets {
        id
        datePerformed
        description
        numberOfHours
        numberOfMinutes
        status
        clientOrganization {
          id
          name
        }
        clientStore {
          id
          name
        }
        relyingPartyAdmin {
          id
          name
        }
      }
      relyingPartyAdmin {
        id
        name
        supervisorEmail
        relyingParty {
          email
        }
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export type InvoicePDFResponse = {
  invoiceCreatePdfReport: {
    pdfDownloadUrl: string | null;
  };
};

export const INVOICE_PDF = gql`
  mutation InvoiceCreatePdfReport($invoiceId: String!) {
    invoiceCreatePdfReport(invoiceId: $invoiceId) {
      pdfDownloadUrl
      errors
    }
  }
`;

export const INVOICE_EMAIL = gql`
  mutation invoiceSendByEmail($invoiceId: String!, $emailSubject: String!, $emailAddresses: String!) {
    invoiceSendByEmail(invoiceId: $invoiceId, emailSubject: $emailSubject, emailAddresses: $emailAddresses) {
      status
    }
  }
`;

export const CHANGE_INVOICE_STATUSES = gql`
  mutation changeStatusInvoices($invoiceStatuses: [InvoiceStatusInput!]!) {
    changeStatusInvoices(invoiceStatuses: $invoiceStatuses) {
      status
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($invoiceId: String!) {
    deleteInvoice(invoiceId: $invoiceId) {
      status
      message
    }
  }
`;
