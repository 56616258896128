import AllScriptsPatientOnboardDialog from '@/components/Dialogs/AllScriptsPatientOnboardDialog';
import MuiMenu from '@/components/MuiMenu';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { H3 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect, useState } from 'react';

type MenuOption = {
  label: string;
  onAction: () => void;
  disabled: boolean;
};

const Patients = () => {
  const { showModal } = useModal();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { setContent } = useContext(SideMenuContext);

  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([]);

  const showAllScriptsModal = selectedType => {
    const modalRef = showModal(AllScriptsPatientOnboardDialog, {
      title: selectedType === 'AllScripts' ? 'Onboard AllScripts Patients' : 'Onboard Adt Patients',
      hideDialog: () => {
        modalRef.hide();
      },
      type: selectedType
    });
  };

  useEffect(() => {
    const options: MenuOption[] = [];

    options.push(
      {
        label: 'Allscripts',
        onAction: () => {
          showAllScriptsModal('AllScripts');
        },
        disabled: currentUser && currentUser?.settings && JSON.parse(currentUser.settings)?.AllScriptsIntegration ? false : true
      },
      {
        label: 'Adt Monitoring',
        onAction: () => {
          showAllScriptsModal('AdtMonitoring');
        },
        disabled: currentUser && currentUser?.settings && JSON.parse(currentUser.settings)?.AccountCareMonitoringIntegration ? false : true
      }
    );

    setMenuOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, []);

  return (
    <Paper component={Stack} direction="row" p={5} sx={{ justifyContent: 'space-between' }}>
      <H3>Patient Onboarding</H3>

      <Stack direction="row" spacing={1}>
        <MuiMenu variant={'outlined'} label="Onboard Patients" options={menuOptions} />
      </Stack>
    </Paper>
  );
};

export default Patients;
