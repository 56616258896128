import Alert from '@/components/Alert';
import Button from '@/components/Button';
import LabelGroup from '@/components/LabelGroup';
import { Medication } from '@/types/medication';
import { gql, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import ZoomableImage from '../ZoomableImage';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

const ATTACH_TO_MEDICATION = gql`
  mutation AddAttachmentToAccountMedication($accountMedicationId: String!, $key: String!) {
    addAttachmentToAccountMedication(accountMedicationId: $accountMedicationId, key: $key) {
      attachments
    }
  }
`;

export type FormValues = {
  medSelect: string;
};

const defaultValues: FormValues = {
  medSelect: ''
};

type Props = BaseDialogProps & {
  title: string;
  successMessage: string;
  truentityId?: string;
  keyToAttach: string;
  urlToAttach: string;
  accountMedications: any[];
  hideDialog: () => void;
};

const AttachToMedDialog = ({
  title,
  successMessage,
  truentityId,
  keyToAttach,
  urlToAttach,
  hideDialog,
  accountMedications,
  ...props
}: Props) => {
  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const [sendMedrecSnapshot] = useMutation(ATTACH_TO_MEDICATION);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    let variables = {};
    variables = {
      accountMedicationId: values.medSelect,
      key: keyToAttach
    };
    try {
      const result = await sendMedrecSnapshot({
        variables: { ...variables }
      });
      const { attachments } = result.data!.addAttachmentToAccountMedication;

      if (attachments) {
        showSnackbar(successMessage, 'success');
        return;
      }
    } catch (err) {
      console.log(err);
    }

    showSnackbar('Something went wrong. Please contact Tech Support.', 'error');
  };

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
    hideDialog();
  };

  const getMedicationList = () => {
    let fmtOrgs = accountMedications
      .filter((medication: Medication) => {
        return medication.isCurrent;
      })
      .map((medication: Medication, index) => {
        return (
          <MenuItem key={index} value={medication.id}>
            {medication.displayName}
          </MenuItem>
        );
      });
    return fmtOrgs;
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        {accountMedications?.length === 0 && (
          <>
            <Alert description="No Medications Found." status="warning" title="Medications not found for this patient" />

            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <Button a11yLabel="OK" onClick={hideDialog} />
            </DialogActions>
          </>
        )}

        {accountMedications.length > 0 && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabelGroup label="Image">
              <ZoomableImage src={`${urlToAttach}`} />
            </LabelGroup>

            <FormControl variant="outlined" fullWidth margin="dense" size="medium">
              <InputLabel id="med-select-label">Select a Medication</InputLabel>
              <Select {...register('medSelect')} defaultValue="" labelId="med-select-label" label="Select a Medication" required>
                {getMedicationList()}
              </Select>
            </FormControl>

            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <Button type="submit" a11yLabel="Submit" appearance="primary" />
              <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
            </DialogActions>
          </form>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default AttachToMedDialog;
