import { Outlet } from 'react-router-dom';

import MyTasks from './MyTasks';

const TasksSection = () => {
  return <Outlet />;
};

export { MyTasks };
export default TasksSection;
