import type { TruentityTextFieldProps } from '@/components/TruentityTextField';
import TruentityTextField from '@/components/TruentityTextField';
import { Body1 } from '@/components/Typography';
import { formatPhoneNumber } from '@/util/format';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Tooltip } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

type Props = TruentityTextFieldProps & {
  editable?: boolean;
  displayAs?: any;
  isDisabled?: boolean;
  textColor?: 'error' | 'success' | 'warning' | 'info' | 'inherit' | 'primary' | 'secondary';
};
const TruentityPhoneNumber = ({ value, editable, onChange, isDisabled = false, textColor = 'primary', ...props }: Props) => {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleChange = event => {
    setPhoneNumber(event?.target?.value);
    if (onChange) {
      onChange(event);
    }
  };

  const handleClick = useCallback(() => {
    const formattedNumber = formatPhoneNumber(phoneNumber);
    if (!editable && formattedNumber) {
      window.location.href = `tel:${phoneNumber}`;
    }
  }, [editable, phoneNumber]);

  const displayPhoneNumber = formatPhoneNumber(phoneNumber) ?? phoneNumber;
  const isNANP = displayPhoneNumber !== phoneNumber;

  const renderPhoneNumberText = useCallback(() => {
    if (isNANP) {
      return (
        <Button onClick={handleClick} color={textColor} variant="text">
          {displayPhoneNumber}
        </Button>
      );
    } else {
      return (
        <Body1 fontWeight={'bold'} sx={{ maxWidth: 150 }}>
          {phoneNumber}
          <Tooltip title="Invalid format for NANP">
            <WarningIcon color="error" style={{ fontSize: 18, marginLeft: 4 }} />
          </Tooltip>
        </Body1>
      );
    }
  }, [isNANP, phoneNumber, handleClick, displayPhoneNumber, textColor]);

  useEffect(() => {
    if (editable) {
      const cleaned = value?.toString()?.replace(/^\+1/, '') ?? '';
      setPhoneNumber(cleaned);
    } else {
      setPhoneNumber(value?.toString() ?? '');
    }
  }, [value, editable]);

  return (
    <>
      {editable ? (
        <InputMask mask="(999) 999-9999" value={phoneNumber} onChange={handleChange} maskChar=" " disabled={isDisabled} {...props}>
          {inputProps => <TruentityTextField disabled={isDisabled} {...inputProps} variant="outlined" />}
        </InputMask>
      ) : (
        renderPhoneNumberText()
      )}
    </>
  );
};

export default TruentityPhoneNumber;
