import useAssignExistingUser from '@/hooks/forms/useAssignExistingUser';
import AssignUserForm from '@/routes/Administration/Components/AssignUserForm';
import { UserFormMode } from '@/types/utils';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { DialogActions, DialogContent, Grid } from '@mui/material';
import Button from '../Button';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type AddExistingUserDialogProps = BaseDialogProps & {
  hideDialog: () => void;
  onExistingUserAdd?: () => void;
};

const AddExistingUserDialog = ({ title, hideDialog, onExistingUserAdd, ...props }: AddExistingUserDialogProps) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { handleAssignUsersSave, selectedUsers, setSelectedUsers, loadingAssignMultipleAdmins } = useAssignExistingUser();

  const handleCancelDialog = () => {
    hideDialog();
  };

  const assignUserHandleSubmit = async () => {
    const response = await handleAssignUsersSave();
    if (response && onExistingUserAdd) {
      onExistingUserAdd();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={handleCancelDialog} fullWidth maxWidth="md">
      <DialogContent>
        <AssignUserForm
          formMode={UserFormMode.EXISTING_COMPANY}
          companyName={currentUser?.relyingParty?.name}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          hideHeader={true}
        />
        <Grid item xs={12} justifyContent="flex-end">
          <DialogActions sx={{ justifyContent: 'end', padding: '30px 0 0 0' }}>
            <Button
              onClick={assignUserHandleSubmit}
              isLoading={loadingAssignMultipleAdmins}
              type="submit"
              a11yLabel="Save"
              appearance="primary"
            />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={handleCancelDialog} />
          </DialogActions>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddExistingUserDialog;
