import TruentityLogo from '@/components/TruentityLogo';
import { Body1, H3 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { Box, Paper, Stack } from '@mui/material';

const QuickUploadThanks = () => {
  return (
    <Stack alignItems={'center'} sx={{ width: '100%', height: '100%', backgroundColor: color.truentityCyan[500] }}>
      <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', marginTop: '10vh' }}>
        <Box sx={{ paddingBottom: '24px', borderBottom: '2px solid $truentityLigthGray', marginBottom: '19px' }}>
          <TruentityLogo />
        </Box>

        <H3 textAlign="center">Thank you</H3>
        <Body1 textAlign="center">You can now close this tab/window</Body1>
      </Paper>
    </Stack>
  );
};

export default QuickUploadThanks;
