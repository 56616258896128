import { ActivitiesList } from '@/components/ActivitiesList';
import CollapsibleListGroup from '@/components/CollapsibleListGroup';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { ProfileDialog } from '@/components/Dialogs';
import { GridItem } from '@/components/GridItem';
import type { NavigationSidebarMenuItem } from '@/components/SideMenuTree';
import SideMenuTree from '@/components/SideMenuTree';
import { TimeoutStatus } from '@/components/TimeoutStatus';
import { Body1, H3, H5 } from '@/components/Typography';
import AdministrationContext from '@/context/administrationContext';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetRelyingPartyAdminSessionsResponse } from '@/graphql/administration';
import { GET_RELYING_PARTY_ADMINS_ACTIVITIES, GET_RELYING_PARTY_ADMINS_SESSIONS } from '@/graphql/administration';
import type { UsersData } from '@/routes/Administration/Users';
import type { TruentityDateInput } from '@/types/date';
import type { RelyingPartyAdminSessionType } from '@/types/graphql';
import { groupBy } from '@/util/array';
import { toDate } from '@/util/date';
import { duration, formatDate, formatDateAndTime, formatTime } from '@/util/format';
import { localeCompare } from '@/util/sort';
import { useLazyQuery, useQuery } from '@apollo/client';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { Box, Collapse, Grid, IconButton, List, ListItemButton, ListItemText, ListSubheader, Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserInfoTabs from '../userInfoTabs';

function timeDifference(start: TruentityDateInput, end: TruentityDateInput) {
  const endSeconds = Math.floor(toDate(end).getTime() / 1000);
  const startSeconds = Math.floor(toDate(start).getTime() / 1000);
  return duration(endSeconds - startSeconds);
}

const excludeByType = activity => {
  const type = activity?.activityType;
  return !type?.startsWith('Session::Started') && !type?.startsWith('Session::Ended');
};

const renderSessionNavItems = (sessions: RelyingPartyAdminSessionType[], navigateFunc: Function): NavigationSidebarMenuItem[] => {
  const Icon = () => {
    return <AccessTimeIcon fontSize="small" />;
  };

  const getLabel = (item: RelyingPartyAdminSessionType) => {
    let labelText = formatTime(item.startedAt);
    if (item.endedAt) {
      labelText += ' - ' + formatTime(item.endedAt);
    }

    return labelText;
  };

  //TODO: Fix adminstration context
  const sortedSessions = [...(sessions as RelyingPartyAdminSessionType[])].sort(localeCompare('startedAt', true));
  const groupedByDate = groupBy<RelyingPartyAdminSessionType>(sortedSessions, m => formatDate(m.startedAt));
  const entries = Object.entries(groupedByDate);

  return entries.map(([date, children]) => {
    return {
      labelText: date,
      labelInfo: children.length.toString(),
      items: children.map(item => ({
        labelText: getLabel(item),
        labelIcon: Icon,
        onClick: () => navigateFunc(item)
      }))
    };
  });
};

const UserDetailsSection = () => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { setContent } = useContext(SideMenuContext);
  const { users, sessions, setSessions } = useContext(AdministrationContext);
  const [selectedUserInfo] = useState<UsersData | undefined>(users.find(u => u.id.toString() === id));

  const [activities, setActivities] = useState<any[]>([]);
  const [selectedSession, setSelectedSession] = useState<RelyingPartyAdminSessionType>();

  const { data } = useQuery<GetRelyingPartyAdminSessionsResponse>(GET_RELYING_PARTY_ADMINS_SESSIONS, {
    variables: {
      relyingPartyAdminId: selectedUserInfo?.id, //'40b40597-464f-11ec-acbb-16e10b9d7a1c',
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [sessionsNavItems, setSessionsNavItems] = useState<NavigationSidebarMenuItem[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [getActivities, activitiesResult] = useLazyQuery(GET_RELYING_PARTY_ADMINS_ACTIVITIES);
  const [openDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    if (selectedSession) {
      getActivities({
        variables: {
          relyingPartyAdminSessionId: selectedSession.id,
          pageNum: 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      }).catch(err => console.error(err));
    }
  }, [getActivities, selectedSession]);

  useEffect(() => {
    const data = activitiesResult?.data?.relyingPartyAdminActivitiesBySession?.activityLogs || [];
    setActivities(data?.filter(a => excludeByType(a)) || []);
  }, [activitiesResult?.data?.relyingPartyAdminActivitiesBySession?.activityLogs]);

  const onBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onAssignedPatients = useCallback(() => {
    navigate(`/patients?search=${id}`);
  }, [id, navigate]);

  const SideMenu = useMemo(() => <SideMenuTree index={0} items={sessionsNavItems} />, [sessionsNavItems]);
  const SideMenuContent = useCallback(() => {
    return (
      <List component="nav">
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <List subheader={<ListSubheader>Patients</ListSubheader>}>
            <ListItemButton onClick={() => onAssignedPatients()}>
              <ListItemText primary="Assigned Patients" />
            </ListItemButton>
          </List>
          <List subheader={<ListSubheader>Reports</ListSubheader>}>
            <CollapsibleListGroup defaultOpen={true} primaryText="Recent Sessions" icon={<ListRoundedIcon />}>
              {SideMenu}
            </CollapsibleListGroup>
          </List>
        </Box>
      </List>
    );
  }, [SideMenu, onAssignedPatients, onBack]);

  const showEditProfileDialog = () => {
    const modal = showModal(ProfileDialog, {
      title: 'Edit User',
      hideDialog: () => {
        modal.hide();
      },
      type: 'EXTERNAL_UPDATE',
      pharmacistInfo: selectedUserInfo
    });
  };

  useEffect(() => {
    if (!selectedUserInfo) {
      enqueueSnackbar('User not found', { variant: 'error' });
      navigate('/administration/users');
    }
  }, [enqueueSnackbar, navigate, selectedUserInfo]);

  useEffect(() => {
    setSessionsNavItems(renderSessionNavItems(sessions, setSelectedSession));
  }, [setSelectedSession, sessions]);

  useEffect(() => {
    setContent(<SideMenuContent />);
  }, [SideMenuContent, sessionsNavItems, setContent]);

  useEffect(() => {
    if (data && data.relyingPartyAdminSessions) {
      setSessions(data.relyingPartyAdminSessions.relyingPartyAdminSessions);
    }
  }, [data, setSessions]);

  return (
    <Stack direction="column" spacing={4}>
      <UserInfoTabs userInfo={selectedUserInfo} goToEditProfile={showEditProfileDialog} />

      <Paper component={Stack} p={4} sx={{ height: '100%' }}>
        {selectedSession && (
          <Stack spacing={2}>
            <H3>Sessions</H3>

            <Grid container rowSpacing={4}>
              <GridItem
                xs={6}
                icon={<LoginIcon fontSize={'small'} />}
                label="Started"
                value={formatDateAndTime(selectedSession?.startedAt)}
              />
              {selectedSession?.endedAt && (
                <GridItem
                  xs={6}
                  icon={<LogoutIcon fontSize={'small'} />}
                  label="Ended"
                  value={
                    <>
                      {formatDateAndTime(selectedSession?.endedAt)} <TimeoutStatus timedOut={selectedSession?.timedOut}></TimeoutStatus>
                    </>
                  }
                />
              )}
              {selectedSession?.endedAt && (
                <GridItem
                  xs={6}
                  icon={<TimelapseIcon fontSize={'small'} />}
                  label="Duration"
                  value={timeDifference(selectedSession?.startedAt, selectedSession?.endedAt)}
                />
              )}

              {(selectedSession?.device?.userAgent || selectedSession?.device?.ip) && (
                <Stack component={Grid} item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InfoIcon fontSize="small"></InfoIcon>
                    <H5 fontWeight="bold">Access Details</H5>
                    <Stack
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setOpenDetails(!openDetails)}
                      spacing={1}
                      direction="row"
                      justifyContent={'center'}
                      alignItems={'center'}
                    >
                      <IconButton>
                        <ExpandMoreIcon sx={{ transform: openDetails ? 'rotate(0deg)' : 'rotate(180deg)' }} />
                      </IconButton>
                    </Stack>
                  </Stack>

                  <Collapse in={openDetails}>
                    <Stack sx={{ paddingLeft: '15px' }} direction="row" alignItems="center" justifyContent={'space-between'} spacing={2}>
                      <Stack sx={{ flex: 1 }} direction={'row'} alignItems="center" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <InsertLinkIcon fontSize={'small'} />
                          <H5 fontWeight="bold">IP</H5>
                        </Stack>
                        <Body1 sx={{ width: '50%' }} textAlign="left">
                          {selectedSession?.device?.ip || '---'}
                        </Body1>
                      </Stack>

                      <Stack sx={{ flex: 1 }} direction={'row'} alignItems="center" justifyContent={'space-between'}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <SmartphoneIcon fontSize={'small'} />
                          <H5 fontWeight="bold">Device</H5>
                        </Stack>
                        <Body1 sx={{ width: '50%' }} textAlign="left">
                          {selectedSession?.device?.userAgent || '---'}
                        </Body1>
                      </Stack>
                    </Stack>
                  </Collapse>
                </Stack>
              )}
            </Grid>
            <ActivitiesList activities={activities} />
          </Stack>
        )}

        {!selectedSession && <H3 textAlign="center">No Session Selected</H3>}
      </Paper>
    </Stack>
  );
};

export default UserDetailsSection;
