import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { Body1, H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import LineChartSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/Vitals/LineChart';
import { color } from '@/styles/assets/colors';
import { RpmHealthTrackingKeys, type RpmAccountHealthTrackingType } from '@/types/remotePatientMonitoring';
import { formatDate, isBefore, isSame } from '@/util/format';
import { Grid, Stack } from '@mui/material';
import type { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import type React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface Props {
  healthTrackings: RpmAccountHealthTrackingType[];
  healthTrackingsLoading: boolean;
  totalHealthTrackingsCount: number;
  currentA1CPage: number;
  setCurrentA1CPage: (page: number) => void;
}

export const PatientA1CReadings: React.FC<Props> = ({
  healthTrackings,
  healthTrackingsLoading,
  currentA1CPage,
  setCurrentA1CPage,
  totalHealthTrackingsCount
}) => {
  const { patientInfo } = useContext(PatientDetailContext);
  const [chartXValues, setChartXValues] = useState<string[]>([]);
  const [overwriteChartXValues, setOverwriteChartXValues] = useState<string[]>([]);
  const [chartYValues, setChartYValues] = useState<Array<number | null>>([]);

  const [a1cSortModel, setA1CSortModel] = useState<GridSortModel>([{ field: 'recordedAt', sort: 'desc' }]);
  const a1cReadingsTableColumns: GridColDef<RpmAccountHealthTrackingType>[] = useMemo(
    () => [
      {
        field: 'startedAt',
        headerName: 'Recorded At',
        sortable: true,
        flex: 2,
        valueGetter: params => params?.row?.startedAt,
        valueFormatter: params => formatDate(params?.value)
      },
      {
        field: 'value',
        headerName: 'Value',
        sortable: false,
        flex: 2,
        headerAlign: 'right',
        align: 'right',
        padding: 'none',
        renderCell: params => `${Number(parseFloat(params?.value ?? '0').toFixed(1))} ${params.row?.unit}`
      }
    ],
    []
  );

  const addEnrolledAtToChartData = useCallback(
    ({
      xValues,
      yValues,
      formattedXValues,
      rpmEnrolledAt,
      formattedRpmEnrolledAt
    }: {
      xValues: string[];
      yValues: Array<number | null>;
      formattedXValues: string[];
      rpmEnrolledAt: string;
      formattedRpmEnrolledAt: string;
    }) => {
      xValues.push(formatDate(rpmEnrolledAt, 'YYYY-MM-DD'));
      formattedXValues.push(formattedRpmEnrolledAt);
      yValues.push(null);
    },
    [patientInfo]
  );

  const mapA1CReadingsToChartData = useCallback(
    (a1CReadingsData: RpmAccountHealthTrackingType[]) => {
      const xValues: string[] = [];
      const yValues: Array<number | null> = [];
      const formattedXValues: string[] = [];
      let insertedEnrolledAt = false;
      const rpmEnrolledAt = patientInfo?.rpmEnrolledAt ?? '';
      const formattedRpmEnrolledAt = formatDate(patientInfo?.rpmEnrolledAt, 'MM/DD/YYYY');

      for (let i = a1CReadingsData.length - 1; i >= 0; i--) {
        const data = a1CReadingsData[i];

        if (isSame(data?.startedAt, rpmEnrolledAt)) {
          insertedEnrolledAt = true;
        } else {
          if (!insertedEnrolledAt && isBefore(rpmEnrolledAt, data?.startedAt)) {
            addEnrolledAtToChartData({
              xValues,
              yValues,
              formattedXValues,
              rpmEnrolledAt,
              formattedRpmEnrolledAt
            });
            insertedEnrolledAt = true;
          }

          xValues.push(formatDate(data?.startedAt, 'YYYY-MM-DD'));
          formattedXValues.push(formatDate(data?.startedAt, 'MM/DD/YYYY'));
          yValues.push(Number(parseFloat(data?.value ?? '0')?.toFixed(1)));

          if (i === 0 && !insertedEnrolledAt) {
            addEnrolledAtToChartData({
              xValues,
              yValues,
              formattedXValues,
              rpmEnrolledAt,
              formattedRpmEnrolledAt
            });
          }
        }
      }

      setChartXValues(xValues);
      setChartYValues(yValues);
      setOverwriteChartXValues(formattedXValues);
    },
    [patientInfo]
  );

  const resetA1CReadingsChartData = useCallback(() => {
    setChartXValues([]);
    setChartYValues([]);
    setOverwriteChartXValues([]);
  }, []);

  useEffect(() => {
    if (healthTrackings?.length > 1) {
      mapA1CReadingsToChartData(healthTrackings);
    } else {
      resetA1CReadingsChartData();
    }
  }, [healthTrackings, mapA1CReadingsToChartData]);

  useEffect(() => {
    return () => {
      resetA1CReadingsChartData();
    };
  }, []);

  return (
    <Grid mt={4} spacing={2} container direction="row" justifyContent="flex-start" alignItems="flex-start" width="100%">
      <Grid item xs={12}>
        <H4>HbA1c History</H4>
      </Grid>
      {healthTrackings?.length > 0 && (
        <Grid item xs={12}>
          <Body1
            sx={{
              fontStyle: 'italic'
            }}
          >
            A1C test reflects the average blood sugar levels over the past 2 to 3 months. An A1C level below 5.7% is considered normal.
          </Body1>
        </Grid>
      )}
      <Grid item xs={6} sm={4}>
        <Stack
          flex={1}
          sx={{
            marginTop: healthTrackings?.length > 0 ? 4 : 2
          }}
        >
          <TruentityDataGrid
            name={'dg-a1c-readings'}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentA1CPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentA1CPage(page);
            }}
            sortModel={a1cSortModel}
            onSortModelChange={newModel => {
              setA1CSortModel(newModel);
            }}
            autoHeight
            rows={healthTrackings}
            columns={a1cReadingsTableColumns}
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: a1cSortModel
              }
            }}
            loading={healthTrackingsLoading}
            paginationMode={'server'}
            rowCount={totalHealthTrackingsCount}
            slots={{
              toolbar: null
            }}
          />
        </Stack>
      </Grid>
      {healthTrackings?.length > 1 && (
        <Grid item xs={6} sm={8}>
          <LineChartSection
            width={'100%'}
            chartXValues={chartXValues}
            overwriteChartXValues={overwriteChartXValues}
            chartSeries={[
              {
                name: RpmHealthTrackingKeys.HEMOGLOBIN_A1C,
                data: chartYValues
              }
            ]}
            additionalCss={{ backgroundColor: 'transparent' }}
            tooltip={{
              formatter: value => formatDate(value, 'MM/DD/YYYY')
            }}
            annotations={{
              xAxis: [
                {
                  value: patientInfo?.rpmEnrolledAt ? formatDate(patientInfo.rpmEnrolledAt, 'YYYY-MM-DD') : '',
                  name: 'Enrolled Date',
                  color: color.successDark
                }
              ]
            }}
            dataLabels={{
              enabled: true
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PatientA1CReadings;
