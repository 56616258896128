export enum PatientsListSearchFilters {
  All = 'all',
  Recent = 'recent',
  Lookup = 'lookup',
  ToCallNext = 'to-call-next'
}

export type PatientsListSearchFilter =
  | PatientsListSearchFilters.All
  | PatientsListSearchFilters.ToCallNext
  | PatientsListSearchFilters.Recent
  | PatientsListSearchFilters.Lookup;
