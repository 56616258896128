import Button from '@/components/Button';
import { DeleteEncounterConfirmationDialog, EditEncounterDialog } from '@/components/Dialogs';
import { GridItem } from '@/components/GridItem';
import { Body1, H3, H4, H5 } from '@/components/Typography';
import { DELETE_ENCOUNTER } from '@/graphql/taskEncounter';
import useToken from '@/hooks/useToken';
import colors from '@/styles/colors';
import { Role } from '@/types/admin';
import type { EncounterType, TaskType } from '@/types/graphql';
import { DISPOSITION_TYPES, TASK_MODE_OF_RESOLUTION_TYPES } from '@/types/medication';
import { formatDateAndTime } from '@/util/format';
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useModal } from 'mui-modal-provider';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

const TaskTabContent = (task: TaskType) => {
  return (
    <Grid container spacing={4}>
      <GridItem item xs={6} label="Type" value={task.type} />
      <GridItem item xs={6} label="Mode Of Resolution" value={task.modeOfResolution} />
      <GridItem item xs={6} label="Health Plan" value={task.healthPlan} />
      <GridItem item xs={6} label="Billed Amount" value={`$ ${task.billedAmount}`} />
      <GridItem item xs={6} label="Wrap Up" value={task.wrapUpStatus} />
      <GridItem item xs={6} label="Is Primary Affiliation" value={task.isPrimaryAffiliation === 1} />
      <GridItem item xs={6} label="Medication" value={task.medicationName} />
    </Grid>
  );
};

//TODO: Extract encounter modals into a HOC

type EncounterDetailsSectionProps = {
  encounterDetail?: Partial<EncounterType>;
  refetch: () => void;
  showTasks: boolean;
  usedInTCM: boolean;
};
const EncounterDetailsSection = ({ encounterDetail, refetch, showTasks, usedInTCM }: EncounterDetailsSectionProps) => {
  const { id } = useParams();
  const [deleteEncounter] = useMutation(DELETE_ENCOUNTER);
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);

  const { showModal } = useModal();

  const showEditEncounterModal = useCallback(
    (id: string, encounter: Partial<EncounterType>) => {
      if (id && encounter) {
        const encounterModal = showModal(EditEncounterDialog, {
          title: 'Edit Encounter',
          id,
          showTasks,
          encounter,
          onComplete: () => {
            encounterModal.hide();
            refetch();
          },
          hideDialog: () => {
            encounterModal.hide();
          }
        });
      }
    },
    [showModal, showTasks, refetch]
  );

  const showDeleteEncounterConfirmationModal = useCallback(
    (truentityId: string, encounterId: string) => {
      const modalRef = showModal(DeleteEncounterConfirmationDialog, {
        title: 'Delete Encounter',
        description: 'Are you sure you want to delete this encounter log?',
        onConfirm: () => {
          modalRef.hide();
          deleteEncounter({
            variables: {
              truentityId,
              encounterId
            }
          })
            .then(() => {
              refetch();
            })
            .catch(err => {
              console.error(err);
            });
        },
        hideDialog: () => {
          modalRef.hide();
        }
      });
    },
    [deleteEncounter, refetch, showModal]
  );

  return (
    <Stack direction="column" spacing={6}>
      <Stack spacing={2}>
        <Stack spacing={4}>
          <H3 textAlign="center" css={{ width: '100%' }}>
            Encounter
          </H3>
        </Stack>

        <Stack direction="row">
          <Stack direction="row" justifyContent={'space-between'} flex={1} alignItems={'flex-end'}>
            <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
              <H4 sx={{ maxWidth: '50vw' }}>{formatDateAndTime(encounterDetail?.calledOn)}</H4>

              <Chip
                variant={'filled'}
                sx={{ textTransform: 'uppercase', letterSpacing: 2 }}
                label={
                  TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === encounterDetail?.modeOfResolution).length > 0
                    ? TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === encounterDetail?.modeOfResolution)[0].label
                    : ''
                }
                color={'info'}
                size="small"
              />
            </Stack>

            {!usedInTCM && (
              <Stack direction="row" justifyContent={'flex-end'}>
                <Button
                  sx={{ marginLeft: '10px' }}
                  disabled={isProviderAdmin || !encounterDetail}
                  disableElevation
                  color={'primary'}
                  startIcon={<EditIcon />}
                  variant={'contained'}
                  onClick={() => id && encounterDetail && showEditEncounterModal(id, encounterDetail)}
                >
                  Modify
                </Button>
                <Button
                  sx={{ marginLeft: '10px' }}
                  disabled={isProviderAdmin || !encounterDetail?.id}
                  disableElevation
                  color={'warning'}
                  startIcon={<DeleteIcon />}
                  variant={'outlined'}
                  onClick={() => id && encounterDetail?.id && showDeleteEncounterConfirmationModal(id, encounterDetail.id)}
                >
                  Delete
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} sx={{ marginBottom: '8px' }}>
          <Stack sx={{ marginBottom: '0px' }}>
            <Body1>
              <strong>{'Disposition'}</strong>
            </Body1>
            <Body1>
              {DISPOSITION_TYPES.filter(type => type.value === encounterDetail?.disposition).length > 0
                ? DISPOSITION_TYPES.filter(type => type.value === encounterDetail?.disposition)[0].label
                : '---'}
            </Body1>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={2} sx={{ marginBottom: '8px' }}>
          <Stack sx={{ marginBottom: '20px' }}>
            <Body1>
              <strong>{'Notes'}</strong>
            </Body1>
            <Body1>{encounterDetail?.notes ? encounterDetail?.notes : '---'}</Body1>
          </Stack>
        </Stack>

        {usedInTCM && (
          <Stack direction="row" justifyContent={'flex-end'}>
            <Button
              sx={{ marginLeft: '10px' }}
              disabled={isProviderAdmin || !encounterDetail}
              disableElevation
              color={'primary'}
              startIcon={<EditIcon />}
              variant={'contained'}
              onClick={() => id && encounterDetail && showEditEncounterModal(id, encounterDetail)}
            >
              Modify
            </Button>
            <Button
              sx={{ marginLeft: '10px' }}
              disabled={isProviderAdmin || !encounterDetail?.id}
              disableElevation
              color={'warning'}
              startIcon={<DeleteIcon />}
              variant={'outlined'}
              onClick={() => id && encounterDetail?.id && showDeleteEncounterConfirmationModal(id, encounterDetail.id)}
            >
              Delete
            </Button>
          </Stack>
        )}
      </Stack>

      <Stack>
        {encounterDetail?.tasks?.length === 0 && (
          <Stack
            direction="row"
            alignContent={'center'}
            spacing={2}
            sx={{ marginBottom: '8px', alignItems: 'center', justifyContent: 'center' }}
          >
            <H5 color="default" weight="medium" css={{ marginLeft: '3px', fontStyle: 'italic' }}>
              No Tasks Assigned
            </H5>
          </Stack>
        )}

        {encounterDetail?.tasks &&
          encounterDetail?.tasks?.map(row => (
            <Accordion key={row.id} defaultExpanded={false}>
              <AccordionSummary
                sx={{ boxShadow: `inset 0 -1px 0 0 ${colors.neutralA8}` }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'{option.label}-header-content-{index}'}
                id={'{option.label}-header-{index}'}
              >
                <H4>{row.type}</H4>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  backgroundColor: '#fafafa',
                  padding: '30px'
                }}
              >
                {TaskTabContent(row)}
              </AccordionDetails>
            </Accordion>
          ))}
      </Stack>
    </Stack>
  );
};

export default EncounterDetailsSection;
