import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import { H3 } from '@/components/Typography';
import { FollowUpSubTypes, FollowUpTypes } from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import type { FollowUpFormHandles } from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import FollowUpForm from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { Stack } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';

type Props = {
  truentityId: string;
  doNotCall: boolean;
  rpmStatus: string;
  accountAssignees: RelyingPartyAdminData[];
  savedFollowupsData: FollowUpRemindersTypes[] | null;
};

const FollowUpStep = forwardRef<FollowUpFormHandles, Props>(
  ({ truentityId, doNotCall, rpmStatus, accountAssignees, savedFollowupsData }, ref) => {
    const [formMode, setFormMode] = useState<FollowupFormModeTypes>(FollowupFormModeTypes.ADD);

    useEffect(() => {
      if (savedFollowupsData && savedFollowupsData?.length > 0) {
        return setFormMode(FollowupFormModeTypes.UPDATE);
      }
      setFormMode(FollowupFormModeTypes.ADD);
    }, [savedFollowupsData]);

    return (
      <Stack component="form">
        <H3
          sx={{
            marginBottom: 3
          }}
        >
          {formMode === FollowupFormModeTypes.UPDATE ? 'Reschedule Initial Visit' : 'Schedule Initial Visit'}
        </H3>
        <FollowUpForm
          truentityId={truentityId}
          formMode={formMode}
          doNotCall={doNotCall}
          accountAssignees={accountAssignees}
          hideSubmitButton
          rpmStatus={rpmStatus}
          defaultFollowUpType={FollowUpTypes.RPM_INITIAL_VISIT}
          defaultFollowupSubType={FollowUpSubTypes.RPM}
          ref={ref}
          isInitialVisitFollowupForm
          {...(savedFollowupsData && savedFollowupsData.length > 0 && { followupsData: savedFollowupsData })}
        />
      </Stack>
    );
  }
);

export default FollowUpStep;
