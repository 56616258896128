import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H4 } from '@/components/Typography';
import InputRow from '@/elements/InputRow';
import { FollowUpStatusTypes } from '@/graphql/account';
import type { TruentityDateInput } from '@/types/date';
import type { EncounterType, TaskType } from '@/types/graphql';
import { DISPOSITION_TYPES, TASK_MODE_OF_RESOLUTION_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import type { LabelValuePair } from '@/types/misc';
import { followUpStatuses, followUpSubTypes, followUpTypes } from '@/util/followUp';
import {
  formatDateAndTime,
  formatDateAndTime2,
  formatDateIgnoreTZAddDays,
  formatTime,
  getCurrentDate,
  setSpecificTimeOnDate
} from '@/util/format';
import { unknown } from '@/util/string';
import { useAccountStore } from '@/zustand/AccountStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import Button from '../../Button';
import { CustomSelectList } from '../../CustomSelectList';
import TruentityTextField from '../../TruentityTextField';
import type { EncounterDialogFormState } from './EditEncounterDialog';
import { SelectedTaskListItem } from './EditEncounterDialog';

//TODO:  Fix the required fields. Not displaying correctly for the dropdowns

const encounterDialogDefaultData: EncounterDialogFormState = {
  id: '',
  disposition: '',
  notes: '',
  modeOfResolution: ''
};

export type EncounterDialogFormData = Partial<EncounterType> & {
  selectedTasks: string[];
  followUpOn: string;
  followUpType: string;
  followUpSubType: string;
  followUpStatus: string;
  followUpNote: string;
};

type Props = {
  onSubmit: (formData: EncounterDialogFormData) => void;
  onReset: () => void;
  showTasks: boolean;
  tasksFromAPI?: TaskType[];
  encounter?: Partial<EncounterType>;
  disableSubmitButton?: boolean;
  isEditForm?: boolean;
};
const EncounterDialogForm = ({ onSubmit, onReset, showTasks, tasksFromAPI, encounter, disableSubmitButton, isEditForm = false }: Props) => {
  const currentDateTime = new Date();
  const { account } = useAccountStore();
  const [performedDate, setPerformedDate] = useState<TruentityDateInput>(new Date());
  const [performedTime, setPerformedTime] = useState<TruentityDateInput>(formatTime(currentDateTime, 'HH:mm'));

  const [nextCallDate, setNextCallDate] = useState<TruentityDateInput>('');
  const [nextCallTime, setNextCallTime] = useState<TruentityDateInput>('');

  const [FOLLOW_UP_STATUSES] = useState<TextSelectOption[]>(followUpStatuses());
  const [followUpStatus, setStatus] = useState<string>(FollowUpStatusTypes.PENDING);
  const [followUpType, setFollowUpTypeType] = useState<string>('');
  const [followUpSubType, setFollowUpSubType] = useState<string>('None');
  const [followUpNote, setFollowUpNote] = useState<string>('');

  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [state, setState] = useState(encounterDialogDefaultData);

  const [isRequiredFollowUpField, setIsRequiredFollowUpField] = useState<boolean>(false);

  const FOLLOW_UP_TYPES: TextSelectOption[] = followUpTypes(account?.doNotCall);
  const FOLLOW_UP_SUB_TYPES: TextSelectOption[] = followUpSubTypes();

  const handleOnSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    const dateTime = formatDateAndTime2(performedDate, performedTime);
    const followUpOn = formatDateAndTime2(nextCallDate, nextCallTime);
    const currentFollowUpSubType = followUpSubType === 'None' ? '' : followUpSubType;

    const formData: EncounterDialogFormData = {
      calledOn: dateTime,
      notes: state.notes,
      disposition: state.disposition,
      selectedTasks,
      modeOfResolution: state.modeOfResolution,
      followUpOn,
      followUpType,
      followUpStatus,
      followUpSubType: currentFollowUpSubType,
      followUpNote
    };
    onSubmit(formData);
  };

  useEffect(() => {
    if (encounter) {
      setPerformedDate(encounter?.calledOn);
      setPerformedTime(formatTime(encounter?.calledOn, 'HH:mm'));

      setState(encounter);

      if (encounter?.tasks && encounter?.tasks?.length > 0) {
        const selectedTasksIds = encounter?.tasks?.map(task => task.id);
        setSelectedTasks(selectedTasksIds);
      }
    }
  }, [encounter]);

  const handelCheckBox = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsRequiredFollowUpField(checked);
    if (checked) {
      const currentDate = getCurrentDate();
      const nextCallDate = formatDateIgnoreTZAddDays(currentDate, 7);
      const nextCallTime = setSpecificTimeOnDate(10, 0, 0);
      setNextCallDate(nextCallDate);
      setNextCallTime(nextCallTime);
      setFollowUpTypeType(account?.doNotCall ? FOLLOW_UP_TYPES[1].value : FOLLOW_UP_TYPES[0].value);
    } else {
      setNextCallDate('');
      setNextCallTime(formatTime(''));
      setFollowUpTypeType('');
      setFollowUpSubType('None');
      setFollowUpNote('');
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container spacing={1} pb={2}>
        <Grid item xs={12}>
          <Card sx={{ minWidth: 275, marginTop: '10px' }}>
            <CardContent>
              <Grid container spacing={1} pb={2}>
                <Grid item xs={6}>
                  <InputRow>
                    <TruentityDatePicker
                      label="Encounter Date"
                      fullWidth={true}
                      value={performedDate}
                      onChange={newValue => {
                        setPerformedDate(newValue);
                      }}
                    />
                  </InputRow>
                </Grid>

                <Grid item xs={6}>
                  <InputRow>
                    <TruentityTextField
                      id="time"
                      label="Encounter Time"
                      type="time"
                      value={performedTime}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300
                      }}
                      required
                      onChange={event => {
                        const value = event.target.value;
                        if (value) {
                          setPerformedTime(value);
                        }
                      }}
                    />
                  </InputRow>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                      <InputLabel id={'modeOfResolution'}>Mode Of Resolution</InputLabel>

                      <Select
                        labelId={'modeOfResolution'}
                        label={'Mode Of Resolution'}
                        placeholder={'Select an option...'}
                        value={state?.modeOfResolution}
                        onChange={input => {
                          setState({ ...state, modeOfResolution: input.target.value });
                        }}
                        required
                      >
                        {TASK_MODE_OF_RESOLUTION_TYPES.map((item: LabelValuePair) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <FormControl variant="outlined" fullWidth={true} margin="dense" size="medium">
                      <InputLabel id={'disposition'}>Disposition</InputLabel>

                      <Select
                        labelId={'newDisposition'}
                        label={'Disposition'}
                        placeholder={'Select an option...'}
                        value={state?.disposition}
                        onChange={event => {
                          setState({ ...state, disposition: event.target.value });
                        }}
                        required
                      >
                        {DISPOSITION_TYPES.map((item: LabelValuePair) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputRow>
                      <TruentityTextField
                        label="Additional Notes"
                        type="text"
                        multiline
                        maxRows={4}
                        value={state?.notes}
                        onChange={input => {
                          setState({
                            ...state,
                            notes: input.target.value
                          });
                        }}
                      />
                    </InputRow>
                  </FormControl>
                </Grid>
              </Grid>

              {tasksFromAPI && showTasks && (
                <CustomSelectList<TaskType>
                  title={'Tasks worked on during this Encounter'}
                  items={tasksFromAPI}
                  selectedItems={selectedTasks}
                  setSelectedItems={setSelectedTasks}
                  options={tasksFromAPI.map(task => {
                    const subType = UPDATED_SUB_TASK_TYPES.find(type => type.value === task.subType);
                    const label = `${task.type} ${subType?.label ?? ''} - ${task.medicationName ?? unknown()} - ${formatDateAndTime(
                      task.createdAt
                    )}`;

                    return {
                      id: task.id,
                      value: task.id,
                      label
                    };
                  })}
                  generateSelectedItem={item => <SelectedTaskListItem task={item} />}
                />
              )}

              {!isEditForm && (
                <>
                  <Divider sx={{ marginTop: 2 }} />
                  <FormControlLabel
                    label={`Add a Follow-up Reminder`}
                    control={<Checkbox value={isRequiredFollowUpField} onChange={handelCheckBox} />}
                  />
                  <Accordion expanded={isRequiredFollowUpField}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`follow-up-reminder`}>
                      <H4>Follow-up Reminder</H4>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: '#fafafa', padding: 2 }}>
                      <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={6}>
                          <InputRow>
                            <TruentityDatePicker
                              label="Follow-Up Date"
                              format="YYYY-MM-DD"
                              value={nextCallDate}
                              onChange={date => {
                                setNextCallDate(date as Date);
                              }}
                              TextFieldProps={{ required: isRequiredFollowUpField }}
                            />
                          </InputRow>
                        </Grid>
                        <Grid item xs={6}>
                          <MuiTextField
                            sx={{ width: '100%', marginTop: '8px' }}
                            id="time"
                            label="Follow-Up Time"
                            type="time"
                            value={nextCallTime}
                            inputProps={{
                              step: 300
                            }}
                            required={isRequiredFollowUpField}
                            onChange={event => {
                              const value = event.target.value;
                              if (value) {
                                setNextCallTime(value);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectList
                            id="followupType"
                            label={'Follow-Up Type'}
                            options={FOLLOW_UP_TYPES}
                            placeholder="Select an option..."
                            value={followUpType}
                            keepOriginalValue
                            onChange={event => setFollowUpTypeType(event.target.value as string)}
                            required={isRequiredFollowUpField}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectList
                            id="followupSubType"
                            label={'Follow-Up Sub Type (Optional)'}
                            options={FOLLOW_UP_SUB_TYPES}
                            placeholder="Select an option..."
                            value={followUpSubType}
                            keepOriginalValue
                            onChange={event => setFollowUpSubType(event.target.value as string)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <SelectList
                            id="status"
                            label={'Status'}
                            options={FOLLOW_UP_STATUSES}
                            placeholder="Select an Status..."
                            value={status}
                            keepOriginalValue
                            onChange={event => setStatus(event.target.value as string)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TruentityTextField
                            value={followUpNote}
                            multiline
                            fullWidth
                            rows={2}
                            inputProps={{ max: 2 }}
                            placeholder="Type your note here"
                            id="note"
                            label="Notes (Optional)"
                            onChange={event => setFollowUpNote(event.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
        <Button type="submit" a11yLabel={'Submit'} appearance="primary" disabled={disableSubmitButton} />
        <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={onReset} />
      </DialogActions>
    </form>
  );
};

export default EncounterDialogForm;
