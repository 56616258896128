import Alert from '@/components/Alert';
import { PhotoCamera } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import type { SxProps } from '@mui/material';
import { Box, IconButton, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import Button from './Button';
import { Body2 } from './Typography';

export type FileWithOrigin = {
  id: string;
  mediaFile: File | string;
};

export type DropzoneFile = {
  id: string;
  mediaFile: File;
};

type Props = {
  maxFileCount?: number;
  maxFileSize?: number;
  dropzoneStyles?: SxProps;
  mediaFiles?: FileWithOrigin[];
  onFileUpload: (files: DropzoneFile[]) => void;
  onRemoveFile: (files: DropzoneFile[]) => void;
};

const TruentityDropzone = ({
  onFileUpload,
  onRemoveFile,
  mediaFiles,
  dropzoneStyles,
  maxFileCount = 1,
  maxFileSize = 5,
  ...props
}: Props) => {
  const [files, setFiles] = useState<DropzoneFile[]>([]);
  const [error, setError] = useState('');

  const isMultiple = useMemo(() => maxFileCount > 1, [maxFileCount]);

  const onDrop = useCallback(
    (acceptedFiles: File[], existingFiles: DropzoneFile[]) => {
      const fileSize = maxFileSize * 1024 * 1024;

      const refactoredAcceptedFiles = acceptedFiles.map(
        acceptedFile =>
          ({
            id: uuidv4() as string,
            mediaFile: acceptedFile
          } as DropzoneFile)
      );

      const files = [...refactoredAcceptedFiles, ...existingFiles];

      const newFiles = files.filter(file => {
        if (fileSize && file.mediaFile.size > fileSize) {
          handleErrors(`File ${file.mediaFile.name} is too large.`);
          return false;
        }
        return true;
      });

      setFiles(newFiles);
      onFileUpload(newFiles);
    },
    [maxFileSize, onFileUpload]
  );

  const removeFile = (fileToRemove: DropzoneFile) => () => {
    const updatedFiles = files.filter(file => file.id !== fileToRemove.id);
    setFiles(updatedFiles);
    onRemoveFile(updatedFiles);
  };

  const handleErrors = (errorMessage: string) => {
    setError(errorMessage);
    setTimeout(() => setError(''), 5000);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => onDrop(acceptedFiles, files),
    multiple: isMultiple,
    maxFiles: maxFileCount,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png']
    }
  });

  const fetchAndConvertFiles = useCallback(async (urls: string[]) => {
    const fetchedFiles = await Promise.all(
      urls.map(async url => {
        const response = await fetch(url);
        const blob = await response.blob();
        const newFile = new File([blob], 'filename', { type: blob.type });
        return newFile;
      })
    );
    return fetchedFiles;
  }, []);

  useEffect(() => {
    (async () => {
      const initialFiles: DropzoneFile[] = [];

      if (mediaFiles && mediaFiles.length > 0) {
        for (const media of mediaFiles) {
          if (typeof media.mediaFile === 'string') {
            const fetchedFiles = await fetchAndConvertFiles([media.mediaFile]);
            initialFiles.push({
              id: media.id,
              mediaFile: fetchedFiles[0]
            });
          } else {
            initialFiles.push(media as DropzoneFile);
          }
        }
      }
      setFiles(initialFiles);
    })();
  }, [mediaFiles, fetchAndConvertFiles]);

  useEffect(() => {
    if (files.length > maxFileCount) {
      const newFiles = files.slice(0, maxFileCount);
      setFiles(newFiles);
      onFileUpload(newFiles);
      handleErrors(`Maximum file count exceeded. Only ${maxFileCount} files can be uploaded at a time.`);
    }
  }, [files, maxFileCount, onFileUpload]);

  return (
    <Box>
      {error && <Alert status={'error'} title={'Failed to upload logo'} description={error} sx={{ marginBottom: 2 }} />}
      <Stack
        {...getRootProps()}
        gap={2}
        alignItems="center"
        sx={{
          border: '2px dashed',
          borderColor: error ? 'error' : 'gray',
          borderRadius: 2,
          p: 4,
          textAlign: 'center',
          cursor: 'pointer',
          ...dropzoneStyles
        }}
        {...props}
      >
        <input {...getInputProps()} />
        <PhotoCamera sx={{ fontSize: 40 }} />
        <Body2>Drag your photo here, or</Body2>
        <Button variant="contained" component="span" disabled={files?.length >= maxFileCount}>
          Browse File{isMultiple ? 's' : ''}
        </Button>
      </Stack>
      <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {files.map((file, index) => (
          <Box key={index} sx={{ position: 'relative', m: 1, width: 100, height: 100, borderRadius: '5px' }}>
            <IconButton
              onClick={removeFile(file)}
              sx={{
                position: 'absolute',
                top: '-10px',
                right: '-10px',
                p: 0.5,
                background: 'white',
                zIndex: 10,
                ':hover': {
                  background: 'grey'
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
            <img
              src={URL.createObjectURL(file.mediaFile)}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              alt="preview"
              onLoad={() => URL.revokeObjectURL(file.mediaFile.name)}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TruentityDropzone;
