import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import TruentityTextField from '@/components/TruentityTextField';
import { H3, H4 } from '@/components/Typography';
import type { ExistingAdminUserType, ExistingUserType, GetExistingUsersResponse } from '@/graphql/user';
import { GET_EXISTING_USERS } from '@/graphql/user';
import type { UserFormMode } from '@/types/utils';
import { mapRolesToText, mapTitlesToText } from '@/util/adminstration';
import { useLazyQuery } from '@apollo/client';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Chip, LinearProgress, Stack } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import type { GridColDef, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';

type LookupFormValuesType = {
  firstName?: string;
  lastName?: string;
};

const lookupFormDefault: LookupFormValuesType = {
  firstName: '',
  lastName: ''
};

type AssignUserFormProps = {
  hideHeader?: boolean;
  selectedUsers: ExistingAdminUserType[];
  setSelectedUsers: (users: ExistingAdminUserType[]) => void;
  companyId?: string;
  companyName?: string;
  formMode: UserFormMode;
};

const AssignUserForm: React.FC<AssignUserFormProps> = ({
  selectedUsers,
  setSelectedUsers,
  companyName,
  companyId,
  formMode,
  hideHeader = false
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [finalExistingUsers, setFinalExistingUsers] = useState<ExistingUserType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const { getValues, control, reset: resetLookupForm, handleSubmit } = useForm<LookupFormValuesType>({ defaultValues: lookupFormDefault });

  const [getExistingUsers, { data: existingUsers, loading: loadingExistingUsers, error: errorExistingUsers }] =
    useLazyQuery<GetExistingUsersResponse>(GET_EXISTING_USERS, {
      fetchPolicy: 'cache-and-network'
    });

  const onSubmit: SubmitHandler<LookupFormValuesType> = data => fetchExistingUsers(data);

  const fetchExistingUsers = async (values: LookupFormValuesType) => {
    getExistingUsers({
      variables: {
        pageSize,
        pageNum: currentPage + 1,
        relyingPartyId: companyId,
        filterOptions: {
          firstName: values.firstName,
          lastName: values.lastName
        }
      }
    });
  };

  const handleResetForm = () => {
    resetLookupForm();
    setCurrentPage(0);
    fetchExistingUsers(lookupFormDefault);
  };

  const handleRowSelection = (e: GridRowSelectionModel) => {
    setRowSelectionModel(e);
    setSelectedUsers(
      (finalExistingUsers.filter(user => e.includes(user.id) && !user.userExists) ?? []).map(
        ({ id, firstName, lastName, email, roleType, title, userExists }) => ({
          id,
          firstName,
          lastName,
          email,
          roleType,
          title,
          userExists
        })
      )
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First Name',
        sortable: true,
        flex: 1
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        sortable: true,
        flex: 1
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        flex: 2
      },
      {
        field: 'roleType',
        headerName: 'Role',
        sortable: true,
        renderCell: ({ value }) => <Chip label={mapRolesToText(value)} color="default" variant="filled" />,
        flex: 1
      },
      {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        renderCell: ({ value }) => <Chip label={mapTitlesToText(value)} color="default" variant="filled" />,
        flex: 1
      }
    ],
    []
  );

  const renderInfoBanner = useCallback(() => {
    if (companyName) {
      if (selectedUsers.length > 0) {
        return (
          <Alert severity="info">{parse(`Selected ${selectedUsers.length} user(s) to assign to Company: <b>${companyName}</b>`)}</Alert>
        );
      } else {
        return <Alert severity="info">{parse(`Select one or more existing users to Company: <b>${companyName}</b>`)}</Alert>;
      }
    } else if (formMode === 'NEW_COMPANY') {
      return <Alert severity="error">Company not found. Please save a Company to assign users.</Alert>;
    } else {
      return <></>;
    }
  }, [companyName, selectedUsers]);

  useEffect(() => {
    fetchExistingUsers({
      firstName: getValues('firstName') ?? '',
      lastName: getValues('lastName') ?? ''
    });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (errorExistingUsers) {
      enqueueSnackbar(errorExistingUsers.message ?? 'Could not get existing users.', { variant: 'error' });
    }
  }, [errorExistingUsers]);

  useEffect(() => {
    if (finalExistingUsers?.length > 0) {
      const mergedSelectedUsers = new Map<string, ExistingAdminUserType>();
      selectedUsers.forEach(user => mergedSelectedUsers.set(user.id, user));

      finalExistingUsers.filter(user => user.userExists).forEach(user => mergedSelectedUsers.set(user.id, user));
      handleRowSelection(Array.from(mergedSelectedUsers.values()).map(user => user.id));
    }
  }, [finalExistingUsers]);

  useEffect(() => {
    const mergedAllUsers = new Map<string, ExistingUserType>();
    selectedUsers.forEach(user =>
      mergedAllUsers.set(user.id, {
        id: user.id,
        firstName: user.firstName,
        roleType: user.roleType,
        title: user.title,
        userExists: false,
        email: user.email,
        lastName: user.lastName
      })
    );

    (existingUsers?.getExistingUsers?.users ?? []).forEach(user => mergedAllUsers.set(user.id, user));
    setFinalExistingUsers(Array.from(mergedAllUsers.values()));
  }, [existingUsers]);

  return (
    <Stack
      spacing={2}
      sx={{
        minHeight: 300,
        width: '100%',
        height: 'auto'
      }}
    >
      {!hideHeader && <H3>Assign User(s)</H3>}
      <Stack
        display={'flex'}
        component={'form'}
        flexWrap={'wrap'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField
              sx={{ m: 1, width: 200 }}
              size={'medium'}
              autoFocus
              onChange={onChange}
              value={value}
              label={'First Name'}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField sx={{ m: 1, width: 200 }} size={'medium'} onChange={onChange} value={value} label={'Last Name'} />
          )}
        />
        <Button sx={{ m: 1, p: 1 }} startIcon={<SearchIcon />} label={'Search'} type="submit" size="small" />
        <Button sx={{ m: 1, p: 1 }} startIcon={<RestartAltIcon />} label={'Reset'} onClick={handleResetForm} size="small" />
      </Stack>
      <Stack sx={{ m: 1 }}>{renderInfoBanner()}</Stack>
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          flex: 1
        }}
      >
        {(getValues('firstName') ?? '').length > 0 || (getValues('lastName') ?? '').length > 0 || finalExistingUsers.length > 0 ? (
          <TruentityDataGrid
            sx={{
              minHeight: 250,
              width: '100%',
              '& .disabled-assign-user-row': {
                backgroundColor: lighten(theme.palette.primary.dark, 0.9),
                color: theme.palette.text.disabled
              },
              '& .assign-user-row': {
                color: theme.palette.text.disabled,
                '& .MuiCheckbox-root': {
                  color: theme.palette.primary.main
                }
              }
            }}
            slots={{
              loadingOverlay: LinearProgress
            }}
            checkboxSelection={true}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={handleRowSelection}
            name={'existing-users-dg'}
            columns={columns}
            rows={finalExistingUsers}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            isRowSelectable={(params: GridRowParams<ExistingUserType>) => !params.row.userExists}
            getRowClassName={params => (params.row.userExists ? 'disabled-assign-user-row' : 'assign-user-row')}
            loading={loadingExistingUsers}
            rowCount={existingUsers?.getExistingUsers?.meta?.totalCount ?? 0}
            paginationMode="server"
            keepNonExistentRowsSelected
          />
        ) : (
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
              height: 250,
              width: '100%',
              textAlign: 'center'
            }}
          >
            <H4>Search by First Name or Last Name to find existing users.</H4>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default AssignUserForm;
