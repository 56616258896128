import { PieChart } from '@/components/Charts/charts';
import { GET_TASKS_BY_TIP_SUB_TYPES, GET_TASKS_BY_WRAP_UP_STATUS } from '@/graphql/analytics';
import { TASK_WRAP_UP_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H2 } from '@/components/Typography';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';

export type BaseKpiSectionProps = {
  label: ReactNode;
  value: number;
  icon?: ReactNode;
};

export type KpiRange = {
  matches: (val) => boolean;
  backgroundColor: string;
  iconColor: string;
};

type PatientsByLocation = {
  name: string;
  totalPatients: number;
};

type PatientSparklineData = {
  index: number;
  count: number;
  importedMeds: number;
  avgMedsPerPatient: number;
};

type DashboardSectionProps = {
  title?: ReactNode;
  children: ReactNode;
};

const DashboardSection = ({ title, children }: DashboardSectionProps) => {
  return (
    <Paper component={Stack} spacing={2} sx={{ padding: '15px' }}>
      {title && <H2>{title}</H2>}
      {children}
    </Paper>
  );
};

const ReportDashboard = () => {
  const theme = useTheme();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [getTasksByTipData, { refetch: refetchTasksByTipData, data: tasksByTipData }] = useLazyQuery(GET_TASKS_BY_TIP_SUB_TYPES);
  const [getTasksByWrapUpStatusData, { refetch: refetchTasksByWrapUpStatusData, data: tasksByWrapUpStatusData }] =
    useLazyQuery(GET_TASKS_BY_WRAP_UP_STATUS);

  const [totalTIPTasksCount, setTotalTIPTasksCount] = useState(0);
  const [tasksByTipSubTypes, setTasksByTipSubTypes] = useState([]);
  const [relyingPartyAdminsBySubType, setRelyingPartyAdminsBySubType] = useState([]);
  const [selectedSubType, setSelectedSubType] = useState(null);

  const [totalTWrapUpStatusTasksCount, setTotalTWrapUpStatusTasksCount] = useState(0);
  const [tasksByWrapUpStatusTypes, setTasksByWrapUpStatusTypes] = useState([]);

  const [isAnalyticsDataLoading, setIsAnalyticsDataLoading] = useState(true);

  const today = new Date();
  const firstDatePrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastDatePrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  const [startDate, setStartDate] = useState<Date | null>(firstDatePrevMonth);
  const [endDate, setEndDate] = useState<Date | null>(lastDatePrevMonth);

  useEffect(() => {
    getTasksByTipData({
      variables: {
        filterOptions: {
          relyingPartyAdminId: currentUser?.id,
          performedOnStartDate: startDate ? moment(startDate).local().hours(0).minutes(0).seconds(0) : undefined,
          performedOnEndDate: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined
        }
      }
    })
      .then(res => {
        setIsAnalyticsDataLoading(false);
      })
      .catch(err => {
        setIsAnalyticsDataLoading(false);
      });

    getTasksByWrapUpStatusData({
      variables: {
        filterOptions: {
          relyingPartyAdminId: currentUser?.id,
          performedOnStartDate: startDate ? moment(startDate).local().hours(0).minutes(0).seconds(0) : undefined,
          performedOnEndDate: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined
        }
      }
    })
      .then(res => {
        setIsAnalyticsDataLoading(false);
      })
      .catch(err => {
        setIsAnalyticsDataLoading(false);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    if (tasksByTipData?.tipOverSubTypesTasksReporting) {
      if (tasksByTipData?.tipOverSubTypesTasksReporting?.tasksReports.length > 0) {
        var totalTIPDateCount = tasksByTipData?.tipOverSubTypesTasksReporting?.tasksReports.reduce(function (acc, obj) {
          return acc + obj.count;
        }, 0);

        setTotalTIPTasksCount(totalTIPDateCount);
        setTasksByTipSubTypes(tasksByTipData?.tipOverSubTypesTasksReporting?.tasksReports);
      }
    }
  }, [tasksByTipData]);

  useEffect(() => {
    if (tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting) {
      if (tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting?.tasksReports.length > 0) {
        var totalCount = tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting?.tasksReports.reduce(function (acc, obj) {
          return acc + obj.count;
        }, 0);

        setTotalTWrapUpStatusTasksCount(totalCount);
        setTasksByWrapUpStatusTypes(tasksByWrapUpStatusData?.usersOverWrapUpStatusTasksReporting?.tasksReports);
      }
    }
  }, [tasksByWrapUpStatusData]);

  useEffect(() => {
    if (selectedSubType?.relyingPartyAdmins) {
      setRelyingPartyAdminsBySubType(selectedSubType?.relyingPartyAdmins);
    }
  }, [selectedSubType]);

  const seletTaskSubTypeByIndex = (index: number) => {
    const selectedSubType = tasksByTipSubTypes[index];
    if (selectedSubType) {
      setSelectedSubType(selectedSubType);
    }
  };

  const onTaskSubTypeSliceSelected = (event, chartContext, config) => {
    seletTaskSubTypeByIndex(config.dataPointIndex);
  };

  return (
    <Paper component={Stack} sx={{ backgroundColor: 'transparent' }} elevation={0} spacing={2}>
      <DashboardSection title={'My Tasks'}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent={'flex-start'}>
              <TruentityDatePicker
                label="Start Date"
                fullWidth={false}
                sx={{ width: 400, marginRight: 1 }}
                value={startDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setIsAnalyticsDataLoading(true);
                  setStartDate(date as Date);
                }}
              />

              <TruentityDatePicker
                label="End Date"
                fullWidth={false}
                sx={{ width: 400 }}
                value={endDate}
                format="MMM DD, YYYY"
                onChange={date => {
                  setIsAnalyticsDataLoading(true);
                  setEndDate(date as Date);
                }}
              />

              {isAnalyticsDataLoading && (
                <Grid item sx={{ margin: 'auto 0' }}>
                  <CircularProgress size={22} color="primary" sx={{ marginLeft: 2 }} />
                </Grid>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={6}>
            {tasksByTipSubTypes && tasksByTipSubTypes.length > 0 && (
              <PieChart
                title={'Completion % by TIP Types'}
                width={480}
                series={tasksByTipSubTypes.map(v => v.count)}
                options={{
                  labels: tasksByTipSubTypes.map(v => UPDATED_SUB_TASK_TYPES.filter(type => type.value === v.subType)[0].label),
                  chart: {
                    events: {
                      dataPointSelection: onTaskSubTypeSliceSelected,
                      mounted: chartCtx => chartCtx.toggleDataPointSelection(0)
                    }
                  },
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} sx={{ margin: 'auto' }}>
            {tasksByTipSubTypes && tasksByTipSubTypes.length > 0 && (
              <TableContainer style={{ maxHeight: 300 }} component={Paper} sx={{ width: '75%' }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>TIP Type</TableCell>
                      <TableCell align="center">Count</TableCell>
                      <TableCell align="center">Percentage</TableCell>
                    </TableRow>
                  </TableHead>

                  {tasksByTipSubTypes.map(task => {
                    return (
                      <TableRow key={task.name}>
                        <TableCell>{UPDATED_SUB_TASK_TYPES.filter(type => type.value === task.subType)[0].label}</TableCell>
                        <TableCell align="center">{task.count}</TableCell>
                        <TableCell align="center">{((task.count / totalTIPTasksCount) * 100).toFixed(2)} % </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              </TableContainer>
            )}
          </Grid>

          {tasksByTipSubTypes && tasksByTipSubTypes.length === 0 && (
            <Grid item xs={12} md={12} pb={4} pt={2} sx={{ textAlign: 'center' }}>
              <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <CardContent component={Stack} justifyContent={'space-evenly'} sx={{ padding: '16px', flex: '1', minHeight: '100px' }}>
                  <H2>No TIP tasks completed for this time range</H2>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            {tasksByWrapUpStatusTypes && tasksByWrapUpStatusTypes.length > 0 && (
              <PieChart
                title={'Completion % by Resolution Types'}
                width={480}
                series={tasksByWrapUpStatusTypes.map(v => v.count)}
                options={{
                  labels: tasksByWrapUpStatusTypes.map(v => TASK_WRAP_UP_TYPES.filter(type => type.value === v.wrapUpStatus)[0].label),
                  chart: {
                    events: {
                      // not necessary for now, since no logics are written to handle when clicked
                      // dataPointSelection: onTaskWrapUpStatusSliceSelected,
                      mounted: chartCtx => chartCtx.toggleDataPointSelection(0)
                    }
                  },
                  legend: {
                    show: true,
                    position: 'bottom'
                  }
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} sx={{ margin: 'auto' }}>
            {tasksByWrapUpStatusTypes && tasksByWrapUpStatusTypes.length > 0 && (
              <TableContainer style={{ maxHeight: 300 }} component={Paper} sx={{ width: '75%' }}>
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Resolutions</TableCell>
                      <TableCell align="center">Count</TableCell>
                      <TableCell align="center">Percentage</TableCell>
                    </TableRow>
                  </TableHead>

                  {tasksByWrapUpStatusTypes.map(task => {
                    return (
                      <TableRow key={task.name}>
                        <TableCell>{TASK_WRAP_UP_TYPES.filter(type => type.value === task.wrapUpStatus)[0].label}</TableCell>
                        <TableCell align="center">{task.count}</TableCell>
                        <TableCell align="center">{((task.count / totalTWrapUpStatusTasksCount) * 100).toFixed(2)} % </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              </TableContainer>
            )}
          </Grid>

          {tasksByWrapUpStatusTypes && tasksByWrapUpStatusTypes.length === 0 && (
            <Grid item xs={12} md={12} pb={4} pt={2} sx={{ textAlign: 'center' }}>
              <Card sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <CardContent component={Stack} justifyContent={'space-evenly'} sx={{ padding: '16px', flex: '1', minHeight: '100px' }}>
                  <H2>No Resolution Type tasks completed for this time range</H2>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </DashboardSection>
    </Paper>
  );
};

export default ReportDashboard;
