import type { AccountListsResponse } from '@/graphql/account-lists';
import { ADD_ACCOUNT_TO_ACCOUNT_LIST, GET_ALL_ACCOUNTS_LIST, REMOVE_ACCOUNT_FROM_ACCOUNT_LIST } from '@/graphql/account-lists';
import { useOrganizationStarredStore } from '@/zustand/OrganizationStarredStore';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Star } from '@mui/icons-material';
import OutlinedStarIcon from '@mui/icons-material/StarBorder';

import { IconButton } from '@mui/material';
import { amber } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

type Props = {
  truentityId: string;
};

export const DEFAULT_ACCOUNT_LIST_DATA = {
  name: 'Starred',
  description: 'Starred',
  isShared: true
};

const StarredPatient = ({ truentityId }: Props) => {
  const theme = useTheme();
  const [callAccountList, { data: accountListData }] = useLazyQuery<AccountListsResponse>(GET_ALL_ACCOUNTS_LIST, {
    variables: {
      pageNum: 1,
      pageSize: 100
    },
    fetchPolicy: 'network-only'
  });

  const [addPatientToAccountList] = useMutation(ADD_ACCOUNT_TO_ACCOUNT_LIST, { onCompleted: () => callAccountList() });
  const [removePatientFromAccountList] = useMutation(REMOVE_ACCOUNT_FROM_ACCOUNT_LIST, { onCompleted: () => callAccountList() });

  const { starredList, setStarredList } = useOrganizationStarredStore();

  const onClick = async e => {
    e.stopPropagation();
    if (starredList) {
      if (isStarred()) {
        await removePatientFromAccountList({
          variables: {
            accountListId: starredList?.id,
            truentityIds: [truentityId]
          }
        });
      } else {
        await addPatientToAccountList({
          variables: {
            accountListId: starredList?.id,
            truentityIds: [truentityId]
          }
        });
      }
    }
  };

  const isStarred = () => starredList?.accounts?.find(v => v?.truentityId === truentityId);

  useEffect(() => {
    if (accountListData && accountListData.getAllAccountLists) {
      const list = accountListData.getAllAccountLists.accountLists?.find(
        accountList => accountList?.name === DEFAULT_ACCOUNT_LIST_DATA.name
      );

      if (list) return setStarredList(list);
      return setStarredList(null);
    }
  }, [accountListData]);

  return (
    <IconButton
      size={'small'}
      onClick={onClick}
      className={isStarred() ? 'selected' : ''}
      sx={{
        color: isStarred() ? amber[400] : theme.palette.grey[400],
        '&:hover': {
          color: isStarred() ? amber[600] : theme.palette.grey[600]
        }
      }}
    >
      {isStarred() ? <Star /> : <OutlinedStarIcon />}
    </IconButton>
  );
};

export default StarredPatient;
