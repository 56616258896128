import CollapsibleListGroup from '@/components/CollapsibleListGroup';
import type { NavigationSidebarMenuItem } from '@/components/SideMenuTree';
import SideMenuTree from '@/components/SideMenuTree';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import type { PatientsData } from '@/routes/Patients/patients';
import { MedicalServices } from '@/types/admin';
import { formatDateAndTime } from '@/util/format';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AppsIcon from '@mui/icons-material/AppsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import GetAppIcon from '@mui/icons-material/GetApp';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import LockIcon from '@mui/icons-material/Lock';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import NoteIcon from '@mui/icons-material/Note';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NewEncounterDialog } from '../Dialogs';

type Props = {
  patientInfo: PatientsData;
  healthAppNavItems: NavigationSidebarMenuItem[];
  medRecHistoryNavItems: NavigationSidebarMenuItem[];
  showModalMedRec: () => void;
  encounterNavItems: NavigationSidebarMenuItem[];
  medicationNavItems: NavigationSidebarMenuItem[];
  importsNavItems: NavigationSidebarMenuItem[];
  goToNewMedication: () => void;
  goToTriageMedication: () => void;
  openMedTree: boolean;
  accountCareEventsNavItems: NavigationSidebarMenuItem[];
  accountADTEventsNavItems: NavigationSidebarMenuItem[];
  reloadEncounters: () => void;
  setReloadPatientInfo: (val: boolean) => void;
  loadingNavItems: {
    [key in MedNavItems]: boolean;
  };
};

export enum MedNavItems {
  MEDICATION = 'MEDICATION',
  IMPORTS = 'IMPORTS',
  ENCOUNTERS = 'ENCOUNTERS'
}

//TODO this is not ideal but slowly decoupling it while maintaining current timeline
const MedSideMenu = ({
  patientInfo,
  healthAppNavItems,
  medRecHistoryNavItems,
  showModalMedRec,
  encounterNavItems,
  medicationNavItems,
  importsNavItems,
  goToNewMedication,
  goToTriageMedication,
  openMedTree,
  accountCareEventsNavItems,
  accountADTEventsNavItems,
  reloadEncounters,
  setReloadPatientInfo,
  loadingNavItems
}: Props) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const pathnameIncludes = (path: string) => {
    return pathname.includes(path);
  };

  const [openNewEncounterModal, setOpenNewEncounterModal] = useState(false);

  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);

  const isDevicesAvailable = patientInfo?.accountsMonitoringDevices?.length === 0;

  const { showModal } = useModal();

  const showNewEncounterModal = useCallback(() => {
    if (!id) {
      return;
    }

    setOpenNewEncounterModal(true);
    const encounterModal = showModal(NewEncounterDialog, {
      title: 'Log Encounter',
      id,
      showTasks: true,
      onComplete: () => {
        reloadEncounters();
        setOpenNewEncounterModal(false);
        encounterModal.hide();
      },
      hideDialog: () => {
        setOpenNewEncounterModal(false);
        encounterModal.hide();
      }
    });
  }, [reloadEncounters, setReloadPatientInfo, showModal, id]);

  return (
    <List component="nav">
      <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }} my={2}>
        <List subheader={<ListSubheader>Medications</ListSubheader>}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                id && navigate(`/patients/${id}/details/medications/triage/list`);
              }}
              selected={pathnameIncludes('/triage/list') || pathnameIncludes('/triage/board')}
            >
              <ListItemIcon>
                <MedicationLiquidIcon />
              </ListItemIcon>
              <ListItemText primary="Medications" />
            </ListItemButton>
          </ListItem>
          {!isRPMViewable && (
            <ListItemButton selected={pathnameIncludes('/medications/new')} onClick={goToNewMedication}>
              <ListItemIcon>
                <AddCircleOutlineRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Add New" />
            </ListItemButton>
          )}

          {/* <ListItemButton selected={pathnameIncludes('/triage/list')} onClick={goToTriageMedication}>
                <ListItemIcon>
                  <ListRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Triage" />
              </ListItemButton> */}

          <CollapsibleListGroup
            isLoading={loadingNavItems[MedNavItems.MEDICATION]}
            defaultOpen={openMedTree}
            primaryText="Medication List"
            icon={<MedicalServicesOutlinedIcon />}
          >
            <SideMenuTree index={0} items={medicationNavItems} />
          </CollapsibleListGroup>

          {!isRPMViewable && (
            <ListItemButton
              onClick={() => {
                showModalMedRec();
              }}
            >
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Lock & Certify MedRec" />
            </ListItemButton>
          )}

          <CollapsibleListGroup primaryText="MedRec History" icon={<HistoryOutlinedIcon />}>
            <SideMenuTree index={0} items={medRecHistoryNavItems} />
          </CollapsibleListGroup>
        </List>

        {isMTMViewable && (
          <List subheader={<ListSubheader>Medication Therapy Management</ListSubheader>}>
            <CollapsibleListGroup primaryText="Add Tasks" icon={<AddCircleOutlineRoundedIcon />}>
              <List>
                <Box sx={{ paddingLeft: 2 }}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        id && navigate(`/patients/${id}/details/medications/tasks/new/tip`);
                      }}
                      selected={pathnameIncludes('/tasks/new/tip')}
                    >
                      <ListItemText primary={'Add TIP Tasks'} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        id && navigate(`/patients/${id}/details/medications/tasks/new/cmr`);
                      }}
                      selected={pathnameIncludes('/tasks/new/cmr')}
                    >
                      <ListItemText primary={'Add CMR Tasks'} />
                    </ListItemButton>
                  </ListItem>

                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        id && navigate(`/patients/${id}/details/medications/tasks/new/adherence`);
                      }}
                      selected={pathnameIncludes('/tasks/new/adherence')}
                    >
                      <ListItemText primary={'Add Adherence Tasks'} />
                    </ListItemButton>
                  </ListItem>
                </Box>
              </List>
            </CollapsibleListGroup>

            <ListItemButton
              selected={pathnameIncludes('/tasks/list')}
              onClick={() => {
                id && navigate(`/patients/${id}/details/medications/tasks/list`);
              }}
            >
              <ListItemIcon>
                <ListRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="task list" />
            </ListItemButton>

            <ListItemButton selected={openNewEncounterModal} onClick={showNewEncounterModal}>
              <ListItemIcon></ListItemIcon>
              <ListItemText primary="Log an Encounter" />
            </ListItemButton>

            <CollapsibleListGroup
              isLoading={loadingNavItems[MedNavItems.ENCOUNTERS]}
              primaryText="Encounters"
              icon={<HistoryOutlinedIcon />}
            >
              <List>
                <Box sx={{ paddingLeft: 2 }}>
                  {encounterNavItems.length > 0 &&
                    encounterNavItems.map(encounterItem => {
                      return (
                        <ListItem key={encounterItem.id} disablePadding>
                          <ListItemButton
                            onClick={() => {
                              id &&
                                encounterItem?.id &&
                                navigate(`/patients/${id}/details/medications/encounters/view/${encounterItem.id}`);
                            }}
                            selected={typeof encounterItem?.id !== 'undefined' && pathnameIncludes(`/encounters/view/${encounterItem?.id}`)}
                          >
                            <ListItemText primary={formatDateAndTime(encounterItem.labelText)} />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </Box>
              </List>
            </CollapsibleListGroup>
          </List>
        )}

        {isRPMViewable && patientInfo?.rpmStatus !== null && (
          <List subheader={<ListSubheader>Remote Patient Monitoring</ListSubheader>}>
            <ListItemButton
              selected={pathnameIncludes('/rpm/readings')}
              key={'readings'}
              onClick={() => {
                id && navigate(`/patients/${id}/details/rpm/readings`);
              }}
              disabled={isDevicesAvailable}
            >
              <ListItemIcon>
                <DataThresholdingIcon />
              </ListItemIcon>
              <ListItemText primary="Readings" />
            </ListItemButton>
            <ListItemButton
              selected={pathnameIncludes('/rpm/activities')}
              key={'activities'}
              onClick={() => {
                id && navigate(`/patients/${id}/details/rpm/activities`);
              }}
            >
              <ListItemIcon>
                <PlaylistAddCheckOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Activities" />
            </ListItemButton>
            <ListItemButton
              selected={pathnameIncludes('/rpm/alerts')}
              key={'alerts'}
              onClick={() => {
                id && navigate(`/patients/${id}/details/rpm/alerts`);
              }}
            >
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="Alerts" />
            </ListItemButton>
            <ListItemButton
              selected={pathnameIncludes('/rpm/reports')}
              key={'reports'}
              onClick={() => {
                id && navigate(`/patients/${id}/details/rpm/reports`);
              }}
            >
              <ListItemIcon>
                <AssessmentOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItemButton>
            <ListItemButton
              selected={pathnameIncludes('/rpm/patient-setup')}
              key={'patient-setup'}
              onClick={() => {
                id && navigate(`/patients/${id}/details/rpm/patient-setup/care-activity/clinical-summary`);
              }}
            >
              <ListItemIcon>
                <StickyNote2Icon />
              </ListItemIcon>
              <ListItemText primary="Patient Setup" />
            </ListItemButton>
          </List>
        )}

        <List subheader={<ListSubheader>Messaging</ListSubheader>}>
          <ListItemButton
            selected={pathnameIncludes('/conversations')}
            key={2}
            onClick={() => {
              id && navigate(`/patients/${id}/details/conversations`);
            }}
          >
            <ListItemIcon>
              <ChatBubbleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Conversations" />
          </ListItemButton>
        </List>

        {isRPMViewable && (
          <List subheader={<ListSubheader>Misc</ListSubheader>}>
            <ListItemButton
              selected={pathnameIncludes('/notes')}
              key={'notes'}
              onClick={() => {
                id && navigate(`/patients/${id}/details/notes`);
              }}
            >
              <ListItemIcon>
                <NoteIcon />
              </ListItemIcon>
              <ListItemText primary="Notes" />
            </ListItemButton>
          </List>
        )}

        {isTCMViewable && (
          <List subheader={<ListSubheader>Transitional Care Management</ListSubheader>}>
            <CollapsibleListGroup defaultOpen={openMedTree} primaryText="ADT Events" icon={<MedicalServicesOutlinedIcon />}>
              <SideMenuTree index={0} items={accountCareEventsNavItems} />
            </CollapsibleListGroup>
          </List>
        )}

        <List subheader={<ListSubheader>Imports</ListSubheader>}>
          <CollapsibleListGroup defaultOpen={openMedTree} primaryText="ADT Events" icon={<MedicalServicesOutlinedIcon />}>
            <SideMenuTree index={0} items={accountADTEventsNavItems} />
          </CollapsibleListGroup>
          <ListItemButton
            key={'pioneerrx'}
            selected={pathnameIncludes('/pioneerrx')}
            onClick={() => {
              id && navigate(`/patients/${id}/details/pioneerrx`);
            }}
          >
            <ListItemIcon>
              <LabelImportantIcon />
            </ListItemIcon>
            <ListItemText primary="PioneerRx" />
          </ListItemButton>

          <CollapsibleListGroup isLoading={loadingNavItems[MedNavItems.IMPORTS]} primaryText="PioneerRx Events" icon={<GetAppIcon />}>
            <SideMenuTree index={0} items={importsNavItems} />
          </CollapsibleListGroup>
        </List>

        {!isRPMViewable && (
          <List subheader={<ListSubheader>Patient Shared</ListSubheader>}>
            <ListItemButton
              selected={pathnameIncludes('/quickupload-history')}
              key={6}
              onClick={() => {
                id && navigate(`/patients/${id}/details/shared/quickupload-history`);
              }}
            >
              <ListItemIcon>
                <CloudUploadOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Quick Uploads" />
            </ListItemButton>

            <CollapsibleListGroup primaryText="Health Apps" icon={<AppsIcon />}>
              <SideMenuTree index={0} items={healthAppNavItems} />
            </CollapsibleListGroup>
          </List>
        )}
      </Box>
    </List>
  );
};

export default MedSideMenu;
