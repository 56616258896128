import type { TruentityDateInput } from '@/types/date';
import { addTwoMonthsFromDate, toDate, toUTC } from '@/util/date';
import type { SystemCssProperties, Theme } from '@mui/system';
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import type React from 'react';

interface TruentityDateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onChange: (newStartDate: Date, newEndDate: Date) => void;
  label?: string;
  disableFuture?: boolean;
  useUtc?: boolean;
  sx?: SystemCssProperties<Theme>;
}

const TruentityDateFullRangePicker: React.FC<TruentityDateRangePickerProps> = ({
  label,
  startDate,
  endDate,
  onChange,
  disableFuture = false,
  useUtc = true,
  sx,
  ...props
}) => {
  const handleDateChange = (dateArray: TruentityDateInput[]) => {
    if (Array.isArray(dateArray) && dateArray?.length === 2) {
      const [inputStartDate, inputEndDate] = dateArray;

      const momentStartDate = toDate(inputStartDate);
      const momentEndDate = toDate(inputEndDate);

      onChange(momentStartDate, momentEndDate);
    } else {
      console.error('Invalid dateArray format.');
    }
  };

  return (
    <DateRangePicker
      {...(label && { label: label })}
      sx={{ width: '100%', ...sx }}
      maxDate={addTwoMonthsFromDate(startDate)}
      disableFuture={disableFuture}
      slots={{ field: SingleInputDateRangeField }}
      onChange={handleDateChange}
      defaultValue={useUtc ? [toUTC(startDate), toUTC(endDate)] : [moment(startDate), moment(endDate)]}
      {...props}
    />
  );
};

export default TruentityDateFullRangePicker;
