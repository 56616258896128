import { SAVE_INVOICE } from '@/graphql/invoices';
import type { SaveInvoiceData } from '@/routes/Administration/Invoices/Invoices';
import { InvoiceStatuses } from '@/routes/Administration/Invoices/Invoices';
import type { TimesheetData } from '@/routes/Administration/Timesheets/Timesheets';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDateIgnoreTZ } from '@/util/format';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DialogActions, DialogContent, Divider, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityDatePicker from '../TruentityDatePicker';
import TruentityTextField from '../TruentityTextField';
import { Body1, Body2, Small } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  selectedTimesheets: TimesheetData[];
};

const defaultValues: SaveInvoiceData = {
  hourlyRate: 33.33,
  timesheetIds: [],
  dateInvoiced: new Date().toDateString(),
  description: '',
  status: InvoiceStatuses.Pending,
  totalNumberOfHours: 0,
  totalNumberOfMinutes: 0
};

const InvoiceAddDialog = ({ title, hideDialog, selectedTimesheets, ...props }: Props) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [saveInvoice] = useMutation(SAVE_INVOICE);

  const { control, setValue, watch, reset, handleSubmit } = useForm<SaveInvoiceData>({
    defaultValues: { ...defaultValues, hourlyRate: currentUser?.hourlyRate }
  });
  const { enqueueSnackbar } = useSnackbar();

  const totalHoursWatch = watch('totalNumberOfHours');
  const totalMinsWatch = watch('totalNumberOfMinutes');
  const hourlyRateWatch = !currentUser?.hourlyRate ? 0 : watch('hourlyRate');

  const [invoiceTotal, setInvoiceTotal] = useState(0);

  useEffect(() => {
    if (selectedTimesheets?.length > 0) {
      const totalHours = selectedTimesheets.reduce((prev, current) => (prev += current.numberOfHours), 0);
      const totalMins = selectedTimesheets.reduce((prev, current) => (prev += current.numberOfMinutes), 0);

      setValue('totalNumberOfHours', totalHours);
      setValue('totalNumberOfMinutes', totalMins);
      setValue(
        'timesheetIds',
        selectedTimesheets.map(t => t.id)
      );
    }
  }, [selectedTimesheets]);

  useEffect(() => {
    const minsToHours = totalMinsWatch / 60;

    const invoiceTotalRounded = ((+totalHoursWatch + +minsToHours) * hourlyRateWatch).toFixed(2);
    setInvoiceTotal(invoiceTotalRounded);
  }, [totalHoursWatch, totalMinsWatch, hourlyRateWatch, currentUser]);

  const onSubmit: SubmitHandler<SaveInvoiceData> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: SaveInvoiceData) => {
    try {
      if (!currentUser?.hourlyRate) {
        enqueueSnackbar('Unable to save Invoice. No hourly rate set', {
          variant: 'error'
        });
        return;
      }

      const result: any = await saveInvoice({
        variables: {
          invoiceData: {
            dateInvoiced: formatDateIgnoreTZ(values.dateInvoiced, 'YYYY-MM-DD'),
            description: values.description,
            totalNumberOfHours: Number(values.totalNumberOfHours),
            totalNumberOfMinutes: Number(values.totalNumberOfMinutes),
            hourlyRate: Number(values.hourlyRate),
            timesheetIds: values.timesheetIds,
            status: values.status
          },
          invoiceId: undefined
        }
      });

      enqueueSnackbar('Invoice Saved', {
        variant: 'success'
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to save Invoice', {
        variant: 'error'
      });
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container spacing={4} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Stack>
              <Controller
                control={control}
                name="dateInvoiced"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker TextFieldProps={{ required: true }} onChange={onChange} value={value} label={'Date Invoiced'} />
                )}
              />

              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField multiline rows={4} required onChange={onChange} value={value} label={'Description'} />
                )}
              />

              <Divider />

              <Stack direction="row" sx={{ marginTop: 1 }} spacing={1}>
                <Body1 sx={{ fontWeight: 'bold' }}>Time: </Body1>
                <Body2>{totalHoursWatch} Hours, </Body2>
                <Body2>{totalMinsWatch} Minutes</Body2>
              </Stack>

              <Stack direction="row">
                <Body1 sx={{ fontWeight: 'bold' }}>Hourly Rate: $</Body1>
                <Body2>{hourlyRateWatch}</Body2>
                {!currentUser?.hourlyRate && (
                  <Small sx={{ marginLeft: 1, color: 'red' }}>(Hourly rate not set! Please Contact your Supervisor)</Small>
                )}
              </Stack>

              <Stack direction="row">
                <Body1 sx={{ fontWeight: 'bold' }}>Invoice Total: $</Body1>
                <Body2>{invoiceTotal}</Body2>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button type="submit" a11yLabel="Save" appearance="primary" disabled={!currentUser?.hourlyRate} />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default InvoiceAddDialog;
