import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import type { ReactNode } from 'react';

export type TimelineItemData = {
  left: ReactNode;
  right: ReactNode;
};

type Props = {
  data: TimelineItemData[];
};

export default function CustomTimeline({ data }: Props) {
  return (
    <Timeline>
      {data?.map((item, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent>{item.left}</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="primary" variant="outlined" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{item.right}</TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
