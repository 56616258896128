import AdministrationContext from '@/context/administrationContext';
import SideMenuContext from '@/context/sideMenuContext';
import { GET_CLIENT_STORE } from '@/graphql/administration';
import { pathnameIncludes } from '@/util/location';
import { useLazyQuery } from '@apollo/client';
import { Box, List, ListItemButton, ListItemText, ListSubheader, Paper, Stack } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import StoreDetails from './StoreDetails';

const StoreSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setClientStore, clientStore, reloadStore, setReloadStore } = useContext(AdministrationContext);
  const { setContent } = useContext(SideMenuContext);

  const [
    getClientStore,
    { loading: organizationDataLoading, data: organizationData, called: organizationDataCalled, refetch: storeDataRefetch }
  ] = useLazyQuery(GET_CLIENT_STORE);

  const SideMenuContent = useCallback(() => {
    return (
      <List component="nav">
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <List subheader={<ListSubheader>Store</ListSubheader>}>
            <ListItemButton
              selected={pathnameIncludes('/patients')}
              onClick={() => {
                navigate('patients');
              }}
            >
              <ListItemText primary="Patients" />
            </ListItemButton>

            <ListItemButton
              selected={pathnameIncludes('/tasks')}
              onClick={() => {
                navigate('tasks');
              }}
            >
              <ListItemText primary="Tasks" />
            </ListItemButton>
          </List>
        </Box>
      </List>
    );
  }, [navigate]);

  useEffect(() => {
    setContent(<SideMenuContent />);
  }, [SideMenuContent, setContent]);

  useEffect(() => {
    getClientStore({
      variables: {
        clientStoreId: id
      }
    });
  }, []);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientStore(organizationData.clientStore);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    if (reloadStore) {
      storeDataRefetch()
        .then(res => {
          setReloadStore(false);
        })
        .catch(err => {
          setReloadStore(false);
        });
    }
  }, [reloadStore]);

  return (
    <Stack direction="column" spacing={4}>
      <Paper component={Stack} p={4}>
        <StoreDetails clientStoreDetails={clientStore} />
      </Paper>

      <Paper component={Stack} p={4} sx={{ height: '100%' }}>
        <Outlet />
      </Paper>
    </Stack>
  );
};

export default StoreSection;
