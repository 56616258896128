import TriageBoard from '@/components/TriageBoard';
import { useContext, useEffect, useState } from 'react';

import DropdownMenu from '@/components/DropdownMenu';
import TriageContext from '@/context/triageContext';
import type { Medication, MedicationVerificationStatus } from '@/types/medication';
import type { Column, Entities, MedicationStatus, SortOption } from '@/types/triage-medication-types';
import { COLUMN_TITLES } from '@/types/triage-medication-types';
import { isVerified } from '@/util/medications';
import { dateCompare, localeCompare } from '@/util/sort';
import { parseIsCurrentFromColumnTitle, SORT_OPTIONS_DISPLAY_NAMES } from '@/util/triage';
import { faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import { Stack } from '@mui/material';
import type { MenuItemData } from 'mui-nested-menu';

const allSortOptions: SortOption[] = [
  { keyName: 'displayName', ascending: true, sortFunc: localeCompare('displayName', true), icon: faSortAlphaUp },
  { keyName: 'displayName', ascending: false, sortFunc: localeCompare('displayName', false), icon: faSortAlphaDown },
  { keyName: 'soldDateAt', ascending: true, sortFunc: dateCompare('soldDateAt', true), icon: faSortNumericUp },
  { keyName: 'soldDateAt', ascending: false, sortFunc: dateCompare('soldDateAt', false), icon: faSortNumericDown },
  {
    keyName: 'prescriptionWrittenDateAt',
    ascending: true,
    sortFunc: dateCompare('prescriptionWrittenDateAt', true),
    icon: faSortNumericUp
  },
  {
    keyName: 'prescriptionWrittenDateAt',
    ascending: false,
    sortFunc: dateCompare('prescriptionWrittenDateAt', false),
    icon: faSortNumericDown
  },
  { keyName: 'lastFillDateAt', ascending: true, sortFunc: dateCompare('lastFillDateAt', true), icon: faSortNumericUp },
  {
    keyName: 'lastFillDateAt',
    ascending: false,
    sortFunc: dateCompare('lastFillDateAt', false),
    icon: faSortNumericDown
  }
];

const TriageMedicationBoard = () => {
  const { triageMedications, changeMedicationStatuses, medicationSelected, verifyMedications } = useContext(TriageContext);

  const [sortedMedications, setSortedMedications] = useState<any[]>([]);

  const [medicationStatuses, setMedicationStatuses] = useState<MedicationStatus[]>([]);
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption>(allSortOptions[1]);

  useEffect(() => {
    if (medicationStatuses?.length > 0) {
      changeMedicationStatuses(medicationStatuses);
    }
  }, [medicationStatuses]);

  useEffect(() => {
    if (triageMedications?.length > 0) {
      setSortedMedications([...triageMedications].sort(selectedSortOption?.sortFunc));
    }
  }, [triageMedications, selectedSortOption]);

  const unverifyMedsThatAreNoLongerCurrent = (meds: Medication[]) => {
    const notCurrentAndVerified: MedicationVerificationStatus[] = meds
      .filter((m: Medication) => isVerified(m) && !m.isCurrent)
      .map(
        (med: Medication) =>
          ({
            id: med.id,
            isVerified: false
          } as MedicationVerificationStatus)
      );
    if (notCurrentAndVerified.length > 0) {
      verifyMedications(notCurrentAndVerified);
    }
  };

  const updateMedicationStatuses = (entities: Entities) => {
    if (!entities) {
      return;
    }

    const statuses: MedicationStatus[] = [];
    const columns: Column[] = Object.values(entities.columns);
    const tasksMap = entities.tasks;

    columns.forEach(column => {
      Object.values(column.taskIds).forEach(taskId => {
        const isCurrent = parseIsCurrentFromColumnTitle(column.title);

        tasksMap[taskId].content = {
          ...tasksMap[taskId].content,
          isCurrent
        };

        statuses.push({
          id: taskId,
          isCurrent
        });
      });
    });

    const medicationTasks = Object.values(tasksMap).map(v => v.content);
    unverifyMedsThatAreNoLongerCurrent(medicationTasks);
    setSortedMedications([...medicationTasks].sort(selectedSortOption?.sortFunc));
    setMedicationStatuses(statuses);
  };

  const sortBy = (option: SortOption) => {
    const updatedOption = {
      ...option,
      ascending: option.ascending
    };

    setSelectedSortOption(updatedOption);
  };

  const renderSortOptions = (): MenuItemData => {
    const items = allSortOptions.map(option => {
      return {
        label: getSortLabel(option),
        callback: () => sortBy(option)
      };
    });

    return {
      label: 'Sort',
      items
    };
  };

  const getSortLabel = option => {
    if (!option) {
      return 'Sort';
    }
    return `${SORT_OPTIONS_DISPLAY_NAMES[option.keyName]} ${option.ascending ? '(Ascending)' : '(Descending)'}`;
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="flex-end">
        <DropdownMenu
          menuItemsData={renderSortOptions()}
          MenuProps={{
            elevation: 3,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' }
          }}
          ButtonProps={{ variant: 'outlined', color: 'primary' }}
        />
      </Stack>
      <Stack direction="row" alignItems="start" sx={{ height: '100%', flex: '1' }}>
        <TriageBoard
          medications={sortedMedications}
          columnTitles={COLUMN_TITLES}
          onChanged={tasks => updateMedicationStatuses(tasks)}
          onMedicationSelected={medicationSelected}
        ></TriageBoard>
      </Stack>
    </Stack>
  );
};

export default TriageMedicationBoard;
