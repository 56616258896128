import { Stack } from '@mui/material';

import StoreInfo from './storeInfo';

const StoreDetails = ({ clientStoreDetails }) => {
  return (
    <Stack direction="column" spacing={4}>
      <StoreInfo clientStoreDetails={clientStoreDetails} />
    </Stack>
  );
};

export default StoreDetails;
