import type { AccountProviderType, RelyingPartyProviderType } from '@/graphql/remotePatientMonitoring';
import type { ProviderType } from '@/types/remotePatientMonitoring';
import create from 'zustand';

type ProviderSpecialty = 'Primary Care' | 'Specialist';

export type ProviderFieldsType = {
  id: string;
  firstName: string;
  lastName: string;
  phone?: string;
  npiNumber?: string;
  email?: string;
  fax?: string;
  isPrimary?: boolean;
  type?: ProviderSpecialty;
  onUpdateAccountProvidersSuccess: (success: boolean) => void;
  assignedProviders: AccountProviderType[];
};

export type ProviderStore = {
  showAssignedProviders: boolean;
  setShowAssignedProviders: (value: boolean) => void;
  providers: ProviderType[];
  setProviders: (value: ProviderType[]) => void;
  assignedProviders: AccountProviderType[];
  setAssignedProviders: (value: AccountProviderType[]) => void;
  relyingPartyProviders: ProviderType[];
  assignedRelyingPartyProviders: RelyingPartyProviderType[];
  setRelyingPartyProviders: (value: ProviderType[]) => void;
  setAssignedRelyingPartyProviders: (value: RelyingPartyProviderType[]) => void;
};

export const useProviderStore = create<ProviderStore>(set => ({
  showAssignedProviders: false,
  setShowAssignedProviders: value => set(() => ({ showAssignedProviders: value })),
  providers: [],
  setProviders: (value: ProviderType[]) => set(() => ({ providers: value })),
  assignedProviders: [],
  setAssignedProviders: (value: AccountProviderType[]) => set(() => ({ assignedProviders: value })),
  relyingPartyProviders: [],
  assignedRelyingPartyProviders: [],
  setRelyingPartyProviders: (value: ProviderType[]) => set(() => ({ relyingPartyProviders: value })),
  setAssignedRelyingPartyProviders: (value: RelyingPartyProviderType[]) => set(() => ({ assignedRelyingPartyProviders: value }))
}));
