import { Alert, AlertTitle, DialogContent, Stack } from '@mui/material';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  onReEditConfirmed: () => void;
};

const TcmReEditConfirmationDialog = ({ title, hideDialog, onReEditConfirmed, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="warning">
            <AlertTitle sx={{ margin: '0 auto' }}>
              This TCM task has been processed and completed. Do you wish to reopen it for editing ?
            </AlertTitle>
          </Alert>

          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{ alignItems: 'center', justifyContent: 'flex-end', padding: '0 0 0 0' }}
            spacing={1}
          >
            <Button type="submit" a11yLabel="Yes" appearance="primary" onClick={onReEditConfirmed} />
            <Button type="reset" a11yLabel="No" appearance="outline" onClick={hideDialog} />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default TcmReEditConfirmationDialog;
