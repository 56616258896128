import { useIdleModal } from '@/hooks/useIdleModal';
import QuickUploadStart from '@/routes/Upload/start';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const QuickUploadSection = () => {
  const navigate = useNavigate();
  const { idleTimer } = useIdleModal({
    handleLogout: () => {
      navigate('/login', { replace: true });
    },
    timeoutOverrideInSeconds: 600,
    promptTimeoutOverrideInSeconds: 60
  });

  useEffect(() => {
    idleTimer.start();
  }, []);

  return <Outlet />;
};

export { QuickUploadStart };
export default QuickUploadSection;
