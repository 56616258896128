import { COLUMN_TITLES } from '../types/triage-medication-types';

export const parseIsCurrentFromColumnTitle = title => {
  if (title === COLUMN_TITLES.current) {
    return true;
  } else if (title === COLUMN_TITLES.previous) {
    return false;
  }

  return null;
};

export const parseColumnFromIsCurrent = isCurrent => {
  switch (true) {
    case isCurrent === null:
      return COLUMN_TITLES.todo;

    case isCurrent === true:
      return COLUMN_TITLES.current;

    case isCurrent === false:
      return COLUMN_TITLES.previous;
  }

  //default to todo
  return COLUMN_TITLES.todo;
};

export const SORT_OPTIONS_DISPLAY_NAMES = {
  displayName: 'Name',
  prescriptionWrittenDateAt: 'Prescribed Date',
  lastFillDateAt: 'Fill Date',
  soldDateAt: 'Sold Date'
};
