import Alert from '@/components/Alert';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { formatDateAndTime } from '@/util/format';
import { Chip } from '@mui/material';

const ImportActivityTable = ({ rows }: any) => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeading>Transaction Id</TableHeading>
            <TableHeading>Imported On</TableHeading>
            <TableHeading>Imported By</TableHeading>
            <TableHeading align="left">Status</TableHeading>
            <TableHeading>Message</TableHeading>
          </TableRow>
        </TableHead>
        <tbody>
          {rows.length > 0 &&
            rows.map(row => (
              <TableRow key={row.id.toString()}>
                <TableCell>{row.transactionId}</TableCell>
                <TableCell> {formatDateAndTime(row.activityTime)}</TableCell>
                <TableCell> {row.relyingPartyAdmin.name}</TableCell>
                <TableCell align="left">
                  <Chip sx={{ minWidth: '150px' }} color={row.status === 'Success' ? 'success' : 'error'} label={row.status} />
                </TableCell>

                <TableCell>{row.logText}</TableCell>
              </TableRow>
            ))}
        </tbody>
      </Table>

      {rows.length === 0 && <Alert description={'No Activities yet'} status="warning" title="No Logs" />}
    </>
  );
};

export default ImportActivityTable;
