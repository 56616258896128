import { Body1, Caption2, H5 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import type { CustomGoalType, ParsedTemplateResult } from '@/types/remotePatientMonitoring';
import { RemoveCircleOutline } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Stack } from '@mui/material';
import { useCallback } from 'react';

type Props = {
  completedCustomGoals: CustomGoalType[];
  completedTemplate: ParsedTemplateResult[];
  handleEditTemplateGoal: (id: string, type?: 'CUSTOM' | 'TEMPLATE') => void;
  removeSelectedGoal: (id: string, type?: 'CUSTOM' | 'TEMPLATE') => void;
};

const CompletedGoalsSection = ({ completedTemplate, completedCustomGoals, handleEditTemplateGoal, removeSelectedGoal }: Props) => {
  const generatedCustomGoalText = useCallback(
    (customGoal: CustomGoalType) => {
      const dynamicNode = <Body1 sx={{ display: 'inline', mx: 0.5 }}>{customGoal.value}</Body1>;

      return (
        <Stack
          direction="row"
          alignItems="center"
          borderRadius={2}
          padding={1}
          my={1}
          paddingLeft={2}
          key={customGoal.id}
          bgcolor={color.grey100}
        >
          <Box width="100%">{dynamicNode}</Box>
          <Box>
            <Stack direction="row">
              <IconButton edge="end" onClick={() => handleEditTemplateGoal(customGoal.id, 'CUSTOM')}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton edge="end" onClick={() => removeSelectedGoal(customGoal.id, 'CUSTOM')}>
                <RemoveCircleOutline fontSize="small" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      );
    },
    [handleEditTemplateGoal, removeSelectedGoal]
  );

  const generateCompletedText = useCallback(
    (parsedTemplate: ParsedTemplateResult) => {
      const { template } = parsedTemplate;
      const templateParts = template.templateText.split(/(<<[^>]+>>)/g);

      const dynamicNode = templateParts.map((part, index) => {
        const match = part.match(/<<(.+?)>>/);
        if (match) {
          const placeholder = match[1];
          const value = parsedTemplate.inputs.find(input => input.key === placeholder)?.value || '';

          return (
            <Body1 key={index} sx={{ display: 'inline', mx: 0.5 }}>
              {' '}
              <b>{value}</b>{' '}
            </Body1>
          );
        }

        return (
          <Body1 key={index} sx={{ display: 'inline' }}>
            {part}
          </Body1>
        );
      });

      return (
        <Stack direction="row" alignItems="center" borderRadius={2} padding={1} paddingLeft={2} key={template.id} bgcolor={color.grey100}>
          <Box>{dynamicNode}</Box>
          <Box>
            <Stack direction="row">
              <IconButton edge="end" onClick={() => handleEditTemplateGoal(template.id)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton edge="end" onClick={() => removeSelectedGoal(template.id)}>
                <RemoveCircleOutline fontSize="small" />
              </IconButton>
            </Stack>
          </Box>
        </Stack>
      );
    },
    [handleEditTemplateGoal, removeSelectedGoal]
  );

  return (
    <Stack spacing={2} py={2}>
      <Box sx={{ p: 1, border: '1px solid', borderColor: theme => theme.palette.divider, backgroundColor: color.grey50, borderRadius: 1 }}>
        <H5 mb={1}>Goal List</H5>
        {completedTemplate.length > 0 || completedCustomGoals.length > 0 ? (
          <>
            <Body1>{completedTemplate.map(template => generateCompletedText(template))}</Body1>
            <Body1>{completedCustomGoals.map(template => generatedCustomGoalText(template))}</Body1>
          </>
        ) : (
          <Box py={3} textAlign="center">
            <Caption2>Empty List</Caption2>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default CompletedGoalsSection;
