import { MedicalServiceType } from '@/types/graphql';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

const useShowMedicalServices = (value: string): boolean => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const medicalServices = currentUser?.relyingParty?.medicalServices;

  const initialValues = (): boolean =>
    Boolean(medicalServices && medicalServices.some((service: MedicalServiceType) => service.shortName === value));
  const [exists, setExists] = useState<boolean | (() => boolean)>(initialValues);

  useEffect(() => {
    if (medicalServices) {
      setExists(() => medicalServices.some((service: MedicalServiceType) => service.shortName === value));
    }
  }, [medicalServices, value]);

  return exists instanceof Function ? exists() : exists;
};

export default useShowMedicalServices;
