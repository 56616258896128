import Button from '@/components/Button';
import Pagination from '@/components/Pagination';
import { GET_ACCOUNT_COMMENTS } from '@/graphql/account';
import { ADD_COMMENT, ADD_COMMENT_FOR_MEDICATION } from '@/graphql/comment';
import { GET_COMMENTS_FOR_MEDICATIONS } from '@/graphql/medication';
import useToken from '@/hooks/useToken';
import type { AccountComment, GetAccountCommentsResponse } from '@/types/accountProfile';
import { Role } from '@/types/admin';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Fab, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import type { CommentDialogValues } from '../Dialogs/CreateCommentDialog';
import CreateCommentDialog from '../Dialogs/CreateCommentDialog';
import CommentsTable from './CommentsTable';

type Props = {
  patientId?: string | null;
  medicationId?: string | null;
  collapsible?: boolean;
};

export const COMMENTS_PER_PAGE = 5;

const Comments = ({ patientId, medicationId, collapsible = true }: Props) => {
  const [showCommentsTable, setShow] = useState(!collapsible);
  const [comments, setComments] = useState<AccountComment[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: commentsResultData,
    refetch: refetchComments,
    called: accountCommentsCalled,
    loading: accountCommentsLoading
  } = useQuery<GetAccountCommentsResponse>(GET_ACCOUNT_COMMENTS, {
    variables: {
      truentityId: patientId,
      pageNum: currentPage,
      pageSize: COMMENTS_PER_PAGE
    },
    skip: !patientId
  });

  const [lastCommentId, setLastCommentId] = useState<number | null>(null);
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const [getCommentsForMedication, commentsResultForMedicationData] = useLazyQuery(GET_COMMENTS_FOR_MEDICATIONS);

  const [addCommentToAccount] = useMutation(ADD_COMMENT);
  const [addCommentToAccountMedication] = useMutation(ADD_COMMENT_FOR_MEDICATION);

  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const handleShowTable = event => {
    event.preventDefault();
    setShow(value => !value);
  };

  const handleShowModal = event => {
    event.preventDefault();
    showCommentModal();
  };

  const onCommentsDelete = async () => {
    const newPageNum = comments.length === 1 && currentPage > 1 ? currentPage - 1 : currentPage;
    await refetchComments({
      truentityId: patientId,
      pageNum: newPageNum,
      pageSize: COMMENTS_PER_PAGE
    });
    setCurrentPage(newPageNum);
  };

  const isDataLoaded = () =>
    (commentsResultData &&
      accountCommentsCalled &&
      !accountCommentsLoading &&
      commentsResultData &&
      commentsResultData.commentsByAccount.comments) ||
    (commentsResultForMedicationData &&
      commentsResultForMedicationData.called &&
      !commentsResultForMedicationData.loading &&
      commentsResultForMedicationData.data &&
      commentsResultForMedicationData.data.commentsByAccountMedication.comments);

  const isThereData = () =>
    (isDataLoaded() && commentsResultData && commentsResultData.commentsByAccount.meta.totalCount > 0) ||
    (isDataLoaded() &&
      commentsResultForMedicationData.data &&
      commentsResultForMedicationData.data.commentsByAccountMedication.meta.totalCount > 0);

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  const refetch = useCallback(() => {
    if (refetchComments) {
      refetchComments().catch(err => console.error(err));
    }
    if (commentsResultForMedicationData.refetch) {
      commentsResultForMedicationData.refetch().catch(err => console.error(err));
    }
  }, [commentsResultForMedicationData, refetchComments]);

  const fetchCommentsForMedications = useCallback(() => {
    if (medicationId) {
      getCommentsForMedication({
        variables: {
          accountMedicationId: medicationId,
          pageNum: currentPage,
          pageSize: COMMENTS_PER_PAGE
        }
      }).catch(err => console.error(err));
    }
  }, [currentPage, getCommentsForMedication, medicationId]);

  const submitComment = (values: CommentDialogValues, idType: string, addCommentFunction: any) => {
    addCommentFunction({
      variables: {
        comment: values.comment,
        [idType]: idType === 'truentityId' ? patientId : medicationId
      }
    })
      .then(({ data }) => {
        enqueueSnackbar('Comment Saved', {
          variant: 'success'
        });

        const id = idType === 'truentityId' ? data.addCommentToAccount?.comment?.id : data.addCommentToAccountMedication?.comment?.id;
        if (id) {
          setLastCommentId(id);
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar('Unable to add Comment', {
          variant: 'error'
        });
      });
  };

  const showCommentModal = () => {
    const modal = showModal(CreateCommentDialog, {
      title: 'Add Comment',
      uiLabels: {
        commentInput: 'Comment',
        submitButton: 'Save',
        cancelButton: 'Cancel'
      },
      commentRequired: true,
      onSubmitted: (values: CommentDialogValues) => {
        if (patientId) {
          submitComment(values, 'truentityId', addCommentToAccount);
        }
        if (medicationId) {
          submitComment(values, 'accountMedicationId', addCommentToAccountMedication);
        }
        modal.hide();
      },
      onCancelled: () => {
        modal.hide();
      },
      hideDialog: () => modal.hide()
    });
  };

  useEffect(() => {
    if (commentsResultData && commentsResultData) {
      setTotalCount(commentsResultData.commentsByAccount.meta.totalCount);
      setTotalPages(commentsResultData.commentsByAccount.meta.totalPages);
    }
    if (commentsResultForMedicationData && commentsResultForMedicationData.data) {
      setTotalCount(commentsResultForMedicationData.data.commentsByAccountMedication.meta.totalCount);
      setTotalPages(commentsResultForMedicationData.data.commentsByAccountMedication.meta.totalPages);
    }
  }, [commentsResultData, commentsResultForMedicationData]);

  useEffect(() => {
    fetchCommentsForMedications();
  }, [fetchCommentsForMedications, medicationId]);

  useEffect(() => {
    if (commentsResultData && commentsResultData) {
      setComments(commentsResultData.commentsByAccount.comments);
    }
    if (commentsResultForMedicationData && commentsResultForMedicationData.data) {
      setComments(commentsResultForMedicationData.data.commentsByAccountMedication.comments);
    }
  }, [commentsResultData, commentsResultForMedicationData]);

  useEffect(() => {
    if (patientId) {
      refetchComments().catch(err => console.error(err));
    }
    fetchCommentsForMedications();
  }, [currentPage, fetchCommentsForMedications, patientId, refetchComments]);

  useEffect(() => {
    if (lastCommentId) {
      refetch();
    }
  }, [lastCommentId, refetch]);

  return (
    <Stack alignItems={'start'} spacing={4}>
      {collapsible && (
        <Button
          a11yLabel="View All Comments"
          label="View All Comments"
          appearance="plainBlue"
          iconLeft={showCommentsTable ? faChevronUp : faChevronDown}
          onClick={handleShowTable}
        />
      )}

      {showCommentsTable && <CommentsTable truentityId={patientId} isDeletable rows={comments} onCommentsDelete={onCommentsDelete} />}

      {showCommentsTable && isThereData() && (
        <Pagination
          currentPage={currentPage}
          totalRecords={totalCount}
          pageLimit={totalPages}
          pageNeighbours={2}
          onPageChanged={updateData}
        />
      )}

      <Stack sx={{ width: '100%' }} direction="row" justifyContent={'flex-end'} alignItems={'center'}>
        <Fab
          sx={{ position: 'absolute', bottom: '16px', right: '16px' }}
          color="primary"
          aria-label="add-comment"
          onClick={handleShowModal}
          disabled={isProviderAdmin}
        >
          <AddCommentIcon />
        </Fab>
      </Stack>
    </Stack>
  );
};

export default Comments;
