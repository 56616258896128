import { color } from '@/styles/assets/colors';
import CloseIcon from '@mui/icons-material/Close';
import type { SnackbarCloseReason, SnackbarOrigin } from '@mui/material';
import { Box, IconButton, Snackbar, Stack } from '@mui/material';
import type React from 'react';

type CustomSnackbarProps = {
  open: boolean;
  message: React.ReactNode;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
  handleExited: () => void;
  anchorOrigin?: SnackbarOrigin;
  backgroundColor?: string;
  borderColor?: string;
  icon?: React.ReactNode;
};

const CustomSnackbar = ({
  open,
  message,
  handleClose,
  handleExited,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  backgroundColor = color.truentityCyan[500],
  borderColor = color.truentityCyan[900],
  icon = null
}: CustomSnackbarProps) => {
  const handleTransition = () => handleExited();

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={handleClose}
      sx={{ width: 'max-content', maxWidth: '90%', minWidth: '50%' }}
      TransitionProps={{
        onExited: handleTransition
      }}
    >
      <Box
        sx={{
          background: backgroundColor,
          width: '100%',
          height: '100%',
          p: 2,
          borderRadius: 3,
          border: 1,
          borderColor: borderColor
        }}
      >
        <Stack direction="row" gap={5} alignItems="center">
          {icon && <Box>{icon}</Box>}
          <Box flexGrow={1}>{message}</Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          </Box>
        </Stack>
      </Box>
    </Snackbar>
  );
};

export default CustomSnackbar;
