import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ChipColumn from '@/components/ChipLabel/ChipColumn';
import ChipsList from '@/components/ChipsList';
import DropdownMenu from '@/components/DropdownMenu';
import { Body1, Caption, H2, Label } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import { UPDATE_RPM_ACCOUNT_MONITORED_CONDITION } from '@/graphql/remotePatientMonitoring';
import styled from '@/styles';
import { theme } from '@/styles/mui-theme';
import {
  AccountRpmMonitoredCondition,
  ActivityLogType,
  RpmApprovalStatusTypes,
  RpmStatusTypes,
  type AccountsMonitoringDeviceType
} from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { formatRpmStatusString, getConditionStrings, getDiagnosisUsingConditions, mapRpmSignOffStatus } from '@/util/rpm';
import { useMutation } from '@apollo/client';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Box, Chip, Popover, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import { useContext, useMemo, useState } from 'react';
import type { ButtonMenuItemTypes } from './Components/RpmWorkflow/RpmInfoTabsButtonMenu';
import RpmInfoTabsButtonMenu from './Components/RpmWorkflow/RpmInfoTabsButtonMenu';

const maxNamesToShow = 2;

type Props = {
  children?: ReactNode;
  devices: AccountsMonitoringDeviceType[] | [];
};

const ButtonMoreContainer = styled('div', {
  margin: 'auto !important',
  '& button': {
    marginLeft: '8px'
  },
  '.MuiButton-iconSizeSmall': {
    margin: '0px !important'
  }
});

const RpmWorkflowToolbar = ({ children, devices }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);

  const [devicesSuppliedAnchorEl, setDevicesSuppliedAnchorEl] = useState(null);
  const [showMoreAssignedDevices, setShowMoreAssignedDevices] = useState(false);
  const [type, setType] = useState<ActivityLogType>(ActivityLogType.COMMUNICATION_SUMMARY);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  const [updateAccountMonitoredCondition] = useMutation(UPDATE_RPM_ACCOUNT_MONITORED_CONDITION, {
    onCompleted(data) {
      setReloadPatientInfo(true);
      enqueueSnackbar(data?.updateRpmAccountMonitoredCondition?.message, { variant: 'success' });
    },
    onError(err) {
      if (err.graphQLErrors && err.graphQLErrors[0]) {
        enqueueSnackbar(err.graphQLErrors[0].message, { variant: 'error' });
      } else {
        enqueueSnackbar(
          'Unable to update patient conditions due to a system error. Please try again or contact support if the issue persists.',
          { variant: 'error' }
        );
      }
    }
  });

  const buttonMenuItems: ButtonMenuItemTypes[] = useMemo(
    () => [
      {
        buttonToolTipText: 'Communication Summery',
        buttonIcon: StickyNote2Icon,
        buttonAction: () => openActivityDrawer(ActivityLogType.COMMUNICATION_SUMMARY)
      }
    ],
    []
  );

  const openActivityDrawer = (type: ActivityLogType) => {
    setType(type);
    setIsActivityDrawerOpen(true);
  };
  const closeActivityDrawer = () => setIsActivityDrawerOpen(false);

  const monitoredCondition = useMemo(() => {
    const conditions = patientInfo?.accountRpmMonitoredCondition;
    return getConditionStrings(conditions);
  }, [patientInfo?.accountRpmMonitoredCondition]);

  const handleAssignedDevicesPopoverClick = event => {
    setDevicesSuppliedAnchorEl(event.currentTarget);
    setShowMoreAssignedDevices(true);
  };

  const handleAssignedDevicesPopoverClose = () => {
    setDevicesSuppliedAnchorEl(null);
    setShowMoreAssignedDevices(false);
  };

  const handleMonitoringCondition = (condition: AccountRpmMonitoredCondition) => {
    updateAccountMonitoredCondition({
      variables: {
        truentityId: patientInfo?.truentityId,
        conditions: getDiagnosisUsingConditions(condition)
      }
    });
  };

  return (
    <>
      <Stack sx={{ background: theme.palette.background.paper }} p={4} borderRadius={4} spacing={2}>
        {patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED && (
          <Box flex={1}>
            <Alert
              status={'error'}
              description={`${getAccountUserFullName(patientInfo?.user)} unenrolled from the program on ${formatDate(
                patientInfo?.rpmUnenrolledAt
              )}`}
            />
          </Box>
        )}
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent="space-between">
          <H2>Patient Setup</H2>
          <Stack>
            <Stack direction="row" columnGap={2}>
              {patientInfo?.rpmStatus && (
                <ChipColumn
                  label={<Label>Status</Label>}
                  color={patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED ? 'error' : 'primary'}
                  value={
                    <Body1 color={patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED ? 'error' : 'default'}>
                      {formatRpmStatusString(patientInfo?.rpmStatus) +
                        '' +
                        (patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED
                          ? ' on ' + formatDate(patientInfo?.rpmUnenrolledAt)
                          : patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED
                          ? ' on ' + formatDate(patientInfo?.rpmEnrolledAt)
                          : '')}
                    </Body1>
                  }
                />
              )}
              {patientInfo?.rpmApprovalStatus && (
                <ChipColumn
                  label={<Label>Approval Status</Label>}
                  value={<Body1>{formatRpmStatusString(patientInfo?.rpmApprovalStatus)}</Body1>}
                  secondaryValue={
                    patientInfo?.rpmApprovalStatus ? (
                      <Box
                        sx={{
                          margin: 1
                        }}
                      >
                        <Caption textTransform="none" color="primary">
                          {mapRpmSignOffStatus({
                            rpmSignOffStatus: patientInfo?.rpmSignOffStatus
                          }) ?? ''}
                        </Caption>
                      </Box>
                    ) : undefined
                  }
                />
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack gap={1}>
            <Stack direction="row" spacing={1} alignItems={'center'}>
              <Body1>Reason For Visit:</Body1>
              <DropdownMenu
                menuItemsData={{
                  label: monitoredCondition,
                  items: [
                    {
                      label: AccountRpmMonitoredCondition.DIABETES,
                      callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.DIABETES)
                    },
                    {
                      label: AccountRpmMonitoredCondition.HYPERTENSION,
                      callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.HYPERTENSION)
                    },
                    {
                      label: AccountRpmMonitoredCondition.DIABETES_AND_HYPERTENSION,
                      callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.DIABETES_AND_HYPERTENSION)
                    },
                    {
                      label: AccountRpmMonitoredCondition.UNASSIGNED,
                      callback: () => handleMonitoringCondition(AccountRpmMonitoredCondition.UNASSIGNED)
                    }
                  ]
                }}
                ButtonProps={{
                  variant: 'outlined',
                  size: 'small',
                  color: 'error',
                  disabled: patientInfo?.rpmApprovalStatus === RpmApprovalStatusTypes.APPROVED,
                  sx: { minWidth: '100%', borderRadius: 8, padding: '3px 10px' }
                }}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems={'center'}>
              <Body1>Devices Supplied:</Body1>
              {devices?.length > 0 ? (
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  <Stack direction="row" spacing={1}>
                    {devices.map(
                      ({ monitoringDevice }, index) =>
                        index < maxNamesToShow && (
                          <div key={`${monitoringDevice?.name}-${index}`}>
                            {monitoringDevice?.name && (
                              <Chip size="medium" variant={'outlined'} label={monitoringDevice?.name} style={{ marginRight: '0px' }} />
                            )}
                          </div>
                        )
                    )}

                    {devices.length > maxNamesToShow && (
                      <ButtonMoreContainer>
                        <Button
                          variant="text"
                          label={`${devices.length - maxNamesToShow}+`}
                          onClick={handleAssignedDevicesPopoverClick}
                          endIcon={showMoreAssignedDevices ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        />
                      </ButtonMoreContainer>
                    )}
                  </Stack>
                  <Popover open={showMoreAssignedDevices} anchorEl={devicesSuppliedAnchorEl} onClose={handleAssignedDevicesPopoverClose}>
                    <Stack direction="row" spacing={1} sx={{ p: 2 }}>
                      <ChipsList
                        items={devices?.map(({ monitoringDevice }) => monitoringDevice?.name)}
                        maxItemsToShow={maxNamesToShow}
                        sx={{ marginRight: '4px' }}
                      />
                      <Button variant="text" onClick={handleAssignedDevicesPopoverClose}>
                        Close
                      </Button>
                    </Stack>
                  </Popover>
                </Stack>
              ) : (
                <Chip size="medium" variant={'outlined'} label={'Not Assigned'} style={{ marginRight: '4px' }} />
              )}
            </Stack>
          </Stack>
          <Stack spacing={2} alignSelf="baseline">
            {children}
          </Stack>
        </Stack>
        <Box overflow="hidden" position="absolute" bottom="10px" right="10px">
          <RpmInfoTabsButtonMenu buttonMenuItems={buttonMenuItems} />
        </Box>
      </Stack>
      <ActivityDrawer isOpen={isActivityDrawerOpen} onClose={closeActivityDrawer} type={type} />
    </>
  );
};

export default RpmWorkflowToolbar;
