import { List, ListItem, ListItemText, Popover, Stack } from '@mui/material';

type Props = {
  open: boolean;
  anchorEl: null | HTMLElement;
  onClose: () => void;
  contents: string[];
  popperTransitionExited: () => void;
};

const ContactInfoPopover = ({ open, anchorEl, onClose, contents, popperTransitionExited }: Props) => {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none'
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      disableRestoreFocus
      onTransitionExited={popperTransitionExited}
    >
      <Stack p={1} rowGap={1} bgcolor="white" sx={{ maxWidth: '300px' }}>
        {contents?.length > 0 && (
          <List disablePadding>
            {contents?.map((value, index) => (
              <ListItem disablePadding>
                <ListItemText primary={value} key={index} />
              </ListItem>
            ))}
          </List>
        )}
      </Stack>
    </Popover>
  );
};

export default ContactInfoPopover;
