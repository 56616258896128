import TruentityTextField from '@/components/TruentityTextField';
import { H3 } from '@/components/Typography';
import { Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Stack } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import type React from 'react';
import type { BaseSyntheticEvent } from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

export type AddCompanyFormValuesType = {
  companyName: string;
  rpm: boolean;
  mtm: boolean;
  tcm: boolean;
};

export const addCompanyFormDefault: AddCompanyFormValuesType = {
  companyName: '',
  rpm: false,
  mtm: false,
  tcm: false
};

export type AddCompanyFormProps = {
  control: Control<AddCompanyFormValuesType>;
  onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
};

export const AddCompanyForm: React.FC<AddCompanyFormProps> = ({ control, onSubmit }) => {
  return (
    <Stack component={'form'} spacing={2} p={3} onSubmit={onSubmit}>
      <H3>Company Details</H3>
      <Controller
        control={control}
        name="companyName"
        rules={{ required: 'Company Name is required' }}
        render={({ field: { onChange, value } }) => (
          <TruentityTextField fullWidth={true} maxLength={128} required onChange={onChange} value={value} label={'Company Name'} />
        )}
      />

      <Grid container m={1}>
        <Grid item xs={6}>
          <FormControl required component={'fieldset'}>
            <FormLabel component="legend">Medical Services</FormLabel>
            <FormGroup>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="RPM" />
                )}
                name={'rpm'}
                control={control}
              />

              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="MTM" />
                )}
                name={'mtm'}
                control={control}
              />

              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="TCM" />
                )}
                name={'tcm'}
                control={control}
              />
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AddCompanyForm;
