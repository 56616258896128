import type { BoxProps } from '@mui/material';
import { Stack } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { Label } from './Typography';

type Props = BoxProps & {
  htmlFor?: string;
  label?: string;
  LabelProps?: BoxProps;
  children?: ReactNode;
};

const LabelGroup: FC<Props> = ({ children, htmlFor, label, ...props }) => {
  const { LabelProps, ...otherProps } = props;

  return (
    <Stack sx={{ marginBottom: props.marginBottom ?? '1.25em' }} spacing={1} {...otherProps}>
      {label && (
        <Label htmlFor={htmlFor} {...LabelProps}>
          {label}
        </Label>
      )}
      {children}
    </Stack>
  );
};

export default LabelGroup;
