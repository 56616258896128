import { FilterType } from '@/components/Dialogs/AdtFilterDialog';
import type { MuiColor } from '@/types/mui';
import moment from 'moment';

export enum FilterModalTypes {
  AdtFilter = 'AdtFilter',
  AdtFilterTemplate = 'AdtFilterTemplate'
}

export enum TaskStatuses {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Processed = 'Processed',
  Missed = 'Missed'
}

//TODO:  This needs to be enforced
export enum AccountCareEventStatuses {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Processed = 'Processed',
  Missed = 'Missed'
}

export enum EventTypeFilter {
  All = 'all',
  Admit = 'admit',
  Discharge = 'discharge',
  Transfer = 'transfer'
}

//TODO: this needs to be enforced and clarified
export type AccountCareEventStatus =
  | AccountCareEventStatuses.Pending
  | AccountCareEventStatuses.InProgress
  | AccountCareEventStatuses.Processed
  | AccountCareEventStatuses.Missed;

export const EventTypeColorMap = new Map<string, MuiColor>([
  [EventTypeFilter.All, 'default'],
  [EventTypeFilter.Admit, 'error'],
  [EventTypeFilter.Discharge, 'success'],
  [EventTypeFilter.Transfer, 'warning']
]);

export const getBadgeCount = (
  tabLabel: string,
  filteredTaskStatusesPendingCount: number,
  filteredTaskStatusesInProgressCount: number,
  filteredTaskStatusesProcessedCount: number,
  filteredTaskStatusesMissedCount: number
) => {
  switch (tabLabel) {
    case 'Pending':
      return filteredTaskStatusesPendingCount ? filteredTaskStatusesPendingCount : '0';
    case 'In Progress':
      return filteredTaskStatusesInProgressCount ? filteredTaskStatusesInProgressCount : '0';
    case 'Processed':
      return filteredTaskStatusesProcessedCount ? filteredTaskStatusesProcessedCount : '0';
    case 'Missed':
      return filteredTaskStatusesMissedCount ? filteredTaskStatusesMissedCount : '0';
  }
  return '0';
};

export const onFiltersAppliedTemp = filters => {
  let selectedEvent = null;
  let readmissionrisk = null;

  let initialCommsDueDateTo = null;
  let initialCommsDueDateFrom = null;

  let admissionReason = null;

  let dischargeDateTo = null;
  let dischargeDateFrom = null;

  let admissionDateTo = null;
  let admissionDateFrom = null;

  let dischargedDisposition = null;
  let dischargingPhysician = null;

  let selectedFilters: string[] = [];

  if (filters.length > 0) {
    filters.map(filter => {
      if (filter.filterType) {
        if (filter.filterType === FilterType.Event && filter.eventType) {
          selectedEvent = filter.eventType;
          selectedFilters.push(`Event Type is : ${selectedEvent}`);
        }

        if (filter.filterType === FilterType.ReadmissionRisk && filter.eventType) {
          var isTrueSet = filter.eventType === 'true' ? true : false;
          readmissionrisk = isTrueSet;

          selectedFilters.push(`Readmission Risk Flag : ${readmissionrisk}`);
        }

        if (filter.filterType === FilterType.InitialCommunicationDueBy && filter.eventType.length === 2) {
          initialCommsDueDateTo = moment(filter.eventType[0]).format('YYYY-MM-DD');
          initialCommsDueDateFrom = moment(filter.eventType[1]).format('YYYY-MM-DD');

          selectedFilters.push(`Initial Communication Due by : ${initialCommsDueDateTo} - ${initialCommsDueDateFrom}`);
        }

        if (filter.filterType === FilterType.AdmissionReason && filter.eventType) {
          admissionReason = filter.eventType;
          selectedFilters.push(`Admission Reason Is : ${admissionReason}`);
        }

        if (filter.filterType === FilterType.DischargeDate && filter.eventType.length === 2) {
          dischargeDateTo = moment(filter.eventType[0]).format('YYYY-MM-DD');
          dischargeDateFrom = moment(filter.eventType[1]).format('YYYY-MM-DD');

          selectedFilters.push(`Discharge Date is : ${dischargeDateTo} - ${dischargeDateFrom}`);
        }

        if (filter.filterType === FilterType.AdmissionDate) {
          admissionDateTo = moment(filter.eventType[0]).format('YYYY-MM-DD');
          admissionDateFrom = moment(filter.eventType[1]).format('YYYY-MM-DD');
          selectedFilters.push(`Admission Date Is : ${admissionDateTo}`);
        }

        if (filter.filterType === FilterType.DischargedDisposition) {
          dischargedDisposition = filter.eventType;
          selectedFilters.push(`Discharged Disposition Is : ${dischargedDisposition}`);
        }

        if (filter.filterType === FilterType.DischargingPhysician) {
          dischargingPhysician = filter.eventType;
          selectedFilters.push(`Discharging Physician Is : ${dischargingPhysician}`);
        }
      }
    });
  }

  return {
    selectedEvent,
    readmissionrisk,
    initialCommsDueDateTo,
    initialCommsDueDateFrom,
    admissionReason,
    dischargeDateTo,
    dischargeDateFrom,
    admissionDateTo,
    admissionDateFrom,
    dischargedDisposition,
    dischargingPhysician,
    selectedFilters
  };
};
