export type KeyRenamed<T, K extends keyof T, R extends PropertyKey> = Omit<T, K> &
  (undefined extends T[K] ? { [P in R]?: T[K] } : { [P in R]: T[K] });

export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

export enum RequestStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING'
}

export enum UserFormMode {
  NEW_COMPANY = 'NEW_COMPANY',
  EXISTING_COMPANY = 'EXISTING_COMPANY'
}
