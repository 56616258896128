import Alert from '@/components/Alert';
import Button from '@/components/Button';
import TruentityLogo from '@/components/TruentityLogo';
import { Body1, H4, H6 } from '@/components/Typography';
import { LOGGED_USER } from '@/graphql/account';
import type { ConfirmSwitchCompanyResponseType, SwitchCompanyResponseType } from '@/graphql/company';
import { CONFIRM_SWITCH_COMPANY, SWITCH_COMPANY } from '@/graphql/company';
import useSettings from '@/hooks/useSettings';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { AuthErrorCodes } from '@/types/error';
import type { LoggedUser, LoggedUserAccountData } from '@/types/graphql';
import { currentLoggedRelyingParty, currentLoggedUserVar, loggedUserData } from '@/util/apollo/cache';
import client from '@/util/apollo/client';
import { useSwitchCompanyStore } from '@/zustand/SwitchCompanyStore';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, CircularProgress, Paper, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

type FormValues = { otpCode: string };

const defaultValues: FormValues = {
  otpCode: ''
};

export enum SwitchCompanyStatus {
  LOADING,
  CONFIRM_OTP,
  LOAD_USER,
  SUCCESS,
  ERROR
}

const SwitchCompany = () => {
  const { newCompany, currentCompany, setCurrentCompany, setNewCompany } = useSwitchCompanyStore();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { parseSettingsJsonString } = useSettings();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [getLoggedUser] = useLazyQuery(LOGGED_USER);
  const { setTokenAndRefreshToken, setRelayingPartyId, setRoleType, handleLogout } = useToken();

  const [otpId, setOtpId] = useState<string | null>(null);
  const [status, setStatus] = useState<SwitchCompanyStatus>(SwitchCompanyStatus.LOADING);

  const [switchCompany] = useMutation<SwitchCompanyResponseType>(SWITCH_COMPANY);
  const [confirmSwitchCompany] = useMutation<ConfirmSwitchCompanyResponseType>(CONFIRM_SWITCH_COMPANY);

  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const otpCode = values.otpCode.trim();
      if (otpCode?.length !== 6) {
        showError('Invalid OTP code provided');
        return;
      }

      const confirmSwitchCompanyResult = await confirmSwitchCompany({
        variables: {
          otpId: otpId,
          otpCode: otpCode
        }
      });

      if (
        confirmSwitchCompanyResult.data?.confirmSwitchRelyingParty?.relyingPartyAdmin &&
        confirmSwitchCompanyResult.data?.confirmSwitchRelyingParty?.accessToken &&
        confirmSwitchCompanyResult.data?.confirmSwitchRelyingParty?.refreshToken
      ) {
        await initializeLocalStorage({
          accessToken: confirmSwitchCompanyResult.data.confirmSwitchRelyingParty.accessToken,
          refreshToken: confirmSwitchCompanyResult.data.confirmSwitchRelyingParty.refreshToken,
          relyingPartyAdmin: confirmSwitchCompanyResult.data.confirmSwitchRelyingParty.relyingPartyAdmin
        });
      } else {
        switch (confirmSwitchCompanyResult?.errors?.[0]?.extensions?.code) {
          case AuthErrorCodes.INVALID_CODE:
            showError(confirmSwitchCompanyResult?.errors?.[0]?.message);
            break;
          default:
            showError(
              `${
                confirmSwitchCompanyResult?.errors?.[0]?.message ?? 'Something went wrong. Could not confirm OTP for switching companies'
              }. Please login again.`
            );
            handleLogout();
            break;
        }
      }
    } catch (err: any) {
      showError(`${err?.message ?? 'Something went wrong. Could not confirm OTP for switching companies'}. Please login again.`);
      handleLogout();
    }
  };

  const showError = useCallback(
    (errorMessage: string) => {
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    },
    [enqueueSnackbar]
  );

  const handleSwitchCompanyError = useCallback(
    (error: string) => {
      setStatus(SwitchCompanyStatus.ERROR);
      showError(error);
    },
    [showError]
  );

  const callSwitchCompany = async (companyId: string) => {
    try {
      const switchCompanyResult = await switchCompany({
        variables: {
          relyingPartyId: companyId
        }
      });

      if (switchCompanyResult.data?.switchRelyingParty?.otpId) {
        setOtpId(switchCompanyResult.data.switchRelyingParty?.otpId);
        setStatus(SwitchCompanyStatus.CONFIRM_OTP);
      } else if (
        switchCompanyResult.data?.switchRelyingParty?.relyingPartyAdmin &&
        switchCompanyResult.data?.switchRelyingParty?.accessToken &&
        switchCompanyResult.data?.switchRelyingParty?.refreshToken
      ) {
        await initializeLocalStorage({
          accessToken: switchCompanyResult.data.switchRelyingParty.accessToken,
          refreshToken: switchCompanyResult.data.switchRelyingParty.refreshToken,
          relyingPartyAdmin: switchCompanyResult.data.switchRelyingParty.relyingPartyAdmin
        });
      } else {
        handleSwitchCompanyError('Something went wrong. Could not switch company');
      }
    } catch (error) {
      handleSwitchCompanyError('Something went wrong. Could not switch company');
    }
  };

  const initializeLocalStorage = async ({
    accessToken,
    refreshToken,
    relyingPartyAdmin
  }: {
    accessToken: string;
    refreshToken: string;
    relyingPartyAdmin: LoggedUser;
  }) => {
    await client.cache.reset();
    parseSettingsJsonString(relyingPartyAdmin?.settings ?? '');
    setTokenAndRefreshToken(accessToken, refreshToken);
    currentLoggedUserVar(relyingPartyAdmin);
    const {
      relyingParty: { id },
      roleType
    } = relyingPartyAdmin;
    setRelayingPartyId(id);
    setRoleType(roleType);
    setStatus(SwitchCompanyStatus.LOAD_USER);
  };

  const fetchAndSetLoggedUser = useCallback(
    async (companyId: string) => {
      try {
        const loggedUserResult = await getLoggedUser({
          variables: {
            relyingPartyId: companyId
          }
        });

        if (loggedUserResult?.data?.relyingParty) {
          const userData: LoggedUserAccountData = {
            name: loggedUserResult.data.relyingParty.name,
            logo: loggedUserResult.data.relyingParty.logoUrl,
            orgHandle: loggedUserResult.data.relyingParty.orgHandle,
            settings: loggedUserResult.data.relyingParty.settings,
            isCtaAccepted: loggedUserResult.data.relyingParty.isCtaAccepted
          };

          loggedUserData(userData);
          currentLoggedRelyingParty(loggedUserResult.data.relyingParty);
          setStatus(SwitchCompanyStatus.SUCCESS);
        } else {
          handleSwitchCompanyError('Something went wrong. Could not get company user');
        }
      } catch (error) {
        handleSwitchCompanyError('Something went wrong. Could not get company user');
      }
    },
    [handleSwitchCompanyError, getLoggedUser]
  );

  useEffect(() => {
    if (!newCompany && status === SwitchCompanyStatus.LOADING) {
      enqueueSnackbar('Something went wrong. Could not switch company', { variant: 'error' });
      setStatus(SwitchCompanyStatus.ERROR);
    } else if (newCompany) {
      callSwitchCompany(newCompany.id);
    }
  }, [newCompany]);

  useEffect(() => {
    if (currentUser && status === SwitchCompanyStatus.LOAD_USER) {
      fetchAndSetLoggedUser(currentUser.relyingParty.id);
    }
  }, [currentUser, status, fetchAndSetLoggedUser]);

  useEffect(() => {
    if (status === SwitchCompanyStatus.SUCCESS || status === SwitchCompanyStatus.ERROR) {
      setTimeout(() => {
        navigate('/home');
        setNewCompany(null);
        setCurrentCompany(null);
      }, 3000);
    }
  }, [status]);

  const loadSwitchCompaniesContent = () => {
    if (status === SwitchCompanyStatus.LOADING || status === SwitchCompanyStatus.LOAD_USER) {
      return (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <H4>Loading</H4>
          <CircularProgress color="primary" sx={{ margin: 2 }} />
          <Alert status={'info'} description={'Switching companies. Please wait...'} title={'Loading'} />
        </Stack>
      );
    } else if (status === SwitchCompanyStatus.CONFIRM_OTP) {
      return (
        <Stack>
          <H4 align="center" color={color.truentityCyan[600]}>
            Two-Factor Authentication
          </H4>
          <Body1
            sx={{
              margin: 2
            }}
          >
            We've sent a one-time code to the device associated with your account. Please enter the code below to proceed with switching the
            Company.
          </Body1>

          <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField required sx={{ letterSpacing: '2px' }} label="One-Time Code" type="password" {...register('otpCode')} fullWidth />

            <Button type="submit" a11yLabel="Switch Company" color={'secondary'} fullWidth />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '19px' }}>
              <H6 component={Link} color={color.truentityCyan[600]} sx={{ textDecoration: 'none' }} to="/">
                Cancel
              </H6>
            </Box>
          </Stack>
        </Stack>
      );
    } else if (status === SwitchCompanyStatus.SUCCESS) {
      return (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <H4>Success</H4>
          <CircularProgress color="primary" sx={{ margin: 2 }} />
          <Alert
            status={'success'}
            sx={{
              whiteSpace: 'pre-line'
            }}
            description={`You have been signed out of ${currentCompany?.name}. \n Please wait, you're now signing into ${newCompany?.name}.`}
            title={'Success'}
          />
        </Stack>
      );
    } else if (status === SwitchCompanyStatus.ERROR) {
      return (
        <Stack>
          <H4>Error</H4>
          <Alert status="error" description={`Something went wrong. Could not sign into ${newCompany?.name}`} title={'Error'} />
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Stack alignItems={'center'} sx={{ width: '100%', height: '100%', backgroundColor: color.truentityCyan[500] }}>
      <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', marginTop: '10vh' }}>
        <Box
          sx={{
            paddingBottom: '24px',
            borderBottom: '2px solid $truentityLigthGray',
            marginBottom: '19px'
          }}
        >
          <TruentityLogo />
        </Box>
        {loadSwitchCompaniesContent()}
      </Paper>
    </Stack>
  );
};

export default SwitchCompany;
