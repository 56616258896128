import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import { Paper, Stack } from '@mui/material';

import { faPills, faUserCog } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';

import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';

import { AssignRelyingPartyAdminDialog } from '@/components/Dialogs';
import { H3 } from '@/components/Typography';
import AdministrationContext from '@/context/administrationContext';
import useToken from '@/hooks/useToken';
import PatientActions from '@/routes/Administration/patientActions';
import PatientFilters from '@/routes/Administration/patientFilters';
import { Role } from '@/types/admin';

import { useMutation } from '@apollo/client';
import GppBadIcon from '@mui/icons-material/GppBad';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { UNASSIGN_ADMINS_TO_PATIENTS } from '@/graphql/administration';
import { toDateOrNull } from '@/util/date';
import { useLocation } from 'react-router-dom';

export type SelectableFilter = {
  category: string;
  value: any;
};

const Patients = () => {
  const { showModal } = useModal();

  const { pathname } = useLocation();

  const [unassignAdminsToPatients] = useMutation(UNASSIGN_ADMINS_TO_PATIENTS);

  const { organization, clientStore, relyingPartyAdmins, setSelectedPatients, selectedPatients, setReloadOrganization, setReloadStore } =
    useContext(AdministrationContext);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  const [filteredPatients, setFilteredPatients] = useState<any[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('All');

  // can be either store-patient or client-org-patient
  const [patientType, setPatientType] = useState('');

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First name',
        sortable: true,
        valueGetter: params => params.row.user.firstName,
        flex: 1,
        align: 'left'
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        sortable: true,
        valueGetter: params => params.row.user.lastName,
        flex: 1,
        align: 'left'
      },
      {
        field: 'status',
        headerName: 'Assigned',
        sortable: true,
        valueGetter: params => params.row.createdAt,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: cellValues => {
          if (cellValues?.row?.currentAccountsAssignments?.length > 0) {
            return <VerifiedUserIcon color={'success'} />;
          } else {
            return <GppBadIcon color={'error'} />;
          }
        }
      },
      {
        field: 'relyingPartyAdmin',
        headerName: 'Assigned to',
        sortable: true,
        minWidth: 400,
        valueGetter: params => params.row.createdAt,
        flex: 1,
        align: 'left',
        renderCell: cellValues => {
          let relyingPartyAdmin = '---';

          if (cellValues?.row?.currentAccountsAssignments?.length > 0) {
            const relyingPartyAdminString = cellValues.row.currentAccountsAssignments
              .map(item => {
                return item.relyingPartyAdmin.name;
              })
              .join(', ');
            relyingPartyAdmin = relyingPartyAdminString;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {relyingPartyAdmin}
            </Stack>
          );
        }
      },
      {
        field: 'truentityid',
        headerName: 'Truentity ID',
        sortable: true,
        valueGetter: params => params.row.truentityId,
        flex: 1,
        align: 'left'
      },
      {
        field: 'createdAt',
        headerName: 'Created On',
        sortable: true,
        type: 'date',
        valueGetter: params => toDateOrNull(params.row.createdAt),
        flex: 1,
        align: 'left',
        renderCell: cellValues => {
          const formattedDate = moment(cellValues.row.birthdDate).format('YYYY-MM-DD');
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {formattedDate}
            </Stack>
          );
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <Link to={`/patients/${cellValues.row.truentityId}/details/medications/triage/list`}>
                <Icon icon={faPills} fixedWidth size="lg" />
              </Link>

              {!isProviderAdmin && (
                <Link to={`/providers/${cellValues.row.truentityId}/manage`}>
                  <Icon icon={faUserCog} fixedWidth size="lg" />
                </Link>
              )}
            </Stack>
          );
        }
      }
    ],
    [isProviderAdmin]
  );

  useEffect(() => {
    if (patientType === 'store-patient') {
      setFilteredPatients(clientStore?.accounts);
    } else if (patientType === 'client-org-patient') {
      setFilteredPatients(organization?.accounts);
    }
  }, [patientType, clientStore?.accounts, organization?.accounts]);

  useEffect(() => {
    if (pathname.includes('stores')) {
      setPatientType('store-patient');
    } else if (pathname.includes('patients')) {
      setPatientType('client-org-patient');
    }
  }, [pathname]);

  const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilter((event.target as HTMLInputElement).value);

    filterPatients((event.target as HTMLInputElement).value);
  };

  const filterPatients = selectedOption => {
    if (patientType === 'store-patient') {
      filterPatientsUtil(selectedOption, clientStore?.accounts);
    } else if (patientType === 'client-org-patient') {
      filterPatientsUtil(selectedOption, organization?.accounts);
    }
  };

  const filterPatientsUtil = (selectedOption, patients) => {
    if (selectedOption === 'All') {
      setFilteredPatients(patients);
    } else if (selectedOption === 'Assigned') {
      const filtered = patients.filter(patient => patient.currentAccountsAssignments.length > 0);
      setFilteredPatients(filtered);
    } else if (selectedOption === 'Unassigned') {
      const filtered = patients.filter(patient => patient.currentAccountsAssignments.length === 0);
      setFilteredPatients(filtered);
    }
  };

  const handleAssignClicked = title => {
    if (title === 'Assign') {
      showAssignAdminModal();
    } else if (title === 'Unassign') {
      unassignAdminsToPatientsAPI(selectedPatients);
    }
  };

  const unassignAdminsToPatientsAPI = async patients => {
    if (patients.length > 0) {
      let variables = {};
      variables = {
        accountIds: patients
      };

      try {
        const result = await unassignAdminsToPatients({
          variables: { ...variables }
        });
      } catch (err) {
        console.log(err);
      }

      if (patientType === 'store-patient') {
        setReloadStore(true);
      } else if (patientType === 'client-org-patient') {
        setReloadOrganization(true);
      }

      onReset();
    }
  };

  const showAssignAdminModal = () => {
    const modalRef = showModal(AssignRelyingPartyAdminDialog, {
      title: 'Assign to',
      hideDialog: () => {
        modalRef.hide();

        if (patientType === 'store-patient') {
          setReloadStore(true);
        } else if (patientType === 'client-org-patient') {
          setReloadOrganization(true);
        }

        onReset();
      },
      relyingPartyAdmins: relyingPartyAdmins,
      selectedPatients: selectedPatients
    });
  };

  const onReset = () => {
    setSelectionModel([]);
    setSelectedPatients([]);
  };

  return (
    <Stack spacing={2}>
      <Paper component={Stack} direction="column" spacing={2}>
        <H3 textAlign="center" css={{ width: '100%' }}>
          Patients
        </H3>

        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={2}>
          <PatientFilters selectedFilter={selectedFilter} handleRadioButtonChange={handleRadioButtonChange} />

          {selectedPatients.length > 0 && <PatientActions handleAssignClicked={handleAssignClicked} selectedPatients={selectedPatients} />}
        </Stack>

        <div style={{ display: 'flex' }}>
          {/* TODO:  This cant be paginated server side since the data is passed as a parameter and does not belong to this component */}
          <TruentityDataGrid
            name={'dg-org-patients'}
            autoHeight
            rows={filteredPatients || []}
            rowCount={rowCountState}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            paginationMode="client"
            disableRowSelectionOnClick
            checkboxSelection
            onRowSelectionModelChange={e => {
              setSelectedPatients(e);
              setSelectionModel(e);
            }}
            rowSelectionModel={selectionModel}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Patients;
