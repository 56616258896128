import { DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import parse from 'html-react-parser';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

export type InfoButtonType = {
  name: string;
  action: () => void;
  variant?: 'text' | 'outlined' | 'contained';
};

type Props = BaseDialogProps & {
  message: string;
  actions: InfoButtonType[];
};

const InformationDialog = ({ title, message, actions, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title}>
      <DialogContent>
        <DialogContentText>{parse(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map(button => (
          <Button onClick={button.action} variant={button.variant ?? 'contained'} key={button.name}>
            {button.name}
          </Button>
        ))}
      </DialogActions>
    </BaseDialog>
  );
};

export default InformationDialog;
