import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { AddProviderResponse } from '@/graphql/remotePatientMonitoring';
import { ADD_PROVIDER } from '@/graphql/remotePatientMonitoring';
import { PROVIDER_TITLE_OPTIONS } from '@/types/providers';
import { useMutation } from '@apollo/client';
import { Autocomplete, DialogContent, Grid, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

type FormValues = {
  firstName: string;
  lastName: string;
  npiNumber: string;
  title: string;
  email: string;
  phone: string;
  fax: string;
};

const AddProviderForm = ({ hideDialog }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [addProvider, { loading: addProviderLoading }] = useMutation<AddProviderResponse>(ADD_PROVIDER);

  const { control, handleSubmit } = useForm<FormValues>({});
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const inputFieldValues = {
        firstName: values.firstName,
        lastName: values.lastName,
        npiNumber: values.npiNumber,
        title: values.title || '',
        email: values.email || '',
        phone: values.phone || '',
        fax: values.fax || ''
      };

      const response = await addProvider({
        variables: inputFieldValues
      });

      if (response?.data?.createProviders?.status === 'Failure') {
        enqueueSnackbar(
          `Failed to add Provider: ${
            response?.data?.createProviders?.message ? response.data.createProviders.message : 'Something went wrong'
          }`,
          { variant: 'error' }
        );
      } else {
        enqueueSnackbar('Successfully Added Provider', { variant: 'success' });
      }
    } catch (error) {
      console.error('Error adding Provider:', error?.toString());
      enqueueSnackbar('Failed to add Provider: Something went wrong', { variant: 'error' });
    }
    hideDialog();
  };

  return (
    <Stack
      sx={{
        m: 2
      }}
      component={'form'}
      spacing={1}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container width="100%" height="auto" spacing={1}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField autoFocus={true} required onChange={onChange} value={value} label={'First Name'} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField required onChange={onChange} value={value} label={'Last Name'} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="npiNumber"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField required onChange={onChange} value={value} label={'NPI Number'} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField type={'email'} onChange={onChange} value={value} label={'Email'} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value } }) => (
              <TruentityPhoneNumber editable={true} onChange={onChange} value={value} label={'Phone Number'} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="fax"
            render={({ field: { onChange, value } }) => (
              <TruentityPhoneNumber editable={true} onChange={onChange} value={value} label={'Fax'} />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            name="title"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="title-options"
                options={PROVIDER_TITLE_OPTIONS}
                renderInput={params => <TextField {...params} label="Title" variant="outlined" />}
                getOptionLabel={option => option}
                value={value}
                onChange={(_event, selectedOption) => {
                  onChange(selectedOption);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} mt={2}>
        <Button a11yLabel="Cancel" type="reset" variant={'text'} onClick={hideDialog} />
        <Button a11yLabel="Save" isLoading={addProviderLoading} appearance="primary" type={'submit'} />
      </Stack>
    </Stack>
  );
};

const ProviderAddDialog = ({ title, hideDialog, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <AddProviderForm hideDialog={hideDialog} />
      </DialogContent>
    </BaseDialog>
  );
};

export default ProviderAddDialog;
