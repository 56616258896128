import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import PatientLink from '@/components/PatientLink';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import StarredPatient from '@/components/StarredPatient';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import SideMenuContext from '@/context/sideMenuContext';
import { GET_ALL_ACCOUNTS_LIST } from '@/graphql/account-lists';
import type { PatientsData } from '@/routes/Patients/patients';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { useQuery } from '@apollo/client';
import type { GridAlignment, GridColDef } from '@mui/x-data-grid-pro';
import { useContext, useEffect, useMemo, useState } from 'react';

const StarredPatients = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const { setContent } = useContext(SideMenuContext);

  const { data: accountsList } = useQuery(GET_ALL_ACCOUNTS_LIST, {
    variables: {
      pageNum: 1,
      pageSize: 100
    }
  });
  const [starredList, setStarredList] = useState<PatientsData[]>([]);

  const updateData = (data: number) => {
    setCurrentPage(data);
  };

  const columns: GridColDef<PatientsData>[] = useMemo(
    () =>
      [
        {
          field: 'truentityId',
          headerName: 'Starred',
          type: 'string',
          sortable: false,
          width: 100,
          align: 'center' as GridAlignment,
          headerAlign: 'center' as GridAlignment,
          renderCell: params => {
            return <StarredPatient truentityId={params.value} />;
          }
        },
        {
          field: 'name',
          headerName: 'Patient Name',
          type: 'string',
          minWidth: 250,
          sortable: true,
          valueGetter: params => getAccountUserFullName(params.row.user),
          renderCell: params => <PatientLink {...params.row} />
        },
        {
          field: 'dateOfBirth',
          headerName: 'DOB',
          type: 'string',
          sortable: true,
          flex: 1,
          valueGetter: params => (params.row.birthDate ? formatDate(new Date(params.row.birthDate.replace(/-/g, '/'))) : ''),
          align: 'center' as GridAlignment,
          headerAlign: 'center' as GridAlignment
        },
        {
          field: 'phone',
          type: 'phone',
          headerName: 'Phone Number',
          sortable: true,
          flex: 1,
          align: 'center' as GridAlignment,
          headerAlign: 'center' as GridAlignment,
          renderCell: params => {
            return <TruentityPhoneNumber value={params.value} />;
          }
        }
      ].filter(col => col.hide !== true),
    []
  );

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  useEffect(() => {
    const list = accountsList?.getAllAccountLists?.accountLists?.find(accountList => accountList?.name === 'Starred') ?? [];
    setStarredList(list.accounts ?? []);
  }, [accountsList]);

  return (
    <div style={{ display: 'flex' }}>
      <TruentityDataGrid
        name={'dg-patients'}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={({ page }) => updateData(page)}
        autoHeight
        rows={starredList}
        columns={columns}
        paginationMode="client"
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default StarredPatients;
