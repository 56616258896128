export enum ActivityTypes {
  CARE_ACTIVITY_STARTED = 'CARE_ACTIVITY_STARTED',
  CARE_ACTIVITY_ENDED_TIMEDOUT = 'CARE_ACTIVITY_ENDED_TIMEDOUT',
  CARE_ACTIVITY_ENDED_LOGGEDOUT = 'CARE_ACTIVITY_ENDED_LOGGEDOUT',
  ENCOUNTER_STARTED = 'ENCOUNTER_STARTED',
  ENCOUNTER_ENDED_TIMEDOUT = 'ENCOUNTER_ENDED_TIMEDOUT',
  ENCOUNTER_ENDED_LOGGEDOUT = 'ENCOUNTER_ENDED_LOGGEDOUT',
  INVOICE_ADMIN_EMAILED = 'INVOICE_ADMIN_EMAILED',
  MEDS_EPHEMERAL_VIEWED = 'MEDS_EPHEMERAL_VIEWED',
  MEDS_EPHEMERAL_EMAILED = 'MEDS_EPHEMERAL_EMAILED',
  MEDS_ADMIN_EMAILED = 'MEDS_ADMIN_EMAILED',
  MEDS_EHR_UPLOADED = 'MEDS_EHR_UPLOADED',
  MEDS_EHR_SUBMITTED = 'MEDS_EHR_SUBMITTED',
  MEDS_ESI_IMPORTED_DIRECT = 'Meds::MEDS_ESI_IMPORTED_DIRECT',
  PATIENT_CONSENT_UPDATED = 'PATIENT_CONSENT_UPDATED',
  PATIENT_MEDTASKS_PERFORMED = 'PATIENT_MEDTASKS_PERFORMED',
  SESSION_STARTED = 'SESSION_STARTED',
  SESSION_NAVIGATED_TO = 'SESSION_NAVIGATED_TO',
  SESSION_ENDED_TIMEDOUT = 'SESSION_ENDED_TIMEDOUT',
  SESSION_ENDED_LOGGEDOUT = 'SESSION_ENDED_LOGGEDOUT',
  RP_CTA_ACCEPTED = 'RP_CTA_ACCEPTED',
  RP_CTA_REJECTED = 'RP_CTA_REJECTED',
  RPM_CONSENT_UPDATE = 'RPM_CONSENT_UPDATE',
  RPM_CARE_PLAN_UPDATE = 'RPM_CARE_PLAN_UPDATE',
  RPM_ADD_DEVICES = 'RPM_ADD_DEVICES',
  RPM_CONFIGURATION_UPDATE = 'RPM_CONFIGURATION_UPDATE',
  RPM_START_CONSULT = 'RPM_START_CONSULT',
  RPM_MONTHLY_REPORT_SENT = 'RPM_MONTHLY_REPORT_SENT',
  RPM_MONTHLY_REPORT_NOT_SENT = 'RPM_MONTHLY_REPORT_NOT_SENT',
  RPM_MONTHLY_SMS_SENT = 'RPM_MONTHLY_SMS_SENT',
  RPM_MONTHLY_SMS_NOTSENT = 'RPM_MONTHLY_SMS_NOTSENT',
  RPM_REPORT_FINALIZED = 'RPM_REPORT_FINALIZED',
  RPM_REPORT_NOT_FINALIZED = 'RPM_REPORT_NOT_FINALIZED',
  RPM_REPORT_FAX_SENT = 'RPM_REPORT_FAX_SENT',
  RPM_REPORT_FAX_NOT_SENT = 'RPM_REPORT_FAX_NOT_SENT',
  RPM_REPORT_GENERATED = 'RPM_REPORT_GENERATED',
  RPM_REPORT_NOT_GENERATED = 'RPM_REPORT_NOT_GENERATED',
  RPM_REPORT_REGENERATED = 'RPM_REPORT_REGENERATED',
  RPM_REPORT_NOT_REGENERATED = 'RPM_REPORT_NOT_REGENERATED',
  RPM_REPORT_SEND_NOW = 'RPM_REPORT_SEND_NOW',
  RPM_DAILY_REMINDER_SMS_SENT = 'RPM_DAILY_REMINDER_SMS_SENT',
  RPM_SNAPSHOT_CREATED = 'RPM_SNAPSHOT_CREATED',
  RPM_SNAPSHOT_NOT_CREATED = 'RPM_SNAPSHOT_NOT_CREATED',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_READY_FOR_SENDING = 'UPDATE_REPORT_DAILY_SEND_STATUS_TO_READY_FOR_SENDING',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_SCHEDULE = 'UPDATE_REPORT_DAILY_SEND_STATUS_TO_SCHEDULE',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_NULL = 'UPDATE_REPORT_DAILY_SEND_STATUS_TO_NULL',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_SENT = 'UPDATE_REPORT_DAILY_SEND_STATUS_TO_SENT',
  UPDATE_REPORT_DAILY_SEND_STATUS_TO_FAILED = 'UPDATE_REPORT_DAILY_SEND_STATUS_TO_FAILED',
  UPDATE_RPM_APROVAL_STATUS_TO_IN_REVIEW = 'UPDATE_RPM_APROVAL_STATUS_TO_IN_REVIEW',
  UPDATE_RPM_APROVAL_STATUS_TO_APPROVE = 'UPDATE_RPM_APROVAL_STATUS_TO_APPROVE',
  UPDATE_RPM_APROVAL_STATUS_TO_REJECT = 'UPDATE_RPM_APROVAL_STATUS_TO_REJECT',
  UPDATE_RPM_APROVAL_STATUS_TO_SKIPPED = 'UPDATE_RPM_APROVAL_STATUS_TO_SKIPPED',
  PATIENT_MED_HISTORY_FILE_LOAD_CREATED = 'PATIENT_MED_HISTORY_FILE_LOAD_CREATED',
  PATIENT_MED_HISTORY_FILE_LOAD_NOT_CREATED = 'PATIENT_MED_HISTORY_FILE_LOAD_NOT_CREATED',
  PATIENT_MED_HISTORY_RESPONSE_FILE_RECEIVED = 'PATIENT_MED_HISTORY_RESPONSE_FILE_RECEIVED',
  PATIENT_MED_HISTORY_RESPONSE_FILE_SUCESSS = 'PATIENT_MED_HISTORY_RESPONSE_FILE_SUCESSS',
  PATIENT_MED_HISTORY_RESPONSE_FILE_FAILED = 'PATIENT_MED_HISTORY_RESPONSE_FILE_FAILED',
  PATIENT_MED_HISTORY_MEDICATION_FILE_RECEIVED = 'PATIENT_MED_HISTORY_MEDICATION_FILE_RECEIVED',
  PATIENT_MED_HISTORY_MEDICATION_ADDED_SUCCESS = 'PATIENT_MED_HISTORY_MEDICATION_ADDED_SUCCESS',
  PATIENT_MED_HISTORY_PATIENT_NOT_FOUND = 'PATIENT_MED_HISTORY_PATIENT_NOT_FOUND',
  VITALS_EPHEMERAL_VIEWED = 'VITALS_EPHEMERAL_VIEWED',
  VITALS_EPHEMERAL_EMAILED = 'VITALS_EPHEMERAL_EMAILED'
}

export enum LogNames {
  CARE_ACTIVITY_LOGS = 'Care Activity Logs',
  ENCOUNTER_LOGS = 'Encounter Logs',
  ADMIN_INVOICE_LOGS = 'Admin Invoice Logs',
  MEDICATION_SNAPSHOT_LOGS = 'Medication Snapshot Logs',
  MEDICATION_EHR_LOGS = 'Medication EHR Logs',
  MEDICATION_ESI_LOGS = 'Medication ESI Logs',
  PATIENT_MEDICATION_VIEW_LOGS = 'Patient Medication View Logs',
  PATIENT_CONSENT_LOGS = 'Patient Consent Logs',
  PATIENT_MED_TASK_LOGS = 'Patient Med Task Logs',
  SESSION_LOGS = 'Session Logs',
  ORGANIZATION_CTA_LOGS = 'Organization CTA Logs',
  RPM_DEVICE_LOGS = 'RPM Device Logs',
  RPM_COMMUNICATION_LOGS = 'RPM Communication Logs',
  RPM_CONSULT_LOGS = 'RPM Consult Logs',
  RPM_CONFIGURATION_LOGS = 'RPM Configuration Logs',
  RPM_CONSENT_LOGS = 'RPM Consent Logs',
  RPM_CARE_PLAN_LOGS = 'RPM Care Plan Logs',
  RPM_SNAPSHOT_LOGS = 'RPM Snapshot Logs',
  RPM_REPORTS_LOGS = 'RPM Reports Logs',
  PROVIDER_LOGS = 'Provider Logs',
  PATIENTS_MEDICATION_HISTORY_LOGS = 'Patients Medication History Logs',
  PATIENT_VITAL_ACCESS_LOGS = 'Patient Vital Access Logs'
}

export type AdtTcmActivityLog = {
  id?: string;
  relyingPartyAdmin: {
    user: userType;
  };
  activityTime: Date;
  account: {
    user: userType;
  };
  tcmTask: {
    id: string;
  };
  logText: string;
};

export type userType = {
  firstName: string;
  lastName: string;
};
