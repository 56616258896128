import Alert from '@/components/Alert';
import { RelyingPartyIdleTimeoutReLoginResponse, RELYING_PARTY_IDLE_TIMEOUT_RE_LOGIN } from '@/graphql/administration';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, type SubmitHandler } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Button from '../Button';
import { Body1, Caption } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  askForPin: boolean;
  hideDialog: () => void;
  countFrom: number;
  logout: ({ showErrorMessage }: { showErrorMessage?: boolean | undefined }) => void;
};

type FormValues = {
  pin: string;
};

const IdleDialog = ({ title, askForPin, countFrom, hideDialog, logout, ...props }: Props) => {
  const { handleSubmit, control } = useForm<FormValues>();
  const { enqueueSnackbar } = useSnackbar();

  const [relyingPartyTimeoutReLogin] = useMutation<RelyingPartyIdleTimeoutReLoginResponse>(RELYING_PARTY_IDLE_TIMEOUT_RE_LOGIN);

  const [countdown, setCountdown] = useState(countFrom);
  const [error, setError] = useState<string | null>(null);
  const [pinValue, setPinValue] = useState('');

  const onSubmit: SubmitHandler<FormValues> = () => handleSubmitImpl();

  const handlePinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPinValue(e.target.value.replaceAll('-', ''));
  };

  const handleSubmitImpl = useCallback(async () => {
    try {
      const reLoginResponse = await relyingPartyTimeoutReLogin({
        variables: {
          pinCode: pinValue
        }
      });

      if (reLoginResponse?.data?.relyingPartyIdleTimeoutReLogin?.status === 'Success') {
        hideDialog();
      } else {
        if (reLoginResponse?.data?.relyingPartyIdleTimeoutReLogin?.code === 'INVALID_PIN') {
          setError(reLoginResponse?.data?.relyingPartyIdleTimeoutReLogin?.message ?? 'Invalid PIN');
        } else if (reLoginResponse?.data?.relyingPartyIdleTimeoutReLogin?.code === 'LOGOUT') {
          enqueueSnackbar('Your session has timed out due to invalid re-login attempts', { variant: 'error' });
          setTimeout(() => {
            logout({
              showErrorMessage: false
            });
          }, 2000);
        } else if (reLoginResponse?.data?.relyingPartyIdleTimeoutReLogin?.code === 'REQUEST_FAILED') {
          setError('Could not log you back in. Please try again.');
        }
      }
    } catch (error) {
      setError('Could not log you back in. Please try again.');
    }
  }, [hideDialog, pinValue, relyingPartyTimeoutReLogin, logout]);

  useEffect(() => {
    if (pinValue.length === 6) {
      handleSubmitImpl();
    }
  }, [handleSubmitImpl, pinValue.length]);

  useEffect(() => {
    countdown > 0 && setTimeout(() => setCountdown(countdown - 1), 1000);
  }, [countdown]);

  return (
    <BaseDialog
      disableBackdropClick={true}
      canNavigateBack={false}
      title={title}
      hideDialog={hideDialog}
      {...props}
      hideCloseButton={true}
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogContent>
        <Stack py={2} spacing={2} component={'form'} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          {error && <Alert status="error" description={error} />}

          <Stack direction={'row'} spacing={1}>
            <Body1>You are going to be logged out due to inactivity in</Body1>
            <Body1 fontWeight={'bold'}>{countdown} seconds</Body1>
          </Stack>

          {askForPin && (
            <Stack flexDirection="column" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Controller
                name="pin"
                defaultValue={''}
                control={control}
                render={({ field }) => (
                  <InputMask
                    mask="9-9-9-9-9-9"
                    alwaysShowMask
                    maskPlaceholder={'-'}
                    maskChar={null}
                    {...field}
                    value={pinValue}
                    onChange={handlePinChange}
                  >
                    {() => (
                      <TextField label={'Enter your 6 digit PIN'} placeholder={'Enter your 6 digit PIN'} variant="outlined" fullWidth />
                    )}
                  </InputMask>
                )}
              />
              <Caption textTransform="none">Forgot PIN? Please contact support in our Slack channel.</Caption>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      {!askForPin && (
        <DialogActions>
          <Button label="Continue" onClick={() => hideDialog()} />
          <Button label="Logout" onClick={() => logout({ showErrorMessage: true })} />
        </DialogActions>
      )}
    </BaseDialog>
  );
};

export default IdleDialog;
