import Alert from '@/components/Alert';
import TruentityLogo from '@/components/TruentityLogo';
import { color } from '@/styles/assets/colors';
import { Box, Paper, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { Small } from './Typography';

type Props = {
  feedback?: ReactNode;
};
const InvalidSession = ({ feedback }: Props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const isLogout = searchParams.get('logout') === 'true';

  return (
    <Stack flex="1" alignItems={'center'} sx={{ width: '100%', minHeight: '100vh', backgroundColor: color.truentityCyan[500] }}>
      <Paper component={Stack} spacing={2} p={2} sx={{ width: '300px', margin: '0 auto', marginTop: '10vh', textAlign: 'center' }}>
        <Box sx={{ paddingBottom: '4px', marginBottom: '19px' }}>
          <TruentityLogo />
        </Box>

        {isLogout ? (
          <Alert status="success" description="You have been logged out successfully" />
        ) : (
          <>{feedback && <Alert status={'error'} description={feedback} />}</>
        )}

        <Small color={color.truentityCyan[600]}>
          <span>
            Please contact{' '}
            <a href="https://truentity.health/contact-us/" target="_blank">
              Truentity Health
            </a>{' '}
          </span>
        </Small>
      </Paper>
    </Stack>
  );
};

export default InvalidSession;
