import { H5 } from '@/components/Typography';
import { formatTime } from '@/util/format';
import styled from '@emotion/styled';
import type { DateInput } from '@fullcalendar/core';
import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack } from '@mui/material';
import type React from 'react';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: theme.palette.primary.light
  }
}));
interface CustomDatePickerHeaderProps {
  changeMonth?: (increment: number) => void;
  changeYear?: (increment: number) => void;
  selectedDate: DateInput;
}

export const CustomDatePickerHeader: React.FC<CustomDatePickerHeaderProps> = ({ changeMonth, changeYear, selectedDate }) => {
  return (
    <Stack p={2} spacing={1} direction="row" justifyContent="center" alignItems="center">
      {changeYear && (
        <CustomIconButton onClick={() => changeYear(-1)}>
          <KeyboardDoubleArrowLeft />
        </CustomIconButton>
      )}
      {changeMonth && (
        <CustomIconButton onClick={() => changeMonth(-1)}>
          <KeyboardArrowLeftIcon />
        </CustomIconButton>
      )}
      <H5 flex={1} textAlign="center">
        {formatTime(selectedDate, 'MMMM YYYY')}
      </H5>
      {changeMonth && (
        <CustomIconButton onClick={() => changeMonth(1)}>
          <KeyboardArrowRightIcon />
        </CustomIconButton>
      )}
      {changeYear && (
        <CustomIconButton onClick={() => changeYear(1)}>
          <KeyboardDoubleArrowRight />
        </CustomIconButton>
      )}
    </Stack>
  );
};

export default CustomDatePickerHeader;
