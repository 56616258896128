import type { ContentForm } from '@/components/Dialogs/ManageAnnouncementDialog';
import type { EditorItemGroupNames } from '@/components/RichTextEditor/EditorToolbar';
import TruentityRichTextEditor from '@/components/RichTextEditor/TruentityRichTextEditor';
import { H3 } from '@/components/Typography';
import { AnnouncementType } from '@/types/announcement';
import { Stack } from '@mui/material';
import { Controller, type Control } from 'react-hook-form';

type Props = {
  control: Control<ContentForm, unknown>;
  announcementType?: AnnouncementType;
};

const ComposeAnnouncementForm = ({ control, announcementType }: Props) => {
  const disabledGroups: EditorItemGroupNames[] = announcementType === AnnouncementType.SECONDARY ? ['extras', 'heading', 'list'] : [];

  return (
    <Stack component="form" gap={2}>
      <H3>Compose Announcement</H3>
      <Stack direction="row" gap={2}>
        <Controller
          control={control}
          name="content"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TruentityRichTextEditor label="Content" onChange={onChange} description={value} disabledGroups={disabledGroups} />
          )}
        />
      </Stack>
    </Stack>
  );
};

export default ComposeAnnouncementForm;
