import { PDFViewer } from '@/routes/PatientDetails/DischargeReports/Summary';
import { DialogContent } from '@mui/material';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import type { GetRpmReportByAccountResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_REPORT_BY_ACCOUNT } from '@/graphql/remotePatientMonitoring';
import { useSnackbar } from 'notistack';
import LoadingOverlay from '@/components/LoadingOverlay';
import { fetchDocumentUrl } from '@/util/rpm';
import { getAccountUserFullName } from '@/util/account';

type Props = BaseDialogProps & {
  truentityId: string;
  filterMonthYear: string;
};

const RpmReportPreviewDialog = ({ truentityId, filterMonthYear, title, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [reportUrl, setReportUrl] = useState('');
  const [dialogTitle, setDialogTitle] = useState(title);
  const [getRpmReportByAccount, { loading: rpmReportLoading }] = useLazyQuery<GetRpmReportByAccountResponse>(GET_RPM_REPORT_BY_ACCOUNT, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    const fetchRpmReport = async () => {
      try {
        if (!truentityId || !filterMonthYear) return;
        const response = await getRpmReportByAccount({
          variables: {
            truentityId: truentityId,
            monthYear: filterMonthYear
          }
        });
        if (response?.data?.getRpmReportByAccount?.reportedDocS3Key) {
          setDialogTitle(
            `${getAccountUserFullName(response?.data?.getRpmReportByAccount?.account?.user)} - ${response?.data?.getRpmReportByAccount?.monthYear} Report`
          );
          await getDocumentPreview(response?.data?.getRpmReportByAccount?.reportedDocS3Key);
        } else {
          enqueueSnackbar('No RPM report found', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Could not load RPM report', { variant: 'error' });
      }
    };
    fetchRpmReport();
  }, [truentityId, filterMonthYear, getRpmReportByAccount, enqueueSnackbar]);

  const getDocumentPreview = useCallback(async (pdfS3Key: string) => {
    const previewUrl = await fetchDocumentUrl(pdfS3Key);
    setReportUrl(previewUrl);
  }, []);

  return (
    <BaseDialog {...props} title={dialogTitle} fullWidth maxWidth="lg">
      <DialogContent
        sx={{
          overflowY: 'hidden'
        }}
      >
        <LoadingOverlay text="RPM Report loading..." active={rpmReportLoading} />
        <PDFViewer src={reportUrl} />
      </DialogContent>
    </BaseDialog>
  );
};

export default RpmReportPreviewDialog;
