import { Body1 } from '@/components/Typography';
import type { ActiveRelyingPartyAdminSubscriptionResponse } from '@/graphql/remotePatientMonitoring';
import { ON_RELYING_PARTY_ADMIN_ACTIVITY_ENGAGEMENT_TRACKER } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import type { ActivityLogType, RelyingPartyAdminType } from '@/types/graphql';
import { MuiColor } from '@/types/mui';
import type { TabBasedActiveAdminsType } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName, getIconDataBasedOnRole } from '@/util/account';
import { formatFromSnakeCase } from '@/util/string';
import { useSubscription } from '@apollo/client';
import LensIcon from '@mui/icons-material/Lens';
import type { SvgIconTypeMap } from '@mui/material';
import { Badge, Box, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper, Popover, Stack } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Props = {
  id: string | undefined;
  tabBasedActiveAdmins: TabBasedActiveAdminsType[];
  setTabBasedActiveAdmins: Dispatch<SetStateAction<TabBasedActiveAdminsType[]>>;
};

type ActiveUserType = {
  role: Role;
  Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
  bgColor: string;
  badgeColor: MuiColor;
  userCount: number;
  activeAdmins: RelyingPartyAdminType[];
};

type ActiveUserPopoverType = {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: (role: Role) => void;
  role: Role;
  activeAdmins: RelyingPartyAdminType[];
};

const ActiveUserPopover = ({ id, open, anchorEl, handleClose, role, activeAdmins }: ActiveUserPopoverType) => {
  return (
    <Popover
      id={id}
      sx={{ pointerEvents: 'none' }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={handleClose}
      disableRestoreFocus
    >
      {activeAdmins.length === 0 ? (
        <Box component={Paper} p={1}>
          <Body1>No {formatFromSnakeCase(role)}'s are currently active</Body1>
        </Box>
      ) : (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} subheader={<ListSubheader>Active users</ListSubheader>}>
          {activeAdmins.map(activeUser => (
            <ListItem key={activeUser.id}>
              <ListItemIcon>
                <LensIcon color="success" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={`${getAccountUserFullName(activeUser.user)}${
                  activeUser.user.provider?.title ? `, ${activeUser.user.provider?.title}` : ''
                }`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </Popover>
  );
};

const RpmActiveUsers = ({ id, tabBasedActiveAdmins, setTabBasedActiveAdmins }: Props) => {
  const { roleType } = useToken();

  const defaultState = useCallback(() => {
    const state: Partial<{ [key in Role]?: ActivityLogType[] }> = {};

    if (roleType === Role.PROVIDER) {
      state[Role.PHARMACIST] = [];
    } else if (roleType === Role.PHARMACIST || roleType === Role.PHARMACIST_ADMIN) {
      state[Role.PROVIDER] = [];
    } else if (roleType === Role.SUPER) {
      state[Role.PHARMACIST] = [];
      state[Role.PROVIDER] = [];
    }

    return state;
  }, [roleType]);

  const [userRoleBasedActivities, setUserRoleBasedActivities] = useState<{ [key in Role]?: ActivityLogType[] }>(defaultState());
  const [activeAdminData, setActiveAdminData] = useState<{
    anchorEl: HTMLElement | null;
    adminRole: Role | null;
    activeAdmins: RelyingPartyAdminType[];
  }>({
    anchorEl: null,
    adminRole: null,
    activeAdmins: []
  });

  useSubscription<ActiveRelyingPartyAdminSubscriptionResponse>(ON_RELYING_PARTY_ADMIN_ACTIVITY_ENGAGEMENT_TRACKER, {
    variables: { truentityId: id },
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.relyingPartyAdminEngagementTracking) {
        const activities = subscriptionData.data.relyingPartyAdminEngagementTracking.adminsWorkingOnPatientActivity;

        const roleBasedActivities = refactorRoleBasedACtivities(activities);
        setUserRoleBasedActivities(roleBasedActivities);
      }
    },
    onError: err => console.error('Subscription error:', err),
    fetchPolicy: 'cache-first'
  });

  const activeUserRoles: ActiveUserType[] = useMemo(() => {
    const users = Object.entries(userRoleBasedActivities).map(([role, activities]) => {
      const iconData = getIconDataBasedOnRole(role as Role);
      return {
        role: role,
        Icon: iconData.Icon,
        bgColor: role === Role.PHARMACIST ? 'primary.main' : 'secondary.main',
        badgeColor: role === Role.PHARMACIST ? 'success' : 'success',
        userCount: activities?.length,
        activeAdmins: activities.map(activity => activity.relyingPartyAdmin)
      } as ActiveUserType;
    });

    return users.sort((a, b) => b.userCount - a.userCount);
  }, [userRoleBasedActivities]);

  const updatedTabBasedAdmins = useMemo(() => {
    return tabBasedActiveAdmins.map(tab => {
      const updatedAdmins = { ...tab.admins };

      Object.entries(userRoleBasedActivities).forEach(([role, activities]) => {
        const relevantActivities = activities.filter(activity => {
          const pathFromActivity = activity.logText.split('/').pop();
          return pathFromActivity === tab.path;
        });

        if (role in updatedAdmins) {
          updatedAdmins[role as Exclude<Role, Role.ADT_ADMIN>] = relevantActivities.length;
        }
      });

      return { ...tab, admins: updatedAdmins };
    });
  }, [tabBasedActiveAdmins, userRoleBasedActivities]);

  const refactorRoleBasedACtivities = useCallback(
    (activities: ActivityLogType[]) => {
      const updatedActivities = {
        ...defaultState()
      };

      activities.forEach(activity => {
        const adminRole = activity.relyingPartyAdmin?.roleType as unknown as Role;
        const roleKey: Role = adminRole === Role.PROVIDER ? Role.PROVIDER : Role.PHARMACIST;

        if (!updatedActivities[roleKey]) {
          updatedActivities[roleKey] = [];
        }

        const adminId = activity.relyingPartyAdmin?.id;
        if (adminId) {
          const existingIndex = updatedActivities[roleKey].findIndex(act => act.relyingPartyAdmin?.id === adminId);
          if (existingIndex > -1) {
            updatedActivities[roleKey][existingIndex] = activity;
          } else {
            updatedActivities[roleKey].push(activity);
          }
        }
      });

      return updatedActivities;
    },
    [defaultState]
  );

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, role: Role, activeAdmins: RelyingPartyAdminType[]) => {
    setActiveAdminData({ anchorEl: event.currentTarget, activeAdmins: activeAdmins, adminRole: role });
  };

  const handlePopoverClose = () => {
    setActiveAdminData({ anchorEl: null, adminRole: null, activeAdmins: [] });
  };

  useEffect(() => {
    const currentTabBasedAdminsString = JSON.stringify(tabBasedActiveAdmins);
    const updatedTabBasedAdminsString = JSON.stringify(updatedTabBasedAdmins);

    if (currentTabBasedAdminsString !== updatedTabBasedAdminsString) {
      setTabBasedActiveAdmins(updatedTabBasedAdmins);
    }
  }, [updatedTabBasedAdmins, tabBasedActiveAdmins, setTabBasedActiveAdmins]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        {activeUserRoles.map(activeUserRole => {
          return (
            <Badge badgeContent={activeUserRole.userCount} color={activeUserRole.badgeColor} sx={{ '& .MuiBadge-badge': { fontSize: 10 } }}>
              <Stack
                id={activeUserRole.role}
                key={activeUserRole.role}
                component="div"
                justifyContent="center"
                alignItems="center"
                borderRadius={50}
                width={30}
                height={30}
                p={2}
                bgcolor={activeUserRole.userCount > 0 ? activeUserRole.bgColor : color.grey300}
                onMouseEnter={e => handlePopoverOpen(e, activeUserRole.role, activeUserRole.activeAdmins)}
                onMouseLeave={handlePopoverClose}
                sx={{ cursor: 'pointer' }}
                hidden={activeUserRole.userCount <= 0}
              >
                <activeUserRole.Icon
                  style={{ color: activeUserRole.userCount > 0 ? color.white : color.grey500 }}
                  fontSize="small"
                  sx={{ userSelect: 'none', pointerEvents: 'none' }}
                />
              </Stack>
            </Badge>
          );
        })}
      </Stack>
      {activeAdminData.adminRole && activeAdminData.activeAdmins && (
        <ActiveUserPopover
          id={'active-admin-popover'}
          open={Boolean(activeAdminData.anchorEl)}
          anchorEl={activeAdminData.anchorEl}
          handleClose={handlePopoverClose}
          role={activeAdminData.adminRole}
          activeAdmins={activeAdminData.activeAdmins}
        />
      )}
    </>
  );
};

export default RpmActiveUsers;
