export const stopEvent = (ev?: React.UIEvent<any>) => {
  if (ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }
};

export const pauseEvent =
  <T extends any>(cb: () => T) =>
  (ev?: React.UIEvent<any>): T => {
    stopEvent(ev);
    return cb();
  };

// Determines if the platform specific toggle selection in group key was used
export const controlOrMetaKeyUsed = (event: MouseEvent | KeyboardEvent) => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
  return isUsingWindows ? event.ctrlKey : event.metaKey;
};

// Determines if the multiSelect key was used
export const shiftKeyUsed = (event: MouseEvent | KeyboardEvent) => event.shiftKey;
