import PerformanceDashboardTable from '@/components/PerformanceDashboardTable/PerformanceDashboardTable';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import { GetAdminPerformancesResponse, GET_ADMIN_PERFORMANCE } from '@/graphql/administration';
import { PerformanceTableType } from '@/types/administration';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import React, { useContext, useEffect } from 'react';

export const MyPerformance: React.FC = () => {
  const { setContent } = useContext(SideMenuContext);
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const { data: performanceData, loading: performanceDataLoading } = useQuery<GetAdminPerformancesResponse>(GET_ADMIN_PERFORMANCE, {
    variables: {
      relyingPartyAdminId: currentUser?.id
    },
    skip: !currentUser?.id
  });

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, []);

  return (
    <Stack spacing={1} width="100%" height="100%">
      <PerformanceDashboardTable performanceData={performanceData} tableType={PerformanceTableType.SELF} />
    </Stack>
  );
};

export default MyPerformance;
