import { DEACTIVATE_RELYING_PARTY_ADMIN } from '@/graphql/administration';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Alert from '../Alert';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  id: string;
};

const UserDeactivateDialog = ({ title, hideDialog, id, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [deactivateUser] = useMutation(DEACTIVATE_RELYING_PARTY_ADMIN);

  const onDeactivateUser = async () => {
    try {
      const result: any = await deactivateUser({
        variables: {
          relyingPartyAdminId: id,
          isActive: false
        }
      });

      const data = result.data!.deactivateRelyingPartyAdmin;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, {
        variant
      });
      hideDialog();
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to update user', {
        variant: 'error'
      });
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Alert
          title="Are you sure ?"
          description={
            'This action will disable this user’s account on the portal. Please contact Tech Support if this account will need to be enabled again.'
          }
          status={'warning'}
        />

        <Stack direction="row" sx={{ justifyContent: 'flex-end', alignItems: 'center' }} spacing={1}>
          <DialogActions sx={{ justifyContent: 'end', padding: '30px 0 0 0' }}>
            <Button type="submit" onClick={onDeactivateUser} a11yLabel={'Deactivate'} appearance="primary" />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default UserDeactivateDialog;
