import Button from '@/components/Button';
import { LoadMessageContextResponse, LOAD_MESSAGE_CONTEXT, SEND_COPILOT_MESSAGE } from '@/graphql/coPilot';
import { SenderType, Template } from '@/types/coPilot';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useMutation, useReactiveVar } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Card, CardHeader, CircularProgress, Stack, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';

type Props = {
  title: string;
  onClose: () => void;
};

const CoPilotWindow = ({ title, onClose }: Props) => {
  const { id } = useParams();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const messages = useCopilotMessageStore(state => state.messages);
  const addMessage = useCopilotMessageStore(state => state.addMessage);

  const [templates] = useState<Template[]>([
    { title: "Summarize Patient's Data", enabled: true },
    { title: 'Hi', enabled: true }
  ]);

  const [input, setInput] = useState<string>('');
  const [contextLoadError, setContextLoadError] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);

  const [loadMessageContext] = useMutation<LoadMessageContextResponse>(LOAD_MESSAGE_CONTEXT, {
    variables: {
      truentityId: id,
      relyingPartyId: currentUser?.relyingParty?.id
    },
    onError: error => {
      console.error('Error loading message context:', error.message);
      setContextLoadError(true);
    }
  });

  const [sendMessage] = useMutation(SEND_COPILOT_MESSAGE, {
    onCompleted: () => {
      setSendMessageLoading(false);
    },
    onError: error => {
      console.error('Send Message Error:', error.message);
      addMessage({
        sender: SenderType.BOT,
        text: 'There was an error sending your message. Please try again later. 😞'
      });
      setSendMessageLoading(false);
    }
  });

  const handleSend = () => {
    if (!input.trim()) {
      return;
    }

    addMessage({ sender: SenderType.USER, text: input });
    setInput('');
    setSendMessageLoading(true);

    sendMessage({
      variables: {
        truentityId: id,
        relyingPartyId: currentUser?.relyingParty.id,
        message: input
      }
    }).catch(error => {
      console.error('Error caught in handleSend:', error.message);
    });
  };

  const handleClose = () => {
    onClose();
  };

  const onTemplateSelected = (template: Template) => {
    setInput(template.title);
  };

  useEffect(() => {
    loadMessageContext();
  }, [loadMessageContext]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <Card
      elevation={14}
      sx={{
        zIndex: theme.zIndex.drawer + 2,
        position: 'fixed',
        bottom: 10,
        right: 40,
        width: 650,
        background: '#fff'
      }}
    >
      <CardHeader
        title={title}
        action={
          <IconButton aria-label="Close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Box sx={{ marginLeft: 2, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {templates.map((template, index) => (
          <Button
            key={index}
            variant="outlined"
            color="primary"
            disabled={!template.enabled}
            onClick={() => onTemplateSelected(template)}
            sx={{ fontSize: '0.8rem' }}
          >
            {template.title}
          </Button>
        ))}
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 500,
          overflow: 'auto',
          padding: 1
        }}
      >
        <Stack
          direction="column"
          sx={{
            border: '1px solid',
            borderColor: theme.palette.grey[300],
            borderRadius: 2,
            height: '380px',
            overflowY: 'auto',
            padding: 1
          }}
          spacing={2}
          my={1}
        >
          {messages.length > 0 ? (
            messages.map((msg, index) => (
              <Stack key={index} direction={msg.sender === SenderType.BOT ? 'row' : 'row-reverse'} spacing={1}>
                <Box
                  sx={{
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: msg.sender === SenderType.BOT ? theme.palette.secondary.dark : theme.palette.primary.dark,
                    color: '#fff',
                    maxWidth: '70%',
                    position: 'relative',
                    overflow: 'visible'
                  }}
                >
                  {sendMessageLoading && msg.sender === SenderType.USER && index === messages.length - 1 && (
                    <CircularProgress
                      size={15}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '-20px',
                        marginTop: '-7.5px',
                        zIndex: 1
                      }}
                    />
                  )}
                  <Markdown>{msg.text}</Markdown>
                </Box>
              </Stack>
            ))
          ) : (
            <Box sx={{ textAlign: 'center', paddingTop: '20px' }}>No messages yet. Start the conversation!</Box>
          )}
          <div ref={messagesEndRef} />
        </Stack>

        <Stack
          direction="row"
          component="form"
          onSubmit={e => {
            e.preventDefault();
            handleSend();
          }}
        >
          <TextField
            value={input}
            onChange={e => setInput(e.target.value)}
            placeholder={contextLoadError ? 'Error loading context. Try again later.' : 'Type a message...'}
            variant="outlined"
            fullWidth
            sx={{ marginRight: '8px' }}
            disabled={contextLoadError || sendMessageLoading}
          />
          <IconButton type="submit" disabled={!input.trim() || sendMessageLoading || contextLoadError}>
            <SendIcon />
          </IconButton>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CoPilotWindow;
