import { makeVar } from '@apollo/client';

import type { LoggedUser, LoggedUserAccountData, RelyingParty } from '@/types/graphql';
import client from '@/util/apollo/client';
import { getVarPersisted } from './reactive-var';

export const currentLoggedUserVar = getVarPersisted(makeVar<LoggedUser | null>(null), 'th_currentLoggedUserVar');
export const currentLoggedRelyingParty = getVarPersisted(makeVar<RelyingParty | null>(null), 'th_currentRP');

export const loggedUserData = makeVar<LoggedUserAccountData | null>(null);

export const tempDueDate = makeVar(''); //TODO: BR -> This is a hack for the demo, we need to make sure we are updating value properly and updating as needed

// TODO
// this can be moved into the 'query' way of getting cached data
// https://www.apollographql.com/blog/apollo-client/caching/local-state-management-with-reactive-variables/
export const tempCalendarActiveStart = makeVar<Date>(new Date());

export const clearCache = async () => {
  currentLoggedUserVar(null);
  loggedUserData(null);
  currentLoggedRelyingParty(null);
  await client.cache.reset();
};
