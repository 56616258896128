import { H2 } from '@/components/Typography';
import type { PaperProps, StackProps } from '@mui/material';
import { Paper, Stack } from '@mui/material';
import type { ReactNode } from 'react';

type DashboardSectionProps = PaperProps &
  StackProps & {
    title?: ReactNode;
    children: ReactNode;
  };

const DashboardSection = ({ title, children, ...props }: DashboardSectionProps) => {
  return (
    <Paper component={Stack} spacing={2} sx={{ padding: theme => theme.spacing(2) }} {...props}>
      {title && <H2>{title}</H2>}
      {children}
    </Paper>
  );
};

export default DashboardSection;
