import Button from '@/components/Button';
import TruentityTextField from '@/components/TruentityTextField';
import { H3, H5 } from '@/components/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Grid, IconButton, Stack, Tooltip } from '@mui/material';
import type React from 'react';
import type { BaseSyntheticEvent } from 'react';
import type { Control } from 'react-hook-form';
import { Controller, useFieldArray } from 'react-hook-form';

export type AddClientOrgFormValuesType = {
  name: string;
  clientStores: Array<{
    name: string;
    nameTag: string;
  }>;
};

export const addClientOrgFormDefault: AddClientOrgFormValuesType = {
  name: '',
  clientStores: []
};

export type ClientOrgFormProps = {
  control: Control<AddClientOrgFormValuesType>;
  onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
};

export const ClientOrgForm: React.FC<ClientOrgFormProps> = ({ control, onSubmit }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'clientStores'
  });

  const handleAddLocation = () => {
    append({ name: '', nameTag: '' });
  };

  const handleRemoveLocation = (index: number) => {
    remove(index);
  };

  return (
    <Stack component={'form'} spacing={2} p={3} onSubmit={onSubmit}>
      <H3>Add Client Organization</H3>
      <Grid container m={1}>
        <Grid item xs={12}>
          <H5>Organization</H5>
        </Grid>
        <Grid
          sx={{
            marginTop: 2
          }}
          item
          xs={12}
        >
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Client Organization name is required' }}
            render={({ field: { onChange, value } }) => (
              <TruentityTextField fullWidth={true} maxLength={128} required onChange={onChange} value={value} label={'Organization Name'} />
            )}
          />
        </Grid>
        <Grid
          sx={{
            marginY: 2
          }}
          item
          xs={12}
        >
          <H5>Locations</H5>
        </Grid>
        {fields.map((field, index) => (
          <Grid
            sx={{
              marginY: 0.5
            }}
            key={field.id}
            item
            xs={12}
          >
            <Grid
              container
              spacing={2}
              sx={{
                width: '100%',
                height: 'auto'
              }}
            >
              <Grid item xs={4}>
                <Controller
                  control={control}
                  rules={{ required: 'Location name is required' }}
                  name={`clientStores.${index}.name`}
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField autoFocus required onChange={onChange} value={value} label={'Name'} />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  control={control}
                  rules={{ required: 'Location name tag is required' }}
                  name={`clientStores.${index}.nameTag`}
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField required onChange={onChange} value={value} label={'Name Tag'} />
                  )}
                />
              </Grid>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                item
                xs={2}
              >
                <Tooltip title="Remove Location">
                  <IconButton
                    sx={{
                      border: '2px solid',
                      borderColor: 'primary.main',
                      borderRadius: '50%',
                      padding: 0.5
                    }}
                    onClick={() => handleRemoveLocation(index)}
                  >
                    <RemoveIcon color={'primary'} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ))}
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: fields?.length > 0 ? 'center' : 'flex-start',
            alignItems: 'center'
          }}
          item
          xs={8}
        >
          <Button
            sx={{
              marginTop: 1
            }}
            startIcon={<AddIcon color={'primary'} />}
            type="button"
            a11yLabel="Add Location"
            color="primary"
            variant="outlined"
            onClick={handleAddLocation}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ClientOrgForm;
