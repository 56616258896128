import { secondsToTime } from '@/util/format';
import { getColorForTotalReadings } from '@/util/rpm';
import { Badge, Chip } from '@mui/material';

interface ActiveAlertsRowProps {
  totalTime: number;
  totalReadings: number;
}

export const ActiveAlertsRow = ({ totalTime, totalReadings }: ActiveAlertsRowProps) => {
  const color = getColorForTotalReadings(totalReadings);
  return (
    <>
      <Badge
        sx={{ '& .MuiBadge-badge': { fontSize: 11 } }}
        badgeContent={totalReadings ? `${totalReadings?.toString()}/16` : '0/16'}
        color={color}
      >
        <Chip label={'Monthly reading days'} variant={'outlined'} color={'primary'} />
      </Badge>
      <Badge sx={{ '& .MuiBadge-badge': { fontSize: 11 } }} badgeContent={secondsToTime(totalTime)} color={'info'}>
        <Chip label={'Time spent this month'} variant={'outlined'} color={'primary'} />
      </Badge>
    </>
  );
};
