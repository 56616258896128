import MuiTabs from '@/components/MuiTabs';
import { H1, H3 } from '@/components/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import PersonIcon from '@mui/icons-material/Person';
import RoomIcon from '@mui/icons-material/Room';
import { Collapse, Divider, Grid, IconButton, Paper, Stack } from '@mui/material';
import { useState } from 'react';

import { GridItem } from '@/components/GridItem';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getEmail, getTelephone } from '../..';

type User = {
  lastName: string;
  firstName: string;
  email: string;
};

type PatientObject = {
  id?: string;
  truentityId?: string;
};

type ClientStoreObject = {
  name?: string;
  nameTag?: string;
  id?: string;
};

type ClientOrganizationObject = {
  name?: string;
  id?: string | undefined;
  clientStores: ClientStoreObject[];
  accounts: PatientObject[];
  createdAt?: string;
};

export type PatientInfo = {
  user: User;
  address: string;
  birthDate: string;
  phone: string;
  gender: string;
  numQuickUploadsNew: number;
  doNotCall: boolean;
  doNotCallLastSpecifiedAt: Date | null;
  accountsFollowupReminders: FollowUpRemindersTypes[];
  primaryLanguage: string;
  consent: boolean;
};

const StoreInfo = ({ clientStoreDetails }) => {
  const [collapsePatientInfo, setCollapsePatientInfo] = useState(true);

  return (
    <Paper component={Stack} p={4} spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems={'center'} spacing={2}>
          <H1>{clientStoreDetails && clientStoreDetails.name} </H1>
        </Stack>

        <Stack
          sx={{ cursor: 'pointer' }}
          onClick={() => setCollapsePatientInfo(!collapsePatientInfo)}
          spacing={1}
          direction="row"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <IconButton>
            <ExpandMoreIcon sx={{ transform: collapsePatientInfo ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          </IconButton>
        </Stack>
      </Stack>

      <H3>{clientStoreDetails.nameTag}</H3>

      {!collapsePatientInfo && <Divider sx={{ marginTop: '10px' }} />}

      <Collapse in={!collapsePatientInfo} timeout="auto">
        <MuiTabs
          tabs={[
            {
              label: 'Profile',
              children: (
                <Stack spacing={4}>
                  <Grid container rowSpacing={4}>
                    <GridItem xs={6} icon={<MailIcon fontSize={'small'} />} label="Email" value={getEmail(clientStoreDetails)} />
                    <GridItem
                      xs
                      icon={<PersonIcon fontSize={'small'} />}
                      label="# of Patients"
                      value={clientStoreDetails?.accounts?.length || 0}
                    />
                    <GridItem
                      xs={6}
                      icon={<LocalPhoneIcon fontSize={'small'} />}
                      label="Phone"
                      value={<TruentityPhoneNumber value={getTelephone(clientStoreDetails)} label={''} />}
                    />
                    <GridItem
                      xs={6}
                      icon={<RoomIcon fontSize={'small'} />}
                      label="Location"
                      value={clientStoreDetails?.addresses?.length > 0 ? clientStoreDetails?.addresses[0].line1 : '---'}
                    />
                  </Grid>
                </Stack>
              )
            }
          ]}
        />
      </Collapse>
    </Paper>
  );
};

export default StoreInfo;
