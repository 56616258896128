import NotificationContext from '@/context/notificationContext';
import { useContext } from 'react';

const useMessages = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }

  return context;
};

export default useMessages;
