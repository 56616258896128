import styled from '@/styles';
import React from 'react';
import type { Column as ColumnType, Id, IdMap, Task as TaskType } from '../../types/triage-medication-types';
import Task from './task';

import { Stack } from '@mui/material';
import memoizeOne from 'memoize-one';
import { Droppable } from 'react-beautiful-dnd';
import { H3, Small } from '../Typography';

type Props = {
  column: ColumnType;
  tasks: TaskType[];
  selectedTaskIds: Id[];
  draggingTaskId: Id | null;
  toggleSelection: (taskId: Id) => void;
  toggleSelectionInGroup: (taskId: Id) => void;
  multiSelectTo: (taskId: Id) => void;
  goToMedicationById: (id: string) => void;
};

const TaskColumn = styled('div', {
  padding: '8px',
  borderRadius: '8px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  userSelect: 'none'
});

const TaskList = styled('div', {
  padding: '8px',
  marginTop: '8px',
  borderRadius: '8px',
  transition: 'background-color 0.2s ease',
  backgroundColor: 'lightgrey',
  border: '1px solid lightgrey',
  flexGrow: 1,
  minHeight: '200px',
  variants: {
    isDraggingOver: {
      true: {
        backgroundColor: '$colors$themeDefault'
      },
      false: {}
    }
  }
});

const getSelectedMap = memoizeOne((selectedTaskIds: Id[]) =>
  selectedTaskIds.reduce((previous: IdMap, current: Id): IdMap => {
    previous[current] = true;
    return previous;
  }, {})
);

const ColumnTasks: React.FC<any> = React.memo(
  ({ tasks, selectedTaskIds, toggleSelection, toggleSelectionInGroup, multiSelectTo, goToMedicationById }) => {
    return tasks.map((task: TaskType, index: number) => {
      const isSelected: boolean = Boolean(getSelectedMap(selectedTaskIds)[task.id]);
      return (
        <Task
          task={task}
          index={index}
          key={task.id}
          isSelected={isSelected}
          selectionCount={selectedTaskIds.length}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          multiSelectTo={multiSelectTo}
          goToMedicationById={goToMedicationById}
        />
      );
    });
  }
);

const Column = (props: Props) => {
  const { column } = props;

  return (
    <TaskColumn>
      <Stack direction="row" alignItems="center" spacing={1}>
        <H3 css={{ textAlign: 'center' }}>{column.title}</H3>
        <Small> (Count: {props.tasks.length})</Small>
      </Stack>
      <Droppable droppableId={column.id}>
        {(provided: any, snapshot: any) => (
          <TaskList ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver} {...provided.droppableProps}>
            <ColumnTasks {...props} />
            <span
              style={{
                display: 'none'
              }}
            >
              {provided.placeholder}
            </span>
          </TaskList>
        )}
      </Droppable>
    </TaskColumn>
  );
};

export default Column;
