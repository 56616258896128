import type { CalendarOptions, DatesSetArg, EventContentArg, EventSourceInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import multiMonthPlugin from '@fullcalendar/multimonth';
import FullCalendar from '@fullcalendar/react';

import { Body2, Small } from './Typography';

import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import 'bootstrap/dist/css/bootstrap.css';

import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { useCallback, useEffect, useRef, useState } from 'react';

import { tempCalendarActiveStart } from '@/util/apollo/cache';
import { useMutation, useReactiveVar } from '@apollo/client';

import type { UpdateAccountFollowupResponse } from '@/graphql/account';
import { FollowUpStatusTypes, UPDATE_ACCOUNT_FOLLOWUP } from '@/graphql/account';
import styled from '@/styles';
import { Box, Checkbox, Stack } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import type { PopoverDataType } from './CalendarPopover';
import CalendarPopover from './CalendarPopover';

export type CalendarEvent = EventSourceInput;

type Props = CalendarOptions & {
  onMonthChange: (date: Date) => void;
  setIsFollowUpUpdated: (followUpsUpdated: boolean) => void;
  onEventClick: (event: EventContentArg) => void;
};

type RenderEventContentProps = {
  eventInfo: EventContentArg;
  handleStatusChange: ({ followUpId, assigneeId, checked }: { followUpId: string; assigneeId: string; checked: boolean }) => void;
  onEventClick: (event: EventContentArg) => void;
};
const renderEventContent = ({ eventInfo, handleStatusChange, onEventClick }: RenderEventContentProps) => {
  const { firstName } = eventInfo?.event?.extendedProps?.relyingPartyAdmin?.user ?? '';

  return (
    <>
      <Stack
        onClick={() => {
          onEventClick(eventInfo);
        }}
        p={0.5}
        alignItems="flex-start"
        sx={{ cursor: 'pointer' }}
      >
        <Body2
          component="span"
          lineHeight="1rem"
          style={{ color: 'white', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '100%', display: 'block' }}
        >
          {eventInfo.event?.title}
        </Body2>

        {eventInfo.event?.extendedProps?.type && (
          <Small component="span" lineHeight="1rem" fontSize="11px" style={{ color: 'white', textTransform: 'capitalize' }}>
            {`${eventInfo.event?.extendedProps?.type}`}
            {eventInfo.event?.extendedProps?.subType && `, ${eventInfo.event?.extendedProps?.subType}`}
          </Small>
        )}
        <Small component="span" lineHeight="1rem" fontSize="11px" style={{ color: 'white' }}>
          Assigned to: {firstName}
        </Small>
        <Stack marginY={1} width="100%" direction="row" justifyContent="space-between">
          <Box>
            <Box height="auto" px={1.5} mt={1} bgcolor="#00000045" borderRadius="50px">
              <Small component="span" lineHeight="1rem" fontSize="11px" style={{ color: 'white' }}>
                {eventInfo.event?.extendedProps?.time}
              </Small>
            </Box>
          </Box>
        </Stack>
      </Stack>
      <Checkbox
        className="followup-status-checkbox"
        checked={eventInfo.event?.extendedProps?.status === FollowUpStatusTypes.COMPLETED}
        onChange={event => {
          handleStatusChange({
            followUpId: eventInfo.event?.extendedProps?.uuid,
            assigneeId: eventInfo.event?.extendedProps?.relyingPartyAdmin.id,
            checked: event.target.checked
          });
        }}
        sx={{
          p: 0,
          m: 1,
          position: 'absolute',
          bottom: 0,
          right: 0,
          zIndex: 10,
          color: 'white',
          '&.Mui-checked': {
            color: 'white'
          }
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </>
  );
};

export const StyledCalendarWrapper = styled('div', {
  '.fc-more-popover': {
    maxHeight: '85vh',
    overflowY: 'auto'
  }
});

const Calendar = ({ events, loading, onMonthChange, setIsFollowUpUpdated, onEventClick, ...props }: Props) => {
  const calendarRef = useRef<FullCalendar>(null);
  const tempCalendarActiveStartPersisted = useReactiveVar(tempCalendarActiveStart);
  const { enqueueSnackbar } = useSnackbar();

  const [updateAccountFollowUp] = useMutation<UpdateAccountFollowupResponse>(UPDATE_ACCOUNT_FOLLOWUP);

  const [selectedView, setSelectedView] = useState(localStorage.getItem('selectedView') || 'dayGridMonth');
  const [activeStart, setActiveStart] = useState(new Date());
  const [popoverData, setPopoverData] = useState<PopoverDataType>({} as PopoverDataType);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleStatusChange = useCallback(
    async ({ followUpId, assigneeId, checked }: { followUpId: string; assigneeId: string; checked: boolean }) => {
      try {
        const response = await updateAccountFollowUp({
          variables: {
            followUpIds: [followUpId],
            followUpAssigneeIds: [assigneeId],
            status: checked ? FollowUpStatusTypes.COMPLETED : FollowUpStatusTypes.PENDING
          }
        });
        if (response.data?.updateAccountFollowup?.status === 'Success') {
          enqueueSnackbar('Followup status updated', { variant: 'success' });
          setIsFollowUpUpdated(true);
        } else {
          enqueueSnackbar(
            response?.errors !== undefined && response.errors?.length > 0
              ? response.errors[0]?.message
              : 'Could not update followup status',
            {
              variant: 'error'
            }
          );
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Could not update followup status', { variant: 'error' });
      }
    },
    [updateAccountFollowUp, enqueueSnackbar, setIsFollowUpUpdated]
  );

  const handlePopoverOpen = eventInfo => {
    const popoverData: PopoverDataType = {
      name: eventInfo.event?.title,
      type: eventInfo.event?.extendedProps?.type,
      subType: eventInfo.event?.extendedProps?.subType,
      notes: eventInfo.event?.extendedProps?.notes,
      relyingPartyAdmin: eventInfo?.event?.extendedProps?.relyingPartyAdmin?.user
    };
    if (eventInfo.event?.extendedProps?.notes && eventInfo.event?.extendedProps?.notes.trim().length > 0) {
      setAnchorEl(eventInfo.el);
      setPopoverData(popoverData);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handelDateSet = (arg: DatesSetArg) => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const currentDate = calendarApi.getDate();
      const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

      setActiveStart(firstDayOfMonth);
      tempCalendarActiveStart(firstDayOfMonth);
    } else {
      setActiveStart(arg.view.activeStart);
      tempCalendarActiveStart(arg.view.activeStart);
    }
  };

  useEffect(() => {
    localStorage.setItem('selectedView', selectedView);
  }, [selectedView]);

  useEffect(() => {
    onMonthChange(tempCalendarActiveStartPersisted);
  }, [activeStart, onMonthChange, tempCalendarActiveStartPersisted]);

  useEffect(() => {
    if (events?.length > 0) {
      const sortedEvents = events?.sort((a, b) => new Date(a?.start).getTime() - new Date(b?.start).getTime());

      if (sortedEvents.length > 0) {
        setActiveStart(new Date(sortedEvents[0].start));

        const calendarApi = calendarRef?.current?.getApi();
        calendarApi && calendarApi.gotoDate(moment(new Date(sortedEvents[0].start)).format('YYYY-MM-DD'));
        // calendarApi.gotoDate("2000-01-01");
      }
    }
  }, [events]);

  return (
    <>
      <StyledCalendarWrapper>
        <FullCalendar
          ref={calendarRef}
          loading={loading}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,multiMonthYear listWeek'
          }}
          plugins={[dayGridPlugin, momentPlugin, bootstrap5Plugin, listPlugin, multiMonthPlugin]}
          events={events}
          initialView={selectedView}
          initialDate={activeStart}
          eventContent={eventInfo =>
            renderEventContent({
              eventInfo,
              handleStatusChange,
              onEventClick: event => {
                handlePopoverClose();
                onEventClick(event);
              }
            })
          }
          // 2023-11-29 Comment to improve Patient Selection
          // dayCellDidMount={(cell: DayCellMountArg) =>
          //   renderCalendarAddIcon({
          //     cell,
          //     handleAddIconClick
          //   })
          // }
          // tried these props, but they don't seem to work
          // visibleRange={{
          //   start: '2020-03-22',
          //   end: '2020-03-25'
          // }}
          // initialDate={initialDate}
          // initialDate={moment(tempCalendarActiveStartPersisted).format('YYYY-MM-DD')}
          themeSystem={'bootstrap5'}
          viewDidMount={viewInfo => setSelectedView(viewInfo.view.type)}
          datesSet={arg => handelDateSet(arg)}
          eventMouseEnter={handlePopoverOpen}
          eventMouseLeave={handlePopoverClose}
          eventOrder="eventTime"
          dayMaxEvents={3}
          multiMonthMaxColumns={1}
          height={'auto'}
          {...props}
        />
      </StyledCalendarWrapper>
      <CalendarPopover open={open} onClose={handlePopoverClose} anchorEl={anchorEl} popoverData={popoverData} />
    </>
  );
};

export default Calendar;
