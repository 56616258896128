import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { Stack } from '@mui/material';
import { TaskStatuses } from './AdtQueue';

type DueDateFiltersProps = {
  setFilterDate: (val: Date | undefined) => void;
  filterDate: Date | undefined;
  filterInitialCommDue: Date | undefined;
  setFilterInitialCommDue: (val: Date | undefined) => void;
  setReadmissionStatusOption: (val) => void;
  readmissionStatusOption;
  selectedStatus;
};

export enum ReadmissionOptions {
  Any = 'any',
  High = 'high',
  Low = 'low'
}

export type ReadmiSsionStatus = ReadmissionOptions.Any | ReadmissionOptions.High | ReadmissionOptions.Low;

export const DueDateFilters = ({
  setFilterDate,
  filterDate,
  setFilterInitialCommDue,
  filterInitialCommDue,
  setReadmissionStatusOption,
  readmissionStatusOption,
  selectedStatus
}: DueDateFiltersProps) => {
  return (
    <Stack direction="row" alignItems={'center'} justifyContent={'flex-start'} spacing={2} p={2}>
      {selectedStatus === TaskStatuses.Pending && (
        <TruentityDatePicker
          label="Initial Communication due by"
          value={filterInitialCommDue}
          onChange={newValue => {
            setFilterInitialCommDue(newValue as Date);
          }}
        />
      )}

      {selectedStatus === TaskStatuses.InProgress && (
        <TruentityDatePicker
          label="TCM Final due by"
          value={filterDate}
          onChange={newValue => {
            setFilterDate(newValue as Date);
          }}
        />
      )}

      {selectedStatus === TaskStatuses.Pending || selectedStatus === TaskStatuses.InProgress ? (
        <SelectList
          formControlProps={{ sx: { minWidth: '200px' } }}
          label="Readmission Risk"
          options={Object.entries(ReadmissionOptions).map(([key, val]) => ({
            label: val.toUpperCase(),
            value: val
          }))}
          placeholder="Select an option..."
          value={readmissionStatusOption}
          onChange={event => setReadmissionStatusOption(event.target.value as ReadmiSsionStatus)}
          fullWidth={false}
        />
      ) : (
        ''
      )}
    </Stack>
  );
};
