import type { PaletteOptions } from '@mui/material';

export function themePalette(theme): PaletteOptions | undefined {
  return {
    mode: theme.customization.navType,
    common: {
      black: '#000000'
    },
    primary: {
      light: theme.colors.primaryLight,
      main: theme.colors.primaryMain,
      dark: theme.colors.primaryDark,
      200: theme.colors.primary200,
      300: theme.colors.primary300,
      400: theme.colors.primary400,
      600: theme.colors.primary600,
      800: theme.colors.primary800,
      contrastText: theme.colors.white
    },
    secondary: {
      light: theme.colors.secondaryLight,
      main: theme.colors.secondaryMain,
      dark: theme.colors.secondaryDark,
      200: theme.colors.secondary200,
      800: theme.colors.secondary800,
      contrastText: theme.colors.white
    },
    grey: {
      50: theme.colors.grey50,
      100: theme.colors.grey100,
      300: theme.colors.grey300,
      400: theme.colors.grey400,
      500: theme.darkTextSecondary,
      600: theme.heading,
      700: theme.darkTextPrimary,
      900: theme.textDark
    },
    text: {
      primary: theme.darkTextPrimary,
      secondary: theme.darkTextSecondary,
      disabled: theme.colors.grey700
    },
    background: {
      paper: theme.paper,
      default: theme.backgroundDefault
    }
  };
}
