import { pathnameIncludes } from '@/util/location';
import type { ListItemButtonOwnProps } from '@mui/material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import type { To } from 'history';
import type { ReactNode } from 'react';
import { NavLink, useResolvedPath } from 'react-router-dom';

type ListItemNavLinkProps = {
  to: To;
  title: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
  ListItemButtonProps?: ListItemButtonOwnProps;
};
const MenuItemNavLink = ({ to, title, icon, onClick, ...props }: ListItemNavLinkProps) => {
  const resolved = useResolvedPath(to);
  return (
    <ListItemButton
      component={NavLink}
      to={to}
      selected={pathnameIncludes(resolved.hash || resolved.search || resolved.pathname)}
      onClick={onClick}
      {...props.ListItemButtonProps}
      sx={{
        gap: 1,
        ...props.ListItemButtonProps?.sx
      }}
    >
      {icon && (
        <ListItemIcon
          sx={{
            minWidth: 'auto'
          }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText>{title}</ListItemText>
    </ListItemButton>
  );
};

export default MenuItemNavLink;
