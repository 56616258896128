import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { SendMedRecEmailDialog } from '@/components/Dialogs';
import SendEHRDialog from '@/components/Dialogs/SendEHRDialog';
import MuiTabs from '@/components/MuiTabs';
import { Body2 } from '@/components/Typography';
import { GET_MEDREC_INTERACTIONS, GET_MED_REC_SNAPSHOT_DETAIL, SNAPSHOT_REPORT_EXPORT } from '@/graphql/medication';
import styled from '@/styles';
import { goToPdfUrl } from '@/util/file';
import { formatDateAndTime } from '@/util/format';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HistoryTable from './historyTable';
import MedInteractions from './medInteractions';

const TabContent = styled('span', {
  display: 'none',
  height: '100%',

  '&.active': {
    display: 'block'
  }
});

const PDFViewer = styled('iframe', {
  width: '100%',
  height: '100%',
  minHeight: '600px'
});

const ButtonMedRec = styled('div', {
  '& button': {
    width: '100%'
  },
  '@tablet': {
    marginBottom: 0,
    marginRight: '1.25rem'
  }
});

const ButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& button': {
    marginBottom: '1rem'
  },

  '@tablet': {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
});

const MedRecHistory = () => {
  const { medRecId, id } = useParams();

  const [getInfo, { data, loading, called }] = useLazyQuery(GET_MED_REC_SNAPSHOT_DETAIL);

  const [createPdfMedrecSnapshotReport, { data: pdfReportData }] = useMutation(SNAPSHOT_REPORT_EXPORT);

  const [getInteractions, { data: dataInteractions, loading: loadingInteractions, called: calledInteractions }] =
    useLazyQuery(GET_MEDREC_INTERACTIONS);

  const [medRecSnapshotDetail, setMedRecSnapshotDetail] = useState<[]>([]);

  const [medInteractions, setMedInteractions] = useState<[]>([]);

  const [author, setAuthor] = useState<string>('');
  const [date, setDate] = useState<string>('---');
  const [medRecName, setMedRecName] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const PDFMessage = 'patient-report-med-rec';
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState('');

  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const { showModal } = useModal();

  const showSendEHRDialog = () => {
    const medRecSnapshotName = `${data.medrecSnapshot.medrecSnapshot.name}` || '';
    const modalRef = showModal(SendEHRDialog, {
      title: 'Send MedRec to EHR',
      successMessage: 'MedRec document has been sent to the EHR',
      truentityId: id,
      medRecSnapshotId: medRecId,
      medRecSnapshotName: medRecSnapshotName,
      hideDialog: () => modalRef.hide()
    });
  };

  const showMedRecEmailModal = () => {
    const medRecModal = showModal(SendMedRecEmailDialog, {
      truentityId: id,
      title: 'Email MedRec PDF',
      successMessage: 'MedRec document has been emailed to the recipients',
      medrecSnapshotId: medRecId,
      medRecSnapshotName: medRecName,
      hideDialog: () => medRecModal.hide()
    });
  };

  const getPdfUrl = (url: string) => {
    fetch(`${url}`)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        setPdfUrl(url);
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (pdfDownloadUrl && pdfDownloadUrl.length > 0) {
      getPdfUrl(pdfDownloadUrl);
    }
  }, [pdfDownloadUrl]);

  useEffect(() => {
    if (data && data.medrecSnapshot && data.medrecSnapshot.accountMedications) {
      setMedRecSnapshotDetail(data.medrecSnapshot.accountMedications);
    }

    if (data && data.medrecSnapshot && data.medrecSnapshot.medrecSnapshot) {
      if (data.medrecSnapshot.meta) {
        setTotalCount(data.medrecSnapshot.meta.totalCount);
        setTotalPages(data.medrecSnapshot.meta.totalPages);
      }

      if (data.medrecSnapshot.medrecSnapshot.relyingPartyAdmin) {
        setAuthor(data.medrecSnapshot.medrecSnapshot.relyingPartyAdmin.name);
      }
      if (data.medrecSnapshot.medrecSnapshot.createdAt) {
        setDate(data.medrecSnapshot.medrecSnapshot.createdAt);
      }
      if (data.medrecSnapshot.medrecSnapshot.name) {
        setMedRecName(data.medrecSnapshot.medrecSnapshot.name);
      }
    }
  }, [data]);

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  useEffect(() => {
    getInfo({
      variables: {
        medrecSnapshotId: medRecId,
        truentityId: id,
        pageNum: currentPage,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, [currentPage, medRecId]);

  useEffect(() => {
    createPdfMedrecSnapshotReport({
      variables: {
        medrecSnapshotId: medRecId
      }
    });
  }, [medRecId]);

  useEffect(() => {
    if (pdfReportData && pdfReportData.createPdfMedrecSnapshotReport) {
      const { pdfDownloadUrl } = pdfReportData.createPdfMedrecSnapshotReport;
      setPdfDownloadUrl(pdfDownloadUrl);
      setPdfLoaded(true);
    }
  }, [pdfReportData]);

  useEffect(() => {
    if (dataInteractions && dataInteractions.medrecInteractions) {
      setMedInteractions(dataInteractions.medrecInteractions);
    }
  }, [dataInteractions]);

  useEffect(() => {
    getInteractions({
      variables: {
        medrecSnapshotId: medRecId,
        truentityId: id,
        pageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, [currentPage, medRecId]);

  const onSendEmail = useCallback(() => {
    showMedRecEmailModal();
  }, []);

  return (
    <Card>
      <CardHeader
        title="Medrec History"
        component={Stack}
        direction="row"
        subheader={
          <Stack spacing={2}>
            <Body2>{`${formatDateAndTime(date)} - ${medRecName} - ${author} (# of Meds: ${medRecSnapshotDetail.length})`}</Body2>

            <ButtonsContainer>
              <ButtonMedRec>
                <Button a11yLabel="Download PDF" label="Download PDF" onClick={() => goToPdfUrl(pdfDownloadUrl, id!, PDFMessage)} />
              </ButtonMedRec>
              <ButtonMedRec>
                <Button a11yLabel="Send to EHR" label="Send to EHR" onClick={() => showSendEHRDialog()} />
              </ButtonMedRec>
              <ButtonMedRec>
                <Button a11yLabel="Email" label="Email" onClick={() => onSendEmail()} />
              </ButtonMedRec>
            </ButtonsContainer>
          </Stack>
        }
      ></CardHeader>

      <CardContent component={Stack} spacing={2}>
        <MuiTabs
          tabVariant="standard"
          tabs={[
            {
              label: 'Document',
              children: <PDFViewer src={pdfUrl} />
            },
            {
              label: 'History',
              children: (
                <Stack spacing={2}>
                  <HistoryTable
                    accountMedications={medRecSnapshotDetail}
                    called={called}
                    loading={loading}
                    currentPage={currentPage}
                    totalCount={totalCount}
                    totalPages={totalPages}
                    onUpdateData={updateData}
                  />
                </Stack>
              )
            },
            {
              label: 'Meds Interaction',
              children: <MedInteractions interactions={medInteractions} called={calledInteractions} loading={loadingInteractions} />
            }
          ]}
        />
      </CardContent>
    </Card>
  );
};

export default MedRecHistory;
