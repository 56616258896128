import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { ProfileDialog, UserDeactivateDialog } from '@/components/Dialogs';
import ExistingUserAdd from '@/components/UserManagementBar/ExistingUserAdd';
import UserAdd from '@/components/UserManagementBar/UserAdd';
import UserSearch from '@/components/UserManagementBar/UserSearch';
import AdministrationContext from '@/context/administrationContext';
import SideMenuContext from '@/context/sideMenuContext';
import Icon from '@/elements/Icon';
import { GET_RELYING_PARTY_ADMINS } from '@/graphql/administration';
import useToken from '@/hooks/useToken';
import { Role, TITLE_TYPE_OPTIONS } from '@/types/admin';
import { getIconDataBasedOnRole } from '@/util/account';
import { unknown } from '@/util/string';
import { useLazyQuery } from '@apollo/client';
import { faEdit, faUserCog, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Grid, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Users = () => {
  const { roleType } = useToken();
  const navigate = useNavigate();

  const { reloadAccounts, setReloadAccounts } = useContext(SideMenuContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isPharmacist] = useState<boolean>(roleType === Role.PHARMACIST);
  const { showModal } = useModal();
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  const [getCompanyAdmins, { loading, data, called, refetch }] = useLazyQuery(GET_RELYING_PARTY_ADMINS, {
    fetchPolicy: 'cache-and-network'
  });
  const { users, setUsers } = useContext(AdministrationContext);

  const isDataLoaded = useCallback(() => {
    return called && !loading && data?.relyingPartyAdmins?.relyingPartyAdmins;
  }, [called, data, loading]);

  const updateData = data => {
    setCurrentPage(data);
  };

  const showManageModal = id => {
    const modalRef = showModal(ProfileDialog, {
      title: 'Edit User',
      hideDialog: () => {
        modalRef.hide();
        setReloadAccounts(new Date());
        refetch();
      },
      pharmacistInfo: { id }
    });
  };

  const showDeactivateModal = id => {
    const modalRef = showModal(UserDeactivateDialog, {
      title: 'Deactivate User',
      hideDialog: () => {
        modalRef.hide();
        setReloadAccounts(new Date());
      },
      id
    });
  };

  const refetchCompanyAdmins = () => {
    refetch();
  };

  useEffect(() => {
    getCompanyAdmins({
      variables: {
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, [currentPage, getCompanyAdmins, reloadAccounts]);

  useEffect(() => {
    if (isDataLoaded()) {
      setUsers(data.relyingPartyAdmins?.relyingPartyAdmins);
      setRowCount(data.relyingPartyAdmins?.meta?.totalCount || 0);
    }
  }, [isDataLoaded, setUsers]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First name',
        sortable: true,
        valueGetter: params => params.row.user.firstName,
        flex: 1,
        align: 'left'
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        sortable: true,
        valueGetter: params => params.row.user.lastName,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'email',
        sortable: true,
        valueGetter: params => params.row.user.email,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'roleType',
        width: 30,
        minWidth: 30,
        align: 'center',
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => null,
        renderCell: params => {
          const { roleType } = params.row;
          const { Icon, color } = getIconDataBasedOnRole(roleType);
          if (Icon) return <Icon color={color} />;
          return unknown();
        }
      },
      {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const title = cellValues.row.title;
          const isRpmPrimary = cellValues.row.isRpmPrimary;
          if (title) {
            const label = TITLE_TYPE_OPTIONS.filter(data => data.value === title);

            if (label.length > 0) {
              return (
                <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
                  {label[0].label} {isRpmPrimary && <SupportAgentIcon />}
                </Stack>
              );
            }
          } else {
            return 'N/A';
          }
        }
      },
      {
        field: 'npiNumber',
        headerName: 'NPI #',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'techLicense',
        headerName: 'License #',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center'
      },
      {
        field: 'action',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        valueGetter: params => {
          return [
            params.row.user.firstName,
            params.row.user.lastName,
            params.row.user.email,
            params.row.npiNumber,
            params.row.techLicense,
            params.row.title,
            params.row.isObserver
          ].join(',');
        },
        renderCell: value => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton onClick={() => showManageModal(value.id)}>
                <Icon icon={faEdit} fixedWidth size="lg" />
              </IconButton>

              <IconButton onClick={() => navigate(`/administration/users/${value.row.id}/details`)}>
                <Icon icon={faUserCog} fixedWidth size="lg" />
              </IconButton>

              <IconButton onClick={() => showDeactivateModal(value.id)}>
                <Icon icon={faUserXmark} fixedWidth size="lg" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    []
  );

  return (
    <Stack spacing={2}>
      <Grid
        container
        spacing={1}
        key={'search-bar'}
        alignItems="center"
        style={{ display: isSuperAdmin || isPharmacistAdmin || isPharmacist ? 'flex' : 'none' }}
      >
        <Grid item xs={6}>
          <UserSearch />
        </Grid>

        <Grid item xs={6}>
          <Stack spacing={1} alignItems={'center'} direction="row" justifyContent={'flex-end'}>
            {isSuperAdmin && <ExistingUserAdd onExistingUserAdd={refetchCompanyAdmins} />}
            <UserAdd onUserAdd={refetchCompanyAdmins} />
          </Stack>
        </Grid>
      </Grid>

      <Paper component={Stack} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-users'}
            autoHeight
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              updateData(page);
            }}
            rows={users}
            rowCount={rowCountState}
            loading={loading}
            columns={columns}
            paginationMode="server"
            disableRowSelectionOnClick
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Users;
