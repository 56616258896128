import Button from '@/components/Button';
import { Small } from '@/components/Typography';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const SEND_MEDREC_SNAPSHOT = gql`
  mutation sendMedrecSnapshot($truentityId: String!, $healthdataSourceId: ID!, $medrecSnapshotId: String!, $description: String!) {
    sendMedrecSnapshot(
      truentityId: $truentityId
      healthdataSourceId: $healthdataSourceId
      medrecSnapshotId: $medrecSnapshotId
      description: $description
    ) {
      status
    }
  }
`;

const GET_ACCOUNT_HEALTHDATA_SOURCES = gql`
  query getHealthdataSources($truentityId: String!) {
    healthdataSources(truentityId: $truentityId) {
      healthdataSources {
        id
        name
        updatedAt
        sourceType
        externalId
        address
        logoUrl
      }
    }
  }
`;

const EMAIL_MEDREC_SNAPSHOT = gql`
  mutation emailMedrecSnapshot($truentityId: String!, $medrecSnapshotId: String!, $emailSubject: String!, $emailAddresses: String!) {
    emailMedrecSnapshot(
      truentityId: $truentityId
      medrecSnapshotId: $medrecSnapshotId
      emailSubject: $emailSubject
      emailAddresses: $emailAddresses
    ) {
      status
    }
  }
`;

type FormValues = {
  truentityId: string;
  medrecSnapshotId: string;
  emailSubject: string;
  emailAddresses: string;
};

const defaultValues: FormValues = {
  truentityId: '',
  medrecSnapshotId: '',
  emailSubject: '',
  emailAddresses: ''
};

type Props = BaseDialogProps & {
  title: string;
  successMessage: string;
  truentityId?: string;
  medrecSnapshotId?: string;
  medRecSnapshotName?: string;
  hideDialog: () => void;
};

const SendMedRecEmailDialog = ({
  title,
  successMessage,
  truentityId,
  medrecSnapshotId,
  medRecSnapshotName,
  hideDialog,
  ...props
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });

  const [sendMedrecSnapshot] = useMutation(SEND_MEDREC_SNAPSHOT);
  const [emailMedrecSnapshot] = useMutation(EMAIL_MEDREC_SNAPSHOT);
  const [accountHealthdataSources, setHealthdataSources] = useState<[]>([]);

  const [getHealthdataSources, { data, loading, called }] = useLazyQuery(GET_ACCOUNT_HEALTHDATA_SOURCES);

  const { enqueueSnackbar } = useSnackbar();

  const isDataLoaded = () => called && !loading && data && data.healthdataSources;

  const isThereData = () => isDataLoaded() && data.healthdataSources.healthdataSources.length > 0;

  useEffect(() => {
    getHealthdataSources({
      variables: {
        truentityId
      }
    });
  }, [truentityId]);

  useEffect(() => {
    if (data && data.healthdataSources && data.healthdataSources.healthdataSources) {
      setHealthdataSources(data.healthdataSources.healthdataSources);
    }
  }, [data]);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitEmail(data);
  const handleSubmitEmail = async (values: FormValues) => {
    try {
      const result = await emailMedrecSnapshot({
        variables: {
          truentityId,
          medrecSnapshotId,
          emailSubject: values.emailSubject,
          emailAddresses: values.emailAddresses
        }
      });
      const { status } = result.data!.emailMedrecSnapshot;

      if (status !== 'Failure') {
        showSnackbar(successMessage, 'success');
        return;
      }
    } catch (err) {
      console.log(err);
    }

    showSnackbar('Error sending email. Please contact Tech Support', 'error');
  };

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
    hideDialog();
  };

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props} maxWidth="sm" fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TruentityTextField
            {...register('emailSubject')}
            type="text"
            label="Subject"
            fullWidth
            variant="outlined"
            margin="dense"
            required
          />

          <TruentityTextField
            {...register('emailAddresses')}
            type="email"
            label="Email"
            multiple
            fullWidth
            variant="outlined"
            margin="dense"
            required
          />

          <Small>One email address or multiple separated by ,</Small>

          <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
            <Button type="submit" a11yLabel="Submit" appearance="primary" />
            <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </DialogContent>
      </form>
    </BaseDialog>
  );
};

export default SendMedRecEmailDialog;
