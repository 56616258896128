import { Body1, H3 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import { RpmAlertsCount, RpmAlertsLabelTypes, RpmAlertTabEnum } from '@/types/remotePatientMonitoring';
import { getAlertTypeStyles } from '@/util/rpm';
import CircleIcon from '@mui/icons-material/Circle';
import { Card, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import type React from 'react';
import { useCallback, useState } from 'react';

type AlertTypeFilterProps = {
  type: RpmAlertsLabelTypes;
  count: number;
  onSelectAlertType: (alertType: RpmAlertsLabelTypes) => void;
  isSelected: boolean;
};
const AlertTypeFilter: React.FC<AlertTypeFilterProps> = ({ type, onSelectAlertType, count, isSelected }) => {
  const alertTypeStyles = getAlertTypeStyles(type);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <Tooltip
      title={`Click to filter by ${alertTypeStyles?.labelText}`}
      placement="top"
      arrow
      open={tooltipOpen && !isSelected}
      onClose={handleClose}
      onOpen={handleOpen}
    >
      <Card
        onClick={() => {
          onSelectAlertType(type);
        }}
        sx={{
          paddingX: 1.2,
          paddingY: 1,
          borderRadius: 1,
          minWidth: 160,
          maxWidth: 180,
          backgroundColor: theme => theme.palette.background.default,
          boxShadow: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          border: isSelected ? `2px solid ${color.primary800}` : '2px solid transparent',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <Stack
          display="inline-flex"
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          marginY={0.5}
          marginX={1}
        >
          <CircleIcon fontSize={'small'} sx={{ color: alertTypeStyles?.labelColor }} />
          <Box
            sx={{
              flex: '1 1 auto',
              whiteSpace: {
                xs: 'wrap',
                lg: 'nowrap'
              }
            }}
          >
            <Body1>{alertTypeStyles?.labelText}</Body1>
          </Box>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1} marginY={0.5} marginX={1}>
          <H3>{count}</H3>
          <Body1>Patients</Body1>
        </Stack>
      </Card>
    </Tooltip>
  );
};

export type RpmAlertTypesFiltersSectionProps = {
  alertsTabEnum: RpmAlertTabEnum;
  alertsCount: RpmAlertsCount | undefined;
  alertTypes: { [key: string]: boolean };
  setAlertTypes: (val: { [key: string]: boolean }) => void;
};

export const RpmAlertTypesFiltersSection: React.FC<RpmAlertTypesFiltersSectionProps> = ({
  alertsTabEnum,
  alertTypes,
  setAlertTypes,
  alertsCount
}) => {
  const onSelectAlertType = useCallback(
    (alertType: RpmAlertsLabelTypes) => {
      setAlertTypes({
        ...alertTypes,
        [alertType]: !alertTypes[alertType]
      });
    },
    [alertTypes, setAlertTypes]
  );

  return (
    <Stack
      justifyContent={'flex-end'}
      direction="row"
      sx={{
        width: '100%',
        height: 'auto'
      }}
    >
      <Stack
        justifyContent="flex-end"
        display="inline-flex"
        height="auto"
        direction="row"
        alignItems="stretch"
        flexWrap="wrap"
        gap={2}
        width="100%"
      >
        {alertsTabEnum === RpmAlertTabEnum.PATIENTS && (
          <>
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.CRITICALLY_HIGH}
              count={alertsCount?.criticallyHigh ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.CRITICALLY_HIGH]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.CRITICALLY_LOW}
              count={alertsCount?.criticallyLow ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.CRITICALLY_LOW]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.HIGH}
              count={alertsCount?.high ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.HIGH]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.LOW}
              count={alertsCount?.low ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.LOW]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.RPM_SETUP}
              count={alertsCount?.rpmSetup ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.RPM_SETUP]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.NO_READINGS}
              count={alertsCount?.noReadings ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.NO_READINGS]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.PROVIDER_CONFIG}
              count={alertsCount?.providerConfig ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.PROVIDER_CONFIG]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.MED_REGIMEN}
              count={alertsCount?.medRegimen ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.MED_REGIMEN]}
            />
            <AlertTypeFilter
              onSelectAlertType={onSelectAlertType}
              type={RpmAlertsLabelTypes.PATIENT_VITAL_ACCESS_BLOCKED}
              count={alertsCount?.patientVitalAccessBlocked ?? 0}
              isSelected={alertTypes[RpmAlertsLabelTypes.PATIENT_VITAL_ACCESS_BLOCKED]}
            />
          </>
        )}
        {alertsTabEnum === RpmAlertTabEnum.REPORTS && (
          <AlertTypeFilter
            onSelectAlertType={onSelectAlertType}
            type={RpmAlertsLabelTypes.REPORT_FAX_FAILED}
            count={alertsCount?.reportFaxFailed ?? 0}
            isSelected={alertTypes[RpmAlertsLabelTypes.REPORT_FAX_FAILED]}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default RpmAlertTypesFiltersSection;
