import { TaskFormState } from '@/types/medication';

export const emptyData: TaskFormState = {
  id: null,
  type: 'TIPNeedsRefill',
  subType: null,
  healthPlan: '',
  medicationName: '',
  name: '',
  performedOn: null,
  status: '',
  resolutionStatus: '',
  modeOfResolution: '',
  wrapUpStatus: '',
  nextSteps: '',
  location: '',
  billedAmount: 0.0,
  isPrimaryAffiliation: true
};

export const reducer = (state, action: { type: string; payload: string | null | boolean | Date | undefined }) => {
  if (action.type === 'payment') {
    return {
      ...state,
      billedAmount: action.payload
    };
  }

  if (action.type === 'type') {
    return {
      ...state,
      type: action.payload
    };
  }

  if (action.type === 'sub-type') {
    return {
      ...state,
      subType: action.payload
    };
  }

  if (action.type === 'status') {
    return {
      ...state,
      status: action.payload
    };
  }

  if (action.type === 'resolution-status') {
    return {
      ...state,
      resolutionStatus: action.payload
    };
  }

  if (action.type === 'wrap-up-status') {
    return {
      ...state,
      wrapUpStatus: action.payload
    };
  }

  if (action.type === 'is-primary') {
    console.log('action-payload----', action);

    return {
      ...state,
      isPrimaryAffiliation: action.payload
    };
  }

  if (action.type === 'mode-of-resolution') {
    return {
      ...state,
      modeOfResolution: action.payload
    };
  }

  if (action.type === 'health-plan') {
    return {
      ...state,
      healthPlan: action.payload
    };
  }

  if (action.type === 'medication-name') {
    return {
      ...state,
      medicationName: action.payload
    };
  }

  if (action.type === 'next-steps') {
    return {
      ...state,
      nextSteps: action.payload
    };
  }

  if (action.type === 'name') {
    return {
      ...state,
      name: action.payload
    };
  }

  if (action.type === 'location') {
    return {
      ...state,
      location: action.payload
    };
  }

  if (action.type === 'performed-on') {
    return {
      ...state,
      performedOn: action.payload
    };
  }

  return state;
};
