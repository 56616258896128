import Button from '@/components/Button';
import InvalidSession from '@/components/InvalidSession';
import LoadingOverlay from '@/components/LoadingOverlay';
import TruentityLogo from '@/components/TruentityLogo';
import TruentityTextField from '@/components/TruentityTextField';
import { Body1, H4 } from '@/components/Typography';
import {
  CheckProviderSessionResponse,
  CHECK_PROVIDER_SESSION,
  StartProviderSessionResponse,
  START_PROVIDER_SESSION
} from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { ProviderSessionStatus, ProviderSessionStatusTypes } from '@/types/remotePatientMonitoring';
import { setRpmProviderAuthCode } from '@/util/rpm';
import { useProviderSessionStore } from '@/zustand/ProviderSessionStore';
import { useMutation } from '@apollo/client';
import { Box, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

type FormValues = { otpCode: string };

const defaultValues: FormValues = {
  otpCode: ''
};

const RpmReviewStart = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { sessionId } = useParams();
  const { setProvider, setRequestedOn } = useProviderSessionStore();

  const { control, handleSubmit } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const [validSession, setValidSession] = useState<boolean>(false);
  const [validSessionError, setValidSessionError] = useState<string>('');
  const [authCode, setAuthCode] = useState<string>();

  const [checkProviderSession, { loading: validSessionLoading }] = useMutation<CheckProviderSessionResponse>(CHECK_PROVIDER_SESSION);
  const [startProviderSession, { loading: startSessionLoading }] = useMutation<StartProviderSessionResponse>(START_PROVIDER_SESSION);

  const validateReviewSession = async () => {
    try {
      if (sessionId === undefined) {
        setValidSession(false);
        return;
      }

      const result = await checkProviderSession({
        variables: {
          sessionId: sessionId
        }
      });

      if (result && result?.data?.checkProviderSession?.status === ProviderSessionStatusTypes.SUCCESS) {
        setProvider(result.data?.checkProviderSession?.provider);
        setRequestedOn(result.data?.checkProviderSession?.dateRequested);
        setValidSession(true);
      } else {
        setValidSession(false);
      }
    } catch (error) {
      setValidSession(false);
      console.error('Error occurred:', error);
    }
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      if (sessionId === undefined) {
        setValidSession(false);
        return;
      }

      if (!values.otpCode) {
        enqueueSnackbar('Please enter the OTP code sent to your email', { variant: 'error' });
        return;
      } else if (values.otpCode.length !== 6) {
        enqueueSnackbar('Please enter a valid OTP code', { variant: 'error' });
        return;
      }

      const result = await startProviderSession({
        variables: {
          sessionId: sessionId,
          otpCode: values.otpCode
        }
      });

      if (result) {
        const { status, authCode } = result.data?.startProviderSession || { status: 'unknown', authCode: '' };

        switch (status.toLowerCase()) {
          case ProviderSessionStatus.STARTED:
            setAuthCode(authCode);
            break;
          case ProviderSessionStatus.INVALID_OTP:
            enqueueSnackbar('Invalid OTP code. Please try again', { variant: 'error' });
            break;
          case ProviderSessionStatus.INVALID_ACCOUNT:
            enqueueSnackbar('Please double check your credentials', { variant: 'error' });
            break;

          default:
            enqueueSnackbar('An unknown error has occurred. Please contact support', { variant: 'error' });
        }
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Something has gone wrong.  Please make sure you enter your details correctly', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (sessionId) {
      validateReviewSession();
    }
  }, [sessionId]);

  useEffect(() => {
    if (authCode) {
      setRpmProviderAuthCode(authCode);
      navigate('view', { replace: true });
    }
  }, [authCode]);

  return (
    <>
      {validSession ? (
        <Stack
          flex="1"
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ width: '100%', minHeight: '100vh', backgroundColor: color.truentityCyan[500] }}
        >
          {validSessionLoading && <LoadingOverlay active={validSessionLoading} text={'Validating Session'} />}
          <Paper component={Stack} spacing={2} p={2} sx={{ width: '90%', maxHeight: '90%', maxWidth: '500px', overflow: 'auto' }}>
            <Box
              sx={{
                paddingBottom: '24px',
                borderBottom: '2px solid $truentityLigthGray'
              }}
            >
              <TruentityLogo />
            </Box>

            <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} flexDirection={'column'} justifyContent={'stretch'} spacing={2}>
              <H4 align="center" color={color.truentityCyan[600]}>
                Enter OTP Code
              </H4>
              <Body1 align="center">
                We've sent a one-time code to the email associated with your account. Please enter the code below to proceed with the login.
              </Body1>

              <Controller
                control={control}
                name="otpCode"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField autoFocus required onChange={onChange} value={value} label={'OTP Code'} />
                )}
              />

              <Stack sx={{ justifyContent: 'center', marginTop: '20px' }}>
                <Button
                  disabled={validSessionLoading || startSessionLoading}
                  type="submit"
                  a11yLabel="Login"
                  appearance="secondary"
                  variant="contained"
                  fullWidth={false}
                />
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      ) : (
        <InvalidSession feedback={validSessionError ?? 'Session is invalid. Please try again.'} />
      )}
    </>
  );
};

export default RpmReviewStart;
