import Button from '@/components/Button';
import SelectList from '@/components/SelectList';
import TruentityTextField from '@/components/TruentityTextField';
import type { GetAccountMonitoringDeviceByIdResponse } from '@/graphql/remotePatientMonitoring';
import { ADD_ACCOUNT_MONITORING_DEVICE, GET_DEVICE_BY_ID, UPDATE_ACCOUNT_MONITORING_DEVICE } from '@/graphql/remotePatientMonitoring';
import { BRAND_NAMES, DEVICE_TYPE_NAMES } from '@/types/remotePatientMonitoring';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, FormControl, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  id: string | undefined;
  deviceId?: string;
  hideDialog: () => void;
  triggerAutoEnrollment: () => void;
};

type FormValues = {
  deviceBrand: string;
  deviceType: string;
  hardwareId: string;
};

const defaultValues: FormValues = {
  deviceBrand: '',
  deviceType: '',
  hardwareId: ''
};

const AddDeviceByDeviceId = ({ id, deviceId, hideDialog, triggerAutoEnrollment }: Props) => {
  const { isReadOnly } = useRpmWorkflowStore();
  const { enqueueSnackbar } = useSnackbar();
  const { control, setValue, handleSubmit } = useForm<FormValues>({ defaultValues });

  const [saveDevice, { loading: loadingOnSaveAccountMonitoringDevice }] = useMutation(ADD_ACCOUNT_MONITORING_DEVICE);
  const [updateDevice, { loading: loadingOnUpdateAccountMonitoringDevice }] = useMutation(UPDATE_ACCOUNT_MONITORING_DEVICE);
  const [getDeviceByIdQuery, { data: deviceByIdData, called: deviceByIdCalled, loading: deviceByIdLoading }] =
    useLazyQuery<GetAccountMonitoringDeviceByIdResponse>(GET_DEVICE_BY_ID, { fetchPolicy: 'cache-and-network' });

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data, deviceId);

  const handleSubmitImpl = async (values: FormValues, deviceId?: string) => {
    try {
      const deviceObject = {
        ...(deviceId ? { deviceId } : {}),
        deviceBrand: values.deviceBrand,
        deviceType: values.deviceType,
        hardwareId: values.hardwareId,
        truentityId: id
      };

      const response = deviceId
        ? await updateDevice({
            variables: deviceObject
          })
        : await saveDevice({
            variables: deviceObject
          });

      const data = deviceId ? response.data!.updateAccountDevice : response.data!.addAccountDevice;
      const errors = response.errors;
      const variant = data?.status === 'Success' ? 'success' : 'error';
      if (variant === 'error') {
        enqueueSnackbar('Failed to add device', { variant });
      } else {
        enqueueSnackbar(data.message, { variant });
        triggerAutoEnrollment();
      }
      hideDialog();

      if (errors) {
        console.error('GraphQL errors:', errors);
        enqueueSnackbar('Failed to process add device request', { variant: 'error' });
        return;
      }
    } catch (err) {
      const failMessage: string = deviceId ? 'Failed to update a device' : 'Failed to Create a device';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (deviceId) {
      try {
        getDeviceByIdQuery({
          variables: {
            deviceId
          }
        });
      } catch (error) {
        enqueueSnackbar('Failed to retrieve device data', { variant: 'error' });
        hideDialog();
      }
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceByIdCalled && deviceByIdData && !deviceByIdLoading) {
      const { hardwareId, monitoringDevice } = deviceByIdData?.getAccountDeviceById;
      setValue('deviceBrand', monitoringDevice.brandName, { shouldTouch: true });
      setValue('deviceType', monitoringDevice.typeName);
      setValue('hardwareId', hardwareId);
    }
  }, [deviceByIdCalled, deviceByIdData, deviceByIdLoading]);
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box pb={2}>
        <FormControl fullWidth size="small">
          <Controller
            control={control}
            name="deviceBrand"
            render={({ field: { onChange, value } }) => (
              <SelectList
                id="type"
                label="Device Brand"
                options={BRAND_NAMES}
                placeholder="Select an option..."
                value={value}
                onChange={onChange}
                required
                disabled={isReadOnly}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <Controller
            control={control}
            name="deviceType"
            render={({ field: { onChange, value } }) => (
              <SelectList
                id="type"
                label="Device Type"
                options={DEVICE_TYPE_NAMES}
                placeholder="Select an option..."
                value={value}
                onChange={onChange}
                disabled={isReadOnly}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth size="small">
          <Controller
            control={control}
            name="hardwareId"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField
                id={'location'}
                required
                label="Hardware ID"
                type="text"
                value={value}
                onChange={onChange}
                disabled={isReadOnly}
              />
            )}
          />
        </FormControl>
      </Box>

      <Stack direction="row" gap={1}>
        <Button
          type="submit"
          a11yLabel={'Save'}
          appearance="primary"
          isLoading={loadingOnSaveAccountMonitoringDevice || loadingOnUpdateAccountMonitoringDevice}
          disabled={isReadOnly}
        />
        <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} disabled={isReadOnly} />
      </Stack>
    </Box>
  );
};

export default AddDeviceByDeviceId;
