import type { MomentInput } from 'moment';

export type TruentityDateInput = MomentInput;

export enum GeneralTimezone {
  EST = 'America/New_York'
}

export enum CustomTimezone {
  ACCOUNT_TIMEZONE = 'ACCOUNT_TIMEZONE',
  ADMIN_TIMEZONE = 'ADMIN_TIMEZONE'
}
