import Icon from '@/elements/Icon';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Stack } from '@mui/material';
import type { ReactNode } from 'react';

type IconWithChildrenContainerProps = {
  children?: ReactNode;
  icon?: ReactNode | IconDefinition;
};

const IconWithChildrenContainer = ({ icon, children, ...props }: IconWithChildrenContainerProps) => {
  const styles = {
    marginTop: 0,
    alignItems: 'center'
  };

  return (
    <Stack direction="row" sx={styles} spacing={0.5} {...props}>
      {icon && <Icon icon={icon} fixedWidth size="lg" />}
      {children}
    </Stack>
  );
};

export default IconWithChildrenContainer;
