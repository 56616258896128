import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useReactiveVar } from '@apollo/client';
import React, { FC, ReactNode, useEffect } from 'react';

type Setting = string | number | boolean;

type SettingsContextValue = {
  settings: Record<string, Setting>;
  parseSettingsJsonString: (jsonStr: string) => void;
};

type SettingsProviderProps = {
  children: ReactNode;
};

const initialValue: SettingsContextValue = {
  parseSettingsJsonString: () => {},
  settings: {}
};

const SettingsContext = React.createContext<SettingsContextValue>(initialValue);

export const SettingsProvider: FC<SettingsProviderProps> = ({ children }) => {
  const [settings, setSettings] = React.useState<Record<string, Setting>>({});
  const currentUser = useReactiveVar(currentLoggedUserVar);

  useEffect(() => {
    if (currentUser?.settings) {
      parseSettingsJsonString(currentUser.settings);
    }
  }, [currentUser]);

  const parseSettingsJsonString = (jsonStr: string) => {
    if (jsonStr?.length > 0) {
      try {
        const parsedSettings = JSON.parse(jsonStr);
        setSettings(parsedSettings);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        parseSettingsJsonString,
        settings
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
