import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import parse from 'html-react-parser';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  message: string;
  onAgree: any;
  onDisagree: any;
};

export default function ConfirmDialog({ title, message, onAgree, onDisagree, ...props }: Props) {
  return (
    <BaseDialog {...props} title={title} onClose={onDisagree}>
      <DialogContent>
        <DialogContentText>{parse(message)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree}>No</Button>
        <Button onClick={onAgree} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </BaseDialog>
  );
}
