import Button from '@/components/Button';
import type { CommentDialogValues } from '@/components/Dialogs/CreateCommentDialog';
import ProviderCommentDialog from '@/components/Dialogs/ProviderCommentDialog';
import GenderIcon from '@/components/Gender/GenderIcon';
import LoadingOverlay from '@/components/LoadingOverlay';
import PDFViewer from '@/components/PDFViewer';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, H1, H2, H3 } from '@/components/Typography';
import type { GetProviderSessionRpmReportResponse, UpdateProviderSessionRpmReportStatusResponse } from '@/graphql/remotePatientMonitoring';
import { GET_PROVIDER_SESSION_RPM_REPORT, UPDATE_PROVIDER_SESSION_RPM_REPORT_STATUS } from '@/graphql/remotePatientMonitoring';
import type { RpmProviderActivitiesType } from '@/types/remotePatientMonitoring';
import { ProviderReviewTypeEnum, RpmReportReviewTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDateIgnoreTZ } from '@/util/format';
import { fetchDocumentUrl, getRpmProviderAuthCode, getRpmProviderReportsIds } from '@/util/rpm';
import { useMutation, useQuery } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Chip, Divider, Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RpmMonthlyReport = () => {
  const { providerActivityId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { showModal } = useModal();

  const [reportDetails, setReportDetails] = useState<RpmProviderActivitiesType>({} as RpmProviderActivitiesType);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [nextReportId, setNextReportId] = useState<string>('');

  const {
    data: rpmProviderActivityData,
    loading: rpmProviderActivityLoading,
    refetch: rpmProviderActivityRefetch
  } = useQuery<GetProviderSessionRpmReportResponse>(GET_PROVIDER_SESSION_RPM_REPORT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      authCode: getRpmProviderAuthCode(),
      providerActivityId: providerActivityId
    }
  });
  const [updateRpmReportReviewStatus] = useMutation<UpdateProviderSessionRpmReportStatusResponse>(
    UPDATE_PROVIDER_SESSION_RPM_REPORT_STATUS
  );

  const getPDFUrl = async (pdfS3Key: string) => {
    const pdfUrl = await fetchDocumentUrl(pdfS3Key);
    setPdfUrl(pdfUrl);
  };

  const handelRpmReviewStatus = () => {
    try {
      const modal = showModal(ProviderCommentDialog, {
        title: (
          <Stack textAlign="center" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
            <H2
              sx={{
                fontSize: 'medium'
              }}
            >{`Monthly (${reportDetails?.rpmReport?.monthYear}) RPM Report Review`}</H2>
            <H3>{`${getAccountUserFullName(reportDetails?.rpmReport?.account?.user)}`}</H3>
          </Stack>
        ) as any,
        uiLabels: {
          commentInput: 'Comment',
          submitButton: 'Submit',
          cancelButton: 'Cancel'
        },
        reviewType: ProviderReviewTypeEnum.REPORT,
        providerActivityId: providerActivityId,
        authCode: getRpmProviderAuthCode(),
        onSubmitted: async (values: CommentDialogValues) => {
          try {
            const updateReviewStatusResponse = await updateRpmReportReviewStatus({
              variables: {
                authCode: getRpmProviderAuthCode(),
                providerActivityId: providerActivityId,
                reviewStatus: RpmReportReviewTypes.COMPLETED,
                comment: values.comment
              }
            });
            if (updateReviewStatusResponse.data?.updateProviderSessionRpmReportStatus?.status === 'Success') {
              rpmProviderActivityRefetch();
              enqueueSnackbar(updateReviewStatusResponse.data?.updateProviderSessionRpmReportStatus?.message, { variant: 'success' });
            } else {
              enqueueSnackbar('Could not save patient report review response', { variant: 'error' });
            }
          } catch (error) {
            enqueueSnackbar(`Could not save patient report review response, please try again later.`, { variant: 'error' });
          } finally {
            modal.hide();
          }
        },
        onCancelled: () => {
          modal.hide();
        },
        hideDialog: () => modal.hide()
      });
    } catch (error) {
      enqueueSnackbar('Failed to update RPM report review status', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (rpmProviderActivityData && !rpmProviderActivityLoading) {
      setReportDetails(rpmProviderActivityData.getProviderSessionRpmReport);
      getPDFUrl(rpmProviderActivityData.getProviderSessionRpmReport?.rpmReport?.reportedDocS3Key ?? '');
    }
  }, [rpmProviderActivityData, rpmProviderActivityLoading]);

  useEffect(() => {
    const rpmReportsReviewersIds: string | string[] = JSON.parse(getRpmProviderReportsIds());

    if (rpmReportsReviewersIds.length > 0 && providerActivityId) {
      const index = rpmReportsReviewersIds.indexOf(providerActivityId);
      const nextReport = rpmReportsReviewersIds.length !== index + 1 ? rpmReportsReviewersIds[index + 1] : rpmReportsReviewersIds[0];
      setNextReportId(nextReport);
    }
  }, [providerActivityId]);

  return (
    <Stack flex={1}>
      {<LoadingOverlay active={rpmProviderActivityLoading} text="Please wait. Loading Landing Patient Data..." />}
      <Stack spacing={2}>
        <Paper
          elevation={1}
          color={'inherit'}
          component={Stack}
          p={4}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack alignItems="flex-start" spacing={1}>
            <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
              <Button
                type="button"
                label={'All Monthly Reports'}
                onClick={() => navigate('../rpmMonthlyReports')}
                variant={'text'}
                startIcon={<ArrowBackIosIcon />}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <H1>
                {reportDetails?.rpmReport?.account?.user?.lastName ?? '-'}, {reportDetails?.rpmReport?.account?.user?.firstName ?? '-'}
              </H1>
              <Chip label={reportDetails?.rpmReport?.account?.healthPlan?.orgName ?? 'N/A'} variant="outlined" />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              {reportDetails?.rpmReport?.account?.gender && <GenderIcon gender={reportDetails?.rpmReport?.account?.gender} />}
              <Body1 fontWeight="600">{formatDateIgnoreTZ(reportDetails?.rpmReport?.account?.birthDate) ?? 'N/A'}</Body1>
              <Divider variant="middle" orientation="vertical" flexItem />
              <TruentityPhoneNumber value={reportDetails?.rpmReport?.account?.phone} />
              <Divider variant="middle" orientation="vertical" flexItem />
              <Body1 fontWeight="600">{reportDetails?.rpmReport?.monthYear ?? 'N/A'}</Body1>
            </Stack>
          </Stack>

          <Stack direction={'row'} spacing={1}>
            <Button label="Next" onClick={() => navigate(`../rpmMonthlyReports/${nextReportId}`)} />
          </Stack>
        </Paper>
        <Paper sx={{ py: 5, px: 8 }}>
          <PDFViewer src={pdfUrl} />
          <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} spacing={1} mt={2}>
            <Button label={'Reviewed'} type={'button'} onClick={handelRpmReviewStatus} />
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default RpmMonthlyReport;
