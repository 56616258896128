import Button from '@/components/Button';
import { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import { gql, useMutation } from '@apollo/client';
import { DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import BaseDialog from './BaseDialog';

const DELETE_MEDICATION = gql`
  mutation deleteMedication($truentityId: String!, $id: String!) {
    deleteAccountMedication(truentityId: $truentityId, accountMedicationId: $id) {
      status
    }
  }
`;

type Props = BaseDialogProps & {
  id: string;
  medicationId: string;
  medicationName: string;
  hideDialog: () => void;
  reloadMeds: () => void;
};

const MedicationDeleteDialog = ({ id, title, medicationId, medicationName, hideDialog, reloadMeds, ...props }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteMedication, { data: dataDelete }] = useMutation(DELETE_MEDICATION);

  const confirmDelete = () => {
    deleteMedication({
      variables: {
        truentityId: id,
        id: medicationId
      }
    });
  };

  useEffect(() => {
    if (dataDelete) {
      hideDialog();

      enqueueSnackbar('Medication has been deleted', {
        variant: 'success'
      });

      reloadMeds();
    }
  }, [dataDelete]);

  return (
    <BaseDialog title={title} hideDialog={hideDialog} {...props}>
      <DialogContent>{`Are you sure you want to remove ${medicationName} from the patient's profile?`}</DialogContent>

      <DialogActions>
        <Button a11yLabel="Yes" appearance="primary" onClick={confirmDelete} />
        <Button a11yLabel="No" appearance="outline" onClick={hideDialog} />
      </DialogActions>
    </BaseDialog>
  );
};

export default MedicationDeleteDialog;
