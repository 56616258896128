import { Body1 } from '@/components/Typography';
import useMessages from '@/hooks/useMessages';
import { color } from '@/styles/assets/colors';
import type { MessageTemplate, MessageTemplateItem } from '@/types/chat';
import { Send } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip
} from '@mui/material';
import parse from 'html-react-parser';
import type React from 'react';

interface MessageTemplatesListProps {
  onSelectMessageTemplateItem: (messageTemplateItem: MessageTemplateItem) => void;
}
export const MessageTemplatesList: React.FC<MessageTemplatesListProps> = ({ onSelectMessageTemplateItem }) => {
  const { messageTemplates, currentMessageTemplate, setCurrentMessageTemplate } = useMessages();

  const handleChange = (template: MessageTemplate) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setCurrentMessageTemplate(isExpanded ? template : null);
  };

  return (
    <Stack
      flexGrow={1}
      sx={{
        display: 'flex',
        flexGrow: 1,
        width: '100% !important',
        height: '100%',
        overflowY: 'auto'
      }}
    >
      {messageTemplates.map((messageTemplate: MessageTemplate) => (
        <Tooltip title={typeof messageTemplate.isDisabled === 'object' ? messageTemplate.isDisabled.disabledText : ''}>
          <Accordion
            key={messageTemplate?.id}
            sx={{
              width: '100%',
              margin: '0 !important',
              '&.MuiAccordion-root': {
                boxShadow: 'none'
              }
            }}
            expanded={currentMessageTemplate?.id === messageTemplate?.id}
            onChange={handleChange(messageTemplate)}
            disabled={typeof messageTemplate.isDisabled === 'object' ? messageTemplate.isDisabled.disabled : messageTemplate.isDisabled}
          >
            <AccordionSummary
              sx={{
                '&.Mui-expanded': {
                  margin: '0 !important',
                  padding: '0 !important'
                },
                borderBottom: `1px solid ${color.grey400}`
              }}
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: color.primaryMain
                  }}
                />
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Body1 sx={{ width: '100%', flexShrink: 0 }}>{messageTemplate?.category}</Body1>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '0'
              }}
            >
              <List
                sx={{
                  width: '100%',
                  padding: '0'
                }}
              >
                {messageTemplate?.messageTemplateItems?.map(templateItem => (
                  <ListItem key={templateItem?.id} divider>
                    <ListItemText
                      sx={{
                        marginLeft: 1,
                        my: 1
                      }}
                      primary={parse(templateItem?.text)}
                    />
                    <ListItemIcon>
                      <IconButton
                        size="small"
                        sx={{
                          color: color.grey700
                        }}
                        aria-label="send message"
                        type="button"
                        onClick={() => {
                          onSelectMessageTemplateItem(templateItem);
                        }}
                      >
                        <Send />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Tooltip>
      ))}
    </Stack>
  );
};

export default MessageTemplatesList;
