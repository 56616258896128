import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

type Props = LinkProps & BoxProps;

const Link = forwardRef(({ ...props }: Props, ref) => {
  return (
    <Box
      ref={ref}
      sx={{ color: 'truentityMediumGray.main', outline: 'none', textDecoration: 'none' }}
      component={ReactRouterLink}
      {...props}
    />
  );
});

export default Link;
