import styled from '@/styles';

const FlexContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    horizontal: {
      true: {
        flexDirection: 'row'
      },
      false: {}
    },
    color: {
      darkCyan: {
        backgroundColor: '$colors$truentityDarkCyan'
      },
      white: {
        backgroundColor: '$colors$truentityWhite'
      }
    },
    justify: {
      start: {
        justifyContent: 'flex-start'
      },
      even: {
        justifyContent: 'space-evenly'
      },
      between: {
        justifyContent: 'space-between'
      },
      end: {
        justifyContent: 'flex-end'
      }
    },
    align: {
      start: {
        alignItems: 'flex-start'
      }
    }
  }
});

FlexContainer.displayName = 'stitches(FlexContainer)';

export default FlexContainer;
