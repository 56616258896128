import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import type { DropzoneFile } from '../TruentityDropzone';
import TruentityDropzone from '../TruentityDropzone';

type Props = BaseDialogProps & {
  open: boolean;
  onSave: (file) => void;
  hideDialog: () => void;
};

const UploadLogoDialog = ({ open, hideDialog, onSave, ...props }: Props) => {
  const [file, setFile] = useState<File[]>([]);

  const handleFiles = (files: DropzoneFile[]) => {
    setFile(files.map(file => file.mediaFile));
  };

  const handleSave = () => {
    onSave(file?.[0]);
    hideDialog();
  };

  return (
    <BaseDialog {...props} title={props.title} open={open} hideDialog={hideDialog} fullWidth={true} maxWidth={'sm'}>
      <DialogContent>
        <TruentityDropzone maxFileSize={10} onFileUpload={handleFiles} onRemoveFile={handleFiles} />
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={file.length <= 0}>
          Save
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default UploadLogoDialog;
