/* eslint-disable @typescript-eslint/no-unsafe-argument */
//TODO: Look at how to sort out types for ShowModals (https://app.clickup.com/t/864dt4mkz)

import { EventTypeFilter } from '@/routes/TransitionalCareManagement/AdtQueue';
import { FilterModalTypes } from '@/routes/TransitionalCareManagement/AdtQueueUtil';
import { DISCHARGE_TYPES } from '@/types/dischargePatient';

import TruentityDatePicker from '@/components/TruentityDatePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import Button from '../Button';
import { FilterType, OperatorType, reAdmissionRiskType } from '../Dialogs/AdtFilterDialog';
import { Body1 } from '../Typography';

const AdtFilterTemplateItems = ({
  onFilterAddClicked,
  onUpdateFilterClicked,
  selectedTemplate,
  formInputFields,
  handleFormChange,
  handleDateChange,
  handleToDateChange,
  onDeleteIconClicked,
  onRemoveFilterClicked
}) => {
  const filterIndividualComponent = () => {
    let componentsArray = formInputFields.map((row, index) => {
      return (
        <Grid item xs={12} key={index}>
          <Stack direction="row" spacing={0.5} sx={{ justifyContent: 'start', alignItems: 'center', padding: '0 0 0 0', marginTop: 0 }}>
            <Body1 sx={{ fontWeight: 'bold' }} mr={1}>
              Where:{' '}
            </Body1>

            <FormControl variant="outlined" sx={{ width: '50%' }} margin="dense" size="medium">
              <Select
                name="filterType"
                labelId={'event'}
                label={'Event'}
                placeholder={'Select filter'}
                value={row.filterType}
                sx={{ textTransform: 'capitalize' }}
                onChange={event => handleFormChange(index, event)}
              >
                {Object.values(FilterType).map(value => (
                  <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {row.filterType && (
              <FormControl variant="outlined" sx={{ width: '35%' }} margin="dense" size="medium">
                <Select
                  name="operatorType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a event type'}
                  value={'is' || row.operatorType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {Object.values(OperatorType).map(value => (
                    <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {row.filterType === FilterType.Event && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Select
                  name="eventType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a event type'}
                  value={row.eventType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {Object.values(EventTypeFilter).map(value => (
                    <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {row.filterType === FilterType.ReadmissionRisk && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Select
                  name="eventType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a readmission type'}
                  value={row.eventType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {Object.values(reAdmissionRiskType).map(value => (
                    <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {row.filterType === FilterType.AdmissionReason && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <TextField
                  name="eventType"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={row.eventType}
                  onChange={event => handleFormChange(index, event)}
                  label="Admission Reason"
                />
              </FormControl>
            )}

            {row.filterType === FilterType.DischargingPhysician && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <TextField
                  name="eventType"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  value={row.eventType}
                  onChange={event => handleFormChange(index, event)}
                  label="Admission Reason"
                />
              </FormControl>
            )}

            {row.filterType === FilterType.DischargeDate && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Stack direction={'row'} justifyContent="space-between">
                  <TruentityDatePicker
                    label="From"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[0]}
                    onChange={event => handleDateChange(index, event)}
                  />
                  <TruentityDatePicker
                    label="To"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[1]}
                    onChange={event => handleToDateChange(index, event)}
                  />
                </Stack>
              </FormControl>
            )}

            {row.filterType === FilterType.AdmissionDate && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Stack direction={'row'} justifyContent="space-between">
                  <TruentityDatePicker
                    label="From"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[0]}
                    onChange={event => handleDateChange(index, event)}
                  />
                  <TruentityDatePicker
                    label="To"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[1]}
                    onChange={event => handleToDateChange(index, event)}
                  />
                </Stack>
              </FormControl>
            )}

            {row.filterType === FilterType.InitialCommunicationDueBy && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Stack direction={'row'} justifyContent="space-between">
                  <TruentityDatePicker
                    label="From"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[0]}
                    onChange={event => handleDateChange(index, event)}
                  />
                  <TruentityDatePicker
                    label="To"
                    value={row && row.eventType && row.eventType.length > 0 && row.eventType[1]}
                    onChange={event => handleToDateChange(index, event)}
                  />
                </Stack>
              </FormControl>
            )}

            {row.filterType === FilterType.DischargedDisposition && (
              <FormControl variant="outlined" sx={{ width: '55%' }} margin="dense" size="medium">
                <Select
                  name="eventType"
                  labelId={'event'}
                  label={'Is'}
                  placeholder={'select a event type'}
                  value={row.eventType}
                  sx={{ textTransform: 'capitalize' }}
                  onChange={event => handleFormChange(index, event)}
                >
                  {DISCHARGE_TYPES.map(({ label, value }) => {
                    return (
                      <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

            <IconButton onClick={() => onDeleteIconClicked(index, formInputFields, FilterModalTypes.AdtFilterTemplate)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Grid>
      );
    });

    return componentsArray;
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const DeleteConfirmationPopover = selectedTemplate => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Typography sx={{ pt: 2, pb: 1, pl: 2, pr: 2 }}>Are you sure you want to delete this template ?</Typography>
        <Stack direction={'row'} sx={{ pt: 1, pb: 2, pl: 2, pr: 2, justifyContent: 'flex-end' }} spacing={0.8}>
          <Button a11yLabel="Yes" appearance="secondary" onClick={() => onRemoveFilterClicked(selectedTemplate)} />
          <Button a11yLabel="No" type="reset" appearance="primary" onClick={handleClose} />
        </Stack>
      </Popover>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} ml={2}>
        {filterIndividualComponent()}

        <Grid item xs={12}>
          <Button
            variant="text"
            startIcon={<AddIcon />}
            onClick={() => {
              onFilterAddClicked(formInputFields, FilterModalTypes.AdtFilterTemplate);
            }}
            size={'small'}
          >
            Add Filter
          </Button>
        </Grid>

        <Grid item xs={12}>
          <DialogActions sx={{ justifyContent: 'flex-end', padding: '30px 0 10px 0' }}>
            <Stack direction={'row'} spacing={1}>
              <Button
                type="button"
                a11yLabel="Update"
                appearance="primary"
                onClick={() => {
                  onUpdateFilterClicked(selectedTemplate, formInputFields);
                }}
                disabled={!selectedTemplate}
              />
              <Button
                type="reset"
                a11yLabel="Remove"
                appearance="outline"
                onClick={e => {
                  handleClick(e);
                }}
                disabled={!selectedTemplate}
              />
              {DeleteConfirmationPopover(selectedTemplate)}
            </Stack>
          </DialogActions>
        </Grid>

        <Divider />

        <Grid item xs={12}>
          <DialogActions sx={{ justifyContent: 'flex-end', padding: '10px 0 0 0' }}>
            <Stack direction={'row'} spacing={1}>
              <Button type="submit" a11yLabel="Apply" appearance="primary" />
              <Button
                disabled
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  // hideDialog();
                }}
              />
            </Stack>
          </DialogActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdtFilterTemplateItems;
