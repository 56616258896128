import Button from '@/components/Button';
import useMessages from '@/hooks/useMessages';
import useNotifications from '@/hooks/useNotifications';
import { UserTypes } from '@/types/chat';
import type { NotificationItem } from '@/types/notifications';
import { stringAvatar } from '@/util/avatar';
import { BlockRounded } from '@mui/icons-material';
import CircleIcon from '@mui/icons-material/Circle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface NotificationsPopoverProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
}

export const NotificationsPopover = (props: NotificationsPopoverProps) => {
  const navigate = useNavigate();
  const { anchorEl, onClose, open } = props;

  const { notifications, readAllNotifications } = useNotifications();
  const { setIsMessageDrawerOpen } = useMessages();

  const handleNotificationClick = async (notification: NotificationItem) => {
    // navigate to patient message box and open message drawer
    setIsMessageDrawerOpen(true);
    onClose();
    navigate(`/patients/${notification?.patientTruentiyId}/details/medications/triage/list`);
  };

  const handleReadAllNotifications = async () => {
    // mark all notifications as read
    await readAllNotifications();
    onClose();
  };

  const onViewAllNotifications = () => {
    onClose();
    navigate('/patients/conversations');
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      sx={{
        '& .MuiPopover-paper': {
          width: 450,
          maxWidth: '100%'
        }
      }}
      onClose={onClose}
      open={open}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
          maxWidth: 350,
          width: 350
        }}
      >
        <Typography color="text.secondary" variant="h3">
          Notifications
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ maxHeight: 320, overflowY: 'auto' }}>
        {notifications?.length === 0 ? (
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <BlockRounded
              sx={{
                m: 2
              }}
            />
            <Typography color="text.secondary" variant="h3">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.map(notification => {
                return (
                  <ListItem
                    divider
                    key={notification.id}
                    sx={{
                      backgroundColor: !(notification.isRead || notification.type === UserTypes.user)
                        ? 'background.paper'
                        : 'background.default',
                      '&:hover': {
                        backgroundColor: 'background.paper'
                      },
                      cursor: 'pointer'
                    }}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'stretch',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <Box>
                          <ListItemAvatar>
                            <Avatar
                              {...stringAvatar(notification?.user?.avatar ?? '')}
                              sx={{ bgColor: 'blue' }}
                              aria-label="notification-avatar"
                            />
                          </ListItemAvatar>
                        </Box>
                        <Box
                          sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <ListItemText
                            primary={`${notification.user?.name}`}
                            primaryTypographyProps={{
                              variant: 'subtitle2',
                              color: 'text.primary'
                            }}
                            sx={{
                              whiteSpace: 'normal'
                            }}
                            secondary={notification.message}
                          />
                          {!notification.isRead && notification.type === UserTypes.otherUser ? (
                            <CircleIcon
                              sx={{
                                color: 'primary.main'
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          mt: 1,
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant={'caption'} color={'text.secondary'}>
                          {notification.createdAt.toLocaleTimeString('en-US', {
                            weekday: 'long',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          })}
                        </Typography>
                        <Typography variant={'caption'} color={'text.secondary'}>
                          {notification.createdAt.toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric'
                          })}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
            <Divider />
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button
                color="primary"
                sx={{
                  p: 1,
                  textTransform: 'none'
                }}
                variant="text"
                startIcon={<DoneAllIcon fontSize="inherit" />}
                onClick={() => handleReadAllNotifications()}
              >
                <Typography color="primary" variant="subtitle2">
                  Mark all as read
                </Typography>
              </Button>
              <Button type="button" onClick={onViewAllNotifications} color="primary" size="small" sx={{ mt: 1 }} variant="contained">
                View all notifications
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Popover>
  );
};
