import { create } from 'zustand';

type SwitchCompanyType = {
  id: string;
  name: string;
};

export type SwitchCompanyStore = {
  newCompany: SwitchCompanyType | null;
  setNewCompany: (value: SwitchCompanyType | null) => void;
  currentCompany: SwitchCompanyType | null;
  setCurrentCompany: (value: SwitchCompanyType | null) => void;
};

export const useSwitchCompanyStore = create<SwitchCompanyStore>(set => ({
  newCompany: null,
  setNewCompany: value => set({ newCompany: value }),
  currentCompany: null,
  setCurrentCompany: value => set({ currentCompany: value })
}));
