import { DialogActions, DialogContent } from '@mui/material';
import Alert from '../Alert';
import Button from '../Button';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  success: boolean;
  importResponseMessage: string;
  onConfirm: () => void;
};

const DirectImportDialog = ({ title, success, importResponseMessage, hideDialog, onConfirm, ...props }: Props) => {
  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog}>
      <DialogContent>
        <Alert
          title={success ? 'Import Successful' : 'Error: Could not import medications for this patient'}
          description={importResponseMessage}
          status={success ? 'success' : 'warning'}
        />

        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          <Button a11yLabel="Ok" appearance="primary" onClick={onConfirm} />
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default DirectImportDialog;
