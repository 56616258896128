import UploadLogoDialog from '@/components/Dialogs/UploadLogoDialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';

export const withUploadLogic = Component => {
  return function WrappedComponent(props) {
    const { enqueueSnackbar } = useSnackbar();
    const { showModal } = useModal();
    const theme = useTheme();

    const showUploadLogoModal = () => {
      const modalRef = showModal(UploadLogoDialog, {
        title: props.modalTitle || 'Upload File',
        hideDialog: () => {
          modalRef.hide();
        },
        onSave: file => {
          props.onUploadSuccess(file);
        },
        onError: err => {
          console.error('File retrieval failed:', err);
          modalRef.hide();
          enqueueSnackbar('Could not retrieve file.', { variant: 'error' });
        }
      });
    };

    return (
      <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={showUploadLogoModal}>
        <Component {...props} />
        <Box
          sx={{
            borderRadius: '50%',
            width: 20,
            height: 20,
            padding: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            right: -5,
            bottom: -5,
            backgroundColor: theme.palette.grey[400],
            color: 'white',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'primary.dark'
            }
          }}
        >
          <CloudUploadIcon fontSize={'8rem'} />
        </Box>
      </Box>
    );
  };
};
