import Button from '@/components/Button';
import ReportsTable from '@/components/ReportsTable';
import { H1 } from '@/components/Typography';
import { GET_HEALTH_PLAN_ANALYTICS } from '@/graphql/analytics';
import { useLazyQuery } from '@apollo/client';
import { Grid, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';

const HealthPlan = () => {
  const [getHealthPlanAnalytics, { loading: loadingHealthPlanAnalytics }] = useLazyQuery(GET_HEALTH_PLAN_ANALYTICS);

  const [healthPlansByOrgStores, setHealthPlansByOrgStores] = useState([]);
  const [healthPlansByPharmacyOrgs, setHealthPlansByPharmacyOrgs] = useState([]);
  const [healthPlansByPharmacyOrgsStores, setHealthPlansByPharmacyOrgsStores] = useState([]);
  const [healthPlansOverall, setHealthPlansOverall] = useState([]);

  const [healthPlansLoading, setHealthPlansLoading] = useState(false);

  const callGetTasks = async () => {
    setHealthPlansLoading(true);

    try {
      const healthPlanData = await getHealthPlanAnalytics();

      if (healthPlanData && healthPlansByOrgStores?.length === 0) {
        const healthPlanAnalyticsMetrics = healthPlanData?.data?.healthPlanAnalytics?.healthPlanAnalyiticsMetrics;

        setHealthPlansByOrgStores(healthPlanAnalyticsMetrics?.healthPlansByOrgStores || []);
        setHealthPlansByPharmacyOrgs(healthPlanAnalyticsMetrics?.healthPlansByPharmacyOrgs || []);
        setHealthPlansByPharmacyOrgsStores(healthPlanAnalyticsMetrics?.healthPlansByPharmacyOrgsStores || []);
        setHealthPlansOverall(healthPlanAnalyticsMetrics?.healthPlansOverall || []);
      }

      setHealthPlansLoading(false);
    } catch (error) {
      setHealthPlansLoading(false);
      console.error('Error retrieving health plan analytics data:', error);
    }
  };

  const createGridColumn = (
    fieldOne: string,
    fieldTwo: string,
    fieldThree: string,
    fieldFour: string,
    headerOne: string,
    headerTwo: string,
    headerThree: string,
    headerFour: string
  ): GridColDef[] => [
    {
      field: fieldOne,
      headerName: headerOne
    },
    {
      field: fieldTwo,
      headerName: headerTwo
    },
    {
      field: fieldThree,
      headerName: headerThree
    },
    {
      field: fieldFour,
      headerName: headerFour
    }
  ];

  const plansByOrgStoresColumns: GridColDef[] = useMemo(
    () => createGridColumn('org', 'plan', 'count', '', 'Org Name', 'Plan', 'Count', ''),
    []
  );
  const plansByPharmacyOrgsColumns: GridColDef[] = useMemo(
    () => createGridColumn('org', 'plan', 'count', '', 'Org Name', 'Plan', 'Count', ''),
    []
  );
  const plansByPharmacyOrgsStoresColumns: GridColDef[] = useMemo(
    () => createGridColumn('org', 'plan', 'name', 'count', 'Org Name', 'Plan', 'Name', 'Count'),
    []
  );
  const plansOverallColumns: GridColDef[] = useMemo(() => createGridColumn('org', 'count', '', '', 'Org Name', 'Count', '', ''), []);

  return (
    <Paper component={Stack} sx={{ padding: '20px' }}>
      <Grid container mt={2}>
        <Grid item xs={5}>
          <Stack alignItems={'left'} direction="row" justifyContent={'flex-start'}>
            <Button label="Run Reports" onClick={callGetTasks} />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          {' '}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack alignItems={'center'} direction="row" justifyContent={'center'}>
            <H1>Health Plan Analytics</H1>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={4}>
          <ReportsTable
            columns={plansByOrgStoresColumns}
            tableName="Health Plans By Org and Stores"
            rows={healthPlansByOrgStores}
            loader={loadingHealthPlanAnalytics || healthPlansLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <ReportsTable
            columns={plansByPharmacyOrgsColumns}
            tableName="Health Plans By Pharmacy and Orgs"
            rows={healthPlansByPharmacyOrgs}
            loader={loadingHealthPlanAnalytics || healthPlansLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <ReportsTable
            columns={plansByPharmacyOrgsStoresColumns}
            tableName="Health Plans By Pharmacy, Orgs and Stores"
            rows={healthPlansByPharmacyOrgsStores}
            loader={loadingHealthPlanAnalytics || healthPlansLoading}
          />
        </Grid>
        <Grid item xs={4}>
          <ReportsTable
            columns={plansOverallColumns}
            tableName="Health Plans Overall"
            rows={healthPlansOverall}
            loader={loadingHealthPlanAnalytics || healthPlansLoading}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HealthPlan;
