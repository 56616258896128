import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import DeleteRelyingPartyProvider from '@/components/Dialogs/DeleteRelyingPartyProvider';
import ManageRelyingPartyProvidersDialog from '@/components/Dialogs/ManageRelyingPartryProviderDialog';
import UpdateRelyingPartyProviderDialog from '@/components/Dialogs/UpdateRelyingPartyProviderDialog';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { DELETE_RELYING_PARTY_PROVIDER } from '@/graphql/provider';
import type { GetRelyingPartyProvidersResponse, RelyingPartyProviderType } from '@/graphql/remotePatientMonitoring';
import { GET_RELYING_PARTY_PROVIDERS } from '@/graphql/remotePatientMonitoring';
import type { RelyingParty } from '@/types/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RateReviewIcon from '@mui/icons-material/RateReview';
import StarsIcon from '@mui/icons-material/Stars';
import { IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type ProvidersSectionProps = {
  providerData: RelyingParty;
  assignedRelyingPartyProviders: RelyingPartyProviderType[];
};

const ProvidersSection: React.FC<ProvidersSectionProps> = ({ providerData, assignedRelyingPartyProviders }) => {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [providers, setProviders] = useState<RelyingPartyProviderType[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  const [deleteRelyingPartyProvider] = useMutation(DELETE_RELYING_PARTY_PROVIDER);

  const [
    getRelyingPartyProvidersDataQuery,
    { data: relyingPartyProvidersData, refetch: relyingPartyProvidersRefetch, loading: relyingPartyProvidersDataLoading }
  ] = useLazyQuery<GetRelyingPartyProvidersResponse>(GET_RELYING_PARTY_PROVIDERS, { fetchPolicy: 'cache-and-network' });

  const getRelyingPartyProviders = useCallback(async () => {
    try {
      await getRelyingPartyProvidersDataQuery({
        variables: {
          relyingPartyId: providerData.id,
          pageNum: currentPage + 1,
          pageSize: DEFAULT_PAGE_SIZE
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [currentPage, getRelyingPartyProvidersDataQuery, providerData.id]);

  const showManageProviderDialog = () => {
    const modal = showModal(ManageRelyingPartyProvidersDialog, {
      relyingPartyId: providerData.id,
      assignedRelyingPartyProviders: assignedRelyingPartyProviders,
      fullName: providerData?.name,
      title: '',
      hideDialog: () => {
        modal.hide();
        relyingPartyProvidersRefetch();
      }
    });
  };

  const onUnassignProviderConfirmed = useCallback(
    (providerId: string) => {
      deleteRelyingPartyProvider({
        variables: {
          providerId: providerId
        }
      })
        .then(() => {
          relyingPartyProvidersRefetch().catch(console.error);
        })
        .catch(() => {
          enqueueSnackbar('Error while unassign provider', {
            variant: 'error'
          });
        });
    },
    [relyingPartyProvidersRefetch, deleteRelyingPartyProvider, enqueueSnackbar]
  );

  const showRemoveRelyingPartyProviderDialog = useCallback(
    (noteId: string) => {
      const modalRef = showModal(DeleteRelyingPartyProvider, {
        title: 'Unassign Provider',
        description: 'Are you sure you want to unassign this provider?',
        onConfirm: () => {
          modalRef.hide();
          onUnassignProviderConfirmed(noteId);
        },
        hideDialog: () => modalRef.hide()
      });
    },
    [onUnassignProviderConfirmed, showModal]
  );

  const showUpdateRelyingPartyProviderDialog = useCallback(
    (data: RelyingPartyProviderType) => {
      const modal = showModal(UpdateRelyingPartyProviderDialog, {
        title: 'Update Provider',
        hideDialog: () => {
          relyingPartyProvidersRefetch();
          modal.hide();
        },
        data: data
      });
    },
    [relyingPartyProvidersRefetch, showModal]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'individualFirstName',
        headerName: 'First Name',
        sortable: true,
        valueGetter: params => params.row.provider.individualFirstName,
        flex: 2,
        align: 'left'
      },
      {
        field: 'individualLastName',
        headerName: 'Last Name',
        sortable: true,
        valueGetter: params => params.row.provider.individualLastName,
        flex: 2
      },
      {
        field: 'npiNumber',
        headerName: 'NPI Number',
        sortable: true,
        valueGetter: params => params.row.provider.npiNumber,
        flex: 2
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        sortable: true,
        flex: 2,
        valueGetter:
          (params => {
            let val = '';
            params.row?.provider?.contacts?.map(contact => {
              if (contact.type === 'Email') {
                val = contact.value;
              }
            });
            return val;
          }) || ''
      },
      {
        field: 'isRpmReviewer',
        headerName: 'RPM Reviewer',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => params.row.isRpmReviewer && <RateReviewIcon />,
        sortable: false
      },
      {
        field: 'isPrimary',
        headerName: 'Primary',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => params.row.isPrimary && <StarsIcon />,
        sortable: false
      },
      {
        field: 'phone',
        headerName: 'Phone',
        type: 'string',
        sortable: true,
        flex: 2,
        renderCell: params => <TruentityPhoneNumber value={params.value} />,
        valueGetter:
          (params => {
            let val = '';
            params.row?.provider?.contacts?.map(contact => {
              if (contact.type === 'Telephone') {
                val = contact.value;
              }
            });
            return val;
          }) || ''
      },
      {
        field: 'fax',
        headerName: 'Fax',
        type: 'string',
        sortable: true,
        flex: 2,
        renderCell: params => <TruentityPhoneNumber value={params.value} />,
        valueGetter:
          (params => {
            let val = '';
            params.row?.provider?.contacts?.map(contact => {
              if (contact.type === 'Fax') {
                val = contact.value;
              }
            });
            return val;
          }) || ''
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 2,
        minWidth: 130,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => (
          <Stack direction="row" justifyContent="flex-end" alignItems="center" width="100%" flexWrap="wrap">
            <IconButton
              color="primary"
              onClick={e => {
                e.preventDefault();
                showUpdateRelyingPartyProviderDialog(params.row);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={e => {
                e.preventDefault();
                showRemoveRelyingPartyProviderDialog(params.row.provider.id);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        )
      }
    ],
    [showRemoveRelyingPartyProviderDialog, showUpdateRelyingPartyProviderDialog]
  );

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  useEffect(() => {
    if (relyingPartyProvidersData?.relyingPartyProviders) {
      setProviders(relyingPartyProvidersData?.relyingPartyProviders?.relyingPartyProviders);
      setRowCount(relyingPartyProvidersData?.relyingPartyProviders?.meta.totalCount || 0);
    }
  }, [relyingPartyProvidersData, currentPage]);

  useEffect(() => {
    getRelyingPartyProviders();
  }, [currentPage, getRelyingPartyProviders]);

  return (
    <Stack mt={3}>
      <Stack
        sx={{
          marginY: 2
        }}
        direction={'row'}
        justifyContent={'flex-end'}
      >
        <Button sx={{ width: '180px', height: '40px' }} label={'ASSIGN PROVIDERS'} onClick={() => showManageProviderDialog()} />
      </Stack>
      <Paper
        component={Stack}
        direction="column"
        sx={{
          width: '100%'
        }}
      >
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-company-providers'}
            autoHeight
            rows={providers}
            rowCount={rowCountState}
            loading={relyingPartyProvidersDataLoading}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            paginationMode="server"
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            keepNonExistentRowsSelected
            getRowId={() => Math.floor(Math.random() * 100)}
            sx={{ backgroundColor: '#ffffff' }}
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default ProvidersSection;
