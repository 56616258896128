import { gql, useLazyQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import { useModal } from 'mui-modal-provider';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Button from '@/components/Button';
import ChipBadge from '@/components/ChipBadge';
import Table, { TableCell, TableHead, TableHeading, TableRow } from '@/elements/Table';
import { Paper, Stack } from '@mui/material';

import Alert from '@/components/Alert';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { CreateRequestDialog } from '@/components/Dialogs';
import Pagination from '@/components/Pagination';
import TruentityTextField from '@/components/TruentityTextField';
import useDebounce from '@/hooks/useDebounce';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { formatDateAndTime } from '@/util/format';

const GET_REQUESTS = gql`
  query getRequests($pageNum: Int!, $pageSize: Int!, $search: String, $endDate: ISO8601DateTime, $truentityId: String) {
    requests(pageNum: $pageNum, pageSize: $pageSize, search: $search, endDate: $endDate, truentityId: $truentityId) {
      requests {
        id
        account {
          truentityId
          user {
            firstName
            lastName
          }
        }
        createdAt
        status
        body
        updatedAt
        type
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

const renderItems = (item: any, navigate: Function) => {
  const purpose = JSON.parse(item.body);
  const status = item.status.toLowerCase();

  const handleNavigation = (item: any) => {
    if (item.type === 'Requests::ShareMedication') {
      navigate(`/patients/${item.account.truentityId}/details`);
    } else {
      navigate(`/patients/${item.account.truentityId}/immunization`);
    }
  };

  return (
    <TableRow key={item.id}>
      <TableCell>{`${item.account.user.firstName} ${item.account.user.lastName}`}</TableCell>
      <TableCell>{formatDateAndTime(item.createdAt)}</TableCell>
      <TableCell>{formatDateAndTime(item.updatedAt)}</TableCell>
      <TableCell>{item.account.truentityId}</TableCell>
      <TableCell>{purpose.purpose}</TableCell>
      <TableCell>
        <ChipBadge status={status} />
      </TableCell>
      <TableCell>
        <Button a11yLabel="Detail" appearance="outline" disabled={status !== 'approved'} onClick={() => handleNavigation(item)} />
      </TableCell>
    </TableRow>
  );
};

const Requests = () => {
  const navigate = useNavigate();
  const [getInfo, { data, loading, called }] = useLazyQuery(GET_REQUESTS, {
    pollInterval: 5000
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState(new Date());
  const [query, setQuery] = useState('');
  const debouncedSearchTerm = useDebounce<string>(query, 300);
  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);

  const isDataLoaded = () => called && !loading && data && data.requests.requests;

  const isThereData = () => isDataLoaded() && data.requests.meta.totalCount > 0;

  const thereIsNoData = () => isDataLoaded() && data.requests.meta.totalCount === 0;

  const { showModal } = useModal();

  const onChangeText = value => {
    setQuery(value.target.value);
  };

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  useEffect(() => {
    getInfo({
      variables: {
        pageNum: currentPage,
        pageSize: DEFAULT_PAGE_SIZE,
        search: debouncedSearchTerm,
        endDate: null,
        truentityId: null
      }
    });
  }, [currentPage, date, debouncedSearchTerm]);

  const onAddRequest = useCallback(() => {
    showCreateRequestModal();
  }, []);

  const showCreateRequestModal = () => {
    const createRequestDialog = showModal(CreateRequestDialog, {
      title: 'Patient Medication Request Info',
      successMessage: `Request has been sent to patient.`,
      hideDialog: () => createRequestDialog.hide()
    });
  };

  return (
    <Stack spacing={2} p={2}>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
        <TruentityTextField icon={<SearchIcon />} placeholder="Search" onChange={onChangeText} sx={{ width: '300px' }} />

        <Button a11yLabel="New Request" onClick={onAddRequest} disabled={isProviderAdmin} />
      </Stack>

      <Paper component={Stack}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeading>Name</TableHeading>
              <TableHeading>Request Sent</TableHeading>
              <TableHeading>Request Grant Time</TableHeading>
              <TableHeading>Truentity ID</TableHeading>
              <TableHeading>Purpose</TableHeading>
              <TableHeading>Status</TableHeading>
              <TableHeading>Details</TableHeading>
            </TableRow>
          </TableHead>
          <tbody>{isThereData() && data.requests.requests.map(item => renderItems(item, navigate))}</tbody>
        </Table>
        {thereIsNoData() && <Alert description="There are no requests registered in the database " status="warning" title="No Requests" />}
        {isThereData() && (
          <Pagination
            currentPage={currentPage}
            totalRecords={data.requests.meta.totalCount}
            pageLimit={data.requests.meta.totalPages}
            pageNeighbours={2}
            onPageChanged={updateData}
          />
        )}
      </Paper>
    </Stack>
  );
};

export default Requests;
