import CollapsibleListGroup from '@/components/CollapsibleListGroup';
import CustomListItem from '@/components/CustomListItem';
import type { TimelineItemData } from '@/components/CustomTimeline';
import CustomTimeline from '@/components/CustomTimeline';
import LabelGroup from '@/components/LabelGroup';
import { Body1, Body2 } from '@/components/Typography';
import type { MuiColor } from '@/types/mui';
import { formatDate } from '@/util/format';
import { localeCompare } from '@/util/sort';
import ReportIcon from '@mui/icons-material/Report';
import { Avatar, Box, Chip, Grid, List, ListItemAvatar, Stack } from '@mui/material';
import { InsightGridContent } from './InsightGridContent';
import type { InsightsType } from './insights-types';

const colorMap = {
  severe: 'error',
  moderate: 'warning',
  low: 'success'
} as const;

type SeverityIconProps = {
  severity: 'severe' | 'moderate' | 'low';
};
const SeverityIcon = ({ severity }: SeverityIconProps) => {
  const color: Partial<MuiColor> = colorMap[severity];
  return <ReportIcon color={color} />;
};

type GeneratedInsightsProps = {
  data: InsightsType;
  showSummary: boolean;
  showVisits: boolean;
  showDrugCondition: boolean;
};
export const GeneratedInsights = ({ data, showSummary, showVisits, showDrugCondition }: GeneratedInsightsProps) => {
  return (
    <Stack alignItems="flex-start" sx={{ minHeight: '100px' }} p={4} spacing={4}>
      {showSummary && (
        <Grid container gap={4}>
          <InsightGridContent item sm={12} title="Summary">
            <Box component="ul">
              {data?.insightSummary?.map(item => (
                <Box key={item.text} component="li">
                  {item.text}
                </Box>
              ))}
            </Box>
          </InsightGridContent>

          <InsightGridContent item title="Medications">
            {data?.insightSummaryMedications?.map((item, index) => (
              <CollapsibleListGroup
                key={item.name + '-' + index}
                icon={
                  <ListItemAvatar>
                    <Avatar src={item.image} variant="circular" sx={{ backgroundColor: 'transparent', img: { objectFit: 'contain' } }} />
                  </ListItemAvatar>
                }
                primaryText={
                  <Body1 fontSize={'1.1em'} fontWeight={500}>
                    {item.name}
                  </Body1>
                }
              >
                <Box pl={10} pr={2}>
                  {item.dose && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      marginBottom={0}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Dose"
                    >
                      <Chip label={item.dose}></Chip>
                    </LabelGroup>
                  )}
                  {item.frequency && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      marginBottom={0}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Frequency"
                    >
                      <Chip label={item.frequency}></Chip>
                    </LabelGroup>
                  )}
                  {item.route && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      marginBottom={0}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Route"
                    >
                      <Chip label={item.route}></Chip>
                    </LabelGroup>
                  )}
                  {item.end_date && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Ended"
                    >
                      <Chip label={formatDate(item.end_date)}></Chip>
                    </LabelGroup>
                  )}
                </Box>
              </CollapsibleListGroup>
            ))}
          </InsightGridContent>

          <InsightGridContent item title="Conditions">
            {data?.insightSummaryConditions?.map((item, index) => (
              <CollapsibleListGroup
                key={item.name + '-' + index}
                icon={
                  <ListItemAvatar>
                    <Avatar src={item.image} variant="circular" sx={{ backgroundColor: 'transparent', img: { objectFit: 'contain' } }} />
                  </ListItemAvatar>
                }
                primaryText={
                  <Body1 fontSize={'1.1em'} fontWeight={500}>
                    {item.name}
                  </Body1>
                }
              >
                <Box pl={10} pr={2}>
                  {item.diagnosis_date && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      marginBottom={0}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Diagnosis Date"
                    >
                      <Chip label={formatDate(item.diagnosis_date)}></Chip>
                    </LabelGroup>
                  )}
                  {item.status && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      marginBottom={0}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Status"
                    >
                      <Chip label={item.status}></Chip>
                    </LabelGroup>
                  )}
                </Box>
              </CollapsibleListGroup>
            ))}
          </InsightGridContent>

          <InsightGridContent item title="Procedures">
            {data?.insightSummaryProcedures?.map((item, index) => (
              <CollapsibleListGroup
                key={item.name + '-' + index}
                icon={
                  <ListItemAvatar>
                    <Avatar src={item.image} variant="circular" sx={{ backgroundColor: 'transparent', img: { objectFit: 'contain' } }} />
                  </ListItemAvatar>
                }
                primaryText={
                  <Body1 fontSize={'1.1em'} fontWeight={500}>
                    {item.name}
                  </Body1>
                }
              >
                <Box pl={10} pr={2}>
                  {item.date && (
                    <LabelGroup
                      LabelProps={{ color: 'primary' }}
                      marginBottom={0}
                      flexDirection={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      label="Date"
                    >
                      <Chip label={formatDate(item.date)}></Chip>
                    </LabelGroup>
                  )}
                  {item.notes && (
                    <LabelGroup flexDirection={'row'} LabelProps={{ color: 'primary' }} marginBottom={0} marginTop={1} label="Notes">
                      <Body1 pl={10} textAlign="justify">
                        {item.notes}
                      </Body1>
                    </LabelGroup>
                  )}
                </Box>
              </CollapsibleListGroup>
            ))}
          </InsightGridContent>
        </Grid>
      )}

      {showVisits && (
        <Grid container p={2}>
          <InsightGridContent item title="Provider Visits" xs={12}>
            <CustomTimeline
              data={
                data?.insightSummaryProviderVisits?.sort(localeCompare('date', false)).map(
                  item =>
                    ({
                      left: (
                        <LabelGroup LabelProps={{ color: 'primary' }} my={1} label={formatDate(item.date)}>
                          <Body2>{item.reason}</Body2>
                        </LabelGroup>
                      ),

                      right: (
                        <List sx={{ background: theme => theme.palette.grey[100], paddingY: 0 }}>
                          {item.diagnosis && <CustomListItem primary={'Diagnosis'} secondary={item.diagnosis} />}
                          {item.treatment && <CustomListItem primary={'Treatment'} secondary={item.treatment} />}
                          {item.notes && <CustomListItem primary={'Notes'} secondary={item.notes} />}
                        </List>
                      )
                    } as TimelineItemData)
                ) || []
              }
            />
          </InsightGridContent>
        </Grid>
      )}

      {showDrugCondition && (
        <Grid container gap={4}>
          <InsightGridContent item title="Drug Condition Interactions">
            {data?.drugConditionInteractions?.map((item, index) => (
              <CollapsibleListGroup
                key={item.name + '-' + index}
                icon={
                  <ListItemAvatar>
                    <SeverityIcon severity={item.severity} />
                  </ListItemAvatar>
                }
                primaryText={
                  <Body1 fontSize={'1.1em'} fontWeight={500}>
                    {item.name}
                  </Body1>
                }
              >
                <Box pl={10} pr={2}>
                  <Body1>{item.reason}</Body1>
                </Box>
              </CollapsibleListGroup>
            ))}
          </InsightGridContent>

          <InsightGridContent item title="Drug Food Interactions">
            {data?.drugFoodInteractions?.map((item, index) => (
              <CollapsibleListGroup
                key={item.name + '-' + index}
                icon={
                  <ListItemAvatar>
                    <SeverityIcon severity={item.severity} />
                  </ListItemAvatar>
                }
                primaryText={
                  <Body1 fontSize={'1.1em'} fontWeight={500}>
                    {item.name}
                  </Body1>
                }
              >
                <Box pl={10} pr={2}>
                  <Body1>{item.reason}</Body1>
                </Box>
              </CollapsibleListGroup>
            ))}
          </InsightGridContent>

          <InsightGridContent item title="Drug Drug Interactions">
            {data?.drugDrugInteractions?.map((item, index) => (
              <CollapsibleListGroup
                key={item.name + '-' + index}
                icon={
                  <ListItemAvatar>
                    <SeverityIcon severity={item.severity} />
                  </ListItemAvatar>
                }
                primaryText={
                  <Body1 fontSize={'1.1em'} fontWeight={500}>
                    {item.name}
                  </Body1>
                }
              >
                <Box pl={10} pr={2}>
                  <Body1>{item.reason}</Body1>
                </Box>
              </CollapsibleListGroup>
            ))}
          </InsightGridContent>
        </Grid>
      )}
    </Stack>
  );
};
