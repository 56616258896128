import Button from '@/components/Button';
import { GET_PATIENT_DETAIL, UPDATE_USER_PROFILE } from '@/graphql/account';
import PatientDetailsForm from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/PatientDetailsForm';
import { emptyData } from '@/state/patientProfileForm';
import { FormValues } from '@/types/accountProfile';
import { toDate } from '@/util/date';
import { formatDate } from '@/util/format';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation, useQuery } from '@apollo/client';
import { DialogContent, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  hideDialog: () => void;
  onPatientUpdated: () => void;
  skipDialog: () => void;
};

const ConfirmHealthCarePatientAttributesDialog = ({ title, hideDialog, onPatientUpdated, skipDialog, ...props }: Props) => {
  const { setIsAccountChanged } = useAccountStore();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();

  const [defaultBirthDate, setDefaultBirthDate] = useState<Date | undefined>(undefined);

  const { control, reset, handleSubmit, setValue } = useForm<FormValues>({ defaultValues: emptyData });

  const { data: patientData } = useQuery(GET_PATIENT_DETAIL, {
    variables: {
      truentityId: id
    }
  });

  const [
    updateUserAccountProfile,
    { data: updateUserAccountProfileData, loading: updateUserAccountProfileLoading, error: updateUserAccountProfileError }
  ] = useMutation(UPDATE_USER_PROFILE);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const onSuccess = (success: boolean, message?: string) => {
    enqueueSnackbar(message ?? (success ? 'Profile Updated' : 'Unable to Update Profile'), { variant: success ? 'success' : 'error' });
    if (success) {
      setIsAccountChanged(true);
    }
  };

  const handleSubmitImpl = async (values: FormValues) => {
    const profileData = {
      userProfile: {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName
      },
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressState: values.addressState?.toUpperCase(),
      addressCity: values.addressCity,
      zipcode: values.zipcode,
      birthDate: formatDate(values.birthDate, 'YYYY-MM-DD'),
      gender: values.gender
    };

    try {
      await updateUserAccountProfile({
        variables: {
          truentityId: id,
          profileData
        }
      });

      onPatientUpdated();
    } catch (error: any) {
      onSuccess(false);
    }
  };

  useEffect(() => {
    if (patientData) {
      const account = patientData.accountGet;
      const user = account.user;
      const birthDate = toDate(formatDate(account.birthDate, 'YYYY-MM-DD'));
      const patientInfo: FormValues = {
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        birthDate: birthDate,
        gender: account.gender,
        zipcode: account.zipcode,
        addressLine1: account.addressLine1,
        addressLine2: account.addressLine2,
        addressState: account.addressState,
        addressCity: account.addressCity
      };
      setDefaultBirthDate(birthDate);
      reset(patientInfo);
    }
  }, [patientData, reset]);

  useEffect(() => {
    if (updateUserAccountProfileError) {
      onSuccess(false, updateUserAccountProfileError.message);
    }
  }, [updateUserAccountProfileError]);

  useEffect(() => {
    if (updateUserAccountProfileData?.updateUserAccountProfile?.account) {
      onSuccess(true);
    }
  }, [updateUserAccountProfileData]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container spacing={2} onSubmit={handleSubmit(onSubmit)}>
          <PatientDetailsForm control={control} setValue={setValue} defaultBirthDate={defaultBirthDate} />
          <Grid xs={12}>
            <Stack direction="row" spacing={2} alignContent="center" justifyContent={'flex-end'} mt={1}>
              <Button isLoading={updateUserAccountProfileLoading} type="submit" a11yLabel="Save and Continue " appearance="primary" />
              <Button
                type="button"
                onClick={() => {
                  skipDialog();
                }}
                a11yLabel="Continue"
                appearance="primary"
              />
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default ConfirmHealthCarePatientAttributesDialog;
