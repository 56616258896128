import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';

export type VerticalStepperStep = {
  header: (index: number, currentStep?: number, setCurrentStep?: Dispatch<SetStateAction<number>>) => ReactNode;
  children?: () => ReactNode;
};

interface Props {
  steps: VerticalStepperStep[];
  icon?: ReactNode;
}

const VerticalStepper = ({ steps, icon }: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(-1);
  return (
    <Stepper
      activeStep={currentStep}
      orientation="vertical"
      sx={{
        '& .MuiStepLabel-root': {
          p: 0
        },
        '& .MuiStepConnector-line': {
          minHeight: '0.75rem'
        }
      }}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel icon={icon}>{step.header(index, currentStep, setCurrentStep)}</StepLabel>
          {step.children && <StepContent>{step.children()}</StepContent>}
        </Step>
      ))}
    </Stepper>
  );
};

export default VerticalStepper;
