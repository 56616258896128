import Button from '@/components/Button';
import { Small } from '@/components/Typography';
import type { InsightConfig } from '@/routes/Patients/insights-types';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Stack } from '@mui/material';

type DataSourceItemProps = {
  item: InsightConfig;
  showPdf: (val: InsightConfig) => void;
  selected: boolean;
  onClick: (item: InsightConfig) => void;
};

const DataSourceItem = ({ item, showPdf, onClick, selected }: DataSourceItemProps) => {
  return (
    <Stack justifyContent={'flex-end'} spacing={1}>
      <Stack
        onClick={() => onClick(item)}
        data-selected={selected}
        data-type={item.type}
        data-document={item.document}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={1}
        sx={{
          width: 150,
          height: 150,
          background: selected ? theme => theme.palette.grey.A100 : theme => theme.palette.background.default,
          borderWidth: selected ? 2 : 1,
          borderColor: theme => theme.palette.primary.dark,
          borderStyle: 'solid',
          padding: 1,
          cursor: 'pointer',
          userSelect: 'none'
        }}
      >
        {item.image && item.image?.length > 0 ? (
          <Box
            component={'img'}
            sx={{
              overflow: 'hidden',
              aspectRatio: 1,
              width: '100%',
              height: '100%'
            }}
            src={item.image}
          ></Box>
        ) : (
          <PictureAsPdfIcon fontSize="large" />
        )}
        <Small sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} textAlign={'center'}>
          {item.label}
        </Small>
      </Stack>
      <Button label={'View'} onClick={() => showPdf(item)} />
    </Stack>
  );
};

export default DataSourceItem;
