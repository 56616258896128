import type React from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';

import { Small } from '@/components/Typography';
import useDebounce from '@/hooks/useDebounce';
import { emptyData, reducer } from '@/state/medicationForm';
import styled from '@/styles';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import SaveIcon from '@mui/icons-material/Save';
import { ButtonGroup, FormControl, FormControlLabel, Grid, Stack, Switch } from '@mui/material';
import Autocomplete from 'react-autocomplete';

import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import InputRow from '@/elements/InputRow';
import useToken from '@/hooks/useToken';
import { changeVerificationStatusAccountMedications } from '@/services/medication';
import { Role } from '@/types/admin';
import type { Medication, MedicationFillOption } from '@/types/medication';
import {
  DOSAGE_UNIT_OPTIONS,
  frequencyOptionsMap,
  ROUTE_OF_ADMINISTRATION_OPTIONS,
  SourceType,
  STRENGTH_UNIT_OPTIONS,
  TIME_OF_DAY_OPTIONS
} from '@/types/medication';
import { toISOString } from '@/util/date';
import {
  buildDosageValues,
  buildPeriodValues,
  buildRouteOfAdministrationValues,
  buildStrengthValues,
  buildTimeOfDayValues,
  selectDosage,
  selectPeriod,
  selectRouteOfAdministrationArray,
  selectStrength,
  selectTimeOfDayArray
} from '@/util/medications';
import { getKeyByValue } from '@/util/object';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../Button';
import MuiAccordion from '../MuiAccordion';
import SelectList from '../SelectList';
import TruentityDatePicker from '../TruentityDatePicker';
import TruentityTextField from '../TruentityTextField';
import BaseDialog from './BaseDialog';

const ADD_MEDICATION = gql`
  mutation saveMedication(
    $truentityId: String!
    $id: String
    $name: String
    $instructions: String
    $prescriberName: String
    $adherance: String
    $quantity: Float
    $prescriptionWrittenDateAt: String
    $soldDateAt: String
    $pharmacyName: String
    $ndc: String
    $numRefills: Int
    $source: String
    $dosage: [MedicationAttributeInput]
    $strength: [MedicationAttributeInput]
    $period: [MedicationAttributeInput]
    $timeOfDay: [MedicationAttributeInput]
    $routeOfAdministration: [MedicationAttributeInput]
    $lastFillDateAt: String
    $isEffective: Boolean
    $isNoLongerTaking: Boolean
    $isTakenAsPrescribed: Boolean
    $isTakingDifferently: Boolean
    $isUnableToVerify: Boolean
    $isTakenAsDirected: Boolean
    $isSupplement: Boolean
  ) {
    saveMedication(
      truentityId: $truentityId
      medication: {
        id: $id
        name: $name
        instructions: $instructions
        prescriberName: $prescriberName
        adherance: $adherance
        quantity: $quantity
        prescriptionWrittenDateAt: $prescriptionWrittenDateAt
        soldDateAt: $soldDateAt
        pharmacyName: $pharmacyName
        ndc: $ndc
        numRefills: $numRefills
        source: $source
        dosage: $dosage
        strength: $strength
        period: $period
        lastFillDateAt: $lastFillDateAt
        isEffective: $isEffective
        isNoLongerTaking: $isNoLongerTaking
        isTakenAsPrescribed: $isTakenAsPrescribed
        isTakingDifferently: $isTakingDifferently
        isUnableToVerify: $isUnableToVerify
        isTakenAsDirected: $isTakenAsDirected
        timeOfDay: $timeOfDay
        routeOfAdministration: $routeOfAdministration
        isSupplement: $isSupplement
      }
    ) {
      medication {
        id
      }
    }
  }
`;

const FILTER_MEDICATION = gql`
  query filter($medName: String!) {
    medicationSearch2(medName: $medName) {
      name
      ndc
      fillOptions {
        form
        rxterm
        rxcui
        strength
        unit
      }
    }
  }
`;

const ItemRow = styled('div', {
  width: '46.77%',
  height: 'auto',
  paddingBottom: 0
});

const ImportedByContent = styled(Small, {
  marginTop: '-6px',
  marginBottom: '10px'
});

const initialRadioButtonsConfig = {
  'taken-as-directed': true,
  'no-longer-taking': false,
  'taking-differently': false,
  'unable-to-verify': false
};

type FrequencyOption = {
  label: string;
  value: string;
};

const frequencyOptions: FrequencyOption[] = Object.entries(frequencyOptionsMap).map(([key, val]) => ({
  label: key,
  value: val
}));

type Props = BaseDialogProps & {
  id: any;
  medication?: any;
  editMode: boolean;
  sourceType?: any;
  hideDialog: () => void;
  reloadMeds: () => void;
};

const NewMedicationDialog = ({
  id,
  title,
  hideDialog,
  medication,
  editMode,
  sourceType,
  reloadMeds,
  ...props
}: Props): React.ReactElement => {
  const medicationId = medication?.id;

  const [saveMedication, { data: dataOnSaveMedication, loading: loadingOnSaveMedication, error: errorOnSaveMedication }] =
    useMutation(ADD_MEDICATION);

  const [state, dispatch] = useReducer(reducer, emptyData);

  const [filter, { loading, data: queryData, called }] = useLazyQuery(FILTER_MEDICATION);
  const [lastMedicationIdCreated, setLastMedicationIdCreated] = useState<string | null>();

  const [isEffective, setIsEffective] = useState({
    yes: true,
    no: false
  });

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [prescriptionWrittenDate, setPrescriptionWrittenDate] = useState<Date | null>(null);
  const [soldDate, setSoldDate] = useState<Date | null>(null);

  const [searchTerm, setSearchTerm] = useState(state.name);
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 300);
  const [medications, setMedications] = useState<Medication[]>([]);
  const [fillOptions, setFillOptions] = useState<MedicationFillOption[]>([]);
  const [strengthOptions, setStrengthOptions] = useState<any[]>([]);
  const [selectedStrength, setSelectedStrength] = useState('');
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [selectedModifiedFrequency, setSelectedModifiedFrequency] = useState('');
  const [medDoseAndStrength, setMedDoseAndStrength] = useState({});
  const [formOptions, setFormOptions] = useState<any[]>([]);
  const [editMedName, setEditMedName] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [imported, setImported] = useState(false);
  const [verified, setVerified] = useState(false);
  const [importedBy, setImportedBy] = useState('');
  const [displayName, setDisplayName] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const fromQuickScan: boolean = editMode && sourceType === SourceType.QUICK_SCAN;
  const navigate = useNavigate();
  const location = useLocation();

  const { roleType } = useToken();
  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);

  useEffect(() => {
    if (medication) {
      const {
        id,
        ndc,
        name,
        displayName,
        instructions,
        strength,
        dosage,
        period,
        prescriberName,
        isEffective,
        isTakenAsPrescribed,
        isNoLongerTaking,
        isTakingDifferently,
        isUnableToVerify,
        isTakenAsDirected,
        source,
        lastFillDateAt,
        routeOfAdministration,
        timeOfDay,
        isSupplement,
        rxcui,
        importedName,
        lastVerifiedById,
        numRefills,
        quantity,
        prescriptionWrittenDateAt,
        soldDateAt,
        pharmacyName
      } = medication;

      if (lastFillDateAt) {
        // Remove the Z to make the Date object ignore TZ
        const date = new Date(lastFillDateAt.slice(0, -1));
        setStartDate(date);
      }

      if (prescriptionWrittenDateAt) {
        // Remove the Z to make the Date object ignore TZ
        const date = new Date(prescriptionWrittenDateAt.slice(0, -1));
        setPrescriptionWrittenDate(date);
      }

      if (soldDateAt) {
        // Remove the Z to make the Date object ignore TZ
        const date = new Date(soldDateAt.slice(0, -1));
        setSoldDate(date);
      }

      setImported(source?.toLowerCase().startsWith('imported'));
      setVerified(lastVerifiedById !== null);
      setImportedBy(importedName);
      setDisplayName(displayName);

      setSearchTerm(name);
      dispatch({ type: 'id', payload: id });
      dispatch({ type: 'name', payload: name });
      dispatch({ type: 'ndc', payload: ndc });
      dispatch({ type: 'rxcui', payload: rxcui });

      const s = selectStrength(strength, true);
      dispatch({ type: 'strength-id', payload: s.id });
      dispatch({ type: 'strength-value', payload: s.value });
      dispatch({ type: 'strength-unit', payload: s.unit });
      const ms = selectStrength(strength, false);
      dispatch({ type: 'modified-strength-id', payload: ms.id });
      dispatch({ type: 'modified-strength-value', payload: ms.value });
      dispatch({ type: 'modified-strength-unit', payload: ms.unit });

      const d = selectDosage(dosage, true);
      dispatch({ type: 'dosage-id', payload: d.id });
      dispatch({ type: 'dosage-value', payload: d.value });
      dispatch({ type: 'dosage-unit', payload: d.unit });
      const md = selectDosage(dosage, false);
      dispatch({ type: 'modified-dosage-id', payload: md.id });
      dispatch({ type: 'modified-dosage-value', payload: md.value });
      dispatch({ type: 'modified-dosage-unit', payload: md.unit });

      const p = selectPeriod(period, true);
      dispatch({ type: 'period-id', payload: p.id });
      dispatch({ type: 'period-value', payload: p.value });
      dispatch({ type: 'period-unit', payload: p.unit });
      const mp = selectPeriod(period, false);
      dispatch({ type: 'modified-period-id', payload: mp.id });
      dispatch({ type: 'modified-period-value', payload: mp.value });
      dispatch({ type: 'modified-period-unit', payload: mp.unit });

      const r = selectRouteOfAdministrationArray(routeOfAdministration, true);
      dispatch({ type: 'route-of-administration-id', payload: r.id });
      dispatch({ type: 'route-of-administration', payload: r.value });
      const mr = selectRouteOfAdministrationArray(routeOfAdministration, false);
      dispatch({ type: 'modified-route-of-administration-id', payload: mr.id });
      dispatch({ type: 'modified-route-of-administration', payload: mr.value });

      const t = selectTimeOfDayArray(timeOfDay, true);
      dispatch({ type: 'time-of-day-id', payload: t.id });
      dispatch({ type: 'time-of-day', payload: t.value });
      const mt = selectTimeOfDayArray(timeOfDay, false);
      dispatch({ type: 'modified-time-of-day-id', payload: mt.id });
      dispatch({ type: 'modified-time-of-day', payload: mt.value });

      dispatch({ type: 'is-effective', payload: isEffective });
      dispatch({ type: 'instructions', payload: instructions || '' });
      dispatch({ type: 'prescriber', payload: prescriberName });
      dispatch({ type: 'source', payload: source });

      dispatch({ type: 'taken-as-prescribed', payload: isTakenAsPrescribed });
      dispatch({ type: 'no-longer-taking', payload: isNoLongerTaking });
      dispatch({ type: 'taking-differently', payload: isTakingDifferently });
      dispatch({ type: 'unable-to-verify', payload: isUnableToVerify });
      dispatch({ type: 'taken-as-directed', payload: isTakenAsDirected });
      dispatch({ type: 'is-supplement', payload: isSupplement });

      dispatch({ type: 'refills', payload: numRefills });
      dispatch({ type: 'quantity', payload: quantity });
      dispatch({ type: 'pharmacy', payload: pharmacyName });

      initialRadioButtonsConfig['no-longer-taking'] = isNoLongerTaking;
      initialRadioButtonsConfig['taken-as-directed'] = isTakenAsDirected;
      initialRadioButtonsConfig['taking-differently'] = isTakingDifferently;
      initialRadioButtonsConfig['unable-to-verify'] = isUnableToVerify;

      setSelectedFrequency(p.value + ':' + p.unit);

      const modifiedFrequencyKey = getKeyByValue(frequencyOptionsMap, mp.value + ':' + mp.unit) ? mp.value + ':' + mp.unit : '';
      setSelectedModifiedFrequency(modifiedFrequencyKey);
    }

    if (!editMode) {
      setEditMedName(true);
    }
  }, []);

  // update fill options when editing without interacting with text input
  useEffect(() => {
    if (medications[0]?.fillOptions && editMode) {
      setFillOptions(medications[0].fillOptions);
      setFirstLoad(false);
    }
  }, [medications, editMode, firstLoad]);

  useEffect(() => {
    if (debouncedSearchTerm?.length > 0) {
      filter({
        variables: {
          medName: debouncedSearchTerm
        }
      });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (called && !loading && queryData && queryData.medicationSearch2) {
      setMedications(queryData.medicationSearch2);
    }
  }, [queryData]);

  useEffect(() => {
    if (fromQuickScan) {
      setEditMedName(true);
    }
  }, [fromQuickScan]);

  const onChangeSearchTerm = e => {
    setSearchTerm(e.target.value);
    dispatch({ type: 'name', payload: e.target.value });
  };

  const handleAutoCompleteMedicationSelected = (value, item) => {
    setFillOptions(item.fillOptions);
    setSearchTerm(item.name);

    dispatch({ type: 'name', payload: item.name });
  };

  useEffect(() => {
    updateStrengthOptions(state.dosage.unit);
  }, [state.dosage.unit, state.name, formOptions]);

  const updateStrengthOptions = category => {
    try {
      const selectedOption = medDoseAndStrength[category];
      if (selectedOption?.length > 0) {
        const values: any[] = selectedOption.map(item => {
          const [ndc, strength, unit] = item.value.split(':');
          const label = `${strength} ${unit}`;
          const value = `${ndc}:${strength}:${unit}`;
          return { label, value };
        });

        setStrengthOptions(values);
        return;
      }
    } catch (error) {
      console.error(error);
    }

    setStrengthOptions([]);
  };

  useEffect(() => {
    if (!medDoseAndStrength) {
      setFormOptions(DOSAGE_UNIT_OPTIONS);
      return;
    }

    const formOptions = Object.keys(medDoseAndStrength).map(v => {
      return { label: v, value: v };
    });

    if (formOptions.length === 0) {
      setFormOptions(DOSAGE_UNIT_OPTIONS);
      return;
    }

    setFormOptions(formOptions);
  }, [medDoseAndStrength]);

  useEffect(() => {
    const formOptionsDict = {};

    try {
      fillOptions.forEach(opt => {
        const form = opt.form.toLowerCase();

        if (!formOptionsDict[form]) {
          formOptionsDict[form] = [];
        }

        const strength = `${opt.strength}:${opt.unit}`;
        formOptionsDict[form].push({ label: form, value: `${opt.rxcui}:${strength}` });
      });

      setMedDoseAndStrength(formOptionsDict);
    } catch (error) {
      console.error(error);
    }
  }, [fillOptions]);

  useEffect(() => {
    if (formOptions?.length === 1) {
      const optionValue = formOptions[0].value;
      updateDosage({ unit: optionValue });
    }
  }, [formOptions]);

  useEffect(() => {
    if (strengthOptions?.length > 0 && editMode) {
      setSelectedStrength(state.ndc + ':' + state.strength.value + ':' + state.strength.unit);
    }
  }, [strengthOptions, editMode]);

  useEffect(() => {
    if (!medDoseAndStrength) {
      setFormOptions(DOSAGE_UNIT_OPTIONS);
      return;
    }

    const formOptions = Object.keys(medDoseAndStrength).map(v => {
      return { label: v, value: v };
    });

    if (formOptions.length === 0) {
      setFormOptions(DOSAGE_UNIT_OPTIONS);
      return;
    }

    setFormOptions(formOptions);
  }, [medDoseAndStrength]);

  useEffect(() => {
    const formOptionsDict = {};

    try {
      fillOptions.forEach(opt => {
        const form = opt.form.toLowerCase();

        if (!formOptionsDict[form]) {
          formOptionsDict[form] = [];
        }

        const strength = `${opt.strength}:${opt.unit}`;
        formOptionsDict[form].push({ label: form, value: `${opt.rxcui}:${strength}` });
      });

      setMedDoseAndStrength(formOptionsDict);
    } catch (error) {
      console.error(error);
    }
  }, [fillOptions]);

  useEffect(() => {
    if (formOptions?.length === 1) {
      const optionValue = formOptions[0].value;
      updateDosage({ unit: optionValue });
    }
  }, [formOptions]);

  useEffect(() => {
    if (strengthOptions?.length > 0 && editMode) {
      setSelectedStrength(state.ndc + ':' + state.strength.value + ':' + state.strength.unit);
    }
  }, [strengthOptions, editMode]);

  useEffect(() => {
    if (!medDoseAndStrength) {
      setFormOptions(DOSAGE_UNIT_OPTIONS);
      return;
    }

    const formOptions = Object.keys(medDoseAndStrength).map(v => {
      return { label: v, value: v };
    });

    if (formOptions.length === 0) {
      setFormOptions(DOSAGE_UNIT_OPTIONS);
      return;
    }

    setFormOptions(formOptions);
  }, [medDoseAndStrength]);

  useEffect(() => {
    const formOptionsDict = {};

    try {
      fillOptions.forEach(opt => {
        const form = opt.form.toLowerCase();

        if (!formOptionsDict[form]) {
          formOptionsDict[form] = [];
        }

        const strength = `${opt.strength}:${opt.unit}`;
        formOptionsDict[form].push({ label: form, value: `${opt.rxcui}:${strength}` });
      });

      setMedDoseAndStrength(formOptionsDict);
    } catch (error) {
      console.error(error);
    }
  }, [fillOptions]);

  useEffect(() => {
    if (formOptions?.length === 1) {
      const optionValue = formOptions[0].value;
      updateDosage({ unit: optionValue });
    }
  }, [formOptions]);

  const handleIsEffectiveState = (event: any) => {
    const auxObj: any = {};

    Object.keys(isEffective).forEach(item => {
      auxObj[item] = event.target.id === item;
    });

    setIsEffective(auxObj);
  };

  useEffect(() => {
    if (strengthOptions?.length > 0 && editMode) {
      setSelectedStrength(state.ndc + ':' + state.strength.value + ':' + state.strength.unit);
    }
  }, [strengthOptions, editMode]);

  useEffect(() => {
    if (loadingOnSaveMedication) {
      return;
    }

    if (errorOnSaveMedication) {
      const failMessage: string = editMode ? 'Failed to Update Medication' : 'Failed to Create Medication';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
      return;
    }

    if (dataOnSaveMedication) {
      if (dataOnSaveMedication.saveMedication) {
        setLastMedicationIdCreated(dataOnSaveMedication.saveMedication.medication.id);

        const successMessage: string = editMode ? 'Medication Updated' : 'Medication Created';
        enqueueSnackbar(successMessage, {
          variant: 'success'
        });
      } else {
        const failMessage: string = editMode ? 'Failed to Update Medication' : 'Failed to Create Medication';
        enqueueSnackbar(failMessage, {
          variant: 'error'
        });
      }

      hideDialog();
    }
  }, [dataOnSaveMedication, loadingOnSaveMedication, errorOnSaveMedication]);

  useEffect(() => {
    if (lastMedicationIdCreated !== undefined) {
      reloadMeds();
    }
  }, [lastMedicationIdCreated]);

  const handleOnSubmit = useCallback(
    event => {
      event.preventDefault();

      try {
        let medication: Medication | null = null;
        const s = buildStrengthValues(state, state.strength, editMode);
        const d = buildDosageValues(state, state.dosage, editMode);
        const p = buildPeriodValues(state, state.period, editMode);
        const r = buildRouteOfAdministrationValues(state, state.routeOfAdministration, editMode);
        const t = buildTimeOfDayValues(state, state.timeOfDay, editMode);

        if (editMode) {
          medication = {
            id: state.id,
            instructions: state.instructions,
            name: state.name,
            ndc: state.ndc,
            strength: s,
            dosage: d,
            period: p,
            routeOfAdministration: r,
            timeOfDay: t,
            prescriberName: state.prescriber,
            isEffective: state.isEffective,
            isTakenAsPrescribed: state.isTakenAsPrescribed,
            isNoLongerTaking: state.isNoLongerTaking,
            isTakingDifferently: state.isTakingDifferently,
            isUnableToVerify: state.isUnableToVerify,
            isTakenAsDirected: state.isTakenAsDirected,
            quantity: Number(state.quantity),
            numRefills: parseInt(state.refills),
            prescriptionWrittenDateAt: toISOString(prescriptionWrittenDate),
            soldDateAt: toISOString(soldDate),
            pharmacyName: state.pharmacy,
            lastFillDateAt: toISOString(startDate),
            isSupplement: state.isSupplement
          };
        } else {
          medication = {
            ndc: state.ndc,
            name: state.name,
            instructions: state.instructions,
            strength: s,
            dosage: d,
            period: p,
            routeOfAdministration: r,
            timeOfDay: t,
            prescriberName: state.prescriber,
            isEffective: state.isEffective,
            source: state.source,
            isTakenAsPrescribed: state.isTakenAsPrescribed,
            isNoLongerTaking: state.isNoLongerTaking,
            isTakingDifferently: state.isTakingDifferently,
            isUnableToVerify: state.isUnableToVerify,
            isTakenAsDirected: state.isTakenAsDirected,
            quantity: Number(state.quantity),
            numRefills: parseInt(state.refills),
            prescriptionWrittenDateAt: toISOString(prescriptionWrittenDate),
            soldDateAt: toISOString(soldDate),
            pharmacyName: state.pharmacy,
            lastFillDateAt: toISOString(startDate),
            isSupplement: state.isSupplement
          };
        }

        saveMedication({
          variables: {
            ...medication,
            truentityId: id
          }
        });
      } catch (error) {
        console.error(error);
        const failMessage: string = editMode ? 'Failed to Update Medication' : 'Failed to Create Medication';
        enqueueSnackbar(failMessage, {
          variant: 'error'
        });
      }
    },
    [state, startDate, prescriptionWrittenDate, soldDate]
  );

  const renderAutocompleteInput = useCallback(
    props => {
      return (
        <TruentityTextField
          label="Medication Name"
          {...props}
          sx={{ position: 'relative', width: '400px' }}
          placeholder="Medication Name"
        />
      );
    },
    [medication, sourceType, state.name, editMedName]
  );

  const renderAutocompleteItem = (item, isHighlighted) => (
    <div
      key={item.name}
      style={{
        background: isHighlighted ? 'lightgray' : 'white'
      }}
    >
      {item.name}
    </div>
  );

  const onFrequencyChanged = val => {
    const [value, unit] = val.split(':');

    if (value > 1 && unit === 'daily') {
      dispatch({
        type: 'time-of-day',
        payload: 'multiple'
      });
    }

    dispatch({
      type: 'period-value',
      payload: value
    });

    dispatch({
      type: 'period-unit',
      payload: unit
    });

    setSelectedFrequency(val);
  };

  const onModifiedFrequencyChanged = val => {
    const [value, unit] = val.split(':');

    if (value > 1 && unit === 'daily') {
      dispatch({
        type: 'modified-time-of-day',
        payload: 'multiple'
      });
    }

    dispatch({
      type: 'modified-period-value',
      payload: value
    });

    dispatch({
      type: 'modified-period-unit',
      payload: unit
    });

    setSelectedModifiedFrequency(val);
  };

  const updateStrength = ({ value, unit }: { value?: string; unit?: string }) => {
    if (value) {
      dispatch({
        type: 'strength-value',
        payload: value
      });
    }

    if (unit) {
      dispatch({
        type: 'strength-unit',
        payload: unit
      });
    }
  };

  const updateDosage = ({ value, unit }: { value?: string; unit?: string }) => {
    if (value) {
      dispatch({
        type: 'dosage-value',
        payload: value
      });
    }

    if (unit) {
      dispatch({
        type: 'dosage-unit',
        payload: unit
      });
    }
  };

  const onStrengthOptionSelected = val => {
    setSelectedStrength(val);
    const [ndc, strength, unit] = val.split(':');
    updateStrength({ value: strength, unit });
    dispatch({ type: 'ndc', payload: ndc });
  };

  const onDosageUnitSelected = unit => {
    updateDosage({ unit });
    updateStrengthOptions(unit);
  };

  const confirmDelete = () => {
    navigate(`/patients/${id}/details/medications/delete/${medicationId}`, {
      state: {
        returnUrl: `/patients/${id}/details`
      }
    });
  };

  const verifyMedication = async (val: boolean) => {
    const { status } = await changeVerificationStatusAccountMedications([{ id: medicationId, isVerified: val }]);

    if (status === 'Success') {
      const message = val ? 'Medication Verified' : 'Medication Unverified';

      enqueueSnackbar(message, {
        variant: 'success'
      });

      reloadMeds();
    } else {
      enqueueSnackbar('Unable to verify medication, please contact support', {
        variant: 'error'
      });
    }
  };

  return (
    <BaseDialog title={title} hideDialog={hideDialog} maxWidth={'xl'} fullWidth {...props}>
      <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
        <form onSubmit={handleOnSubmit}>
          <Stack sx={{ marginTop: '10px' }}>
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
              <Stack>
                <Autocomplete
                  renderInput={renderAutocompleteInput}
                  menuStyle={{
                    borderRadius: '3px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: 'white',
                    padding: '5px ',
                    position: 'absolute',
                    top: 64,
                    left: 0,
                    height: 'auto',
                    zIndex: 200
                  }}
                  wrapperStyle={{ display: 'block' }}
                  getItemValue={item => item.name}
                  items={medications}
                  renderItem={renderAutocompleteItem}
                  value={searchTerm}
                  onChange={onChangeSearchTerm}
                  onSelect={handleAutoCompleteMedicationSelected}
                />

                {editMode && imported && importedBy?.length > 0 && (
                  <ImportedByContent>
                    <b>Imported As: </b>
                    {importedBy}
                  </ImportedByContent>
                )}

                <InputRow>
                  <FormControl sx={{ height: '40px', justifyContent: 'center' }} variant="outlined" margin="dense">
                    <FormControlLabel
                      sx={{
                        justifyContent: 'space-between',
                        marginLeft: '5px',
                        marginRight: '5px'
                      }}
                      labelPlacement={'start'}
                      label="Is a Supplement?"
                      control={
                        <Switch
                          checked={state.isSupplement}
                          onChange={input => {
                            dispatch({
                              type: 'is-supplement',
                              payload: !state.isSupplement
                            });
                          }}
                        />
                      }
                    />
                  </FormControl>
                </InputRow>
              </Stack>

              <Stack direction="row" spacing={2}>
                {editMode && (
                  <Button
                    disableElevation
                    variant={'contained'}
                    startIcon={<FactCheckIcon />}
                    color={verified ? 'error' : 'success'}
                    onClick={() => verifyMedication(!verified)}
                  >
                    {verified ? 'Unverify' : 'Verify'}
                  </Button>
                )}
                <ButtonGroup>
                  {editMode && (
                    <Button
                      disabled={isProviderAdmin}
                      disableElevation
                      variant={'outlined'}
                      startIcon={<DeleteIcon />}
                      onClick={() => confirmDelete()}
                    >
                      Delete
                    </Button>
                  )}

                  <Button disableElevation type="submit" startIcon={<SaveIcon />} variant={'contained'}>
                    Save
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <MuiAccordion
              options={[
                {
                  label: 'Prescribed',
                  content: (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputRow>
                          <ItemRow>
                            <SelectList
                              id="dosageUnit"
                              label="Form"
                              options={formOptions}
                              placeholder="Select an option..."
                              value={state.dosage.unit}
                              onChange={event => onDosageUnitSelected(event.target.value)}
                              required
                            />
                          </ItemRow>

                          <ItemRow>
                            <TruentityTextField
                              label="Dose"
                              type="number"
                              min={0}
                              step={0.1}
                              value={state.dosage.value}
                              onChange={input => updateDosage({ value: input.target.value })}
                              required
                            />
                          </ItemRow>
                        </InputRow>

                        {strengthOptions?.length > 0 && (
                          <InputRow>
                            <SelectList
                              label="Strength"
                              options={strengthOptions}
                              placeholder="Select an option..."
                              value={selectedStrength}
                              onChange={event => onStrengthOptionSelected(event.target.value)}
                              required
                            />
                          </InputRow>
                        )}

                        {strengthOptions?.length === 0 && (
                          <InputRow>
                            <ItemRow>
                              <TruentityTextField
                                label="Strength"
                                type="number"
                                min={0}
                                step={0.1}
                                value={state.strength.value}
                                defaultValue=""
                                onChange={input => updateStrength({ value: input.target.value })}
                                required
                              />
                            </ItemRow>
                            <ItemRow>
                              <SelectList
                                id="strengthUnit"
                                label="Unit"
                                options={STRENGTH_UNIT_OPTIONS}
                                placeholder="Select an option..."
                                value={state.strength.unit}
                                defaultValue=""
                                onChange={event => updateStrength({ unit: event.target.value })}
                                required
                              />
                            </ItemRow>
                          </InputRow>
                        )}

                        <InputRow>
                          <SelectList
                            label="Frequency"
                            options={frequencyOptions}
                            placeholder="Select an option..."
                            value={selectedFrequency}
                            onChange={event => onFrequencyChanged(event.target.value)}
                            required
                          />
                        </InputRow>

                        <InputRow>
                          <SelectList
                            id="timeOfDay"
                            label="Time of Day"
                            options={TIME_OF_DAY_OPTIONS}
                            placeholder="Select an option..."
                            value={state.timeOfDay.value}
                            onChange={event => {
                              dispatch({
                                type: 'time-of-day',
                                payload: event.target.value
                              });
                            }}
                          />
                        </InputRow>
                        <InputRow>
                          <TruentityTextField
                            label="SIG"
                            type="text"
                            maxLength={255}
                            value={state.instructions}
                            onChange={input => {
                              dispatch({
                                type: 'instructions',
                                payload: input.target.value
                              });
                            }}
                          />
                        </InputRow>
                        <InputRow>
                          <TruentityDatePicker
                            label="Date Filled"
                            value={startDate}
                            format="MMM DD, YYYY"
                            onChange={date => setStartDate(date as Date)}
                          />
                        </InputRow>
                        <InputRow>
                          <TruentityDatePicker
                            label="Date Written"
                            format="MMM DD, YYYY"
                            value={prescriptionWrittenDate}
                            onChange={date => setPrescriptionWrittenDate(date as Date)}
                          />
                        </InputRow>
                      </Grid>
                      <Grid item xs={6}>
                        <InputRow>
                          <TruentityTextField
                            label="Prescriber"
                            type="text"
                            maxLength={255}
                            value={state.prescriber}
                            onChange={input => {
                              dispatch({ type: 'prescriber', payload: input.target.value });
                            }}
                          />
                        </InputRow>

                        <InputRow>
                          <TruentityTextField
                            label="Pharmacy"
                            type="text"
                            maxLength={255}
                            value={state.pharmacy}
                            onChange={input => {
                              dispatch({ type: 'pharmacy', payload: input.target.value });
                            }}
                          />
                        </InputRow>

                        <InputRow>
                          <TruentityTextField
                            label="Refills"
                            type="number"
                            min={0}
                            value={state.refills}
                            onChange={event =>
                              dispatch({
                                type: 'refills',
                                payload: event.target.value.toString()
                              })
                            }
                          />
                        </InputRow>
                        <InputRow>
                          <TruentityTextField
                            label="Quantity"
                            type="number"
                            value={parseFloat(state.quantity).toFixed(2)}
                            defaultValue={parseFloat(state.quantity).toFixed(2)}
                            onChange={event =>
                              dispatch({
                                type: 'quantity',
                                payload: parseFloat(event.target.value).toFixed(2)
                              })
                            }
                          />
                        </InputRow>
                        <InputRow>
                          <SelectList
                            id="routeOfAdministrationValue"
                            label="Route of Administration"
                            options={ROUTE_OF_ADMINISTRATION_OPTIONS}
                            placeholder="Select an option..."
                            value={state.routeOfAdministration.value}
                            onChange={event => {
                              dispatch({
                                type: 'route-of-administration',
                                payload: event.target.value
                              });
                            }}
                          />
                        </InputRow>
                        <InputRow>
                          <TruentityDatePicker
                            label="Date Sold"
                            value={soldDate}
                            format="MMM DD, YYYY"
                            onChange={date => setSoldDate(date as Date)}
                          />
                        </InputRow>
                        <InputRow>
                          <FormControl sx={{ height: '40px', justifyContent: 'center' }} variant="outlined" margin="dense">
                            <FormControlLabel
                              sx={{
                                justifyContent: 'space-between',
                                marginLeft: '5px',
                                marginRight: '5px'
                              }}
                              labelPlacement={'start'}
                              label="Does patient find medication effective?"
                              control={
                                <Switch
                                  checked={state.isEffective}
                                  onChange={input => {
                                    dispatch({
                                      type: 'is-effective',
                                      payload: !state.isEffective
                                    });
                                  }}
                                />
                              }
                            />
                          </FormControl>
                        </InputRow>
                      </Grid>
                    </Grid>
                  ),
                  defaultExpand: true
                }
              ]}
            />
          </Stack>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default NewMedicationDialog;
