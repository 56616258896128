import { useEffect, useState } from 'react';

import DropdownMenu from '@/components/DropdownMenu';
import type { Medication, MedicationVerificationStatus } from '@/types/medication';
import { COLUMN_TITLES } from '@/types/triage-medication-types';
import { parseIsCurrentFromColumnTitle } from '@/util/triage';
import type { MenuItemData } from 'mui-nested-menu';
import { AdherenceStatuses } from '../../../components/AdherenceSection';
import { AdherenceKeysToPropMap } from './triageMedicationList';

type Props = {
  selectedMedications: Medication[];
  updateMedications: any;
  changeMedsIsCurrent: any;
  verifyMedications: any;
};

const TriageActions = ({ selectedMedications, updateMedications, changeMedsIsCurrent, verifyMedications }: Props) => {
  const [menuData, setMenuData] = useState<MenuItemData>({ label: 'Actions', items: [] });

  const setAdherenceStatus = (status: string) => {
    const key = AdherenceKeysToPropMap[status];

    const modifiedMeds = selectedMedications.map((medication: Medication) => {
      const modifiedMed = { ...medication };
      modifiedMed.isTakingDifferently = modifiedMed.isTakenAsDirected = modifiedMed.isNoLongerTaking = modifiedMed.isUnableToVerify = false;
      modifiedMed[key] = true;

      return modifiedMed;
    });

    updateMedications(modifiedMeds);
  };

  const isSupplement = (val: boolean) => {
    const modifiedMeds = selectedMedications.map((medication: Medication) => {
      const modifiedMed = { ...medication };
      modifiedMed.isSupplement = val;

      return modifiedMed;
    });

    updateMedications(modifiedMeds);
  };

  const verifyMedication = async (val: boolean) => {
    const statuses: MedicationVerificationStatus[] = selectedMedications.map(
      (medication: Medication) =>
        ({
          id: medication.id,
          isVerified: val
        } as MedicationVerificationStatus)
    );
    verifyMedications(statuses);
  };

  const medicationStatusActions: MenuItemData[] = Object.values(COLUMN_TITLES).map(title => {
    const action: MenuItemData = {
      label: `${title}`,
      callback: () => changeMedsIsCurrent(parseIsCurrentFromColumnTitle(title))
    };

    return action;
  });

  const adherenceActions: MenuItemData[] = Object.entries(AdherenceStatuses).map((data: string[]) => {
    const id = data[0];
    const label = `${data[1]}`;

    const action: MenuItemData = {
      label,
      callback: () => setAdherenceStatus(id)
    };

    return action;
  });

  const supplementActions: MenuItemData[] = ['Yes', 'No'].map(title => {
    const action: MenuItemData = {
      label: `${title}`,
      callback: () => isSupplement(title === 'Yes')
    };

    return action;
  });

  const verifyMedicationActions: MenuItemData[] = ['Verify', 'Unverify'].map(title => {
    const action: MenuItemData = {
      label: `${title}`,
      callback: () => verifyMedication(title === 'Verify')
    };

    return action;
  });

  useEffect(() => {
    const menuItems = [
      {
        label: 'Medication Status',
        items: medicationStatusActions
      },
      {
        label: 'Adherence',
        items: adherenceActions
      },
      {
        label: 'Supplement',
        items: supplementActions
      }
    ];

    //only show this option if all selected meds are current
    if (selectedMedications.every(m => m.isCurrent)) {
      menuItems.push({
        label: 'Verify',
        items: verifyMedicationActions
      });
    }

    setMenuData({
      label: 'Actions',
      items: menuItems
    });
  }, [selectedMedications]);

  return (
    <DropdownMenu
      menuItemsData={menuData}
      MenuProps={{
        elevation: 3,
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' }
      }}
      ButtonProps={{ variant: 'outlined', color: 'primary', sx: { minWidth: '200px' } }}
    />
  );
};

export default TriageActions;
