import MuiTabs from '@/components/MuiTabs';
import SelectList, { TextSelectOption } from '@/components/SelectList';
import { H1, H5 } from '@/components/Typography';
import type { GetGlobalAccountByIdResponse } from '@/graphql/account';
import { GET_GLOBAL_ACCOUNT_BY_ID } from '@/graphql/account';
import type { PioneerRxObjectsResponseType } from '@/graphql/imports';
import { GET_PIONEERRX_OBJECTS } from '@/graphql/imports';
import { ClientOrganizationType, ClientStoreType } from '@/types/graphql';
import type { TabDataType } from '@/types/pioneerRx';
import { mapJsonData, renderFormattedValue } from '@/util/pioneerRx';
import { useLazyQuery, useQuery } from '@apollo/client';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Box, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PioneerRxObjectTabSection from './Components/PioneerRxObjectTabSection';

type SelectedValuesType = {
  clientOrg: string;
  clientStore: string | null;
};

const PioneerObjects = () => {
  const { id } = useParams();

  const [headerData, setHeaderData] = useState({
    lastImportedOn: ''
  });
  const [tabData, setTabData] = useState<TabDataType>({
    reStructuredPatient: [],
    reStructuredPrescribe: [],
    reStructuredPharmacy: []
  });
  const [patientClientOrgs, setPatientClientOrgs] = useState<ClientOrganizationType[]>([]);
  const [patientClientStores, setPatientClientStores] = useState<ClientStoreType[]>([]);
  const [patientAllClientStores, setPatientAllClientStores] = useState<ClientStoreType[]>([]);
  const [selectedValues, setSelectedValues] = useState<SelectedValuesType>({ clientOrg: '', clientStore: '' });

  const { data: patientData, loading: patientDataIsLoading } = useQuery<GetGlobalAccountByIdResponse>(GET_GLOBAL_ACCOUNT_BY_ID, {
    variables: {
      truentityId: id
    },
    fetchPolicy: 'cache-and-network'
  });
  const [getPioneerRxObject, { data: pioneerRxObjectsData, loading: pioneerRxObjectsLoading }] = useLazyQuery<PioneerRxObjectsResponseType>(
    GET_PIONEERRX_OBJECTS,
    { fetchPolicy: 'cache-and-network' }
  );

  const filterPatientClientStoresFromClientOrg = (clientOrg: ClientOrganizationType, patientClientStores: ClientStoreType[]) => {
    return patientClientStores.filter(store => clientOrg?.clientStores?.some(clientOrgStore => clientOrgStore.id === store.id));
  };

  const handleClientOrgSelect = useCallback(
    (clientOrgId: string) => {
      const selectedClientOrg = patientClientOrgs.find(clientOrg => clientOrg.id == clientOrgId);
      let clientStores = (selectedClientOrg && filterPatientClientStoresFromClientOrg(selectedClientOrg, patientAllClientStores)) || [];
      const anyItem: ClientStoreType = { id: 'Any', name: 'Any', createdAt: '', updatedAt: '' };
      clientStores.unshift(anyItem);

      setPatientClientStores(clientStores);
      setSelectedValues({
        clientOrg: selectedClientOrg?.id || '',
        clientStore: clientStores?.[0]?.id || null
      });
    },
    [patientClientOrgs, patientAllClientStores, setSelectedValues, setPatientClientStores]
  );

  const handleClientStoreSelect = useCallback(
    (clientStoreId: string) => {
      setSelectedValues({ ...selectedValues, clientStore: clientStoreId });
    },
    [selectedValues, setSelectedValues]
  );

  useEffect(() => {
    if (!pioneerRxObjectsLoading && pioneerRxObjectsData && pioneerRxObjectsData.getPioneerrxObjectsByAccountId) {
      const convertedPioneerRxHeaderObject = JSON.parse(pioneerRxObjectsData.getPioneerrxObjectsByAccountId.objectHeader);
      const lastImportedOn = convertedPioneerRxHeaderObject?.SentOnUTC;

      setTabData(mapJsonData(pioneerRxObjectsData.getPioneerrxObjectsByAccountId));
      setHeaderData({ lastImportedOn: lastImportedOn });
    } else {
      setTabData({ reStructuredPatient: [], reStructuredPrescribe: [], reStructuredPharmacy: [] });
      setHeaderData({ lastImportedOn: '' });
    }
  }, [pioneerRxObjectsData, pioneerRxObjectsLoading]);

  useEffect(() => {
    if (patientData && !patientDataIsLoading && patientData.globalAccountById.clientOrgs) {
      const patientClientOrgs = patientData?.globalAccountById?.clientOrgs || [];
      const patientClientStores = patientData?.globalAccountById?.clientStores || [];
      const defaultClientOrg = patientClientOrgs?.[0];

      const anyItem: ClientStoreType =
        patientClientOrgs?.length > 0 ? { id: 'Any', name: 'Any', createdAt: '', updatedAt: '' } : ({} as ClientStoreType);

      const filteredClientStores = [anyItem, ...filterPatientClientStoresFromClientOrg(defaultClientOrg, patientClientStores)];

      const defaultClientStore = filteredClientStores?.[0];

      setPatientClientOrgs(patientClientOrgs ?? []);
      setPatientClientStores(filteredClientStores ?? []);
      setPatientAllClientStores(patientClientStores ?? []);

      setSelectedValues({ clientOrg: defaultClientOrg?.id || '', clientStore: defaultClientStore?.id || null });
    } else {
      setPatientClientStores([]);
      setPatientClientOrgs([]);
      setSelectedValues({ clientOrg: '', clientStore: null });
    }
  }, [patientData, patientDataIsLoading]);

  useEffect(() => {
    if (selectedValues.clientOrg || selectedValues.clientStore) {
      const clientStore =
        selectedValues.clientStore && selectedValues.clientStore.toLowerCase() != 'any' ? selectedValues.clientStore : null;

      getPioneerRxObject({
        variables: {
          truentityId: id,
          clientOrgId: selectedValues.clientOrg,
          clientStoreId: clientStore
        }
      });
    } else {
      console.error('Please select client organization');
    }
  }, [id, selectedValues]);

  return (
    <>
      <Stack direction="row" mb={2} justifyContent="space-between">
        <Box>
          <H1 textAlign="left">Data Imported from PioneerRx</H1>
          {headerData.lastImportedOn && <H5>Last imported on: {renderFormattedValue(headerData.lastImportedOn)}</H5>}
        </Box>
        <Stack direction="row" spacing={2}>
          <SelectList
            formControlProps={{ sx: { minWidth: '200px' } }}
            label="Organization"
            options={patientClientOrgs?.map(item => {
              return {
                label: item.name,
                value: item.id
              } as TextSelectOption;
            })}
            placeholder="Select an option..."
            value={selectedValues.clientOrg}
            onChange={event => {
              handleClientOrgSelect(event.target.value as string);
            }}
            fullWidth={false}
            disabled={patientClientOrgs.length === 0}
          />
          <SelectList
            formControlProps={{ sx: { minWidth: '200px' } }}
            label="Location"
            options={patientClientStores?.map(item => {
              return {
                label: item.name,
                value: item.id
              } as TextSelectOption;
            })}
            placeholder="Select an option..."
            value={selectedValues.clientStore ?? ''}
            onChange={event => {
              handleClientStoreSelect(event.target.value as string);
            }}
            fullWidth={false}
            disabled={patientClientStores.length === 0}
          />
        </Stack>
      </Stack>
      <MuiTabs
        aria-label="PioneerRx Objects"
        tabVariant="fullWidth"
        tabs={[
          {
            label: 'Patient',
            icon: <SupervisorAccountIcon />,
            sx: { textTransform: 'uppercase' },
            children: <PioneerRxObjectTabSection sectionData={tabData.reStructuredPatient} />
          },
          {
            label: 'Pharmacy',
            icon: <CorporateFareIcon />,
            sx: { textTransform: 'uppercase' },
            children: <PioneerRxObjectTabSection sectionData={tabData.reStructuredPharmacy} />
          },
          {
            label: 'Prescribers',
            icon: <PeopleAltIcon />,
            sx: { textTransform: 'uppercase' },
            children: <PioneerRxObjectTabSection sectionData={tabData.reStructuredPrescribe} />
          }
        ]}
        customPadding
      />
    </>
  );
};

export default PioneerObjects;
