import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { NoPatientRecordDialog } from '@/components/Dialogs';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { H2 } from '@/components/Typography';
import type { GetAccountsByFilterResponse } from '@/graphql/account';
import { GET_ACCOUNT_BY_FILTER } from '@/graphql/account';
import useToken from '@/hooks/useToken';
import { color } from '@/styles/assets/colors';
import { PatientsListSearchFilters } from '@/types/patients-list-filters';
import type { HandlerMap } from '@/util/error';
import { handleGraphQLError } from '@/util/error';
import { formatDate } from '@/util/format';
import { addIfExists } from '@/util/object';
import { useLazyQuery } from '@apollo/client';
import { Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { PatientsData } from '../Patients/patients';
import DischargePatientDetailsForm from './DischargePatientDetailsForm';

type FormValues = {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  nextCallOnDate?: Date | string | null;
  organization?: string | null;
  orgStore?: string | null;
};

type LookupFilterOptions = FormValues;

const defaultValues: FormValues = {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  nextCallOnDate: new Date(),
  organization: null,
  orgStore: null
};

const DischargePatient = () => {
  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues: defaultValues });
  const [patientType] = useState<string>(PatientsListSearchFilters.Lookup);
  const [filteredPatients, setFilteredPatients] = useState<PatientsData[]>([]);
  const [lookupFilter, setLookupFilter] = useState<LookupFilterOptions | null>(null);
  const [getInfoByLookup, { data: dataLookup, error: errorLookup }] = useLazyQuery<GetAccountsByFilterResponse>(GET_ACCOUNT_BY_FILTER);
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { handleLogout } = useToken();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = (values: FormValues) => {
    values.dateOfBirth = values.dateOfBirth ? formatDate(values.dateOfBirth, 'YYYY-MM-DD') : undefined;

    const filterOptionsObj: LookupFilterOptions = {
      firstName: addIfExists(values.firstName),
      lastName: addIfExists(values.lastName),
      dateOfBirth: addIfExists(values.dateOfBirth)
    };

    if (patientType === PatientsListSearchFilters.ToCallNext) {
      const formattedDate = moment(values.nextCallOnDate).format('YYYY-MM-DD');
      filterOptionsObj.nextCallOnDate = addIfExists(formattedDate);
    }

    setLookupFilter(filterOptionsObj);
  };

  const showNoPatientRecordDialog = useCallback(() => {
    const modal = showModal(NoPatientRecordDialog, {
      title: '',
      hideDialog: () => {
        modal.hide();
      }
    });
    reset(defaultValues);
  }, [reset, showModal]);

  useEffect(() => {
    if (lookupFilter) {
      if (
        patientType === PatientsListSearchFilters.Lookup &&
        [lookupFilter.firstName, lookupFilter.lastName, lookupFilter.dateOfBirth?.toString()].every(entry => !entry || entry.length === 0)
      ) {
        enqueueSnackbar('One of the First Name, Last Name and Date of Birth is required', {
          variant: 'error'
        });

        reset();
        setFilteredPatients([]);
        return;
      }

      getInfoByLookup({
        variables: {
          filterOptions: lookupFilter,
          pageNum: 0,
          pageSize: DEFAULT_PAGE_SIZE
        }
      }).catch(err => {
        console.error(err);
        enqueueSnackbar('Lookup failed', { variant: 'error' });
      });
    }
  }, [enqueueSnackbar, getInfoByLookup, lookupFilter, patientType, reset]);

  useEffect(() => {
    if (dataLookup) {
      const data = dataLookup.accountsLookup?.accounts;
      if (data.length === 0) {
        showNoPatientRecordDialog();
      }
      setFilteredPatients(data);
    }
  }, [dataLookup, showNoPatientRecordDialog]);

  useEffect(() => {
    if (errorLookup) {
      const handlerMap: HandlerMap = {
        AUTHORIZATION_FAILED: err => {
          console.error(err);
          handleLogout();
          navigate('/login');
          enqueueSnackbar('Request not allowed, logging out', { variant: 'error' });
        },
        all: err => {
          console.error(err);
          enqueueSnackbar('An error has occured', { variant: 'error' });
        }
      };

      console.log('Error getting Patient Accounts. If auth error, user will be logged out.');
      console.error(errorLookup);
      handleGraphQLError(errorLookup, handlerMap);
    }
  }, [enqueueSnackbar, errorLookup, handleLogout]);

  return (
    <Stack spacing={2}>
      <H2 textAlign="center" fontWeight={600}>
        Patient Discharge
      </H2>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        justifyContent="space-between"
        alignItems="center"
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField sx={{ width: '33%' }} autoFocus onChange={onChange} value={value} label={'First Name'} />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField sx={{ width: '33%' }} onChange={onChange} value={value} label={'Last Name'} />
          )}
        />
        <Controller
          control={control}
          defaultValue={null}
          name="dateOfBirth"
          render={({ field: { onChange, value } }) => (
            <TruentityDatePicker sx={{ width: '33%' }} onChange={onChange} value={value} label={'Date of Birth'} />
          )}
        />

        <Grid container spacing={1} pb={1} pt={1}>
          <Grid item>
            <Typography variant="subtitle2" color={color.truentityCyan[600]} fontSize={'small'} component="div">
              Lookup Patients by First Name, Last Name or Date of Birth, or their combinations. You may enter first few letters for first
              and last names (in either lowercase or uppercase).
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ mt: '0.5rem' }}>
            <Stack direction={'row'}>
              <Button label={'Lookup'} type="submit" size="small" sx={{ marginRight: 1 }} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <DischargePatientDetailsForm patientDetails={filteredPatients} />
    </Stack>
  );
};

export default DischargePatient;
