import { Popover, Stack } from '@mui/material';
import { LogText, Small } from './Typography';

type Props = {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  popoverData: PopoverDataType;
};

export type PopoverDataType = {
  name: string;
  type: string;
  subType?: string;
  notes?: string;
  relyingPartyAdmin: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
};

const CalendarPopover = ({ open, onClose, anchorEl, popoverData, ...props }: Props) => {
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        zIndex: 10000,
        pointerEvents: 'none'
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={onClose}
      disableRestoreFocus
      {...props}
    >
      <Stack p={1} rowGap={1} sx={{ maxWidth: '300px' }}>
        {popoverData?.notes && popoverData?.notes?.trim().length > 0 && (
          <Stack>
            <LogText fontWeight="normal" fontSize="10px">
              Note:
            </LogText>
            <Small style={{ color: 'black' }}>{popoverData?.notes}</Small>
          </Stack>
        )}
      </Stack>
    </Popover>
  );
};

export default CalendarPopover;
