import { withUploadLogic } from '@/hof/withUploadLogic';
import { stringAvatar } from '@/util/avatar';
import type { AvatarProps } from '@mui/material';
import { Avatar } from '@mui/material';

type Props = AvatarProps & {
  avatarUrl?: string;
  name?: string;
};
export const ProfileAvatar = ({ avatarUrl, name, ...props }: Props) => {
  return <Avatar {...(avatarUrl ? { src: avatarUrl } : stringAvatar(name ?? ''))} {...props} />;
};
export const ProfileAvatarWithUpload = withUploadLogic(ProfileAvatar);
