import MenuItemNavLink from '@/components/MenuItemNavLink';
import TruentityMenu from '@/components/TruentityMenu';
import { Body1 } from '@/components/Typography';
import type { MainMenuItem, MenuSectionType } from '@/zustand/AppFrameStore';
import { MainMenuKeys, useAppFrameStore } from '@/zustand/AppFrameStore';
import { Divider, IconButton, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

const VISIBLE_SECONDARY_MENU_ITEMS = new Map<string, boolean>([
  [MainMenuKeys.RecentPatients, true],
  [MainMenuKeys.Patients, true],
  [MainMenuKeys.MedicationManagement, true],
  [MainMenuKeys.RemotePatientMonitoring, true],
  [MainMenuKeys.UserAdmin, true],
  [MainMenuKeys.Administration, true],
  [MainMenuKeys.SystemAdmin, true],
  [MainMenuKeys.TimesheetsInvoicesAdmin, true],
  [MainMenuKeys.QuickLinksAdmin, true]
]);

type MenuSectionProps = {
  title: ReactNode;
  options: MainMenuItem[];
  onClick: () => void;
};

const MenuSection = ({ title, options, onClick }: MenuSectionProps) => {
  return (
    <Stack sx={{ overflow: 'hidden', width: '300px' }} flexWrap={'wrap'} spacing={1} mr={4} mb={2}>
      <Body1 color={'primary'} fontWeight={'bold'} fontSize={'1.1em'}>
        {title}
      </Body1>
      <Divider />
      {options.map((item, index) => {
        return (
          <MenuItemNavLink
            MenuItemProps={{
              sx: {
                borderRadius: 6
              }
            }}
            to={item.navigatePath}
            title={item.label}
            icon={item.icon}
            key={index}
            onClick={onClick}
          />
        );
      })}
    </Stack>
  );
};

type Props = {
  icon: ReactNode;
  disabled?: boolean;
};
const SecondaryMenu = ({ icon, disabled }: Props) => {
  const { mainMenuItems } = useAppFrameStore();
  const [visibleMainMenuItems, setVisibleMainMenuItems] = useState<MenuSectionType[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (mainMenuItems) {
      const secondaryMenuItems = new Map<string, MenuSectionType>();
      let adminMenuItems: MainMenuItem[] = [];

      mainMenuItems.forEach((item, key) => {
        if (VISIBLE_SECONDARY_MENU_ITEMS.get(key) && !item.hidden && item.options.length > 0) {
          // Check for 'Admin' in the key
          if (key.includes('Admin')) {
            adminMenuItems = adminMenuItems.concat(item.options);
          } else {
            secondaryMenuItems.set(key, item);
          }
        }
      });

      const adminMenuItem: MenuSectionType = {
        title: 'Administration',
        hidden: false, // or true, if you want to initially hide this section
        options: adminMenuItems
      };

      if (adminMenuItems.length > 0) {
        secondaryMenuItems.set('Administration', adminMenuItem);
      }
      setVisibleMainMenuItems(Array.from(secondaryMenuItems.values()));
    }
  }, [mainMenuItems]);

  return (
    <>
      <IconButton disabled={disabled} onClick={handleClick}>
        {icon}
      </IconButton>

      <TruentityMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Stack direction={'row'} flexWrap={'wrap'}>
          {visibleMainMenuItems.map((item, index) => (
            <MenuSection key={index} title={item.title} options={item.options} onClick={handleClose} />
          ))}
        </Stack>
      </TruentityMenu>
    </>
  );
};

export default SecondaryMenu;
