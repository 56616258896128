import type { AlertColor, AlertProps } from '@mui/material';
import { Alert as MuiAlert, AlertTitle } from '@mui/material';
import type { ReactNode } from 'react';

export type Props = AlertProps & {
  description?: ReactNode;
  title?: ReactNode;
  status: AlertColor;
  action?: ReactNode;
};

const AlertMap = new Map([
  ['neutral', 'info'],
  ['theme', 'info'],
  ['danger', 'error']
]);

const Alert = ({ description, title, status, action, ...props }: Props) => {
  status = status || AlertMap.get(status) || 'info';

  return (
    <MuiAlert severity={status} action={action} {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {description}
    </MuiAlert>
  );
};

export default Alert;
