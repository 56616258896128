import { CopilotMessagingSubscriptionResponse, COPILOT_MESSAGING_SUBSCRIPTION } from '@/graphql/coPilot';
import { SenderType } from '@/types/coPilot';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { useCopilotMessageStore } from '@/zustand/CoPilotMessageStore';
import { useReactiveVar, useSubscription } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const CopilotMessageSubscriptionHandler = () => {
  const { id } = useParams();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const addMessage = useCopilotMessageStore(state => state.addMessage);
  const clearMessages = useCopilotMessageStore(state => state.clearMessages);

  useEffect(() => {
    clearMessages();
  }, [id]);

  useSubscription<CopilotMessagingSubscriptionResponse>(COPILOT_MESSAGING_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    onError: error => {
      console.error('Subscription error:', error);
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const data = subscriptionData?.data?.copilotMessaging?.copilot;

      if (data) {
        const { truentityId, relyingPartyId, relyingPartyAdminId, type, message } = data;

        if (
          id === truentityId &&
          currentUser?.relyingParty?.id === relyingPartyId &&
          currentUser?.id === relyingPartyAdminId &&
          type === SenderType.BOT
        ) {
          addMessage({ sender: SenderType.BOT, text: message });
        } else {
          console.warn('Message does not meet conditions for display.');
        }
      } else {
        console.warn('No data or message received in subscription.');
      }
    }
  });

  return null;
};

export default CopilotMessageSubscriptionHandler;
