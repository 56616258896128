import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { ManageProvidersDialog } from '@/components/Dialogs';
import { GridItem } from '@/components/GridItem';
import type { AccordionOption } from '@/components/MuiAccordion';
import MuiAccordion from '@/components/MuiAccordion';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H1 } from '@/components/Typography';
import type { AccountProviderType } from '@/graphql/remotePatientMonitoring';
import { UPDATE_ACCOUNTS_PROVIDER_PRIMARY_CARE } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import type { ProviderFieldsType } from '@/zustand/ProviderStore';
import { useProviderStore } from '@/zustand/ProviderStore';
import { useMutation } from '@apollo/client';
import FaxIcon from '@mui/icons-material/Fax';
import MailIcon from '@mui/icons-material/Mail';
import NumbersIcon from '@mui/icons-material/Numbers';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import { Drawer, Grid, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ProviderAccordionInfo = ({
  id,
  firstName,
  lastName,
  npiNumber,
  email,
  phone,
  fax,
  isPrimary,
  onUpdateAccountProvidersSuccess,
  assignedProviders
}: ProviderFieldsType) => {
  const { id: truentityId } = useParams();
  const [updateAccountProviderPrimaryCare] = useMutation(UPDATE_ACCOUNTS_PROVIDER_PRIMARY_CARE);
  const [providerValue, setProviderValue] = useState<string>('');

  useEffect(() => {
    if (providerValue) {
      updateAccountProviderPrimaryCare({
        variables: {
          truentityId: truentityId,
          providerId: providerValue
        }
      }).then(result => onUpdateAccountProvidersSuccess(result.data?.updateProviderPrimaryCare?.status === 'Success'));
    }
  }, [providerValue]);

  useEffect(() => {
    const primaryCareProvider = assignedProviders?.find(provider => provider.isPrimaryCare);
    if (primaryCareProvider) {
      const primaryCareFirstName = primaryCareProvider.provider.id;
      setProviderValue(primaryCareFirstName);
    }
  }, [assignedProviders]);

  return (
    <Stack sx={{ justifyContent: 'space-between' }}>
      <Stack direction={'row'} justifyContent={'end'}>
        <Button
          color={isPrimary ? 'warning' : 'primary'}
          label={isPrimary ? 'Unassign PCP' : 'Assign PCP'}
          variant={'outlined'}
          size={'small'}
          onClick={() => (isPrimary ? setProviderValue(' ') : setProviderValue(id))}
        />
      </Stack>
      <Grid container rowSpacing={2}>
        <GridItem xs={12} icon={<PersonIcon fontSize={'small'} />} label="Name" value={firstName + ' ' + lastName} />
        <GridItem xs={12} icon={<NumbersIcon fontSize={'small'} />} label="NPI Number" value={npiNumber} />
        <GridItem xs={12} icon={<MailIcon fontSize={'small'} />} label="Email" value={email} />
        <GridItem
          xs={12}
          icon={<PhoneIcon fontSize={'small'} />}
          label="Phone Number"
          value={<TruentityPhoneNumber value={phone} label={''} />}
        />
        <GridItem xs={12} icon={<FaxIcon fontSize={'small'} />} label="Fax" value={<TruentityPhoneNumber value={fax} label={''} />} />
      </Grid>
    </Stack>
  );
};

const formatProviderData = (
  data: AccountProviderType[],
  onUpdateAccountProvidersSuccess: (success: boolean) => void
): AccordionOption[] => {
  return data?.map(item => {
    let emailVal = '-----';
    let telVal = '-----';
    let faxVal = '-----';
    item.provider?.contacts?.map(c => {
      if (c.type === 'Email') {
        emailVal = c.value;
      } else if (c.type === 'Telephone') {
        telVal = c.value;
      } else if (c.type === 'Fax') {
        faxVal = c.value;
      }
    });
    return {
      label: item.provider.individualFirstName + ' ' + item.provider.individualLastName,
      chipLabel: item.isPrimaryCare ? 'Primary Care' : undefined,
      chipProps: {
        color: item.isPrimaryCare ? 'success' : 'default',
        variant: 'filled'
      },
      content: (
        <ProviderAccordionInfo
          id={item.provider.id}
          firstName={item.provider.individualFirstName}
          lastName={item.provider.individualLastName}
          npiNumber={item.provider.npiNumber}
          email={emailVal}
          phone={telVal}
          fax={faxVal}
          isPrimary={item.isPrimaryCare}
          assignedProviders={data}
          onUpdateAccountProvidersSuccess={onUpdateAccountProvidersSuccess}
        />
      ),
      defaultExpand: true
    };
  });
};

type Props = {
  fullName: string;
  assignedProviders: AccountProviderType[];
  onUpdateAccountProvidersSuccess: (success: boolean) => void;
};
const AssignedProvidersDrawer = ({ fullName, assignedProviders, onUpdateAccountProvidersSuccess }: Props) => {
  const { showModal } = useModal();
  const { showAssignedProviders, setShowAssignedProviders } = useProviderStore();

  const showManageProviderDialog = () => {
    const modal = showModal(ManageProvidersDialog, {
      assignedProviders: assignedProviders,
      fullName: fullName,
      title: '',
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  return (
    <Drawer
      container={this}
      anchor={'right'}
      open={showAssignedProviders}
      sx={{ zIndex: theme.zIndex.drawer + 1, width: '150px' }}
      color="inherit"
      onClose={() => setShowAssignedProviders(false)}
    >
      <Stack spacing={2} sx={{ height: '100%' }}>
        <Stack spacing={2} p={2}>
          <H1 style={{ fontSize: '16px', fontWeight: 500, lineHeight: '40px', color: color.grey900 }}>
            Providers assigned for {fullName}{' '}
          </H1>
          <Stack direction={'row'} justifyContent={'flex-end'}>
            <Button sx={{ width: '180px', height: '40px' }} label={'ASSIGN PROVIDERS'} onClick={() => showManageProviderDialog()} />
          </Stack>
        </Stack>

        <Stack spacing={1} p={2} sx={{ background: theme.palette.background.default, width: '100%', height: '100%' }}>
          {!assignedProviders?.find(v => v.isPrimaryCare) && (
            <Alert status={'warning'} description={'It is recommended that one provider is a PCP (Primary Care Provider)'} />
          )}
          <MuiAccordion options={formatProviderData(assignedProviders, onUpdateAccountProvidersSuccess)} />
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default AssignedProvidersDrawer;
