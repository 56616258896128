import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const SystemSection = () => {
  const { setContent } = useContext(SideMenuContext);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, []);

  return <Outlet />;
};

export default SystemSection;
