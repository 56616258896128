import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import Pagination from '@/components/Pagination';
import { Stack } from '@mui/material';
import CommentsTable from './ImportActivityTable';

import { GET_ACTIVITY_LOGS_USER } from '@/graphql/activityLog';

type Props = {
  patientId?: string | null;
  medicationId?: string | null;
  collapsible?: boolean;
};

export const ACTIVITIES_PER_PAGE = 5;
const ImportActivity = ({ patientId, medicationId, collapsible = true }: Props) => {
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [
    getActivityLogsForUser,
    {
      loading: activityLogsForUserLoading,
      data: activityLogsForUserData,
      called: activityLogsForUserCalled,
      refetch: refetchActivityLogsForUserData
    }
  ] = useLazyQuery(GET_ACTIVITY_LOGS_USER);

  useEffect(() => {
    getActivityLogsForUser({
      variables: {
        truentityId: patientId,
        pageNum: currentPage,
        pageSize: ACTIVITIES_PER_PAGE,
        activityType: 'Meds::ESI::Imported::Direct'
      }
    });
  }, [patientId]);

  useEffect(() => {
    if (activityLogsForUserCalled && !activityLogsForUserLoading && activityLogsForUserData) {
      const temporaryLogs = [];
      activityLogsForUserData.activityLogs.activityLogs.forEach(log => {
        const parsedData = JSON.parse(log.data);

        const objCopy = { ...log };
        objCopy.transactionId = parsedData.transaction_id;

        if (parsedData.status) {
          objCopy.status = 'Success';
        } else {
          objCopy.status = 'Failure';
        }

        temporaryLogs.push(objCopy);
      });

      setLogs(temporaryLogs);
    }
  }, [activityLogsForUserLoading, activityLogsForUserData, activityLogsForUserCalled]);

  useEffect(() => {
    if (patientId) {
      getActivityLogsForUser({
        variables: {
          truentityId: patientId,
          pageNum: currentPage,
          pageSize: ACTIVITIES_PER_PAGE,
          activityType: 'Meds::ESI::Imported::Direct'
        }
      });
    }
  }, [currentPage]);

  const updateData = data => {
    setCurrentPage(data.currentPage);
  };

  let totalCount = 0;
  let totalPages = 0;
  if (activityLogsForUserData && activityLogsForUserData.activityLogs) {
    totalCount = activityLogsForUserData.activityLogs.meta.totalCount;
    totalPages = activityLogsForUserData.activityLogs.meta.totalPages;
  }

  return (
    <Stack alignItems={'start'} spacing={4}>
      <CommentsTable rows={logs} />

      <Pagination
        currentPage={currentPage}
        totalRecords={totalCount}
        pageLimit={totalPages}
        pageNeighbours={2}
        onPageChanged={updateData}
      />
    </Stack>
  );
};

export default ImportActivity;
