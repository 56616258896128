import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import { SAVE_TIMESHEET } from '@/graphql/timesheet';
import type { SaveTimesheetData, TimesheetData } from '@/routes/Administration/Timesheets/Timesheets';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import { formatDateIgnoreTZ } from '@/util/format';
import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, DialogActions, DialogContent, FormControl, FormControlLabel, FormGroup, Grid, Stack } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import SelectList from '../SelectList';
import TruentityDatePicker from '../TruentityDatePicker';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  selectedTimesheet?: TimesheetData | null;
};

const defaultValues: SaveTimesheetData = {
  datePerformed: new Date().toString(),
  numberOfHours: 0,
  numberOfMinutes: 0,
  description: '',
  clientOrganizationId: undefined,
  clientStoreId: undefined
};

const TimesheetAddDialog = ({ title, hideDialog, selectedTimesheet, ...props }: Props) => {
  const [saveTimesheet] = useMutation(SAVE_TIMESHEET);
  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<SaveTimesheetData>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled,
    refetch: organizationDataRefetch
  } = useQuery(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const selectedOrgIdWatch = watch('clientOrganizationId');

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);

  const [billedToOrg, setBilledToOrg] = useState(true);

  useEffect(() => {
    if (selectedTimesheet) {
      const clientOrganizationId = selectedTimesheet.clientOrganization?.id || '';
      reset({ ...selectedTimesheet, clientOrganizationId });
    }
  }, [selectedTimesheet]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations(organizationData.clientOrganizations.clientOrganizations);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setFilteredClientStores([]);
    setValue('clientStoreId', '');

    const org = clientOrganizations?.find(o => o.id === selectedOrgIdWatch);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores(org.clientStores);
    }

    if (selectedTimesheet) {
      const clientStoreId = selectedTimesheet.clientStore?.id || '';
      setValue('clientStoreId', clientStoreId);
    }
  }, [selectedTimesheet, selectedOrgIdWatch]);

  const onSubmit: SubmitHandler<SaveTimesheetData> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: SaveTimesheetData) => {
    try {
      const result: any = await saveTimesheet({
        variables: {
          timesheetData: {
            datePerformed: formatDateIgnoreTZ(values.datePerformed, 'YYYY-MM-DD'),
            description: values.description,
            numberOfHours: Number(values.numberOfHours),
            numberOfMinutes: Number(values.numberOfMinutes),
            clientOrganizationId: values.clientOrganizationId,
            clientStoreId: values.clientStoreId,
            status: values.status
          },
          timesheetId: selectedTimesheet?.id || undefined
        }
      });

      enqueueSnackbar('Timesheet Saved', {
        variant: 'success'
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to save Timesheet', {
        variant: 'error'
      });
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container spacing={4} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Stack>
              <Controller
                control={control}
                name="datePerformed"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker
                    maxDate={moment(new Date())}
                    TextFieldProps={{ required: true }}
                    onChange={onChange}
                    value={value}
                    label={'Date Performed'}
                  />
                )}
              />

              <Stack direction="row" justifyContent={'space-between'}>
                <Controller
                  control={control}
                  name="numberOfHours"
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField
                      sx={{ width: '48%' }}
                      min="0"
                      type="number"
                      autoFocus={true}
                      required
                      onChange={onChange}
                      value={value}
                      label={'Hours'}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="numberOfMinutes"
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField
                      sx={{ width: '48%' }}
                      min="0"
                      max="59"
                      type="number"
                      required
                      onChange={onChange}
                      value={value}
                      label={'Minutes'}
                    />
                  )}
                />
              </Stack>

              <Controller
                control={control}
                name="description"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField multiline rows={4} required onChange={onChange} value={value} label={'Description'} />
                )}
              />

              <FormGroup>
                <FormControlLabel
                  control={<Checkbox onClick={() => setBilledToOrg(!billedToOrg)} checked={billedToOrg} />}
                  label="Task billed to Organization/Location"
                />
              </FormGroup>

              {billedToOrg && (
                <>
                  <Stack direction="row" justifyContent={'space-between'}>
                    <Controller
                      defaultValue={''}
                      control={control}
                      name="clientOrganizationId"
                      render={({ field: { onChange, value } }) => (
                        <FormControl sx={{ width: '48%' }} variant="outlined" margin="dense" size="medium">
                          <SelectList
                            required={billedToOrg}
                            clearFunction={() => setValue('clientOrganizationId', '')}
                            label="Organization"
                            options={clientOrganizations.map((item: ClientOrganizationObject) => ({
                              value: item.id!,
                              label: item.name!
                            }))}
                            placeholder="Select an option..."
                            value={value || ''}
                            onChange={onChange}
                          />
                        </FormControl>
                      )}
                    />

                    <Controller
                      defaultValue={''}
                      control={control}
                      name="clientStoreId"
                      render={({ field: { onChange, value } }) => (
                        <FormControl sx={{ width: '48%' }} variant="outlined" margin="dense" size="medium">
                          <SelectList
                            clearFunction={() => setValue('clientStoreId', '')}
                            disabled={filteredClientStores.length === 0}
                            label="Store"
                            options={filteredClientStores.map((item: ClientStoreObject) => ({
                              value: item.id!,
                              label: item.name!
                            }))}
                            placeholder="Select an option..."
                            value={value || ''}
                            onChange={onChange}
                          />
                        </FormControl>
                      )}
                    />
                  </Stack>
                </>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button type="submit" a11yLabel="Save" appearance="primary" />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default TimesheetAddDialog;
