import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import { H4 } from '@/components/Typography';
import ScheduledReportsList from '@/routes/Administration/Components/ScheduledReportsList';
import type { ScheduledReportTypeEnum } from '@/types/administration';
import { Stack } from '@mui/material';
import type React from 'react';

type ScheduledReportsListDialogProps = BaseDialogProps & {
  hideDialog: () => void;
  reportType: ScheduledReportTypeEnum;
};

export const ScheduledReportsListDialog: React.FC<ScheduledReportsListDialogProps> = ({ hideDialog, reportType, title, ...props }) => {
  return (
    <BaseDialog canNavigateBack={false} hideDialog={hideDialog} {...props} title={''} maxWidth={'lg'} fullWidth={true}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          spacing: 1,
          marginTop: 1,
          padding: 1
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <H4>{title}</H4>
        </Stack>
        <ScheduledReportsList reportType={reportType} />
      </Stack>
    </BaseDialog>
  );
};

export default ScheduledReportsListDialog;
