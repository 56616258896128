import { GridItem } from '@/components/GridItem';
import MuiTabs from '@/components/MuiTabs';
import { Body1, H1 } from '@/components/Typography';
import { color } from '@/styles/assets/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailIcon from '@mui/icons-material/Mail';
import NumbersIcon from '@mui/icons-material/Numbers';
import { Collapse, Divider, Fab, Grid, IconButton, Paper, Stack } from '@mui/material';
import { useState } from 'react';
import { UsersData } from '.';

type Props = {
  userInfo: UsersData | undefined;
  goToEditProfile: () => void;
};

const UserInfoTabs = ({ userInfo, goToEditProfile }: Props) => {
  const [collapsePatientInfo, setCollapsePatientInfo] = useState(true);
  const fullName =
    userInfo?.user?.lastName && userInfo?.user?.firstName ? `${userInfo?.user?.lastName}, ${userInfo?.user?.firstName}` : '---';

  return (
    <Paper component={Stack} p={4} spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems={'center'} spacing={2}>
          <H1>{fullName} </H1>
        </Stack>

        <Stack
          sx={{ cursor: 'pointer' }}
          onClick={() => setCollapsePatientInfo(!collapsePatientInfo)}
          spacing={1}
          direction="row"
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Body1 fontWeight={'600'} color={color.truentityBlue[500]}>
            {userInfo?.roleType?.toUpperCase()}
          </Body1>
          <IconButton>
            <ExpandMoreIcon sx={{ transform: collapsePatientInfo ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          </IconButton>
        </Stack>
      </Stack>

      {!collapsePatientInfo && <Divider sx={{ marginTop: '10px' }} />}

      <Collapse in={!collapsePatientInfo} timeout="auto">
        <MuiTabs
          tabs={[
            {
              label: 'Profile',
              children: (
                <Stack spacing={4}>
                  <Grid container rowSpacing={4}>
                    <GridItem xs={6} icon={<MailIcon fontSize={'small'} />} label="Email" value={userInfo?.user?.email} />
                    <GridItem xs icon={<AccountCircleIcon fontSize={'small'} />} label="Role" value={userInfo?.roleType} />
                    <GridItem xs={6} icon={<NumbersIcon fontSize={'small'} />} label="NPI Number" value={userInfo?.npiNumber} />
                  </Grid>

                  <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'}>
                    <Fab color="primary" aria-label="preferences" onClick={goToEditProfile}>
                      <EditIcon />
                    </Fab>
                  </Stack>
                </Stack>
              )
            }
          ]}
        />
      </Collapse>
    </Paper>
  );
};

export default UserInfoTabs;
