import { MedicationDeleteDialog, NewMedicationDialog } from '@/components/Dialogs';
import PatientDetailContext from '@/context/patientDetailContext';
import type { Medication } from '@/types/medication';
import { SourceType } from '@/types/medication';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const MedicationsRoute = () => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { medicationId, type } = useParams();
  const { medications, reloadMeds, goDetailsPage } = useContext(PatientDetailContext);

  const showMedicationModal = (medication: Medication = null, editMode: boolean = false, sourceType: any = SourceType.PROVIDER) => {
    const title = editMode ? `Edit Medication` : 'New Medication';
    const medicationModal = showModal(NewMedicationDialog, {
      title,
      id,
      medication,
      editMode,
      sourceType,
      hideDialog: () => {
        medicationModal.hide();
      },
      reloadMeds: () => {
        medicationModal.hide();
        reloadMeds();
      }
    });
    return medicationModal;
  };

  const showDeleteModal = (medication: Medication) => {
    const deleteModal = showModal(MedicationDeleteDialog, {
      id,
      title: 'Confirmation',
      hideDialog: () => deleteModal.hide(),
      medicationId,
      medicationName: medication.displayName || '',
      reloadMeds: () => {
        deleteModal.hide();
        reloadMeds();
        goDetailsPage();
      }
    });
  };

  useEffect(() => {
    if (type.toLowerCase() === 'new') {
      showMedicationModal(null, false);
    }
  }, [type]);

  useEffect(() => {
    if (!type || type === 'new') {
      return;
    }

    if (medications.length > 0) {
      const typeLc = type.toLowerCase() || 'view';
      const med: Medication | undefined = medications.find(m => m.id === medicationId) as Medication;

      if (!med) {
        console.error('not found');
        return;
      }

      switch (typeLc) {
        case 'edit':
          showMedicationModal(med, true);
          break;

        case 'delete':
          showDeleteModal(med);
          break;
      }
    }
  }, [type, medications, medicationId]);

  return <></>;
};

export default MedicationsRoute;
