import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { UPDATE_PROVIDER } from '@/graphql/remotePatientMonitoring';
import { PROVIDER_TITLE_OPTIONS } from '@/types/providers';
import type { ProviderType } from '@/types/remotePatientMonitoring';
import { useMutation } from '@apollo/client';
import { Autocomplete, DialogContent, Grid, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  existingProvider: ProviderType;
};

type FormValues = {
  id: string;
  firstName: string;
  lastName: string;
  npiNumber: string;
  title: string;
  email: string;
  phone: string;
  fax: string;
};

const ProviderUpdateDialog = ({ title, hideDialog, existingProvider, ...props }: Props) => {
  const { reset, control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      id: existingProvider.id,
      firstName: existingProvider.individualFirstName,
      lastName: existingProvider.individualLastName,
      npiNumber: existingProvider.npiNumber,
      title: existingProvider.title ?? '',
      email: existingProvider.email ?? '',
      phone: existingProvider.phone ?? '',
      fax: existingProvider.fax ?? ''
    }
  });
  const { enqueueSnackbar } = useSnackbar();

  const [updateProvider, { data: updateProviderData, loading: updateProviderLoading, error: updateProviderError }] =
    useMutation(UPDATE_PROVIDER);

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      await updateProvider({
        variables: {
          id: values.id,
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            npiNumber: values.npiNumber,
            title: values.title,
            email: values.email ?? '',
            phone: values.phone ?? '',
            fax: values.fax ?? ''
          }
        }
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to update provider', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (updateProviderData?.updateProvider?.status === 'Success') {
      enqueueSnackbar('Successfully Updated Provider', {
        variant: 'success'
      });
      if (hideDialog) {
        hideDialog();
      }
    }
  }, [updateProviderData]);

  useEffect(() => {
    if (updateProviderError) {
      enqueueSnackbar(updateProviderError?.message ?? 'Unable to update provider', {
        variant: 'error'
      });
    }
  }, [updateProviderError]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack component={'form'} spacing={1} marginTop={'10px'} onSubmit={handleSubmit(onSubmit)}>
          <Grid container width="100%" height="auto" spacing={1}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField autoFocus={true} required onChange={onChange} value={value} label={'First Name'} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField required onChange={onChange} value={value} label={'Last Name'} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="npiNumber"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField required onChange={onChange} value={value} label={'NPI Number'} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField type={'email'} onChange={onChange} value={value} label={'Email'} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <TruentityPhoneNumber editable={true} onChange={onChange} value={value} label={'Phone Number'} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="fax"
                render={({ field: { onChange, value } }) => (
                  <TruentityPhoneNumber editable={true} onChange={onChange} value={value} label={'Fax'} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name="title"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="title-options"
                    options={PROVIDER_TITLE_OPTIONS}
                    renderInput={params => <TextField {...params} label="Title" variant="outlined" />}
                    getOptionLabel={option => option}
                    value={value}
                    onChange={(_event, selectedOption) => {
                      onChange(selectedOption);
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} mt={2}>
            <Button type="submit" isLoading={updateProviderLoading} a11yLabel="Save" appearance="primary" />
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                if (hideDialog) {
                  hideDialog();
                }
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default ProviderUpdateDialog;
