import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import Button from '../Button';

export type StepperStep = {
  label: string;
  optional?: ReactNode;
  children: ReactNode;
  canSave?: () => boolean;
  canNext?: () => boolean;
  trySave?: () => void;
  tryNext?: () => void;
  tryBack?: () => void;
  customActionButtons?: ReactNode[];
};

type Props = {
  steps: StepperStep[];
  currentStep: number;
  onFormReset?: () => void;
  enableFinalize?: boolean;
  disableFinishedStep?: boolean;
};

export default function HorizontalStepper({ steps, currentStep, onFormReset, enableFinalize = true, disableFinishedStep = false }: Props) {
  const [activeStep, setActiveStep] = useState(currentStep);

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleNext = () => {
    const tryNext = steps[activeStep]?.tryNext ?? (() => {});
    tryNext();
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    const tryBack = steps[activeStep]?.tryBack ?? (() => {});
    tryBack();
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    if (onFormReset) {
      onFormReset();
    }
  };

  const renderNextButton = () => {
    if (activeStep < steps.length - 1 && steps[activeStep].canNext) {
      return (
        <Button color="primary" disabled={!steps[activeStep].canNext()} onClick={handleNext}>
          Next
        </Button>
      );
    } else if (!enableFinalize && steps[activeStep].canNext) {
      return (
        <Button color="primary" disabled={!steps[activeStep].canNext()} onClick={handleNext}>
          Skip
        </Button>
      );
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (currentStep !== activeStep) {
      setActiveStep(currentStep);
    }
  }, [currentStep]);

  return (
    <Stack sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(step => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel optional={step.optional}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          {!disableFinishedStep ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Stack spacing={1} p={5} direction="column-reverse">
          <Stack direction="row" spacing={1}>
            {activeStep > 0 && (
              <Button color="primary" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {steps[activeStep].customActionButtons?.map((button, i) => <React.Fragment key={i}>{button}</React.Fragment>)}
            {steps[activeStep].trySave && (
              <Button
                color="success"
                disabled={!steps[activeStep].canSave?.()}
                onClick={() => {
                  if (steps[activeStep].trySave) steps[activeStep].trySave();
                }}
              >
                {enableFinalize && isLastStep() ? 'Finalize' : 'Save'}
              </Button>
            )}
            {renderNextButton()}
          </Stack>
          <Divider />
          {steps[activeStep].children}
        </Stack>
      )}
    </Stack>
  );
}
