import Button from '@/components/Button';
import JsonEditor, { JSONData } from '@/components/JsonKit/JsonEditor';
import MuiTabs from '@/components/MuiTabs';
import { UpdateRelyingPartyResponse, UPDATE_RELYING_PARTY } from '@/graphql/account';
import { RelyingParty } from '@/types/graphql';
import { isJSON } from '@/util/json';
import { useMutation } from '@apollo/client';
import { Box, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export type SettingSectionProps = {
  companyData: RelyingParty;
};

type FormValues = {
  tenoviClientName: string;
  tenoviApiKey: string;
  tenoviSecondaryApiKey: string;
  textJson: string;
};

const defaultValues: FormValues = {
  tenoviClientName: '',
  tenoviApiKey: '',
  tenoviSecondaryApiKey: '',
  textJson: ''
};

const SettingsSection = ({ companyData }: SettingSectionProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const initialCompanySettingJson = useRef<JSONData | null>(null);
  const [companySettingJson, setCompanySettingJson] = useState<JSONData | null>(null);

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, setValue, watch, reset } = methods;

  const [updateRelyingParty, { loading: updateRelyingPartyLoading }] = useMutation<UpdateRelyingPartyResponse>(UPDATE_RELYING_PARTY);

  const textJson = watch('textJson');
  const tenoviClientName = watch('tenoviClientName');
  const tenoviApiKey = watch('tenoviApiKey');
  const tenoviSecondaryApiKey = watch('tenoviSecondaryApiKey');

  const setJsonValueToFields = (jsonData: JSONData) => {
    if (!jsonData) return;

    jsonData?.['Tenovi']?.['clientName'] && setValue('tenoviApiKey', jsonData['Tenovi']['apiKey']);
    jsonData?.['Tenovi']?.['apiKey'] && setValue('tenoviClientName', jsonData['Tenovi']['clientName']);
    jsonData?.['Tenovi']?.['secondaryApiKey'] && setValue('tenoviSecondaryApiKey', jsonData['Tenovi']['secondaryApiKey']);
  };

  const handleJsonUpdate = (changedValues: Record<string, any>) => {
    const { updated_src } = changedValues;
    const updated_value = JSON.stringify(updated_src);
    if (updated_src && isJSON(updated_value)) {
      const jsonParsedSetting = JSON.parse(updated_value);
      setCompanySettingJson(jsonParsedSetting);
      setValue('textJson', updated_value);
      setJsonValueToFields(jsonParsedSetting);
    }
  };

  const isValueChanged = useMemo(
    () => JSON.stringify(initialCompanySettingJson.current) === JSON.stringify(companySettingJson),
    [initialCompanySettingJson, companySettingJson]
  );

  const handelReset = useCallback(() => {
    const orgSettings = initialCompanySettingJson.current;
    setCompanySettingJson(orgSettings);
    const stringJson = JSON.stringify(orgSettings, null, 2);
    reset({
      textJson: stringJson,
      tenoviApiKey: orgSettings?.['Tenovi']?.['apiKey'] || '',
      tenoviClientName: orgSettings?.['Tenovi']?.['clientName'] || '',
      tenoviSecondaryApiKey: orgSettings?.['Tenovi']?.['secondaryApiKey'] || ''
    });
  }, [initialCompanySettingJson, setCompanySettingJson]);

  const handelSaveJson = useCallback(async () => {
    try {
      const res = await updateRelyingParty({
        variables: {
          relyingPartyId: companyData?.id,
          settings: JSON.stringify(companySettingJson)
        }
      });

      if (res?.data?.updateRelyingParty?.status === 'Success') {
        const updatedSettings = res?.data?.updateRelyingParty?.relyingParty?.settings;
        if (updatedSettings && updatedSettings.trim().length > 0) {
          setCompanySettingJson(JSON.parse(updatedSettings));
        }
        enqueueSnackbar('Company settings are updated successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Could not update company settings', { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Could not update company settings', { variant: 'error' });
    }
  }, [companyData, companySettingJson, enqueueSnackbar, updateRelyingParty]);

  useEffect(() => {
    if (companyData?.settings) {
      const jsonSettings = JSON.parse(companyData?.settings);
      setValue('textJson', JSON.stringify(jsonSettings, null, 2));
      setCompanySettingJson(jsonSettings);
      initialCompanySettingJson.current = jsonSettings;
      setJsonValueToFields(jsonSettings);
    } else {
      setValue('textJson', '');
      setCompanySettingJson(JSON.parse('{}'));
      initialCompanySettingJson.current = JSON.parse('{}');
    }
  }, [companyData]);

  useEffect(() => {
    if (companySettingJson) {
      const updatedJson = {
        ...companySettingJson,
        Tenovi: {
          ...(tenoviClientName?.trim()?.length > 0 && { clientName: tenoviClientName }),
          ...(tenoviApiKey?.trim()?.length > 0 && { apiKey: tenoviApiKey }),
          ...(tenoviSecondaryApiKey?.trim()?.length > 0 && { secondaryApiKey: tenoviSecondaryApiKey })
        }
      };
      setValue('textJson', JSON.stringify(updatedJson, null, 2));
      setCompanySettingJson(updatedJson);
    }
  }, [tenoviClientName, tenoviApiKey, tenoviSecondaryApiKey]);

  useEffect(() => {
    try {
      const validJson = JSON.parse(textJson);
      setCompanySettingJson(validJson);
    } catch (error) {
      console.error(error);
    }
  }, [textJson]);

  return (
    <Box>
      <MuiTabs
        tabVariant="standard"
        tabs={[
          {
            label: 'Form',
            children: (
              <>
                <Box py={1}>
                  <Controller
                    control={control}
                    name="tenoviClientName"
                    render={({ field: { onChange, value } }) => (
                      <TextField label="Tenovi Client Name" fullWidth variant="outlined" value={value} onChange={onChange} />
                    )}
                  />
                </Box>
                <Box py={1}>
                  <Controller
                    control={control}
                    name="tenoviApiKey"
                    render={({ field: { onChange, value } }) => (
                      <TextField label="Tenovi API Key" fullWidth variant="outlined" value={value} onChange={onChange} />
                    )}
                  />
                </Box>
                <Box py={1}>
                  <Controller
                    control={control}
                    name="tenoviSecondaryApiKey"
                    render={({ field: { onChange, value } }) => (
                      <TextField label="Tenovi Secondary API Key" fullWidth variant="outlined" value={value} onChange={onChange} />
                    )}
                  />
                </Box>
              </>
            )
          },
          {
            label: 'Text',
            children: (
              <Controller
                control={control}
                name="textJson"
                render={({ field: { onChange, value } }) => (
                  <TextField multiline minRows={5} fullWidth variant="outlined" value={value} onChange={onChange} />
                )}
              />
            )
          },
          {
            label: 'JSON',
            children: (
              <>
                {companySettingJson && (
                  <JsonEditor src={companySettingJson} onAdd={handleJsonUpdate} onDelete={handleJsonUpdate} onEdit={handleJsonUpdate} />
                )}
              </>
            )
          }
        ]}
        customPadding
      />
      <Grid container justifyContent="flex-end" spacing={1} paddingTop={2}>
        <Grid item>
          <Button type="button" variant="outlined" label={'Reset'} size="large" disabled={isValueChanged} onClick={handelReset} />
        </Grid>
        <Grid item>
          <Button
            type="button"
            variant="contained"
            label={'Save'}
            size="large"
            isLoading={updateRelyingPartyLoading}
            disabled={isValueChanged}
            onClick={handelSaveJson}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsSection;
