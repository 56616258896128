import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';
import type { ApexOptions } from 'apexcharts';
import ReactECharts from 'echarts-for-react';
import type { ReactNode } from 'react';
import Chart from 'react-apexcharts';
import { H3 } from '../Typography';

type BaseChartProps = StackProps & {
  title?: ReactNode;
  children: ReactNode;
  additionalCss?: any;
};

type PieChartProps = {
  options?: Partial<ApexOptions>;
  width?: string | number;
  height?: string | number;
  series?: ApexOptions['series'];
  colors?: string[];
} & Partial<BaseChartProps>;

export const chartColors = {
  blue: '#3498DB',
  green: '#2ECC71',
  yellow: '#F1C40F',
  orange: '#E67E22',
  red: '#E74C3C',
  purple: '#9B59B6',
  lightGreen: '#1ABC9C',
  grey: '#95A5A6'
};

export const BaseChart = ({ title, children, additionalCss, ...props }: BaseChartProps) => {
  return (
    <Stack
      sx={{
        padding: '15px',
        marginBottom: '10px',
        borderRadius: additionalCss?.borderRadius || undefined,
        width: additionalCss?.width ? additionalCss.width : null,
        backgroundColor: additionalCss?.backgroundColor ? additionalCss.backgroundColor : '#ffffff'
      }}
      {...props}
    >
      {title && <H3 textAlign={'center'}>{title}</H3>}
      {children}
    </Stack>
  );
};

export const PieChart = ({ options, series, width, colors = [], ...props }: PieChartProps) => {
  options = {
    ...options,
    dataLabels: {
      enabled: false
    },
    theme: {
      monochrome: {
        enabled: false
      }
    },
    colors:
      (colors?.length ?? 0) > 0
        ? colors
        : [
            chartColors.blue,
            chartColors.green,
            chartColors.yellow,
            chartColors.orange,
            chartColors.red,
            chartColors.purple,
            chartColors.lightGreen,
            chartColors.grey
          ]
  };

  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} type="pie" width={width} />
    </BaseChart>
  );
};

export const BarChart = ({ options, series, width, height, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} type="bar" width={width} height={height} />
    </BaseChart>
  );
};

export const LineChart = ({ options, series, width, height, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart style={{ minWidth: '-webkit-fill-available' }} options={options} series={series} type="line" width={width} height={height} />
    </BaseChart>
  );
};

export const DonutChart = ({ options, series, labels, width, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} labels={labels} type="donut" width={width} />
    </BaseChart>
  );
};

export const SparkLine = ({ title, subTitle, series, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart
        type="area"
        options={{
          chart: {
            type: 'area',
            height: 160,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'straight'
          },
          yaxis: {
            min: 0,
            labels: {
              show: false
            }
          },
          title: {
            text: title,
            offsetX: 0,
            style: {
              fontSize: '24px'
            }
          },
          subtitle: {
            text: subTitle,
            offsetX: 0,
            style: {
              fontSize: '14px'
            }
          }
        }}
        series={series}
      />
    </BaseChart>
  );
};

export const EChart = ({ option, ...props }) => {
  return (
    <ReactECharts
      option={option}
      style={{ height: '420px', width: '550px' }}
      notMerge={true}
      lazyUpdate={true}
      opts={{ renderer: 'svg' }}
    />
  );
};
