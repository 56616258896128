import type React from 'react';
import { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import type { ReadingsSummaryType } from '@/graphql/remotePatientMonitoring';
import MarkerLabel from '@/components/MarkerLabel';
import { color } from '@/styles/assets/colors';
import { H5 } from '@/components/Typography';

type VitalsReadingsSummaryProps = {
  readingsSummaryValues: ReadingsSummaryType;
};
export const VitalsReadingsSummary: React.FC<VitalsReadingsSummaryProps> = ({ readingsSummaryValues }) => {
  const readingsSummaryTitlesMap = useMemo(() => {
    return {
      uniqueDaysCount: {
        title: 'Unique Days',
        color: color.primaryDark
      },
      criticallyHigh: {
        title: 'Critically High',
        color: color.red900
      },
      high: {
        title: 'High',
        color: color.errorMain
      },
      normal: {
        title: 'Normal',
        color: color.green800
      },
      low: {
        title: 'Low',
        color: color.warningDark
      },
      criticallyLow: {
        title: 'Critically Low',
        color: color.amber800
      }
    };
  }, []);

  return (
    <Stack display="flex" justifyContent="center" direction="row" rowGap={1} spacing={1} maxWidth={'100%'} flexWrap="wrap">
      {Object.keys(readingsSummaryValues).map(key => (
        <Stack
          key={key}
          display="inline-flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            backgroundColor: 'white',
            borderRadius: 2,
            padding: 1,
            width: 'auto'
          }}
        >
          <MarkerLabel fontSize={'small'} label={readingsSummaryTitlesMap[key].title} color={readingsSummaryTitlesMap[key].color} />
          <Box textAlign={'center'}>
            <H5
              sx={{
                fontSize: 'small'
              }}
            >{`: ${readingsSummaryValues[key]}`}</H5>
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

export default VitalsReadingsSummary;
