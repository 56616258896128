import Button from '@/components/Button';
import { DialogActions, DialogContent } from '@mui/material';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

export type CommentDialogValues = {
  comment: string;
};

const defaultValues: CommentDialogValues = {
  comment: ''
};

type UILabels = {
  commentInput: string;
  submitButton: string;
  cancelButton: string;
};

type Props = BaseDialogProps & {
  title: string;
  uiLabels: UILabels;
  commentRequired: boolean;
  onSubmitted: (values: CommentDialogValues) => void;
  onCancelled: () => void;
};

function CreateCommentDialog({ title, onSubmitted, onCancelled, uiLabels, commentRequired, hideDialog, ...props }: Props) {
  const { register, handleSubmit } = useForm<CommentDialogValues>({ defaultValues });
  const onSubmit: SubmitHandler<CommentDialogValues> = data => onSubmitted(data);

  return (
    <BaseDialog {...props} canNavigateBack={false} title={title} customTitleHeader hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TruentityTextField
            {...register('comment')}
            fullWidth
            variant="outlined"
            margin="dense"
            multiline
            rows={4}
            inputProps={{ maxLength: 500 }}
            label={uiLabels.commentInput}
            placeholder={commentRequired ? '' : 'Optional'}
            required={commentRequired}
          />
          <DialogActions sx={{ justifyContent: 'flex-end' }}>
            <Button type="reset" label={uiLabels.cancelButton} appearance="outline" onClick={onCancelled} />
            <Button type="submit" label={uiLabels.submitButton} appearance="primary" />
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
}
export default CreateCommentDialog;
