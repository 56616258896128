import { gql } from '@apollo/client';

export const ON_RELYING_PARTY_ADMIN_ANNOUNCEMENTS = gql`
  subscription OnRelyingPartyAdminAnnouncements {
    relyingPartyAdminAnnouncements {
      primaryAnnouncement {
        id
        title
        content
        startTime
        endTime
        alwaysShow
        media {
          s3Key
          s3PresignedUrl
        }
        type
      }
      secondaryAnnouncement {
        id
        title
        content
        startTime
        endTime
        alwaysShow
        media {
          s3Key
          s3PresignedUrl
        }
        type
      }
    }
  }
`;

export const READ_ANNOUNCEMENT = gql`
  mutation ReadAnnouncements($announcementId: ID!) {
    readAnnouncement(announcementId: $announcementId) {
      message
      status
    }
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement(
    $title: String!
    $content: String!
    $startTime: ISO8601DateTime!
    $endTime: ISO8601DateTime!
    $alwaysShow: Boolean!
    $medias: [Upload!]
    $type: AnnouncementTypeEnum!
    $recipients: [AnnouncementRecipientsInput!]!
  ) {
    createAnnouncement(
      title: $title
      content: $content
      startTime: $startTime
      endTime: $endTime
      alwaysShow: $alwaysShow
      medias: $medias
      type: $type
      recipients: $recipients
    ) {
      status
      message
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement(
    $id: ID!
    $title: String!
    $content: String!
    $startTime: ISO8601DateTime!
    $endTime: ISO8601DateTime!
    $alwaysShow: Boolean!
    $isActive: Boolean
    $mediasToAdd: [Upload!]
    $mediaKeysToRemove: [String!]
    $type: AnnouncementTypeEnum!
    $recipients: [AnnouncementRecipientsInput!]!
  ) {
    updateAnnouncement(
      id: $id
      title: $title
      content: $content
      startTime: $startTime
      endTime: $endTime
      alwaysShow: $alwaysShow
      isActive: $isActive
      mediasToAdd: $mediasToAdd
      mediaKeysToRemove: $mediaKeysToRemove
      type: $type
      recipients: $recipients
    ) {
      status
      message
    }
  }
`;

export const GET_USER_ANNOUNCEMENT = gql`
  query GetRelyingPartyAdminsAnnouncements($relyingPartyAdminId: ID, $pageSize: Int!, $pageNum: Int!) {
    getRelyingPartyAdminsAnnouncements(relyingPartyAdminId: $relyingPartyAdminId, pageNum: $pageNum, pageSize: $pageSize) {
      announcements {
        id
        title
        content
        startTime
        endTime
        alwaysShow
        isActive
        media {
          s3Key
          s3PresignedUrl
        }
        type
        announcementRecipientsRelyingParty {
          id
        }
        currentUserRecipient {
          isRead
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
            }
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_ALL_ANNOUNCEMENTS = gql`
  query GetAllAnnouncements($pageSize: Int!, $pageNum: Int!) {
    getAllAnnouncements(pageNum: $pageNum, pageSize: $pageSize) {
      announcements {
        id
        title
        content
        startTime
        endTime
        alwaysShow
        isActive
        media {
          s3Key
          s3PresignedUrl
        }
        type
        announcementRecipientsRelyingParty {
          id
        }
        announcementRecipients {
          isRead
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
            }
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($announcementId: ID!) {
    deleteAnnouncement(announcementId: $announcementId) {
      message
      status
    }
  }
`;
