import TuneIcon from '@mui/icons-material/Tune';
import { useModal } from 'mui-modal-provider';
import { useEffect } from 'react';
import Button from './Button';
import AdtFilterDialog from './Dialogs/AdtFilterDialog';

const AdtFilters = ({
  formInputFields,
  onFilterAddClicked,
  onDeleteIconClicked,
  handleFormChange,
  handleDateChange,
  handleToDateChange,
  onFiltersApplied,
  relyingPartyFilters,
  relyingPartyAdminFilters,
  onTemplateSelected,
  onSaveFilterClicked,
  filtersModalId
}) => {
  const { showModal, updateModal, hideModal } = useModal();

  useEffect(() => {
    if (filtersModalId.current) {
      updateModal(filtersModalId.current, {
        formInputFields: formInputFields,
        onFilterAddClicked: onFilterAddClicked,
        onDeleteIconClicked: onDeleteIconClicked,
        handleFormChange: handleFormChange
      });
    }
  }, [formInputFields, onFilterAddClicked, onDeleteIconClicked, handleFormChange]);

  const showAdtFiltersDialog = () => {
    const modal = showModal(
      AdtFilterDialog,
      {
        title: 'Filters',
        hideDialog: () => {
          modal.hide();
        },
        formInputFields,
        onFilterAddClicked,
        onDeleteIconClicked,
        handleFormChange,
        handleDateChange,
        handleToDateChange,
        onFiltersApplied,
        relyingPartyFilters,
        relyingPartyAdminFilters,
        onTemplateSelected,
        onSaveFilterClicked
      },
      {
        rootId: 'startEventModal'
      }
    );
    filtersModalId.current = modal.id;
  };

  return <Button label="Filters" onClick={() => showAdtFiltersDialog()} endIcon={<TuneIcon />} />;
};

export default AdtFilters;
