import Button from '@/components/Button';
import { SaveFilterDialog } from '@/components/Dialogs';
import { Body1 } from '@/components/Typography';
import type { PatientFilter, PatientListToolbarFilters } from '@/zustand/patientFilterStore';
import { usePatientFilterStore } from '@/zustand/patientFilterStore';
import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';

type Props = StackProps & {
  activeFilter: PatientListToolbarFilters;
  onReset: () => void;
  onSave: (filter: PatientFilter) => void;
};
const SaveFilter = ({ activeFilter, onReset, onSave, ...props }: Props) => {
  const { showModal } = useModal();
  const { filters, setFilters, setSelectedFilter } = usePatientFilterStore();

  const showSaveFilterModal = () => {
    const modalRef = showModal(SaveFilterDialog, {
      title: 'Save Filter',
      activeFilter,
      onSave: filter => {
        setFilters([...filters, filter]);
        setSelectedFilter(filter);
        onSave(filter);
      },
      hideDialog: () => {
        modalRef.hide();
      }
    });
  };

  const onSaveBtnClick = () => {
    showSaveFilterModal();
  };
  const onClearBtnClick = () => {
    onReset();
  };

  return (
    <Stack
      sx={{ backgroundColor: '#FFFDE7', border: '1px solid lightgray', width: '100%', borderRadius: theme => theme.spacing(1) }}
      spacing={3}
      alignItems={'center'}
      direction={'row'}
      p={2}
    >
      <Body1>Would you like to save this search?</Body1>

      <Stack direction={'row'} spacing={1}>
        <Button label="Clear" variant={'text'} onClick={onClearBtnClick} />
        <Button label="Save" variant={'text'} onClick={onSaveBtnClick} />
      </Stack>
    </Stack>
  );
};

export default SaveFilter;
