import { Stack } from '@mui/material';
import { useContext } from 'react';

import LoadingOverlay from '@/components/LoadingOverlay';
import AdministrationContext from '@/context/administrationContext';
import OrganizationInfo from './organizationInfo';

const OrganizationDetails = () => {
  const { organization, reloadOrganization } = useContext(AdministrationContext);

  return (
    <Stack direction="column" spacing={4}>
      <LoadingOverlay active={reloadOrganization} text="Getting Details..." />
      <OrganizationInfo organizationInfo={organization} />
    </Stack>
  );
};

export default OrganizationDetails;
