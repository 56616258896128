import { useLazyQuery } from '@apollo/client';
import { faEdit, faTableList } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useMemo, useState } from 'react';

import Icon from '@/elements/Icon';
import Link from '@/elements/Link';

import Button from '@/components/Button';
import { Grid, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';

import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { OrganizationAddDialog } from '@/components/Dialogs';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import SideMenuContext from '@/context/sideMenuContext';
import { GET_CLIENT_ORGANIZATIONS } from '@/graphql/administration';
import { toDateOrNull } from '@/util/date';
import { formatDateIgnoreTZ } from '@/util/format';
import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';

type OrganizationData = {
  id: number;
  name: string;
};

const Organizations = () => {
  const [getOrganizations, { loading: organizationDataLoading, data: organizationData, called: organizationDataCalled, refetch }] =
    useLazyQuery(GET_CLIENT_ORGANIZATIONS, {
      fetchPolicy: 'cache-and-network'
    });

  const { showModal } = useModal();

  const [currentPage, setCurrentPage] = useState(0);
  const [organizations, setOrganizations] = useState<OrganizationData[]>([]);
  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);

  const { setContent } = useContext(SideMenuContext);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, []);

  const updateData = data => {
    setCurrentPage(data.page);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        valueGetter: params => params.row.name,
        flex: 1,
        align: 'left'
      },
      {
        field: 'clientStores',
        headerName: '# of Locations',
        sortable: true,
        valueGetter: params => params.row.clientStoresCount,
        flex: 1,
        align: 'left'
      },
      {
        field: 'patients',
        headerName: '# of Patients',
        sortable: true,
        valueGetter: params => params.row.accountsCount,
        flex: 1,
        align: 'left'
      },
      {
        field: 'createdAt',
        headerName: 'Created On',
        type: 'date',
        sortable: true,
        valueGetter: params => toDateOrNull(params.row.createdAt),
        flex: 1,
        align: 'left',
        renderCell: cellValues => {
          const formattedDate = formatDateIgnoreTZ(cellValues.row.createdAt, 'YYYY-MM-DD');
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {formattedDate}
            </Stack>
          );
        }
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        renderCell: cellValues => {
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <IconButton onClick={() => showOrgAddDialog(cellValues.id, cellValues.row, true)}>
                <Icon icon={faEdit} fixedWidth size="lg" />
              </IconButton>

              <Link to={`/administration/organizations/${cellValues.id}/stores`}>
                <Icon icon={faTableList} fixedWidth size="lg" />
              </Link>
            </Stack>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    getOrganizations({
      variables: {
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, [currentPage]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setOrganizations(organizationData.clientOrganizations.clientOrganizations);
      setRowCount(organizationData.clientOrganizations.meta.totalCount);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const showOrgAddDialog = (id, data, editMode) => {
    const modal = showModal(OrganizationAddDialog, {
      title: editMode ? 'Edit Organization' : 'Add Organization',
      hideDialog: () => {
        refetch();
        modal.hide();
      },
      id: id,
      data: data,
      editMode: editMode
    });
  };

  return (
    <Stack spacing={2}>
      <Grid container spacing={1} key={'search-bar'} alignItems="center">
        <Grid item xs={12}>
          <Stack spacing={1} alignItems={'center'} direction="row" justifyContent={'flex-end'}>
            <Button startIcon={<AddIcon />} label="Add Organization" onClick={() => showOrgAddDialog(null, null, false)} />
          </Stack>
        </Grid>
      </Grid>

      <Paper component={Stack} direction="column" spacing={2}>
        <div style={{ display: 'flex' }}>
          <TruentityDataGrid
            name={'dg-organizations'}
            autoHeight
            rows={organizations}
            rowCount={rowCountState}
            loading={organizationDataLoading}
            columns={columns}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={updateData}
            paginationMode="server"
            disableRowSelectionOnClick
          />
        </div>
      </Paper>
    </Stack>
  );
};

export default Organizations;
