import type { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select, Stack } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { H5 } from './Typography';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

type Props<T = unknown> = {
  title: string;
  items: ({ id: string } & T)[];
  selectedItems: string[];
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
  options: { id: string; value: string; label: string }[];
  generateSelectedItem: (item: T) => ReactNode;
};

export const CustomSelectList = <T,>({ title, items, selectedItems, setSelectedItems, options, generateSelectedItem }: Props<T>) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={2} pb={1}>
        <H5 fontWeight="bold">{title}</H5>
      </Stack>

      {/* TODO:  This should not be a dropdown, autocomplete which is filtered server side otherwise we risk not finding correct tasks when list becomes too big */}
      <Select
        labelId="tasks-multiple-select"
        id="tasks-multiple-chip"
        multiple
        disabled={items.length === 0}
        value={selectedItems}
        onChange={(event: SelectChangeEvent<string[]>) => {
          const {
            target: { value }
          } = event;
          const items = typeof value === 'string' ? value.split(',') : value;
          setSelectedItems([...items]);
        }}
        renderValue={selectedItemIds => (
          <Stack direction="row" spacing={0.5} flexWrap="wrap">
            {items.filter(item => selectedItemIds?.includes(item.id)).map(item => generateSelectedItem(item))}
          </Stack>
        )}
        MenuProps={MenuProps}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};
