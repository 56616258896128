import { gql, useMutation } from '@apollo/client';

import Button from '@/components/Button';
import { DEFAULT_TYPE_QUICK_UPLOAD_STATUS, TYPE_QUICK_UPLOAD_STATUS_OPTIONS } from '@/types/quickUploadStatus';
import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

const UPDATE_QUICK_UPLOAD = gql`
  mutation UpdateQuickUpload($quickUploadSessionId: String!, $status: String!) {
    updateQuickUpload(quickUploadSessionId: $quickUploadSessionId, status: $status) {
      status
    }
  }
`;

export type FormValues = {
  statusSelect: string;
};

const defaultValues: FormValues = {
  statusSelect: DEFAULT_TYPE_QUICK_UPLOAD_STATUS.value || ''
};

type Props = BaseDialogProps & {
  title: string;
  successMessage: string;
  quickUploadSessionId?: string;
  quickUploadSessionStatus?: string;
  onQuickUploadUpdated?: () => void;
  hideDialog: () => void;
};

const UpdateQuickUploadDialog = ({
  title,
  successMessage,
  quickUploadSessionId,
  quickUploadSessionStatus,
  onQuickUploadUpdated,
  hideDialog,
  ...props
}: Props) => {
  const [updateQuickUpload] = useMutation(UPDATE_QUICK_UPLOAD);
  const { register, handleSubmit } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitImpl = async (values: FormValues) => {
    let variables = {
      quickUploadSessionId: quickUploadSessionId,
      status: values.statusSelect
    };
    try {
      const result = await updateQuickUpload({
        variables: { ...variables }
      });
      const { status } = result.data!.updateQuickUpload;

      enqueueSnackbar(successMessage, { variant: 'success' });

      if (onQuickUploadUpdated) {
        onQuickUploadUpdated();
      }
    } catch (err) {
      console.log(err);
    }

    hideDialog();
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="status-select-label">Select a Status</InputLabel>
            <Select
              {...register('statusSelect')}
              labelId="status-select-label"
              label="Select a Status"
              defaultValue={quickUploadSessionStatus?.toLowerCase()}
              required
            >
              {TYPE_QUICK_UPLOAD_STATUS_OPTIONS.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DialogActions sx={{ justifyContent: 'flex-start' }}>
            <Button type="submit" a11yLabel="Update" appearance="outline" />
          </DialogActions>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default UpdateQuickUploadDialog;
