import Alert from '@/components/Alert';
import Button from '@/components/Button';
import LabelGroup from '@/components/LabelGroup';
import { H4 } from '@/components/Typography';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const SEND_MEDREC_SNAPSHOT = gql`
  mutation sendMedrecSnapshot($truentityId: String!, $healthdataSourceId: ID!, $medrecSnapshotId: String!, $description: String!) {
    sendMedrecSnapshot(
      truentityId: $truentityId
      healthdataSourceId: $healthdataSourceId
      medrecSnapshotId: $medrecSnapshotId
      description: $description
    ) {
      status
    }
  }
`;

const GET_ACCOUNT_HEALTHDATA_SOURCES = gql`
  query getHealthdataSources($truentityId: String!) {
    healthdataSources(truentityId: $truentityId) {
      healthdataSources {
        id
        name
        updatedAt
        sourceType
        externalId
        address
        logoUrl
      }
    }
  }
`;

export type FormValues = {
  healthdataSourceSelect: string;
  description: string;
};

type Props = BaseDialogProps & {
  title: string;
  successMessage: string;
  truentityId?: string;
  medRecSnapshotId?: string;
  medRecSnapshotName?: string;
  hideDialog: () => void;
};

const SendEHRDialog = ({ title, successMessage, truentityId, medRecSnapshotId, medRecSnapshotName, hideDialog, ...props }: Props) => {
  const [sendMedrecSnapshot] = useMutation(SEND_MEDREC_SNAPSHOT);
  const [accountHealthdataSources, setHealthdataSources] = useState<[]>([]);
  const { register, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [getHealthdataSources, { data, loading, called }] = useLazyQuery(GET_ACCOUNT_HEALTHDATA_SOURCES);

  const isDataLoaded = () => called && !loading && data && data.healthdataSources;
  const isThereData = () => isDataLoaded() && data.healthdataSources.healthdataSources.length > 0;

  useEffect(() => {
    getHealthdataSources({
      variables: {
        truentityId: truentityId
      }
    });
  }, [truentityId]);

  useEffect(() => {
    if (data && data.healthdataSources && data.healthdataSources.healthdataSources) {
      setHealthdataSources(data.healthdataSources.healthdataSources);
    }
  }, [data]);

  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    let variables = {};
    variables = {
      truentityId: truentityId,
      medrecSnapshotId: medRecSnapshotId,
      healthdataSourceId: values.healthdataSourceSelect,
      description: values.description
    };
    try {
      const result = await sendMedrecSnapshot({
        variables: { ...variables }
      });
      const { status } = result.data!.sendMedrecSnapshot;

      if (status !== 'Failure') {
        showSnackbar(successMessage, 'success');
        return;
      }
    } catch (err) {
      console.log(err);
    }

    showSnackbar('Connection under construction. Please contact Tech Support.', 'error');
  };

  const getHealthdataSourceList = () => {
    const fmtOrgs = accountHealthdataSources.map((healthdataSource: { id: number; name: string }, index: number) => {
      return (
        <MenuItem key={index} value={healthdataSource.id}>
          {healthdataSource.name}
        </MenuItem>
      );
    });
    return fmtOrgs;
  };

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, { variant });
    hideDialog();
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        {!isThereData() && (
          <>
            <Alert description="No Healthcare Orgs Found." status="warning" title="Target Orgs not found" />
            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <Button a11yLabel="OK" onClick={hideDialog} />
            </DialogActions>
          </>
        )}

        {isThereData() && (
          <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
            <LabelGroup label="MedRec Snapshot Name">
              <H4>{medRecSnapshotName}</H4>
            </LabelGroup>

            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel id="healthdata-select-label">Select a HealthCare Organization</InputLabel>

              <Select
                {...register('healthdataSourceSelect')}
                labelId="healthdata-select-label"
                label="Select a HealthCare Organization"
                required
              >
                {getHealthdataSourceList()}
              </Select>
            </FormControl>

            <TruentityTextField type="text" {...register('description')} label="Description" fullWidth multiline rows={6} required />

            <DialogActions sx={{ justifyContent: 'flex-start' }}>
              <Button type="submit" a11yLabel="Submit" appearance="primary" />
              <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={hideDialog} />
            </DialogActions>
          </Stack>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default SendEHRDialog;
