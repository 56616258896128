import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { H3 } from '@/components/Typography';
import type { GetAllCompaniesResponse } from '@/graphql/company';
import { GET_ALL_COMPANIES } from '@/graphql/company';
import type { RelyingPartyType } from '@/types/graphql';
import { formatDateAndTime } from '@/util/format';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';

type Props = {
  selectedRelyingPartyIds: GridRowId[];
  setSelectedRelyingPartyIds: Dispatch<SetStateAction<GridRowId[]>>;
};

const SelectAnnouncementRecipient = ({ selectedRelyingPartyIds, setSelectedRelyingPartyIds }: Props) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { data: allCompaniesData, loading: allCompaniesLoading } = useQuery<GetAllCompaniesResponse>(GET_ALL_COMPANIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    }
  });

  const columns: GridColDef<RelyingPartyType>[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      sortable: true
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Created On',
      valueGetter: params => formatDateAndTime(params.row.createdAt),
      sortable: true
    }
  ];

  return (
    <Stack gap={2}>
      <H3>Select Recipients</H3>
      <TruentityDataGrid
        name={'dg-all-companies'}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
        checkboxSelection
        rowSelectionModel={selectedRelyingPartyIds}
        onRowSelectionModelChange={e => {
          setSelectedRelyingPartyIds(e);
        }}
        paginationMode={'server'}
        rowCount={allCompaniesData?.relyingPartiesAll?.meta?.totalCount ?? 0}
        autoHeight
        loading={allCompaniesLoading}
        rows={allCompaniesData?.relyingPartiesAll?.relyingParties ?? []}
        columns={columns}
        disableRowSelectionOnClick
        keepNonExistentRowsSelected
      />
    </Stack>
  );
};

export default SelectAnnouncementRecipient;
